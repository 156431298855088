import { HoursOfRestriction } from './hours-of-restriction';
import { HoursOfOperation } from './hours-of-operation';

export class RatePackage {

    description?: string;

    id?: string;

    fiscalYear: number;

    hoursOfOperation: HoursOfOperation;

    hoursOfOperationId: string;

    hoursOfRestriction: HoursOfRestriction;

    hoursOfRestrictionId: string;

    isInactivated: boolean;

    isSeasonal: boolean;

    name?: string;

    parentHoO: HoursOfOperation;

    parentHoOId: string;

    threeYearFUA: FUA[];

    dateRequested: Date;

    seasonalDateRanges: DateStartEndPair[];

    numberOfHistoricalMeters: number;

    numberOfActiveSpaces: number;

    numberOfActiveMeters: number;
}

export class FUA {
  calculationYear: number;

  utilizationAmount: number;
}

export class RatePackageFUAsListKeyValuePair {
  key: string;
  value: FUA[];
}

export class RatePackageStatistics {
  weekly: number;
  monthly: number;
  yearly: number;
}

export class DateStartEndPair {
  constructor(startDate: Date, endDate: Date, isSeasonal: boolean) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.isSeasonal = isSeasonal;
  }
    public endDate: Date;

    public isSeasonal: boolean;

    public startDate: Date;

}
