exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-row {\r\n    white-space: 'normal';\r\n    word-wrap: 'break-word';\r\n    height:50px !important;\r\n    min-height:50px !important;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();