import { Injectable } from '@angular/core';
import { Zone } from './zone';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ZoneService {
    constructor(private http: HttpClient) { }

    public getAllZones(): Observable<Array<Zone>> {
        return this.http.get('/Zones')
          .pipe(catchError(error => this.handleError(error)));
    }

    public createZone(zone: Zone): Observable<Zone> {
        return this.http.post('/Zones', zone)
          .pipe(catchError(error => this.handleError(error)));
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
