export const DaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const DaysOfWeekAbbreviated = [ 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun' ];

export const RestrictionTypes = ['Alderman Parking', 'Industrial Parking Permit', 'Police Parking', 'Rush Hour', 'Residential Restriction', 'Street Cleaning', 'School Days'];

export const DayAbbreviatedDictionary = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thurs',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thurs: 'Thurs',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun'
};
