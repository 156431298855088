import { EditCollectionFormComponent } from './edited-collections/edit-collection-form/edit-collection-form.component';
import { EditedCollectionsComponent } from './edited-collections/edited-collections.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { WorkOrdersModule } from '../work-orders/work-orders.module';

import { AdminComponent } from './admin.component';
import { AnnualPercentageAdjustmentValueComponent } from './system-level-parameters/annual-percentage-adjustment-value.component';
import { AnnualPercentageAdjustmentValueService } from './system-level-parameters/services/annual-percentage-adjustment-value-service';
import { CreateNodeComponent } from './create-node/create-node.component';
import { DeleteManualCollectionsConfirmationComponent } from './enter-manual-collections/delete-manual-collections-confirmation/delete-manual-collections-confirmation';
import { EditAnnualPercentageAdjustmentValue } from './system-level-parameters/edit-annual-percentage-adjustment-value/edit-annual-percentage-adjustment-value';
import { EnterManualCollectionsComponent } from './enter-manual-collections/enter-manual-collections.component';
import { AdFileUploadModule } from 'ad-file-upload';
import { ImportDowntimeComponent } from './system-level-parameters/import-downtime/import-downtime.component';
import { ManualCollectionsService } from './enter-manual-collections/services/manual-collections-service';
import { RevenueReconciliationComponent } from './system-level-parameters/revenue-reconciliation.component';
import { RevenueReconciliationService } from './system-level-parameters/services/revenue-reconciliation-service';
import { PreviousCollectionComponent } from './enter-manual-collections/previous-collection/previous-collection.component';
import { LinkedBlocksComponent } from './linked-blocks/linked-blocks.component';
import { EditedCollectionsService } from './edited-collections/services/edited-collections-service';
import { MeterCollectionsService } from './edited-collections/services/meter-collections-service';
import { WorkOrderGroupsComponent } from './work-order-groups/work-order-groups.component';
import { DowntimeUploadService } from './system-level-parameters/import-downtime/services/downtime-upload-service';

@NgModule({
  imports: [CommonModule, CoreModule, AdFileUploadModule, WorkOrdersModule],
  declarations: [
    AdminComponent,
    AnnualPercentageAdjustmentValueComponent,
    CreateNodeComponent,
    DeleteManualCollectionsConfirmationComponent,
    EditAnnualPercentageAdjustmentValue,
    EditCollectionFormComponent,
    EnterManualCollectionsComponent,
    EditedCollectionsComponent,
    ImportDowntimeComponent,
    LinkedBlocksComponent,
    RevenueReconciliationComponent,
    PreviousCollectionComponent,
    WorkOrderGroupsComponent
  ],
  exports: [
    AnnualPercentageAdjustmentValueComponent,
    EditAnnualPercentageAdjustmentValue,
    EditCollectionFormComponent,
    DeleteManualCollectionsConfirmationComponent,
    PreviousCollectionComponent,
    RevenueReconciliationComponent
  ],
  providers: [
    AnnualPercentageAdjustmentValueService,
    EditedCollectionsService,
    MeterCollectionsService,
    ManualCollectionsService,
    RevenueReconciliationService,
    DowntimeUploadService
  ],
  entryComponents: [
    DeleteManualCollectionsConfirmationComponent,
    EditAnnualPercentageAdjustmentValue,
    EditCollectionFormComponent,
    PreviousCollectionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: []
})
export class AdminModule {}
