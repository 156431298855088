import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Space } from '../space';
import { SpaceAroundCornerConstants } from '../space-constants';
import { InventoryFilterRepresentation } from '../../inventory-filter/inventory-filter-representation';
import { FilterOperator } from '../../../core/query-strings/query-string-builder';
import { SpaceService } from '../spaces.service';
import { StringExtensions } from '../../../string-extensions';

@Component({
  selector: 'app-edit-space-dialog',
  templateUrl: './edit-space-dialog.component.html',
  styleUrls: ['./edit-space-dialog.component.css']
})
export class EditSpaceDialogComponent implements OnInit {

  space: Space;
  spaceIdAlreadyExists: boolean = false;
  originalSpaceId: string; 

  constructor(public dialogRef: MatDialogRef<EditSpaceDialogComponent>,
  public spaceService: SpaceService) { }

  ngOnInit() {
    this.originalSpaceId = this.space.displayId;
  }


  checkIfSpaceExists(spaceId) {
    if(spaceId == this.originalSpaceId || StringExtensions.StringIsEmpty(spaceId.toString())) {
      this.spaceIdAlreadyExists = false;
      return;
    }

    var spaceFilter = new InventoryFilterRepresentation();
    spaceFilter.SpaceId = spaceId;
    this.spaceService.getAllSpaces("currentStatus", true, 0, 25, spaceFilter).subscribe(
      spacesReturned => {
        var spaceWithSameId = spacesReturned.items.filter(s => s.displayId == spaceId);
        this.spaceIdAlreadyExists = spaceWithSameId && spaceWithSameId.length > 0;
      }
    );
  }
}
