import { WorkOrder } from './../../../models/work-order';
import { ReactivateMeterConstants } from './../../../action-field/action-field-constants';
import { Validators } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { ActionFormWithValidation } from './action-form-with-validation';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from './../../../action-field/workorder-action-json-constants';
import { InventoryIdExistsValidation } from '../form-field-validators/inventory-id-validators';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { NotPhysicallyReinstallingMeter } from '../../../models/warning-constants';
import {debounceTime} from 'rxjs/operators';

export class ReactivateMeterForm extends ActionFormWithValidation {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJson: any, hasHistoricalDate: boolean, historicalDate: Date, overrideAsCity: boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJson;
    this.onChanges();
  }

  private onChanges() {
    if (!this.workOrderActionJsonObject) {
      this.setValue(ReactivateMeterConstants.ReinstallEffectiveImmediately, true);
    }

    this.addControl(ReactivateMeterConstants.ReinstallDate, [Validators.required]);

    if (this.controlExists(ReactivateMeterConstants.ReinstallEffectiveImmediately)) {
      this.subscribeToControl(ReactivateMeterConstants.ReinstallEffectiveImmediately).subscribe(value => {
        if (value) {
          this.disableControl(ReactivateMeterConstants.ReinstallDate);
        }
        else {
          this.enableControl(ReactivateMeterConstants.ReinstallDate);
          this.setAsRequired(ReactivateMeterConstants.ReinstallDate);
          this.updateValueAndValidity(ReactivateMeterConstants.ReinstallDate);
        }
      });
    }

    if (this.workOrderActionJsonObject) {
      if (this.workOrderActionJsonObject[ReactivateMeterConstants.MeterIdText] !== this.workOrderActionJsonObject[ReactivateMeterConstants.MeterSelect]
        && this.workOrderActionJsonObject[ReactivateMeterConstants.MeterIdText] || this.workOrderActionJsonObject[ReactivateMeterConstants.MeterSelect]) {
        this.setMeterSelectIdBasedOnGuid(this.isCityUser() ? ReactivateMeterConstants.MeterIdText : ReactivateMeterConstants.MeterSelect);
      }
    }

    if (this.isCityUser()) {
      this.setAsyncValidator(ReactivateMeterConstants.MeterIdText, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, null, null, this));
      this.subscribeToControl(ReactivateMeterConstants.MeterIdText).pipe(debounceTime(1500)).subscribe(result => {
        this.setMeterGuidBasedOnDisplayId(result, WorkorderActionJsonConstants.meterGuid);
      });
      if(this.workOrderActionJsonObject) {
        if(!this.workOrderActionJsonObject[ReactivateMeterConstants.MeterAddress]) {
          this._meterService.getMeterById(this.workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid]).subscribe(meter =>
          {
            this.setValue(ReactivateMeterConstants.MeterAddress, meter.meterAddress);
          });
        }
      }

    }
    else
    {
      this.addControl(ReactivateMeterConstants.MeterSelect, [Validators.required]);
      this.subscribeToControl(ReactivateMeterConstants.MeterSelect).pipe(debounceTime(1500)).subscribe(result => {
        this.setMeterGuidBasedOnDisplayId(result, WorkorderActionJsonConstants.meterGuid);
      });

      this.setNotPhysicallyReinstallingMeterWarnings();
    }


    if (!this.guidControlExists(WorkorderActionJsonConstants.blockGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.meterGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
    }
  }

  private setNotPhysicallyReinstallingMeterWarnings(): void {
    if(!this.controlExists(ReactivateMeterConstants.PhysicallyReinstallMeter)) {
      this.addControl(ReactivateMeterConstants.PhysicallyReinstallMeter, []);
    }

    this.subscribeToControl(ReactivateMeterConstants.PhysicallyReinstallMeter).pipe(debounceTime(1000)).subscribe(result => {
      //happens on initial creation of the checkbox, unsure why
      if(result === null
        || result === undefined
        || result === ''){ //result is sometimes null or undefined, default to true
        result = true; //but should always be set
        this.setValue(ReactivateMeterConstants.PhysicallyReinstallMeter, result);
      }

      if(result === false && !(this.warnings.includes(NotPhysicallyReinstallingMeter))) {
        this.pushToWarnings(NotPhysicallyReinstallingMeter);
      }
      else if(this.warnings.includes(NotPhysicallyReinstallingMeter) && result === true) {
        this.removeFromWarnings(NotPhysicallyReinstallingMeter);
      }
      this.formGroup.updateValueAndValidity();
    });
  }
}
