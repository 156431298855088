export class MeterDistribution {
    forMonth: string;
    meterId: string;
    calculationTimeStamp: string;
    meterDistributionAmount: number;
}

export class MeterDistributionKeyValuePair {
    month: number;
    meterDistribution: MeterDistribution;
}