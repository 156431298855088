import { InventoryFilterService, DropdownOptions } from './../services/inventory-filter-service';
import { ActionConstants } from './../../action/action-constants';
import { FieldConstants } from './../../action-field/field-constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'rxjs/Rx';
import * as moment from 'moment';

import { Area } from './../../../inventory-management/areas/area';
import { Zone } from './../../../inventory-management/zones/zone';

import { AreaService } from '../../../inventory-management/areas/areas.service';
import { ZoneService } from './../../../inventory-management/zones/zones.service';

import { RegularRateResponse } from './models/regular-rate-response';
import { RegularRateAdjustmentService } from './../services/regular-rate-adjustment.service';
import { WorkOrderAction } from './../../models/work-order-action';
import { RegularRateAdjustmentSpaceChange } from './models/regular-rate-adjustment-space-change';
import { RegularRateSummary } from './models/regular-rate-summary';
import { StringExtensions } from '../../../string-extensions';
import { SubmitRegularRateAdjustmentChangeGroup } from './models/submit-regular-rate-adjustment-change-group';
import { TokenCache } from '../../../security/token-cache';
import { WorkOrderResponseService } from '../../services/work-order-response.service';
import { InventoryChangesService } from '../services/inventory-changes-service';
import { WorkOrder, WorkOrderStatusType } from '../../models/work-order';
import { CommentsDialogComponent } from '../../letter-of-directions-list/comments-dialog/comments-dialog.component';
import { BlockInventoryChange } from '../models/block-inventory-change';
import { MeterInventoryChange } from '../models/meter-inventory-change';
import { SpaceInventoryChange } from '../models/space-inventory-change';
import { DateTimeUtility } from '../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { RegularRateAdjustmentInventoryChanges } from './models/regular-rate-adjustment-inventory-changes';
import { WorkOrderActionResponse } from '../../models/approval-models';
import { environment } from '../../../environment';
import { WorkOrderComment } from '../../models/work-order-comment';
import { InventoryChangeStatusType } from '../models/inventory-change-base';
import { LoadingZoneImpactComponent } from '../../../inventory-management/loading-zone-impact/loading-zone-impact.component';
import { BulkChangesComponentBase } from '../bulk-changes/bulk-changes-component-base';

@Component({
  selector: 'app-regular-rate-adjustment',
  templateUrl: './regular-rate-adjustment.component.html',
  styleUrls: ['./regular-rate-adjustment.component.css']
})
export class RegularRateAdjustmentComponent extends BulkChangesComponentBase implements OnInit {
  constructor(
    private _zoneService: ZoneService,
    private _regularRateAdjustmentService: RegularRateAdjustmentService,
    private _inventoryFilterService: InventoryFilterService,
    private snackBar: MatSnackBar,
    private tokenCache: TokenCache,
    public dialog: MatDialog
  ) {
    super();
  }

  @Input() workOrderAction: WorkOrderAction;
  @Input() workOrderCompleted: boolean;
  @Input() workOrderId: string;
  @Input() workOrderStatusId: number;
  @Input() workOrder: WorkOrder;

  regularRateAdjustmentForm = new FormGroup({
    zoneControl: new FormControl(),
    areaControl: new FormControl(),
    blockControl: new FormControl(),
    meterControl: new FormControl(),
    filterDate: new FormControl()
  });

  zoneOptions: Zone[];
  areaOptions: Area[];
  blockOptions: DropdownOptions[];
  meterOptions: DropdownOptions[];

  loadingSpaces = false;
  displayAreaLoading: boolean;
  displayBlockLoading: boolean;
  displayMeterLoading: boolean;

  isRegularRateAdjustment: boolean;
  isUpdateMeterAttributes: boolean;

  filterDate: Date;
  nodeSelection: string;

  regularRateResponse: RegularRateResponse;
  submittedSummaries: RegularRateSummary[];
  spaceChangesToSubmit: RegularRateAdjustmentSpaceChange[] = [];

  showInvChanges: boolean;
  inventoryChangesSubmitted: boolean;
  isCity: boolean;
  isPendingCityApproval: boolean;
  wasReturnedWithComments = false;
  dialogRefComments: MatDialogRef<CommentsDialogComponent>;

  ratePackagesWithNoNextRatePackage: string[];
  rateChange: any;


  ngOnInit() {
    if (this.workOrder.currentLetterOfDirection.historicalEffectiveDate) {
      this.effectiveDate = new Date(this.workOrder.currentLetterOfDirection.historicalEffectiveDate);
      this.filterDate = this.effectiveDate;
    }
    else {
      this.filterDate = new Date();
      this.filterDate.setHours(0, 0, 0, 0); // as of start of day
    }

    if (this.workOrderAction.blockInventoryChanges.length === 0 && this.workOrderAction.meterInventoryChanges.length === 0 && this.workOrderAction.spaceInventoryChanges.length === 0) {
      this.inventoryChangesSubmitted = false;
    } else {
      this.inventoryChangesSubmitted = this.areAllInventoryChangesPending(this.workOrderAction.blockInventoryChanges, this.workOrderAction.meterInventoryChanges, this.workOrderAction.spaceInventoryChanges);
    }
    this.isPendingCityApproval = this.workOrder.currentStatusId === WorkOrderStatusType.PendingCityApprovalId;
    this.isCity = this.tokenCache.checkForCityUser();
    if (this.workOrderAction.workOrderActionResponses.length > 0) {
      const orderedResponses = this.workOrderAction.workOrderActionResponses.sort((a, b) => {
        return DateTimeUtility.compareDate(a.responseDate, b.responseDate) ? -1 : 1;
      });
      if (orderedResponses[0].responseType === 'Return With Comments') { this.wasReturnedWithComments = true; }
    }
    this.showInvChanges = !this.isCity || this.inventoryChangesSubmitted || (this.isCity && this.workOrder.currentStatusId === WorkOrderStatusType.PendingCityApprovalId);

    this._zoneService.getAllZones().subscribe(result => {
      this.zoneOptions = this.sort(result, 'displayId');
      this.regularRateAdjustmentForm.get('zoneControl').enable();
    });

    if (this.workOrderAction.regularRateAdjustmentInventoryChanges) {
      this.createSummaryRecordsFromPreviouslySubmittedChanges();
    }

    this.regularRateAdjustmentForm
      .get('zoneControl')
      .valueChanges.subscribe(value => {
        if (value === null || value.length > 1 || value.length === 0) {
          this.regularRateAdjustmentForm.get('areaControl').disable();
          this.regularRateAdjustmentForm.get('areaControl').setValue(null);
          this.regularRateAdjustmentForm.get('blockControl').disable();
          this.regularRateAdjustmentForm.get('blockControl').setValue(null);
          this.regularRateAdjustmentForm.get('meterControl').disable();
          this.regularRateAdjustmentForm.get('meterControl').setValue(null);
        } else if (value.length === 1) {
          this.displayAreaLoading = true;
          this._inventoryFilterService.getAreasFilterByZone(value[0], this.filterDate).subscribe(
            result => {
              this.areaOptions = this.sort(result, 'value');
              this.regularRateAdjustmentForm.get('areaControl').enable();
              this.displayAreaLoading = false;
            },
            error => {
              this.openSnackBar(error['_body'], 'X');
              console.log(error);
              this.displayAreaLoading = false;
            }
          );
        }
      });

    this.regularRateAdjustmentForm
      .get('areaControl')
      .valueChanges.subscribe(value => {
        if (value === null || value.length > 1 || value.length === 0) {
          this.regularRateAdjustmentForm.get('blockControl').disable();
          this.regularRateAdjustmentForm.get('blockControl').setValue(null);
          this.regularRateAdjustmentForm.get('meterControl').disable();
          this.regularRateAdjustmentForm.get('meterControl').setValue(null);
        } else if (value.length === 1) {
          this._inventoryFilterService
            .getBlocksFilterByAreas(
              this.regularRateAdjustmentForm.get('areaControl').value, this.regularRateAdjustmentForm.get('filterDate').value
            )
            .subscribe(
              result => {
                this.blockOptions = this.sort(result, 'value');
                this.regularRateAdjustmentForm.get('blockControl').enable();
                this.displayBlockLoading = false;
              },
              error => {
                this.openSnackBar(error['_body'], 'X');
                console.log(error);
                this.displayBlockLoading = false;
              }
            );
        }
      });

    this.regularRateAdjustmentForm
      .get('blockControl')
      .valueChanges.subscribe(value => {
        if (value === null || value.length > 1) {
          this.regularRateAdjustmentForm.get('meterControl').disable();
          this.regularRateAdjustmentForm.get('meterControl').setValue(null);
        } else if (value.length === 1) {
          this._inventoryFilterService
            .getMetersFilterByBlocks(
              this.regularRateAdjustmentForm.get('blockControl').value, this.regularRateAdjustmentForm.get('filterDate').value
            )
            .subscribe(
              result => {
                this.meterOptions = this.sort(result, 'value');
                this.regularRateAdjustmentForm.get('meterControl').enable();
                this.displayMeterLoading = false;
              },
              error => {
                this.openSnackBar(error['_body'], 'X');
                console.log(error);
                this.displayMeterLoading = false;
              }
            );
        }
      });
  }

  private areAllInventoryChangesPending(...changeTypes: Array<Array<BlockInventoryChange|MeterInventoryChange|SpaceInventoryChange>>): boolean {
    if (!changeTypes) { return true; }

    const changes = [].concat(...changeTypes);
    for (let i = 0, change; change = changes[i]; ++i) {
      if (change.status !== InventoryChangeStatusType.Pending) { return false; }
    }

    return true;
  }

  createSummaryRecordsFromPreviouslySubmittedChanges() {
    const regularRateAdjustmentSpaceChanges = new Array<RegularRateAdjustmentSpaceChange>();
    this.workOrderAction.regularRateAdjustmentInventoryChanges.forEach(regularRateAdjustmentGroup => {
      this.workOrderAction.spaceInventoryChanges.forEach(spaceChange => {
        const existingSpace = regularRateAdjustmentSpaceChanges.find(x => {
          return spaceChange.spaceId === x.spaceId;
        });

        //ignore any space already found
        if(!(existingSpace === null || existingSpace === undefined)){
          return;
        }

        if (spaceChange.regularRateAdjustmentInventoryChangeGroupId && spaceChange.regularRateAdjustmentInventoryChangeGroupId !== StringExtensions.EmptyGuid) {
          regularRateAdjustmentSpaceChanges.push(new RegularRateAdjustmentSpaceChange(spaceChange.currentRatePackageName,
            spaceChange.currentRatePackageId, spaceChange.effectiveDate, spaceChange.meterId,
            spaceChange.meterDisplayId, spaceChange.spaceId, spaceChange.spaceDisplayId, spaceChange.ratePackageName,
             spaceChange.ratePackageId, 0, regularRateAdjustmentGroup.id));

          const existingChange = this.spaceChangesToSubmit.filter(change => {
            return change.spaceId === spaceChange.spaceId;
          });

          if (existingChange.length === 0) {
            this.spaceChangesToSubmit.push(new RegularRateAdjustmentSpaceChange(spaceChange.currentRatePackageName, spaceChange.currentRatePackageId, spaceChange.effectiveDate, spaceChange.meterId, spaceChange.meterDisplayId, spaceChange.spaceId,
              spaceChange.spaceDisplayId, spaceChange.ratePackageName, spaceChange.ratePackageId, 1, regularRateAdjustmentGroup.id));
          }
        }
      });
    });

    const regularRateAdjustmentSummaries = new Array<RegularRateSummary>();
    const ratePackageIdAndMeterIdDictionary = {};
    regularRateAdjustmentSpaceChanges.forEach(spaceChange => {
      const existingSummary = regularRateAdjustmentSummaries.filter(createdSummary => {
        return createdSummary.currentRatePackageId === spaceChange.currentRatePackageId;
      })[0];

      if (existingSummary) {
        existingSummary.spaceCount++;
        if (!ratePackageIdAndMeterIdDictionary[spaceChange.currentRatePackageId].includes(spaceChange.meterId)) {
          existingSummary.meterCount++;
          ratePackageIdAndMeterIdDictionary[spaceChange.currentRatePackageId].push(spaceChange.meterId);
        }
      }
      else {
        ratePackageIdAndMeterIdDictionary[spaceChange.currentRatePackageId] = [spaceChange.meterId];
        regularRateAdjustmentSummaries.push(new RegularRateSummary(spaceChange.currentRatePackageDisplayId,
          spaceChange.currentRatePackageId, 1, spaceChange.newRatePackageDisplayId,
          spaceChange.newRatePackageDisplayId, 1, spaceChange.inventoryChangeId, spaceChange.meterDisplayId, spaceChange.spaceDisplayId));
      }
    });

    this.submittedSummaries = regularRateAdjustmentSummaries;
  }

  clear(controlName) {
    this.regularRateAdjustmentForm.get(controlName).setValue(null);
  }

  clearAll() {
    this.regularRateAdjustmentForm.reset();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  submitSelection() {
    this.loadingSpaces = true;
    let idList = [];
    let nodeType = '';
    this.nodeSelection = '';
    if (this.regularRateAdjustmentForm.get('meterControl').value) {
      idList = this.regularRateAdjustmentForm.get('meterControl').value;
      nodeType = 'meter';
    } else if (this.regularRateAdjustmentForm.get('blockControl').value) {
      idList = this.regularRateAdjustmentForm.get('blockControl').value;
      nodeType = 'block';
    } else if (this.regularRateAdjustmentForm.get('areaControl').value) {
      idList = this.regularRateAdjustmentForm.get('areaControl').value;
      nodeType = 'area';
    } else if (this.regularRateAdjustmentForm.get('zoneControl').value) {
      idList = this.regularRateAdjustmentForm.get('zoneControl').value;
      nodeType = 'zone';
    }

     this.generateNodeSelectionString();

    const firstGroup = this.workOrderAction.regularRateAdjustmentInventoryChanges[0];
    this.rateChange = firstGroup.rateChange;

    let filterDateValue = new Date(this.regularRateAdjustmentForm.get('filterDate').value);

    if (this.workOrder.previousActivationEffectiveDate != null) {
      const previousDay = new Date(filterDateValue.getTime() - 60000);
      const previousEffectiveDate = new Date(this.workOrder.previousActivationEffectiveDate);

      // use gettime to compare milliseconds since unix epoch
      if (previousDay.getTime() === previousEffectiveDate.getTime()) {
        filterDateValue = previousEffectiveDate;
      }
    }

    this._inventoryFilterService.getSpacesForRegularRateAdjustment(idList, nodeType, filterDateValue, firstGroup.rateChange).subscribe(
      result => {
        this.regularRateResponse = result.result;
        this.ratePackagesWithNoNextRatePackage = new Array<string>(); //clear out the errors if we had a good response
        this.loadingSpaces = false;
      },
      error => {
        this.regularRateResponse = null; //clear out the good result if we had an error
        this.ratePackagesWithNoNextRatePackage = new Array<string>();
        const operationResult = JSON.parse(error['_body']);
        if (operationResult.validationRepresentations) {
          operationResult.validationRepresentations.forEach(element => {
          if (!this.ratePackagesWithNoNextRatePackage.includes(element.currentRatePackageName)) {
              this.ratePackagesWithNoNextRatePackage.push(element.currentRatePackageName);
          }
        }); }
        this.loadingSpaces = false;

        this.openSnackBar('Part of your selection does not have a corresponding rate package. Please create a corresponding rate package and try again.', 'X');

      }
    );
  }

  generateNodeSelectionString() {
    this.nodeSelection = this.generateNodeGroupString('Zone');
    if (this.regularRateAdjustmentForm.get('areaControl').value) {
      this.nodeSelection = this.nodeSelection + ',  ' + this.generateNodeGroupString('Area');
    }
    if (this.regularRateAdjustmentForm.get('blockControl').value) {
      this.nodeSelection = this.nodeSelection + ',  ' + this.generateNodeGroupString('Block');
    }
    if (this.regularRateAdjustmentForm.get('meterControl').value) {
      this.nodeSelection = this.nodeSelection + ',  ' + this.generateNodeGroupString('Meter');
    }
  }

  generateNodeGroupString(node: string): string {
    const nodeOptionsString = (node.toLowerCase() + 'Options').replace(/[\s]/g, '');
    const nodeControlString = (node.toLowerCase() + 'Control').replace(/[\s]/g, '');
    const isZone = node.toLowerCase().includes('zone');
    if (this.regularRateAdjustmentForm.get(nodeControlString).value.length > 1) {
      node = node + 's ';
      this.regularRateAdjustmentForm.get(nodeControlString).value.forEach(nodeValue => {
        node = node + this[nodeOptionsString].filter(nodeOption => {
          if (isZone) {
            return nodeOption.id === nodeValue;
          }
          else {
            return nodeOption.key === nodeValue;
          }
        })[0][isZone ? 'displayId' : 'value'];
        if (this.regularRateAdjustmentForm.get(nodeControlString).value.indexOf(nodeValue) !== this.regularRateAdjustmentForm.get(nodeControlString).value.length - 1) {
          node = node + ', ';
        }
      });
    }
    else {
      node = node + ' ';
      node = node + this[nodeOptionsString].filter(nodeOption => {
          if (isZone) {
            return nodeOption.id === this.regularRateAdjustmentForm.get(nodeControlString).value[0];
          }
          else {
            return nodeOption.key === this.regularRateAdjustmentForm.get(nodeControlString).value[0];
          }
      })[0][isZone ? 'displayId' : 'value'];
    }

    return node;
  }

  removeSelection(regularRateAdjustmentChange: RegularRateSummary) {
    this.openSnackBar('Removing Previous Selection...', '');
    this._regularRateAdjustmentService.deleteSelection(this.workOrderAction.id, regularRateAdjustmentChange.inventoryChangeId, this.workOrder.id).subscribe(result => {
      this.openSnackBar('Removed Previous Selection', '');
      window.location.reload();
    });
  }

  submitChanges() {
    this.openSnackBar('Submitting Regular Rate Changes', '');
    const spacesToSubmit = new Array<RegularRateAdjustmentSpaceChange>();
    this.regularRateResponse.detail.forEach(regularRateResponseSpaceChange => {
      const spaceAlreadyInSpaceChanges = this.workOrderAction.spaceInventoryChanges.filter(spaceChange => {
        return spaceChange.spaceId === regularRateResponseSpaceChange.spaceId;
      });
      if (spaceAlreadyInSpaceChanges.length === 0) {
        spacesToSubmit.push(regularRateResponseSpaceChange);
      }
    });

    const toSubmit = this.createSubmitObject(spacesToSubmit, this.workOrderAction.regularRateAdjustmentInventoryChanges[0]);

    this._regularRateAdjustmentService
      .submitRegularRateChanges(toSubmit)
      .subscribe(
        result => {
          this.openSnackBar('Submitted Regular Rate Changes', '');
          window.location.reload();
        },
        error => {
          this.openSnackBar(error['_body'], 'X');
          console.log('Error submitting Regular Rate Changes');
          console.log(error);
        }
      );
  }

  activateChanges() {
    const impactReasons = new Array<string>();
    this.workOrderAction.loadingZoneImpactChangeGroups.forEach(loadingZoneImpactChangeGroup => {
      loadingZoneImpactChangeGroup.relatedChanges.forEach(relatedChange => {
        const reason = impactReasons.find(impactReason => impactReason === relatedChange.impactReason);
        if (reason == null || reason === undefined) {
          impactReasons.push(relatedChange.impactReason);
        }
      });
    });
    if (impactReasons.length > 0) {
      const dialogRef = this.dialog.open(LoadingZoneImpactComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          impactReasons: impactReasons
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.createActivateBody();
        }
        else {
          this.openSnackBar('Activate Cancelled', 'X');
        }
      });
    }
    else {
      this.createActivateBody();
    }
  }

  createActivateBody() {
    this.openSnackBar('Activating Regular Rate Changes', '');
    const toSubmit = this.createSubmitObject(this.spaceChangesToSubmit, this.workOrderAction.regularRateAdjustmentInventoryChanges[0]);
    toSubmit.effectiveDate = this.effectiveDate;
    this._regularRateAdjustmentService
      .activateRegularRateChanges(toSubmit)
      .subscribe(
        result => {
          this.openSnackBar('Activated Regular Rate Changes', '');
          window.location.reload();
        },
        error => {
          this.openSnackBar(error['_body'], 'X');
          console.log('Error activating Regular Rate Changes');
          console.log(error);
        }
      );
  }

  createSubmitObject(changes, existingGroup: RegularRateAdjustmentInventoryChanges) {
    const changedDateTime = moment.utc(new Date()).toDate();
    const toSubmit = new SubmitRegularRateAdjustmentChangeGroup();
    toSubmit.changedDateTime = changedDateTime;
    toSubmit.rateChange = existingGroup.rateChange;
    toSubmit.workOrderActionId = existingGroup.workOrderActionId;
    toSubmit.nodeSelection = this.nodeSelection;
    toSubmit.changes = changes;
    toSubmit.workOrderId = this.workOrderId;
    toSubmit.calculationDatetime = new Date();
    toSubmit.effectiveDate = this.effectiveDate;
    toSubmit.zoneDisplayIds = existingGroup ? existingGroup.zoneDisplayIds : this.regularRateResponse.zoneDisplayIds;
    toSubmit.areaDisplayIds = existingGroup ? existingGroup.areaDisplayIds :  this.regularRateResponse.areaDisplayIds;
    toSubmit.blockDisplayIds = existingGroup ? existingGroup.blockDisplayIds :  this.regularRateResponse.blockDisplayIds;
    toSubmit.meterDisplayIds = existingGroup ? existingGroup.meterDisplayIds :  this.regularRateResponse.meterDisplayIds;

    return toSubmit;
  }

  returnWithComments() {
    this.dialogRefComments = this.dialog.open(CommentsDialogComponent, {
      height: '400px',
      width: '500px'
    });

    this.dialogRefComments.afterClosed().subscribe(result => {
      if (result != null && result !== undefined) {
        this.snackBar.open('Returning Inventory Changes with comments...', '');
        // create response
        const response = new WorkOrderActionResponse();
        response.responder = this.tokenCache.getUserName();
        response.responderRole = this.tokenCache.getUserRoles()[0];
        response.responseDate = new Date();
        response.responseType = 'Return With Comments';
        response.workOrderActionId = this.workOrderAction.id;
        response.workOrderId = this.workOrder.id;

        // create comment
        const newComment = new WorkOrderComment();
        newComment.author = this.tokenCache.getUserName();
        newComment.isInternal = false;
        newComment.text = result;
        newComment.commentTags = [];
        newComment.entryTimeStamp = new Date();
        newComment.workOrderId = this.workOrder.id;
        response.comment = newComment;

        this._regularRateAdjustmentService.returnWithComments(response).subscribe(
          result => {
            window.location.reload();
          },
          error => {
            this.openSnackBar('Error Returning With Comments: ' + error['_body'], 'X');
          }
        );
      }
    });
  }

  acceptChanges() {
    this.openSnackBar('Accepting Regular Rate Adjustment...', '');
    const response = new WorkOrderActionResponse();
    response.responder = this.tokenCache.getUserName();
    response.responderRole = this.tokenCache.getUserRoles()[0];
    response.responseDate = new Date();
    response.responseType = 'Approve';
    response.workOrderActionId = this.workOrderAction.id;
    response.comment = null;
    response.workOrderId = this.workOrder.id;

    this._regularRateAdjustmentService.acceptChanges(response).subscribe(
      result => {
        window.location.reload();
      },
      error => {
        this.openSnackBar('Error Approving: ' + error['_body'], 'X');
      });
  }

  sort(array: any[], sortBy?: string) {
    array.sort((a: any, b: any) => {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      } else if (a[sortBy] > b[sortBy]) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  }

  downloadAll(isSpace: boolean) {
    let str = '';
    let row = '';

    for (const index in this.spaceChangesToSubmit[0]) {
      if (isSpace && index.toLowerCase().includes('display')) {
        row += index + ',';
      } else if (
        index.toLowerCase().includes('display') &&
        !index.toLowerCase().includes('space')
      ) {
        row += index + ',';
      }
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < this.spaceChangesToSubmit.length; i++) {
      if (isSpace || !str.includes(this.spaceChangesToSubmit[i]['meterDisplayId'])) {
        let line = '';
        for (const index in this.spaceChangesToSubmit[i]) {
          if (isSpace && index.toLowerCase().includes('display')) {
            if (line !== '') {
              line += ',';
            }
            line += this.spaceChangesToSubmit[i][index];
          } else if (
            index.toLowerCase().includes('display') &&
            !index.toLowerCase().includes('space')
          ) {
            if (line !== '') {
              line += ',';
            }
            line += this.spaceChangesToSubmit[i][index];
          }
        }
        str += line + '\r\n';
      }
    }

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    const dateString = new Date().toDateString();
    a.download = 'RegularRateAdjustment - ' + dateString + '.csv';
    a.click();
  }

  downloadFile(
    isSpace: boolean,
    currentRatePackageId: string,
    newRatePackageId: string,
    changes: RegularRateAdjustmentSpaceChange[]
  ) {

    const changesToDownload = changes.filter(change => {
      return change.currentRatePackageDisplayId === currentRatePackageId;
    });

    const csvData = this.convertToCSV(
      changesToDownload,
      isSpace,
      currentRatePackageId,
      newRatePackageId
    );
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    const dateString = new Date().toDateString();
    a.download = 'RegularRateAdjustment - ' + dateString + '.csv';
    a.click();
  }

  effectiveDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    this.effectiveDate = event.value;
  }

  convertToCSV(objArray, isSpace, currentRatePackageId, newRatePackageId) {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (const index in objArray[0]) {
      if (isSpace && index.toLowerCase().includes('display')) {
        row += index + ',';
      } else if (
        index.toLowerCase().includes('display') &&
        !index.toLowerCase().includes('space')
      ) {
        row += index + ',';
      }
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      if (isSpace || !str.includes(array[i]['meterDisplayId'])) {
        let line = '';
        for (const index in array[i]) {
          if (isSpace && index.toLowerCase().includes('display')) {
            if (line !== '') {
              line += ',';
            }
            line += array[i][index];
          } else if (
            index.toLowerCase().includes('display') &&
            !index.toLowerCase().includes('space')
          ) {
            if (line !== '') {
              line += ',';
            }
            line += array[i][index];
          }
        }
        str += line + '\r\n';
      }
    }
    return str;
  }

  showDetails(): boolean {
    return !this.isCity && ((this.workOrder.currentStatusId !== WorkOrderStatusType.CompletedId && !this.inventoryChangesSubmitted) || (this.workOrder.currentStatusId !== WorkOrderStatusType.CompletedId));
  }
}
