import { Block } from './../blocks/block';
import { SpaceSummary } from './../spaces/space-summary';
import { MeterSummary } from './../meters/meter-summary';
import { InventoryListSummary, InventoryListPaginationResult } from './inventory-list-summary';

export class InventoryListJsonConverter {

    static jsonToInventoryListSummary(json: any): InventoryListSummary {
        let toReturn = new InventoryListSummary();
        for (let prop in json) {
            toReturn[prop] = json[prop];
        }

        return toReturn;
    }

    static jsonToInventoryListPaginationResult(responseJson: any): InventoryListPaginationResult {
        var inventoryListPaginationResult = new InventoryListPaginationResult();
        inventoryListPaginationResult.items = this.jsonToInventoryList(responseJson["items"]);

        inventoryListPaginationResult.columnSortedBy = responseJson["columnSortedBy"];
        inventoryListPaginationResult.count = responseJson["count"];
        inventoryListPaginationResult.pageSize = responseJson["pageSize"];
        inventoryListPaginationResult.numberOfPages = responseJson["numberOfPages"];
        inventoryListPaginationResult.totalItems = responseJson["totalItems"];

        return inventoryListPaginationResult;
    }

    static jsonToInventoryList(arrayOfInventoryLists: any): InventoryListSummary[] {
        var toReturn: InventoryListSummary[] = [];

        if (arrayOfInventoryLists == null) {
            return toReturn;
        }

        for (var i = 0; i < arrayOfInventoryLists.length; i++) {
            toReturn.push(this.jsonToInventoryListSummary(arrayOfInventoryLists[i]));
        }
        return toReturn;
    }

    static inventoryItemToBlock(inventoryItem: InventoryListSummary){
        var toReturn: Block = new Block();
        this.convertToObjectSummary(toReturn, inventoryItem);

        return toReturn;
    }

    static inventoryItemToMeter(inventoryItem: InventoryListSummary){
        var toReturn: MeterSummary = new MeterSummary();
        this.convertToObjectSummary(toReturn, inventoryItem);

        return toReturn;
    }

    static inventoryItemToSpace(inventoryItem: InventoryListSummary){
        var toReturn: SpaceSummary = new SpaceSummary();
        this.convertToObjectSummary(toReturn, inventoryItem);

        return toReturn;
    }

    static convertToObjectSummary(objectToReturn, inventoryItem){
        for (let prop in inventoryItem) {
            objectToReturn[prop] = inventoryItem[prop];
        }

        return objectToReturn;
    }
}