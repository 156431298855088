import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {WorkOrderHttpService} from './work-order-http.service';
import { Action } from '../models/action';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ActionService {

  constructor(private workorderhttp: WorkOrderHttpService) { }

  public getAllActions(): Observable<any> {
    return this.workorderhttp.get('/actions/', null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public getAllActionsWithCPMLaz(): Observable<any> {
    return this.workorderhttp.get('/actions/all', null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
  }

