exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".button-padding {\r\n    padding-top:15px;\r\n    padding-right:15px;\r\n    padding-left:5%;\r\n}\r\n\r\n.expansion-border {\r\n    border-top: 1px solid lightgray;\r\n}\r\n\r\nspan.mat-expansion-indicator {\r\n    position: absolute !important;\r\n    left:15px !important;\r\n    font-size:250%;\r\n}\r\n\r\n.mat-content {\r\n    position:absolute;\r\n    right:0;\r\n}\r\n\r\n.add-button {\r\n    padding-left:15px;\r\n}\r\n\r\n.work-orders-return {\r\n    position:absolute;\r\n    left:25px;\r\n}\r\n\r\n.add {\r\n  position:absolute;\r\n  right:45px;\r\n}\r\n\r\n.historical-change {\r\n  position:absolute;\r\n  right:125px;\r\n  margin-top:8px;\r\n}\r\n\r\n.modifyingAsCity{\r\n    margin:24px;\r\n}\r\n\r\n.hist-change-warning {\r\n    font-size: 66%;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();