import { WorkOrder } from './../../../models/work-order';
import { ActionConstants } from './../../action-constants';
import { LoadingZoneImpactService } from './../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { ActionField } from '../../../models/action-field';
import { ActionFormWithValidation } from './action-form-with-validation';
import { ChangeContractTypeToConcessionConstants } from './../../../action-field/action-field-constants';
import { InventoryIdExistsValidation } from './../form-field-validators/inventory-id-validators';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from './../../../action-field/workorder-action-json-constants';
import { HistoricalMeterForm } from './historical-meter-form';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import {debounceTime} from 'rxjs/operators';

export class ChangeContractTypeToConcessionForm extends HistoricalMeterForm {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, loadingZoneImpactService: LoadingZoneImpactService, historicalDate: Date, overrideAsCity: boolean, workOrder: WorkOrder ) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, loadingZoneImpactService, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this.onChanges();
  }

  private onChanges() {
    if (this.workOrderActionJsonObject) {
      if (this.isCityUser()) {
        this.setMeterTextField(ChangeContractTypeToConcessionConstants.MeterIDText, ChangeContractTypeToConcessionConstants.MeterStreetNumber);
        const entireMeterChecked = this.getControlValue(ChangeContractTypeToConcessionConstants.EntireMeter);
        this.conditionallyShowAndHideNumberOfSpaces(entireMeterChecked);
      }
      else {
        if (this.workOrderActionJsonObject[ChangeContractTypeToConcessionConstants.MeterIDText] !== this.workOrderActionJsonObject[ChangeContractTypeToConcessionConstants.MeterSelect]
          && this.workOrderActionJsonObject[ChangeContractTypeToConcessionConstants.MeterIDText]) {
            this.setMeterSelectIdBasedOnGuid(ChangeContractTypeToConcessionConstants.MeterSelect);
            this.setMeterSelectIdBasedOnGuid(ChangeContractTypeToConcessionConstants.MeterIDText);
        }
      }
    }
    else {
      this.disableControl(ChangeContractTypeToConcessionConstants.NumberOfSpaces);

      this.setValue(ChangeContractTypeToConcessionConstants.EntireMeter, true);
    }

    const filterDate = this.historicalEffectiveDate ? this.historicalEffectiveDate : new Date();

    if (this.isCityUser()) {
      this.setAsyncValidator(ChangeContractTypeToConcessionConstants.MeterIDText, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, this.getHasHistoricalEffectiveDate, this.getHistoricalEffectiveDate, this));
      this.subscribeToControl(ChangeContractTypeToConcessionConstants.MeterIDText).pipe(debounceTime(1500)).subscribe(result => {
        this.setMeterGuidBasedOnDisplayId(result, WorkorderActionJsonConstants.meterGuid, filterDate);
      });
    }
    else {
      this.setAsyncValidator(ChangeContractTypeToConcessionConstants.MeterSelect, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, this.getHasHistoricalEffectiveDate, this.getHistoricalEffectiveDate, this));
      this.subscribeToControl(ChangeContractTypeToConcessionConstants.MeterSelect).pipe(debounceTime(1500)).subscribe(result => {
        this.setMeterGuidBasedOnDisplayId(result, WorkorderActionJsonConstants.meterGuid, filterDate);
      });
    }

    if (this.controlExists(ChangeContractTypeToConcessionConstants.EntireMeter)) {
      this.subscribeToControl(ChangeContractTypeToConcessionConstants.EntireMeter).subscribe(value => {
        this.conditionallyShowAndHideNumberOfSpaces(value);
      });
    }
  }

  private conditionallyShowAndHideNumberOfSpaces(value) {
    if (value) {
      this.disableControl(ChangeContractTypeToConcessionConstants.NumberOfSpaces);
    }
    else {
      this.enableControl(ChangeContractTypeToConcessionConstants.NumberOfSpaces);
      this.setAsRequired(ChangeContractTypeToConcessionConstants.NumberOfSpaces);
    }}
}
