import { EditAnnualPercentageAdjustmentValue } from './edit-annual-percentage-adjustment-value/edit-annual-percentage-adjustment-value';
import { AnnualPercentageAdjustmentValueService } from './services/annual-percentage-adjustment-value-service';
import { AnnualPercentageAdjustmentValue } from './annual-percentage-adjustment-value';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-annual-percentage-adjustment-value',
  templateUrl: './annual-percentage-adjustment-value.component.html',
  styleUrls: ['./annual-percentage-adjustment-value.component.css']
})
export class AnnualPercentageAdjustmentValueComponent implements OnInit {

  constructor(public dialog: MatDialog, public _annualPercentageAdjustmentValueService: AnnualPercentageAdjustmentValueService) { }

  displayedColumns = ['id', 'fiscalStartYear', 'percentage', 'edit'];
  dataSource = new MatTableDataSource<AnnualPercentageAdjustmentValue>();
  allAnnualPercentageAdjustmentValues: AnnualPercentageAdjustmentValue[];

  ngOnInit() {
    this.getAllAnnualPercentageAdjustmentValues();
  }

  getAllAnnualPercentageAdjustmentValues() {
    this._annualPercentageAdjustmentValueService.getAllAnnualPercentageAdjustmentValues()
    .subscribe(result => {
      this.allAnnualPercentageAdjustmentValues = result;
      this.dataSource = new MatTableDataSource<AnnualPercentageAdjustmentValue>(this.allAnnualPercentageAdjustmentValues);
    },
    error => {
      console.log(error);
    });
  }

  editSystemLevelParam(param: AnnualPercentageAdjustmentValue) {
    const dialogRef = this.dialog.open(EditAnnualPercentageAdjustmentValue, {
      width: '25%',
      height: 'auto',
      data: {
        param: param,
        allAnnualPercentageAdjustmentValues: this.allAnnualPercentageAdjustmentValues
       }
    });

    dialogRef.afterClosed().subscribe(result => {
    if (result === true) {
        this.getAllAnnualPercentageAdjustmentValues();
      }
    });
  }

}
