import { WorkOrderAction } from './work-order-action';

export class WorkOrderUpdate {
    id: string;
    workOrderActions: Array<WorkOrderAction>;
    fieldDiscoveryDate: string;
    dateOfOccurance: string;
    workOrderStatusId: number;
    workOrderStatus: string;
    responderComments: string;
    hasHistoricalEffectiveDate: boolean;
    historicalEffectiveDate: Date;
    zoneDisplayIds: string[];
    areaDisplayIds: string[];
    blockDisplayIds: string[];
    meterDisplayIds: string[];
}
