import { InventoryTimelineModule } from './../inventory-components/inventory-timeline/inventory-timeline.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../../core/core.module';


import { AddressValidationService } from './address-validation.service';
import { BlockDetailComponent } from './block-detail/block-detail.component';
import { BlockService } from './blocks.service';
import { EditBlockDialogComponent } from './edit-block-dialog/edit-block-dialog.component';
import { InventoryTableModule } from '../inventory-table/inventory-table.module';
import { MetersModule } from './../meters/meters.module';
import { RatePackagesModule } from '../rate-packages/rate-packages.module';


@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        RatePackagesModule,
        MetersModule,
        InventoryTableModule,
        InventoryTimelineModule
    ],
    exports: [],
    declarations: [
        BlockDetailComponent,
        EditBlockDialogComponent
    ],
    providers: [
        BlockService,
        AddressValidationService
    ],
    bootstrap: [EditBlockDialogComponent]

})
export class BlocksModule { }
