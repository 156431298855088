export class ReportingQuarter{
  reportingYear: number;
  reportingQuarter: number;
  isApproved: boolean;
  isComplete: boolean;

  public static reportingQuarterSorter(a: ReportingQuarter, b: ReportingQuarter): number {
    if (a.reportingYear < b.reportingYear) {
      return 1;
    }
    else if (a.reportingYear > b.reportingYear) {
      return -1;
    }
    else {
      if (a.reportingQuarter < b.reportingQuarter) {
        return 1;
      }
      else if (a.reportingYear > b.reportingYear) {
        return -1;
      }
      else {
        if (a.isApproved && !b.isApproved) {
          return 1;
        }
        else if (!a.isApproved && b.isApproved) {
          return -1;
        }
      }
    }
    return 0;
  }
}
