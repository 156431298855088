import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportingHttpService } from '../../../reports/services/reporting-http.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class RevenueReconciliationService {
    constructor(private reportingHttpService: ReportingHttpService, protected errorHandler: ErrorHandler) { }

    public get isLoading() {
        return this.reportingHttpService.isLoading;
      }

    public getRevenueReconciliationReport(fiscalYear: number, fiscalQuarter: number): Observable<any> {
        return this.reportingHttpService.get(`/reports/revenueReconciliationReport/Year/${fiscalYear}/Quarter/${fiscalQuarter}`, null)
          .pipe(
            map(response => response),
            catchError(error => this.handleError(error))
          );
    }

    public downloadReport(key: string): Observable<any> {
        return this.reportingHttpService.DownloadReport("REVENUE_RECONCILIATION", key, 10);
    }

    private handleError(error: any): Promise<any> {
        this.errorHandler.handleError(error);
        return Promise.reject(error.message || error);
    }
}
