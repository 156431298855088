import { FormControl, Validators, FormGroup } from '@angular/forms';

import { ActionField } from '../../models/action-field';
import { ActionFieldOption } from '../../models/action-field-option';

export class GenerateFormGroup {

  static toFormGroup(actionIndex: string, actionFields: ActionField[]): FormGroup {
    const group: any = {};

    // Create controls
    this.createControls(actionIndex, actionFields, group);

    // Determine if default conditional display
    this.determineDefaultDisplay(actionIndex, actionFields, group);

    // TODO - Order field options in service call
    this.orderActionFields(actionFields);

    return new FormGroup(group);
  }

  static createControls(actionIndex: string, actionFields: ActionField[], group: FormGroup): void {
    actionFields.forEach(field => {
      if (field.fieldBehavior == null) {
        group[actionIndex + field.id] = new FormControl('');
      } else {
        group[actionIndex + field.id] = new FormControl('', Validators.required);
      }
    });
  }

  static determineDefaultDisplay(actionIndex: string, actionFields: ActionField[], group: FormGroup) {
    actionFields.forEach(field => {
      if (field.conditionalFieldId) {
        const conditionalField = actionFields.filter(fieldInFilter => {
          return fieldInFilter.fieldId === field.conditionalFieldId;
        })[0];

        field.displayField = (field.conditionalValue === conditionalField.value
                          || (field.conditionalValue === 'false' && conditionalField.value === null));

        if (!field.displayField && group[actionIndex + field.id]) {
          group[actionIndex + field.id].clearValidators();
        }
      }
      else {
        field.displayField = true;
      }
    });
  }

  static orderActionFields(actionFields: ActionField[]): void {
    actionFields.forEach(field => {
      if (field.fieldOptions !== null && field.fieldOptions !== undefined && field.fieldOptions.length > 0) {
        field.fieldOptions = this.orderFieldOptions(field.fieldOptions);
      }
    });
  }

  static orderFieldOptions(options: ActionFieldOption[]): ActionFieldOption[] {
    return options.sort((a, b) => a.order - b.order);
  }
}
