export class RegularRateSummary {
  currentRatePackageDisplayId: string;

  currentRatePackageId: string;

  meterCount: number;

  newRatePackageDisplayId: string;

  newRatePackageId: string;

  spaceCount: number;

  inventoryChangeId: string;

  meterDisplayId: string;

  spaceDisplayId: string;

  constructor(currentRatePackageDisplayId: string, currentRatePackageId: string, meterCount: number,
    newRatePackageDisplayId: string, newRatePackageId: string, spaceCount: number, inventoryChangeId: string,
    meterDisplayId: string, spaceDisplayId: string) {
    this.currentRatePackageDisplayId = currentRatePackageDisplayId;
    this.currentRatePackageId = currentRatePackageId;
    this.meterCount = meterCount;
    this.newRatePackageDisplayId = newRatePackageDisplayId;
    this.newRatePackageId = newRatePackageId;
    this.spaceCount = spaceCount;
    this.inventoryChangeId = inventoryChangeId;
    this.meterDisplayId = meterDisplayId;
    this.spaceDisplayId = spaceDisplayId;
  }
}
