import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnChanges, Input } from '@angular/core';

import { Block } from './../../inventory-management/blocks/block';
import { BlockService } from './../../inventory-management/blocks/blocks.service';
import { MeterService } from '../../inventory-management/meters/meters.service';
import { Meter } from '../../inventory-management/meters/meter';
import { AssociatedBlockRelatedBlock } from './associated-block-related-block';

@Component({
  selector: 'app-associated-block-details',
  templateUrl: './associated-block-details.component.html',
  styleUrls: ['./associated-block-details.component.css']
})
export class AssociatedBlockDetailsComponent implements OnChanges {
  @Input() blockId: string;
  @Input() meterId: string;
  @Input() blockTitle: string;
  @Input() effectiveDate: Date = new Date();

  block: Block;
  metersOnBlock: Meter[];
  relatedBlocks: AssociatedBlockRelatedBlock[] = new Array<AssociatedBlockRelatedBlock>();
  loading = true;

  constructor(
    private _blockService: BlockService,
    private _meterService: MeterService,
    private snackbar: MatSnackBar
  ) {}

  ngOnChanges(): void {
    this.loading = true;
    if (this.blockId) {
      this._blockService.getBlockById(this.blockId).subscribe(
        result => {
          this.block = result;
          this.getAllMetersOnBlock();
          this.getAllRelatedBlocksForBlock();
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
    }
    else if (this.meterId) {
      this._meterService.getMeterById(this.meterId).subscribe(result => {
        if (result.blockId !== null) {
          this._blockService.getBlockById(result.blockId).subscribe(result => {
            this.block = result;
            this.getAllMetersOnBlock();
            this.getAllRelatedBlocksForBlock();
          });
        }
        this.loading = false;
      },
    error => {
      this.loading = false;
      console.log(error);
    });
    }
    else {
      this.loading = false;
    }
  }

  getAllMetersOnBlock() {
      this._meterService.getAllMetersOnBlock(this.block.id, this.effectiveDate)
      .subscribe(
        result => {
          this.metersOnBlock = result;
          this.loading = false;
        },
        error => {
          this.snackbar.open('Error finding Meters on Block: ' + error, 'X');
        }
      );
  }

  getAllRelatedBlocksForBlock() {
    this.relatedBlocks = new Array<AssociatedBlockRelatedBlock>();
    this._blockService.getAllLinkedBlocksForSingleBlock(this.block.id)
    .subscribe(
      result => {
        const resultArray = result.filter(block => {
          return block.id !== this.block.id;
        });
        resultArray.forEach(linkedBlock => {
          const displayRelatedBlock = new AssociatedBlockRelatedBlock();
          displayRelatedBlock.displayId = linkedBlock.displayId;
          displayRelatedBlock.id = linkedBlock.id;
          this.relatedBlocks.push(displayRelatedBlock);
        },
        error => {
          this.snackbar.open('Error finding Related Blocks on Block: ' + error, 'X');
        });
      }
    );
  }

  navigateToBlockPage(blockId: string) {
    window.open('/inventorymanagement/blocks/' + blockId, 'noopener');
  }

  navigateToMeterPage(meterId: string) {
    window.open('/inventorymanagement/meters/' + meterId, 'noopener');
  }
}
