import { WorkOrder } from './../../../models/work-order';
import { ActionConstants } from './../../action-constants';
import { Validators } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { ChangeRatePackageConstants } from './../../../action-field/action-field-constants';
import { ChangeRatePackageMoreThanZeroSpacesValidator, ChangeRatePackageTooManySpacesValidator, ChangeRatePackageIsValidForMeterValidator } from '../form-field-validators/change-rate-package-validators';
import { HistoricalMeterForm } from './historical-meter-form';
import { InventoryIdExistsValidation } from '../form-field-validators/inventory-id-validators';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from './../../../action-field/workorder-action-json-constants';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { LoadingZoneImpactService } from '../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { ContractTypeConstants } from '../../../../inventory-management/meters/meter-constants';
import {debounceTime} from 'rxjs/operators';

export class ChangeRatePackageForm extends HistoricalMeterForm {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, loadingZoneImpactService: LoadingZoneImpactService, historicalDate: Date, overrideAsCity: boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, loadingZoneImpactService, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this.onChanges();
  }

  private onChanges() {
    this.initializeFormForLoadingZoneImpacts(ActionConstants.ChangeRatePackageId);
    if (this.workOrderActionJsonObject) {
      if (!this.isCityUser()) {
        if (this.workOrderActionJsonObject[ChangeRatePackageConstants.MeterIDText] !== this.workOrderActionJsonObject[ChangeRatePackageConstants.MeterSelect]
          && this.workOrderActionJsonObject[ChangeRatePackageConstants.MeterIDText]) {
            this.setMeterSelectIdBasedOnGuid(ChangeRatePackageConstants.MeterSelect);
          }
      }
    }
    else {
      this.removeControl(ChangeRatePackageConstants.NumberOfSpaces);
      if(!this.isCityUser()) {
        this.setValue(ChangeRatePackageConstants.EntireMeter, true);
      }   
    }

    if (this.isCityUser()) {
      if (!this.hasHistoricalEffectiveDate) {
        this.setAsyncValidator(ChangeRatePackageConstants.MeterIDText, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, this.getHasHistoricalEffectiveDate, this.getHistoricalEffectiveDate, this));
      }
      let currentValue;
      this.subscribeToControl(ChangeRatePackageConstants.MeterIDText).pipe(debounceTime(1500)).subscribe(value => {
        if (currentValue !== value) {
          currentValue = value;
            if (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
              this.formGroup.get(this.actionIndex + ChangeRatePackageConstants.MeterIDText.toString()).clearAsyncValidators();
              this.addOrRemoveMeterIdValidations(ChangeRatePackageConstants.MeterIDText, ChangeRatePackageConstants.NumberOfSpaces, WorkorderActionJsonConstants.meterGuid, value, [Validators.required, ChangeRatePackageMoreThanZeroSpacesValidator(), ChangeRatePackageTooManySpacesValidator(this.numberOfSpacesOnMeter)], ActionConstants.ChangeRatePackageId);
            }
          this.setMeterGuidBasedOnDisplayId(value, WorkorderActionJsonConstants.meterGuid);

          this._meterService.getMeterByDisplayId(value, this.historicalEffectiveDate).subscribe(meterResult => {
            if(meterResult.currentContractType === ContractTypeConstants.Reserved.title) {
              if(this.controlExists(ChangeRatePackageConstants.EUR)) {
                this.disableControl(ChangeRatePackageConstants.EUR);
              }
            }
            else if(meterResult.currentContractType === ContractTypeConstants.Concession.title) {
              this.enableControl(ChangeRatePackageConstants.EUR);
              this.setAsRequired(ChangeRatePackageConstants.EUR);
              this.updateValueAndValidity(ChangeRatePackageConstants.EUR);
            }
          });
        }
      });
      this.setConditionalDisplaysForCityUser();

      if (this.workOrderActionJsonObject) {
        this.setMeterTextField(ChangeRatePackageConstants.MeterIDText, ChangeRatePackageConstants.MeterStreetNumber);
      }
    }
    else {
      this.addGuidControl(WorkorderActionJsonConstants.ratePackageGuid);
      this.setGuidValidator(WorkorderActionJsonConstants.ratePackageGuid, ChangeRatePackageIsValidForMeterValidator(this.formGroup, this, this._meterService));
      this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
      this.setGuidValidator(WorkorderActionJsonConstants.meterGuid, ChangeRatePackageIsValidForMeterValidator(this.formGroup, this, this._meterService));
      this.setAsyncValidator(ChangeRatePackageConstants.EntireMeter, ChangeRatePackageIsValidForMeterValidator(this.formGroup, this, this._meterService));
      let currentValue;
      this.subscribeToControl(ChangeRatePackageConstants.MeterSelect).pipe(debounceTime(1500)).subscribe(value => {
        if (currentValue !== value) {
          currentValue = value;

            this._meterService.getMeterByDisplayId(value, this.historicalEffectiveDate).subscribe(result => {
              this.meter = result;
              this.numberOfSpacesOnMeter = result.activeSpaces;
              if (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
                if (this.getControlValue(ChangeRatePackageConstants.EntireMeter)) {
                  this.addOrRemoveMeterIdValidations(ChangeRatePackageConstants.MeterSelect, ChangeRatePackageConstants.NumberOfSpaces, WorkorderActionJsonConstants.meterGuid, value, [ChangeRatePackageMoreThanZeroSpacesValidator(), ChangeRatePackageTooManySpacesValidator(this.numberOfSpacesOnMeter)], ActionConstants.ChangeRatePackageId);

                }
                else {
                  this.addOrRemoveMeterIdValidations(ChangeRatePackageConstants.MeterSelect, ChangeRatePackageConstants.NumberOfSpaces, WorkorderActionJsonConstants.meterGuid, value, [Validators.required, ChangeRatePackageMoreThanZeroSpacesValidator(), ChangeRatePackageTooManySpacesValidator(this.numberOfSpacesOnMeter)], ActionConstants.ChangeRatePackageId);
                }
              }
              else {
                if (this.getControlValue(ChangeRatePackageConstants.EntireMeter)) {
                  this.setValidators(ChangeRatePackageConstants.NumberOfSpaces, [ChangeRatePackageMoreThanZeroSpacesValidator(), ChangeRatePackageTooManySpacesValidator(this.numberOfSpacesOnMeter)]);
                }
                this.setValidators(ChangeRatePackageConstants.NumberOfSpaces, [Validators.required, ChangeRatePackageMoreThanZeroSpacesValidator(), ChangeRatePackageTooManySpacesValidator(this.numberOfSpacesOnMeter)]);
              }
              this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.ratePackageGuid).updateValueAndValidity();
              this.formGroup.get(this.actionIndex + ChangeRatePackageConstants.EntireMeter).updateValueAndValidity();
            });
          this.setMeterGuidBasedOnDisplayId(value, WorkorderActionJsonConstants.meterGuid);
        }
      });

      this.subscribeToControl(ChangeRatePackageConstants.RatePackageId).pipe(debounceTime(1500)).subscribe(value => {
        this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid).updateValueAndValidity();
        this.formGroup.get(this.actionIndex + ChangeRatePackageConstants.EntireMeter).updateValueAndValidity();
      });

      this.addControl(ChangeRatePackageConstants.NumberOfSpaces, [Validators.required, ChangeRatePackageMoreThanZeroSpacesValidator(), ChangeRatePackageTooManySpacesValidator(this.numberOfSpacesOnMeter)]);

      this.subscribeToControl(ChangeRatePackageConstants.EntireMeter).subscribe(value => {
        if (this.getControlValue(ChangeRatePackageConstants.EntireMeter) !== value) {
          this.setValue(ChangeRatePackageConstants.EntireMeter, value);
        }
        if (value) {
          this.disableControl(ChangeRatePackageConstants.NumberOfSpaces);
        }
        else {
          this.enableControl(ChangeRatePackageConstants.NumberOfSpaces);
          this.updateValueAndValidity(ChangeRatePackageConstants.NumberOfSpaces);
        }
        this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.ratePackageGuid).updateValueAndValidity();
        this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid).updateValueAndValidity();

      });
      this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
      this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid).setValidators(Validators.required);
      this.addGuidControl(WorkorderActionJsonConstants.ratePackageGuid);
      this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.ratePackageGuid).setValidators(Validators.required);
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.blockGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.meterGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
    }

  }

  setConditionalDisplaysForCityUser() {
    this.subscribeToControl(ChangeRatePackageConstants.ChangeRatePerHour).subscribe(value => {
      if (value) {
        this.enableControl(ChangeRatePackageConstants.RatePerHour);
        this.setAsRequired(ChangeRatePackageConstants.RatePerHour);
      }
      else {
        this.disableControl(ChangeRatePackageConstants.RatePerHour);
      }
    });

    this.subscribeToControl(ChangeRatePackageConstants.ChangePeriodOfStay).subscribe(value => {
      if (value) {
        this.enableControl(ChangeRatePackageConstants.PeriodOfStay);
        this.setAsRequired(ChangeRatePackageConstants.PeriodOfStay);
      }
      else {
        this.disableControl(ChangeRatePackageConstants.PeriodOfStay);
      }
    });

    this.subscribeToControl(ChangeRatePackageConstants.ChangeHoO).subscribe(value => {
      if (value) {
        this.enableControl(ChangeRatePackageConstants.HoursOfOperation);
        this.setAsRequired(ChangeRatePackageConstants.HoursOfOperation);
      }
      else {
        this.disableControl(ChangeRatePackageConstants.HoursOfOperation);
      }
    });

    this.subscribeToControl(ChangeRatePackageConstants.ChangeHoR).subscribe(value => {
      if (value) {
        this.enableControl(ChangeRatePackageConstants.HoursOfRestriction);
        this.setAsRequired(ChangeRatePackageConstants.HoursOfRestriction);
      }
      else {
        this.disableControl(ChangeRatePackageConstants.HoursOfRestriction);
      }
    });
  }
}
