import {ReportingHttpService} from './reporting-http.service';
import { HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import {Injectable} from '@angular/core';
import {FileDownloadService} from '../../core/file-download/file-download.service';
import {TokenCache} from '../../security/token-cache';
import {catchError} from 'rxjs/operators';

@Injectable()
export class SystemSummaryReportService extends ReportingHttpService {

  constructor(
    backend: HttpBackend, options: HttpRequestOptions, tokenCache: TokenCache, fileService: FileDownloadService)
  { super(backend, options, tokenCache, fileService); }

  public RequestSystemSummaryReport(year: any, quarter: any) {

    const endpoint = `/reports/summary/${year}/${quarter}`;

    return this.post(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  //Correlates to QueueItemTypeMetaData in Reporting API
  public static SYSTEM_SUMMARY_RESERVED_POWER_KEY: string = 'SYSTEM_SUMMARY_RESERVED_POWER';

  public DownloadSystemSummaryReport(key: string) {
    return super.DownloadReport(SystemSummaryReportService.SYSTEM_SUMMARY_RESERVED_POWER_KEY, key, 0);
  }
}
