import { TrueUpQueueMessage } from './true-up-queue-message';
import { Component, OnInit, ViewChild, Input, ErrorHandler, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { TrueUpCalculationService } from './true-up-calculation-service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SystemStartTimeService } from '../../system-start-time.service';
import { FiscalUnitConverter } from '../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { EngineRunRequest } from './engine-run-request';
import { TrueUpCalculationStatus } from './true-up-calculation-status';
import { LogMessagesService } from '../log-messages.service';
import { ReportingQuarterService } from '../monthly-calculations/reporting-quarter.service';
import { ReportingQuarter } from '../monthly-calculations/models/reporting-quarter';

@Component({
  selector: 'app-true-up-calculation-status',
  templateUrl: './true-up-calculation-status.component.html',
  styleUrls: ['./true-up-calculation-status.component.css']
})
export class TrueUpCalculationStatusComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dataSource: MatTableDataSource<EngineRunRequest>;
  runRequests: Array<EngineRunRequest>;
  displayedColumns: Array<string> = [
    'status',
    'request',
    'reportingQuarter',
    'closures',
    'inoperable',
    'distributions',
    'trueup',
    'buttons'
  ];
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  reportingQuarters = [];

  selectedReportingQuarter: any;
  runTUEngineAsOfYear: any;
  runTUEngineAsOfQuarter: any;
  systemStartDate: Date;

  loading: boolean;
  trueUpInProgress = false;
  initialIntervalTime = 5000;

  queueInterval: NodeJS.Timer;

  runType = "Run";
  testType = "Test";

  constructor(public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private trueUpCalculationStatusService: TrueUpCalculationService,
    private systemStartTimeService: SystemStartTimeService,
    private logMessagesService: LogMessagesService,
    private reportingQuarterService: ReportingQuarterService,
    private errorHandler: ErrorHandler) {
  }

  ngOnInit() {
    this.loading = true;
    this.trueUpCalculationStatusService.checkIfEngineIsInProcess().subscribe(
      result => {
        this.trueUpInProgress = true;
        this.queueCheckForRunning(this.initialIntervalTime);
      },
      error => {
        this.trueUpInProgress = false;
        this.queueCheckForRunning(this.initialIntervalTime * 2);
      });

    this.systemStartTimeService.getInitialSystemStartTime().subscribe(
      result => {
        this.systemStartDate = result;
        this.initializeReportingQuarterSelectorValues();
      },
      error => {
        console.log('error');
        this.errorHandler.handleError(error);
      }
    );

      this.refreshDataSourceForPageEvent();
  }

  ngOnDestroy() {
    if (this.queueInterval) {
      clearInterval(this.queueInterval);
    }
  }

  queueCheckForRunning(newIntervalTime: number) {
    if (this.queueInterval) {
      clearInterval(this.queueInterval);
    }

    this.queueInterval = setInterval(x => {
      this.trueUpCalculationStatusService.checkIfEngineIsInProcess().subscribe(
        result => {
          this.trueUpInProgress = true;
          this.queueCheckForRunning(this.initialIntervalTime);
          this.refreshDataSourceForPageEvent();
        },
        error => {
          this.trueUpInProgress = false;
          //404 is ok, means nothing running. other errors we should stop
          //this way we don't keep pinging if credentials expire
          //back end will always check when a request is made, so it is safe
          if (error.status === 404) {
            this.queueCheckForRunning(newIntervalTime * 2)
            this.refreshDataSourceForPageEvent();
          }
        });
    },
    newIntervalTime);
  }

  initializeReportingQuarterSelectorValues() {
    this.reportingQuarterService.getReportingQuarters()
      .subscribe(quarters => {

        var filteredQuarters = quarters.filter(q => q.isApproved && q.isComplete);

        if (filteredQuarters.length > 0) {
          //newest calculated quarter
          var newestQuarter = filteredQuarters.sort(ReportingQuarter.reportingQuarterSorter)[0];

          var newestQuarterDate = FiscalUnitConverter.getStartDateOfQuarter(newestQuarter.reportingQuarter, newestQuarter.reportingYear);

          this.reportingQuarters = FiscalUnitConverter.getFiscalQuartersToDate(newestQuarterDate);
        }
        else {
          this.reportingQuarters = FiscalUnitConverter.getFiscalQuartersToDate(this.systemStartDate);
        }
      },
      error => {
        //fall back to system start date if we can't load quarters
        //this is useful if there is a migration that needs to get run against the databases,
        //because it allows us to run the Run Period Decider, which applies pending changes
        this.reportingQuarters = FiscalUnitConverter.getFiscalQuartersToDate(this.systemStartDate);
        this.errorHandler.handleError(error);
      });
  }

  onSelectReportingQuarterChange(reportingQuarter: any) {
    this.runTUEngineAsOfQuarter = String(reportingQuarter).substring(1, 2);
    this.runTUEngineAsOfYear = String(reportingQuarter).substring(3, 7);
  }

  testTrueUpEngine() {
    this.requestTrueUpEngine(this.testType);
  }

  runTrueUpEngine() {
    this.requestTrueUpEngine(this.runType);
  }

  requestTrueUpEngine(requestType: string) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '240px',
      width: '400px',
    });
    this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to initiate a True-Up calculation run for ${this.selectedReportingQuarter}?`;

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const trueUpQueueMessage = this.constructTrueUpQueueMessage();

        var observable: Observable<any>;

        if (requestType == this.runType) {
          observable = this.trueUpCalculationStatusService.runTrueUpEngine(trueUpQueueMessage);
        }
        else if (requestType == this.testType) {
          observable = this.trueUpCalculationStatusService.testTrueUpEngine(trueUpQueueMessage);
        }

        observable.subscribe(
          result => {
            if (result.status === 200) {
              this.trueUpInProgress = true;
              this.queueCheckForRunning(this.initialIntervalTime);
              this.openSnackBar('True-Up Run initiated successfully.', '');
              this.selectedReportingQuarter = undefined;
              this.runTUEngineAsOfQuarter = undefined;
              this.runTUEngineAsOfYear = undefined;

              this.refreshDataSourceForPageEvent();
            }
            else {
              this.openSnackBar('Failed to initiate a True-Up calculation run.', 'X');
            }
          },
          error => {
            this.openSnackBar('Failed to initiate a True-Up calculation run.', 'X');
            this.errorHandler.handleError(error);
          });
      }
    });
  }

  constructTrueUpQueueMessage(): TrueUpQueueMessage {
    return {
      shouldRunDistributions: true,
      shouldRunTrueUp: true,
      reportingYear: this.runTUEngineAsOfYear,
      reportingQuarter: this.runTUEngineAsOfQuarter
    };
  }

  refreshDataSourceForPageEvent() {
    this.trueUpCalculationStatusService.getAll().subscribe(
      result => {
        this.loading = false;
        this.runRequests = result;
        this.dataSource = new MatTableDataSource<EngineRunRequest>(this.runRequests);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  finalizeTrueUpRun(trueUpCalculationRun: TrueUpCalculationStatus) {

    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      width: '360px',
    });
    this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to finalize True-Up calculations for Q${trueUpCalculationRun.reportingQuarter} ${trueUpCalculationRun.reportingYear}?`;

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.trueUpCalculationStatusService.finalizeTrueUpCalculationRun(trueUpCalculationRun.id).subscribe(
          success => {
            this.openSnackBar('Requested finalization of True-Up Calculations', '');
          },
          error => {
            this.openSnackBar('Failed to finalize True-Up Calculations', 'X');
            this.errorHandler.handleError(error);
          });
      }
    });
  }

  downloadLog(streamName: string) {

    if (streamName == null || streamName == '') {
      return;
    }

    this.logMessagesService.downloadStream(streamName).subscribe(
      success => { },
      error => {
        this.openSnackBar('Failed to download log stream', 'X');
        this.errorHandler.handleError(error);
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
