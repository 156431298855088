import { HistoricalChangeToInventoryConfirm } from './../../models/historical-change-to-inventory-confirm';
import { Subject } from 'rxjs';
import { FilterOperator } from './../../../../core/query-strings/query-string-builder';
import { filter, debounceTime } from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SpaceInventoryChange } from '../../models/space-inventory-change';
import { StringExtensions } from '../../../../string-extensions';
import { InventoryFilterRepresentation } from '../../../../inventory-management/inventory-filter/inventory-filter-representation';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { ActionConstants } from './../../../action/action-constants';
import { InventorySelectDialogComponent } from '../../../action-field/inventory-select-dialog/inventory-select-dialog.component';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { WorkOrderAction } from '../../../models/work-order-action';
import { SpaceStatusConstants } from '../../../../inventory-management/spaces/space-constants';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Subscription } from '../../../../../../node_modules/rxjs';

@Component({
    selector: 'app-work-order-install-space',
    templateUrl: 'work-order-install-space.component.html'
})
export class WorkOrderInstallComponent implements OnInit {

    @Input() spaceInventoryChanges: SpaceInventoryChange[];
    @Input() workOrderAction: WorkOrderAction;
    @Input() meterId: string;
    @Input() historicalEffectiveDate: Date;
    @Input() parentForm: FormGroup;
    @Input() actionId: string;

    @Output() errorsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    subscriptions: Subscription[] = [];
    spaceIdFinishedTyping = new Subject<SpaceIdKeyValuePair>();
    selectedSpaceInput: number[] = [];

    constructor(private _spaceService: SpaceService, private _meterService: MeterService,
                private snackBar: MatSnackBar, public dialog: MatDialog) { }

    ngOnInit() {
      this.emitSpaceInventoryChangeStatus();

      this.subscriptions.push(this.spaceIdFinishedTyping.asObservable().pipe(debounceTime(1000))
        .subscribe(spaceIdKeyValuePair => {
          this.confirmSpaceDoesntAlreadyExist(spaceIdKeyValuePair.spaceId, spaceIdKeyValuePair.spaceInventoryChangeId);
        }));

      this.spaceInventoryChanges.forEach(spaceInventoryChange => {
        if (spaceInventoryChange.spaceId === StringExtensions.EmptyGuid) {
          this.parentForm.get(spaceInventoryChange.id).setValue(spaceInventoryChange.spaceDisplayId);
          this.parentForm.get(spaceInventoryChange.id + 'removedSpace').disable();
          this.selectedSpaceInput.push(1);
        }
        else if (spaceInventoryChange.spaceDisplayId) {
          this._spaceService.getSpaceByDisplayId(spaceInventoryChange.spaceDisplayId, this.historicalEffectiveDate).subscribe(result => {
            this.parentForm.get(spaceInventoryChange.id + 'removedSpace').setValue(spaceInventoryChange.spaceDisplayId);
            this.parentForm.get(spaceInventoryChange.id).disable();
            this.selectedSpaceInput.push(2);
          },
        error => {
          this.parentForm.get(spaceInventoryChange.id).setValue(spaceInventoryChange.spaceDisplayId);
          this.parentForm.get(spaceInventoryChange.id + 'removedSpace').disable();
          this.selectedSpaceInput.push(1);
          spaceInventoryChange.spaceId = StringExtensions.EmptyGuid;
        });
        }
        else{ //default case, we have nothing
          this.parentForm.get(spaceInventoryChange.id).setValue(spaceInventoryChange.spaceDisplayId);
          this.parentForm.get(spaceInventoryChange.id + 'removedSpace').disable();
          this.selectedSpaceInput.push(1);
          spaceInventoryChange.spaceId = StringExtensions.EmptyGuid;
        }
      });
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    public changeSpaceIdToEmptyGuid(selectedChange: SpaceInventoryChange, radioValue: number): void {
        selectedChange.spaceId = StringExtensions.EmptyGuid;
        selectedChange.spaceDisplayId = null;
        if (radioValue === 1) {
          this.parentForm.get(selectedChange.id.toString() + 'removedSpace').setValue(null);
          this.parentForm.get(selectedChange.id.toString() + 'removedSpace').disable();
          this.parentForm.get(selectedChange.id.toString()).enable();
        } else {
          selectedChange.displaySpaceAlreadyExistsError = false;
          this.parentForm.get(selectedChange.id.toString()).setValue(null);
          this.parentForm.get(selectedChange.id.toString()).disable();
          this.parentForm.get(selectedChange.id.toString() + 'removedSpace').enable();
        }

        this.emitSpaceInventoryChangeStatus();
    }

    public confirmSpaceDoesntAlreadyExist(displayId, spaceInvChangeId) {
        if (!displayId) {
          return;
        }

        const spaceInvChange = this.spaceInventoryChanges.find(change => {
          return change.id === spaceInvChangeId;
        });

        if (this.spaceInventoryChanges.some(x => x.spaceDisplayId === displayId && x.id !== spaceInvChange.id)) {
          spaceInvChange.displaySpaceAlreadyExistsError = true;
          this.emitSpaceInventoryChangeStatus();

          return;
        }

        if (spaceInvChange !== undefined) {
          this._spaceService.getSpaceByDisplayId(displayId, this.historicalEffectiveDate)// todo call a service that can provide space by displayid historically
          .subscribe(result => {
              if (result) {
                spaceInvChange.displaySpaceAlreadyExistsError = true;
              } else {
                spaceInvChange.displaySpaceAlreadyExistsError = false;
              }

              this.emitSpaceInventoryChangeStatus();
            },
          error => {
            spaceInvChange.displaySpaceAlreadyExistsError = false;
            this.emitSpaceInventoryChangeStatus();
          });
        }
    }

    emitSpaceInventoryChangeStatus() {
      const allSpaceChangesEntered = this.spaceInventoryChanges.filter(x => !x.spaceDisplayId).length === 0;
      const spaceChangesReadyToSubmit = this.spaceInventoryChanges.filter(x => x.displaySpaceAlreadyExistsError).length === 0;
      this.errorsEmitter.emit(allSpaceChangesEntered && spaceChangesReadyToSubmit);
    }

    public previouslyRemovedSpaceIdChanged(displayId, spaceInvChangeId) {
        const spaceInvChange = this.spaceInventoryChanges.find(change => {
          return change.id === spaceInvChangeId;
        });
        if (spaceInvChange !== undefined) {
          const spaceFilter = new InventoryFilterRepresentation();
          spaceFilter.SpaceId = displayId;
          this._spaceService.getAllSpaces('currentStatus', true, 0, 25, spaceFilter)
          .subscribe(result => {
            if (result.items && (result.items.length === 1) && (result.items[0].displayId === displayId)) {
              spaceInvChange.spaceId = result.items[0].id;
              spaceInvChange.spaceDisplayId = result.items[0].displayId;
            }
            else {
              spaceInvChange.spaceId = null;
              spaceInvChange.spaceDisplayId = null;
            }
            this.emitSpaceInventoryChangeStatus();
          });
        }
    }

    openInventorySelectDialog(selectedChange: SpaceInventoryChange): void {
        if (this.actionId === ActionConstants.InstallMeterId.toString()) {
          const dialogRef = this.dialog.open(InventorySelectDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: { context: 'space-select', blockId: this.workOrderAction.blockIDs[0]}
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              let spaceIdUsed = false;
              this.spaceInventoryChanges.forEach(spaceChange => {
                if (spaceChange.spaceId === result.id || spaceChange.spaceDisplayId === result.displayId) {
                  this.snackBar.open('Space ID already selected', '', {
                      duration: 2000,
                  });
                  spaceIdUsed = true;
                }
              });
              if (!spaceIdUsed) {
                selectedChange.spaceDisplayId = result.displayId;
                selectedChange.spaceId = result.id;
                this.parentForm.get(selectedChange.id.toString() + 'removedSpace').setValue(result.displayId);
              }
            }
            else {
              selectedChange.spaceDisplayId = null;
              selectedChange.spaceId = StringExtensions.EmptyGuid;
            }
          });
        }
        else {
          this._meterService.getMeterById(this.meterId)
          .subscribe(result => {
            const meterDisplayId = result.displayId;
            const dialogRef = this.dialog.open(InventorySelectDialogComponent, {
              width: 'auto',
              height: 'auto',
              data: { context: 'space-select', meterId: meterDisplayId }
            });

            dialogRef.afterClosed().subscribe(dialogResult => {
              if (dialogResult) {
                if (dialogResult.currentStatus !== SpaceStatusConstants.removed) {
                  this.snackBar.open('Space selected is currently active', '', {
                    duration: 2000,
                  });
                  return;
                }
                else {
                  let spaceIdUsed = false;
                  this.spaceInventoryChanges.forEach(spaceChange => {
                    if (spaceChange.spaceId === dialogResult.id || spaceChange.spaceDisplayId === dialogResult.displayId) {
                      this.snackBar.open('Space ID already selected', '', {
                        duration: 2000,
                      });
                      spaceIdUsed = true;
                    }
                  });
                  if (!spaceIdUsed) {
                    selectedChange.spaceDisplayId = dialogResult.displayId;
                    selectedChange.spaceId = dialogResult.id;
                    this.parentForm.get(selectedChange.id.toString() + 'removedSpace').setValue(dialogResult.displayId);
                  }
                  else {
                    selectedChange.spaceDisplayId = null;
                    selectedChange.spaceId = StringExtensions.EmptyGuid;
                  }
                }
              } else {
                selectedChange.spaceDisplayId = null;
                selectedChange.spaceId = StringExtensions.EmptyGuid;
              }

              this.emitSpaceInventoryChangeStatus();
            });
          });
        }
      }
}

class SpaceIdKeyValuePair {
  spaceId: string;
  spaceInventoryChangeId: string;
}
