
import {merge as observableMerge,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Meter } from './../meter';
import { BlockService } from './../../blocks/blocks.service';
import { Block } from './../../blocks/block';
import { Component, OnInit, ViewChild, Output, EventEmitter, ErrorHandler, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';

import { SpaceSummary } from './../../spaces/space-summary';
import { SpaceService } from './../../spaces/spaces.service';
import { SpaceStatusConstants } from './../../spaces/space-constants';
import { SpaceSummarySorter } from './../../spaces/space-summary-sorter';

@Component({
    selector: 'app-meter-spaces',
    templateUrl: './meter-spaces.component.html',
    styleUrls: ['./meter-spaces.component.css']
})

export class MeterSpacesComponent implements OnInit {
    spaces: SpaceSummary[];
    dataSource: SpaceSummaryDataSource;
    showShiftedSpaces: boolean;
    meterId: string;
    displayedColumns = ['SpaceID', 'Status', 'MRP', 'ContractType', 'AroundCorner', 'LastRPActionDate',  'CurrentMeterId', 'blockRange', 'fineAmount', 'lastExectedUtilizationRate'];
    @ViewChild(MatSort, {static: true})sort: MatSort;
    SpaceStatusesForActivSpacesTabTitle = [SpaceStatusConstants.active.toLowerCase(), SpaceStatusConstants.reserved.toLowerCase()];

    constructor(private spaceService: SpaceService, private route: ActivatedRoute, private _spaceSummarySorter: SpaceSummarySorter, private _errorHandler: ErrorHandler, private _blockService: BlockService) {
        this.showShiftedSpaces = false;
        this.spaces = new Array<SpaceSummary>();
    }

    @Input() meter: Meter;

    @Output()
    numberOfSpacesEvent: EventEmitter<number> = new EventEmitter<number>();

    ngOnInit(): void {
      if (this.meter) {
        this._blockService.getBlockById(this.meter.blockId).subscribe(result => {
          this.getSpacesForMeter(this.meter.id, result);
        });
      }
    }

    getSpacesForMeter(meterId: string, block: Block) {
        this.spaceService.getSpacesByMeterId(meterId).subscribe(
            result => {
                if (result != null) {
                  this.spaces = result;
                  this.spaces.forEach(space => {
                    space.blockRange = block.location.numberRangeStart + ' - ' + block.location.numberRangeEnd;
                  });
                  const currentSpaces = this.spaces.filter(x => x.currentMeterId === this.meter.id || x.currentStatus === SpaceStatusConstants.activePending);
                  const numberOfActiveSpaces = result.filter(x => (this.SpaceStatusesForActivSpacesTabTitle.includes(x.currentStatus.toLowerCase()) && x.currentMeterId === meterId)).length;
                  this.numberOfSpacesEvent.emit(numberOfActiveSpaces);
                  this.dataSource = new SpaceSummaryDataSource(currentSpaces, this.sort, this._spaceSummarySorter);
                }
            },
            error => {
                this._errorHandler.handleError(error);
            }
        );
    }
    formatDate(dateFromApi: string): string {
        const newDate = new Date(dateFromApi);
        const stringToReturn = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear();
        return stringToReturn;
    }

    shiftedSpacesToggled() {
        this.showShiftedSpaces = !this.showShiftedSpaces;
        let spaces = this.spaces;
        if (!this.showShiftedSpaces) {
            spaces = spaces.filter(x => x.currentMeterId === this.meter.id);
        }
        this.dataSource = new SpaceSummaryDataSource(spaces, this.sort, this._spaceSummarySorter);
    }

    highlightRow(space: SpaceSummary): boolean {
      return (space.currentStatus.toLowerCase() === 'removed'
              || space.currentStatus.toLowerCase() === 'pending'
              || space.currentStatus.toLowerCase() === 'created')
              || space.currentMeterId !== this.meter.id;
    }

  navigateToRatePackagePage(ratePackageName: string) {
    window.open('/inventorymanagement/ratepackages/' + ratePackageName, 'noopener');
  }
}

class SpaceSummaryDataSource extends DataSource<any> {

    constructor(private _spaces: SpaceSummary[], private _sort: MatSort, private _spaceSummarySorter: SpaceSummarySorter) {
        super();
    }

    connect(): Observable<SpaceSummary[]> {
        const displayDataChanges = [
            this._spaces,
            this._sort.sortChange
        ];
        return observableMerge(...displayDataChanges).pipe(map(() => {
            return this._spaceSummarySorter.getSortedData(this._spaces, this._sort);
        }));
    }

    disconnect() {}
}


