import { RatePackageDailyDialog } from './rate-package-daily-dialog/rate-package-daily-dialog';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';

import { RatePackageConfirmationDialogComponent } from './rate-package-confirmation.component';
import { RatePackageCreateComponent } from './rate-package-create/rate-package-create.component';
import { RatePackageDetailComponent } from './rate-package-detail/rate-package-detail.component';
import { RatePackageListComponent } from './rate-package-list/rate-package-list.component';

import { RatePackageSorter } from './rate-package-list/rate-package-sorter';
import { SpaceSummarySorter } from './../spaces/space-summary-sorter';
import { HoursOfOperationService } from './services/hours-of-operation.service';
import { HoursOfRestrictionService } from './services/hours-of-restriction.service';
import { RatePackageService } from './services/rate-package.service';
import { SpaceService } from './../spaces/spaces.service';

import { Routes, RouterModule } from '@angular/router';
import { RateStepComponent } from './rate-package-create/rate-step/rate-step.component';

const routes: Routes = [
  { path: '', component: RatePackageListComponent},
  { path: 'create', component: RatePackageCreateComponent},
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RatePackageConfirmationDialogComponent,
    RatePackageCreateComponent,
    RatePackageDetailComponent,
    RatePackageListComponent,
    RatePackageDailyDialog
  ],
  declarations: [
    RatePackageConfirmationDialogComponent,
    RatePackageCreateComponent,
    RatePackageDetailComponent,
    RatePackageListComponent,
    RatePackageDailyDialog,
    RateStepComponent
  ],
  entryComponents: [
    RatePackageConfirmationDialogComponent,
    RatePackageDailyDialog
  ],
  providers: [
    HoursOfOperationService,
    HoursOfRestrictionService,
    SpaceService,
    RatePackageService,
    RatePackageSorter,
    SpaceSummarySorter,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RatePackagesModule { }
