import { CpmOnlyGuard } from './security/cpm-only-guard/cpm-only-guard.component';
import { AdminComponent } from './admin/admin.component';
import { NgModule } from '@angular/core';
import { Routes, ExtraOptions, RouterModule } from '@angular/router';

import { InventoryManagementComponent } from './inventory-management/inventory-management.component';
import { SpaceDetailComponent } from './inventory-management/spaces/space-detail/space-detail.component';
import { MeterDetailComponent } from './inventory-management/meters/meter-detail/meter-detail.component';
import { BlockDetailComponent } from './inventory-management/blocks/block-detail/block-detail.component';
import { LoginComponent } from './security/login/login.component';
import { WorkOrderCreateComponent } from './work-orders/work-order-create/work-order-create.component';
import { MeterTrueUpDetailComponent } from './trueup/meter-true-up-detail/meter-true-up-detail.component';
import { WorkOrderDetailComponent } from './work-orders/work-order-detail/work-order-detail.component';
import { UnauthorizedComponent } from './security/unauthorized/unauthorized.component';
import { CpmLazOnlyGuard } from './security/cpm-only-guard/cpm-laz-only-guard.component';
import { WorkOrdersListsComponent } from './work-orders/work-orders-lists/work-orders-lists.component';
import { ReportsHomeComponent } from './reports/reports-home/reports-home.component';
import { AuthGuard } from './security/auth.guard';
import { CpmCityOnlyGuard } from './security/cpm-only-guard/cpm-city-only-guard.component';

const appRoutes: Routes = [
    { path: 'admin', component: AdminComponent, pathMatch: 'full', canActivate: [AuthGuard, CpmLazOnlyGuard]},
    { path: 'inventorymanagement', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/meters', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/meters/:id', component: MeterDetailComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/spaces', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/spaces/:id', component: SpaceDetailComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/blocks', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/blocks/:id', component: BlockDetailComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/ratepackages', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'inventorymanagement/ratepackages/create', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard, CpmLazOnlyGuard]},
    { path: 'inventorymanagement/ratepackages/:id', component: InventoryManagementComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'login', component: LoginComponent, pathMatch: 'full'},
    { path: 'trueup', component: MeterTrueUpDetailComponent, pathMatch: 'full', canActivate: [AuthGuard, CpmCityOnlyGuard]},
    { path: 'unauthorized', component: UnauthorizedComponent, pathMatch: 'full' , canActivate: [AuthGuard]},
    { path: 'workorders/create', component: WorkOrderCreateComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'workorders/create/:id', component: WorkOrderCreateComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'workorders/:id', component: WorkOrderDetailComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'workorders', component: WorkOrdersListsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'reports', component: ReportsHomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'reports/reservedpower', component: ReportsHomeComponent, pathMatch: 'full', canActivate:[AuthGuard] },
    { path: 'reports/closure', component: ReportsHomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'inventorymanagement', pathMatch: 'full', canActivate: [AuthGuard]},
    { path: '', redirectTo: 'inventorymanagement', pathMatch: 'full', canActivate: [AuthGuard]},
  ];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
