import { Column } from "../inventory-management/inventory-table/inventory-list/inventory-list-column-def";

export class TrueUpDetailColumnDef {
    static spaceDisplayId: Column = { readable: "Space ID", constant: "spaceId" };
    static spaceId: Column = { readable: "Space GUID", constant: "spaceGuid" };
    static meterDisplayId: Column = { readable: "Meter ID", constant: "meterId" };
    static meterId: Column = { readable: "Meter GUID", constant: "meterGuid" };
    static spaceStatus: Column = { readable: "Space Status", constant: "spaceStatus" };
    static meterStatus: Column = { readable: "Meter Status", constant: "meterStatus" };
    static contractType: Column = { readable: "Contract Type", constant: "contractType" };
    static month: Column = { readable: "Month", constant: "month" };
    static quarter: Column = { readable: "Quarter", constant: "quarter" };
    static year: Column = { readable: "Year", constant: "year" };
    static reportingQuarter: Column = { readable: "Reporting Quarter", constant: "reportingQuarter" };
    static reportingYear: Column = { readable: "Reporting Year", constant: "reportingYear" };
    static revenueValue: Column = { readable: "Revenue Value", constant: "revenue" };
    static existingRevenue: Column = { readable: "Existing Revenue", constant: "existingRevenue" };
    static fullyUtilizedAmount: Column = { readable: "FUA - Month", constant: "fullyUtilizedAmount" };
    static fuaYtd: Column = { readable: "FUA - YTD", constant: "fuaYtd" };
    static fuaYearly: Column = { readable: "FUA - Yearly", constant: "fuaYearly" };
    static areaDisplayId: Column = { readable: "Area", constant: "areaId" };
    static blockDisplayId: Column = { readable: "Block ID", constant: "blockId" };
    static blockId: Column = { readable: "Block GUID", constant: "blockGuid" };
    static blockAddress: Column = { readable: "Block Address", constant: "blockAddress" };
    static ward: Column = { readable: "Ward", constant: "ward" };
    static zoneDisplayId: Column = { readable: "Zone", constant: "zoneId" };
    static ratePackageName: Column = { readable: "Rate Package", constant: "ratePackageName" };
    static ratePackageGuid: Column = { readable: "Rate Package GUID", constant: "ratePackageGuid" };
    static periodOfOperation: Column = { readable: "Period of Operation", constant: "periodOfOperation" };
    static expectedUtilizationRate: Column = { readable: "Expected Utilization Rate", constant: "expectedUtilizationRate" };
    static expectedUtilizationAdjustment: Column = { readable: "Expected Utilization Adjustment", constant: "expectedUtilizationAdjustment" };
    static revenueValueAdjustment: Column = { readable: "Revenue Value Adjustment", constant: "revenueValueAdjustment" };
    static annualPercentageAdjustment: Column = { readable: "Annual Percentage Adjustment", constant: "annualPercentageAdjustment" };
    static aggregateRevenueValue: Column = { readable: "Aggregate Revenue Value", constant: "aggregateRevenueValue" };
    static reservedPowersSystemImpact: Column = { readable: "Reserved Powers System Impact", constant: "reservedPowersSystemImpact" };
    static systemInServicePercentageMonthly: Column = { readable: "System in Service % Monthly", constant: "systemInServicePercentageMonthly" };
    static systemInServicePercentageQuarterly: Column = { readable: "System in Service % Quarterly", constant: "systemInServicePercentageQuarterly" };
    static systemInServicePercentageYearly: Column = { readable: "System in Service % Yearly", constant: "systemInServicePercentageYearly" };
    static closureAllowancePercentage: Column = { readable: "RCA Percentage", constant: "closureAllowancePercentage" };
    static closurePaymentDailyAmount: Column = { readable: "Daily Closure Payment", constant: "closurePaymentDailyAmount" };
    static monthStartClosureBalance: Column = { readable: "RCAB - SOM", constant: "monthStartClosureBalance" };
    static monthEndClosureBalance: Column = { readable: "RCAB - EOM", constant: "monthEndClosureBalance" };
    static requiredClosureAllowance: Column = { readable: "RCA", constant: "requiredClosureAllowance" };
    static requiredClosurePayments: Column = { readable: "Required Closure Payments", constant: "requiredClosurePayments" };
    static requiredClosurePaymentsQuarterToDate: Column = { readable: "RCP - QTD", constant: "requiredClosurePaymentsQuarterToDate" };
    static requiredClosurePaymentsYearToDate: Column = { readable: "RCP - YTD", constant: "requiredClosurePaymentsYearToDate" };
    static distributedRequiredClosurePayments: Column = { readable: "Distributed Required Closure Payments", constant: "distributedRequiredClosurePayments" };
    static rdcpQtd: Column = { readable: "RDCP - QTD", constant: "rdcpQtd" };
    static rdcpYtd: Column = { readable: "RDCP - YTD", constant: "rdcpYtd" };
    static qualifiedRequiredClosureDays: Column = { readable: "Qualified Required Closures", constant: "qualifiedRequiredClosureDays" };
    static systemInServicePaymentQuarterly: Column = { readable: "System in Service Payment Quarterly", constant: "systemInServicePaymentQuarterly" };
    static settlementSystemRevenueValue: Column = { readable: "Settlement System Revenue Value", constant: "settlementSystemRevenueValue" };
    static quarterlySettlementAmount: Column = { readable: "Quarterly Settlement Amount", constant: "quarterlySettlementAmount" };
    static utilizedAmountInoperablePeriods: Column = { readable: "UAI", constant: "utilizedAmountInoperablePeriods" };
    static uaiQtd: Column = { readable: "UAI - QTD", constant: "uaiQtd" };
    static uaiYtd: Column = { readable: "UAI - YTD", constant: "uaiYtd" };

    static closures: Column = { readable: "Closure IDs", constant: "closures" };
    static inoperableWarehouseRecordIds: Column = { readable: "UAI Record IDs", constant: "inoperableWarehouseRecordIds" };
    static numberOfMonthlyRPAs: Column = { readable: "# of Monthly RPAs", constant: "numberOfMonthlyRPAs" };
    static numberOfTotalRPAs: Column = { readable: "# of Total RPAs", constant: "numberOfTotalRPAs" };
    static hasRPA: Column = { readable: "Has RPA", constant: "hasRPA" };
    static dateCreated: Column = { readable: "Date Created", constant: "dateCreated" };
    static dateChanged: Column = { readable: "Date Changed", constant: "dateChanged" };
    static changeInMonth: Column = { readable: "Change in Month", constant: "changeInMonth" };
    static fineAmount: Column = { readable: "Fine Amount", constant: "fineAmount" };
    static spaceFineAmount: Column = { readable: "Fine Amount", constant: "spaceFineAmount" };
    static pac: Column = { readable: "PAC", constant: "pac" };
    static monthlyUR: Column = { readable: "Monthly UR", constant: "monthlyUR" };
    static measuredFua: Column = { readable: "Measured FUA", constant: "measuredFua" };
    static measuredFuaQtd: Column = { readable: "Measured FUA - QTD", constant: "measuredFuaQtd" };
    static measuredFuaYtd: Column = { readable: "Measured FUA - YTD", constant: "measuredFuaYtd" };
    static accurateFua: Column = { readable: "Accurate FUA", constant: "accurateFua" };
    static afuaYtd: Column = {readable: "AFUA - YTD", constant: "afuaYtd" };
    static mur: Column = { readable: "MUR", constant: "mur" };
    static averageMonthlyUrYtd: Column = { readable: "Average Monthly UR - YTD", constant: "averageMonthlyUrYtd" };
    static activeSpacesCount: Column = { readable: "Active Spaces Count", constant: "activeSpacesCount" };
    static actualOperatingRevenueMonthly: Column = { readable: "Actual Operating Revenue - Monthly", constant: "actualOperatingRevenueMonthly" };
    static aorQtd: Column = { readable: "AOR - QTD", constant: "aorQtd" };
    static aorYtd: Column = { readable: "AOR - YTD", constant: "aorYtd" };
    static meteredParkingRevenue: Column = { readable: "Metered Parking Revenue", constant: "meteredParkingRevenue" };
    static mprQtd: Column = { readable: "MPR - QTD", constant: "mprQtd" };
    static mprYtd: Column = { readable: "MPR - YTD", constant: "mprYtd" };
    static rpaReversalPayment: Column = { readable: "RPA Reversal Payment", constant: "rpaReversalPayment" };
    static rpaReversalPaymentQtd: Column = { readable: "RPA Reversal Payment - QTD", constant: "rpaReversalPaymentQtd" };
    static rpaReversalPaymentYtd: Column = { readable: "RPA Reversal Payment - YTD", constant: "rpaReversalPaymentYtd" };
    static calculationTimeStamp: Column = { readable: "Calculation Time Stamp", constant: "calculationTimeStamp" };
    static isValid: Column = { readable: "Is Valid", constant: "isValid" };
    static invalidTimeStamp: Column = { readable: "Invalid Time Stamp", constant: "invalidTimeStamp" };
    static isDeleted: Column = { readable: "Is Deleted", constant: "isDeleted" };
    static systemCalculationId: Column = { readable: "System Calculation Id", constant: "systemCalculationId" };
    static isActive: Column = { readable: "Is Active", constant: "isActive" };
    static changedBy: Column = { readable: "Changed By", constant: "changedBy" };
    static isApproved: Column = { readable: "Is Approved", constant: "isApproved" };
    static approvedBy: Column = { readable: "Approved By", constant: "approvedBy" };
    static urOfRv: Column = { readable: "UR of RV", constant: "urOfRv" };
    static rvRecalculationDate: Column = { readable: "RV Recalculation Date", constant: "rvRecalculationDate" };
    static rateToFineMultiple: Column = { readable: "Rate to Fine - Multiple", constant: "rateToFineMultiple" };
    static rateToFineMultipleFactor: Column = { readable: "Rate to Fine - Multiple Factor", constant: "rateToFineMultipleFactor" };
    static lastRpaEffectiveDate: Column = { readable: "Last RPA Effective Date", constant: "lastRpaEffectiveDate" };
    static lastRpaWoId: Column = { readable: "Last RPA WO ID", constant: "lastRpaWoId" };
    static rpaReversalEffectiveDate: Column = { readable: "RPA Reversal Effective Date", constant: "rpaReversalEffectiveDate" };
    static rpaReversalWoId: Column = { readable: "RPA Reversal WO ID", constant: "rpaReversalWoId" };
    static lastClzWoEffectiveDate: Column = { readable: "Last CLZ WO Effective Date", constant: "lastClzWoEffectiveDate" };
    static lastClzWoId: Column = { readable: "Last CLZ WO ID", constant: "lastClzWoId" };
    static lastRraEffectiveDate: Column = { readable: "Last RRA Effective Date", constant: "lastRraEffectiveDate" };
    static lastRraWoId: Column = { readable: "Last RRA WO ID", constant: "lastRraWoId" };
    static ratePackageChangePriorToReversalEffectiveDate: Column = { readable: "Rate Package Change Prior to Reversal Effective Date", constant: "ratePackageChangePriorToReversalEffectiveDate" };
    static ratePackageChangePriorToReversalWoId: Column = { readable: "Rate Package Change Prior to Reversal WO ID", constant: "ratePackageChangePriorToReversalWoId" };
    static lastStatusChangeDate: Column = { readable: "Last Status Change Date", constant: "lastStatusChangeDate" };
    static lastStatusChangeWoId: Column = { readable: "Last Status Change WO ID", constant: "lastStatusChangeWoId" };
    static pendingRvaStatus: Column = { readable: "Pending RVA Status", constant: "pendingRvaStatus" };
    static meterCalculationId: Column = { readable: "Meter Calculation Id", constant: "meterCalculationId" };
}
