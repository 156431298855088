import { UpdateMeterAttributesResponse } from './../update-meter-attributes/models/update-meter-response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { map, catchError } from 'rxjs/operators';

import { InventoryHttpService } from '../../../inventory-http.service';
import { RegularRateResponse } from './../regular-rate-adjustment/models/regular-rate-response';
import { WorkOrderHttpService } from './../../services/work-order-http.service';
import { OperationResultWithValidation, NextRatePackageDeciderValidationRepresentation } from '../../../core/api-responses/operation-result';

@Injectable()
export class InventoryFilterService {

  constructor(private http: InventoryHttpService, private workOrderHttp: WorkOrderHttpService, private snackBar: MatSnackBar) { }

  public getAreasFilterByZones(idList: string[], filterDate: Date): Observable<DropdownOptions[]> {
    const body = this.generateFilterRequestBody(idList, 'areas', filterDate);
    return this.http.post('/InventoryFilter/FilteredAreas', body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public getAreasFilterByZone(zoneId: string, filterDate: Date): Observable<DropdownOptions[]> {
    const body = this.generateFilterRequestBody([zoneId], 'areas', filterDate);
    return this.http.post(`/InventoryFilter/FilteredAreas/${zoneId}`, body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public getBlocksFilterByAreas(idList: string[], filterDate: Date): Observable<DropdownOptions[]> {
    const body = this.generateFilterRequestBody(idList, 'blocks', filterDate);
    return this.http.post('/InventoryFilter/FilteredBlocks', body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public getMetersFilterByBlocks(idList: string[], filterDate: Date): Observable<DropdownOptions[]> {
    const body = this.generateFilterRequestBody(idList, 'meters', filterDate);
    return this.http.post('/InventoryFilter/FilteredMeters', body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public getSpaces(idList: string[], nodeType: string, filterDate: Date, filterByContractType: boolean, filterByRequiredClosureAllowance: boolean, filterByLocationType: boolean, filterByFineAmount: boolean, contractTypeName: string,
    locationTypeName: string, fineAmountId: string, requiredClosureAllowanceType: string, previousActivationEffectiveDate: Date): Observable<UpdateMeterAttributesResponse> {
    const body = this.generateSpaceRequestBody(idList, nodeType, filterDate, filterByContractType, filterByRequiredClosureAllowance, filterByLocationType, filterByFineAmount, contractTypeName ? 'Reserved' : '', locationTypeName, fineAmountId, requiredClosureAllowanceType, previousActivationEffectiveDate);
    return this.http.post('/InventoryFilter/GetSpaces', body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public getSpacesForRegularRateAdjustment(idList: string[], nodeType: string, filterDate: Date, changeAmount: number): Observable<OperationResultWithValidation<RegularRateResponse, NextRatePackageDeciderValidationRepresentation >> {
    const body = this.generateRegularRateSpaceRequestBody(idList, nodeType, filterDate, changeAmount);
    return this.http.post('/InventoryFilter/rateAdjustmentSpaces', body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public getSpacesForDrilldownForCollections(nodeType: string, filterDate: Date, idList?: DropdownOptions[]): Observable<any> {
    if (nodeType === 'zone') {
      return this.http.post('/InventoryFilter/FilteredZones', filterDate)
        .pipe(
          map(response => response),
          catchError(error=>this.handleError(error))
        );
    }
    else if (nodeType === 'area') {
      const body = { effectiveDate: filterDate, parentList: idList };
      return this.http.post('/InventoryFilter/FilteredAreas/' + idList[0].key, body)
        .pipe(
          map(response => response),
          catchError(error=>this.handleError(error))
        );
    }
    else if (nodeType === 'block') {
      const body = { effectiveDate: filterDate, parentList: idList };
      return this.http.post('/InventoryFilter/FilteredBlocks/' + idList[0].key, body)
        .pipe(
          map(response => response),
          catchError(error=>this.handleError(error))
        );
    }
    else if (nodeType === 'meter') {
      const body = { effectiveDate: filterDate, parentList: idList };
      return this.http.post('/InventoryFilter/FilteredMeters', body)
        .pipe(
          map(response => response),
          catchError(error=>this.handleError(error))
        );
    }
  }

  private generateSpaceRequestBody(idList: string[], nodeType: string, filterDate: Date, filterByContractType: boolean, filterByRequiredClosureAllowance: boolean, filterByLocationType: boolean, filterByFineAmount: boolean, contractTypeName: string,
     locationTypeName: string, fineAmountId: string, requiredClosureAllowanceType: string, previousActivationEffectiveDate: Date): GetSpaceBodyForUpdateMeterAttributes {
    const idListToReturn = new Array<DropdownOptions>();

    idList.forEach(id => {
      idListToReturn.push(new DropdownOptions(id, nodeType + ' name'));
    });

    return new GetSpaceBodyForUpdateMeterAttributes(nodeType === 'zone' ? idListToReturn : [], nodeType === 'area' ? idListToReturn : [], nodeType === 'block' ? idListToReturn : [], nodeType === 'meter' ? idListToReturn : [], filterDate, filterByContractType, filterByRequiredClosureAllowance, filterByLocationType, filterByFineAmount, contractTypeName, locationTypeName, fineAmountId, requiredClosureAllowanceType, previousActivationEffectiveDate);
  }

  private generateRegularRateSpaceRequestBody(idList: string[], nodeType: string, filterDate: Date, changeAmount: number): GetRegularRateAdjustmentSpaceBody {
    const idListToReturn = new Array<DropdownOptions>();

    idList.forEach(id => {
      idListToReturn.push(new DropdownOptions(id, nodeType + ' name'));
    });

    return new GetRegularRateAdjustmentSpaceBody(nodeType === 'zone' ? idListToReturn : [], nodeType === 'area' ? idListToReturn : [], nodeType === 'block' ? idListToReturn : [], nodeType === 'meter' ? idListToReturn : [], filterDate, changeAmount);
  }

  public getMeterSpacesForManualCollection(filterDate: Date, idList: string[], nodeType: string): Observable<any> {
    var monthFirst = new Date(filterDate.getFullYear(), filterDate.getMonth(), 1);

    // Only get spaces on meter before first of month for collections
    const effectiveDate = moment(monthFirst).subtract(1, 'days');

    //collection date and effective date are different!
    const body = { effectiveDate: effectiveDate.toDate(), collectionDate: monthFirst };
    if (nodeType === 'meter') {
      body['MeterList'] = [idList];
    }
    else if (nodeType === 'block') {
      body['BlockList'] = [idList];
    }
    else if (nodeType === 'area') {
      body['AreaList'] = [idList];
    }
    else if (nodeType === 'zone') {
      body['ZoneList'] = [idList];
    }

    return this.http.post('/ManualCollections', body)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  private generateFilterRequestBody(idList: string[], nodeType: string, filterDate: Date): DropdownBody {
    const toReturn = new Array<DropdownOptions>();

    idList.forEach(id => {
      toReturn.push(new DropdownOptions(id, nodeType));
    });

    return new DropdownBody(toReturn, filterDate);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}

export class DropdownOptions {
  constructor(public key: string, public value: string) {
  }
}

export class DropdownBody {
  constructor(private parentList: DropdownOptions[], private effectiveDate: Date) {}
}

export class GetSpaceBodyForUpdateMeterAttributes {
  constructor(private zoneList: DropdownOptions[], private areaList: DropdownOptions[], private blockList: DropdownOptions[], private meterList: DropdownOptions[], private effectiveDate: Date,
    private filterByContractType: boolean, private filterByRequiredClosureAllowance: boolean, private filterByLocationType: boolean, private filterByFineAmount: boolean, private contractTypeName: string,
    private locationTypeName: string, private fineAmountId: string, private requiredClosureAllowanceType: string, private previousActivationEffectiveDate: Date) {}
}

export class GetRegularRateAdjustmentSpaceBody {
  constructor(private zoneList: DropdownOptions[], private areaList: DropdownOptions[], private blockList: DropdownOptions[], private meterList: DropdownOptions[], private effectiveDate: Date, private RatePackageChangeAmount: number) {}
}

export class InventoryFilterSpaceQueryApiModel {
  constructor(private zoneList: DropdownOptions[], private areaList: DropdownOptions[], private blockList: DropdownOptions[], private meterList: DropdownOptions[], private effectiveDate: Date) {}
}
