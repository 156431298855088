export class UpdateMeterAttributesMeterChange {
    meterId: string;

    meterDisplayId: string;

    currentContractTypeId: string;

    currentContractTypeName: string;

    changeContractTypeToReserved: boolean;

    currentLocationTypeId: string;

    currentLocationTypeName: string;

    newLocationTypeName: string;

    currentRequiredClosureAllowanceId: string;

    currentRequiredClosureAllowanceName: string;

    newRequiredClosureAllowanceName: string;

    effectiveDate: Date;
}
