// This class corresponds to src\app\trueup\true-up-detail-column-def.ts
export class Column {
    readable: string;
    constant: string;
}

export class CollectionsListColumnDef {
    // from cognos report

    static meterId:                 Column = { readable: 'Meter ID',                constant: 'meterid' };
    static dateOfCollection:        Column = { readable: 'Date of Collection',      constant: 'collection_date'};
    static timeOfCollection:        Column = { readable: 'Time of Collection',      constant: 'collection_time'};
    static dateReceived:            Column = { readable: 'Date Received',           constant: 'received_date'};
    static totalCollectionAmount:   Column = { readable: 'Total Collection Amount', constant: 'collection_amount' };
    static collectionType:          Column = { readable: 'Collection Type',         constant: 'collection_type' };
    static denominationName:        Column = { readable: 'Denomination Name',       constant: 'denomination_name' };
    static zone:                    Column = { readable: 'Zone',                    constant: 'zone' };
    static area:                    Column = { readable: 'Area',                    constant: 'area' };
    static blockId:                 Column = { readable: 'Block ID',                constant: 'blockid' };
    static parkingType:             Column = { readable: 'Parking Type',            constant: 'parking_type' };
    static collectionNumber:        Column = { readable: 'Collection Number',       constant: 'collection_number' };
    static numberOfCoins:           Column = { readable: 'Number Of Coins',         constant: 'number_of_coins' };
    static purchaseCount:           Column = { readable: 'Purchase Count',          constant: 'purchase_count' };
    static collectionGUID:          Column = { readable: 'Collection GUID',          constant: 'collection_guid' };
}

// this class corresponds to monthly-true-up-calculation-detail-base.ts
export class CollectionsDetailBase {
    numDate: number;
    numTime: number;

    meterId: string;

    dateOfCollection: number;

    timeOfCollection: number;

    dateReceived: string;

    totalCollectionAmount: number;

    collectionType: string;

    denominationName: string;

    zone: number;

    area: number;

    blockId: string;

    parkingType: string;

    collectionNumber: number;

    numberOfCoins: number;

    purchaseCount: number;

    collectionGUID: string;

}

// this class corresponds to CPM-TrueUp-FrontEnd\src\app\trueup\monthly-calculations\true-up-column-constants.ts
export class CollectionsColumnConstants {
    public static AllMeterColumns: Array<Column> = [
        CollectionsListColumnDef.meterId,
        CollectionsListColumnDef.dateOfCollection,
        CollectionsListColumnDef.timeOfCollection,
        CollectionsListColumnDef.dateReceived,
        CollectionsListColumnDef.totalCollectionAmount,
        CollectionsListColumnDef.collectionType,
        CollectionsListColumnDef.denominationName,
        CollectionsListColumnDef.zone,
        CollectionsListColumnDef.area,
        CollectionsListColumnDef.blockId,
        CollectionsListColumnDef.parkingType,
        CollectionsListColumnDef.collectionNumber,
        CollectionsListColumnDef.numberOfCoins,
        CollectionsListColumnDef.purchaseCount,
        CollectionsListColumnDef.collectionGUID
    ];

}
