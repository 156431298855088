import { CpmLazOnlyGuard } from './security/cpm-only-guard/cpm-laz-only-guard.component';
import { CpmCityOnlyGuard } from './security/cpm-only-guard/cpm-city-only-guard.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { CpmOnlyGuard } from './security/cpm-only-guard/cpm-only-guard.component';
import { InventoryManagementModule } from './inventory-management/inventory-management.module';
import { LoginComponent } from './security/login/login.component';
import { LoginService } from './security/login/login.service';
import { SystemStartTimeService } from './system-start-time.service';
import { TrueupModule } from './trueup/trueup.module';
import { UnauthorizedComponent } from './security/unauthorized/unauthorized.component';
import { WorkOrdersModule } from './work-orders/work-orders.module';
import { ReportsModule } from './reports/reports.module';
import { AuthGuard } from './security/auth.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UnauthorizedComponent
  ],
  imports: [
    AdminModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    InventoryManagementModule,
    TrueupModule,
    WorkOrdersModule,
    ReportsModule
  ],
  exports: [],
  providers: [
    LoginService,
    SystemStartTimeService,
    //{
    //  provide: ErrorHandler,
    //  useClass: GlobalErrorHandler
    //},
    CpmOnlyGuard,
    CpmLazOnlyGuard,
    CpmCityOnlyGuard,
    AuthGuard
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
