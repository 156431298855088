import { FiscalUnitConverter } from './../../meters/meter-true-up/models/month-name-dictionary';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Operation, Restriction } from '../../../app-constants';
import { HoursOfRestriction } from '../models/hours-of-restriction';
import { HoursOfRestrictionService } from '../services/hours-of-restriction.service';
import { HoursOfOperation } from '../models/hours-of-operation';
import { HoursOfOperationService } from '../services/hours-of-operation.service';
import { RatePackage } from '../models/rate-package';
import { RatePackageService } from '../services/rate-package.service';
import { ScheduleBlocks } from '../models/schedule-blocks';

import { RatePackageConfirmationDialogComponent } from '../rate-package-confirmation.component';
import { RestrictionTypes, DaysOfWeek } from './rate-package-constants';
import { RateStep } from '../models/rate-step';
import { DailyKeyValuePair, SheduleBlockDayAndValue } from './../models/daily-key-value-for-schedule-blocks';
import { HoursOfBase } from '../models/hours-of-base';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-rate-package-create',
  templateUrl: './rate-package-create.component.html',
  styleUrls: ['./rate-package-create.component.css']
})
export class RatePackageCreateComponent implements OnInit {
  data: any;
  ratePackage: RatePackage;
  hoursOfRestriction: HoursOfRestriction[];
  hoursOfOperation: HoursOfOperation[];
  ratePackageNames: string[];
  selectedHoursOfOperation: HoursOfOperation;
  selectedHoursOfRestriction: HoursOfRestriction;
  displayCreateHoursOfSchedule: boolean;
  ratePackageName: string;
  ratePackageDescription: string;
  loading: boolean;
  parentHoOId: string;

  mode: string;
  isRegularRateAdjustment = false;

  rateSteps: RateStep[];
  scheduleBlocks: any[];
  hoursOfScheduleName: string;
  hoursOfScheduleDescription: string;
  containsInvalidRateStep: boolean;

  repeatedName = false;
  invalidSeasonal = false;
  invalidSeasonalErrorMessage: string;

  descriptionOptions = [];

  constructor(
    private hoursOfRestrictionService: HoursOfRestrictionService,
    private hoursOfOperationService: HoursOfOperationService,
    private ratePackageService: RatePackageService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.loading = true;

    this.displayCreateHoursOfSchedule = false;
    this.mode = 'Rate Package';

    this.getHoursOfOperation(null);
    this.getHoursOfRestriction(null);
    this.getRatePackages();

    this.containsInvalidRateStep = true;

    this.rateSteps = new Array<RateStep>();
  }

  confirmValidInput() {
    this.containsInvalidRateStep = false;
      this.rateSteps.forEach(step => {
        if (step.containsInvalidTimeline
          || step.containsNullValues
          || step.zeroDaysSelected
          || !this.hoursOfScheduleName
          || !this.hoursOfScheduleDescription
          || step.periodOfStay === 0) {
          this.containsInvalidRateStep = true;
        }
      });
  }

  updateChart() {
    if (this.selectedHoursOfOperation) {
      this.ratePackageDescription = this.selectedHoursOfOperation.description;
    }
    if (this.selectedHoursOfRestriction) {
      this.ratePackageDescription = this.ratePackageDescription += ' ' + this.selectedHoursOfRestriction.description;
    }

    this.refreshDisplayRatePackage(this.selectedHoursOfOperation, this.selectedHoursOfRestriction)
      .subscribe(updatedRatePackage => {
        this.validateRatePackageForConfirm(updatedRatePackage);
      });

    document.getElementById('create-rate-package').tabIndex = 0;
    document.getElementById('create-rate-package').focus();

  }

  hourSelected(h) {
    if ((this.selectedHoursOfOperation && this.selectedHoursOfOperation.id === h.id) ||
      (this.selectedHoursOfRestriction && this.selectedHoursOfRestriction.id === h.id)) {
      event.preventDefault();
    }
  }

  createRatePackage() {
    const ratePackage = new RatePackage();
    ratePackage.hoursOfOperation = this.selectedHoursOfOperation;
    if (this.selectedHoursOfRestriction) {
      ratePackage.hoursOfRestriction = this.selectedHoursOfRestriction;
    }
    else {
      ratePackage.hoursOfRestriction = null;
    }
    this.ratePackageService.postNewRatePackage(ratePackage);
  }

  clearHoursOfRestriction() {
    this.selectedHoursOfRestriction = null;
    this.updateChart();
  }

  openCreateHoursOfSchedule(mode) {
    this.mode = mode;
    this.displayCreateHoursOfSchedule = true;
    this.containsInvalidRateStep = false;
    this.refreshDisplayRatePackage();
    this.containsInvalidRateStep = true;
    this.createNewRateStep();
    this.repeatedName = false;
  }

  private refreshDisplayRatePackage(hoursOfOperation?: HoursOfOperation, hoursOfRestriction?: HoursOfRestriction): Observable<RatePackage> {

    var ratePackageNonSeasonal = new RatePackage();
    ratePackageNonSeasonal.hoursOfOperation = hoursOfOperation;
    ratePackageNonSeasonal.hoursOfRestriction = hoursOfRestriction;

    var fiscalYear = FiscalUnitConverter.GetFiscalYear(new Date());

    return this.ratePackageService.getSeasonalRatePackageWithYear(ratePackageNonSeasonal, fiscalYear)
      .pipe(
        map(result => {
          this.ratePackage = result;
          return ratePackageNonSeasonal;
        })
      );
  }

  openCloneHoursOfSchedule(mode) {
    if (mode) {
      this.mode = mode;
    }
    else {
      this.mode = 'Operation';
      this.parentHoOId = this.selectedHoursOfOperation.id;
      this.isRegularRateAdjustment = true;
    }

    this.displayCreateHoursOfSchedule = true;
    this.refreshDisplayRatePackage(this.selectedHoursOfOperation);
    this.hoursOfScheduleDescription = '';
    this.containsInvalidRateStep = true;
    this.descriptionOptions = [this.selectedHoursOfOperation.description];
    this.repeatedName = false;

    this.buildRateStepsFromHoO(this.selectedHoursOfOperation);
  }

  buildRateStepsFromHoO(hoursOfOperation: HoursOfOperation) {
    hoursOfOperation.weeklyDetail.forEach(hoO => {
      const hoursOfOperationDay = hoO.key.toLowerCase();
      const hoursOfOperationDetail = hoO.value;

      hoursOfOperationDetail.scheduleDetails.forEach(hoODetail => {
        const rateStep = new RateStep();
        rateStep.rate = hoODetail.rate;
        rateStep.periodOfStay = hoODetail.periodOfStay / 60;
        rateStep.startTime = this.convertTimeNumberToString(hoODetail.startTimeHours) + ':' + this.convertTimeNumberToString(hoODetail.startTimeMinutes) + ':00';
        rateStep.stopTime = this.convertTimeNumberToString(hoODetail.endTimeHours) + ':' + this.convertTimeNumberToString(hoODetail.endTimeMinutes) + ':00';
        rateStep.monday = false;
        rateStep.tuesday = false;
        rateStep.wednesday = false;
        rateStep.thursday = false;
        rateStep.friday = false;
        rateStep.saturday = false;
        rateStep.sunday = false;
        rateStep.selectAll = false;
        rateStep.containsInvalidTimeline = false;
        rateStep.zeroDaysSelected = false;
        rateStep.containsNullValues = false;
        rateStep.restrictionType = null;
        rateStep.seasonalDayEnd = hoODetail.seasonalDayEnd;
        rateStep.seasonalMonthEnd = hoODetail.seasonalMonthEnd;
        rateStep.seasonalDayStart = hoODetail.seasonalDayStart;
        rateStep.seasonalMonthStart = hoODetail.seasonalMonthStart;
        rateStep.seasonalHolidayStart = hoODetail.seasonalHolidayStart;
        rateStep.seasonalHolidayEnd = hoODetail.seasonalHolidayEnd;
        rateStep.isSeasonal = rateStep.seasonalDayStart !== null;
        rateStep.isSeasonalHoliday = rateStep.seasonalHolidayStart !== null;

        const existingRateStep = this.rateSteps.filter(step => {
          return (step.rate === rateStep.rate
            && step.startTime === rateStep.startTime
            && step.stopTime === rateStep.stopTime
            && step.seasonalDayStart === rateStep.seasonalDayStart
            && step.seasonalMonthStart === rateStep.seasonalMonthStart
            && step.seasonalDayEnd === rateStep.seasonalDayEnd
            && step.seasonalMonthEnd === rateStep.seasonalMonthEnd
            && step.seasonalHolidayStart === rateStep.seasonalHolidayStart
            && step.seasonalHolidayEnd === rateStep.seasonalHolidayEnd
            );
        });

        if (existingRateStep.length > 0) {
          existingRateStep[0][hoursOfOperationDay] = true;
        }
        else {
          rateStep[hoursOfOperationDay] = true;
          this.rateSteps.push(rateStep);
        }
      });
    });

    this.mapRateStepsToGraph();
  }

  convertTimeNumberToString(number: number): string {
    return number > 9 ? number.toString() : '0' + number;
  }

  createNewRateStep() {
    const newRateStep = new RateStep();
    newRateStep.rate = 1;
    newRateStep.periodOfStay = 1;
    newRateStep.startTime = '06:00:00';
    newRateStep.stopTime = '18:00:00';
    newRateStep.monday = true;
    newRateStep.tuesday = true;
    newRateStep.wednesday = true;
    newRateStep.thursday = true;
    newRateStep.friday = true;
    newRateStep.saturday = (this.mode === Operation);
    newRateStep.sunday = false;
    newRateStep.selectAll = false;
    newRateStep.containsInvalidTimeline = false;
    newRateStep.zeroDaysSelected = false;
    newRateStep.containsNullValues = false;
    newRateStep.restrictionType = RestrictionTypes[0];

    this.rateSteps.push(newRateStep);

    this.mapRateStepsToGraph();
  }

  mapRateStepsToGraph() {
    const today = new Date();

    const hoursOfOperationWeeklyDetail: DailyKeyValuePair[] = [];
    const hoursOfRestrictionWeeklyDetail: DailyKeyValuePair[] = [];
    this.scheduleBlocks = new Array<ScheduleBlocks>();

    DaysOfWeek.forEach(day => {
      var dailySchedule: DailyKeyValuePair = new DailyKeyValuePair();
      dailySchedule.key = day;
      dailySchedule.value = new SheduleBlockDayAndValue();
      dailySchedule.value.day = day;
      dailySchedule.value.scheduleDetails = [];

      this.rateSteps.forEach(rateStep => {
        const rateStepStart = rateStep.startTime.split(':');
        const rateStepStop = rateStep.stopTime.split(':');

        if (!rateStep[day.toLowerCase()]) {
          return;
        }

        var newScheduleBlock:ScheduleBlocks = new ScheduleBlocks();
        newScheduleBlock.startTimeHours = parseInt(rateStepStart[0]);
        newScheduleBlock.startTimeMinutes = parseInt(rateStepStart[1]);
        newScheduleBlock.endTimeHours = parseInt(rateStepStop[0]);
        newScheduleBlock.endTimeMinutes = parseInt(rateStepStop[1]);
        newScheduleBlock.isSeasonal = rateStep.isSeasonal;
        newScheduleBlock.seasonalDayStart = rateStep.seasonalDayStart;
        newScheduleBlock.seasonalDayEnd = rateStep.seasonalDayEnd;
        newScheduleBlock.seasonalMonthStart = rateStep.seasonalMonthStart;
        newScheduleBlock.seasonalMonthEnd = rateStep.seasonalMonthEnd;
        newScheduleBlock.seasonalHolidayStart = rateStep.seasonalHolidayStart;
        newScheduleBlock.seasonalHolidayEnd = rateStep.seasonalHolidayEnd;

        if (this.mode === Restriction) {
          newScheduleBlock.restrictionType = rateStep.restrictionType;
        }
        else {
          newScheduleBlock.rate = rateStep.rate;
          newScheduleBlock.periodOfStay = rateStep.periodOfStayInMinutes;
        }

        dailySchedule.value.scheduleDetails.push(newScheduleBlock);
      });

      if (this.mode === Operation) {
        hoursOfOperationWeeklyDetail.push(dailySchedule);
      }
      else {
        hoursOfRestrictionWeeklyDetail.push(dailySchedule);
      }
    });

    var hoursOfOperation = new HoursOfOperation();
    hoursOfOperation.weeklyDetail = hoursOfOperationWeeklyDetail;

    var hoursOfRestriction = new HoursOfRestriction();
    hoursOfRestriction.weeklyDetail = hoursOfRestrictionWeeklyDetail;

    this.refreshDisplayRatePackage(hoursOfOperation, hoursOfRestriction)
      .subscribe(updatedRatePackage => {
        this.validateRatePackageForConfirm(updatedRatePackage);
      });
  }

  cancelHoursOfComponent() {
    this.refreshDisplayRatePackage();
    this.rateSteps = [];
    this.displayCreateHoursOfSchedule = false;
    this.selectedHoursOfOperation = this.hoursOfOperation[0];
    this.selectedHoursOfRestriction = null;

    this.clearHoursOfRestriction();
    this.mode = 'Rate Package';
    this.hoursOfScheduleName = '';
    this.hoursOfScheduleDescription = '';
    this.parentHoOId = '';
  }

  validateRatePackageForConfirm(ratePackage:RatePackage) {
    this.ratePackageService.validateSeasonalRatePackage(ratePackage)
      .subscribe(response => {
        this.invalidSeasonal = false;
      }, error => {
        this.invalidSeasonal = true;
        this.invalidSeasonalErrorMessage = error._body;
      });
  }

  confirmSave() {
    let name = '';
    let description = '';
    let dialogMode = '';
    if (this.mode !== 'Rate Package') {
     dialogMode = 'Hours of ' + this.mode;
     name = this.hoursOfScheduleName;
     description = this.hoursOfScheduleDescription;
    }
    else {
      dialogMode = 'Rate Package';
      name = this.ratePackageName;
      description = this.ratePackageDescription;
    }
        this.dialog.open(RatePackageConfirmationDialogComponent, {

          data: {
            mode: dialogMode,
            name: name,
            description: description,
          },
          height: '200px',
          width: '400px'
        }).afterClosed().subscribe(result => {
          if (result) {
            if (this.mode !== 'Rate Package' && this.hoursOfScheduleDescription === this.descriptionOptions[0]) {
              this.openSnackBar('The description cannot be the same as the previous one.', '');
              return;
            }
            this.openSnackBar('Saving...', '');
            if (this.mode === 'Rate Package') {
              const ratePackageToSave = new RatePackage();
              ratePackageToSave.name = this.ratePackageName;
              ratePackageToSave.description = this.ratePackageDescription;
              ratePackageToSave.hoursOfOperation = this.selectedHoursOfOperation;
              ratePackageToSave.hoursOfRestriction = this.selectedHoursOfRestriction;
              ratePackageToSave.hoursOfOperationId = this.selectedHoursOfOperation.id;
              if (this.selectedHoursOfRestriction) {
                ratePackageToSave.hoursOfRestrictionId = this.selectedHoursOfRestriction.id;
              }
              //TODO check valid before saving, one last time
              this.ratePackageService.postNewRatePackage(ratePackageToSave)
              .subscribe(response => {
                this.openSnackBar('Saved Rate Package', '');
                this.router.navigate(['inventorymanagement/ratepackages']);
              });
            }
            else {
              this.createNewHoursOfSchedule(this.mode);
            }
          }
        },
        error => {
          this.openSnackBar('Error: ' + error, 'X');
        });
  }

  checkNameValidation() {
    if (this.mode === 'Operation') {
        const repeatedNames = this.hoursOfOperation.filter(operation => {
          return this.hoursOfScheduleName.toUpperCase().trim() === operation.name.toUpperCase().trim();
        });

        this.repeatedName = repeatedNames.length > 0;
     }
     else if (this.mode === 'Restriction') {
        const repeatedNames = this.hoursOfRestriction.filter(restriction => {
          return this.hoursOfScheduleName.toUpperCase().trim() === restriction.name.toUpperCase().trim();
        });

        this.repeatedName = repeatedNames.length > 0;
     }
     else {
        const repeatedNames = this.ratePackageNames.filter(ratePackage => {
          return this.ratePackageName.toUpperCase().trim() === ratePackage.toUpperCase().trim();
        });
        this.repeatedName = repeatedNames.length > 0;
     }
  }

  createNewHoursOfSchedule(mode) {
    var newHoursOfSchedule: HoursOfBase;
    if (mode === Operation) {
      newHoursOfSchedule = new HoursOfOperation();
      if (this.parentHoOId) {
        (newHoursOfSchedule as HoursOfOperation).parentHoOId = this.parentHoOId;
      }
    }
    else {
      newHoursOfSchedule = new HoursOfRestriction();
    }

    newHoursOfSchedule.name = this.hoursOfScheduleName;
    newHoursOfSchedule.description = this.hoursOfScheduleDescription;
    newHoursOfSchedule.weeklyDetail = [];

    this.scheduleBlocks = new Array<any>();
      this.rateSteps.forEach(rateStep => {
        DaysOfWeek.forEach(day => {
          if (rateStep[day.toLowerCase()]) {

          const rateStepStart = rateStep.startTime.split(':');
          const rateStepStop = rateStep.stopTime.split(':');

            if (this.mode === Restriction) {
              const newScheduleBlock = new Object({
                startTimeHours: parseInt(rateStepStart[0]),
                startTimeMinutes: parseInt(rateStepStart[1]),
                endTimeHours: parseInt(rateStepStop[0]),
                endTimeMinutes: parseInt(rateStepStop[1]),
                restrictionType: rateStep.restrictionType,
                dailyScheduleId: day,
                seasonalDayStart: rateStep.seasonalDayStart,
                seasonalDayEnd: rateStep.seasonalDayEnd,
                seasonalMonthStart: rateStep.seasonalMonthStart,
                seasonalMonthEnd: rateStep.seasonalMonthEnd,
                seasonalHolidayStart: rateStep.seasonalHolidayStart,
                seasonalHolidayEnd: rateStep.seasonalHolidayEnd
              });
              this.scheduleBlocks.push(newScheduleBlock);
            }
            else {
              const newScheduleBlock = new Object({
                startTimeHours: parseInt(rateStepStart[0]),
                startTimeMinutes: parseInt(rateStepStart[1]),
                endTimeHours: parseInt(rateStepStop[0]),
                endTimeMinutes: parseInt(rateStepStop[1]),
                rate: rateStep.rate,
                periodOfStay: rateStep.periodOfStayInMinutes,
                dailyScheduleId: day,
                seasonalDayStart: rateStep.seasonalDayStart,
                seasonalDayEnd: rateStep.seasonalDayEnd,
                seasonalMonthStart: rateStep.seasonalMonthStart,
                seasonalMonthEnd: rateStep.seasonalMonthEnd,
                seasonalHolidayStart: rateStep.seasonalHolidayStart,
                seasonalHolidayEnd: rateStep.seasonalHolidayEnd
              });
              this.scheduleBlocks.push(newScheduleBlock);
            }
          }
        });
      });

      this.rateSteps = [];

      DaysOfWeek.forEach(day => {
        const newValue = new SheduleBlockDayAndValue();
        newValue.day = day;
        newValue.scheduleDetails = this.scheduleBlocks.filter(block => block.dailyScheduleId === day);

        const newDailySchedule = new DailyKeyValuePair ();
        newDailySchedule.key = day,
        newDailySchedule.value = newValue;

        newHoursOfSchedule.weeklyDetail.push(newDailySchedule);
      });

      this.displayCreateHoursOfSchedule = false;
      this.mode = 'Rate Package';
      this.hoursOfScheduleDescription = '';

      if (mode === Operation) {
        this.hoursOfOperationService.setHoursOfOperation(newHoursOfSchedule as HoursOfOperation)
        .subscribe(result => {
          this.openSnackBar('Saved HoO ' + this.hoursOfScheduleName, '');
          this.hoursOfScheduleName = '';

          this.getHoursOfOperation(JSON.parse(result._body).id);
        });
      }
      else {
        this.hoursOfRestrictionService.setHoursOfRestriction(newHoursOfSchedule as HoursOfRestriction)
        .subscribe(result => {
          this.openSnackBar('Saved HoR ' + this.hoursOfScheduleName, '');
          this.hoursOfScheduleName = '';

          this.getHoursOfRestriction(result);
          this.selectedHoursOfOperation = this.hoursOfOperation[0];
        },
        error => {
          this.openSnackBar('Error: ' + error, 'X');
        });
      }
  }

  getHoursOfOperation(hoursOfOperationId) {
    this.hoursOfOperationService.getAllHoursOfOperation().subscribe(
      result => {
        this.loading = false;

        const sortedHoO = result.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          else if (a.name > b.name) { return 1; }
          else { return 0; }
        });

        this.hoursOfOperation = sortedHoO;
        this.selectedHoursOfOperation = null;

        if (hoursOfOperationId) {
          const newHoO = result.filter(hoO => {
            if (hoO.id === hoursOfOperationId) {
              return true;
            }
          });

          if (newHoO) {
            this.selectedHoursOfOperation = newHoO[0];
          }
        }
        else {
          this.selectedHoursOfOperation = result[0];
        }

        this.selectedHoursOfRestriction = null;

        this.updateChart();
      },
      error => {
          console.log(error);
      });
  }

  getHoursOfRestriction(hoursOfRestrictionId) {

    this.hoursOfRestrictionService.getAllHoursOfRestriction().subscribe(
      result => {

        const sortedHoR = result.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          else if (a.name > b.name) { return 1; }
          else { return 0; }
        });

        this.hoursOfRestriction = sortedHoR;

        if (hoursOfRestrictionId) {
          const newHoR = result.filter(hoR => {
            if (hoR.id === hoursOfRestrictionId) {
              return true;
            }
          });

          if (newHoR) {
            this.selectedHoursOfRestriction = newHoR[0];
          }
        }
        else {
          this.selectedHoursOfRestriction = null;
        }
      },
      error => {
          console.log(error);
      });
  }

  getRatePackages() {
    this.ratePackageService.getAllRatePackageNames().subscribe(
            result => {
                this.ratePackageNames = result;
            },
            error => {
                console.log('error');
            }
        );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 10000,
    });
  }
}
