import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EditSpaceDialogComponent } from './../edit-space-dialog/edit-space-dialog.component';
import { RatePackageDetailComponent } from './../../rate-packages/rate-package-detail/rate-package-detail.component';

import { Component, ErrorHandler, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meter } from '../../meters/meter';
import { MeterService } from '../../meters/meters.service';
import { RatePackage } from '../../rate-packages/models/rate-package';
import { Space } from '../space';
import { RatePackageService } from './../../rate-packages/services/rate-package.service';
import { SpaceService } from './../spaces.service';
import { MeterStatusConstants } from '../../meters/meter-constants';
import { TokenCache } from '../../../security/token-cache';
import { BlockService } from '../../blocks/blocks.service';
import { MonthlyTrueUpCalculationFilterRepresentation } from '../../../trueup/monthly-calculations/monthly-true-up-calculation-filter-representation-base';
declare var google: any;

@Component({
    selector: 'app-space-detail',
    templateUrl: './space-detail.component.html',
    styleUrls: ['./space-detail.component.css']
})

export class SpaceDetailComponent implements OnInit {

    constructor(private spaceService: SpaceService, private meterService: MeterService, private blockService: BlockService,
        private ratePackageService: RatePackageService, private route: ActivatedRoute,
        private errorHandler: ErrorHandler,
        private dialog: MatDialog, private snackBar: MatSnackBar, private tokenCache: TokenCache) {
        }

    space: Space;
    meter: Meter;
    ratePackage: RatePackage;
    map: any;
    noRatePackageFound = false;
    meterTempRemoved = false;
    physicalBlockId = '';
    errorMessage: string;

    ratePackageTabName = 'Rate Package';
    trueUpTabName = 'True Up';
    closuresTabName = 'Closures';

    @ViewChild(RatePackageDetailComponent, {static: true}) ratePackageDetailComponent: RatePackageDetailComponent;

    dialogRef: MatDialogRef<EditSpaceDialogComponent>;

    showEditSpaceButton: boolean;

    showTrueUpTab: boolean;

    filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation;

    ngOnInit(): void {
        this.showEditSpaceButton = this.tokenCache.checkForCPMUser() || this.tokenCache.checkForLAZUser();
        this.showTrueUpTab = this.tokenCache.checkForCPMUser() || this.tokenCache.checkForCityUser();
    }

    ngAfterViewInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.setSpace(id);

    }

    setSpace(id: string) {
        this.spaceService.getSpaceById(id).subscribe(
        result => {
            this.space = result;
            this.filterRepresentation = new MonthlyTrueUpCalculationFilterRepresentation();
            this.filterRepresentation.SpaceId = this.space.displayId;

            this.blockService.getBlockById(this.space.physicalBlockId).subscribe(
                  result => {
                      this.physicalBlockId = result.displayId;
                  },
                  error => {
                    this.errorMessage = error._body;
                      this.errorHandler.handleError(error);
                  }
            );

            this.meterService.getMeterById(result.currentMeterId).subscribe(
            result => {
                this.meter = result;
                if (this.meter.currentStatus === MeterStatusConstants.inactive && this.meter.isPhysicallyRemoved) {
                    this.meterTempRemoved = true;
                } else {
                    this.meterTempRemoved = false;
                }
            },
            error => {
                this.errorHandler.handleError(error);
            });
        },
        error => {
            this.errorHandler.handleError(error);
        });
    }

    loadMap() {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode( { 'location': {
          'lat': this.meter.location.latitude,
          'lng': this.meter.location.longitude
          }
        }, function(results, status) {
            if (status === 'OK') {
                const mapProp = {
                    center: results[0].geometry.location,
                    zoom: 15
                };

                this.map = new google.maps.Map(document.getElementById('map'), mapProp);

                const marker = new google.maps.Marker({
                    map: this.map,
                    position: results[0].geometry.location
                });
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    editSpace() {
        this.dialogRef = this.dialog.open(EditSpaceDialogComponent, {
            height: '240px',
            width: '400px',
          });

          // Make a copy of space object and pass that to dialog, so what's modified in space doesn't affect what's displaying on Space detail
          this.dialogRef.componentInstance.space = JSON.parse(JSON.stringify(this.space));

          this.dialogRef.afterClosed().subscribe(result => {
           if (result) {
               this.spaceService.updateSpaceAttributes(result).subscribe(
                   result => {
                       if (result) {
                           this.space.displayId = result.displayId;
                           this.snackBar.open('Successfully updated space attributes', '', <MatSnackBarConfig>{
                            duration: 2000,
                          });
                       }
                   },
                   error => {
                    this.snackBar.open(error['_body'], 'X');
                   });
           }
          });
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
      if (tabChangeEvent.tab.textLabel === this.ratePackageTabName) {
        //load the rate package if we don't have it yet
        if (!this.ratePackage) {
          this.ratePackageService.getRatePackageById(this.space.currentRatePackageId).subscribe(
            result => {
              this.ratePackage = result;
              this.noRatePackageFound = false;
            },
            error => {
              this.errorHandler.handleError(error);
              this.noRatePackageFound = true;
            }
          );
        }
      }
    }
}
