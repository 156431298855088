export class ScheduleBlocks {

    dailyScheduleId?: string;
    startTimeHours: number;
    startTimeMinutes: number;
    endTimeHours: number;
    endTimeMinutes: number;
    startTime: Date;
    endTime: Date;
    seasonalRateStart?: Date;
    seasonalRateEnd?: Date;
    rate?: number;
    periodOfStay?: number;
    restrictionType?: string;
    isSeasonal: boolean;
    seasonalMonthStart?: number;
    seasonalMonthEnd?: number;
    seasonalDayStart?: number;
    seasonalDayEnd?: number;
    seasonalHolidayStart?: string;
    seasonalHolidayEnd?: string;
}
