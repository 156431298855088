import { WorkOrderAction } from './models/work-order-action';

export class ProjectedCompletionDate {
    public getProjectedCompletionDate(workOrderActions: Array<WorkOrderAction>): Date {
        if(workOrderActions.length == 1) {
            return workOrderActions[0].projectedCompletionDate;
        }
        else {
            var projectedCompletionDates = new Array<Date>();
            workOrderActions.forEach(action => {
                if(action.projectedCompletionDate != null) {
                    projectedCompletionDates.push(new Date(action.projectedCompletionDate));
                }
            });
            if(projectedCompletionDates.length > 0) {
                var earliestProjectedCompletionDate = projectedCompletionDates.sort((a,b) => {
                    if(a.getUTCFullYear() != b.getUTCFullYear()) { return a.getUTCFullYear() - b.getUTCFullYear() }
                    else if(a.getUTCMonth() != b.getUTCMonth()) { return a.getUTCMonth() - b.getUTCMonth() }
                    else { return a.getUTCDate() - b.getUTCDate() }
                });
                return earliestProjectedCompletionDate[0];
            }
            else {
                return null;
            }
        }
    }
}