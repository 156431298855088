exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-row {\r\n  height:40px !important;\r\n  min-height:40px !important;\r\n}\r\n\r\n.mat-card{\r\n  padding-left: 0px;\r\n  padding-right: 0px;\r\n}\r\n\r\n.flex-container {\r\n  width: -webkit-max-content;\r\n  width: -moz-max-content;\r\n  width: max-content;\r\n}\r\n\r\napp-time-period-picker{\r\n  padding-bottom: 20px;\r\n}\r\n\r\n.mat-header-row {\r\n  font-size: 75%;\r\n  border-top: .5px solid lightgray;\r\n  display: table-row;\r\n}\r\n\r\n.mat-table {\r\n  display: table;\r\n  min-width: 1250px;\r\n  overflow-x: auto;\r\n}\r\n\r\n.mat-row {\r\n  max-height: none !important;\r\n  height: auto !important;\r\n  display: table-row;\r\n}\r\n\r\n.mat-header-cell, .mat-cell {\r\n  display: table-cell;\r\n}\r\n\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();