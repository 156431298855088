import { Component, OnInit } from '@angular/core';
import { TokenCache } from '../../security/token-cache';

@Component({
  selector: 'app-work-orders-lists',
  templateUrl: './work-orders-lists.component.html',
  styleUrls: ['./work-orders-lists.component.css']
})
export class WorkOrdersListsComponent implements OnInit {

  searchText: string;
  selectedIndex: number;
  mode: string;
  isCityUser: boolean;

  constructor(private tokenCache: TokenCache) { }

  ngOnInit() {
    this.isCityUser = this.tokenCache.checkForCityUser();
  }


}
