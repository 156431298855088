export class RegularRateAdjustmentSpaceChange {

  currentRatePackageId: string;

  currentRatePackageDisplayId: string;

  effectiveDate: Date;

  meterId: string;

  meterDisplayId: string;

  newRatePackageId: string;

  newRatePackageDisplayId: string;

  spaceId: string;

  spaceDisplayId: string;

  inventoryChangeId: string

  constructor(currentRatePackageDisplayId: string, currentRatePackageId: string, effectiveDate: Date, 
    meterId: string, meterDisplayId: string, spaceId: string, spaceDisplayId: string, 
    newRatePackageDisplayId: string, newRatePackageId: string, spaceCount: number, inventoryChangeId: string) {
    this.currentRatePackageDisplayId = currentRatePackageDisplayId;
    this.currentRatePackageId = currentRatePackageId;
    this.effectiveDate = effectiveDate;
    this.meterId = meterId;
    this.meterDisplayId = meterDisplayId;
    this.newRatePackageDisplayId = newRatePackageDisplayId;
    this.newRatePackageId = newRatePackageId;
    this.spaceId = spaceId;
    this.spaceDisplayId = spaceDisplayId;
    this.inventoryChangeId = inventoryChangeId;
  }
}
