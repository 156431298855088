import { Injectable } from '@angular/core';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { Observable ,  Subject } from 'rxjs';
import { FileDownloadResult } from '../../core/models/file-download-result';
import { ClosureFilterRepresentation } from '../closure/closure-configurator/closure-filter-representation';
import { ClosureQueryStringBuilder } from './closure-query-string-builder';
import { setTimeout } from 'timers';
import { TokenCache } from '../../security/token-cache';
// import { Http, Headers, ConnectionBackend, RequestOptions, RequestOptionsArgs, Response, ResponseContentType } from '@angular/http';
import { HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ClosureReportingService extends ReportingHttpService {

  constructor(
    backend: HttpBackend, options: HttpRequestOptions, tokenCache: TokenCache, fileService: FileDownloadService)
  { super(backend, options, tokenCache, fileService) }

  public RequestQuarterlyClosureReport(filter: ClosureFilterRepresentation) {
    let filterQueryString = '';

    if (filter) {
      filterQueryString = ClosureQueryStringBuilder.createFilterQueryString(filter);
    }

    return this.post(`/reports/closures/${filter.FiscalYear}/${filter.FiscalQuarter}/?${filterQueryString}`, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  //Correlates to QueueItemTypeMetaData in Reporting API
  public static CLOSURE_KEY: string = "CLOSURE";

  public DownloadClosureReport(key: string) {
    return super.DownloadReport(ClosureReportingService.CLOSURE_KEY, key, 0);
  }

}
