import { StringExtensions } from './../../string-extensions';
import { Component, ViewEncapsulation, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ActionConstants } from '../action/action-constants';
import { ActionField } from '../models/action-field';
import { ActionFieldOption } from '../models/action-field-option';
import { FilterOperator } from './../../core/query-strings/query-string-builder';
import { TempRemovalStatusConstants, MeterStatusConstants, ContractTypeConstants } from './../../inventory-management/meters/meter-constants';
import { TokenCache } from './../../security/token-cache';
import { Block } from '../../inventory-management/blocks/block';
import { BlockService } from './../../inventory-management/blocks/blocks.service';
import { InstallMeterConstants, InstallSpaceConstants, RemoveSpaceConstants, RelocateMeterConstants, ChangeRatePackageConstants, UpdateMeterAttributesConstants } from './action-field-constants';
import { InventorySelectDialogComponent } from './inventory-select-dialog/inventory-select-dialog.component';
import { FieldConstants } from './field-constants';
import { Meter } from './../../inventory-management/meters/meter';
import { MeterService } from './../../inventory-management/meters/meters.service';
import { MeterSummary, RatePackageIdSummary } from '../../inventory-management/meters/meter-summary';
import { RatePackage } from './../../inventory-management/rate-packages/models/rate-package';
import { RatePackageSelectDialogComponent } from './rate-package-select-dialog/rate-package-select-dialog.component';
import { WorkorderActionJsonConstants } from './workorder-action-json-constants';

// Validators
import { ShiftSpaceSameMeter, ShiftingZeroSpaces, ShiftingLastSpace, ShiftSpaceToDifferentBlocks, RemovingLastSpace, MoreThanTwentySpaces, MeterIdDoesNotExist, ShiftingSpaceTooManySpaces, RemovingZeroSpaces, RemovingTooManySpaces, InstallingZeroSpaces, MeterIdTooShort, ChangeRatePackageTooManySpaces, ChangeRatePackageZeroSpaces, ShiftingToDifferentContractType, InvalidRatePackageForMeter } from '../models/warning-constants';
import { InventoryFilterRepresentation } from '../../inventory-management/inventory-filter/inventory-filter-representation';
import { WorkOrder } from '../models/work-order';

@Component({
  selector: 'app-action-field',
  templateUrl: './action-field.component.html',
  styleUrls: ['./action-field.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ActionFieldComponent implements OnChanges {

  @Input() actionId: number;
  @Input() actionIndex: string;
  @Input() allActionFields: ActionField[];
  @Input() field: ActionField;
  @Input() form: FormGroup;
  @Input() hasHistoricalEffectiveDate: boolean;
  @Input() historicalEffectiveDate: Date;
  @Input() warnings: string[];
  @Input() workOrderActionJson: any;
  @Input() workOrder: WorkOrder;

  @Output() selectedMeterEmitter: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() selectedFromMeterEmitter: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() selectedToMeterEmitter: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() selectedBlockEmitter: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() selectedRatePackageEmitter: EventEmitter<string> = new EventEmitter<string>(true);

  // Select base should have one output
  // Key and strongly typed value w/ value of change

  displayBlockIdError: boolean;
  displayMeterIdError: boolean;
  displayToMeterIdError: boolean;

  ratePackageOptions: RatePackageIdSummary[];

  selectedBlock: Block;
  selectedToBlock: Block;
  selectedMeter: MeterSummary;
  selectedMeterId: string;
  selectedToMeter: MeterSummary;
  selectedFromMeter: MeterSummary;
  selectedRatePackage: RatePackage;

  tokenCache = new TokenCache();

  WarningConstants = {
    ChangeRatePackageTooManySpaces: ChangeRatePackageTooManySpaces,
    ChangeRatePackageZeroSpaces: ChangeRatePackageZeroSpaces,
    InstallingZeroSpaces: InstallingZeroSpaces,
    MeterIdDoesNotExist: MeterIdDoesNotExist,
    MeterIdTooShort: MeterIdTooShort,
    MoreThanTwentySpaces: MoreThanTwentySpaces,
    RemovingTooManySpaces: RemovingTooManySpaces,
    RemovingLastSpace: RemovingLastSpace,
    RemovingZeroSpaces: RemovingZeroSpaces,
    ShiftingLastSpace: ShiftingLastSpace,
    ShiftSpaceToDifferentBlocks: ShiftSpaceToDifferentBlocks,
    ShiftSpaceSameMeter: ShiftSpaceSameMeter,
    ShiftingSpaceTooManySpaces: ShiftingSpaceTooManySpaces,
    ShiftingZeroSpaces: ShiftingZeroSpaces,
    ShiftingToDifferentContractType: ShiftingToDifferentContractType,
    InvalidRatePackageForMeter: InvalidRatePackageForMeter
  };

  WorkorderActionJsonConstants = WorkorderActionJsonConstants;
  ChangeRatePackageConstants = ChangeRatePackageConstants;

  checkBoxesToBeginUnchecked = [InstallMeterConstants.UpdateBlockRange, InstallSpaceConstants.UpdateBlockRange, InstallSpaceConstants.SignsOnly, RemoveSpaceConstants.UpdateBlockRange,
    RelocateMeterConstants.RelocateToDifferentBlock, ChangeRatePackageConstants.ChangeRatePerHour, ChangeRatePackageConstants.ChangePeriodOfStay, ChangeRatePackageConstants.ChangeHoO, ChangeRatePackageConstants.ChangeHoR];

  optionalDropdowns = [InstallMeterConstants.MeterType, InstallMeterConstants.Reason, InstallMeterConstants.LocationType, UpdateMeterAttributesConstants.RequiredClosureAllowance,
    UpdateMeterAttributesConstants.LocationType, UpdateMeterAttributesConstants.FineAmount];

  constructor(private dialog: MatDialog, private _blockService: BlockService, private _meterService: MeterService) { }

  ngOnChanges() {

    if (this.workOrderActionJson) {
      if (this.field.fieldType === 'checkbox') {
        if (this.field.value === 'false') { this.field.value = false; }
        else if (this.field.value === 'true') { this.field.value = true; }
      }

      if (this.field.fieldId === FieldConstants.CommentsId && this.workOrder.currentLetterOfDirection && (this.tokenCache.getWorkOrderUserRole() !== this.workOrder.currentLetterOfDirection.authorUserRole)) {
        this.field.value = null;
      }
      this.prepopulateSelectFormControls();
      if (this.workOrderActionJson[WorkorderActionJsonConstants.meterGuid] && !this.selectedMeterId) {
        this.selectedMeterId = this.workOrderActionJson[WorkorderActionJsonConstants.meterGuid];
      }

      this.selectedMeterEmitter.emit(this.selectedMeterId);

      // TODO - Determine what needs rate package options
      if (!this.tokenCache.checkForCityUser() && this.field.id === FieldConstants.RatePackageDropdown) {
        this.setRatePackageOptions();
      }
    }
    else {
      if (this.field.fieldType === 'checkbox' && !this.checkBoxesToBeginUnchecked.includes(this.field.id) ) {
        this.field.value = true;
      }
      else if (this.field.fieldType === 'checkbox') {
        this.field.value = false;
      }
    }

    if (this.field.fieldType.includes('select') && this.form && this.form.get(this.actionIndex + this.field.id.toString())) {
      this.form.get(this.actionIndex + this.field.id.toString()).disable();
    }

    this.setHistoricalMeterEmitters();
    if (this.actionId === ActionConstants.InstallMeterId) {
      this.setRatePackageRequiredForInstallMeter();
    }
  }

  private isAnOptionalDropdown(fieldId: number): boolean {
    return this.optionalDropdowns.includes(fieldId);
  }

  private clear(fieldId: number) {
    this.form.get(this.actionIndex + fieldId).setValue(null);
  }

  private setRatePackageRequiredForInstallMeter(): void {
    const rp = this.allActionFields.filter(field => {
      return field.fieldId === FieldConstants.RatePackageSelectId;
    })[0];

    if (rp && this.form && this.form.get(this.actionIndex + InstallMeterConstants.NumberOfSpaces.toString())) {
      this.form.get(this.actionIndex + InstallMeterConstants.NumberOfSpaces.toString()).valueChanges.subscribe(value => {
        value ? rp.fieldBehavior = 'Required' : rp.fieldBehavior = '';
      });
    }
  }

  // TODO - Move this
  private setHistoricalMeterEmitters(): void {
    if (this.form && this.field.fieldType.includes('meter') && this.hasHistoricalEffectiveDate) {
      if (this.historicalEffectiveDate && this.form.get(this.actionIndex + this.field.id.toString())) {
        this.form.get(this.actionIndex + this.field.id.toString()).enable();
      }
      else if (this.form.get(this.actionIndex + this.field.id.toString())) {
        this.form.get(this.actionIndex + this.field.id.toString()).disable();
      }

      this.form.get(this.actionIndex + this.field.id.toString()).valueChanges.subscribe(value => {
        if (this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid) && (this.field.fieldId === FieldConstants.MeterSelectId || this.field.fieldId === FieldConstants.MeterIdTextId)) {
          this.selectedMeterId = this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid).value;
          this.selectedMeterEmitter.emit(this.selectedMeterId);
          if (this.selectedMeterId && this.actionId === ActionConstants.InstallSpaceId) {
            this.setRatePackageOptions();
          }
        }
        else if (this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid) && this.field.fieldId === FieldConstants.MeterFromId) {
          this.selectedFromMeterEmitter.emit(this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid).value);
        }
        else if (this.form.get(WorkorderActionJsonConstants.meterToGuid) && this.field.fieldId === FieldConstants.MeterToId) {
          this.selectedToMeterEmitter.emit(this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterToGuid).value);
        }
      });
    }

    if (this.form !== undefined) {
      if (this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid)) {
        this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid).valueChanges.subscribe(result => {
          this.selectedMeterId = this.form.get(this.actionIndex + WorkorderActionJsonConstants.meterGuid).value;
          this.selectedMeterEmitter.emit(this.selectedMeterId);
        });
      }
    }

  }

  // TODO - Move this
  openInventorySelectDialog(fieldType): void {

    const filter = this.createFilterBasedOnActionId();

    if (this.actionId === ActionConstants.ChangeContractTypeToConcessionId) {
      fieldType = 'meter-select-active-reserved';
    }

    const dialogRef = this.dialog.open(InventorySelectDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {context: fieldType, filter: filter},
    });


    if (fieldType === 'meter-select' || fieldType === 'meter-from-select' || fieldType === 'meter-select-inactive' || fieldType === 'meter-select-active-reserved') {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.displayMeterIdError = false;
          // TODO - Add logic to account for more than just meter-select and meter-from-select
          if (fieldType === 'meter-select' || fieldType === 'meter-select-active-reserved') {
            this.populateWorkOrderInventoryIdFieldsWithMeterResult(result, false, false);
            this.selectedMeter = result;
            this.addInventoryIdAndGuid(WorkorderActionJsonConstants.meterGuid, this.selectedMeter.id, this.selectedMeter.displayId, fieldType === 'meter-select' || fieldType === 'meter-select-active-reserved' ? this.selectedMeterEmitter : this.selectedFromMeterEmitter);
            this.selectedMeterEmitter.emit(result ? this.selectedMeter.id : '');
            if (this.actionId === ActionConstants.InstallSpaceId) {
              this.setRatePackageOptions();
            }
          }
          else if (fieldType === 'meter-from-select') {
            this.populateWorkOrderInventoryIdFieldsWithMeterResult(result, false, true);
            this.selectedFromMeter = result;
            this.addInventoryIdAndGuid(WorkorderActionJsonConstants.meterFromGuid, this.selectedFromMeter.id, this.selectedFromMeter.displayId, this.selectedFromMeterEmitter);
            this.selectedFromMeterEmitter.emit(result ? this.selectedFromMeter.id : '');
          }
        }
        else {
          // TODO - Add logic to account for more than just meter-select and meter-from-select
          this.removeInventoryIdAndGuid(fieldType === 'meter-select' || fieldType === 'meter-select-active-reserved' ? WorkorderActionJsonConstants.meterGuid : WorkorderActionJsonConstants.meterToGuid);
        }
      });
    }
    else if (fieldType === 'meter-to-select') {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.populateWorkOrderInventoryIdFieldsWithMeterResult(result, true, false);
          this.selectedToMeter = result;
          this.displayToMeterIdError = false;
          this.addInventoryIdAndGuid(WorkorderActionJsonConstants.meterToGuid, this.selectedToMeter.id, this.selectedToMeter.displayId, this.selectedToMeterEmitter);
        }
        else {
          this.removeInventoryIdAndGuid(WorkorderActionJsonConstants.meterToGuid);
        }
        this.selectedToMeterEmitter.emit(result ? this.selectedToMeter.id : '');
      });
    }
    else if (fieldType === 'block-select') {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._blockService.getBlockById(result.id)
          .subscribe(resultBlock => {
            this.populateWorkOrderInventoryIdFieldsWithBlockResult(resultBlock);
            this.selectedBlock = resultBlock;
            this.displayBlockIdError = false;
            this.addInventoryIdAndGuid(WorkorderActionJsonConstants.blockGuid, this.selectedBlock.id, this.selectedBlock.displayId, this.selectedBlockEmitter);
          });
        }
        else {
          this.removeInventoryIdAndGuid(WorkorderActionJsonConstants.blockGuid);
        }
      });
    }
    else if (fieldType === 'block-to-select') {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._blockService.getBlockById(result.id)
          .subscribe(resultBlock => {
            this.populateWorkOrderInventoryIdFieldsWithBlockResult(resultBlock);
            this.selectedToBlock = resultBlock;
            this.addInventoryIdAndGuid(WorkorderActionJsonConstants.blockToGuid, this.selectedToBlock.id, this.selectedToBlock.displayId, this.selectedBlockEmitter);
          });
        }
        else {
          this.removeInventoryIdAndGuid(WorkorderActionJsonConstants.blockToGuid);
        }
      });
    }
    else if (fieldType === 'physical-block-select') {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._blockService.getBlockById(result.id)
          .subscribe(resultBlock => {
            this.populateWorkOrderInventoryIdFieldsWithBlockResult(resultBlock);
            this.selectedBlock = resultBlock;
            this.form.get(this.actionIndex + this.field.id.toString()).setValue(this.selectedBlock.displayId);
            this.displayBlockIdError = false;
            this.addInventoryIdAndGuid(WorkorderActionJsonConstants.physicalBlockGuid, this.selectedBlock.id, this.selectedBlock.displayId, this.selectedBlockEmitter);
          });
        }
        else {
          this.removeInventoryIdAndGuid(WorkorderActionJsonConstants.physicalBlockGuid);
        }
      });
    }
  }

  populateWorkOrderInventoryIdFieldsWithBlockResult(block: Block, isToBlock = false) {
    this.clearPreviousWorkOrderInventoryFieldsOnBlockSelect(block, isToBlock);

    if (!this.workOrder.zoneDisplayIds || !this.workOrder.zoneDisplayIds.includes(block.zoneName)) {
      this.workOrder.zoneDisplayIds ?  this.workOrder.zoneDisplayIds.push(block.zoneName) : this.workOrder.zoneDisplayIds = [block.zoneName];
    }
    if (!this.workOrder.areaDisplayIds || !this.workOrder.areaDisplayIds.includes(block.areaName)) {
      this.workOrder.areaDisplayIds ?  this.workOrder.areaDisplayIds.push(block.areaName) : this.workOrder.areaDisplayIds = [block.areaName];
    }
    if (!this.workOrder.blockDisplayIds || !this.workOrder.blockDisplayIds.includes(block.displayId)) {
      this.workOrder.blockDisplayIds ?  this.workOrder.blockDisplayIds.push(block.displayId) : this.workOrder.blockDisplayIds = [block.displayId];
    }
  }

  clearPreviousWorkOrderInventoryFieldsOnBlockSelect(block: Block, isToBlock: boolean) {
    if (!isToBlock && this.selectedBlock && this.selectedBlock.id !== block.id) {
      if (this.workOrder.zoneDisplayIds.includes(this.selectedBlock.zoneName)) {
        this.workOrder.zoneDisplayIds.splice(this.workOrder.zoneDisplayIds.indexOf(this.selectedBlock.zoneName), 1);
      }
      if (this.workOrder.areaDisplayIds.includes(this.selectedBlock.areaName)) {
        this.workOrder.areaDisplayIds.splice(this.workOrder.areaDisplayIds.indexOf(this.selectedBlock.areaName), 1);
      }
      if (this.workOrder.blockDisplayIds.includes(this.selectedBlock.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedBlock.displayId), 1);
      }
    }
    if (isToBlock && this.selectedToBlock && this.selectedToBlock.id !== block.id) {
      if (this.workOrder.zoneDisplayIds.includes(this.selectedToBlock.zoneName)) {
        this.workOrder.zoneDisplayIds.splice(this.workOrder.zoneDisplayIds.indexOf(this.selectedToBlock.zoneName), 1);
      }
      if (this.workOrder.areaDisplayIds.includes(this.selectedToBlock.areaName)) {
        this.workOrder.areaDisplayIds.splice(this.workOrder.areaDisplayIds.indexOf(this.selectedBlock.areaName), 1);
      }
      if (this.workOrder.blockDisplayIds.includes(this.selectedToBlock.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedToBlock.displayId), 1);
      }
    }
  }

  populateWorkOrderInventoryIdFieldsWithMeterResult(meter, isToMeter, isFromMeter) {
    this.clearPreviousWorkOrderInventoryFieldsOnMeterSelect(meter, isToMeter, isFromMeter);

    if (!this.workOrder.zoneDisplayIds || !this.workOrder.zoneDisplayIds.includes(meter.zoneName)) {
      this.workOrder.zoneDisplayIds ?  this.workOrder.zoneDisplayIds.push(meter.zoneName) : this.workOrder.zoneDisplayIds = [meter.zoneName];
    }
    if (!this.workOrder.areaDisplayIds || !this.workOrder.areaDisplayIds.includes(meter.areaName)) {
      this.workOrder.areaDisplayIds ?  this.workOrder.areaDisplayIds.push(meter.areaName) : this.workOrder.areaDisplayIds = [meter.areaName];
    }
    if (!this.workOrder.blockDisplayIds || !this.workOrder.blockDisplayIds.includes(meter.blockName)) {
      this.workOrder.blockDisplayIds ?  this.workOrder.blockDisplayIds.push(meter.blockName) : this.workOrder.blockDisplayIds = [meter.blockName];
    }
    if (!this.workOrder.meterDisplayIds || !this.workOrder.meterDisplayIds.includes(meter.displayId)) {
      this.workOrder.meterDisplayIds ?  this.workOrder.meterDisplayIds.push(meter.displayId) : this.workOrder.meterDisplayIds = [meter.displayId];
    }
  }

  clearPreviousWorkOrderInventoryFieldsOnMeterSelect(meter: Meter, isToMeter: boolean, isFromMeter: boolean) {
    if (!isFromMeter && !isToMeter && this.selectedMeter && this.selectedMeter.id !== meter.id) {
      if (this.workOrder.zoneDisplayIds.includes(this.selectedMeter.zoneName)) {
        this.workOrder.zoneDisplayIds.splice(this.workOrder.zoneDisplayIds.indexOf(this.selectedMeter.zoneName), 1);
      }
      if (this.workOrder.areaDisplayIds.includes(this.selectedMeter.areaName)) {
        this.workOrder.areaDisplayIds.splice(this.workOrder.areaDisplayIds.indexOf(this.selectedMeter.areaName), 1);
      }
      if (this.workOrder.blockDisplayIds.includes(this.selectedMeter.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedMeter.blockName), 1);
      }
      if (this.workOrder.meterDisplayIds.includes(this.selectedMeter.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedMeter.displayId), 1);
      }
    }
    else if (isToMeter && this.selectedToMeter && this.selectedToMeter.id !== meter.id) {
      if (this.workOrder.zoneDisplayIds.includes(this.selectedToMeter.zoneName)) {
        this.workOrder.zoneDisplayIds.splice(this.workOrder.zoneDisplayIds.indexOf(this.selectedToMeter.zoneName), 1);
      }
      if (this.workOrder.areaDisplayIds.includes(this.selectedToMeter.areaName)) {
        this.workOrder.areaDisplayIds.splice(this.workOrder.areaDisplayIds.indexOf(this.selectedBlock.areaName), 1);
      }
      if (this.workOrder.blockDisplayIds.includes(this.selectedToMeter.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedToMeter.displayId), 1);
      }
      if (this.workOrder.meterDisplayIds.includes(this.selectedToMeter.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedToMeter.displayId), 1);
      }
    }
    else if (isFromMeter && this.selectedFromMeter && this.selectedFromMeter.id !== meter.id) {
      if (this.workOrder.zoneDisplayIds.includes(this.selectedFromMeter.zoneName)) {
        this.workOrder.zoneDisplayIds.splice(this.workOrder.zoneDisplayIds.indexOf(this.selectedFromMeter.zoneName), 1);
      }
      if (this.workOrder.areaDisplayIds.includes(this.selectedFromMeter.areaName)) {
        this.workOrder.areaDisplayIds.splice(this.workOrder.areaDisplayIds.indexOf(this.selectedBlock.areaName), 1);
      }
      if (this.workOrder.blockDisplayIds.includes(this.selectedFromMeter.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedFromMeter.displayId), 1);
      }
      if (this.workOrder.meterDisplayIds.includes(this.selectedFromMeter.displayId)) {
        this.workOrder.blockDisplayIds.splice(this.workOrder.blockDisplayIds.indexOf(this.selectedFromMeter.displayId), 1);
      }
    }
  }

  removeAssociatedDetails(event, fieldId: number) {
    if (this.actionId === ActionConstants.InstallSpaceId && fieldId === InstallSpaceConstants.SignsOnly && event.checked === false) {
      this.selectedBlockEmitter.emit(null);
    }
  }

  createFilterBasedOnActionId() {
    const filter = new InventoryFilterRepresentation();
    if (this.actionId === ActionConstants.InactivateMeterId) {
      filter.TempRemovalStatus = TempRemovalStatusConstants.TempRemoved;
      filter.TempRemovalStatusFilterOperator = FilterOperator.Equal;
    }
    else if (this.actionId === ActionConstants.ChangeRatePackageId
          || this.actionId === ActionConstants.RemoveSpaceId
          || this.actionId === ActionConstants.ShiftSpaceId) {
      filter.MeterStatus = MeterStatusConstants.active;
      filter.MeterStatusFilterOperator = FilterOperator.Equal;
    }
    else if (this.actionId === ActionConstants.ChangeContractTypeToConcessionId) {
      filter.MeterStatus = MeterStatusConstants.removed;
      filter.MeterStatusFilterOperator = FilterOperator.NotEqual;
    }
    return filter;
  }

  setRatePackageOptions(): void {
    const ratePackageDropdownOptions = new Array<RatePackageIdSummary>();
    const meterId = this.selectedMeterId ? this.selectedMeterId : this.selectedMeter.id;
    const effectiveDate = this.hasHistoricalEffectiveDate && this.historicalEffectiveDate ? this.historicalEffectiveDate : null;

      this._meterService.getMeterById(meterId, effectiveDate)
      .subscribe(result => {
          let mostFrequentRatePackage = new RatePackageIdSummary();
          this.ratePackageOptions = result.ratePackageSummaries;
          result.ratePackageSummaries.forEach(rpId => {

            if (rpId.id) {
              const newOption = new RatePackageIdSummary();
              newOption.id = rpId.id;
              newOption.name = rpId.name;
              ratePackageDropdownOptions.push(newOption);

              if (mostFrequentRatePackage.id) {
                if (rpId.numberOfSpacesWithRatePackage > mostFrequentRatePackage.numberOfSpacesWithRatePackage) {
                  mostFrequentRatePackage = newOption;
                }
              }
              else {
                mostFrequentRatePackage = newOption;
              }
            }
          });

          const fieldOptions = new Array<ActionFieldOption>();
          ratePackageDropdownOptions.forEach(option => {
            const newOption = new ActionFieldOption();
            newOption.title = option.name;
            newOption.id = Number.parseInt(option.id);
            newOption.order = ratePackageDropdownOptions.indexOf(option);
            fieldOptions.push(newOption);
          });

          const ratePackageDropdown = this.allActionFields.find(actionField => {
            return actionField.id === FieldConstants.RatePackageDropdown;
          });

          ratePackageDropdown.fieldOptions = fieldOptions;

          if (this.form) {
            this.form.get(this.actionIndex + FieldConstants.RatePackageDropdown.toString()).enable();
          }
          this.addInventoryIdAndGuid(WorkorderActionJsonConstants.ratePackageGuid, mostFrequentRatePackage.id, mostFrequentRatePackage.name, this.selectedRatePackageEmitter);
      });
  }

  addInventoryIdAndGuid(inventoryObject: string, guid: string, displayId: string, eventEmitter: EventEmitter<string>) {
    if (this.form) {
      this.form.addControl(this.actionIndex + inventoryObject, new FormControl(''));
      this.form.get(this.actionIndex + inventoryObject).setValue(guid);
      if (this.form.get(this.actionIndex + this.field.id.toString()) && inventoryObject !== WorkorderActionJsonConstants.ratePackageGuid) {
        this.form.get(this.actionIndex + this.field.id.toString()).setValue(displayId);
        this.form.get(this.actionIndex + this.field.id.toString()).markAsDirty();
      }
      else if (inventoryObject === WorkorderActionJsonConstants.ratePackageGuid && this.form.get(this.actionIndex + this.WorkorderActionJsonConstants.ratePackageGuid)) {
        this.form.get(this.actionIndex + FieldConstants.RatePackageDropdown).setValue(displayId);
        this.form.get(this.actionIndex + FieldConstants.RatePackageDropdown).markAsDirty();
      }
      eventEmitter.emit(guid);
    }
  }

  isABlockSelect(fieldType: string) {
    return (fieldType === 'block-select' || fieldType === 'physical-block-select' || fieldType === 'block-to-select');
  }

  removeInventoryIdAndGuid(inventoryObject: string) {
    if (this.form.get(this.actionIndex + inventoryObject) !== null && this.form.get(this.actionIndex + inventoryObject) !== undefined) {
      this.form.get(this.actionIndex + inventoryObject).setValue('');
      this.form.removeControl(this.actionIndex + WorkorderActionJsonConstants.meterGuid);
    }
  }

  // TODO - Remove and populate within forms?
  prepopulateSelectFormControls() {
    if (this.actionId === ActionConstants.InstallMeterId) {
      if (this.field.fieldId === InstallMeterConstants.BlockIDSelect && this.workOrderActionJson[WorkorderActionJsonConstants.blockGuid]) {
        this._blockService.getBlockById(this.workOrderActionJson[WorkorderActionJsonConstants.blockGuid])
        .subscribe(block => {
          this.selectedBlock = block;
          this.populateWorkOrderInventoryIdFieldsWithBlockResult(block);
          this.selectedBlockEmitter.emit(block.id);
          this.form.get(this.actionIndex + this.field.id.toString()).setValue(this.selectedBlock.displayId);
          this.selectedBlockEmitter.emit(this.workOrderActionJson[WorkorderActionJsonConstants.blockGuid]);
        });
      }
    }
    if (this.actionId === ActionConstants.RemoveSpaceId) {
      if (this.workOrderActionJson[WorkorderActionJsonConstants.meterGuid]) {
        this.addInventoryIdAndGuid(WorkorderActionJsonConstants.meterGuid, this.workOrderActionJson[WorkorderActionJsonConstants.meterGuid], this.field.value, this.selectedMeterEmitter);

        this._meterService.getMeterById(this.workOrderActionJson[WorkorderActionJsonConstants.meterGuid])
        .subscribe(result => {
          this.populateWorkOrderInventoryIdFieldsWithMeterResult(result, false, false);
        });
      }
    }
    if (this.actionId === ActionConstants.ShiftSpaceId) {
      if (this.form !== undefined) {
        this.form.get(this.actionIndex + this.field.id.toString()).setValue(this.field.value);
        if (this.field.fieldId === FieldConstants.MeterFromId) {
          this.selectedFromMeterEmitter.emit(this.workOrderActionJson[WorkorderActionJsonConstants.meterFromGuid]);
          this._meterService.getMeterById(this.workOrderActionJson[WorkorderActionJsonConstants.meterFromGuid])
          .subscribe(result => {
            this.populateWorkOrderInventoryIdFieldsWithMeterResult(result, false, true);
          });
        }
        else if (this.field.fieldId === FieldConstants.MeterToId) {
          this.selectedFromMeterEmitter.emit(this.workOrderActionJson[WorkorderActionJsonConstants.meterToGuid]);
          this._meterService.getMeterById(this.workOrderActionJson[WorkorderActionJsonConstants.meterToGuid])
          .subscribe(result => {
            this.populateWorkOrderInventoryIdFieldsWithMeterResult(result, true, false);
          });
        }
      }
    }
  }

  openRatePackageSelectDialog(fieldType): void {
    const dialogRef = this.dialog.open(RatePackageSelectDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {context: fieldType}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.actionId === ActionConstants.InstallMeterId || this.actionId === ActionConstants.ChangeRatePackageId) {
          this.form.get(this.actionIndex + this.field.id.toString()).setValue(result.name);
        }
        this.addInventoryIdAndGuid(WorkorderActionJsonConstants.ratePackageGuid, result.id, result.name, this.selectedRatePackageEmitter);
      }
      else {
        this.removeInventoryIdAndGuid(WorkorderActionJsonConstants.ratePackageGuid);
      }
    });
  }

  setRatePackageGuid(ratePackageGuid: string) {
    if (this.form.value[this.actionIndex + FieldConstants.MeterRatePackageId] !== undefined) {
      this.form.addControl(this.actionIndex + WorkorderActionJsonConstants.ratePackageGuid, new FormControl(''));

      if (this.ratePackageOptions) {
        const matchingRpByName = this.ratePackageOptions.filter(rp => {
          return (this.form.value[this.actionIndex + FieldConstants.MeterRatePackageId] === rp['name']);
        });

        this.form.get(this.actionIndex + WorkorderActionJsonConstants.ratePackageGuid).setValue(matchingRpByName[0].id);
      }
    }
    else {
      this.form.removeControl(this.actionIndex + WorkorderActionJsonConstants.ratePackageGuid);
    }
  }
}
