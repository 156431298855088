exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".center-card {\r\n    width: 70%;\r\n    margin: 50px auto;\r\n    max-width: 800px;\r\n    top:80px;\r\n}\r\n\r\nmat-toolbar {\r\n    text-align: center;\r\n}\r\n\r\nmat-card {\r\n  padding:0px;\r\n}\r\n\r\nmat-card-content {\r\n    padding:2%;\r\n}\r\n\r\ndiv {\r\n    padding-bottom:20px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();