exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".select-button {\r\n    height:65%;\r\n    width:65%;\r\n    color: white;\r\n    background-color: #607d8b;\r\n    font-size:100%;\r\n    margin-right:7%;\r\n}\r\n\r\n.mat-radio-label {\r\n    max-width:100% !important;\r\n    width:100% !important;\r\n}\r\n\r\n.example-margin {\r\n    width:25%;\r\n}\r\n\r\n.space-checkbox-container {\r\n    width: 50%;\r\n    margin-top:15px;\r\n}\r\n\r\n.space-id {\r\n    width:50%;\r\n}\r\n\r\n.mat-radio-group {\r\n    width:80%;\r\n}\r\n\r\n.id-error {\r\n    font-style: italic;\r\n    color: red;\r\n    padding-left:5%;\r\n    padding-right:5%;\r\n}\r\n\r\n.non-active-space {\r\n    margin-left:4.5%;\r\n}\r\n\r\n.previously-removed {\r\n    min-width:100%;\r\n}\r\n\r\n.space-change-column-header{\r\n    text-decoration: underline;\r\n    font-weight: bold;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();