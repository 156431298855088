import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { TrueUpHttpService } from './../../true-up-http.service';
import { SpaceClosureByWarehouse } from './space-closures/space-closure-warehouse';
import { WorkOrderHistoryJsonConverter } from './../work-order-history/work-order-history-json-converter';
import { InventoryQueryStringBuilder } from './../inventory-table/inventory-query-string-builder';
import { SpacePaginationResult } from './space-summary';
import { InventoryFilterRepresentation } from './../inventory-filter/inventory-filter-representation';
import { InventoryListJsonConverter } from './../inventory-table/inventory-list-json-converter';
import { Space } from '../Spaces/space';
import { SpaceSummary } from '../Spaces/space-summary';
import { SpaceJsonConverter } from './space-json-converter';
import { SpaceDisplayIdKeyValuePair } from './../meters/meter-true-up/models/space-collection';
import { DistributionJsonConverter } from './../meters/meter-true-up/distribution-json-converter';
import { SpaceWorkOrderHistory } from './space-work-order-history/space-work-order-history';
import { InventoryHttpService } from '../../inventory-http.service';
import { DateStringBuilder } from '../../core/query-strings/date-string-builder';
import { ExportSelectRepresentation } from '../inventory-filter/export-representations';
import { SpaceClosureByDate } from './space-closures/space-closure-date';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { WorkOrdersService } from '../../work-orders/services/work-orders.service';
import { TimeZoneConverter } from '../../core/time-zone/time-zone-converter';

@Injectable()
export class SpaceService {


    constructor(private http: InventoryHttpService, private trueUpHttp: TrueUpHttpService, private workOrderService: WorkOrdersService) { }

    public getSpaceById(id: string): Observable<Space> {
        return this.http.get('/spaces/' + id, null)
          .pipe(
            map(response => response != null ? SpaceJsonConverter.jsonToSpace(response) : null),
            catchError((error) => this.handleError(error))
          );
    }
    builDateQueryString(dateString:string):string{
        if(!dateString){
            return '';
        }
        return `?date=${dateString}`;
    }

    public getAllSpacesOnBlock(blockId: string, date: Date): Observable<Space[]> {
        const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
        const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
        const queryString = this.builDateQueryString(dateString);
        const endpoint = `/blocks/${blockId}/spaces${queryString}`;
        return this.http.get(endpoint, null)
          .pipe(catchError((error) => this.handleError(error)));
    }
    public getSpacesByMeterId(meterId: string, date?: Date): Observable<SpaceSummary[]> {
        let endpoint = `/meters/${meterId}/spaces`;
        if (date) {
            const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
            const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
            const queryString = this.builDateQueryString(dateString);
            endpoint = `${endpoint}${queryString}`;
        }
        return this.http.get(endpoint, null)
            .pipe(
              map(response => {
                if(response == null){
                    return null;
                }
                const spaces = SpaceJsonConverter.jsonToSpaceSummaries(response)

                //filter out future spaces, because they cause trouble
                //future spaces are installed on the meter at a future date, they may not exist yet
                return spaces.filter(space => space.currentStatus !== null && space.currentStatus !== undefined);
              }),
              catchError(error => this.handleError(error)));
    }
    public getTotalNumberOfSpaces(meterId: string, date: Date): Observable<any> {
        let endpoint = `/meters/${meterId}/totalSpaces`;
        if (date) {
            const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
            const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
            const queryString = this.builDateQueryString(dateString);
            endpoint = `${endpoint}${queryString}`;
        }
        return this.http.get(endpoint, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    public getSpacesByMeterDisplayId(meterId: string, date?: Date): Observable<SpaceSummary[]> {
        let endpoint = `/meters/displayId/${meterId}/spaces`;
        if (date) {
            const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
            const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
            const queryString = this.builDateQueryString(dateString);
            endpoint = `${endpoint}${queryString}`;
        }
        return this.http.get('/meters/' + meterId + '/spaces', null)
          .pipe(
            map(response => response != null ? SpaceJsonConverter.jsonToSpaceSummaries(response) : null),
            catchError((error) => this.handleError(error))
          );
    }


    public getSpacesWorkOrderHistories(spaceId: string): Observable<SpaceWorkOrderHistory[]> {
        return this.http.get('/spaces/' + spaceId + '/workOrderHistories', null)
          .pipe(
            map(response => response != null ? WorkOrderHistoryJsonConverter.jsonToWorkOrderHistoryList(response) : null),
            catchError((error) => this.handleError(error))
          );
    }

    public getSpacesForMeterForDate(meterId: string, date: Date): Observable<SpaceSummary[]> {
        const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
        return this.http.get('/meters/' + meterId + `/spaces/${convertedDate.getFullYear()}/${convertedDate.getMonth()}/${convertedDate.getDay()}`, null)
          .pipe(
            map(response => response != null ? SpaceJsonConverter.jsonToSpaceSummaries(response) : null),
            catchError((error) => this.handleError(error))
          );
    }

    public getSpaceDisplayIds(guids: string[]): Observable<SpaceDisplayIdKeyValuePair[]> {
        const endpoint = '/spaces/displayIds?';

        return this.http.post(endpoint, guids)
          .pipe(
            map(response => response != null ? DistributionJsonConverter.jsonToSpaceDisplayIds(guids, response) : null),
            catchError((error) => this.handleError(error))
          );
    }

  public getClosuresByWarehouseId(spaceId: string, pageSize: number,
    page: number, year?: number, quarter?: number): Observable<PaginatedGetResult<SpaceClosureByWarehouse>> {

    var queryString = '/Occupancy/closure/space/'
      + spaceId +
      '/byclosure/'
      + pageSize +
      '/'
      + page;

    if (year && quarter) {
      queryString = queryString + '?year=' + year + '&quarter=' + quarter;
    }

      return this.trueUpHttp.get(queryString,null)
        .pipe(catchError((error) => this.handleError(error)));
    }

  public getClosuresByDate(spaceId: string, pageSize: number,
    page: number, year?: number, quarter?: number): Observable<PaginatedGetResult<SpaceClosureByDate>> {

    var queryString = '/Occupancy/closure/space/'
      + spaceId +
      '/bydate/'
      + pageSize +
      '/'
      + page;

    if (year && quarter) {
      queryString = queryString + '?year=' + year + '&quarter=' + quarter;
    }

      return this.trueUpHttp.get(queryString, null)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getClosureByWarehouseId(closureWarehouseRecordId: string): Observable<any> {
      const queryString = `/Occupancy/closure/${closureWarehouseRecordId}`;

        return this.trueUpHttp.get(queryString, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    //Useful to make sure the lambda is up before calling a long-running service
    public primeTrueUpService(): Observable<Date> {
      return this.trueUpHttp.get('/time/start/', null)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public updateSpaceAttributes(space: Space): Observable<Space> {
        return this.http.put('/spaces/updateAttributes/', space)
          .pipe(catchError((error) => this.handleError(error)));
  }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    public exportSpaces(orderByCategory: string = 'currentStatus', isAscending: boolean = true, filter: InventoryFilterRepresentation, select: ExportSelectRepresentation): Observable<any> {
        const order = isAscending ? 'asc' : 'desc';

        const filterQueryString = InventoryQueryStringBuilder.createFilterQueryString(filter, true);
        const selectQueryString = select.createEncodedSelect();
        const endpoint = `/spaces/export?orderby=${orderByCategory}%20${order}&${filterQueryString}&select=${selectQueryString}`;

        return this.http.getFile(endpoint, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    public getAllSpaces(orderByCategory: string = 'currentStatus', isAscending: boolean = true,
        skip: number = 25, take: number = 25, filter: InventoryFilterRepresentation): Observable<SpacePaginationResult> {
        const order = isAscending ? 'asc' : 'desc';

        return new Observable<SpacePaginationResult>(obs => {
            if(filter.FilterByWorkOrder){
                this.workOrderService.getWorkOrderActionIdsByWorkOrderDisplayId(filter.WorkOrder)
                .subscribe(workOrderActionIdsResponse=>{
                    var workOrderActionIds = workOrderActionIdsResponse.join(',');
                    if(workOrderActionIds){
                        filter.WorkOrderActionIds = workOrderActionIds;
                    }
                    return this.sendGetAllSpacesRequest(orderByCategory,skip,take,filter,order).subscribe(x=>obs.next(x));
                })
            }else{
                return this.sendGetAllSpacesRequest(orderByCategory,skip,take,filter,order).subscribe(x=>obs.next(x));
            }
        });
    }
    private sendGetAllSpacesRequest(orderByCategory:string,
    skip: number, take: number, filter: InventoryFilterRepresentation, order:string){

        const filterQueryString = InventoryQueryStringBuilder.createFilterQueryString(filter, false);
        const endpoint = `/spaces?orderby=${orderByCategory}%20${order}&skip=${skip}&top=${take}&${filterQueryString}`;

        return this.http.get(endpoint, null)
          .pipe(
            map(response => response != null ? InventoryListJsonConverter.jsonToInventoryListPaginationResult(response) : null),
            catchError((error) => this.handleError(error))
          );
    }

    public getSpaceByDisplayId(displayId: string, date: Date = null): Observable<Space> {
        let endpoint = `/spaces/displayId/${displayId}`;
        if (!DateStringBuilder.dateIsNullOrDefault(date)) {
            const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
            const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
            const queryString = this.builDateQueryString(dateString);
            endpoint = `${endpoint}${queryString}`;
        }
        return this.http.get(endpoint, null)
          .pipe(
            map(response => response != null ? SpaceJsonConverter.jsonToSpaceSummary(response) : null),
            catchError((error) => this.handleError(error))
          );
    }

}
