exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-raised-button {\r\n    width: 20px;\r\n    height: 10px;\r\n    line-height: 20px;\r\n    min-height: 20px;\r\n    vertical-align: top;\r\n    color: #fff;\r\n    background-color: #607d8b;\r\n  }\r\n\r\n.mat-table{\r\n  margin-left: 20px;\r\n  margin-right: 20px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();