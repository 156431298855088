import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnChanges, Input, ViewChild, OnInit, ErrorHandler } from '@angular/core';

import { MeterService } from './../../meters/meters.service';
import { SpaceClosureByWarehouse } from './space-closure-warehouse';
import { SpaceClosureByDate } from './space-closure-date';
import { SpaceService } from './../spaces.service';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { PaginatedGetResult } from '../../../core/models/paginated-get-result';
import { SpaceClosureDetailComponent } from './space-closure-detail.component';

@Component({
  selector: 'app-space-closures',
  templateUrl: './space-closures.component.html',
  styleUrls: ['./space-closures.component.css']
})
export class SpaceClosuresComponent implements OnChanges, OnInit {

  constructor(private _spaceService: SpaceService, private _meterService: MeterService,
      private _startTimeService: SystemStartTimeService, private _errorHandler: ErrorHandler, private dialog: MatDialog) { }

  @Input() spaceId: string;
  @Input() meterId: string;

  @ViewChild(MatPaginator, {static: true}) paginatorByDate: MatPaginator;

  @ViewChild(MatPaginator, {static: true}) paginatorByClosure: MatPaginator;

  byDateDataSource = new MatTableDataSource<SpaceClosureByDate>();
  closuresByDate: SpaceClosureByDate[];
  loadingByDate: boolean;
  errorByDateMessage: string;
  pageByDate: number = 0;
  pageSizeByDate: number = 25;
  totalRecordsByDate: number = 0;
  byDateColumns = ['closureId', 'closureDate', 'totalHours', 'affectedAmount', 'affectedSpaceIds'];

  byClosureDataSource = new MatTableDataSource<SpaceClosureByWarehouse>();
  closuresByClosure: SpaceClosureByWarehouse[];
  loadingByClosure: boolean;
  errorByClosureMessage: string;
  pageByClosure: number = 0;
  pageSizeByClosure: number = 25;
  totalRecordsByClosure: number = 0;
  byClosureColumns = ['closureId', 'closureStartDate', 'closureEndDate', 'totalHours', 'affectedAmount', 'affectedSpaceIds'];

  reportingYearFilter: number;
  reportingYears: number[] = [];

  reportingQuarterFilter: number;
  reportingQuarters = [{ key: 1, value: 'Q1' }, { key: 2, value: 'Q2' }, { key: 3, value: 'Q3' }, { key: 4, value: 'Q4' }];

  reportFormats = ["By Closure", "By Date"];
  reportFormat: string = this.reportFormats[0];


  ngOnInit() {
    this._startTimeService.getInitialSystemStartTime().subscribe(result => {
      for (let i = result.getFullYear(); i <= new Date().getFullYear(); i++) {
        this.reportingYears.push(i);
      }
    },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
      });
  }

  ngOnChanges() {
    if (this.spaceId || this.meterId) {
      this.getAllDowntimes();
    }
  }

  reportingQuarterAndYearSelected(): boolean {
    if(this.reportingQuarterFilter !== undefined && this.reportingYearFilter !== undefined) {
      return false;
    }
    else {
      return true;
    }
  }

  getAllDowntimes() {

    this.pageByDate = 0;
    this.totalRecordsByDate = 0;
    this.closuresByDate = [];
    this.byDateDataSource = new MatTableDataSource<SpaceClosureByDate>(this.closuresByDate);

    this.pageByClosure = 0;
    this.totalRecordsByClosure = 0;
    this.closuresByClosure = [];
    this.byClosureDataSource = new MatTableDataSource<SpaceClosureByWarehouse>(this.closuresByClosure);

    if (this.spaceId) {
      this.loadSpaceTables();
    }
    else if (this.meterId) {
      this.loadMeterTables();
    }
  }

  loadSpaceTables() {

    this.loadSpaceClosuresByDate();

    this.loadSpaceClosuresByWarehouse();
  }

  viewClosureDetails(closureWarehouseRecordId: string): void {
    this.dialog.open(SpaceClosureDetailComponent, {
      width: '450px',
      height: '400px',
      maxHeight: '400px',
      data: { closureWarehouseRecordId: closureWarehouseRecordId }
    });
  }

  private loadSpaceClosuresByDate() {
        this.loadingByDate = true;
        this.errorByDateMessage = null;

    this._spaceService.primeTrueUpService()
      .subscribe(result => {
        this._spaceService.getClosuresByDate(this.spaceId, this.pageSizeByDate, this.pageByDate, this.reportingYearFilter, this.reportingQuarterFilter)
          .subscribe(result => {
            this.loadByDate(result);
          }, error => {
            console.log(error);
            this.errorByDate(error);
          });
      });
    }

  private loadSpaceClosuresByWarehouse() {
        this.loadingByClosure = true;
    this.errorByClosureMessage = null;
    this._spaceService.primeTrueUpService()
      .subscribe(result => {
        this._spaceService.getClosuresByWarehouseId(this.spaceId, this.pageSizeByClosure, this.pageByClosure, this.reportingYearFilter, this.reportingQuarterFilter)
            .subscribe(result => {
                this.loadByClosure(result);
            }, error => {
                console.log(error);
                this.errorByClosure(error);
          });
      });
    }

    private loadByDate(result: PaginatedGetResult<SpaceClosureByDate>) {
        this.closuresByDate = result.items;
        this.byDateDataSource = new MatTableDataSource<SpaceClosureByDate>(this.closuresByDate);
        this.totalRecordsByDate = result.totalItems;
        this.loadingByDate = false;
        this.errorByDateMessage = null;

        if (this.totalRecordsByDate === 0) {
          this.pageByDate = 0;
        }
    }

    private errorByDate(error:any) {
      this.closuresByDate = [];
      this.byDateDataSource = new MatTableDataSource<SpaceClosureByDate>(this.closuresByDate);
      this.totalRecordsByDate = 0;
      this.pageByDate = 0;
      this.errorByDateMessage = error;
      this.loadingByDate = false;
    }

    private loadByClosure(result: PaginatedGetResult<SpaceClosureByWarehouse>) {
        this.closuresByClosure = result.items;
        this.byClosureDataSource = new MatTableDataSource<SpaceClosureByWarehouse>(this.closuresByClosure);
        this.totalRecordsByClosure = result.totalItems;
        this.loadingByClosure = false;
        this.errorByClosureMessage = null;

        if (this.totalRecordsByClosure === 0) {
          this.pageByClosure = 0;
        }
    }

    private errorByClosure(error: any) {
        this.closuresByClosure = [];
        this.byClosureDataSource = new MatTableDataSource<SpaceClosureByWarehouse>(this.closuresByClosure);
        this.totalRecordsByClosure = 0;
        this.pageByClosure = 0;
        this.errorByClosureMessage = error;
        this.loadingByClosure = false;
      }

  loadMeterTables() {

    this.loadMeterClosuresByDate();

    this.loadMeterClosuresByWarehouse();
  }

  private loadMeterClosuresByWarehouse() {
        this.loadingByClosure = true;
    this.errorByClosureMessage = null;
    this._meterService.primeTrueUpService()
      .subscribe(result => {
        this._meterService.getClosuresByWarehouseId(this.meterId, this.pageSizeByClosure, this.pageByClosure, this.reportingYearFilter, this.reportingQuarterFilter)
          .subscribe(result => {
            this.loadByClosure(result);
          }, error => {
            console.log(error);
            this.errorByDate(error);
          });
      });
    }

  private loadMeterClosuresByDate() {
        this.loadingByDate = true;
    this.errorByDateMessage = null;
    this._meterService.primeTrueUpService()
      .subscribe(result => {
        this._meterService.getClosuresByDate(this.meterId, this.pageSizeByDate, this.pageByDate, this.reportingYearFilter, this.reportingQuarterFilter)
          .subscribe(result => {
            this.loadByDate(result);
          }, error => {
            console.log(error);
            this.errorByClosure(error);
          });
      });
    }

  loadMoreCollectionsByDate(event: PageEvent) {
    this.pageByDate = event.pageIndex;
    this.pageSizeByDate = event.pageSize;

    if (this.spaceId) {
      this.loadSpaceClosuresByDate();
    }
    else if (this.meterId) {
      this.loadMeterClosuresByDate();
    }
  }

  loadMoreCollectionsByClosure(event: PageEvent) {
    this.pageByClosure = event.pageIndex;
    this.pageSizeByClosure = event.pageSize;

    if (this.spaceId) {
      this.loadSpaceClosuresByWarehouse();
    }
    else if (this.meterId) {
      this.loadMeterClosuresByWarehouse();
    }
  }

  clearFilters() {
    this.reportingYearFilter = undefined;
    this.reportingQuarterFilter = undefined;
    this.getAllDowntimes();
  }

  applyFilters() {
    this.getAllDowntimes(); //reload!
  }

}
