// This file corresponds with src\app\trueup\meter-true-up-detail\meter-true-up-detail-json-converter.ts

import { CollectionsDetailBase } from '../../collections-list-column-def';
import { PaginatedGetResult } from '../../../../core/models/paginated-get-result';
import { AbstractJsonConverter } from '../../../../core/json-converters/abstract-json-convertert';
import { Injectable } from '@angular/core';

@Injectable()
export class CollectionsDetailJsonConverter extends AbstractJsonConverter<CollectionsDetailBase> {
    getInstance(): CollectionsDetailBase {
        return new CollectionsDetailBase();
    }

    jsonToLevelSpecificDetail(responseJson: any): PaginatedGetResult<CollectionsDetailBase> {
        // tslint:disable-next-line: prefer-const
        let paginatedResult = this.jsonToPaginatedResult(responseJson);

   //     paginatedResult.items.forEach(CollectionsDetailBase => {
   //         this.jsonToBaseCollectionsDetail(CollectionsDetailBase);
   //     });

        return paginatedResult;
    }

}
