import { RatePackage } from '../models/rate-package';

export class RatePackageJsonConverter {
    static jsonToRatePackage(json: any): RatePackage {
        const toReturn = new RatePackage();
        for (const prop in json) {
            toReturn[prop] = json[prop];
        }

        return toReturn;
    }

    static jsonToRatePackageList(json: any): Array<RatePackage> {
        const toReturn = new Array<RatePackage>();

        json.forEach(rP => {
            toReturn.push(this.jsonToRatePackage(rP));
        });
        return toReturn;
    }

    static jsonToWeeklyFUA(json: any): Map<string, number> {
        const toReturn: Map<string, number> = new Map<string, number>();
        json.utilizationAmountPerDayOfWeek.forEach(function (o: any) {
          toReturn.set(o.key, o.value);
        });
        return toReturn;
    }
}
