exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "mat-card-content {\r\n  padding:2%;\r\n}\r\n\r\n.action-options{\r\n  margin-top:15px;\r\n}\r\n\r\n.label {\r\n  font-style: bold;\r\n}\r\n\r\n.detail {\r\n  padding:5px;\r\n  font-size:75%;\r\n}\r\n\r\n.push-checkbox {\r\n  padding-top:15px;\r\n}\r\n\r\n.divider{\r\n  margin-bottom:10px;\r\n}\r\n\r\n.mat-toolbar-row {\r\n  min-height: 35px;\r\n  height:35px;\r\n  font-size:125%;\r\n  font-weight: bold;\r\n}\r\n\r\n.mat-expansion-panel-header{\r\n  height:40px !important;\r\n}\r\n\r\n.pad-toolbar {\r\n  padding-top:40px;\r\n}\r\n\r\n.create-padding {\r\n  padding-right:25px;\r\n  padding-left:25px;\r\n}\r\n\r\n.button-padding {\r\n  padding-top:15px;\r\n}\r\n\r\n.warning-label {\r\n  font-weight: bold;\r\n  font-size: 125%;\r\n}\r\n\r\n.form-warning {\r\n  font-style: italic;\r\n}\r\n\r\n.to-meter {\r\n  margin-top:15px;\r\n}\r\n\r\n.inventory-detail-padding {\r\n  margin-bottom:10px;\r\n  margin-left:10px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();