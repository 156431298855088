exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".padded-left {\r\n    padding-left: 5%;\r\n}\r\n\r\n.meter-details {\r\n    min-height: 25px !important;\r\n    max-height: 25px !important;\r\n    height: 25px !important;\r\n}\r\n\r\n.material-icons, .mat-icon-buttom  {\r\n  font-size: 75%;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();