export class SpaceStatusConstants {
    static active = 'Active';
    static activePending = 'Active - Pending';
    static created = 'Created';
    static inactive = 'Inactive';
    static removed = 'Removed';
    static pending = 'Pending';
    static moved = 'Moved';
    static reserved = 'Reserved';
}

export class SpaceAroundCornerConstants {
    static true = 'true';
    static false = 'false';
}





