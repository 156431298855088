import { SystemStartTimeService } from "../../system-start-time.service";

export class DateStringBuilder {
    static dateIsNullOrDefault(date:Date) {
        if(!date){
            return true;
        }
        date = new Date(date);
        if(date.getFullYear()==1970){
            return true;
        }
        return false;

    }
    static buildStringFromDate(date: Date): string {
        if (this.dateIsNullOrDefault(date)) {
            return null;
        }

        date = new Date(date);
        const utcHoursWithLeadingZero = ('0' + date.getUTCHours()).slice(-2);
        const utcMinutesWithLeadingZero = ('0' + date.getUTCMinutes()).slice(-2);
        const utcSecondsWithLeadingZero = ('0' + date.getUTCSeconds()).slice(-2);

        const formattedLastActivationDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)}-${date.getUTCDate()}T${utcHoursWithLeadingZero}:${utcMinutesWithLeadingZero}:${utcSecondsWithLeadingZero}-0:00`

      
        return formattedLastActivationDate;

    }
}
