import { DatePipe } from '@angular/common';
import { InventoryTimelineComponent } from './../../inventory-components/inventory-timeline/inventory-timeline.component';
import { SpaceSummary } from './../../spaces/space-summary';
import { Component, OnInit, ErrorHandler } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { InventoryFilterRepresentation } from '../../inventory-filter/inventory-filter-representation';
import { Block } from '../block';
import { BlockService } from '../blocks.service';
import { MeterService } from '../../meters/meters.service';
import { MeterSummary } from '../../meters/meter-summary';
import { SpaceService } from '../../spaces/spaces.service';
import { Space } from '../../spaces/space';
import { SpaceStatusConstants } from '../../spaces/space-constants';
import { EditBlockDialogComponent } from '../edit-block-dialog/edit-block-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TokenCache } from '../../../security/token-cache';
import { SystemStartTimeService } from '../../../system-start-time.service';
declare var google: any;

@Component({
  selector: 'app-block',
  templateUrl: './block-detail.component.html',
  styleUrls: ['./block-detail.component.css']
})

export class BlockDetailComponent implements OnInit {

  block: Block;
  map: any;
  blockId: string;
  queryString: string;
  meterInventoryFilter = new InventoryFilterRepresentation();
  filterRepresentationSubject = new Subject<InventoryFilterRepresentation>();
  metersOnBlock = Array<MeterSummary>();
  signsOnlyParentMeterID: string = null;
  signsOnlyParentBlockID: string = null;
  signsOnlyParentMeterGUID: string = null;
  signsOnlyParentBlockGUID: string = null;
  spacesOnBlock: SpaceSummary[];
  timelineComponent: InventoryTimelineComponent;
  locationTypes = new Array<string>();
  physicalBlockIDs = new Array<any>();
  selectedIndex: number;
  readonly relatedTabHeaderDefault = 'Related Blocks';
  relatedBlocksTabHeader = this.relatedTabHeaderDefault;

  dialogRef: MatDialogRef<EditBlockDialogComponent>;
  showEditBlockButton: boolean;
  errorMessage: string;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['NewValue', 'OldValue', 'ChangedBy', 'EffectiveDate'];

    constructor(private _blockService: BlockService, private _spaceService: SpaceService, private _meterService: MeterService, private spaceService: SpaceService, private route: ActivatedRoute, private router: Router,
    private datePipe: DatePipe, private errorHandler: ErrorHandler, private dialog: MatDialog, private snackBar: MatSnackBar, private TokenCache: TokenCache, private startDateService: SystemStartTimeService) { }

    inventoryCountChanged(event: number) {
      if (this.block) {
        this.block.activeMetersOnBlock = event;
      }
    }

    ngOnInit(): void {
        this.blockId = this.route.snapshot.paramMap.get('id');
        this._blockService.getBlockById(this.blockId).subscribe(
             result => {
                 this.block = result;

                 this.meterInventoryFilter.BlockId = this.blockId;
                 this.filterRepresentationSubject.next(this.meterInventoryFilter);
             },
             error => {
               this.errorMessage = error._body;
                 console.log(error);
             }
        );

        this._blockService.getBlockAuditHistory(this.blockId)
        .subscribe(result => {
          this.dataSource = new MatTableDataSource<any>(result);
        },
        error => {
          console.log('Error: ' + error);
        });

        const filter = new InventoryFilterRepresentation();
        filter.BlockId = this.blockId;
        this.getSpacesOnBlock();
        this._meterService.getAllMeters('currentStatus', true, 0, 100, filter).subscribe(
            result => {
                this.metersOnBlock = result.items;
                this.metersOnBlock.forEach(meter => {
                    if (this.locationTypes.length === 0) {
                        this.locationTypes.push(meter.parkingType);
                    }
                    else {
                        const parkingTypeIsAccountedFor = this.locationTypes.find(parkingType => {
                            return parkingType === meter.parkingType;
                        });
                        if (parkingTypeIsAccountedFor !== undefined && parkingTypeIsAccountedFor !== null && parkingTypeIsAccountedFor.length === 0 ) {
                            this.locationTypes.push(meter.parkingType);
                        }
                    }
                    this.spaceService.getSpacesByMeterId(meter.id).subscribe(
                        result => {
                            if (result != null) {
                                const activeSpaces = result.filter(space => {
                                    if (space.currentStatus !== null && space.currentStatus !== undefined) {
                                        return space.currentStatus.toLowerCase() === SpaceStatusConstants.active.toLowerCase()
                                        || space.currentStatus.toLowerCase() === SpaceStatusConstants.reserved.toLowerCase();
                                    }
                                });
                                const hasAroundTheCornerBlockId = activeSpaces.filter(space => {
                                    return space.aroundTheCornerBlockId != null;
                                });
                                if (hasAroundTheCornerBlockId.length > 0) {
                                    this.signsOnlyParentMeterID = meter.displayId;
                                    this.signsOnlyParentMeterGUID = meter.id;
                                    this.signsOnlyParentBlockID = hasAroundTheCornerBlockId[0].aroundTheCornerBlockDisplayId;
                                    this.signsOnlyParentBlockGUID = hasAroundTheCornerBlockId[0].aroundTheCornerBlockId;
                                }
                            }
                        }
                    );
                });
            }
        );
        this.spaceService.getAllSpaces('currentStatus', true, 0, 100, filter).subscribe( result => {
            result.items.forEach(space => {
                var repeatId = false;
                this.physicalBlockIDs.forEach(physicalBlock => {
                    if(physicalBlock.displayId === space.physicalBlockDisplayId) {
                        repeatId = true;
                    }
                });
                if (space.physicalBlockDisplayId !== null && !repeatId) {
                    this.physicalBlockIDs.push({displayId: space.physicalBlockDisplayId, id: space.physicalBlockId});
                }
            });
        });

        this.showEditBlockButton = this.TokenCache.checkForCPMUser() || this.TokenCache.checkForLAZUser();
    }

    updateBlockAttributes(updatedBlock: Block) {
        this.block.description = updatedBlock.description;
        this.block.displayId = updatedBlock.displayId;
        this.block.areaName = updatedBlock.areaName;
        this.block.zoneName = updatedBlock.zoneName;
        this.block.location.streetName = updatedBlock.location.streetName;
        this.block.location.streetDirection = updatedBlock.location.streetDirection;
        this.block.location.numberRangeStart = updatedBlock.location.numberRangeStart;
        this.block.location.numberRangeEnd = updatedBlock.location.numberRangeEnd;
        this.block.location.streetSuffix = updatedBlock.location.streetSuffix;
        this.block.ward = updatedBlock.ward;
        this.block.spaceTypes = updatedBlock.spaceTypes;
    }

    editBlock() {
        this.dialogRef = this.dialog.open(EditBlockDialogComponent, {
            height: '440px',
            width: '400px',
          });
          const blockUpdateModel = JSON.parse(JSON.stringify(this.block));
          this.dialogRef.componentInstance.block = blockUpdateModel;



          this.dialogRef.afterClosed().subscribe(result => {
           if (result) {
               const block = result as Block;
               this._blockService.updateBlockAttributes(block).subscribe(
                   result => {
                       if (result) {
                           this.updateBlockAttributes(block);
                           this.snackBar.open('Successfully updated block attributes', '', <MatSnackBarConfig>{
                            duration: 2000,
                          });
                       }
                   },
                   error => {
                    this.snackBar.open('Failed to update block attributes', 'X');
                   });
           }
          });
    }

    loadMap() {
        const address = this.findMiddleOfBlock() + ' ' + this.block.location.streetDirection + ' ' + this.block.location.streetName + ', Chicago, IL';

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === 'OK') {
                const mapProp = {
                    center: results[0].geometry.location,
                    zoom: 15
                };

                this.map = new google.maps.Map(document.getElementById('map'), mapProp);

                const marker = new google.maps.Marker({
                    map: this.map,
                    position: results[0].geometry.location
                });
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    findMiddleOfBlock(): number {
        const blockEnd = parseInt(this.block.location.numberRangeEnd);
        const blockStart = parseInt(this.block.location.numberRangeStart);
        const middle = (blockEnd + blockStart) / 2;
        return Math.round(middle);
    }

    goToSignsOnlyParentBlockID() {
        const url = '/inventorymanagement/blocks/' + this.signsOnlyParentBlockGUID;
        this.router.navigate([url]);
        window.location.reload();
    }

    getSpacesOnBlock() {
        const filter = new InventoryFilterRepresentation();
        filter.BlockId = this.blockId;
        this._spaceService.getAllSpaces('currentStatus', true, 0, 50, filter).subscribe(
        result => {
            this.spacesOnBlock = result.items;
        });
    }

    changeSpaceTimeline() {
        this.timelineComponent = new InventoryTimelineComponent(this._spaceService, this._meterService, this.datePipe, this.startDateService, this.errorHandler);
        const space = new Space();
        space.id = this.spacesOnBlock[this.selectedIndex].id;
        this.timelineComponent.space = space;
        this.timelineComponent.ngOnChanges();
    }

    goToCLZPhysicalBlockID(id) {
        const url = '/inventorymanagement/blocks/' + id;
        this.router.navigate([url]);
        window.location.reload();
    }

    changeRelatedBlocks(event) {
        this.relatedBlocksTabHeader = this.relatedTabHeaderDefault + ` (${event})`;
    }
}
