import { MeterDistribution, MeterDistributionKeyValuePair } from './models/meter-distribution';
import { SpaceCollection, SpaceDisplayIdKeyValuePair } from './models/space-collection';
import { MonthToMonthName} from './models/month-name-dictionary';

import * as moment from 'moment';

export class DistributionJsonConverter {
    static jsonToMeterDistribution(json: any): MeterDistributionKeyValuePair {
        let monthKey = 0;
        let meterDistribution = new MeterDistribution();
        for (let prop in json) {
            if(prop == 'forMonth') {
                var month = moment(json[prop]).format('MM');
                monthKey = Number(month);
                meterDistribution["forMonth"] = MonthToMonthName[Number(month)];
            }
            else {
                meterDistribution[prop] = json[prop];
            }
        }
        var toReturn : MeterDistributionKeyValuePair = {
            month: monthKey, meterDistribution: meterDistribution
        }
        return toReturn;
    }

    static jsonToSpaceCollection(json: any): SpaceCollection {
        let toReturn = new SpaceCollection();
        for (let prop in json) {
            if (prop == "distributionPercentage") {
                toReturn[prop] = Number(json[prop]) * 100;  // Convert decimal to percent
            }
            else {
                toReturn[prop] = json[prop];
            }
        }

        return toReturn;
    }

    static jsonToMeterDistributions(json: any): MeterDistributionKeyValuePair[] {
        var toReturn: {month: number, meterDistribution: MeterDistribution} [] = [];

        for (var i = 0; i < json.length; i++) {
            var keyValuePair = this.jsonToMeterDistribution(json[i]["value"]);
            toReturn.push(keyValuePair);
        }
        return toReturn;
    }

    static jsonToSpaceCollections(json: any): SpaceCollection[] {
        var toReturn: SpaceCollection[] = [];
        var spaceCollectionJson = json["result"];
        for (var i = 0; i < spaceCollectionJson.length; i++) {
            toReturn.push(this.jsonToSpaceCollection(spaceCollectionJson[i]));
        }
        return toReturn;
    }
    static jsonToSpaceDisplayIds(guids: any, json: any): SpaceDisplayIdKeyValuePair[] {
        var toReturn: SpaceDisplayIdKeyValuePair[] = [];
        for (var i = 0; i < guids.length; i++) {
            var keyValuePair: SpaceDisplayIdKeyValuePair =
            {
                id: "",
                displayId: ""
            };
            keyValuePair.id = guids[i];
            if(json[guids[i]])
            {
                keyValuePair.displayId = json[guids[i]];
            }
            toReturn.push(keyValuePair);
        }
        return toReturn;
    }
}