exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-form-field {\r\n  font-size: 8pt;\r\n  padding-top: 0px !important;\r\n  padding-bottom: 0px !important;\r\n  margin-right: 5px;\r\n}\r\n\r\n.filter-right {\r\n  border-right: 2px solid lightgray;\r\n  margin-left: 5px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.filterButton {\r\n  margin: 10px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();