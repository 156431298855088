import { AbstractControl, ValidatorFn } from '@angular/forms';

export function RemoveSpaceTooManySpacesValidator(numSpacesOnMeter: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    const numberOfRemainingSpaces = numSpacesOnMeter - control.value;

    return numberOfRemainingSpaces < 0 ? { RemoveSpaceTooManySpaces : true} : null;
  };
}

export function RemoveSpaceMoreThanZeroSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    return Number.parseInt(control.value) <= 0 ? { RemoveSpaceMoreThanZeroSpaces : true} : null;
  };
}

