import { Component, ViewChild, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-loading-zone-impact-component',
  templateUrl: './loading-zone-impact.component.html',
  styleUrls: ['./loading-zone-impact.component.css']
})
export class LoadingZoneImpactComponent {

  constructor(private snackbar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: Array<string>, public dialogRef: MatDialogRef<any>) { }

}
