import { Component, OnInit } from '@angular/core';

import { TokenCache } from './../token-cache';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {
  userRole: string;

  constructor(private TokenCache: TokenCache) {

  }
  ngOnInit() {
    this.userRole = this.TokenCache.getUserRoles()[0];
  }

}
