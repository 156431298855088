import { Component, OnInit, Input } from '@angular/core';
import { RatePackageService } from './../../../inventory-management/rate-packages/services/rate-package.service'

@Component({
    selector: 'app-work-order-rate-package-information',
    templateUrl: './work-order-rate-package-information.component.html',
    styleUrls: ['./work-order-rate-package-information.component.css']
})
export class WorkOrderRatePackageInformationComponent implements OnInit {
    ratePackageName: string;
    ratePackageDescription: string;
    
    @Input() ratePackageId: string;

    constructor(private _ratePackageService: RatePackageService) { }

    ngOnInit() {
        this._ratePackageService.getRatePackageById(this.ratePackageId)
        .subscribe(result => {
            this.ratePackageName = result.name;
            this.ratePackageDescription = result.description;
        })
    }
}