import { WorkOrder } from './../../../models/work-order';
import { ActionField } from '../../../models/action-field';
import { ActionFormWithValidation } from './action-form-with-validation';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { RelocateMeterConstants } from './../../../action-field/action-field-constants';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { InventoryIdExistsValidation } from '../form-field-validators/inventory-id-validators';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { debounceTime } from 'rxjs/operators';

export class RelocateMeterForm extends ActionFormWithValidation {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, historicalDate: Date, overrideAsCity:boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate,  overrideAsCity, workOrder, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this.onChanges();
  }

  private onChanges() {
    this.setInitialForm();

    if (this.isCityUser()) {
      this.setCityFormValidation();
    }

    this.subscribeToControl(RelocateMeterConstants.RelocateEffectiveImmediately).subscribe(value => {
      if (value) {
        this.disableControl(RelocateMeterConstants.SelectRelocationDate);
      }
      else {
        this.enableControl(RelocateMeterConstants.SelectRelocationDate);
        this.setAsRequired(RelocateMeterConstants.SelectRelocationDate);
      }
    });

    if(!this.isCityUser()) {
      this.subscribeToControl(RelocateMeterConstants.RelocateToDifferentBlock).subscribe(value => {

        //TODO: uncomment below when there is time to test
        //happens on initial creation of the checkbox, unsure why
        // if(value === null
        //   || value === undefined
        //   || value === ""){ //result is sometimes null or undefined, default to false
        //   value = false; //but should always be set
        //   this.setValue(RelocateMeterConstants.SelectNewBlock, value);
        // }

        if(!value) {
          this.disableControl(RelocateMeterConstants.SelectNewBlock);
        }
        else {
          this.enableControl(RelocateMeterConstants.SelectNewBlock);
          this.setAsRequired(RelocateMeterConstants.SelectNewBlock);
        }
      })
    }

  }

  private setInitialForm(): void {
    if (this.workOrderActionJsonObject) {
      if (this.isCityUser()) {
        this.setMeterTextField(RelocateMeterConstants.MeterIdText, RelocateMeterConstants.OldMeterStreetNumber);
      }
      else {
        this.setMeterSelectIdBasedOnGuid(RelocateMeterConstants.MeterSelect);
      }
    }
    else {
      this.disableControl(RelocateMeterConstants.SelectRelocationDate);
      this.setValue(RelocateMeterConstants.RelocateEffectiveImmediately, true);

      //TODO: should not fill this in when City
      //if(!this.isCityUser()) {
      this.setValue(RelocateMeterConstants.RelocateToDifferentBlock, false);
      //}
    }
  }

  private setCityFormValidation(): void {
    this.setAsyncValidator(RelocateMeterConstants.MeterIdText, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, null,null,this));
    this.subscribeToControl(RelocateMeterConstants.MeterIdText).pipe(debounceTime(1500)).subscribe(result => {
      this.setMeterGuidBasedOnDisplayId(result, WorkorderActionJsonConstants.meterGuid);
    });
  }
}
