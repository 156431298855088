import { Router } from '@angular/router';
import { WorkOrdersService } from './../../work-orders/services/work-orders.service';
import { Component, Input, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { WorkOrderHistory } from './work-order-history';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeTypeConstants } from './change-type';
import { InventoryTimelineConstants } from '../inventory-components/inventory-timeline/inventory-timeline-constants';
import {FilterOperator} from '../../core/query-strings/query-string-builder';
import { WorkOrderFilterRepresentation } from '../../work-orders/work-order-filter/work-order-filter-representation';

@Component({
  selector: 'app-work-order-history',
  templateUrl: './work-order-history.component.html',
  styleUrls: ['./work-order-history.component.css']
})
export class WorkOrderHistoryComponent<TModel extends WorkOrderHistory> implements AfterViewInit, OnInit {

  @Input() workOrderHistories: TModel[];
  @Input() dataSource: MatTableDataSource<ActionHistoryDictionary>;
  @Input() actionHistoryDictionaries = Array<ActionHistoryDictionary>();
  @Input() workOrderFilter: WorkOrderFilterRepresentation;

  displayedColumns = ['date', 'workOrderId', 'actionDetail', 'newValue', 'eur', 'reservedPower', 'historicalChange'];
  changeTypes = [ChangeTypeConstants.All, ChangeTypeConstants.ReservePower, ChangeTypeConstants.WorkOrders];
  changeTypeFilter = ChangeTypeConstants.All;
  filterDate: Date;
  filterDateRange: Date;
  dateRangeDropdown = [FilterOperator.GreaterThan, FilterOperator.LessThan,
    FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between];

  @ViewChild(MatSort, {static: true}) matSort: MatSort;

  constructor(private _workOrderService: WorkOrdersService, private router: Router) { }

  ngOnInit() {
    if (!this.workOrderFilter) {
      this.workOrderFilter = new WorkOrderFilterRepresentation();
    }
  }

  getWorkOrderActionIds(workOrderActionIds) {
    this._workOrderService.getWorkOrdersByWorkOrderActionIds(workOrderActionIds)
    .subscribe(result => {
      if (result.length) {
        this.workOrderHistories.forEach(history => {
          const workOrders = [];
          result.forEach(r => {
            //check all the work order LODs, it may not be the newest that matches
            r.letterOfDirections.filter(latestLod => {
              latestLod.workOrderActions.filter(woa => {
                if (woa.id === history.workOrderActionId) {
                  history.isHistoricalChange = woa.historicalChange !== undefined
                                              || latestLod.hasHistoricalEffectiveDate;
                  workOrders.push(r);
                }
              });
            });
          });

          if (workOrders.length > 0) {
            const workOrder = workOrders[0];
            history.workOrderId = workOrder.id;
            history.workOrderName = workOrder.displayId.toString();
          }
        });
      }
    });
  }

  refreshDataSource() {
    this.actionHistoryDictionaries.forEach(actionHistory => actionHistory.histories = actionHistory.histories.filter(x => x.changeType !== InventoryTimelineConstants.RatePackageReversibility));
    this.actionHistoryDictionaries = this.actionHistoryDictionaries.filter(x => x.histories.length > 0);
    this.dataSource = new MatTableDataSource(this.actionHistoryDictionaries);
    this.dataSource.data = this.sortByDate();
  }

  commaSeperatedActionDetailList(element: ActionHistoryDictionary) {
    let list = '';
    for (let i = 0; i < element.histories.length; i++) {
      if (element.histories[i].changeType.toLowerCase() === 'rate package reserved power') {
        list += 'Rate Package';
      }
      else {
        list += element.histories[i].changeType;
      }

      if (i !== element.histories.length - 1) {
        list += ', ';
      }
    }
    return list;
  }

  commaSeperatedNewValueList(element: ActionHistoryDictionary) {
    let list = '';
    for (let i = 0; i < element.histories.length; i ++) {
      list += element.histories[i].newValueName;
      if (i !== element.histories.length - 1) {
        list += ', ';
      }
    }
    return list;
  }

  route(id) {
    this.router.navigate(['/workorders/' + id]);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.matSort;
  }

  // clear each filter individually including data
  clearFilters() {
    this.changeTypeFilter = ChangeTypeConstants.All;
    this.workOrderFilter.EffectiveDateRangeFilterOperator = FilterOperator.Between;
    this.workOrderFilter.EffectiveDate = null;
    this.workOrderFilter.EffectiveDateRangeEnd = null;
    this.refreshDataSource();
  }

  applyFilter() {
    this.dataSource = new MatTableDataSource(this.actionHistoryDictionaries);
    this.dataSource.data = this.dataSource.data
      .filter((history => {
        if (this.changeTypeFilter === ChangeTypeConstants.All) {
          if (this.workOrderFilter.EffectiveDate && this.workOrderFilter.EffectiveDate != null) {
            return this.checkFilterRange(history);
          }
          else {
            return history;
          }
        }
        else if (this.changeTypeFilter === ChangeTypeConstants.ReservePower) {
          if (this.workOrderFilter.EffectiveDate) {
            return this.checkFilterRange(history) && history.histories[0].isReservedPower;
          }
          else {
            return history.histories[0].isReservedPower;
          }
        }
        else if (this.changeTypeFilter === ChangeTypeConstants.WorkOrders) {
          if (this.workOrderFilter.EffectiveDate) {
            return this.checkFilterRange(history) && !history.histories[0].isReservedPower;
          }
          else {
            return !history.histories[0].isReservedPower;
          }
        }
      }));

    this.dataSource.data = this.sortByDate();
  }

  checkFilterRange(history: ActionHistoryDictionary) {
    const nonUtcDate = new Date(history.histories[0].effectiveTimeStamp);
    const workOrderEffectiveDate = new Date(nonUtcDate.getFullYear(), nonUtcDate.getUTCMonth(), nonUtcDate.getUTCDate()).setHours(0, 0, 0, 0);

    const effectiveDateOption = new Date(this.workOrderFilter.EffectiveDate).setHours(0, 0, 0, 0);

    if (this.workOrderFilter.EffectiveDateRangeFilterOperator === FilterOperator.Between) {
      return (effectiveDateOption <= workOrderEffectiveDate) &&
        (workOrderEffectiveDate <= new Date(this.workOrderFilter.EffectiveDateRangeEnd).setHours(0, 0, 0, 0));
    } else {
      switch (this.workOrderFilter.EffectiveDateRangeFilterOperator) {
        case FilterOperator.GreaterThan:
          return workOrderEffectiveDate > effectiveDateOption;
        case FilterOperator.GreaterThanOrEqual:
          return workOrderEffectiveDate >= effectiveDateOption;
        case FilterOperator.LessThan:
          return workOrderEffectiveDate < effectiveDateOption;
        case FilterOperator.LessThanOrEqual:
          return workOrderEffectiveDate <= effectiveDateOption;
        default:
          return true;
      }
    }
  }
  effectiveDateFilterOperatorChange(event) {
    if (event.value !== 'between') {
      this.workOrderFilter.EffectiveDateRangeEnd = undefined;
      this.workOrderFilter.EffectiveDateRangeEndDisplayString = undefined;
    }
  }

  clearDate() {
    this.filterDate = null;
  }

  clearDateRange() {
    this.filterDateRange = null;
  }

  sortByDate() {
    return this.dataSource.data.sort((a, b) => {
      return this.determineSortOrder(a.histories[0].effectiveTimeStamp, b.histories[0].effectiveTimeStamp, false);
    });
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
        return this.determineSortOrder(a.histories[0].effectiveTimeStamp, b.histories[0].effectiveTimeStamp, isAsc);
        case 'workOrderId':
        return this.determineSortOrder(a.histories[0].workOrderName, b.histories[0].workOrderName, isAsc);
        case 'eur':
        return this.determineSortOrder(a.histories[0].expectedUtilizationRate, b.histories[0].expectedUtilizationRate, isAsc);
        default: return 0;
      }
    });
  }

  determineSortOrder(a, b, isAsc) {
    const ret = (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    return ret;
  }

}

export class ActionHistoryDictionary {
  actionId: string;
  histories: Array<WorkOrderHistory> = new Array<WorkOrderHistory>();
}
