import { WorkOrderQueryStringBuilder } from './work-order-query-string-builder';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderJsonConverter } from '../work-order-json-converter';
import { WorkOrder } from '../models/work-order';
import { WorkOrderUpdate } from '../models/work-order-update';
import { WorkOrderChangeStatus } from '../models/work-order-change-status';
import { SearchableWorkOrder } from '../models/searchable-work-order';
import { WorkOrderHttpService } from './work-order-http.service';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { WorkOrderFilterRepresentation } from '../work-order-filter/work-order-filter-representation';
import { WorkOrderGroup } from '../models/work-order-group';
import { WorkOrderComment } from '../models/work-order-comment';
import { WorkOrderResponse } from '../models/approval-models';
import { LoadingZoneImpactChangeGroup } from '../models/loading-zone-impacts/loading-zone-impact-change-group';
import { SurveyRequest } from '../models/survey-request';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class WorkOrdersService {

  constructor(private workorderHttp: WorkOrderHttpService, private workOrderJsonConverter: WorkOrderJsonConverter) { }

  public getAllWorkOrders(orderByCategory: string = 'currentStatus', isAscending: boolean = true,
                          skip: number = 25, take: number = 25,
                        filter: WorkOrderFilterRepresentation): Observable<PaginatedGetResult<SearchableWorkOrder>> {
    var order = isAscending ? 'asc' : 'desc';
    if(orderByCategory === 'escalated' && order === 'asc') {
      orderByCategory = 'isEscalated';
    }
    else if(orderByCategory === 'escalated' && order === 'desc') {
      orderByCategory = 'isEscalatedReversed';
      order = 'asc';
    }

    let filterQueryString = '';

    if (filter) {
      filterQueryString = WorkOrderQueryStringBuilder.createFilterQueryString(filter);
    }

    const endpoint = `/workorders?orderby=${orderByCategory}%20${order}&skip=${skip}&top=${take}&${filterQueryString}`;

    return this.workorderHttp.get(endpoint, null)
       .pipe(
         map(response => response != null ? this.workOrderJsonConverter.jsonToPaginatedResult(response) : null),
         catchError(error => this.handleError(error))
       );
  }

  public getWorkOrderStatusByWorkOrderId(workOrderId:string, workOrderActionId:string){
    return this.workorderHttp.get(`/workorders/${workOrderId}/${workOrderActionId}/status/`, null)
    .pipe(
      map(response => response != null ? this.workOrderJsonConverter.jsonToItem(response) : null),
      catchError(error => this.handleError(error))
    );
  }

  public getWorkOrderById(id: string): Observable<WorkOrder> {
    return this.workorderHttp.get('/workorders/' + id, null)
      .pipe(
        map(response => response != null ? this.workOrderJsonConverter.jsonToItem(response) : null),
        catchError(error => this.handleError(error))
      );
  }

  public exportWorkOrders(orderByCategory: string = 'currentStatus', isAscending: boolean = true, filter: WorkOrderFilterRepresentation): Observable<any> {
    const order = isAscending ? 'asc' : 'desc';

    const filterQueryString  = WorkOrderQueryStringBuilder.createFilterQueryString(filter);
    const endpoint = `/workorders/export?orderby=${orderByCategory}%20${order}&${filterQueryString}`;
    return this.workorderHttp.getFile(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public getWorkOrdersByWorkOrderActionIds(workOrderActionIds: string[]): Observable<WorkOrder[]> {
    return this.workorderHttp.post('/workorders/workOrderActions', workOrderActionIds)
     .pipe(
       map(response => response != null ? this.workOrderJsonConverter.jsonToListOfItems(response) : null),
       catchError(error => this.handleError(error))
     );
  }

  public saveWorkOrder(workOrder: WorkOrderUpdate, overrideToCityUserRole:boolean) {
    var url = '/workOrders/submit';
    if(overrideToCityUserRole){
      url += '/role/city';
    }
    if (workOrder.workOrderStatus) {
      return this.workorderHttp.put('/workOrders/save', workOrder)
        .pipe(catchError(error => Observable.throwError(error)));
    }
    else {
      return this.workorderHttp.post('/workOrders/save', workOrder)
        .pipe(catchError(error => Observable.throwError(error)));
    }
  }

  public modifyAcceptedWorkOrder(workOrder: WorkOrderUpdate) {
      return this.workorderHttp.put('/workOrders/modify', workOrder)
        .pipe(catchError(error => Observable.throwError(error)));
  }

  public submitWorkOrder(workOrder: WorkOrderUpdate, overrideToCityUserRole:boolean) {
    var url = '/workOrders/submit';
    if(overrideToCityUserRole){
      url += '/role/city';
    }

    if (workOrder.id) {
      return this.workorderHttp.put(url, workOrder)
        .pipe(catchError(error => Observable.throwError(error)));
    }
    else {
      return this.workorderHttp.post(url, workOrder)
        .pipe(catchError(error => Observable.throwError(error)));
    }
  }

  public requestSurveyForWorkOrder(surveyRequest: SurveyRequest) {
    return this.workorderHttp.postToSurvey('/tasks/api/vendor/work_order', surveyRequest)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public cancelWorkOrder(workOrderChange: WorkOrderChangeStatus) {

    return this.workorderHttp.put(`/workOrders/${workOrderChange.workOrderId}/cancel`, workOrderChange)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public undoWorkOrder(workOrderId:string, workOrderResponse: WorkOrderResponse):Observable<any> {
    return this.workorderHttp.post(`/workOrders/${workOrderId}/approveUndo`, workOrderResponse)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public pauseWorkOrder(workOrderId: string, comments: string) {
    const payload = JSON.stringify(comments);
    return this.workorderHttp.put(`/workOrders/${workOrderId}/pause`, payload)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public reinstateWorkOrder(workOrderId: string, comments: string) {
    const payload = JSON.stringify(comments);
    return this.workorderHttp.put(`/workOrders/${workOrderId}/reinstate`,  payload)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public getAllWorkOrderGroups(): Observable<WorkOrderGroup[]> {
    return this.workorderHttp.get('/workordergroups/', null)
      .pipe(
        map(response => response != null ? this.workOrderJsonConverter.jsonToListOfWorkOrderGroups(response) : null),
        catchError(error => this.handleError(error))
      );
  }

  public createGroupAndAddWorkOrder(workOrderId: string, workOrderGroupName: string): Observable<WorkOrderGroup[]> {
    const payload = {'name' : workOrderGroupName};

    return this.workorderHttp.post(`/workordergroups/addworkorder/${workOrderId}`, payload)
      .pipe(catchError(error => this.handleError(error)));
  }

  public AddWorkOrderToGroup(workOrderId: string, workOrderGroupId: string): Observable<WorkOrderGroup[]> {
    const payload = {'id' : workOrderGroupId};
    return this.workorderHttp.put(`/workordergroups/addworkorder/${workOrderId}`, payload)
      .pipe(catchError(error => this.handleError(error)));
  }

  public DeleteWorkOrderGroup(workOrderGroupId: string): Observable<string> {
    return this.workorderHttp.put(`/workordergroups/delete/${workOrderGroupId}`, null)
      .pipe(catchError(error => this.handleError(error)));
  }

  public UpdateCanvasId(workOrderId: string, canvasId: string): Observable<string> {
    const payload = {'canvasId' : canvasId};
    return this.workorderHttp.put(`/workorders/${workOrderId}/updatecanvasid`, payload)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getAllRelevantWorkOrders(workOrder: WorkOrderUpdate) {
    return this.workorderHttp.put(`/workOrders/${workOrder.id}/findImpacted`, workOrder.workOrderActions)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public toggleWorkOrderEscalation(workOrderId: string, isEscalated: boolean) {

    return this.workorderHttp.put(`/workOrders/${workOrderId}/isEscalated`, isEscalated)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public RemoveWorkOrderFromGroup(workOrderId: string): Observable<string> {
    return this.workorderHttp.put(`/workordergroups/reset/${workOrderId}`, '')
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public GetAllWorkOrderAttachmentsForWorkOrder(workOrderId: string): Observable<any> {
    return this.workorderHttp.get(`/workorders/${workOrderId}/attachments`, null)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public DownloadWorkOrderAttachment(workOrderId: string, attachmentId: string): Observable<any> {
    return this.workorderHttp.getFile(`/workorders/${workOrderId}/attachments/${attachmentId}`, null)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public DeleteWorkOrderAttachment(workOrderId: string, attachmentId: string): Observable<any> {
    return this.workorderHttp.put(`/workorders/${workOrderId}/attachments/${attachmentId}/isDeleted`, null)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  public AddWorkOrderCommentAndTag(workOrderId: string, comment: WorkOrderComment): Observable<any> {
    return this.workorderHttp.post(`/workorders/${workOrderId}/comments`, comment)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  createWorkOrderIdWithComments(workOrderId, comments) {
    return {
      workOrderId: workOrderId,
      comments: comments
    };
  }

  getWorkOrderActionIdsByWorkOrderDisplayId(workOrderDisplayId): Observable<any> {
    return this.workorderHttp.get(`/workorders/${workOrderDisplayId}/workOrderActionIds`, null)
      .pipe(catchError(error => Observable.throwError(error)));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
