import { RegularRateAdjustmentSpaceChange } from './regular-rate-adjustment-space-change';
import { SingleLoadingZoneImpact } from '../../../models/loading-zone-impacts/single-loading-zone-impact';

export class SubmitRegularRateAdjustmentChangeGroup {

    areaDisplayIds: string[];

    blockDisplayIds: string[];

    calculationDatetime: Date;

    changedDateTime: Date;

    changes: RegularRateAdjustmentSpaceChange[];

    effectiveDate: Date;

    isIncrease: boolean;

    loadingZoneImpacts: SingleLoadingZoneImpact[];

    meterDisplayIds: string[];

    nodeSelection: string;

    rateChange: number;

    workOrderActionId: string;

    workOrderId: string;

    zoneDisplayIds: string[];
}
