exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".drill-down-width {\r\n  width: 150px;\r\n}\r\n\r\n.view-details {\r\n  width:50%;\r\n  font-size: 50%;\r\n  height: 75%;\r\n}\r\n\r\n.top-margin {\r\n  margin-top: 25px;\r\n}\r\n\r\n.manual-collection-table-row {\r\n  border-bottom: 1px solid lightgray ;\r\n}\r\n\r\n.collection-table {\r\n  padding-top:10px;\r\n  padding-bottom:5px;\r\n}\r\n\r\n.mat-form-field {\r\n  font-size:8pt;\r\n  padding-top:0px !important;\r\n  padding-bottom:0px !important;\r\n  margin-right:5px;\r\n}\r\n\r\n.filter-right {\r\n  border-right:2px solid lightgray;\r\n  margin-left:5px;\r\n  margin-right:5px;\r\n}\r\n\r\n.filter-left {\r\n  border-left:2px solid lightgray;\r\n}\r\n\r\n.filterButton {\r\n  margin:10px;\r\n}\r\n\r\n.filter {\r\n  padding: 5px;\r\n  color:white;\r\n  background-color: #607d8b;\r\n  min-width:100px;\r\n  width:auto;\r\n  font-size:65%;\r\n  text-align: center;\r\n}\r\n\r\n.material-icons {\r\n  padding-right:5px;\r\n}\r\n\r\n.mat-chip:hover {\r\n  cursor: pointer;\r\n}\r\n\r\n.mat-option {\r\n  font-size:8pt !important;\r\n}\r\n\r\n.mat-expansion-panel, .mat-expansion-panel-body, .mat-expansion-panel-content {\r\n  min-width:1250px !important;\r\n}\r\n\r\n.mat-expansion-header {\r\n  width:auto;\r\n}\r\n\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();