import { Component, Input, OnInit } from '@angular/core';
import { MeterService } from '../../../inventory-management/meters/meters.service';
import { Meter } from '../../../inventory-management/meters/meter';
import { MeterInventoryChange } from './../models/meter-inventory-change';
import { WorkOrderAction } from '../../models/work-order-action';

@Component({
    selector: 'app-work-order-relocate-meter',
    templateUrl: './relocate-meter.component.html',
    styleUrls: ['./relocate-meter.component.css']
})
export class RelocateMeterComponent implements OnInit {
    @Input() meterInventoryChange: MeterInventoryChange;
    @Input() meterId: string;
    @Input() workOrderAction: WorkOrderAction;

    selectedMeter: Meter;

    constructor(public meterService: MeterService) { }

    ngOnInit() {
        this.meterService.getMeterById(this.meterId)
            .subscribe(result => {
                this.selectedMeter = result;
                this.workOrderAction.meterInventoryChangesFormComplete = true;
            },
            error => {
                //should not call this complete if we could not not load the meter
                this.workOrderAction.meterInventoryChangesFormComplete = false;
            });
    }
}
