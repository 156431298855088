import { LoadingZoneImpactAssessmentPostBody } from './../../work-orders/models/loading-zone-impacts/loading-zone-impact-assessment-body';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TimeZoneConverter } from '../../core/time-zone/time-zone-converter';

@Injectable()
export class LoadingZoneImpactService {
    constructor(private http: HttpClient) { }

    public getAllImpactedSpaces(originalPostBody: LoadingZoneImpactAssessmentPostBody): Observable<any> {

        //clone so that we don't modify the original object
        var cloneBody = {...originalPostBody};
        cloneBody.effectiveDate = TimeZoneConverter.EnsureChicagoTime(cloneBody.effectiveDate);

        return this.http.post('/loadingzoneimpact/assess', cloneBody)
          .pipe(catchError((error) => this.handleError(error)));
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
