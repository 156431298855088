import { RevenueReconciliationService } from "./services/revenue-reconciliation-service";
import { OnInit, Component, ErrorHandler } from "@angular/core";
import { SystemStartTimeService } from "../../system-start-time.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-revenue-reconciliation',
  templateUrl: './revenue-reconciliation.component.html',
  styleUrls: ['./revenue-reconciliation.component.css']
})
export class RevenueReconciliationComponent implements OnInit {
  constructor(private _revenueReconciliationService: RevenueReconciliationService,
    private _startTimeService: SystemStartTimeService,
    private _errorHandler: ErrorHandler, private snackbar: MatSnackBar) { }
  
  reportLoading:boolean;
  yearDropdown = [];
  quarterDropdown = ['Q1', 'Q2', 'Q3', 'Q4'];

  selectedYear: number = null;
  selectedQuarter: number = null;

  buttonEnabled: boolean = false;

  ngOnInit() {
    this._startTimeService.getInitialSystemStartTime().subscribe(result => {
      for (var i = result.getFullYear(); i <= new Date().getFullYear(); i++) {
        this.yearDropdown.push(i);
      }
    },
      error => {
        console.log('error');
        this._errorHandler.handleError(error)
      });

  }

  quarterSelected(quarter: string) {
    switch (quarter) {
      case 'Q1':
        this.selectedQuarter = 1;
        break;
      case 'Q2':
        this.selectedQuarter = 2;
        break;
      case 'Q3':
        this.selectedQuarter = 3;
        break;
      case 'Q4':
        this.selectedQuarter = 4;
        break;
    }

    if (this.selectedYear !== null) {
      this.buttonEnabled = true;
    }
  }

  yearSelected() {
    if (this.selectedQuarter !== null) {
      this.buttonEnabled = true;
    }
  }

  getRevenueReconciliation() {
    let theFile = {};
    this.reportLoading = true;
    this._revenueReconciliationService.getRevenueReconciliationReport(this.selectedYear, this.selectedQuarter)
      .subscribe(response => {
        const result = JSON.parse(response['_body']);
          this._revenueReconciliationService.downloadReport(result.result).subscribe(result=>{
           
       },error=>{
           this.reportLoading = false;
            this.snackbar.open('Error downloading report','X');
       });
      },
        error => console.log("Error downloading the file."),
        () => console.log('Completed file download.'));
         this._revenueReconciliationService.isLoading.subscribe(result=>{
          this.reportLoading = result.loading;
        });
      }
}
