import { SpaceInventoryChange } from './../../inventory-information/models/space-inventory-change';
import { Component, Input, OnInit } from '@angular/core';

import { WorkOrderAction } from './../../models/work-order-action';
import { LoadingZoneImpactChange } from '../../models/loading-zone-impacts/loading-zone-impact-change-group';

@Component({
  selector: 'app-completed-inventory-changes',
  templateUrl: './completed-inventory-changes.component.html',
  styleUrls: ['./completed-inventory-changes.component.css']
})
export class CompletedInventoryChangesComponent implements OnInit {

  constructor() { }

  @Input() workOrderAction: WorkOrderAction;

  loadingZoneImpactChanges: LoadingZoneImpactChange[] = [];

  ngOnInit() {
    this.workOrderAction.loadingZoneImpactChangeGroups.forEach(loadingZoneImpactChangeGroup => {
      loadingZoneImpactChangeGroup.relatedChanges.forEach(relatedChange => {
        const foundLoadingZoneImpactChange = this.loadingZoneImpactChanges.find(x => {
          return x.spaceId === relatedChange.spaceId;
        });

        if (foundLoadingZoneImpactChange == null || foundLoadingZoneImpactChange === undefined) {
          this.loadingZoneImpactChanges.push(relatedChange);
        }
      });
    });

    this.loadingZoneImpactChanges = this.loadingZoneImpactChanges.sort((a, b) => {
       return a.spaceDisplayId > b.spaceDisplayId ? 1 : -1;
    });
  }


  sort(array: any, sortField: string) {
    return array.sort((a, b) => {
      if (a[sortField] < b[sortField] ) { return -1; }
      else if (a[sortField]  < b[sortField] ) { return 1; }
      else { return 0; }
    });
  }

  navigateToBlockPage(blockId: string) {
    window.open('/inventorymanagement/blocks/' + blockId, 'noopener');
  }

  navigateToMeterPage(meterId: string) {
    window.open('/inventorymanagement/meters/' + meterId, 'noopener');
  }

  navigateToSpacePage(spaceId: string) {
    window.open('/inventorymanagement/spaces/' + spaceId, 'noopener');
  }

}
