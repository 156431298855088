import { Component, Optional, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'canvas-id-confirmation',
    templateUrl: './canvas-id-confirmation.component.html',
    styleUrls: ['./canvas-id-confirmation.component.css']
})
export class CanvasIdConfirmationComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<CanvasIdConfirmationComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

    workOrders: Array<string> = [];

    ngOnInit() {
        this.workOrders = this.data.workOrders;
    }

    submit() {
        this.dialogRef.close(true);
    }
}