import { BehaviorSubject ,  Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { TempRemovedMeter } from './temp-removed-meter';

export class TempRemovedMetersDataSource extends DataSource<TempRemovedMeter> {
    
    data: BehaviorSubject<TempRemovedMeter[]> = new BehaviorSubject<TempRemovedMeter[]>([]);

    constructor(private meters: TempRemovedMeter[]) {
        super();
    }

    setData(meterData: TempRemovedMeter[]) {
        this.data.next(meterData);
    }
    connect(): Observable<TempRemovedMeter[]> {
        return this.data;
    }

    disconnect() { }
}