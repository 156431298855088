export const environment = buildUpEnvironment();

function buildUpEnvironment(){

    const login_api = 'https://api.amigroupllc.com';
    const api_version = '1.0';
    const inventory_api = 'https://9e6eyjeftc.execute-api.us-east-1.amazonaws.com/Prod/api/' + api_version;
    const trueup_api = 'https://2t94d2sg8f.execute-api.us-east-1.amazonaws.com/Prod/api/' + api_version;
    const workorder_api = 'https://olfirew08l.execute-api.us-east-1.amazonaws.com/Prod/api/' + api_version;
    const reporting_api = 'https://np3e65hpf6.execute-api.us-east-1.amazonaws.com/Prod/api/' + api_version;
    const closure_site = 'https://closures.amigroupllc.com/';


    return {
      LOGIN_API_BASE: login_api,
      API_BASE: inventory_api,
      TRUE_UP_API_BASE: trueup_api,
      WORKORDER_API_BASE: workorder_api,
      REPORTING_API_BASE: reporting_api,
      CLOSURE_SITE_BASE: closure_site,
      production: true,
      envName: 'prod'
    };
}

export class SecurityConstants {

  static BackOfficeSupervisor = 'Backoffice Supervisor';
  static CityApprover = 'City - TrueUp Approver';
  static CityUser = 'City - TrueUp User';
  static CPMAdmin = 'CPM TrueUp Admin';
  static CPMUser = 'CPM TrueUp User';
  static LAZAdmin = 'LAZ Work Order Admin';
  static LAZUser = 'LAZ Work Order User';
  static CPMElevatedApprover = 'CPMElevatedApprover';

}

export class RoleOptionsConstants {
  static CityRoleName = { title: 'City', value: 'City' };
  static ConcessionaireName = { title: 'Concessionaire', value: 'Concessionaire' };
}
