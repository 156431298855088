import { PaginatedGetResult } from "../models/paginated-get-result";
export abstract class AbstractJsonConverter<T>{

     jsonToPaginatedResult(responseJson: any): PaginatedGetResult<T> {
        var result = new PaginatedGetResult<T>();
        result.items = this.jsonToListOfItems(responseJson["items"]);
        result.columnSortedBy = responseJson["columnSortedBy"];
        result.count = responseJson["count"];
        result.pageSize = responseJson["pageSize"];
        result.numberOfPages = responseJson["numberOfPages"];
        result.totalItems = responseJson["totalItems"];
        return result;
    }

    jsonToListOfItems(items: any): T[] {
        var toReturn: T[] = [];

        if (items == null) {
            return toReturn;
        }

        for (var i = 0; i < items.length; i++){
            toReturn.push(this.jsonToItem(items[i]));
        }

        return toReturn;
    }

     jsonToItem(json: any): T {
        var item = this.getInstance();
        for (let prop in json) {
            item[prop] = json[prop];
        }
        return item;
    }

    static jsonToItem<T>(json: any, item:T):T {

        for (let prop in json) {
            item[prop] = json[prop];
        }
        return item;
    }

    static jsonToListOfItems<T>(items: any,getInstance: () => T): T[] {
        var toReturn: T[] = [];
        if (items == null) {
            return toReturn;
        }
        for (var i = 0; i < items.length; i++){
          var item = getInstance();
          toReturn.push(AbstractJsonConverter.jsonToItem(items[i], item));
        }

        return toReturn;
    }

    abstract getInstance():T;
}
