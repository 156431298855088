import { WorkOrder } from './../../models/work-order';
import { ActionConstants } from '../action-constants';
import { ActionField } from '../../models/action-field';
import { BaseActionForm } from './forms/base-action-form';
import { BlockService } from './../../../inventory-management/blocks/blocks.service';
import { ChangeContractTypeToConcessionForm } from './forms/change-contract-type-to-concession-form';
import { InstallMeterForm } from './forms/install-meter-form';
import { InstallSpaceForm } from './forms/install-space-form';
import { ChangeRatePackageForm } from './forms/change-rate-package-form';
import { MeterService } from './../../../inventory-management/meters/meters.service';
import { RatePackageService } from './../../../inventory-management/rate-packages/services/rate-package.service';
import { RelocateMeterForm } from './forms/relocate-meter-form';
import { RemoveSpaceForm } from './forms/remove-space-form';
import { ShiftSpaceForm } from './forms/shift-space-form';
import { TokenCache } from './../../../security/token-cache';
import { InactivateMeterForm } from './forms/inactivate-meter-form';
import { SpaceService } from '../../../inventory-management/spaces/spaces.service';
import { ReactivateMeterForm } from './forms/reactivate-meter-form';
import { LoadingZoneImpactService } from '../../../inventory-management/loading-zone-impact/loading-zone-impact.service';

export class ActionFormFactory {
  static tokenCache: TokenCache;

  constructor(tokenCache: TokenCache) { }

  static createActionFormBasedOnActionId(actionIndex: number, selectedActionId: Number, letterOfDirectionFormFields: ActionField[],
    workOrderActionJsonObject: any, hasHistoricalEffectiveDate: boolean, overrideAsCity, workOrder: WorkOrder, historicalDate?: Date, previousActivationEffectiveDate?: Date, _meterService?: MeterService, _blockService?: BlockService,
    _ratePackageService?: RatePackageService, _spaceService?: SpaceService, _loadingZoneImpactService?: LoadingZoneImpactService): RemoveSpaceForm | ShiftSpaceForm | ChangeContractTypeToConcessionForm | InstallMeterForm | ChangeRatePackageForm |
    RelocateMeterForm | InactivateMeterForm | BaseActionForm {
    let newFormClass: RemoveSpaceForm | ShiftSpaceForm | BaseActionForm;

    const actionIndexString = actionIndex.toString();
    if (selectedActionId === ActionConstants.ChangeContractTypeToConcessionId) {
      newFormClass = new ChangeContractTypeToConcessionForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, _loadingZoneImpactService, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.ChangeRatePackageId) {
      newFormClass = new ChangeRatePackageForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, _loadingZoneImpactService, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.InstallMeterId) {
      newFormClass = new InstallMeterForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, _loadingZoneImpactService, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.InstallSpaceId) {
      newFormClass = new InstallSpaceForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, _loadingZoneImpactService, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.RelocateMeterId) {
      newFormClass = new RelocateMeterForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.RemoveSpaceId) {
      newFormClass = new RemoveSpaceForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, _loadingZoneImpactService, historicalDate, previousActivationEffectiveDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.InactivateMeterId) {
      newFormClass = new InactivateMeterForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.ReactivateMeterId) {
      newFormClass = new ReactivateMeterForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, historicalDate, overrideAsCity, workOrder);
    }
    else if (selectedActionId === ActionConstants.ShiftSpaceId) {
      newFormClass = new ShiftSpaceForm(actionIndexString, letterOfDirectionFormFields, _meterService, _blockService, _spaceService, this.tokenCache, workOrderActionJsonObject, hasHistoricalEffectiveDate, _loadingZoneImpactService, historicalDate, overrideAsCity, workOrder);
    }
    else {
      newFormClass = new BaseActionForm(actionIndexString, letterOfDirectionFormFields, this.tokenCache, hasHistoricalEffectiveDate, historicalDate, previousActivationEffectiveDate);
    }

    return newFormClass;
  }
}
