import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReversibilityDeciderService } from '../../reversibility/reversibility-decider.service';
import { TimeZoneConverter } from '../../../core/time-zone/time-zone-converter';

@Component({
  selector: 'app-work-order-reverse-confirmation',
  templateUrl: './work-order-reverse-confirmation.component.html',
  styleUrls: ['./work-order-reverse-confirmation.component.css']
})
export class WorkOrderReverseConfirmationComponent {
  
  constructor(public dialogRef: MatDialogRef<WorkOrderReverseConfirmationComponent>, private reversibilityDeciderService: ReversibilityDeciderService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  effectiveDate: Date;
  historicalAllowed: boolean;

  submit() {
    var payload = {
      submit: true,
      historicalDate: TimeZoneConverter.EnsureChicagoTime(this.effectiveDate)
    };

    this.dialogRef.close(payload);    
  }

  onDateChange(event:Date){

    var localDate = TimeZoneConverter.EnsureChicagoTime(event);

    this.historicalAllowed = false;
    this.reversibilityDeciderService.checkHistoricMultipleSpaceActionReversibility(this.data.spaceIds, localDate).subscribe(reversible => {
      for(var i = 0; i < reversible.length; i++){
        //if any can be reversed, will proceed
        if(reversible[i] === true){
          this.historicalAllowed = true;
        }
      }
    });
  }

}
