import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TokenCache } from '../../security/token-cache';

@Component({
  selector: 'app-reports-home',
  templateUrl: './reports-home.component.html',
  styleUrls: ['./reports-home.component.css']
})
export class ReportsHomeComponent implements OnInit {
  selectedIndex: number;
  mode: string;
  showSystemSummary: boolean;

  constructor(private tokenCache:TokenCache) { }

  ngOnInit() {
    this.selectCorrespondingTab()
    this.showSystemSummary = !this.tokenCache.checkForLAZUser();
  }

  selectCorrespondingTab() {
    this.mode = 'list';

    if (location.pathname.includes('reservedpower')) {
      this.selectedIndex = 0;
    }
    else if (location.pathname.includes('closure')) {
      this.selectedIndex = 1;
    }    
    else if (location.pathname.includes('other')) {
      this.selectedIndex = 2;
    }    
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectCorrespondingTab();
  }

}
