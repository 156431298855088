import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FineAmount } from './fine-amount';
import { InventoryHttpService } from '../../inventory-http.service';

@Injectable()
export class FineAmountService {
    constructor(private http: HttpClient) { }

    public getAllFineAmounts(): Observable<Array<FineAmount>> {
      return this.http.get('/FineAmounts')
        .pipe(catchError(error => this.handleError(error)));
    }

    private handleError(error: any): Promise<any> {
      console.error('An error occurred', error);
      return Promise.reject(error.message || error);
    }
}
