import { Component, ErrorHandler, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { Action, ActionDueDateRule } from './../models/action';
import { ActionConstants } from './action-constants';
import { ActionField, ActionFieldType } from '../models/action-field';
import { ActionFieldService } from './../services/action-field.service';
import { ActionFormFactory } from './action-forms/action-form-factory';
import { ActionService } from './../services/action.service';
import { BlockService } from './../../inventory-management/blocks/blocks.service';
import { LodWithActionFields } from './../models/letter-of-direction';
import { MeterService } from './../../inventory-management/meters/meters.service';
import { RatePackageService } from './../../inventory-management/rate-packages/services/rate-package.service';
import { TokenCache } from './../../security/token-cache';
import { WorkOrder } from '../models/work-order';
import { WorkOrderAction } from './../models/work-order-action';
import { WorkOrderWarning } from '../models/work-order-warning';
import { BaseActionForm } from './action-forms/forms/base-action-form';
import { RemoveSpaceForm } from './action-forms/forms/remove-space-form';
import { RelocateMeterForm } from './action-forms/forms/relocate-meter-form';
import { InactivateMeterForm } from './action-forms/forms/inactivate-meter-form';
import { ShiftSpaceForm } from './action-forms/forms/shift-space-form';
import { ChangeContractTypeToConcessionForm } from './action-forms/forms/change-contract-type-to-concession-form';
import { InstallMeterForm } from './action-forms/forms/install-meter-form';
import { ChangeRatePackageForm } from './action-forms/forms/change-rate-package-form';
import { WorkorderActionJsonConstants } from '../action-field/workorder-action-json-constants';
import { SpaceService } from '../../inventory-management/spaces/spaces.service';
import { FieldConstants } from '../action-field/field-constants';
import { DueDateConstants } from '../models/due-date-constants';
import { LoadingZoneImpactService } from '../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { TimeZoneConverter } from '../../core/time-zone/time-zone-converter';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.css']
})
export class WorkOrderActionComponent implements OnChanges, OnInit {

  constructor(
    private _actionService: ActionService,
    private _blockService: BlockService,
    private _errorHandler: ErrorHandler,
    private _actionFieldService: ActionFieldService,
    private _meterService: MeterService,
    private _ratePackageService: RatePackageService,
    private _loadingZoneImpactService: LoadingZoneImpactService,
    private _spaceService: SpaceService,
    private formBuilder: FormBuilder,
    private tokenCache: TokenCache) {}

  @Input() hasHistoricalEffectiveDate: boolean;
  @Input() historicalEffectiveDate: Date;
  @Input() previousActivationEffectiveDate: Date;
  @Input() workOrderAction: WorkOrderAction;
  @Input() workOrderFormGroup: FormGroup;
  @Input() workOrder: WorkOrder;
  @Input() actionIndex: number;
  @Input() warnings: Array<WorkOrderWarning>;
  @Input() overrideToCityUserRole: boolean;
  actionFieldsLoading: boolean;
  actionForm: FormGroup;
  actions: Action[];
  blockId: string;
  currentLetterOfDirectionWithActionFields: LodWithActionFields;
  inputRows: Array<number>;
  letterOfDirectionFormFields: ActionField[];
  lodDictionary: LodWithActionFields[] = [];
  meterId: string;
  meterToId: string;
  newActionFormWithWarnings: RemoveSpaceForm | ShiftSpaceForm | ChangeContractTypeToConcessionForm | InstallMeterForm | ChangeRatePackageForm |
                             RelocateMeterForm | InactivateMeterForm | BaseActionForm;
  ratePackageId: string;
  selectedActionId: number;
  workOrderActionJsonObject: any;

  ActionConstants = ActionConstants;
  baseFormActions = [ActionConstants.RegularRateAdjustmentId, ActionConstants.UpdateMeterAttributesId];
  dueDateConstants = DueDateConstants;
  userIsCity: boolean;
  selectedDueDateRule: boolean;

  ngOnInit() {
    if(this.selectedActionId) { //load action fields if we have a selected action
      this.getActionFields();
    }
  }

  ngOnChanges() {
    if (this.workOrder.currentLetterOfDirection) {
      this.selectedActionId = this.workOrderAction.actionId;
      this.workOrder.workOrderActions = this.workOrder.currentLetterOfDirection.workOrderActions;
      if (this.workOrderAction.workOrderActionJson) {
        this.workOrderActionJsonObject = JSON.parse(this.workOrderAction.workOrderActionJson);
      }
    }
    else if (this.workOrderAction.isSystemGenerated) {
      this.selectedActionId =  this.workOrderAction.actionId;
      this.workOrderActionJsonObject = JSON.parse(this.workOrderAction.workOrderActionJson);
    }
    this.getActions();

    if (this.newActionFormWithWarnings) {
      this.newActionFormWithWarnings.hasHistoricalEffectiveDate = this.hasHistoricalEffectiveDate;
      if (this.historicalEffectiveDate) {
        this.newActionFormWithWarnings.historicalEffectiveDate = this.historicalEffectiveDate;
      }
    }
    this.userIsCity = this.tokenCache.checkForCityUser();
  }

  getActions() {
    this._actionService.getAllActions().subscribe(result => {
      this.actions = result.filter(action => {
        if (this.actionIndex > 0) {
          return action.id !== ActionConstants.UpdateMeterAttributesId && action.id !== ActionConstants.UpdateMeterAttributesChangeContractTypeId && action.id !== ActionConstants.RegularRateAdjustmentId && action.id !== ActionConstants.ReverseRatePackageChangeId;
        }
        else if(this.selectedActionId === ActionConstants.ReverseRatePackageChangeId) {
          return action.id === ActionConstants.ReverseRatePackageChangeId;
        }
        else {
          return action.id !== ActionConstants.ReverseRatePackageChangeId;
        }
      });
      if (this.selectedActionId) {
        this.setSelectedAction();
      }
    });
  }

  setSelectedAction() {
    this.workOrderAction.action = this.actions.find(action => {
      return action.id === this.selectedActionId;
    });

    if (this.workOrderAction.action.actionDueDateRules !== null && this.workOrderAction.action.actionDueDateRules.length > 0 && (this.workOrderAction.selectedDueDateRule === null || this.workOrderAction.selectedDueDateRule === undefined)) {
      this.workOrderAction.selectedDueDateRule = this.workOrderAction.action.actionDueDateRules.find( x => x.isDefault).dueDateRule;
    }
  }

  selectDueDate(event: any) {
    this.workOrderAction.selectedDueDateRule = event.value.dueDateRule;
  }

  showDueDateSelection(): boolean {
    if (this.workOrderAction.action) {
      return this.workOrderAction.action.actionDueDateRules.length > 0 && !this.userIsCity;
    }
  }

  showCalendarDayWithoutRadioButton(): boolean {
    return this.workOrderAction.action.actionDueDateRules.length === 1 && this.workOrderAction.action.actionDueDateRules[0].dueDateRule.dayType === this.dueDateConstants.calendarDayKey;
  }

  showBusinessDayWithoutRadioButton(): boolean {
    return this.workOrderAction.action.actionDueDateRules.length === 1 && this.workOrderAction.action.actionDueDateRules[0].dueDateRule.dayType === this.dueDateConstants.businessDayKey;
  }

  getOptionDueDateRule(selectedDateRuleId: number, optionActionDateRule: ActionDueDateRule): boolean {
    if (!selectedDateRuleId) {
      return optionActionDateRule.isDefault;
    }

    return selectedDateRuleId === optionActionDateRule.dueDateRuleId;
  }

  getActionFields() {
    this.clearInventory();
    if (this.workOrderFormGroup.get(this.actionIndex.toString())) {
      this.workOrderFormGroup.removeControl(this.actionIndex.toString());
    }
    this.actionFieldsLoading = true;

    // Another function
    this._actionFieldService.getAllActionFields(this.selectedActionId, this.overrideToCityUserRole).subscribe(
      result => {
        this.actionFieldsLoading = false;
        this.workOrderAction.actionId = this.selectedActionId;
        if (this.actions) {
          this.setSelectedAction();
        }
        this.inputRows = new Array<number> ();

        // Cleanup
        this.letterOfDirectionFormFields = result.sort((a, b) => a.inputRow - b.inputRow);
        this.letterOfDirectionFormFields.forEach(field => {
          if (this.workOrderActionJsonObject && this.workOrderActionJsonObject[field.id]) {

            //translate for time zone
            if(field.fieldType === ActionFieldType.Date){
              field.value = TimeZoneConverter.EnsureLocalTime(new Date(this.workOrderActionJsonObject[field.id]))
            }
            else{
              field.value = this.workOrderActionJsonObject[field.id];
            }
          }
          field.displayField = true;
          if (!this.inputRows.includes(field.inputRow)) {
            this.inputRows.push(field.inputRow);
          }
        });
        this.addFormComponentBasedOffActionId();
      },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
        this.actionFieldsLoading = false;
      }
    );
  }

  clearInventory() {
    this.meterId = this.meterToId = this.blockId = null;
  }

  addFormComponentBasedOffActionId() {
    this.newActionFormWithWarnings = ActionFormFactory.createActionFormBasedOnActionId(this.actionIndex, this.selectedActionId, this.letterOfDirectionFormFields, this.workOrderActionJsonObject,
      this.hasHistoricalEffectiveDate, this.overrideToCityUserRole, this.workOrder, this.historicalEffectiveDate, this.previousActivationEffectiveDate, this._meterService, this._blockService, this._ratePackageService, this._spaceService, this._loadingZoneImpactService);

    this.actionForm = this.newActionFormWithWarnings.formGroup;

    this.newActionFormWithWarnings.formGroup.valueChanges.subscribe(value => {
      if (this.newActionFormWithWarnings.warnings.length > 0) {
        this.warnings[this.actionIndex] = new WorkOrderWarning(this.workOrderAction.action.name, this.newActionFormWithWarnings.warnings);
      }
      else if (this.warnings[this.actionIndex]) {
        this.warnings.splice(this.actionIndex, 1);
      }
    });

    this.workOrderFormGroup.addControl(this.actionIndex.toString(), this.actionForm);

    if (this.workOrderActionJsonObject) {
      this.prepopulateForm();
    }
  }

  prepopulateForm(): void {
    Object.keys(this.workOrderActionJsonObject).forEach(key => {
      if (this.workOrderFormGroup.controls[this.actionIndex.toString()] && this.workOrderFormGroup.controls[this.actionIndex.toString()].get(this.actionIndex + key)) {
        this.workOrderFormGroup.controls[this.actionIndex.toString()].get(this.actionIndex + key).patchValue(this.workOrderActionJsonObject[key]);
      }
    });

    if (this.workOrderAction.isSystemGenerated) {
      this.addSystemGeneratedFields(this.workOrderAction);
    }
  }

  filterFieldsByRow(letterOfDirectionFormFields: ActionField[], rowNumber: number): ActionField[] {
    if (letterOfDirectionFormFields.length > 0) {
      return this.sortByOrder(letterOfDirectionFormFields.filter(field => field.inputRow === rowNumber));
    }
  }

  sortByOrder(resultArray): ActionField[] {
    return resultArray.sort((a, b) => {
      if (a.order < b.order) { return -1; }
      else if (a.order > b.order) { return 1; }
      else { return 0; }
    });
  }

  setBlockId(blockId: string) {
    this.blockId = blockId;
  }

  setMeterId(meterId: string, meterType: string) {
    if (meterType === 'to-meter') {
      this.meterToId = meterId;
    }
    else {
      this.meterId = meterId;
    }
  }

  setRatePackageId(ratePackageId: string) {
    this.ratePackageId = ratePackageId;
  }

  addSystemGeneratedFields(workOrderAction: WorkOrderAction) {
    if (workOrderAction.workOrderActionJson) {
      const jsonObject = JSON.parse(workOrderAction.workOrderActionJson);
      const reversalModels = jsonObject[WorkorderActionJsonConstants.ratePackageReversalModels];
      if (reversalModels) {
        const reversalModelsControl = new FormControl('');
        const reversalModelsJson = JSON.stringify(reversalModels);
        this.actionForm.addControl(WorkorderActionJsonConstants.ratePackageReversalModels, reversalModelsControl);
        this.actionForm.get(WorkorderActionJsonConstants.ratePackageReversalModels).setValue(reversalModelsJson);
      }
      const meterGuid = jsonObject[WorkorderActionJsonConstants.meterGuid];
      if (meterGuid) {
        const meterGuidControl = new FormControl('');
        this.actionForm.addControl(WorkorderActionJsonConstants.meterGuid, meterGuidControl);
        this.actionForm.get(WorkorderActionJsonConstants.meterGuid).setValue(meterGuid);
      }
    }

  }
}
