exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".top-margin {\r\n  margin-top: 25px;\r\n}\r\n\r\n.add {\r\n  position: absolute;\r\n  right:25px;\r\n  z-index: 2;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();