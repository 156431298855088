exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".idFiltersContainer {\r\n  padding: 0px 16px 0px 16px;\r\n}\r\n\r\n.allFiltersContainer {\r\n  padding-top: 8px;\r\n}\r\n\r\n.mat-form-field {\r\n  font-size: 8pt;\r\n  padding-top: 0px !important;\r\n  padding-bottom: 0px !important;\r\n  margin-right: 5px;\r\n}\r\n\r\n.filterButton {\r\n  margin: 10px;\r\n}\r\n\r\n.filter-button-container {\r\n  margin-top: 16px\r\n}\r\n\r\n.mat-header-row {\r\n  border-top: .5px solid lightgray;\r\n}\r\n\r\na {\r\n  cursor: pointer;\r\n}\r\n\r\n.material-icons {\r\n  font-size: 16px;\r\n  padding-right: 5px;\r\n}\r\n\r\n.pagination a {\r\n  color: black;\r\n  float: left;\r\n  margin: 16px 16px 16px 16px;\r\n  padding: 8px 8px 8px 8px;\r\n  text-decoration: none;\r\n  -webkit-transition: background-color .3s;\r\n  transition: background-color .3s;\r\n}\r\n\r\n.pagination a.active {\r\n  background-color: #4CAF50;\r\n  color: white;\r\n}\r\n\r\n.pagination a:hover:not(.active) {\r\n  background-color: #ddd;\r\n}\r\n.button-style{\r\n  width:120px;\r\n}\r\n\r\n.pad-right {\r\n  padding-right:1%;\r\n}\r\n\r\n.true-up-detail-field {\r\n  font-size: 10px;\r\n}\r\n\r\n.mat-table {\r\n  min-width: 1250px;\r\n  overflow-x: auto;\r\n}\r\n\r\n/*.mat-row, .mat-header-row {\r\n  min-width:1250px;\r\n  width:100% !important;\r\n  max-width:2000px !important;\r\n}*/\r\n\r\n.mat-header-row {\r\n  font-size:75%;\r\n}\r\n\r\n.filter-right {\r\n  border-right:2px solid lightgray;\r\n  margin-left:5px;\r\n  margin-right:5px;\r\n}\r\n\r\n.mat-option {\r\n  font-size: 8pt !important;\r\n}\r\n\r\n.flex-container {\r\n  width: -webkit-max-content;\r\n  width: -moz-max-content;\r\n  width: max-content;\r\n}\r\n\r\n.mat-expansion-panel-content .mat-expanded {\r\n  overflow-x: scroll !important;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();