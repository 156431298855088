
import { QueryStringBuilder, FilterOperator } from '../../core/query-strings/query-string-builder';
import { StringExtensions } from '../../string-extensions';


export class MonthlyTrueUpCalculationFilterRepresentation{

    CalendarMonth: string = undefined;
    get ShouldFilterByCalendarMonth(): boolean {
        return this.stringIsNotNullOrEmpty(this.CalendarMonth);
    }
    CalendarYear: string = undefined;
    get ShouldFilterByCalendarYear(): boolean {
        return this.stringIsNotNullOrEmpty(this.CalendarYear);
    }
    FiscalQuarter: string = undefined;
    get ShouldFilterByFiscalQuarter(): boolean {
        return this.stringIsNotNullOrEmpty(this.FiscalQuarter);
    }
    FiscalYear: string = undefined;
    get ShouldFilterByFiscalYear(): boolean {
        return this.stringIsNotNullOrEmpty(this.FiscalYear);
    }
    ReportingQuarter: string = undefined;
    get ShouldFilterByReportingQuarter(): boolean {
        return this.stringIsNotNullOrEmpty(this.ReportingQuarter);
    }
    ReportingYear: string = undefined;
    get ShouldFilterByReportingYear(): boolean {
        return this.stringIsNotNullOrEmpty(this.ReportingYear);
    }
    MeterIdFilterOperator:string = FilterOperator.Equal;
    MeterId: string = undefined;
    get ShouldFilterByMeterId(): boolean {
        return this.stringIsNotNullOrEmpty(this.MeterId);
    }
    SpaceIdFilterOperator:string = FilterOperator.Equal;
    SpaceId: string = undefined;
    get ShouldFilterBySpaceId(): boolean {
        return this.stringIsNotNullOrEmpty(this.SpaceId);
    }
    AreaFilterOperator:string = FilterOperator.Equal;
    Area: number = undefined;
    AreaEnd: number = undefined;
    get ShouldFilterByAreaId(): boolean {
        return this.stringIsNotNullOrEmpty(this.Area);
    }
    BlockId: string = undefined;
    get ShouldFilterByBlockId(): boolean {
        return this.stringIsNotNullOrEmpty(this.BlockId);
    }
    ZoneFilterOperator:string = FilterOperator.Equal;
    Zone: string = undefined;
    ZoneEnd: string = undefined;
    get ShouldFilterByZone(): boolean {
        return this.stringIsNotNullOrEmpty(this.Zone);
    }
    SpaceStatus: string = undefined;
    get ShouldFilterBySpaceStatus(): boolean {
        return this.stringIsNotNullOrEmpty(this.SpaceStatus);
    }
    ChangeInMonthFilterOperator:string = FilterOperator.Equal;
    ChangeInMonth: string = undefined;
    get ShouldFilterByChangeInMonth(): boolean {
        return this.stringIsNotNullOrEmpty(this.ChangeInMonth);
    }
    HasReservedPowerWorkOrderActionsFilterOperator:string = FilterOperator.Equal;
    HasReservedPowerWorkOrderActions: string = undefined;
    get ShouldFilterByHasReservedPowerWorkOrderActions(): boolean {
        return this.stringIsNotNullOrEmpty(this.HasReservedPowerWorkOrderActions);
    }
    NumberOfMonthlyRPAsFilterOperator:string = FilterOperator.Equal;
    NumberOfMonthlyRPAs: number = undefined;
    NumberOfMonthlyRPAsEnd: number = undefined;
    get ShouldFilterByNumberOfMonthlyRPAs(): boolean {
        return this.stringIsNotNullOrEmpty(this.NumberOfMonthlyRPAs);
    }
    NumberOfTotalRPAsFilterOperator:string = FilterOperator.Equal;
    NumberOfTotalRPAs: number = undefined;
    NumberOfTotalRPAsEnd: number = undefined;
    get ShouldFilterByNumberOfTotalRPAs(): boolean {
        return this.stringIsNotNullOrEmpty(this.NumberOfTotalRPAs);
    }
    WOID: string = undefined;
    WorkOrderActionIds: string = undefined
    get ShouldFilterByWOID(): boolean {
        return this.stringIsNotNullOrEmpty(this.WOID);
    }
    RowCount: string = undefined
    get ShouldFilterByRowCount(): boolean {
      return this.stringIsNotNullOrEmpty(this.RowCount);
    }
    HasCLZChangeFilterOperator:string = FilterOperator.Equal;
    HasCLZChange: string = undefined;
    get ShouldFilterByHasCLZChange(): boolean {
        return this.stringIsNotNullOrEmpty(this.HasCLZChange);
    }
    RatePackage: string = undefined;
    get ShouldFilterByRatePackage(): boolean {
        return this.stringIsNotNullOrEmpty(this.RatePackage);
    }
    RatePackageName: string = undefined;
    get ShouldFilterByRatePackageName(): boolean {
        return this.stringIsNotNullOrEmpty(this.RatePackageName);
    }
    ContractType: string = undefined;
    get ShouldFilterByContractType(): boolean {
        return this.stringIsNotNullOrEmpty(this.ContractType);
    }
    MonthlyURFilterOperator:string = FilterOperator.Equal;
    MonthlyUR: number = undefined;
    MonthlyUREnd: number = undefined;
    get ShouldFilterByMonthlyUR(): boolean {
        return this.stringIsNotNullOrEmpty(this.MonthlyUR);
    }
    MURFilterOperator:string = FilterOperator.Equal;
    MUR: number = undefined;
    MUREnd: number = undefined;
    get ShouldFilterByMUR(): boolean {
        return this.stringIsNotNullOrEmpty(this.MUR);
    }
    UAIFilterOperator:string = FilterOperator.Equal;
    UAI: number = undefined;
    UAIEnd: number = undefined;
    get ShouldFilterByUAI(): boolean {
        return this.stringIsNotNullOrEmpty(this.UAI);
    }
    ExistingRevenueFilterOperator:string = FilterOperator.Equal;
    ExistingRevenue: number = undefined;
    ExistingRevenueEnd: number = undefined;
    get ShouldFilterByExistingRevenue(): boolean {
        return this.stringIsNotNullOrEmpty(this.ExistingRevenue);
    }
    RevenueValueFilterOperator:string = FilterOperator.Equal;
    RevenueValue: number = undefined;
    RevenueValueEnd: number = undefined;
    get ShouldFilterByRevenueValue(): boolean {
        return this.stringIsNotNullOrEmpty(this.RevenueValue);
    }
    RevenueValueAdjustmentFilterOperator:string = FilterOperator.Equal;
    RevenueValueAdjustment: number = undefined;
    RevenueValueAdjustmentEnd: number = undefined;
    get ShouldFilterByRevenueValueAdjustment(): boolean {
        return this.stringIsNotNullOrEmpty(this.RevenueValueAdjustment);
    }
    ExpectedUtilizationRateFilterOperator:string = FilterOperator.Equal;
    ExpectedUtilizationRate: number = undefined;
    ExpectedUtilizationRateEnd: number = undefined;
    get ShouldFilterByExpectedUtilizationRate(): boolean {
        return this.stringIsNotNullOrEmpty(this.ExpectedUtilizationRate);
    }
    LastReservedPowerAction: string = undefined;
    LastReservedPowerActionRangeEnd: string = undefined;
    LastReservedPowerActionDisplayString: string = undefined;
    LastReservedPowerActionRangeEndDisplayString: string = undefined;
    LastReservedPowerActionRangeFilterOperator: string = FilterOperator.Between;
    get FilterByLastReservedPowerAction(): boolean {
        return StringExtensions.StringIsNotEmpty(this.LastReservedPowerAction);
    }
    LastInventoryChangeDate: string = undefined;
    LastInventoryChangeDateRangeEnd: string = undefined;
    LastInventoryChangeDateDisplayString: string = undefined;
    LastInventoryChangeDateRangeEndDisplayString: string = undefined;
    LastInventoryChangeDateRangeFilterOperator: string = FilterOperator.Between;
    get FilterByLastInventoryChangeDate(): boolean {
      return StringExtensions.StringIsNotEmpty(this.LastInventoryChangeDate);
    }
    ActualOperatingRevenueFilterOperator:string = FilterOperator.Equal;
    ActualOperatingRevenueMonthly: number = undefined;
    ActualOperatingRevenueMonthlyEnd: number = undefined;
    get ShouldFilterByActualOperatingRevenue(): boolean {
        return this.stringIsNotNullOrEmpty(this.ActualOperatingRevenueMonthly);
    }
    RequiredClosurePaymentsFilterOperator:string = FilterOperator.Equal;
    RequiredClosurePayments: number = undefined;
    RequiredClosurePaymentsEnd: number = undefined;
    get ShouldFilterByRequiredClosurePayments(): boolean {
        return this.stringIsNotNullOrEmpty(this.RequiredClosurePayments);
    }
    QualifiedRequiredClosuresFilterOperator:string = FilterOperator.Equal;
    QualifiedRequiredClosures: number = undefined;
    QualifiedRequiredClosuresEnd: number = undefined;
    get ShouldFilterByQualifiedREquiredClosures(): boolean {
        return this.stringIsNotNullOrEmpty(this.QualifiedRequiredClosures);
    }
    ClosureIDs: string = undefined;
    get ShouldFilterByClosureIDs(): boolean {
        return this.stringIsNotNullOrEmpty(this.ClosureIDs);
    }
    MeterParkingRevenueFilterOperator:string = FilterOperator.Equal;
    MeterParkingRevenue: number = undefined;
    MeterParkingRevenueEnd: number = undefined;
    get ShouldFilterByMeterParkingRevenue(): boolean {
        return this.stringIsNotNullOrEmpty(this.MeterParkingRevenue);
    }
    MeasuredUtilizationRateFilterOperator:string = FilterOperator.Equal;
    MeasuredUtilizationRate: number = undefined;
    MeasuredUtilizationRateEnd: number = undefined;
    get ShouldFilterByMeasuredUtilizationRate(): boolean {
        return this.stringIsNotNullOrEmpty(this.MeasuredUtilizationRate);
    }

    private stringIsNotNullOrEmpty(str):boolean
    {
        if(str == undefined || str== null)
        {
            return false;
        }
        var asString = `${str}`;//this is because typescript + javascript is stupid and casts types wrong when no one asks it to.
        return asString.length > 0;
    }


}
