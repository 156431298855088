import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader } from 'ad-file-upload';
import { TokenCache } from '../../../security/token-cache';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { WorkOrderAttachment } from './work-order-attachment';
import { environment } from '../../../environment';
import { FileDownloadService } from '../../../core/file-download/file-download.service';
import { WorkOrdersService } from '../../services/work-orders.service';
import { DeleteAttachmentConfirmationComponent } from './delete-attachment-confirmation/delete-attachment-confirmation.component';

// uploadable mime types agreed upon with CPM
const allowedMimeTypes = ['application/msword',
'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
'application/vnd.ms-word.document.macroEnabled.12',
'application/vnd.ms-word.template.macroEnabled.12',
'application/pdf',
'pdf/pdf',
'application/vnd.ms-excel',
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
'application/vnd.ms-excel.sheet.macroEnabled.12',
'application/vnd.ms-excel.template.macroEnabled.12',
'application/vnd.ms-excel.addin.macroEnabled.12',
'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
'image/jpg',
'image/jpeg',
'image/png',
'image/tif',
'image/tiff'];

@Component({
  selector: 'app-work-order-attachments',
  templateUrl: './work-order-attachments.component.html',
  styleUrls: ['./work-order-attachments.component.css']
})
export class WorkOrderAttachmentsComponent implements OnInit {

  @Input()
  public uploader: FileUploader;
  private _tokenCache: TokenCache;

  dataSource = new MatTableDataSource<WorkOrderAttachment>();
  allAttachments: WorkOrderAttachment[];


  displayedColumns;

  isCity: boolean;

  @Input()
  public workOrderId: string;

  @Output()
  attachmentCountEmitter: EventEmitter<number> = new EventEmitter<number>(true);

  @Output()
  changeAttachmentTab: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private tokenCache: TokenCache, public snackbar: MatSnackBar, private fileDownloadService: FileDownloadService, private workOrdersService: WorkOrdersService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this._tokenCache = tokenCache;
   }

  ngOnInit() {
    this.isCity = this.tokenCache.checkForCityUser();
    if (this.isCity) {
      this.displayedColumns = ['fileName', 'uploadDate', 'uploadingUser', 'fileType'];
    }
    else {
      this.displayedColumns = ['fileName', 'uploadDate', 'uploadingUser', 'fileType', 'delete'];
    }

    const URL = `${environment.WORKORDER_API_BASE}/workorders/${this.workOrderId}/attachments`;

    this.uploader = new FileUploader({url: URL, authToken: this._tokenCache.getBearerToken()});

    this.uploader.options.allowedMimeType = allowedMimeTypes;

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.getAllAttachments();
  }

  async getAllAttachments() {
    this.workOrdersService.GetAllWorkOrderAttachmentsForWorkOrder(this.workOrderId).subscribe((response) => {
      const attachmentList = response ? response : [];
      this.attachmentCountEmitter.emit(attachmentList.length);
      this.dataSource = new MatTableDataSource<WorkOrderAttachment>(attachmentList);
    },
    error => {
      console.log(error);
    });
  }

  uploadAttachment() {
    // mark the item in the queue as not uploaded so we can retry uploads if needed.
        const uploaderQueue = this.uploader.queue;
        if (uploaderQueue[0]) {
          this.uploader.queue[0].isUploaded = false;
        }
        // check if the file is a valid mimetype
        const fileLikeObject = new FileLikeObject(this.uploader.queue[0]);
        fileLikeObject.type = this.uploader.queue[0].file.type;
        const fileToUploadIsValidMimeType = this.uploader._mimeTypeFilter(fileLikeObject);

        if (!fileToUploadIsValidMimeType) {
          this.snackbar.open('The file extension of the file you are attempting to upload is invalid.', '', <MatSnackBarConfig>{
            duration: 2000,
          });
        }
        else {
          this.uploader.uploadAll();

          const uploader = this.uploader;
          const snackBar = this.snackBar;

          const scope = this;

          const interval = setInterval(function() {
            const bool = uploader.isUploading;
            if (!bool) {
              if (uploader.queue[uploaderQueue.length - 1].isSuccess) {
                snackBar.open('File Imported', 'X');
                uploader.clearQueue();
                scope.getAllAttachments();
              }
              else {
                snackBar.open('Error importing file. Please try again.', 'X');
              }
              clearInterval(interval);
            }
          }, 250, 250);
        }
      }

  downloadAttachment(attachmentS3Key: string) {
    // mark the item in the queue as not uploaded so we can retry uploads if needed.
    this.snackBar.open('Downloading Attachment', '', <MatSnackBarConfig>{
      duration: 2000
    });
    this.workOrdersService.DownloadWorkOrderAttachment(this.workOrderId, attachmentS3Key).subscribe(result => {
        const fileDownloadResult = this.fileDownloadService.MapResponseToFileResult(result);
        this.fileDownloadService.SaveFileToBrowser(fileDownloadResult);
      }
    );
  }

  deleteAttachment(attachmentS3Key: string) {
    const dialogRef = this.dialog.open(DeleteAttachmentConfirmationComponent, {
      width: '500px',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {
        this.workOrdersService.DeleteWorkOrderAttachment(this.workOrderId, attachmentS3Key).subscribe(async (deleteResult) => {
            console.log(deleteResult);
            this.snackBar.open('Work order attachment deleted', '', <MatSnackBarConfig>{
              duration: 2000,
            });

            this.getAllAttachments();
        },
        error => {
          const messageBody = error['_body'];
          this.snackBar.open(messageBody, 'X');
          this.getAllAttachments();
        });
      }
    });
  }
}
