import { FineAmountService } from './../../../inventory-management/fine-amounts/fine-amounts.service';
import { ActionConstants } from './../../action/action-constants';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'rxjs/Rx';
import * as moment from 'moment';

import { Area } from './../../../inventory-management/areas/area';
import { Zone } from './../../../inventory-management/zones/zone';
import { AreaService } from '../../../inventory-management/areas/areas.service';
import { ZoneService } from './../../../inventory-management/zones/zones.service';
import { UpdateMeterAttributesSpaceChange } from './models/update-meter-attributes-space-change';
import { UpdateMeterAttributesMeterChange } from './models/update-meter-attributes-meter-change';
import { UpdateMeterAttributesResponse } from './models/update-meter-response';
import { InventoryFilterService, DropdownOptions } from './../services/inventory-filter-service';
import { FieldConstants } from './../../action-field/field-constants';
import { WorkOrderAction } from './../../models/work-order-action';
import { SubmitUpdateMeterAttributesChangesGroup } from './models/submit-update-meter-attributes-changes-group';
import { UpdateMeterAttributesService } from '../services/update-meter-attributes-service';
import { UpdateMeterAttributeResponseGroup } from './models/update-meter-attribute-response-group';
import { UpdateMeterAttributesInventoryChanges } from '../models/update-meter-attributes';
import { WorkOrderStatusType } from '../../models/work-order';
import { ContractTypeConstants } from '../../../inventory-management/meters/meter-constants';
import { isPropertyName } from 'typescript';
import { BlockInventoryChange } from '../models/block-inventory-change';
import { MeterInventoryChange } from '../models/meter-inventory-change';
import { SpaceInventoryChange } from '../models/space-inventory-change';
import { InventoryChangeStatusType } from '../models/inventory-change-base';
import { TokenCache } from '../../../security/token-cache';
import { WorkOrderComment } from './../../models/work-order-comment';
import { environment } from '../../../../environments/environment';
import { CommentsDialogComponent } from '../../letter-of-directions-list/comments-dialog/comments-dialog.component';
import { WorkOrder } from './../../models/work-order';
import { WorkOrderResponseService } from './../../services/work-order-response.service';
import { LetterOfDirectionResponse, WorkOrderActionResponse } from '../../models/approval-models';
import { DateTimeUtility } from '../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { InventoryChangesService } from '../services/inventory-changes-service';
import { WorkOrderStatus } from '../../models/work-order-status';
import { LoadingZoneImpactComponent } from '../../../inventory-management/loading-zone-impact/loading-zone-impact.component';
import { WorkOrderActionStatusConstants } from '../../work-order-constants';
import { BulkChangesComponentBase } from '../bulk-changes/bulk-changes-component-base';
import { FineAmount } from '../../../inventory-management/fine-amounts/fine-amount';

@Component({
  selector: 'app-update-meter-attributes',
  templateUrl: './update-meter-attributes.component.html',
  styleUrls: ['./update-meter-attributes.component.css']
})
export class UpdateMeterAttributesComponent extends BulkChangesComponentBase implements OnInit {

  constructor(
    private _zoneService: ZoneService,
    private _areaService: AreaService,
    private _inventoryFilterService: InventoryFilterService,
    private _updateMeterAttributesService: UpdateMeterAttributesService,
    private snackBar: MatSnackBar,
    private tokenCache: TokenCache,
    public dialog: MatDialog,
    private _workOrderResponseService: WorkOrderResponseService,
    private _inventoryChangesService: InventoryChangesService,
    private _fineAmountService: FineAmountService
  ) {
    super();
  }

  @Input() workOrderAction: WorkOrderAction;
  @Input() workOrderStatusId: number;
  @Input() workOrder: WorkOrder;

  updateMeterAttributesForm = new FormGroup({
    zoneControl: new FormControl(),
    areaControl: new FormControl(),
    blockControl: new FormControl(),
    meterControl: new FormControl(),
    filterDate: new FormControl()
  });

  zoneOptions: Zone[];
  areaOptions: Area[];
  blockOptions: DropdownOptions[];
  meterOptions: DropdownOptions[];

  loadingSpaces = false;
  displayAreaLoading: boolean;
  displayBlockLoading: boolean;
  displayMeterLoading: boolean;

  effectiveDate: Date;
  filterDate: Date;

  changeContractTypeToReserved: boolean;
  fineAmount: number;
  fineAmountId: string;
  fineAmountList: FineAmount[] = [];
  newLocationTypeName: string;
  newRequiredClosureAllowanceName: string;

  updateMeterAttributesResponse: UpdateMeterAttributesResponse;
  submittedSummaries: SubmitUpdateMeterAttributesChangesGroup;
  WorkOrderStatusType = WorkOrderStatusType;

  showInvChanges: boolean;
  inventoryChangesSubmitted: boolean;
  isCity: boolean;
  isPendingCityApproval: boolean;
  wasReturnedWithComments = false;
  dialogRefComments: MatDialogRef<CommentsDialogComponent>;

  notEmptyUpdateMeterAttributeInventoryChanges: UpdateMeterAttributesInventoryChanges[];
  showSomeRecordsWereSkipped: boolean;
  nodeSelection: string;

  ngOnInit() {
    if (this.workOrder.currentLetterOfDirection.historicalEffectiveDate) {
      this.effectiveDate = this.workOrder.currentLetterOfDirection.historicalEffectiveDate;
      this.filterDate = this.effectiveDate;
    }
    else {
      this.filterDate = new Date();
    }

    this.notEmptyUpdateMeterAttributeInventoryChanges = this.workOrderAction.updateMeterAttributesInventoryChanges
      .filter(x => ((x.relatedMeterChanges !== null && x.relatedMeterChanges.length > 0)  || (x.relatedSpaceChanges != null && x.relatedSpaceChanges.length > 0))); // Filter out ones that don't have any related changes
    if (this.workOrderAction.blockInventoryChanges.length === 0 && this.workOrderAction.meterInventoryChanges.length === 0 && this.workOrderAction.spaceInventoryChanges.length === 0) {
      this.inventoryChangesSubmitted = false;
    } else {
      this.inventoryChangesSubmitted = this.areAllInventoryChangesPending(this.workOrderAction.blockInventoryChanges, this.workOrderAction.meterInventoryChanges, this.workOrderAction.spaceInventoryChanges);
    }
    this.isPendingCityApproval = this.workOrder.currentStatusId === WorkOrderStatusType.PendingCityApprovalId;
    this.isCity = this.tokenCache.checkForCityUser();
    if (this.workOrderAction.workOrderActionResponses.length > 0) {
      const orderedResponses = this.workOrderAction.workOrderActionResponses.sort((a, b) => {
        return DateTimeUtility.compareDate(a.responseDate, b.responseDate) ? -1 : 1;
      });
      if (orderedResponses[0].responseType === 'Return With Comments') { this.wasReturnedWithComments = true; }
    }

    this.showInvChanges = !this.isCity || this.inventoryChangesSubmitted || (this.isCity && this.workOrderStatusId === WorkOrderStatusType.PendingCityApprovalId);

    this.changeContractTypeToReserved = this.workOrderAction.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId;

    const firstGroup = this.workOrderAction.updateMeterAttributesInventoryChanges[0];

    if (this.workOrderAction.actionId === ActionConstants.UpdateMeterAttributesId) {
      this._fineAmountService.getAllFineAmounts()
      .subscribe(result => {
        this.fineAmountId = firstGroup.newFineAmountId;
        if (this.fineAmountId) {
          this.fineAmount = result.find(fineAmount => fineAmount.id === firstGroup.newFineAmountId).amount;
        }
        result.forEach(fineAmount => {
          const newFineAmount = new FineAmount();
          newFineAmount.id = fineAmount.id;
          newFineAmount.amount = fineAmount.amount;
          this.fineAmountList.push(newFineAmount);
        });

      });

      this.newLocationTypeName = firstGroup.newLocationTypeName;
      this.newRequiredClosureAllowanceName = firstGroup.newRequiredClosureAllowanceName;
    }

    this._zoneService.getAllZones().subscribe(result => {
      this.zoneOptions = this.sort(result, 'displayId');
      this.updateMeterAttributesForm.get('zoneControl').enable();
    });

    this.updateMeterAttributesForm
      .get('zoneControl')
      .valueChanges.subscribe(value => {
        if (value === null || value.length > 1 || value.length === 0) {
          this.updateMeterAttributesForm.get('areaControl').disable();
          this.updateMeterAttributesForm.get('areaControl').setValue(null);
          this.updateMeterAttributesForm.get('blockControl').disable();
          this.updateMeterAttributesForm.get('blockControl').setValue(null);
          this.updateMeterAttributesForm.get('meterControl').disable();
          this.updateMeterAttributesForm.get('meterControl').setValue(null);
        }
        else if (value.length === 1) {
          this.displayAreaLoading = true;
          this._inventoryFilterService.getAreasFilterByZone(value[0], this.filterDate).subscribe(
            result => {
              this.areaOptions = this.sort(result, 'displayId');
              this.updateMeterAttributesForm.get('areaControl').enable();
              this.displayAreaLoading = false;
            },
            error => {
              this.openSnackBar(error['_body'], 'X');
              console.log(error);
              this.displayAreaLoading = false;
            }
          );
        }
      });

    this.updateMeterAttributesForm
      .get('areaControl')
      .valueChanges.subscribe(value => {
        if (value === null || value.length > 1 || value.length === 0) {
          this.updateMeterAttributesForm.get('blockControl').disable();
          this.updateMeterAttributesForm.get('blockControl').setValue(null);
          this.updateMeterAttributesForm.get('meterControl').disable();
          this.updateMeterAttributesForm.get('meterControl').setValue(null);
        }
        else if (value.length === 1) {
          this._inventoryFilterService
            .getBlocksFilterByAreas(
              this.updateMeterAttributesForm.get('areaControl').value, this.updateMeterAttributesForm.get('filterDate').value
            )
            .subscribe(
              result => {
                this.blockOptions = this.sort(result, 'value');
                this.updateMeterAttributesForm.get('blockControl').enable();
                this.displayBlockLoading = false;
              },
              error => {
                this.openSnackBar(error['_body'], 'X');
                console.log(error);
                this.displayBlockLoading = false;
              }
            );
        }
      });

    this.updateMeterAttributesForm
      .get('blockControl')
      .valueChanges.subscribe(value => {
        if (value === null || value.length > 1) {
          this.updateMeterAttributesForm.get('meterControl').disable();
          this.updateMeterAttributesForm.get('meterControl').setValue(null);
        }
        else if (value.length === 1) {
          this._inventoryFilterService
            .getMetersFilterByBlocks(
              this.updateMeterAttributesForm.get('blockControl').value, this.updateMeterAttributesForm.get('filterDate').value
            )
            .subscribe(
              result => {
                this.meterOptions = this.sort(result, 'value');
                this.updateMeterAttributesForm.get('meterControl').enable();
                this.displayMeterLoading = false;
              },
              error => {
                this.openSnackBar(error['_body'], 'X');
                console.log(error);
                this.displayMeterLoading = false;
              }
            );
        }
      });
  }

  private areAllInventoryChangesPending(...changeTypes: Array<Array<BlockInventoryChange|MeterInventoryChange|SpaceInventoryChange>>) {
    if (!changeTypes) { return true; }

    const changes = [].concat(...changeTypes);
    for (let i = 0, change; change = changes[i]; ++i) {
      if (change.status !== InventoryChangeStatusType.Pending) { return false; }
    }

    return true;
  }

  createNewAttributesList(): string {
    const listOfNewAttributes = new Array<string>();

    if (this.changeContractTypeToReserved) {
      listOfNewAttributes.push('Reserved');
    }
    else {
      if (this.newLocationTypeName) {
        listOfNewAttributes.push(this.newLocationTypeName);
      }
      if (this.fineAmount) {
        listOfNewAttributes.push(this.fineAmount.toString());
      }
      if (this.newRequiredClosureAllowanceName) {
        listOfNewAttributes.push(this.newRequiredClosureAllowanceName);
      }
    }

    let newAttributesString = '';
    for (let i = 0; i < listOfNewAttributes.length; i++) {
      newAttributesString += listOfNewAttributes[i];
      if (i !== listOfNewAttributes.length - 1) {
        newAttributesString += ', ';
      }
    }
    return newAttributesString;
  }

  createOldAttributesList(summary: UpdateMeterAttributeResponseGroup): string {
    const listOfOldAttributes = new Array<string>();
    if (this.changeContractTypeToReserved) {
      listOfOldAttributes.push('Concession');
    }
    else {
      if (this.newLocationTypeName !== '') {
        listOfOldAttributes.push(summary.currentLocationTypeName);
      }
      if (this.fineAmount) {
        if (summary.currentFineAmount === null || summary.currentFineAmount === undefined) {
          listOfOldAttributes.push('');
        }
        else {
          listOfOldAttributes.push(this.fineAmountList.find(fineAmount => fineAmount.id === summary.currentFineAmount).amount.toString());
        }
      }
      if (this.newRequiredClosureAllowanceName !== '') {
        listOfOldAttributes.push(summary.currentRequiredClosureAllowanceName);
      }
    }

    let oldAttributesString = '';
    for (let i = 0; i < listOfOldAttributes.length; i++) {
      oldAttributesString += listOfOldAttributes[i];
      if (i !== listOfOldAttributes.length - 1) {
        oldAttributesString += ', ';
      }
    }

    return oldAttributesString;
  }

  generateNodeSelectionString() {
    this.nodeSelection = this.generateNodeGroupString('Zone');
    if (this.updateMeterAttributesForm.get('areaControl').value) {
      this.nodeSelection = this.nodeSelection + ',  ' + this.generateNodeGroupString('Area');
    }
    if (this.updateMeterAttributesForm.get('blockControl').value) {
      this.nodeSelection = this.nodeSelection + ',  ' + this.generateNodeGroupString('Block');
    }
    if (this.updateMeterAttributesForm.get('meterControl').value) {
      this.nodeSelection = this.nodeSelection + ',  ' + this.generateNodeGroupString('Meter');
    }
  }

  generateNodeGroupString(node: string): string {
    const nodeOptionsString = (node.toLowerCase() + 'Options').replace(/[\s]/g, '');
    const nodeControlString = (node.toLowerCase() + 'Control').replace(/[\s]/g, '');
    const isZone = node.toLowerCase().includes('zone');
    if (this.updateMeterAttributesForm.get(nodeControlString).value.length > 1) {
      node = node + 's ';
      this.updateMeterAttributesForm.get(nodeControlString).value.forEach(nodeValue => {
        node = node + this[nodeOptionsString].filter(nodeOption => {
          if (isZone) {
            return nodeOption.id === nodeValue;
          }
          else {
            return nodeOption.key === nodeValue;
          }
        })[0][isZone ? 'displayId' : 'value'];
        if (this.updateMeterAttributesForm.get(nodeControlString).value.indexOf(nodeValue) !== this.updateMeterAttributesForm.get(nodeControlString).value.length - 1) {
          node = node + ', ';
        }
      });
    }
    else {
      node = node + ' ';
      node = node + this[nodeOptionsString].filter(nodeOption => {
          if (isZone) {
            return nodeOption.id === this.updateMeterAttributesForm.get(nodeControlString).value[0];
          }
          else {
            return nodeOption.key === this.updateMeterAttributesForm.get(nodeControlString).value[0];
          }
      })[0][isZone ? 'displayId' : 'value'];
    }

    return node;
  }

  clear(controlName) {
    this.updateMeterAttributesForm.get(controlName).setValue(null);
  }

  clearAll() {
    this.updateMeterAttributesForm.reset();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  submitSelection() {
    this.loadingSpaces = true;
    let idList = [];
    let nodeType = '';

    if (this.updateMeterAttributesForm.get('meterControl').value) {
      idList = this.updateMeterAttributesForm.get('meterControl').value;
      nodeType = 'meter';
    }
    else if (this.updateMeterAttributesForm.get('blockControl').value) {
      idList = this.updateMeterAttributesForm.get('blockControl').value;
      nodeType = 'block';
    }
    else if (this.updateMeterAttributesForm.get('areaControl').value) {
      idList = this.updateMeterAttributesForm.get('areaControl').value;
      nodeType = 'area';
    }
    else if (this.updateMeterAttributesForm.get('zoneControl').value) {
      idList = this.updateMeterAttributesForm.get('zoneControl').value;
      nodeType = 'zone';
    }

    this.generateNodeSelectionString();

    this._inventoryFilterService.getSpaces(idList,
      nodeType,
      this.filterDate,
      this.changeContractTypeToReserved,
      this.newRequiredClosureAllowanceName != null && this.newRequiredClosureAllowanceName != '',
      this.newLocationTypeName != null && this.newLocationTypeName != '',
      this.fineAmountId != null && this.fineAmountId != '',
      this.changeContractTypeToReserved ? 'Reserved' : '',
      this.newLocationTypeName,
      this.fineAmountId,
      this.newRequiredClosureAllowanceName,
      this.workOrder.previousActivationEffectiveDate).subscribe(
      result => {
        this.updateMeterAttributesResponse = result;
        this.updateMeterAttributesResponse.spaceDetail.forEach(detail => {
          detail.newFineAmountId = this.fineAmountId;
        });
        this.showSomeRecordsWereSkipped = result.searchContainsRecordsThatDoNotNeedToBeChanged;
        this.loadingSpaces = false;
      },
      error => {
        this.loadingSpaces = false;
        this.openSnackBar(error['_body'], 'X');
      }
    );
  }

  removeSelection(updateMeterAttributesChange: UpdateMeterAttributesInventoryChanges) {
    this.openSnackBar('Removing Previous Selection...', '');
    this._updateMeterAttributesService.deleteSelection(this.workOrderAction.id, updateMeterAttributesChange.id, this.changeContractTypeToReserved, this.workOrder.id).subscribe(result => {
      this.openSnackBar('Removed Previous Selection', '');
      window.location.reload();
    });
  }

  submitChanges() {
    const changedDateTime = moment.utc(new Date()).toDate();
    this.openSnackBar('Submitting Meter Attribute Updates...', '');
    const toSubmit = new SubmitUpdateMeterAttributesChangesGroup();

    toSubmit.meterChanges = this.updateMeterAttributesResponse.meterDetail;

    toSubmit.meterChanges.forEach(change => {
      change.changeContractTypeToReserved = this.changeContractTypeToReserved;
    });

    toSubmit.workOrderId = this.workOrder.id;
    toSubmit.nodeSelection = this.nodeSelection;
    toSubmit.spaceChanges = this.updateMeterAttributesResponse.spaceDetail;
    toSubmit.newLocationTypeName = this.newLocationTypeName;
    toSubmit.newRequiredClosureAllowanceName = this.newRequiredClosureAllowanceName;
    toSubmit.newFineAmountId = this.fineAmountId;
    toSubmit.changeContractTypeToReserved = this.changeContractTypeToReserved;
    toSubmit.calculationDateTime = new Date();
    toSubmit.workOrderActionId = this.workOrderAction.id;
    toSubmit.zoneDisplayIds = this.updateMeterAttributesResponse.zoneDisplayIds;
    toSubmit.areaDisplayIds = this.updateMeterAttributesResponse.areaDisplayIds;
    toSubmit.blockDisplayIds = this.updateMeterAttributesResponse.blockDisplayIds;
    toSubmit.meterDisplayIds = this.updateMeterAttributesResponse.meterDisplayIds;
    toSubmit.changedDateTime = changedDateTime;

    this._updateMeterAttributesService
      .submitUpdateMeterAttributeChanges(toSubmit, this.workOrderAction.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId)
      .subscribe(
        result => {
          this.openSnackBar('Submitted Meter Attribute Updates', '');
          window.location.reload();
        },
        error => {
          this.openSnackBar('Error Submitting Meter Attribute Updates: ' + error['_body'], 'X');
          console.log('Error');
          console.log(error);
        }
      );
  }

  activateChanges() {
    const impactReasons = new Array<string>();
    this.workOrderAction.loadingZoneImpactChangeGroups.forEach(loadingZoneImpactChangeGroup => {
      loadingZoneImpactChangeGroup.relatedChanges.forEach(relatedChange => {
        const reason = impactReasons.find(impactReason => impactReason === relatedChange.impactReason);
        if (reason == null || reason === undefined) {
          impactReasons.push(relatedChange.impactReason);
        }
      });
    });
    if (impactReasons.length > 0) {
      const dialogRef = this.dialog.open(LoadingZoneImpactComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          impactReasons: impactReasons
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.createActivateBody();
        }
        else {
          this.openSnackBar('Activate Cancelled', 'X');
        }
      });
    }
    else {
      this.createActivateBody();
    }

  }

  createActivateBody() {
    const changedDateTime = moment.utc(new Date()).toDate();
    this.openSnackBar('Activating Meter Attribute Updates...', '');

    const toSubmit = new SubmitUpdateMeterAttributesChangesGroup();
    toSubmit.meterChanges = [];
    toSubmit.spaceChanges = [];
    toSubmit.workOrderId = this.workOrder.id;
    toSubmit.changedDateTime = moment.utc(new Date()).toDate();

    this.workOrderAction.updateMeterAttributesInventoryChanges.forEach(updateMeterAttributesChange => {

      updateMeterAttributesChange.relatedMeterChanges.forEach(meterChange => {
        const newMeterChange = new UpdateMeterAttributesMeterChange();
        newMeterChange.effectiveDate = this.effectiveDate;
        newMeterChange.meterId = meterChange.meterId;
        newMeterChange.meterDisplayId = meterChange.meterDisplayId;
        newMeterChange.changeContractTypeToReserved = this.changeContractTypeToReserved;
        newMeterChange.newLocationTypeName = this.newLocationTypeName;
        newMeterChange.newRequiredClosureAllowanceName = this.newRequiredClosureAllowanceName;

        toSubmit.meterChanges.push(newMeterChange);
      });


      updateMeterAttributesChange.relatedSpaceChanges.forEach(spaceChange => {
        const newSpaceChange = new UpdateMeterAttributesSpaceChange();
        newSpaceChange.effectiveDate = this.effectiveDate;
        newSpaceChange.meterId = spaceChange.meterId;
        newSpaceChange.meterDisplayId = spaceChange.meterDisplayId;
        newSpaceChange.spaceDisplayId = spaceChange.spaceDisplayId;
        newSpaceChange.spaceId = spaceChange.spaceId;
        newSpaceChange.newFineAmount = this.fineAmountId ? this.fineAmount : null;

        toSubmit.spaceChanges.push(newSpaceChange);
      });
    });

    toSubmit.newLocationTypeName = this.newLocationTypeName;
    toSubmit.newRequiredClosureAllowanceName = this.newRequiredClosureAllowanceName;
    toSubmit.changeContractTypeToReserved = this.changeContractTypeToReserved;
    toSubmit.effectiveDate = this.effectiveDate;
    toSubmit.calculationDateTime = new Date();
    toSubmit.workOrderActionId = this.workOrderAction.id;

    this._updateMeterAttributesService
      .activateUpdateMeterAttributeChanges(toSubmit, this.workOrderAction.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId)
      .subscribe(
        result => {
          this.openSnackBar('Activated Meter Attribute Updates', '');
          window.location.reload();
        },
        error => {
          this.openSnackBar('Error Activating Meter Attribute Updates: ' + error['_body'], 'X');
          console.log('Error');
          console.log(error);
        }
      );
  }

  returnWithComments() {
    this.dialogRefComments = this.dialog.open(CommentsDialogComponent, {
      height: '400px',
      width: '500px'
    });

    this.dialogRefComments.afterClosed().subscribe(result => {
      if (result != null && result !== undefined) {
        this.snackBar.open('Returning Inventory Changes with comments...', '');
        // create response
        const response = new WorkOrderActionResponse();
        response.responder = this.tokenCache.getUserName();
        response.responderRole = this.tokenCache.getUserRoles()[0];
        response.responseDate = new Date();
        response.responseType = 'Return With Comments';
        response.workOrderActionId = this.workOrderAction.id;
        response.workOrderId = this.workOrder.id;


        // create comment
        const newComment = new WorkOrderComment();
        newComment.author = this.tokenCache.getUserName();
        newComment.isInternal = false;
        newComment.text = result;
        newComment.commentTags = [];
        newComment.entryTimeStamp = new Date();
        newComment.workOrderId = this.workOrder.id;
        response.comment = newComment;

        this._updateMeterAttributesService.returnWithComments(response, this.changeContractTypeToReserved).subscribe(
          result => {
            window.location.reload();
          },
          error => {
            this.openSnackBar('Error Returning With Comments: ' + error['_body'], 'X');
          }
        );
      }
    });

  }

  acceptChanges() {
    this.openSnackBar('Accepting Meter Attribute Updates...', '');
    const response = new WorkOrderActionResponse();
    response.responder = this.tokenCache.getUserName();
    response.responderRole = this.tokenCache.getUserRoles()[0];
    response.responseDate = new Date();
    response.responseType = 'Approve';
    response.workOrderActionId = this.workOrderAction.id;
    response.comment = null;
    response.workOrderId = this.workOrder.id;

    this._updateMeterAttributesService.acceptChanges(response, this.changeContractTypeToReserved).subscribe(
      result => {
        window.location.reload();
      },
      error => {
        this.openSnackBar('Error Approving: ' + error['_body'], 'X');
      }
    );
  }

  sort(array: any[], sortBy?: string) {
    array.sort((a: any, b: any) => {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      } else if (a[sortBy] > b[sortBy]) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  }

  downloadAll(isSpace) {
    const changesToDownload = [];
    if (isSpace) {
      this.notEmptyUpdateMeterAttributeInventoryChanges.forEach(change => {
        change.relatedSpaceChanges.forEach(spaceChange => {
          changesToDownload.push(spaceChange);
        });
      });
    }
    else {
      this.notEmptyUpdateMeterAttributeInventoryChanges.forEach(change => {
        change.relatedMeterChanges.forEach(meterChange => {
          changesToDownload.push(meterChange);
        });
      });
    }

    const csvData = this.convertToCSV(changesToDownload, isSpace);
    this.downloadCsv(csvData);
  }



  downloadFile(
    isSpace: boolean,
    changeGroup: UpdateMeterAttributesInventoryChanges
  ) {
    let csvData;
    if (changeGroup) {
      csvData = this.convertToCSV(
        isSpace ? changeGroup.relatedSpaceChanges : changeGroup.relatedMeterChanges,
        isSpace
      );
    }
    else {
      csvData = this.convertToCSV(
        isSpace ? this.updateMeterAttributesResponse.spaceDetail : this.updateMeterAttributesResponse.meterDetail,
        isSpace
      );
    }

    this.downloadCsv(csvData);
  }

  downloadCsv(csvData) {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    const dateString = new Date().toDateString();
    a.download = 'Update Meter Attributes - ' + dateString + '.csv';
    a.click();
  }

  convertToCSV(objArray, isSpace) {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    let csvString = '';
    let headerRow = '';

    for (const index in objArray[0]) {
      let title = '';

      if (index.toLowerCase().includes('display')) {
        if (index === PropertyName.SpaceDisplayId) {
          title = 'Space ID';
        } else if (index === PropertyName.MeterDisplayId) {
          title = 'Meter ID';
        }
        headerRow += title + ',';
      }
    }

    headerRow += isSpace && this.fineAmount ? `${PropertyName.OldFineAmount},${PropertyName.NewFineAmount},` : '';
    headerRow += !isSpace && this.newLocationTypeName ? `${PropertyName.OldLocationType},${PropertyName.NewLocationType},` : '';
    headerRow += !isSpace && this.newRequiredClosureAllowanceName ? `${PropertyName.OldRca},${PropertyName.NewRca},` : '';
    headerRow += this.changeContractTypeToReserved ? `${PropertyName.OldContractType},${PropertyName.NewContractType},` : '';
    headerRow = headerRow.slice(0, -1);
    csvString += headerRow + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';

      const spaceDisplayId = array[i][PropertyName.SpaceDisplayId];

      if (spaceDisplayId) {
        line += spaceDisplayId + ',';
      }

      const meterDisplayId = array[i][PropertyName.MeterDisplayId];
      if (meterDisplayId) {
        line += meterDisplayId + ',';
      }

      if (isSpace && this.fineAmount) {
        let oldFineAmount = array[i][PropertyName.CurrentFineAmountId];
        console.log(oldFineAmount);

        oldFineAmount = this.fineAmountList.filter(fineAmount => {
          return fineAmount.id === oldFineAmount;
        })[0].amount;

        if (oldFineAmount) {
          line += oldFineAmount + ',';
        }
        else {
          line += ',';
        }
        line += this.fineAmount + ',';
      }

      if (!isSpace && this.newLocationTypeName) {
        //different names of different objects
        const oldLocationTypeName = array[i][PropertyName.currentLocationTypeName];
        const oldLocationTypeName2 = array[i][PropertyName.currentParkingTypeName];

        if (oldLocationTypeName) {
          line += oldLocationTypeName + ',';
        }
        else if (oldLocationTypeName2) {
          line += oldLocationTypeName2 + ',';
        }
        else {
          line += ',';
        }
        line += this.newLocationTypeName + ',';
      }

      if (!isSpace && this.newRequiredClosureAllowanceName) {
        //different names of different objects
        const oldRequiredClosureAllowanceName = array[i][PropertyName.CurrentRequiredClosureAllowanceName];
        const oldRequiredClosureAllowanceName2 = array[i][PropertyName.currentClosureAllowanceName];

        if (oldRequiredClosureAllowanceName) {
          line += oldRequiredClosureAllowanceName + ',';
        }
        else if (oldRequiredClosureAllowanceName2) {
          line += oldRequiredClosureAllowanceName2 + ',';
        }
        else {
          line += ',';
        }
        line += this.newRequiredClosureAllowanceName + ',';
      }

      if (this.changeContractTypeToReserved) {
        line += ContractTypeConstants.Concession.title + ',';
        line += ContractTypeConstants.Reserved.title + ',';
      }

      line = line.slice(0, -1);
      csvString += line + '\r\n';
    }
    return csvString;
  }
  showDetails(): boolean {
    return !this.isCity && ((this.workOrderStatusId !== WorkOrderStatusType.CompletedId && !this.inventoryChangesSubmitted) || (this.workOrderStatusId !== WorkOrderStatusType.CompletedId));
  }
}

class PropertyName {
  static MeterDisplayId = 'meterDisplayId';
  static SpaceDisplayId = 'spaceDisplayId';
  static CurrentFineAmountId = 'currentFineAmountId';
  static currentLocationTypeName = 'currentLocationTypeName';
  static currentParkingTypeName = 'currentParkingTypeName';
  static CurrentRequiredClosureAllowanceName = 'currentRequiredClosureAllowanceName';
  static currentClosureAllowanceName = 'currentClosureAllowanceName';
  static OldFineAmount = 'Old Fine Amount';
  static NewFineAmount = 'New Fine Amount';
  static OldLocationType = 'Old Location Type';
  static NewLocationType = 'New Location Type';
  static OldRca = 'Old Required Closure Allowance';
  static NewRca = 'New Required Closure Allowance';
  static OldContractType = 'Old Contract Type';
  static NewContractType = 'New Contract Type';
}
