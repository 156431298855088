import { Injectable, ErrorHandler } from '@angular/core';
import { TrueUpHttpService } from '../true-up-http.service';
import { LogMessageResponse } from './recent-log-messages/log-message-response';
import { Observable } from 'rxjs';
import { FileDownloadService } from '../core/file-download/file-download.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class LogMessagesService {

  constructor(private trueUpDataHttpService: TrueUpHttpService, protected errorHandler: ErrorHandler, private fileService: FileDownloadService) { }

  getRecent(count:Number, streamName?: string, token?: string): Observable<LogMessageResponse> {
    var queryString = '?';

    if (streamName != undefined
      && streamName != null
      && streamName != '') {
      queryString = queryString + `&streamName=${encodeURIComponent(streamName)}`;
    }

    if (token != undefined
      && token != null
      && token != '') {
      queryString = queryString + `&token=${encodeURIComponent(token)}`;
    }

    var endPoint = '/TrueUpCalculationLog/recent';
    return this.trueUpDataHttpService.get(`${endPoint}/${count}${queryString}`, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  downloadStream(streamName?: string): Observable<any> {
    var fileResult;
    var endPoint = '/TrueUpCalculationLog/download';
    var queryString = `streamName=${encodeURIComponent(streamName)}`;
    return this.trueUpDataHttpService.getFile(`${endPoint}?${queryString}`, null)
      .pipe(
        map(result => {
          var fileResult = this.fileService.MapResponseToFileResult(result);
          this.fileService.SaveFileToBrowser(fileResult);
          return true;
        }),
        catchError((error) => this.handleError(error)));
  }

  private handleError(error: any): Promise<any> {
    this.errorHandler.handleError(error);
    return Promise.reject(error.message || error);
  }
}
