
import { forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {catchError, map} from 'rxjs/operators';

import { InventoryHttpService } from '../../inventory-http.service';
import { ReversibilityDateRange } from './reversibility-date-range';
import { RatePackageReversibilityModel } from './rate-package-reversibility-model';
import { WorkorderActionJsonConstants } from '../action-field/workorder-action-json-constants';
import { ReverseRatePackageConstants } from '../action-field/action-field-constants';
import { AbstractJsonConverter } from '../../core/json-converters/abstract-json-convertert';
import { DateStringBuilder } from '../../core/query-strings/date-string-builder';
import { WorkOrder } from '../models/work-order';
import { LetterOfDirection } from '../models/letter-of-direction';


@Injectable()
export class ReversibilityDeciderService {

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
}
  constructor(private http: InventoryHttpService) {

  }

  checkSpaceActionReversibility(spaceId: string): Observable<boolean> {
    const url = `/spaces/${spaceId}/isReversible`;
    return this.http.get(url, null)
      .pipe(
        map(data => this.getReversibiltyFromResponse(data)),
        catchError(error => this.handleError(error))
      );
  }

  checkHistoricMultipleSpaceActionReversibility(spaceIds: Array<string>, date: Date): Observable<Array<boolean>> {
    const dateString = DateStringBuilder.buildStringFromDate(date);
    const variableArray = new Array<Observable<boolean>>();

    spaceIds.forEach(spaceId => {
      const url = `/spaces/${spaceId}/isReversible/${dateString}`;
      const observable = this.http.get(url, null).pipe(map(this.getReversibiltyFromResponse));
      variableArray.push(observable);
    });

    return observableForkJoin(variableArray);
  }

  getReversibiltyFromResponse(response: Response): boolean {

    let item = new ReversibilityDateRange();
    item = AbstractJsonConverter.jsonToItem<ReversibilityDateRange>(response, new ReversibilityDateRange());
    return item.isReversible;
  }

  getRatePackageReversalModelsForWorkOrderAction(workOrderActionIds: Array<string>, historicalDate?:Date): Observable<Array<Response>> {
    const variableArray = new Array<Observable<Response>>();
    workOrderActionIds.forEach(workOrderActionId => {
      var url = `/ratePackageReversal/workOrderAction/${workOrderActionId}`;

      if(historicalDate){
        var dateString = DateStringBuilder.buildStringFromDate(historicalDate);
        url = url + `/${dateString}`;
      }

      const observable = this.http.get(url, null);
      variableArray.push(observable);
    });
    return observableForkJoin(variableArray);
  }

  parseReversibilityModels(responses: Array<Response>): Array<WorkOrderActionRatePackageReversals> {
    const workOrderActionReversals = new Array<WorkOrderActionRatePackageReversals>();
    for (let i = 0; i < responses.length; i++) {
      const json = responses[i].json();
      const responseData = AbstractJsonConverter.jsonToListOfItems<RatePackageReversibilityModel>(json, () => new RatePackageReversibilityModel());
      const workOrderActionReversal = new WorkOrderActionRatePackageReversals();
      responseData.forEach(item => {
          workOrderActionReversal.reversedWorkOrderActionId = item.reversedWorkOrderActionId;
          workOrderActionReversal.ratePackageReversals.push(item);
      });
      workOrderActionReversals.push(workOrderActionReversal);
    }
    return workOrderActionReversals;
  }

  persistForNavigation(workOrder: WorkOrder, workOrderActionReversals: WorkOrderActionRatePackageReversals[], reason: string, historicalDate?:Date): string {
    const workOrderActionLetterOfDirectionJsons = [];
    const changedSpaceIds = [];
    for (let index = 0; index < workOrderActionReversals.length; index++) {
      const element = workOrderActionReversals[index];
      const lodJson = {};

      const ratePackageReversalModels = new Array<RatePackageReversibilityModel>();
      element.ratePackageReversals.forEach(x => {

        if (x.meterDisplayId) {
          lodJson[WorkorderActionJsonConstants.meterDisplayId] = x.meterDisplayId;
        }

        if (x.meterId) {
          lodJson[WorkorderActionJsonConstants.meterGuid] = x.meterId;
        }

        if (x.meterDisplayId) {
          lodJson[ReverseRatePackageConstants.MeterDisplayId] = x.meterDisplayId;
        }

        if (x.newRatePackageDisplayId) {
          lodJson[ReverseRatePackageConstants.RatePackageDisplayId] = x.newRatePackageDisplayId;
        }

        if (x.newRatePackageDisplayId) {
          lodJson[ReverseRatePackageConstants.Reason] = reason;
        }


        ratePackageReversalModels.push(x);

        changedSpaceIds.push(x.spaceId);
      });

      //remove any spaces that should not be included
      //first, copy the current LOD
      var currentLod:LetterOfDirection = JSON.parse(JSON.stringify(workOrder.currentLetterOfDirection));

      for(var i = 0; i < currentLod.workOrderActions.length; i++){
        var currentWoa = currentLod.workOrderActions[i];

        for(var j =0; j < currentWoa.spaceInventoryChanges.length; j++){
          if(!changedSpaceIds.includes(currentWoa.spaceInventoryChanges[j].spaceId)){
            currentWoa.spaceInventoryChanges[j] = null;
          }
        }

        currentWoa.spaceInventoryChanges = currentWoa.spaceInventoryChanges.filter(f => f != null);
      }

      lodJson['currentLetterOfDirection'] = currentLod;
      lodJson[WorkorderActionJsonConstants.ratePackageReversalModels] = ratePackageReversalModels;
      if(historicalDate){
        lodJson[WorkorderActionJsonConstants.ratePackageReversalDate] = historicalDate;
      }

      workOrderActionLetterOfDirectionJsons.push(lodJson);
    }



    const persistenceKey = `prepopulated-lod-reason:${workOrder.id}`;
    localStorage.setItem(persistenceKey, JSON.stringify(workOrderActionLetterOfDirectionJsons));
    return persistenceKey;
  }
}

// todo code review, does it make sense to leave this here?
export class WorkOrderActionRatePackageReversals {
  public reversedWorkOrderActionId: string;
  public ratePackageReversals: Array<RatePackageReversibilityModel> = new Array<RatePackageReversibilityModel>();
}
