exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "div {\r\n    padding-bottom:2%;\r\n}\r\n\r\n.AttributeTitle {\r\n    font-size: 16pt;\r\n}\r\n\r\n.revenue-value-history-header {\r\n    font-size: 11pt;\r\n    padding-top: 30px;\r\n    padding-bottom: 5px;\r\n}\r\n\r\n.revenue-value-history {\r\n    padding-top: 10px;\r\n    padding-bottom: 10px;\r\n    -webkit-box-align: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n}\r\n\r\n.active-chip {\r\n    background-color:#00c853;\r\n    color:white;\r\n}\r\n\r\n.inactive-chip {\r\n    background-color:#607d8b;\r\n    color:white;\r\n}\r\n\r\n.temp-removed-chip {\r\n    background-color: orange;\r\n    color: white;\r\n}\r\n\r\n.content {\r\n    margin-top:80px;\r\n}\r\n\r\nrect {\r\n    border: 2px solid black !important;\r\n}\r\n\r\n.map{\r\n    height:380px;\r\n}\r\n\r\nmat-chip {\r\n  height: 12.5px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();