import { PagedResult } from './../../core/models/paged-result';

export class SpaceSummary {

  aroundTheCornerBlockId: string;

  aroundTheCornerBlockDisplayId: string;

  blockRange: string;

  currentFineAmount: number;

  currentFineAmountId: number;

  currentMeterContractType: string;

  currentMeterDisplayId: string;

  currentMeterId: string;

  currentRatePackageId: string;

  currentRatePackageName: string;

  currentStatus: string;

  dateActive: string;

  dateChanged: string;

  dateCreated: string;

  displayId: string;

  dateRemoved: string;

  effectiveDate: string;

  expirationDate: string;

  id: string;

  isAroundCorner: boolean;

  lastExectedUtilizationRate: number;

  lastReservedPowerActionDate: string;

  lastUpdateDate: string;

  lastUpdatedBy: string;

  meterDisplayId: string;

  meterContractType: string;

  meterId: string;

  physicalBlockDisplayId: string;

  physicalBlockId: string;

  ratePackageDisplayId: string;
}

export class SpacePaginationResult extends PagedResult<SpaceSummary> {}
