import { Injectable } from '@angular/core';
import { environment } from '../../environment';

@Injectable()
export class ClosureLinkService {

  constructor( ) { }

  openClosureAppInNewTab(closureId:string){
    window.open(`${environment.CLOSURE_SITE_BASE}#/main/edit/${encodeURIComponent(closureId)}`, closureId, null, false);
  }

}
