import { StringExtensions } from './../../string-extensions';
import { WorkOrderGroup } from '../models/work-order-group';
import { FilterOperator } from '../../core/query-strings/query-string-builder';
export class WorkOrderFilterRepresentation {
    WorkOrderId: string = undefined;
    get FilterByWorkOrderId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.WorkOrderId);
    }
    WorkOrderCanvasId: string = undefined;
    get FilterByWorkOrderCanvasId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.WorkOrderCanvasId);
    }
    WorkOrderGroup: WorkOrderGroup = undefined;
    WorkOrderGroupId: string = undefined;
    get FilterByWorkOrderGroupId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.WorkOrderGroupId);
    }
    RequesterUserRole: string = undefined;
    get FilterByRequesterUserRole(): boolean {
        return StringExtensions.StringIsNotEmpty(this.RequesterUserRole);
    }
    ActionType: string = undefined;
     get FilterByActionType(): boolean {
         return StringExtensions.StringIsNotEmpty(this.ActionType);
    }
    CurrentStatus: string = undefined;
    get FilterByCurrentStatus(): boolean {
        return StringExtensions.StringIsNotEmpty(this.CurrentStatus);
    }

    InProgress: string = undefined;
    get FilterByInProgress(): boolean {
        return StringExtensions.StringIsNotEmpty(this.InProgress);
    }

    AssignedUserRole: string = undefined;
    get FilterByAssignedUserRole(): boolean {
        return StringExtensions.StringIsNotEmpty(this.AssignedUserRole);
    }

    ContractType: string = undefined;
    get FilterByContractType(): boolean {
        return StringExtensions.StringIsNotEmpty(this.ContractType);
    }
    MeterId: string = undefined;
    get FilterByMeterId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.MeterId);
    }
    BlockId: string = undefined;
    get FilterByBlockId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.BlockId);
    }
    AreaId: string = undefined;
    get FilterByAreaId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.AreaId);
    }
    ZoneId: string = undefined;
    get FilterByZoneId(): boolean {
        return StringExtensions.StringIsNotEmpty(this.ZoneId);
    }

    TempApprovalStatus: string = undefined;
    get FilterByTempApprovalStatus(): boolean {
      return StringExtensions.StringIsNotEmpty(this.TempApprovalStatus);
    }

    OriginalRequestDate: string = undefined;
    OriginalRequestDateDisplayString: string = undefined;
    get FilterByOriginalRequestDate(): boolean {
        return StringExtensions.StringIsNotEmpty(this.OriginalRequestDate);
    }
    RevisedRequestDate: string = undefined;
    RevisedRequestDateDisplayString: string = undefined;
    get FilterByRevisedRequestDate(): boolean {
        return StringExtensions.StringIsNotEmpty(this.RevisedRequestDate);
    }
    ProjectedCompletionDate: string = undefined;
    ProjectedCompletionDateDisplayString: string = undefined;
    get FilterByProjectedCompletionDate(): boolean {
        return StringExtensions.StringIsNotEmpty(this.ProjectedCompletionDate);
    }
    DueDate: string = undefined;
    DueDateRangeEnd: string = undefined;
    DueDateDisplayString: string = undefined;
    DueDateRangeEndDisplayString: string = undefined;
    DueDateRangeFilterOperator: string = FilterOperator.Between;
    get FilterByDueDate(): boolean {
        return StringExtensions.StringIsNotEmpty(this.DueDate);
    }

    CompletionDate: string = undefined;
    CompletionDateRangeEnd: string = undefined;
    CompletionDateDisplayString: string = undefined;
    CompletionDateRangeEndDisplayString: string = undefined;
    CompletionDateRangeFilterOperator: string = FilterOperator.Between;
    get FilterByCompletionDate(): boolean {
        return StringExtensions.StringIsNotEmpty(this.CompletionDate);
    }

    EffectiveDate: string = undefined;
    EffectiveDateRangeEnd: string = undefined;
    EffectiveDateDisplayString: string = undefined;
    EffectiveDateRangeEndDisplayString: string = undefined;
    EffectiveDateRangeFilterOperator: string = FilterOperator.Between;
    get FilterByEffectiveDate(): boolean {
        return StringExtensions.StringIsNotEmpty(this.EffectiveDate);
    }

}
