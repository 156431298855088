export class MonthlyTrueUpCalculationDetailBase
{
        forMonth: string;
    
        calendarMonth: number;
    
        calendarYear: number;
    
        reportingQuarter: number;
    
        reportingYear: number;
    
        displayReportingQuarter: string;
    
        revenueValue: number;
    
        existingRevenue: number;
    
        fullyUtilizedAmount: number;

        measuredFullyUtilizedAmount: number;
        
        utilizedAmountInoperablePeriods; number;

        meteredParkingRevenue: number;

        measuredUtilizationRate: number;

        requiredClosurePayments: number;

        requiredClosurePaymentsYearToDate: number;

        meteredParkingRevenueYearToDate: number;

        measuredFullyUtilizedAmountYearToDate: number;

        actualMonthlyUtilizationRate: number;

        averageMonthlyUtilizationRateYearToDate: number;

        fullyUtilizedAmountYearToDate: number;

        revenueValueAdjustment: number;

        closureWarehouseRecordIds: string;
}