import * as moment from 'moment';

export let MonthNameToMonth = {
    'January' : 1,
    'February': 2,
    'March': 3,
    'April': 4,
    'May': 5,
    'June': 6,
    'July': 7,
    'August': 8,
    'September': 9,
    'October': 10,
    'November': 11,
    'December': 12
};

export let MonthToMonthName = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

export let MonthsInFiscalYear = ['March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
'November', 'December', 'January', 'February'];

export class FiscalUnitConverter {
    static getFiscalQuarter(month: any): number {
        if (month >= 3 && month <= 5) {
            return 1;
        }
        else if (month >= 6 && month <= 8) {
            return 2;
        } else if (month >= 9 && month <= 11) {
            return 3;
        } else if (month === 12 || month <= 2) {
            return 4;
        }
  }

  private static getFirstMonthOfQuarter(quarter: number): number {
    if (quarter === 1) {
      return 3;
    }
    else if (quarter === 2) {
      return 6;
    }
    else if (quarter === 3) {
      return 9;
    }
    else if (quarter === 4) {
      return 12;
    }

    return NaN;
  }

  static getFiscalYearFromQuarter(quarter: any, currentYear: any): number {
          if (quarter === 4) {
              return currentYear - 1;
          } else {
              return currentYear;
          }
  }

  static getStartDateOfQuarter(quarter: number, fiscalYear: number): Date {
    var newestQuarterMonth = FiscalUnitConverter.getFirstMonthOfQuarter(quarter);

    var newestQuarterDate = new Date(fiscalYear, newestQuarterMonth-1);

    return newestQuarterDate;
  }

  static getFiscalQuartersToDate(systemStartDate: Date): any[] {
    const systemStartYear = systemStartDate.getFullYear();

    let startingYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    let startingQuarter = FiscalUnitConverter.getFiscalQuarter(currentMonth);

    // include next quarter, sometimes this may be run
    if (startingQuarter === 4) {
      startingQuarter = 1;
      startingYear = startingYear + 1;
    }
    else {
      startingQuarter = startingQuarter + 1;
    }

    const reportingQuarters = [];
    
    for (let year = startingYear; year >= systemStartYear; year--) {
      if (year === startingYear) {
        for (let q = Number(startingQuarter); q >= 1; q--) {

          if (this.getStartDateOfQuarter(q, year) < systemStartDate) {
            continue;
          }

          reportingQuarters.push(
            {
              value: `Q${q} ${year}`,
              viewValue: `Q${q} ${year}`,
              reportingYear: year,
              reportingQuarter: q
            });
        }
      }
      else {
        for (let q = 4; q >= 1; q--) {

          if (this.getStartDateOfQuarter(q, year) < systemStartDate) {
            continue;
          }

          reportingQuarters.push(
            {
              value: `Q${q} ${year}`,
              viewValue: `Q${q} ${year}`,
              reportingYear: year,
              reportingQuarter: q
            });
        }
      }
    }

    return reportingQuarters;
  }

  static GetFiscalYear(date: Date): number {
    var qtr = this.getFiscalQuarter(date.getMonth());

    return this.getFiscalYearFromQuarter(qtr, date.getFullYear());
  }
}

export class DateTimeUtility {
    public static MAXDATE = '9999-12-31T23:59:59.9999999+00:00';
    public static MINDATE = '0001-01-01T00:00:00+00:00';

    /*
    If date2 is earlier than date1, return true; otherwise return false.
    */
    static compareDate(date1: any, date2: any): boolean {
        const d1 = moment.utc(Date.parse(date1));
        const d2 = moment.utc(Date.parse(date2));

        if (d2 < d1) {
            return true;
        }
        return false;
    }

    static parseMonth(dateTime: any): number {
        return moment.utc(Date.parse(dateTime)).month() + 1;
    }
    static parseYear(dateTime: any): number {
        return moment.utc(Date.parse(dateTime)).year();
    }
    /**static parseDateString(dateTime: any): string {
        const dateString = moment.utc(Date.parse(dateTime))
                .format('M/D/YY');**/
    static parseDateString(dateTime: any, dateFormat: any = 'M/D/YY'): string {
        const dateString = moment.utc(Date.parse(dateTime))
                .format(dateFormat);
        return dateString;
    }

    static parseDateTimeString(dateTime: any, dateFormat: any = 'M/D/YY, h:mm:ss'): string {
        const dateString = moment.utc(Date.parse(dateTime))
                .format(dateFormat);
        return dateString;
    }

    static parseTimeString(dateTime: any, utc: boolean = false): string {
        let timeString  = '';
        if (utc) {
            timeString = moment.utc(Date.parse(dateTime)).format('HH:mm:ss');
        } else {
            timeString = moment(Date.parse(dateTime)).format('HH:mm:ss');
        }
        return timeString;
    }
}

