import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as pako from 'pako';
// import { Http, Headers, ConnectionBackend, RequestOptions, RequestOptionsArgs, Response, ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';

import { environment } from '../../environment';
import { TokenCache } from '../../security/token-cache';

@Injectable()
export class WorkOrderHttpService extends HttpClient {
    headers: HttpHeaders;
    postOptions: HttpRequestOptions;

    constructor(backend: HttpBackend, options: HttpRequestOptions, private tokenCache: TokenCache) {
        super(backend);
        this.setHeaders();
        this.tokenCache.onTokenChange.subscribe(() => this.setHeaders());
    }

    public get(url: string, options?: any): Observable<any> {
      options = options || new HttpRequestOptions();

      if (!options.headers) {
        options.headers = this.headers;
      }

      url = `${environment.WORKORDER_API_BASE}${url}`;
      return super.get(url, options);
    }

    public getFile(url: string, options?: HttpRequestOptions): Observable<Object> {
        // allow changing response type for file downloads
      var option;
      if (!options) {
        const newHeaders = this.headers;
        newHeaders.append('Accept', 'application/*');

        option = {headers: newHeaders, responseType: 'blob', observe: 'response' };
      }

        url = `${environment.WORKORDER_API_BASE}${url}`;
        return super.get(url, option);
    }

    public postToSurvey(url: string, body: any): Observable<Object> {
      url = `${environment.LOGIN_API_BASE}${url}`;

      return super.post(url, body, this.getRequestOption(this.postOptions));
    }

    public post(url: string, body: any): Observable<any> {
      url = `${environment.WORKORDER_API_BASE}${url}`;

      if (body && JSON.stringify(body).length > (1000)) { //bigger than 1000 characters? compress!
        return this.postCompressed(url, body);
      }
      else {
        return super.post(url, body, this.getRequestOption(this.postOptions));
      }
    }

    private postCompressed(url: string, body: any): Observable<Object> {
      const newHeaders = this.headers;

      var option = new HttpRequestOptions();
      option.headers = newHeaders;
      option.headers = option.headers.set('x-content-encoding-asp-net', 'gzip');
      option.headers = option.headers.set('Content-Type', 'application/octet-stream');
      option.responseType = 'json';

      var compressedBody = pako.gzip(JSON.stringify(body))

      return super.post(url, compressedBody.buffer, this.getRequestOption(option));
    }

    //, options?: RequestOptionsArgs
    public put(url: string, body?: any): Observable<any> {
        url = `${environment.WORKORDER_API_BASE}${url}`;

        if (body && JSON.stringify(body).length > (1000)) { //bigger than 1000 characters? compress!
          return this.putCompressed(url, body);
        }
        else {
          return super.put(url, body, this.getRequestOption(this.postOptions));
        }
    }

    private putCompressed(url: string, body: any): Observable<Object> {

      const newHeaders = this.headers;

      var option = new HttpRequestOptions();
      option.headers = newHeaders;
      option.headers = option.headers.set('x-content-encoding-asp-net', 'gzip');
      option.headers = option.headers.set('Content-Type', 'application/octet-stream');
      option.responseType = 'json';

      var compressedBody = pako.gzip(JSON.stringify(body))

      return super.put(url, compressedBody.buffer, this.getRequestOption(option));
    }

    public delete(url: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = { headers: this.headers };
        }
        url = `${environment.WORKORDER_API_BASE}${url}`;
        return super.post(url, body, options);
    }

    private setHeaders() {
        this.headers = new HttpHeaders({
          'Authorization': this.tokenCache.getBearerToken()
        });

        const newHeader = new HttpHeaders({
          'Authorization': this.tokenCache.getBearerToken(),
          'Content-Type': 'application/json'
        });

        this.postOptions = new HttpRequestOptions(newHeader);
    }

    private getRequestOption(option: HttpRequestOptions): any {
      const options = {
        headers: option.headers,
        observe: option.observe,
        params: option.params,
        reportProgress: option.reportProgress,
        responseType: option.responseType,
        withCredentials: option.withCredentials
      };

      return options;
    }
}
