import { QueryStringBuilder, FilterOperator } from './../../core/query-strings/query-string-builder';
import { InventoryFilterRepresentation } from './../inventory-filter/inventory-filter-representation';

export class InventoryQueryStringBuilder {
    static createFilterQueryString(evt: InventoryFilterRepresentation, isMeter: boolean): string {

        let filterQueryString = '';

        if (evt.FilterByBlockDisplayId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'blockDisplayId', evt.BlockDisplayIdFilterOperator, evt.BlockDisplayId);
        }
        if (evt.FilterByBlockId) {
            filterQueryString = QueryStringBuilder.AddGuidEquals(filterQueryString, 'blockId', evt.BlockId);
        }
        if (evt.FilterByMeterId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'meterId', evt.MeterIdFilterOperator, `${evt.MeterId}`);
        }
        if (evt.FilterByStreetName) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'streetName', evt.StreetNameFilterOperator, evt.StreetName, null);
        }
        if (evt.FilterByBlockRange) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'blockRange', evt.BlockRangeFilterOperator, evt.BlockRange, evt.BlockRangeEnd);
        }
        if (evt.FilterByArea) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'area', evt.AreaFilterOperator, evt.Area, evt.AreaEnd);
        }
        if (evt.FilterByZone) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'zone', evt.ZoneFilterOperator, evt.Zone, evt.ZoneEnd);
        }
        if (evt.FilterByWard) {

            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'ward', evt.WardFilterOperator, evt.Ward, evt.WardEnd);
        }
        if (evt.FilterByMeterType) {

            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'meterType', evt.MeterTypeFilterOperator, evt.MeterType, null);
        }
         if (evt.FilterByMeterStatus) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'meterStatus', evt.MeterStatusFilterOperator, evt.MeterStatus, null);
        }
        if (evt.FilterByContractStatus) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'contractType', evt.ContractType);
        }
        if (evt.FilterByParkingType) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'locationType', evt.ParkingTypeFilterOperator, evt.ParkingType, null);
        }
        if (evt.FilterByParentMeterId) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'parentMeterId', `${evt.ParentMeterId}`);
        }
        if (evt.FilterByVirtualMeterId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'virtualMeterId', evt.VirtualMeterIdFilterOperator, evt.VirtualMeterId, null);
        }
        if (evt.FilterBySignOnlyMeterId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'signOnlyMeterId', evt.SignOnlyMeterIdFilterOperator, evt.SignOnlyMeterId, null);
        }
        if (evt.FilterByRatePackageId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'ratePackageId', evt.RatePackageNameFilterOperator, evt.RatePackageId, null);
        }
        if (evt.FilterByHistoricallyAssociatedRatePackageId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'ratePackageHistoricalMeters', evt.HistoricallyAssociatedRatePackageIdFilterOperator, evt.HistoricallyAssociatedRatePackageId, null);
        }
        if (evt.FilterByLinkedBlockParentId) {
          filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'linkedBlocks', evt.LinkedBlockFilterOperator, evt.LinkedBlockParentId, null);
      }
        if (evt.FilterBySpaceStatus) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'spaceStatus', evt.SpaceStatus);
        }
        if (evt.FilterBySpaceId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'spaceId', evt.SpaceIdFilterOperator, `${evt.SpaceId}`);
        }
        if (evt.FilterBySpaceType) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'blockSpaceType', evt.SpaceTypeFilterOperator, evt.SpaceType, null);
        }
        if (evt.FilterByTempRemovalStatus) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'tempRemoved', evt.TempRemovalStatusFilterOperator, evt.TempRemovalStatus, null);
        }
        if(evt.FilterByHasSignsOnly) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'hasSignsOnly', evt.HasSignsOnlyFilterOperator, evt.HasSignsOnly, null);
        }
        if(evt.FilterByWorkOrder) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'workOrderActionIds', evt.WorkOrderActionIds);
        }
        return QueryStringBuilder.PrependFilterQueryStringVariableName(filterQueryString);
    }
}
