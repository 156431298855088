exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "hr {\r\n    display: block;\r\n    margin: 10px 0 10px 0;\r\n    border-top: 1px solid rgba(0, 0, 0, .12);\r\n    width: 100%\r\n}\r\n\r\ndiv {\r\n    padding-bottom: 2%;\r\n}\r\n\r\nspan.title {\r\n    padding-bottom:25px;\r\n}\r\n\r\n.mat-tab-body {\r\n    overflow-y:hidden;\r\n}\r\n\r\n.meters-and-spaces-card {\r\n    padding:0;\r\n    padding-left:1%;\r\n    padding-right:1%;\r\n    height:100%;\r\n}\r\n\r\n.mat-card-subtitle{\r\n    margin-bottom: 25px;\r\n}\r\n\r\n.mat-tab-label {\r\n    font-size:50%;\r\n}\r\n\r\n.svg {\r\n    width:100%;\r\n}\r\n\r\nmat-chip {\r\n  height: 12.5px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();