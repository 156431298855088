import { WorkOrderReportService } from './services/work-order-report.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsHomeComponent } from './reports-home/reports-home.component';
import { CoreModule } from '../core/core.module';
import { MeterReservedPowerComponent } from './reserved-power/meter-reserved-power/meter-reserved-power.component';
import { SpaceReservedPowerComponent } from './reserved-power/space-reserved-power/space-reserved-power.component';
import { TimePeriodPickerComponent } from './shared/time-period-picker/time-period-picker.component';
import { ReservedPowerReportingService } from './services/reserved-power-reporting.service';
// import { XHRBackend, RequestOptions } from '@angular/http';
import { HttpXhrBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../core/http-request-options';
import { TokenCache } from '../security/token-cache';
import { FileDownloadService } from '../core/file-download/file-download.service';
import { TempRemovedMetersComponent } from './temp-removed-meters/temp-removed-meters.component';
import { TempRemovedMeterService } from './services/temp-removed-meters.service';
import { WorkOrdersReportComponent } from './work-orders/work-orders.component';
import { MeterDowntimeReportComponent } from './meter-downtime/meter-downtime.component';
import { SystemSummaryComponent} from './system-summary/system-summary.component';
import { SystemSummaryReportService} from './services/system-summary-report.service';
import { AuditReportService } from './services/audit-report.service';
import { AuditComponent } from './audit/audit.component';
import { MeterDowntimeReportService } from './services/meter-downtime-report.service';
import { CollectionsComponent } from './collections/collections.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    ReportsHomeComponent,
    MeterReservedPowerComponent,
    SpaceReservedPowerComponent,
    TimePeriodPickerComponent,
    TempRemovedMetersComponent,
    WorkOrdersReportComponent,
    MeterDowntimeReportComponent,
    SystemSummaryComponent,
    AuditComponent,
    CollectionsComponent
  ],
  providers: [
    {
      provide: ReservedPowerReportingService,
      useFactory: reportingHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    },
    {
      provide: WorkOrderReportService,
      useFactory: workOrderReportHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    },
    {
      provide: MeterDowntimeReportService,
      useFactory: meterDowntimeReportHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    },
    {
      provide: AuditReportService,
      useFactory: auditReportHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    },
    {
      provide: SystemSummaryReportService,
      useFactory: systemSummaryReportHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    },
    TempRemovedMeterService
  ]
})
export class ReportsModule { }

export function reportingHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new ReservedPowerReportingService(backend, defaultOptions, TokenCache, fileDownload);
}

export function workOrderReportHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new WorkOrderReportService(backend, defaultOptions, TokenCache, fileDownload);
}

export function meterDowntimeReportHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new MeterDowntimeReportService(backend, defaultOptions, TokenCache, fileDownload);
}

export function auditReportHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new AuditReportService(backend, defaultOptions, TokenCache, fileDownload);
}

export function systemSummaryReportHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new SystemSummaryReportService(backend, defaultOptions, TokenCache, fileDownload);
}
