export class WorkOrderActionStatusConstants {
    static CreatedId = 0;
    static CreatedName = "Created";//saved or not touched

    static CompletedId = 50;
    static CompletedName = "Completed";//submitted

    static Activated = 100;
    static ActivatedName = "Activated";

    static Undone = 150;
    static UndoneName = "Undone";

    static CancelledId = 200;
    static CancelledName = "Cancelled";
}  
export class WorkOrderStatusConstants{
    static  DraftedId = 0;
    static  DraftedName = "Drafted";

    static  ReadyForReviewId = 10;
    static  ReadyForReviewName = "Ready for Review";

    static  AcceptedId = 15;
    static  ApprovedName = "Accepted";

    static TemporarilyApprovedId =  16;
    static TemporarilyApprovedName= 'Temporarily Approved';

    static PausedId = 20;
    static PausedName = 'Paused';

    static  PendingSurveyId = 25;
    static  PendingSurveyName = "Pending Survey";

    static  ReadyForSurveyId = 50;
    static  ReadyForSurveyName = "Ready for Survey";

    static  PostSurveyApprovedId = 75;
    static  PostSurveyApprovedName = "Post-Survey Aprroved";
     
    static  CompletedId = 100;
    static  CompletedName = "Completed";

    static  UndoneId = 150;
    static  UndoneName = "Undone";

    static  CancelledId = 200;
    static  CancelledName = "Cancelled";


}
