// This file corresponds with src\app\trueup\monthly-calculations\monthly-true-up-calculation-filter-representation-base.ts


import { FilterOperator } from '../../../core/query-strings/query-string-builder';
import { TimeZoneConverter } from '../../../core/time-zone/time-zone-converter';



export class CollectionsFilterRepresentation {

    MeterIdFilterOperator: string = FilterOperator.Equal;
    MeterId: string = undefined;
    get ShouldFilterByMeterId(): boolean {
        return this.stringIsNotNullOrEmpty(this.MeterId);
    }
    DateOfCollectionFilterOperator: string = FilterOperator.GreaterThanOrEqual;

    DateOfCollectionEnd: Date = undefined;
    get MarchFirst(): Date {
      const today = new Date();
      const thisYear = today.getUTCFullYear();

      const marchFirst = new Date(thisYear, 2, 1, 0, 0, 0, 0); //month is zero based

      if (marchFirst <= today) {
        return TimeZoneConverter.EnsureLocalTime(marchFirst);
      }
      else {
        marchFirst.setFullYear(thisYear - 1); // set to last year
        return TimeZoneConverter.EnsureLocalTime(marchFirst);
      }
    }

    DateOfCollection: Date = this.MarchFirst;

    get ShouldFilterByDateOfCollection(): boolean {
        return this.stringIsNotNullOrEmpty(this.DateOfCollection);
    }
    TimeOfCollectionFilterOperator: string = FilterOperator.Equal;
    TimeOfCollectionAMPM: string = 'AM';
    TimeOfCollectionSeconds: string = '00';
    TimeOfCollectionHM: string= undefined;
    TimeOfCollection: string = undefined;

    TimeOfCollectionEnd: string = undefined;
    TimeOfCollectionHMEnd: string = undefined;
    TimeOfCollectionAMPMEnd: string = 'AM';
    TimeOfCollectionSecondsEnd: string = '00';
    get ShouldFilterByTimeOfCollection(): boolean {
        return this.stringIsNotNullOrEmpty(this.TimeOfCollection);
    }

    DateReceivedFilterOperator: string = FilterOperator.GreaterThanOrEqual;
    DateReceived: Date = undefined;
    DateReceivedEnd: Date = undefined;
    get ShouldFilterByDateReceived(): boolean {
        return this.stringIsNotNullOrEmpty(this.DateReceived);
    }
    CollectionAmountFilterOperator: string = FilterOperator.Equal;
    CollectionAmount: string = undefined;
    CollectionAmountEnd: string = undefined;
    get ShouldFilterByCollectionAmount(): boolean {
        return this.stringIsNotNullOrEmpty(this.CollectionAmount);
    }
    CollectionType: Array<string> = [];
    get ShouldFilterByCollectionType(): boolean {
        return this.CollectionType.length > 0;
    }
    DenominationName: string = undefined;
    get ShouldFilterByDenominationName(): boolean {
        return this.stringIsNotNullOrEmpty(this.DenominationName);
    }

    ZoneFilterOperator: string = FilterOperator.Equal;
    Zone: string = undefined;
    ZoneEnd: string = undefined;
    get ShouldFilterByZone(): boolean {
        return this.stringIsNotNullOrEmpty(this.Zone);
    }

    AreaFilterOperator: string = FilterOperator.Equal;
    Area: number = undefined;
    AreaEnd: number = undefined;
    get ShouldFilterByArea(): boolean {
        return this.stringIsNotNullOrEmpty(this.Area);
    }

    BlockIdFilterOperator: string= FilterOperator.Equal;
    BlockId: string = undefined;
    get ShouldFilterByBlockId(): boolean {
        return this.stringIsNotNullOrEmpty(this.BlockId);
    }
    ParkingType: string = undefined;
    get ShouldFilterByParkingType(): boolean {
        return this.stringIsNotNullOrEmpty(this.ParkingType);
    }
    CollectionNumberFilterOperator: string = FilterOperator.Equal;
    CollectionNumber: string = undefined;
    CollectionNumberEnd: string = undefined;
    get ShouldFilterByCollectionNumber(): boolean {
        return this.stringIsNotNullOrEmpty(this.CollectionNumber);
    }

    NumberOfCoinsFilterOperator: string = FilterOperator.Equal;
    NumberOfCoins: string = undefined;
    NumberOfCoinsEnd: string = undefined;
    get ShouldFilterByNumberOfCoins(): boolean {
        return this.stringIsNotNullOrEmpty(this.NumberOfCoins);
    }

    PurchaseCountFilterOperator: string = FilterOperator.Equal;
    PurchaseCount: string = undefined;
    PurchaseCountEnd: string = undefined;
    get ShouldFilterByPurchaseCount(): boolean {
        return this.stringIsNotNullOrEmpty(this.PurchaseCount);
    }
    GUIDS: string = undefined; // use this for default sorting
    CollectionGUIDFilterOperator: string = FilterOperator.Equal;
    CollectionGUID: string = undefined;
    get ShouldFilterByCollectionGUID(): boolean {
        return this.stringIsNotNullOrEmpty(this.CollectionGUID);
    }


    private stringIsNotNullOrEmpty(str): boolean {
        if (str === undefined || str == null) {
            return false;
        }
        // tslint:disable-next-line: prefer-const
        let asString = `${str}`; // this is because typescript + javascript is stupid and casts types wrong when no one asks it to.
        return asString.length > 0;
    }
}
