exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".work-order-tab-summary-sentance {\r\n    font-size: 12pt;\r\n    min-height:50px;\r\n    margin-left: 20px;\r\n}\r\n\r\n.work-order-action-dates {\r\n  font-size: 8pt;\r\n}\r\n\r\n.work-order-detail {\r\n    padding-top:50px;\r\n    padding-left:5px;\r\n}\r\n\r\ndiv.mat-tab-label {\r\n    min-width:150px !important;\r\n    width:150px !important;\r\n}\r\n\r\n.view-work-order-button {\r\n    margin-left: 15px;\r\n}\r\n\r\n.cancel-button {\r\n    margin-right:20px;\r\n}\r\n\r\n.with-left-margin {\r\n    margin-left: 2%;\r\n}\r\n\r\n.group-name-label {\r\n    width: 125px;\r\n}\r\n\r\n.related-work-order-span {\r\n    padding-bottom: 1px;\r\n}\r\n\r\n.wo-group-submit-button {\r\n    height: 40px;\r\n}\r\n\r\n.work-order-group-button {\r\n    margin-left: 2%;\r\n    width: 125px;\r\n}\r\n\r\n.mat-chip-list {\r\n  margin-left:15px;\r\n}\r\n\r\n.mat-chip {\r\n  padding:10px;\r\n  font-size:50%;\r\n}\r\n\r\n.mat-card-title {\r\n  height: 50px;\r\n}\r\n\r\n.action-header-row {\r\n  min-height:50px;\r\n  width:100%;\r\n}\r\n\r\n.mat-card-subtitle {\r\n  margin-left: 10px;\r\n}\r\n\r\n.inventory-information {\r\n  margin-top:20px;\r\n  margin-bottom:20px;\r\n}\r\n\r\n.work-order-subtitle {\r\n  font-size: 75%;\r\n}\r\n\r\n.mat-raised-button {\r\n  font-size:65%;\r\n  min-width: 50px;\r\n}\r\n\r\nh3 {\r\n  max-height:8px;\r\n  height:8px;\r\n}\r\n\r\n.error{\r\n  font-style: italic;\r\n  color: red;\r\n}\r\n\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();