import { Injectable } from '@angular/core';

@Injectable()
export class AddressValidationService {

  constructor() { }

  public validateAddressIsValid(numberRangeStart: any, numberRangeEnd: any): LocationValidationResult {
      const result = new LocationValidationResult();
      const blockStartAsNumber = Number(numberRangeStart);
      if (blockStartAsNumber === NaN) {
        result.message = 'Block Start is not valid a number';
        result.failed = true;
        return result;
      }
      const blockEndAsNumber = Number(numberRangeEnd);
      if (blockEndAsNumber === NaN) {
        result.message = 'Block End is not a valid number';
        result.failed = true;
        return result;
      }

      if (blockEndAsNumber % 2 !== blockStartAsNumber % 2) {
        result.message = 'Block start and end must both be even or both be odd';
        result.failed = true;
        return result;
      }

      result.message = '';
      result.failed = false;
      return result;
  }
}

export class LocationValidationResult {
  failed: boolean;
  message: any;
}
