export class ActionConstants {
    static InstallSpaceId = 3;
    static InstallSpaceName = 'Install Space on Active Meter';

    static InstallMeterId = 5;
    static InstallMeterName = 'Install Meter';

    static RemoveSpaceId = 7;
    static RemoveSpaceName = 'Remove Space(s)/Meter';

    static ChangeRatePackageId = 9;
    static ChangeRatePackageName = 'Change Rate Package';

    static ShiftSpaceId = 11;
    static ShiftSpaceName = 'Shift Space';

    static InactivateMeterId = 13;
    static InactivateMeterName = 'Inactivate Meter';

    static ReactivateMeterId = 15;
    static ReactivateMeterName = 'Reactivate Meter';

    static ChangeContractTypeToConcessionId = 17;
    static ChangeContractTypeToConcessionName = 'Change Contract Type to Concession';

    static RegularRateAdjustmentId = 19;
    static RegularRateAdjustmentName = 'Regular Rate Adjustment';

    static UpdateMeterAttributesId = 21;
    static UpdateMeterAttributesName = 'Update Meter Attributes';

    public static ReverseRatePackageChangeId = 23;
    public static ReverseRatePackageChangeName = 'City Directed Reserved Power Reversal';

    public static RelocateMeterId = 25;
    public static RelocateMeterName = 'Relocate Meter';

    static UpdateMeterAttributesChangeContractTypeId = 27;
    static UpdateMeterAttributesChangeContractTypeName = 'Update Meter Attributes Change Contract Type';
}
