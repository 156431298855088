import { MonthlyTrueUpCalculationDetailBase } from "../monthly-calculations/monthly-true-up-calculation-detail-base";

export class SpaceTrueUpDetail extends MonthlyTrueUpCalculationDetailBase {

    isActive: boolean;
    
    spaceDisplayId: string;
    
    spaceId: string;
    
    meterDisplayId: string;

    meterId:string;

    ratePackageId: string;

    ratePackageName: string;

    ratePackageDescription: string;

    periodOfOperation: number;

    areaId: string;

    areaDisplayId: string;

    blockId: string;

    blockDisplayId: string;

    blockAddress: string;

    ward: number;

    zoneId: string;

    zoneDisplayId: string;

    currentStatus: string;

    lastReservedPowerWorkOrderActionId: string;

    lastReservedPowerWorkOrderActionEffectiveDate: string;

    expectedUtilizationRate: number;

    expectedUtilizationAdjustment: number;


}
