import { Injectable, ErrorHandler } from '@angular/core';
import { QueryStringBuilder } from '../../core/query-strings/query-string-builder';
import { Observable } from 'rxjs';
import { PagedResult } from '../../core/models/paged-result';
import { SystemTrueUpDetail } from './system-true-up-detail';
import { SystemTrueUpDetailDataSource } from './system-true-up-detail-datasource';
import { TrueUpHttpService } from '../../true-up-http.service';
import { AbstractJsonConverter } from '../../core/json-converters/abstract-json-convertert';
import { MonthlyTrueUpCalculationServiceBase } from '../monthly-calculations/monthly-true-up-calculation-service-base';
import { MonthlyTrueUpCalculationFilterRepresentation } from '../monthly-calculations/monthly-true-up-calculation-filter-representation-base';
import { TrueUpDetailJsonConverter } from './../true-up-detail-json-converter';
import { SystemTrueUpDetailJsonConverter } from './system-true-up-detail-json-converter';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';

@Injectable()
export class SystemTrueUpDetailService extends MonthlyTrueUpCalculationServiceBase<SystemTrueUpDetail>  {
  getAllItems(orderByColumn: string, isAscending: boolean, skip: number, top: number, filter: MonthlyTrueUpCalculationFilterRepresentation): Observable<PaginatedGetResult<SystemTrueUpDetail>> {
    return super.getAll(orderByColumn, isAscending, skip, top, filter);
  }

  constructor(trueUpDataHttpService: ReportingHttpService, errorHandler: ErrorHandler, trueUpDetailJsonConverter: SystemTrueUpDetailJsonConverter, private systemFileDownloadService: FileDownloadService) {
    super(trueUpDataHttpService,errorHandler,trueUpDetailJsonConverter, systemFileDownloadService);
  }

  GetEndpoint(): string {
    return '/SystemMonthlyTrueUpCalculations';
  }


  GetReportType(): string {
    return 'SYSTEM_TRUEUP';
  }

  AddNodeSpecificQueryStringParts(queryString: string, filter: MonthlyTrueUpCalculationFilterRepresentation): string {
    var systemFilter = filter as MonthlyTrueUpCalculationFilterRepresentation;
    return QueryStringBuilder.PrependFilterQueryStringVariableName(queryString);
  }
}



