import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { ClosureFilterRepresentation } from './closure-filter-representation';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { ClosureReportingService } from '../../services/closure-reporting.service';
import { FileDownloadService } from '../../../core/file-download/file-download.service';
import { FilterOperator } from '../../../core/query-strings/query-string-builder';
import { ZoneService } from '../../../inventory-management/zones/zones.service';
import { Zone } from '../../../inventory-management/zones/zone';
import { Subscription } from 'rxjs';
import { AreaService } from '../../../inventory-management/areas/areas.service';
import {Area} from '../../../inventory-management/areas/area';

@Component({
  selector: 'app-closure-configurator',
  templateUrl: './closure-configurator.component.html',
  styleUrls: ['./closure-configurator.component.css']
})
export class ClosureConfiguratorComponent implements OnInit, OnDestroy {

  constructor(private startTimeService: SystemStartTimeService,
    private reportService: ClosureReportingService,
    private fileDownloader: FileDownloadService,
    private errorHandler: ErrorHandler,
    private zoneService: ZoneService,
    private areaService: AreaService) {
    this.closureFilter = new ClosureFilterRepresentation();
  }

  closureFilter: ClosureFilterRepresentation;
  reportingQuarters: number[] = [1, 2, 3, 4];
  reportingYears: number[] = [];
  reportLoading: boolean;
  idFilterDropDown = [FilterOperator.Equal, FilterOperator.Contains];
  numberFilterDropDown = [FilterOperator.Equal, FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual];
  zoneFilterDropDown = [];
  areaFilterDropDown = [];
  subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.startTimeService.getInitialSystemStartTime().subscribe(result => {
      for (let i = result.getFullYear(); i <= new Date().getFullYear(); i++) {
        this.reportingYears.push(i);
      }

      this.reportingYears = this.reportingYears.reverse();

      this.setDefaultDates();
    },
      error => {
      console.log('error');
      this.errorHandler.handleError(error);
    });

    this.zoneService.getAllZones().subscribe(result => {
      if (result) {
        this.zoneFilterDropDown = result.sort((a: Zone, b: Zone) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);
      }
    });

    this.areaService.getAllAreas().subscribe(result => {
      if (result) {
        this.areaFilterDropDown = result.sort((a: Area, b: Area) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);
      }
    });

    this.subscriptions.push(this.reportService.isLoading
      .subscribe(response => {
        if (response.reportType === ClosureReportingService.CLOSURE_KEY) {
          this.reportLoading = response.loading;
        }
      }));
  }

  setDefaultDates() {
    const today = new Date();

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // remember months are 0 based
    if (currentMonth < 2) {
      this.closureFilter.FiscalYear = currentYear - 1;
    }
    else {
      this.closureFilter.FiscalYear = currentYear;
    }

    // march through may
    if (currentMonth >= 2 && currentMonth < 5) {
      this.closureFilter.FiscalQuarter = 1;
    } // june through august
    else if (currentMonth >= 5 && currentMonth < 8) {
      this.closureFilter.FiscalQuarter = 2;
    } // september through november
    else if (currentMonth >= 8 && currentMonth < 11) {
      this.closureFilter.FiscalQuarter = 3;
    } // december through february
    else {
      this.closureFilter.FiscalQuarter = 4;
    }
  }

  downloadReport() {

    this.reportService.RequestQuarterlyClosureReport(this.closureFilter)
      .subscribe(result => {
          this.reportService.DownloadClosureReport(result.result);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  clearFilters() {
    this.closureFilter = new ClosureFilterRepresentation();
    this.setDefaultDates();
  }

}
