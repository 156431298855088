export class RateStep {
  containsInvalidTimeline: boolean;

  containsNullValues: boolean;

  zeroDaysSelected: boolean;

  monday: boolean;

  tuesday: boolean;

  wednesday: boolean;

  thursday: boolean;

  friday: boolean;

  saturday: boolean;

  sunday: boolean;

  periodOfStay: number;

  periodOfStayInMinutes: number;

  restrictionType: string;

  rate: number;

  isSeasonal: boolean;

  isSeasonalHoliday: boolean;

  selectAll: boolean;

  startTime: any;

  stopTime: any;

  seasonalMonthStart: number;

  seasonalMonthEnd: number;

  seasonalDayStart: number;

  seasonalDayEnd: number;

  seasonalHolidayStart: string;

  seasonalHolidayEnd: string;
}
