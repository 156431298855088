exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".title {\r\n    height:30px;\r\n    font-weight:bold;\r\n    color:black;\r\n}\r\n\r\n.header {\r\n    min-height:25px;\r\n    height:25px;\r\n    font-weight:bold;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();