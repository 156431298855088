import { DateTimeUtility } from './../meter-true-up/models/month-name-dictionary';
import { Component, OnInit, Input, OnChanges, ErrorHandler } from '@angular/core';
import { WorkOrderHistoryComponent, ActionHistoryDictionary } from '../../work-order-history/work-order-history.component';
import { MeterWorkOrderHistory } from './meter-work-order-history';
import { Meter } from '../meter';
import { MatTableDataSource } from '@angular/material/table';
import { MeterService } from '../meters.service';
import { WorkOrdersService } from '../../../work-orders/services/work-orders.service';
import { Router } from '@angular/router';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { WorkOrderFilterRepresentation } from '../../../work-orders/work-order-filter/work-order-filter-representation';

@Component({
  selector: 'app-meter-work-order-history',
  templateUrl: './meter-work-order-history.component.html',
  styleUrls: ['./meter-work-order-history.component.css']
})
export class MeterWorkOrderHistoryComponent extends WorkOrderHistoryComponent<MeterWorkOrderHistory> implements OnInit, OnChanges {

  @Input() meter: Meter;
  @Input() workOrderFilter: WorkOrderFilterRepresentation;
  workOrderHistories: MeterWorkOrderHistory[] = [];
  dataSource = new MatTableDataSource<ActionHistoryDictionary>();
  systemStartDate: Date;

  constructor(private _meterService: MeterService, _workOrderService: WorkOrdersService, router: Router,
    private systemStartTimeService: SystemStartTimeService, private _errorHandler: ErrorHandler) {
    super(_workOrderService, router);
   }

  ngOnInit(): void {
    this.systemStartTimeService.getInitialSystemStartTime().subscribe(
      result => {
        this.systemStartDate = result;
        this.refreshDataSource();
      },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
      }
    );

  }

  ngOnChanges() {
    if (this.meter) {
      const workOrderActionIds = new Array<string>();
      this._meterService.getMeterWorkOrderHistories(this.meter.id)
      .subscribe(result => {
        result.forEach(history => {
          if (history.effectiveTimeStamp) {
            if (DateTimeUtility.compareDate(this.systemStartDate, history.effectiveTimeStamp)) {
              history.workOrderName = 'Intial Value';
            }
          }

          if (history.workOrderActionId) {
            workOrderActionIds.push(history.workOrderActionId);
          }

          const nonUtcDate = new Date(history.effectiveTimeStamp);
          history.effectiveDate = new Date(nonUtcDate.getFullYear(), nonUtcDate.getUTCMonth(), nonUtcDate.getUTCDate());
          this.workOrderHistories.push(history);
        });


        if (workOrderActionIds.length > 0) {
          this.getWorkOrderActionIds(workOrderActionIds);
        }
        this.workOrderHistories.forEach(workOrderHistory => {
          var actionHistory = this.actionHistoryDictionaries.filter(actionHistory => actionHistory.actionId === workOrderHistory.workOrderActionId);
          if(workOrderHistory.changeType.toLowerCase() !== 'location type') {
            if(actionHistory.length > 0) {
              actionHistory[0].histories.push(workOrderHistory);
            }
            else {
              this.actionHistoryDictionaries.push({ actionId: workOrderHistory.workOrderActionId, histories: [workOrderHistory] });
            }
          }
      });
        this.UpdateDataSource();
      });
    }
  }

  UpdateDataSource() {
    this.dataSource = new MatTableDataSource<ActionHistoryDictionary>(this.actionHistoryDictionaries);
    this.refreshDataSource();
  }
}
