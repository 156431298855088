import { WorkOrder } from './../../../models/work-order';
import { ValidatorFn, FormControl, Validators } from '@angular/forms';
import { Observable ,  Subject } from 'rxjs';

import { ActionField } from '../../../models/action-field';
import { BaseActionForm } from './base-action-form';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { InventoryFilterRepresentation } from '../../../../inventory-management/inventory-filter/inventory-filter-representation';
import { FilterOperator } from '../../../../core/query-strings/query-string-builder';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { InstallSpaceConstants } from '../../../action-field/action-field-constants';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { Meter } from '../../../../inventory-management/meters/meter';

export class ActionFormWithValidation extends BaseActionForm {

  constructor(actionIndex: string, actionFields: ActionField[], tokenCache: TokenCache, _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, hasHistoricalDate: boolean, private overrideAsCity: boolean, workOrder: WorkOrder,
    historicalDate?: Date, previousActivationEffectiveDate?: Date) {
    super(actionIndex, actionFields, tokenCache, hasHistoricalDate, historicalDate, previousActivationEffectiveDate);
    this._meterService = _meterService;
    this._blockService = _blockService;
    this._spaceService = _spaceService;
    this.workOrder = workOrder;
  }

  _meterService: MeterService;
  _blockService: BlockService;
  _spaceService: SpaceService;
  tokenCache: TokenCache;
  workOrderActionJsonObject: any;
  workOrder: WorkOrder;

  //So that forms that extend this form can perform actions after the base meter attributes are updated
  onSelectedMeterUpdated: Subject<Meter> = new Subject<Meter>();

  // Make a second level form
  addControl(controlId: number, validators: ValidatorFn[]): void {
    if (!this.controlExists(controlId)) {
      this.formGroup.addControl(this.actionIndex + controlId.toString(), new FormControl(null, validators));
    }
  }

  addGuidControl(guid: string): void {
    this.formGroup.addControl(this.actionIndex + guid, new FormControl(''));
  }

  controlIsValid(controlId: number): boolean {
    return this.formGroup.get(this.actionIndex + controlId.toString()).valid;
  }

  getGuidControlValue(guid: string): any {
    if (this.guidControlExists(guid)) {
      return this.formGroup.get(this.actionIndex + guid).value;
    }
  }

  getControlValue(controlId: number): any {
    if (this.controlExists(controlId)) {
      return this.formGroup.get(this.actionIndex + controlId.toString()).value;
    }
  }

  controlExists(controlId: number): boolean {
    return this.formGroup.get(this.actionIndex + controlId.toString()) !== null;
  }

  guidControlExists(guid: string): boolean {
    return this.formGroup.get(this.actionIndex + guid) !== null;
  }

  removeGuidControl(guid: string): void {
    if (this.guidControlExists(guid)) {
      this.formGroup.removeControl(this.actionIndex + guid);
    }
  }

  disableControl(controlId: number): void {
    if (this.controlExists(controlId)) {
      this.formGroup.get(this.actionIndex + controlId.toString()).disable();
    }
  }

  enableControl(controlId: number): void {
    if (this.controlExists(controlId)) {
      this.formGroup.get(this.actionIndex + controlId.toString()).enable();
    }
  }

  removeControl(controlId: number): void {
    if (this.controlExists(controlId)) {
      this.formGroup.removeControl(this.actionIndex + controlId.toString());
    }
  }

  pushToWarnings(warning: string): void {
    this.warnings.push(warning);
  }

  removeFromWarnings(warning: string): void {
    this.warnings.splice(this.warnings.indexOf(warning), 1);
  }

  setAsyncValidator(controlId: number, asyncValidator: ValidatorFn): void {
    if (this.controlExists(controlId)) {
      this.formGroup.get(this.actionIndex + controlId.toString()).setAsyncValidators(asyncValidator.bind(this));
    }
  }

  setGuidValidator(controlId: string, asyncValidator: ValidatorFn): void {
    this.formGroup.get(this.actionIndex + controlId).setAsyncValidators(asyncValidator.bind(this));
  }

  setMeterSelectIdBasedOnGuid(meterSelectFieldId: number): void {
    this._meterService.getMeterById(this.workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid], this.historicalEffectiveDate).subscribe(result => {
      this.setValue(meterSelectFieldId, result.displayId);
      this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
      this.setGuidValue(WorkorderActionJsonConstants.blockGuid, result.blockId);
      this.addTotalNumberOfSpacesControl();
      this.addNumberOfActiveSpacesControl();
      this.setNumberOfActiveSpacesControl(result.activeSpaces);
      this._spaceService.getSpacesByMeterId(result.id).subscribe(spaces => {
        this.setTotalNumberOfSpacesControl(spaces.length);
      });
    });
  }

  setMeterTextField(meterIdTextId: number, meterStreetNumberId: number, contractTypeId?: number): void {
    if (this.workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid]) {
      this._meterService.getMeterById(this.workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid], this.historicalEffectiveDate).subscribe(result => {
        this.updateInventoryIdsOnWorkOrder(result);
        this.setValue(meterIdTextId, result.displayId);

        var meterStreetText = '';

        if(result.location.number){
          meterStreetText = result.location.number.toString();
        }

        if(result.location.streetDirection){
          meterStreetText = meterStreetText + " " + result.location.streetDirection;
        }

        if(result.location.streetName){
          meterStreetText = meterStreetText + " " + result.location.streetName;
        }

        if(result.location.streetSuffix){
          meterStreetText = meterStreetText + " " + result.location.streetSuffix;
        }

        this.setValue(meterStreetNumberId, meterStreetText);
        this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
        this.setGuidValue(WorkorderActionJsonConstants.blockGuid, result.blockId);
        this.addTotalNumberOfSpacesControl();
        this.addNumberOfActiveSpacesControl();
        this.setNumberOfActiveSpacesControl(result.activeSpaces);
        this._spaceService.getSpacesByMeterId(result.id).subscribe(spaces => {
          this.setTotalNumberOfSpacesControl(spaces.length);
        });
        if (contractTypeId) {
          this.setValue(contractTypeId, result.currentContractType);
        }
      });
    }
  }

  setHistoricalMeterGuid(meterDisplayId: string, value: string): void {
    if (this.workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid]) {
      this.setGuidValue(WorkorderActionJsonConstants.meterGuid, value);
    }
  }

  setMeterGuidBasedOnDisplayId(meterDisplayId: string, meterGuidPropertyName: string, date: Date = null): void {
    this.addGuidControl(meterGuidPropertyName);
    this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
    this.addTotalNumberOfSpacesControl();
    this.addNumberOfActiveSpacesControl();

    this._meterService.getMeterByDisplayId(meterDisplayId, date).subscribe(result => {
      if (result) {
        this.updateInventoryIdsOnWorkOrder(result);
        this.setNumberOfActiveSpacesControl(result.activeSpaces);
        this._spaceService.getTotalNumberOfSpaces(result.id, date).subscribe(spacesResult => {
          this.setTotalNumberOfSpacesControl(spacesResult.totalNumberOfSpaces);
        });
        this.setGuidValue(meterGuidPropertyName, result.id);
        this.setGuidValue(WorkorderActionJsonConstants.blockGuid, result.blockId);
      }

      this.onSelectedMeterUpdated.next(result);
    });
  }

  updateInventoryIdsOnWorkOrder(meter) {
    if (!this.workOrder.zoneDisplayIds || !this.workOrder.zoneDisplayIds.includes(meter.zoneName)) {
      this.workOrder.zoneDisplayIds ?  this.workOrder.zoneDisplayIds.push(meter.zoneName) : this.workOrder.zoneDisplayIds = [meter.zoneName];
    }
    if (!this.workOrder.areaDisplayIds || !this.workOrder.areaDisplayIds.includes(meter.areaName)) {
      this.workOrder.areaDisplayIds ?  this.workOrder.areaDisplayIds.push(meter.areaName) : this.workOrder.areaDisplayIds = [meter.areaName];
    }
    if (!this.workOrder.blockDisplayIds || !this.workOrder.blockDisplayIds.includes(meter.blockDisplayId)) {
      this.workOrder.blockDisplayIds ?  this.workOrder.blockDisplayIds.push(meter.blockDisplayId) : this.workOrder.blockDisplayIds = [meter.blockDisplayId];
    }
    if (!this.workOrder.meterDisplayIds || !this.workOrder.meterDisplayIds.includes(meter.displayId)) {
      this.workOrder.meterDisplayIds ?  this.workOrder.meterDisplayIds.push(meter.displayId) : this.workOrder.meterDisplayIds = [meter.displayId];
    }
  }

  addTotalNumberOfSpacesControl(): void {
    this.formGroup.addControl(this.actionIndex + WorkorderActionJsonConstants.totalNumberOfSpacesOnMeter, new FormControl(''));
  }

  setTotalNumberOfSpacesControl(numberOfSpaces: number): void {
    this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.totalNumberOfSpacesOnMeter).setValue(numberOfSpaces);
  }

  addNumberOfActiveSpacesControl(): void {
    this.formGroup.addControl(this.actionIndex + WorkorderActionJsonConstants.numberOfActiveSpacesOnMeter, new FormControl(''));
  }

  setNumberOfActiveSpacesControl(numberOfSpaces: number): void {
    this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.numberOfActiveSpacesOnMeter).setValue(numberOfSpaces);
  }

  addTotalNumberOfSpacesOnToMeterControl(): void {
    this.formGroup.addControl(this.actionIndex + WorkorderActionJsonConstants.totalNumberOfSpacesOnToMeter, new FormControl(''));
  }

  setTotalNumberOfSpacesOnToMeter(numberOfSpaces: number): void {
    this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.totalNumberOfSpacesOnToMeter).setValue(numberOfSpaces);
  }

  addTotalNumberOfSpacesOnFromMeterControl(): void {
    this.formGroup.addControl(this.actionIndex + WorkorderActionJsonConstants.totalNumberOfSpacesOnFromMeter, new FormControl(''));
  }

  setTotalNumberOfSpacesOnFromMeter(numberOfSpaces: number): void {
    this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.totalNumberOfSpacesOnFromMeter).setValue(numberOfSpaces);
  }

  setValidators(controlId: number, validators: ValidatorFn[]): void {
    if (this.controlExists(controlId)) {
      this.formGroup.get(this.actionIndex + controlId.toString()).setValidators(validators);
    }
  }

  setAsRequired(controlId: number): void {
    if (this.controlExists(controlId)) {
      this.formGroup.get(this.actionIndex + controlId.toString()).setValidators(Validators.required);
    }
  }

  setAsNotRequired(controlId: number): void {
    if (this.controlExists(controlId)) {
      this.formGroup.get(this.actionIndex + controlId.toString()).clearValidators();
    }
  }

  setValue(controlId: number, value: any): void {
    if (!this.controlExists(controlId)) {
      this.addControl(controlId, []);
    }
      this.formGroup.get(this.actionIndex + controlId.toString()).setValue(value);
  }

  setGuidValue(guid: string, value: any): void {
    this.formGroup.get(this.actionIndex + guid).setValue(value);
  }

  subscribeToControl(controlId: number): Observable<any> {
    if (this.controlExists(controlId)) {
      return this.formGroup.get(this.actionIndex + controlId.toString()).valueChanges;
    }
  }

  subscribeToGuidControl(controlId: string): Observable<any> {
    if (this.guidControlExists(controlId)) {
      return this.formGroup.get(this.actionIndex + controlId).valueChanges;
    }
  }

  updateValueAndValidity(controlId: number): void {
    this.formGroup.get(this.actionIndex + controlId.toString()).updateValueAndValidity();
  }

  isCityUser(): boolean {
    if (this.overrideAsCity) {
        return true;
    }
    this.tokenCache = new TokenCache();
    return this.tokenCache.checkForCityUser();
  }

  isLazUser(): boolean {
    this.tokenCache = new TokenCache();
    return this.tokenCache.checkForLAZUser();
  }

  isLazOrCPMUser(): boolean {
    this.tokenCache = new TokenCache();
    return this.tokenCache.checkForLAZUser() || this.tokenCache.checkForCPMUser();
  }
}
