import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';

import { InventoryHttpService } from '../../../inventory-http.service';
import { RatePackage, RatePackageFUAsListKeyValuePair } from '../models/rate-package';
import { RatePackageJsonConverter } from './rate-package-json-converter';
import { TrueUpHttpService } from './../../../true-up-http.service';
import { StringExtensions } from '../../../string-extensions';
import { QueryStringBuilder } from './../../../core/query-strings/query-string-builder';
import { RatePackageFilterRepresentation } from './../rate-package-list/rate-package-filter-representation';

@Injectable()
export class RatePackageService {

    constructor(private http: HttpClient, private trueUpHttpService: TrueUpHttpService, private _inventoryHttpService: InventoryHttpService) { }

    public getRatePackageById(id: string): Observable<RatePackage> {
      return this.http.get('/ratePackage/' + id)
        .pipe(
          map(response => response != null ? RatePackageJsonConverter.jsonToRatePackage(response) : null),
          catchError((error) => this.handleError(error))
        );
    }

      public getRatePackageDescription(name: string): Observable<string> {
      return this.http.get('/ratePackage/' + name + '/description', {responseType: 'text'})
        .pipe(catchError((error) => this.handleError(error)));
    }

    public postNewRatePackage(ratePackage: RatePackage) {
      return this.http.post('/ratePackage', ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public updateRatePackageIsInactive(ratePackageId: string, isInactivated: boolean) {
      return this.http.put('/ratePackage/toggleIsInactivated', { id: ratePackageId, isInactivated: isInactivated })
        .pipe(catchError((error) => Observable.throwError(error)));
    }

    public getAllRatePackages(filter: RatePackageFilterRepresentation = null): Observable<any> {
      const filterQueryString = filter != null ? this.createFilterQueryString(filter) : '';
      const endpoint = filterQueryString !== '' ? `/ratePackage?${filterQueryString}` : '/ratePackage/';

      return this.http.get(endpoint)
        .pipe(
          map(response => response != null ? RatePackageJsonConverter.jsonToRatePackageList(response) : null),
          catchError((error) => this.handleError(error))
        );
    }

    public getAllRatePackageNames(filter: RatePackageFilterRepresentation = null): Observable<any> {
      const filterQueryString = filter != null ? this.createFilterQueryString(filter) : '';
      const endpoint = filterQueryString !== '' ? `/ratePackage/names?${filterQueryString}` : '/ratePackage/names/';

      return this.http.get(endpoint)
        .pipe(
          map(response => response != null ? response : []),
          catchError((error) => (this.handleError(error)))
        );
    }

    private handleError(error: any): Promise<any> {
      console.error('An error occurred', error);
      return Promise.reject(error.message || error);
    }

    public getWeeklyUtilization(ratePackage: any) {
      return this.trueUpHttpService.post('/utilization/weekly/', ratePackage)
        .pipe(
          map(response => response != null ? RatePackageJsonConverter.jsonToWeeklyFUA(response) : 0),
          catchError((error) => this.handleError(error))
        );
    }

    public getMonthlyUtilization(ratePackage: any, year: number, month: number) {
      return this.trueUpHttpService.post('/utilization/monthly/' + year + '/' + month + '/', ratePackage)
        .pipe(
          map(response => response != null ? response.utilizationAmount : 0),
          catchError((error) => this.handleError(error))
        );
    }

    public getMonthlyUtilizationsForYear(ratePackage: any, fiscalYear: number) {
      return this.trueUpHttpService.post('/utilization/monthly/' + fiscalYear + '/', ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getWeeklyPeriodOfOperation(ratePackage: any) {
      return this.trueUpHttpService.post('/utilization/periodofoperation/weekly/', ratePackage)
        .pipe(
          map(response => response != null ? response.periodOfOperation : 0),
          catchError((error) => this.handleError(error))
        );
    }

    public getDailyBreakdown(ratePackage: any) {
      return this.trueUpHttpService.post('/utilization/dailybreakdown', ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getMonthlyPeriodOfOperation(ratePackage: any, year: number, month: number) {
      return this.trueUpHttpService.post('/utilization/periodofoperation/monthly/' + year + '/' + month + '/', ratePackage)
        .pipe(
          map(response => response != null ? response.periodOfOperation : 0),
          catchError((error) => this.handleError(error))
        );
    }

  public getYearlyPeriodOfOperation(ratePackage: any, fiscalYear: number) {
      return this.trueUpHttpService.post('/utilization/periodofoperation/yearly/' + fiscalYear, ratePackage)
        .pipe(
          map(response => response != null ? response.periodOfOperation : 0),
          catchError((error) => this.handleError(error))
        );
    }

    public getThreeYearFUA(ratePackageToUpdate: any[], currentFiscalYear: number) {
      return this.trueUpHttpService.post('/Utilization/Yearly/PastThree/' + currentFiscalYear, ratePackageToUpdate)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getYearlyFUA(ratePackage: any, fiscalYear: number) {
      return this.trueUpHttpService.post('/Utilization/Yearly/' + fiscalYear, ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getSeasonalRatePackage(ratePackage: RatePackage): Observable<RatePackage> {
      return this._inventoryHttpService.post('/ratePackage/seasonal', ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getSeasonalRatePackageWithYear(ratePackage: RatePackage, fiscalYear: number):Observable<RatePackage> {
      return this._inventoryHttpService.post('/ratePackage/seasonal/' + fiscalYear, ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public validateSeasonalRatePackage(ratePackage: any) {
      return this._inventoryHttpService.post('/ratePackage/seasonal/validate', ratePackage)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public getAdditionalInformationForRatePackageList(id: string): Observable<any> {
      return this._inventoryHttpService.get('/ratePackage/list/' + id, null)
        .pipe(
          map(response => response != null ? RatePackageJsonConverter.jsonToRatePackage(response) : null),
          catchError((error) => this.handleError(error))
        );
  }

    public getThreeYearFUAForListOfRatePackages(ratePackages: Object[], currentFiscalYear: number): Observable<RatePackageFUAsListKeyValuePair[][]> {
      return this.trueUpHttpService.post('/Utilization/Yearly/PastThree/listOfRatePackages/' + currentFiscalYear, ratePackages)
        .pipe(catchError((error) => this.handleError(error)));
    }

    public exportRatePackages(filter: RatePackageFilterRepresentation): Observable<any> {
      const filterQueryString = filter != null ? this.createFilterQueryString(filter) : '';
      const endpoint = filterQueryString !== '' ? `/ratePackage/export?${filterQueryString}` : '/ratePackage/export';

      return this._inventoryHttpService.getFile(endpoint, null)
        .pipe(catchError((error) => this.handleError(error)));
    }

    createFilterQueryString(evt: RatePackageFilterRepresentation): string {

        let filterQueryString = '';

        if (evt.FilterByRatePackageName) {

            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'name',
                evt.ratePackageNameFilterOperator, evt.ratePackageName, null);
        }
        if (evt.FilterByRatePerHour) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'rate',
                evt.ratePerHourFilterOperator, evt.ratePerHour, evt.ratePerHourEnd);
        }
        if (evt.FilterByperiodOfStay) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'periodOfStay',
                evt.periodOfStayFilterOperator, evt.periodOfStayInMinutes, null);
        }

        if (evt.FilterByHoursOfOperationName) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'hoOName',  evt.hoursOfOperationName);
        }

        if (evt.FilterByHoursOfRestrictionName) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'hoRName',  evt.hoursOfRestrictionName);
        }

        if (evt.FilterByIsInactivated) {
            filterQueryString = QueryStringBuilder.AddEquals(filterQueryString, 'isInactivated',  evt.isInactivated ? 'true' : 'false');
        }

        if (StringExtensions.StringIsNotEmpty(filterQueryString)) {
            return QueryStringBuilder.PrependFilterQueryStringVariableName(filterQueryString);
        }

        return '';
    }
}
