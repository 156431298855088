exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".select-button {\r\n    height:65%;\r\n    width:65%;\r\n    color: white;\r\n    background-color: #607d8b;\r\n    font-size:115%;\r\n}\r\n\r\n.mat-radio-label {\r\n    max-width:100% !important;\r\n    width:100% !important;\r\n}\r\n\r\n.row {\r\n    min-height:25px !important;\r\n    max-height:25px !important;\r\n    height:25px !important;\r\n}\r\n\r\n.id-error {\r\n    font-style: italic;\r\n    color: red;\r\n    padding-left:5%;\r\n}\r\n\r\n[hidden] {\r\n    display: none !important;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();