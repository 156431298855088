import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { WorkOrderWarning } from './../../models/work-order-warning';

@Component({
    selector: 'app-work-order-create-confirmation-dialog',
    templateUrl: './work-order-create-confirmation.html',
    styleUrls: ['./work-order-create-confirmation.css']
})
export class WorkOrderCreateConfirmationComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<WorkOrderCreateConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    warnings = new Array<WorkOrderWarning>();
    multipleActionWarnings = new Array<WorkOrderWarning>();
    relevantWorkOrders = [];
    isHistoricalChange: boolean;
    disableOkButton = false;

   ngOnInit() {
     this.warnings = this.data.warnings;
     this.multipleActionWarnings = this.data.multipleActionWarnings;
     this.disableOkButton = this.data.disableOkButton;
     this.isHistoricalChange = this.data.isHistoricalChangeConfirmation;
     this.relevantWorkOrders = this.data.workOrderDisplayIds;
   }

   openRelatedWorkOrder(workOrderId: string): void {
    window.open('/workorders/' + workOrderId, 'noopener');
   }
}
