import {Component, ErrorHandler, Inject, OnInit} from '@angular/core';
import {SpaceService} from '../spaces.service';
import {MeterService} from '../../meters/meters.service';
import {SystemStartTimeService} from '../../../system-start-time.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClosureLinkService } from '../../../core/services/closure-link.service';

@Component({
  selector: 'app-space-closure-details',
  templateUrl: './space-closure-detail.component.html',
  styleUrls: ['./space-closure-detail.component.css']
})
export class SpaceClosureDetailComponent implements OnInit {

  closureWarehouseRecordId: string = null;
  spaceClosureDetail: SpaceClosureDetailModel;
  loading = false;
  errorMessage: string = null;

  constructor(private _spaceService: SpaceService, private _errorHandler: ErrorHandler,
              private _closureLinkService: ClosureLinkService,
              public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.closureWarehouseRecordId = this.data.closureWarehouseRecordId;
    this.loadClosureDetails();
  }

  loadClosureDetails() {
    this.loading = true;
    this.errorMessage = null;

    this._spaceService.getClosureByWarehouseId(this.closureWarehouseRecordId)
      .subscribe(result => {
          this.spaceClosureDetail = result;
        },
        error => {
          this._errorHandler.handleError(error);
          console.log(error);
        });

    this.loading = false;
  }

  launchClosureSite(){
    if(this.spaceClosureDetail){
      this._closureLinkService.openClosureAppInNewTab(this.closureWarehouseRecordId);
    }
  }
}

export class SpaceClosureDetailModel {
  closureWarehouseId: string;
  closureWarehouseRecordId: string;
  earliestClosureDate: Date;
  latestClosureDate: Date;
  earliestClosureTime: string;
  latestClosureTime: string;
  continuousInterval: string;
  closureDays: string;
}


