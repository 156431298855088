import { FineAmountService } from './../../../inventory-management/fine-amounts/fine-amounts.service';
import { ActionConstants } from './../../action/action-constants';
import { UpdateMeterAttributesConstants } from './../../action-field/action-field-constants';
import { ActionFieldService } from './../../services/action-field.service';
import { WorkOrder } from './../../models/work-order';
import { ActionField } from './../../models/action-field';
import { WorkOrderActionWithActionFields, LetterOfDirection } from './../../models/letter-of-direction';
import { Component, OnChanges, Input } from '@angular/core';
import { WorkOrderAction } from '../../models/work-order-action';
import { FieldConstants } from '../../action-field/field-constants';
import { DueDateConstants } from '../../models/due-date-constants';
import { TokenCache } from '../../../security/token-cache';
import { RoleOptionsConstants } from '../../../environment';
import { RatePackageService } from '../../../inventory-management/rate-packages/services/rate-package.service';


@Component({
  selector: 'app-letter-of-direction',
  templateUrl: './letter-of-direction.component.html',
  styleUrls: ['./letter-of-direction.component.css']
})
export class LetterOfDirectionComponent implements OnChanges {

  constructor(private _actionFieldService: ActionFieldService, private _ratePackageService: RatePackageService, private _tokenCache: TokenCache, private _fineAmountService: FineAmountService) { }

  @Input() workOrder: WorkOrder;
  @Input() workOrderAction: WorkOrderAction;
  @Input() context: string;
  @Input() letterOfDirection: LetterOfDirection;

  workOrderActionWithFields: WorkOrderActionWithActionFields;
  workOrderActionJsonObject: any;
  actionFieldsWithValues: any;
  dueDateConstants = DueDateConstants;
  userIsCity: boolean;
  ratePackageDescriptions: any = {};

  ConcessionaireName = RoleOptionsConstants.ConcessionaireName.value;

  ngOnChanges() {

    this.userIsCity = this._tokenCache.checkForCityUser();
    if (this.workOrderAction.workOrderActionJson) {
      this.workOrderActionJsonObject = JSON.parse(this.workOrderAction.workOrderActionJson);
      this._actionFieldService.getAllReadOnlyActionFields(this.workOrderAction.actionId, this.letterOfDirection.authorUserRole)
      .subscribe(result => {
        this.workOrderActionWithFields = new WorkOrderActionWithActionFields(this.workOrderAction, result, this.workOrderActionJsonObject, this.workOrderAction.summationSentence);
        this.actionFieldsWithValues = this.workOrderActionWithFields.actionFields.filter(x => this.workOrderActionJsonObject[x.id] !== 'false' && this.workOrderActionJsonObject[x.id] !== null
          && this.workOrderActionJsonObject[x.id] !== undefined && this.workOrderActionJsonObject[x.id] !== '');
        let meterTextHasValue;
        let meterSelectHasValue;
        this.actionFieldsWithValues.forEach(actionField => {
          if (actionField.fieldId === FieldConstants.MeterIDId && this.workOrderActionJsonObject[actionField.id] !== null && this.workOrderActionJsonObject[actionField.id] !== undefined) {
            meterTextHasValue = true;
          }
          else if (actionField.fieldId === FieldConstants.MeterSelectId && this.workOrderActionJsonObject[actionField.id] !== null && this.workOrderActionJsonObject[actionField.id] !== undefined) {
            meterSelectHasValue = true;
          }

          //load rate package descriptions
          if(this.actionIsRatePackageName(actionField) && this.workOrderActionJsonObject[actionField.id] !== null && this.workOrderActionJsonObject[actionField.id] !== undefined){
            this.getRatePackageDescription(this.workOrderActionJsonObject[actionField.id], actionField.id);
          }
        });
        if (meterTextHasValue && meterSelectHasValue) {
          this.actionFieldsWithValues = this.actionFieldsWithValues.filter(x => x.fieldId !== FieldConstants.MeterIDId);
        }
        this.actionFieldsWithValues = this.sort(this.actionFieldsWithValues);

        const fineAmountId = this.workOrderActionJsonObject[UpdateMeterAttributesConstants.FineAmount];
        if (this.workOrderAction.actionId === ActionConstants.UpdateMeterAttributesId && fineAmountId) {
            this._fineAmountService.getAllFineAmounts()
            .subscribe(result => {
              const fineAmount = result.find(fineAmount => fineAmountId === fineAmount.id);
              if (fineAmount) {
                this.workOrderActionJsonObject[UpdateMeterAttributesConstants.FineAmount] = '$' + fineAmount.amount;
              }
            });
        }

      },
      error => {
        console.log(error);
      });
    }
  }

  sort(resultArray) {
    return resultArray.sort((a, b) => {
      if (a.inputRow < b.inputRow) { return -1; }
      else if (a.inputRow > b.inputRow) { return 1; }
      else { return 0; }
    });
  }

  actionValueIsDate(actionField: ActionField) {
    return actionField.fieldName.toLowerCase().includes('date') && !actionField.fieldName.toLowerCase().includes('update');
  }

  actionValueIsBool(value) {
    return value === 'true' || value === 'false';
  }

  actionFieldTypeIsPercentage(actionField: ActionField) {
    return actionField.fieldType.toLowerCase() === 'percentage';
  }

  actionValueIsNotBoolDateOrPercentage(actionField: ActionField) {
    if (this.workOrderActionJsonObject[actionField.id] === 'true' || this.workOrderActionJsonObject[actionField.id] === 'false') { return false; }
    if (actionField.fieldName.toLowerCase().includes('date')) { return false; }
    if (actionField.fieldType.toLowerCase().includes('percentage')) { return false; }
    return true;
  }

  actionIsRatePackageName(actionField: ActionField) {
    return actionField.fieldName.toLowerCase().includes('rate package id');
  }

  getRatePackageDescription(ratePackageName: string, actionFieldId:number){
    this._ratePackageService.getRatePackageDescription(ratePackageName).subscribe(s => {
      this.ratePackageDescriptions[actionFieldId] = s;
    });
  }

}
