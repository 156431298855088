import { ReservedPowerColumns } from './../reserved-power-columns';
import { Component, ErrorHandler, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

import { ReservedPowerReportingService } from '../../services/reserved-power-reporting.service';
import { FileDownloadService } from '../../../core/file-download/file-download.service';

@Component({
  selector: 'app-meter-reserved-power',
  templateUrl: './meter-reserved-power.component.html',
  styleUrls: ['./meter-reserved-power.component.css']
})
export class MeterReservedPowerComponent implements OnInit, OnDestroy {

  reportLoading: boolean;
  tableLoading: boolean;
  subscriptions: Subscription[] = [];
  dataSource: MatTableDataSource<any>;

  displayedColumns = ReservedPowerColumns.MeterReservedPowerColumns;

  constructor(private _reportingService: ReservedPowerReportingService,
    private _fileDownloader: FileDownloadService,
    private _errorHandler: ErrorHandler ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(this._reportingService.isLoading.subscribe(response => {
      if (response.reportType === ReservedPowerReportingService.METER_KEY) {
        this.reportLoading = response.loading;
      }
      else if (response.reportType === ReservedPowerReportingService.METER_DATA_KEY) {
        this.tableLoading = response.loading;
        if (!this.tableLoading) {
          this.dataSource = new MatTableDataSource<any>(response.fileData);
          this.tableLoading = false;
        }
      }
    }));
  }

  meterWorkOrderRequested(event: Date) {
    this.reportLoading = true;
    this._reportingService.RequestMeterMonthlyReservedPowerReport(event)
      .subscribe(result => {
        this._reportingService.DownloadMeterReport(result.result);
      },
      error => {
        this._errorHandler.handleError(error);
      });
  }

  meterTableDataRequested(event: Date) {
    this.tableLoading = true;
    this._reportingService.RequestMeterMonthlyReservedPowerData(event)
      .subscribe(result => {
        this._reportingService.DownloadMeterReportData(result.result);
      },
      error => {
        this._errorHandler.handleError(error);
      });
  }


}
