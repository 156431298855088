import { environment } from '../../environment';
import { Component, OnInit, isDevMode } from '@angular/core';
import { LoginService } from './login.service';
import { TokenCache } from './../token-cache';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
    loading = false;
    username: string;
    userPassword: string;

    constructor(private loginService: LoginService, private router: Router, public snackBar: MatSnackBar, private TokenCache: TokenCache) { }

    login() {
      this.openSnackBar('Logging in....', '');

      this.loading = true;

      this.loginService.login(this.username, this.userPassword).subscribe(
        result => {
            this.TokenCache.setBearerToken(result, this.username);
            const route = this.loginService.redirectUrl || 'inventorymanagement/';
            this.router.navigate([route]);
            this.openSnackBar('Welcome ' + this.username + '!', '');
        },
        error => {
            this.openSnackBar('Invalid username or password', '');
            this.loading = false;
            this.userPassword = null;
            console.log(error);
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

}
