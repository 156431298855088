import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpBackend, HttpRequest, HttpParams, HttpResponse, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenCache } from './security/token-cache';
import { environment } from './environment';
import { HttpRequestOptions } from './core/http-request-options';

@Injectable()
export class InventoryHttpService extends HttpClient {

    headers: HttpHeaders;
    postOptions: { headers: HttpHeaders } ;

    constructor(backend: HttpBackend, options: HttpRequestOptions, private tokenCache: TokenCache) {
        super(backend);
        this.setHeaders();
        this.tokenCache.onTokenChange.subscribe(() => this.setHeaders());
    }

    public get(url: string, options: any): Observable<any> {
      options = options || new HttpRequestOptions();

      if (!options.headers) {
        options.headers = this.headers;
      }

      url = `${environment.API_BASE}${url}`;
      return super.get(url, options);
    }

    public getFile(url: string, options: any): Observable<Object> {
        // allow changing response type for file downloads
        var option;
        if (!options) {
            const newHeaders = this.headers;
            newHeaders.append('Accept', 'application/*');

            option = {headers: newHeaders, responseType: 'blob', observe: 'response' };
        }

        url = `${environment.API_BASE}${url}`;
        return super.get(url, option);
    }

    public post(url: string, body: any, options?: any): Observable<any> {
        url = `${environment.API_BASE}${url}`;
        return super.post(url, body, this.postOptions);
    }

    public login(url: string, body: any, options: any): Observable<ArrayBuffer> {
        if (!options) {
            let options = { headers: this.headers };
        }
        url = `${environment.LOGIN_API_BASE}/identity/oauth/${url}`;
        return super.post(url, body, options);
    }

    public getUsers(url: string, options: any): Observable<Object> {
        if(!options) {
            options = { headers: this.headers };
        }
        url = `${environment.LOGIN_API_BASE}/identity/api/accounts`;
        return super.get(url, options);
    }

    public getUsersByRole(url: string, role: string, options: any): Observable<Object> {
      if (!options) {
        options = { headers: this.headers };
      }
      url = `${environment.LOGIN_API_BASE}/identity/api/accounts/search/${role}`;
      return super.get(url, options);
    }

    public put(url: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = { headers: this.headers };
        }
        url = `${environment.API_BASE}${url}`;
        return super.put(url, body, options);
    }

    public delete(url: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = { headers: this.headers };
        }
        url = `${environment.API_BASE}${url}`;
        return super.post(url, body, options);
    }

    public nonPostDelete(url: string, options?: any): Observable<ArrayBuffer> {
      if (!options) {
          options = { headers: this.headers };
      }
      url = `${environment.API_BASE}${url}`;
      return super.delete(url, options);
  }

    private setHeaders() {
        this.headers = new HttpHeaders({
            'Authorization': this.tokenCache.getBearerToken()
        });

        let options = new HttpHeaders({
          'Authorization': this.tokenCache.getBearerToken(),
          'Content-Type': 'application/json'
        });

        this.postOptions = { headers: options };
    }
}
