import { QueryStringBuilder, FilterOperator } from '../../core/query-strings/query-string-builder';

export class InventoryFilterRepresentation {
    BlockId: string = undefined;
    
    get FilterByBlockId(): boolean {
        return this.BlockId !== undefined && this.BlockId !== '' && this.BlockId !== null;
    }
    BlockDisplayId: string = undefined;
    BlockDisplayIdFilterOperator: string = FilterOperator.Contains;
    get FilterByBlockDisplayId(): boolean {
        return this.BlockDisplayId !== undefined && this.BlockDisplayId !== '' && this.BlockDisplayId !== null;
    }
    MeterIdFilterOperator: string = FilterOperator.Contains;
    MeterId: string = undefined;
     get FilterByMeterId(): boolean {
         return this.MeterId !== undefined && this.MeterId !== null;
    }
    StreetName: string = undefined;
    StreetNameFilterOperator: string = FilterOperator.Contains;
    get FilterByStreetName(): boolean {
        return this.StreetName !== undefined && this.StreetName !== '' && this.StreetName !== null;
    }
    BlockRange: number = undefined;
    BlockRangeFilterOperator: string = FilterOperator.Between;
    BlockRangeEnd: number = undefined;
    get FilterByBlockRange(): boolean {
        return this.BlockRange !== undefined && this.BlockRange !== null;
    }
    Area: number = undefined;
    AreaFilterOperator: string = FilterOperator.Equal;
    AreaEnd: number = undefined;
    get FilterByArea(): boolean {
        return this.Area !== undefined && this.Area !== null;
    }
    Zone: string = undefined;
    ZoneFilterOperator: string = FilterOperator.Equal;
    ZoneEnd: string = undefined;
    get FilterByZone(): boolean {
        return this.Zone !== undefined && this.Zone !== '' && this.Zone !== null;
    }
    Ward: number = undefined;
    WardFilterOperator: string = FilterOperator.Equal;
    WardEnd: number = undefined;
    get FilterByWard(): boolean {
        return this.Ward !== undefined && this.Ward !== null;
    }
    MeterType: string = undefined;
    MeterTypeFilterOperator: string = FilterOperator.Equal;
    get FilterByMeterType(): boolean {
        return this.MeterType !== undefined && this.MeterType !== '' && this.MeterType !== null;
    }
    MeterStatus: string = undefined;
    MeterStatusFilterOperator: string = FilterOperator.Equal;
    get FilterByMeterStatus(): boolean {
        return this.MeterStatus !== undefined && this.MeterStatus !== '' && this.MeterStatus !== null;
    }
    ContractType: string = undefined;
    get FilterByContractStatus(): boolean {
        return this.ContractType !== undefined && this.ContractType !== '' && this.ContractType !== null;
    }
    ParkingType: string = undefined;
    ParkingTypeFilterOperator: string = FilterOperator.Equal;
    get FilterByParkingType(): boolean {
        return this.ParkingType !== undefined && this.ParkingType !== '' && this.ParkingType !== null;
    }
    ParentMeterId: number = undefined;
    get FilterByParentMeterId(): boolean {
        return this.ParentMeterId !== undefined && this.ParentMeterId !== null;
    }
    VirtualMeterId: number = undefined;
    VirtualMeterIdFilterOperator: string = FilterOperator.Equal;
    get FilterByVirtualMeterId(): boolean {
        return this.VirtualMeterId !== undefined && this.VirtualMeterId !== null;
    }
    SignOnlyMeterId: number = undefined;
    SignOnlyMeterIdFilterOperator: string = FilterOperator.Equal;
    get FilterBySignOnlyMeterId(): boolean {
        return this.SignOnlyMeterId !== undefined && this.SignOnlyMeterId !== null;
    }
    RatePackageId: string = undefined;
    RatePackageNameFilterOperator: string = FilterOperator.Equal;
    get FilterByRatePackageId(): boolean {
        return this.RatePackageId !== undefined && this.RatePackageId !== null;
    }
    HistoricallyAssociatedRatePackageId: string = undefined;
    HistoricallyAssociatedRatePackageIdFilterOperator: string = FilterOperator.Equal;
    get FilterByHistoricallyAssociatedRatePackageId(): boolean {
        return this.HistoricallyAssociatedRatePackageId !== undefined && this.HistoricallyAssociatedRatePackageId !== null;
    }
    LinkedBlockParentId: string = undefined;
    LinkedBlockFilterOperator: string = FilterOperator.Equal;
    get FilterByLinkedBlockParentId(): boolean {
        return this.LinkedBlockParentId !== undefined && this.LinkedBlockParentId !== null;
    }
    WorkOrder: string = undefined;
    get FilterByWorkOrder(): boolean {
        return this.WorkOrder !== undefined && this.WorkOrder !== '' && this.WorkOrder !== null;
    }
    TempRemovalStatus: string = undefined;
    TempRemovalStatusFilterOperator: string = FilterOperator.Equal;
    get FilterByTempRemovalStatus(): boolean {
        return this.TempRemovalStatus !== undefined && this.TempRemovalStatus !== null;
    }
    HasSignsOnly: string = undefined;
    HasSignsOnlyFilterOperator: string = FilterOperator.Equal;
    get FilterByHasSignsOnly(): boolean {
        return this.HasSignsOnly !== undefined && this.HasSignsOnly !== null;
    }
    SpaceId?: number = undefined;
    SpaceIdFilterOperator: string = FilterOperator.Contains;
     get FilterBySpaceId(): boolean {
         return this.SpaceId !== undefined && this.SpaceId !== null;
    }
    SpaceStatus?: string = undefined;
    SpaceStatusFilterOperator?: string = FilterOperator.Equal;
    get FilterBySpaceStatus(): boolean {
        return this.SpaceStatus !== undefined && this.SpaceStatus !== '' && this.SpaceStatus !== null;
    }

    SpaceType: string = undefined;
    SpaceTypeFilterOperator: string = FilterOperator.Equal;
    get FilterBySpaceType(): boolean {
        return this.SpaceType !== undefined && this.SpaceType !== '' && this.SpaceType !== null;
    }

    WorkOrderActionIds: string = undefined;
}
