
import {of as observableOf,  Observable } from 'rxjs';
import { RatePackageService } from './../services/rate-package.service';
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-rate-package-daily-dialog',
    templateUrl: 'rate-package-daily-dialog.html',
    styleUrls: ['./rate-package-daily-dialog.css']
  })
  export class RatePackageDailyDialog implements OnInit {

    constructor(
      public dialogRef: MatDialogRef<RatePackageDailyDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private ratePackageService: RatePackageService) { }

    dailyDataSource: any;
    monthlyDataSource: any;
    dailyDisplayedColumns = ['Day', 'FUA', 'PoO'];
    monthlyDisplayedColumns = ['Month', 'FUA'];
    daysOfWeek = [ {key: 0, value: 'Monday'}, {key: 1, value: 'Tuesday'}, {key: 2, value: 'Wednesday'}, {key: 3, value: 'Thursday'}, {key: 4, value: 'Friday'}, {key: 5, value: 'Saturday'}, {key: 6, value: 'Sunday'} ];
    monthsInYear = [ {key: 'march', value: 'March'}, {key: 'april', value: 'April'}, {key: 'may', value: 'May'}, {key: 'june', value: 'June'}, {key: 'july', value: 'July'}, {key: 'august', value: 'August'}, {key: 'september', value: 'September'}, {key: 'october', value: 'October'}, {key: 'november', value: 'November'}, {key: 'december', value: 'December'}, {key: 'january', value: 'January'}, {key: 'february', value: 'February'}];

    ngOnInit(): void {
      const dailyBreakdowns = new Array<DailyBreakdown>();
      const monthlyUtilizations = new Array<MonthlyUtilization>();

      this.ratePackageService.getDailyBreakdown(this.data.ratePackage)
        .subscribe(
        result => {
          this.daysOfWeek.forEach(day => {
            var fuaDailyBreakdown = result.result.fullyUtilizedAmountDailyBreakdown.filter(x => x.key === day.value)[0].value;
            var pooDailyBreakdown = result.result.hoursOfOperationDailyBreakdown.filter(x => x.key === day.value)[0].value;
            const breakdown = new DailyBreakdown(day.value, fuaDailyBreakdown, pooDailyBreakdown);
            dailyBreakdowns.push(breakdown);

            this.dailyDataSource = new DailyBreakdownDataSource(dailyBreakdowns);
          });
        },
        error => {
          console.log(error);
        });

        this.ratePackageService.getMonthlyUtilizationsForYear(this.data.ratePackage, this.data.ratePackage.fiscalYear)
          .subscribe(
            result => {
              this.monthsInYear.forEach(month => {
                var utilization = result[month.key];
                const monthlyUtilization = new MonthlyUtilization(utilization, month.value);
                monthlyUtilizations.push(monthlyUtilization);

                this.monthlyDataSource = new MonthlyUtilizationDataSource(monthlyUtilizations);
              });
            }
          )
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
  }

  export class DailyBreakdown {
    day: string;
    fua: number;
    poo: number;


    constructor(day: string, fua: number, poo: number) {
      this.day = day;
      this.fua = fua;
      this.poo = poo;
    }
  }

  export class MonthlyUtilization {
    utilizationAmount: number;
    calculationMonth: string;

    constructor(utilizationAmount: number, calculationMonth: string) {
      this.utilizationAmount = utilizationAmount;
      this.calculationMonth = calculationMonth;
    }
  }

  export class DailyBreakdownDataSource extends DataSource<any> {
    constructor(
      public rows: any) {super(); }
    connect(): Observable<Element[]> {
      return observableOf(this.rows);
    }

    disconnect() {}
  }

  export class MonthlyUtilizationDataSource extends DataSource<any> {
    constructor(public rows: any) { super(); }

    connect(): Observable<Element[]> {
      return observableOf(this.rows);
    }

    disconnect() { }
  }
