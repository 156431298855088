import { forkJoin, Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from '../../../security/login/login.service';
import { WorkOrder } from './../../models/work-order';
import { WorkOrdersService } from '../../services/work-orders.service';
import { WorkOrderComment } from './../../models/work-order-comment';
import { WorkOrderCommentTag } from '../../models/work-order-comment-tag';
import { TokenCache } from '../../../security/token-cache';
import {SecurityConstants} from '../../../environment';
import {SystemUser} from '../../../security/login/system-user';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-work-order-comments',
    templateUrl: './work-order-comments.component.html',
    styleUrls: ['./work-order-comments.component.css']
})

export class WorkOrderCommentsComponent implements OnInit {
    @Input() workOrder: WorkOrder;

    users = [];
    userResult = [];
    comments = Array<WorkOrderComment>();

    @Output()
    numberOfCommentsEmitter: EventEmitter<Number> = new EventEmitter<Number>(true);

    selectedUsers = [];
    newComment: string;
    internalComment: boolean = false;
    isUserCity: boolean = false;
    userGroupsToInclude = [
        SecurityConstants.CityApprover,
        SecurityConstants.CityUser,
        SecurityConstants.CPMAdmin,
        SecurityConstants.LAZUser,
        SecurityConstants.LAZAdmin,
        SecurityConstants.CPMElevatedApprover
    ];

    constructor(private _loginService: LoginService, private _workOrdersService: WorkOrdersService, private tokenCache: TokenCache) { }

    ngOnInit() {
        this.isUserCity = this.tokenCache.checkForCityUser();
        this.comments = this.workOrder.workOrderComments;
        this.comments.sort((a,b) => a.entryTimeStamp > b.entryTimeStamp ? -1 : 1);
        this.numberOfCommentsEmitter.emit(this.comments.length);

        this.getUsers().subscribe(results => {
          results.forEach(result => {
            result.forEach(user => {
              const found = this.userResult.find(function (e) {
                return user.UserName === e.UserName;
              });
              if (!found && user.Email) {
                this.userResult.push({FullName: `${user.FullName} (${user.UserName})`, UserName: user.UserName, UserEmail: user.Email});
              }
              });
            });

          this.users = this.userResult.sort((user1, user2) => user1.FullName.toLowerCase() < user2.FullName.toLowerCase() ? -1 : 1);
        });
    }

    getUsers(): Observable<Array<SystemUser>[]> {
      const observableBatch = new Array<Observable<SystemUser[]>>();

      // wait for all api calls to finish to sort through the users
      this.userGroupsToInclude.forEach(userGroup => {
        var obs = this._loginService.getUsersByRole(userGroup).pipe(map( response => response));
        observableBatch.push(obs);
      });

      return forkJoin(observableBatch);
    }

    submitComment() {
        var newComment = new WorkOrderComment();
        newComment.author = this.tokenCache.getUserFullName();
        newComment.isInternal = this.internalComment;
        newComment.text = this.newComment;
        newComment.commentTags = [];
        this.selectedUsers.forEach(user => {
            var newCommentTag = new WorkOrderCommentTag();
            newCommentTag.taggedUserName = user.FullName;
            newCommentTag.taggedUserEmail = user.UserEmail;
            newComment.commentTags.push(newCommentTag);
        });
        newComment.entryTimeStamp = new Date();

        this._workOrdersService.AddWorkOrderCommentAndTag(this.workOrder.id, newComment).subscribe(result => {
            window.location.reload();
        });
    }

    commaSeperatedList(tags: WorkOrderCommentTag[]) {
        var list = '';
        tags.forEach(tag => {
            list += (tag.taggedUserName + ', ');
        });
        return list.substring(0, list.lastIndexOf(','));
    }
}
