import { ZoneService } from './../../inventory-management/zones/zones.service';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Zone } from '../../inventory-management/zones/zone';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AreaService } from '../../inventory-management/areas/areas.service';
import { Area } from '../../inventory-management/areas/area';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BlockInventoryChange } from '../../work-orders/inventory-information/models/block-inventory-change';
import { InventoryChangeStatusType } from '../../work-orders/inventory-information/models/inventory-change-base';
import { BlockInventoryChangeService } from '../../work-orders/inventory-information/services/block-inventory-change-service';
import { LinkedBlock } from '../linked-blocks/linked-block';
import { BlockService } from '../../inventory-management/blocks/blocks.service';
@Component({
  selector: 'app-create-node',
  templateUrl: './create-node.component.html',
  styleUrls: ['./create-node.component.css']
})

export class CreateNodeComponent implements OnInit {

  showZoneForm = false;
  showAreaForm = false;
  showBlockForm = false;
  showLinkedBlockForm = false;

  zoneDropDown = [];

  zoneFormGroup: FormGroup;
  areaFormGroup: FormGroup;
  blockInventoryChange: BlockInventoryChange;

  zoneIdAlreadyExists = false;
  areaIdAlreadyExists = false;

  blockFormInvalid = true;

  linkedBlockSubmitDisabled = true;

  linkedBlocks: LinkedBlock[];

  shouldSubmitLinkedBlocks = false;

  @Output()
  submitLinkedBlocksEmitter: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  @ViewChild('zoneFormToggleButton', {static: true}) zoneFormButton: MatButtonToggle;

  constructor(private zoneService: ZoneService, private areaService: AreaService,
    public snackBar: MatSnackBar, private formBuilder: FormBuilder, public blockInventoryChangeService: BlockInventoryChangeService,
  public blockService: BlockService) { }

  ngOnInit() {
    this.zoneService.getAllZones()
        .subscribe(zoneResult => {
          this.zoneDropDown = zoneResult.sort((a: Zone, b: Zone) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);
        });

    this.zoneFormGroup = this.formBuilder.group({
          zoneId: void 0,
      });

    this.areaFormGroup = this.formBuilder.group({
        zoneId: void 0,
        areaId: void 0
    });
  }

  ngAfterViewInit() {
    this.zoneFormButton.checked = true;
    this.showZoneForm = true;
    this.showAreaForm = false;
    this.showBlockForm = false;
    this.showLinkedBlockForm = false;
  }

  displayZoneForm(showZoneForm) {
    this.showZoneForm = showZoneForm;
    this.showAreaForm = false;
    this.showBlockForm = false;
    this.showLinkedBlockForm = false;
  }

  displayAreaForm(showAreaForm) {
    this.showAreaForm = showAreaForm;
    this.showZoneForm = false;
    this.showBlockForm = false;
    this.showLinkedBlockForm = false;
  }

  displayBlockForm(showBlockForm) {
    this.showBlockForm = showBlockForm;
    this.showZoneForm = false;
    this.showAreaForm = false;
    this.showLinkedBlockForm = false;
  }

  displayLinkedBlocksForm(showLinkedBlocksForm) {
    this.showLinkedBlockForm = showLinkedBlocksForm;
    this.showZoneForm = false;
    this.showAreaForm = false;
    this.showBlockForm = false;
  }

  checkIfZoneExists(zoneIdEntered) {
    this.zoneService.getAllZones().subscribe(zones => {
      const zone = zones.filter(z => z.displayId === zoneIdEntered);

      this.zoneIdAlreadyExists = zone && zone.length > 0;
    });
  }

  checkIfAreaExists(areaIdEntered) {
    this.areaService.getAllAreas().subscribe(areas => {
      const area = areas.filter(z => z.displayId === areaIdEntered);

      this.areaIdAlreadyExists = area && area.length > 0;
    });
  }

  checkForBlockError(event: boolean) {
    this.blockFormInvalid = event;
  }

  checkForLinkedBlockSubmitDisabled(event: boolean) { // change to better name
    this.linkedBlockSubmitDisabled = event;
  }

  receiveLinkedBlocksToSubmit(event: LinkedBlock[]) {
    this.blockService.SaveLinkedBlocks(event).subscribe(
      result => {
        this.snackBar.open('Successfully created Linked Blocks', '', <MatSnackBarConfig>{
          duration: 5000,
        });
      },
      error => {
        this.snackBar.open('Failed to create Linked Blocks', 'X');
      });
  }

  submitNewZoneId() {
    const zone = new Zone();
    zone.displayId = this.zoneFormGroup.get('zoneId').value;
    this.zoneService.createZone(zone).subscribe(
      result => {
        this.snackBar.open(`Successfully created Zone ${result.displayId}`, '', <MatSnackBarConfig>{
          duration: 5000,
        });

        this.zoneFormGroup.get('zoneId').setValue(undefined);

        this.zoneDropDown.push(result);
        this.zoneDropDown = this.zoneDropDown.sort((a: Zone, b: Zone) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);
      },
      error => {
        this.snackBar.open(`Failed to create Zone ${zone.displayId}`, 'X');
      }
    );
  }

  submitNewAreaId() {
    const area = new Area();
    area.displayId = this.areaFormGroup.get('areaId').value;
    area.zoneId = this.areaFormGroup.get('zoneId').value;
    this.areaService.createArea(area).subscribe(
      result => {
        this.snackBar.open(`Successfully created Area ${result.displayId}`, '', <MatSnackBarConfig>{
          duration: 5000,
        });

        this.areaFormGroup.get('areaId').setValue(undefined);
        this.areaFormGroup.get('zoneId').setValue(undefined);
      },
      error => {
        this.snackBar.open(`Failed to create Area ${area.displayId}`, 'X');
      }
    );
  }

  submitNewBlock() {
    this.snackBar.open('Sumitting new Block');
    const blockChange = this.blockInventoryChange;
    if (blockChange && blockChange.status !== InventoryChangeStatusType.Completed) {
      this.blockInventoryChangeService
        .submitBlockChangeWithoutWorkOrder(blockChange)
        .subscribe(body => {
          if (!body) { return; }
          this.snackBar.open('Submitted Block Inventory Change', 'X');
          window.location.reload();
        }, error => {
          const result = JSON.parse(error['_body']);
          if (result.validationRepresentation && result.validationRepresentations.length > 0) {
            let errorMessage = '';
            for (let i = 0; i < result.validationRepresentations.length; i++) {
              errorMessage += result.validationRepresentations[i].message;
              if (i !== result.validationRepresentations.length - 1) {
                errorMessage += ', ';
              }
            }
            this.snackBar.open(`Error submitting Block Inventory Change: ${errorMessage}`, 'X');
          } else {
            this.snackBar.open(`Error submitting Block Inventory Change: ${result.message}`, 'X');
          }
        });
    }
  }

  submitLinkedBlocks() {
    this.shouldSubmitLinkedBlocks = !this.shouldSubmitLinkedBlocks;
  }

  setBlockInventoryChange(blockInventoryChange: BlockInventoryChange) {
    this.blockInventoryChange = blockInventoryChange;
  }
}
