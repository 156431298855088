import { Component, OnChanges, Input } from '@angular/core';
import { Meter } from './../../inventory-management/meters/meter';
import { MeterService } from './../../inventory-management/meters/meters.service';
import { BlockService } from '../../inventory-management/blocks/blocks.service';
import { Block } from '../../inventory-management/blocks/block';

@Component({
    selector: 'app-associated-meter-details',
    templateUrl: './associated-meter-details.component.html',
    styleUrls: ['./associated-meter-details.component.css']
})

export class AssociatedMeterDetailsComponent implements OnChanges {
    @Input() meterId: string;
    @Input() meterTitle: string;
    @Input() effectiveDate: Date;

    loading: boolean;
    meter: Meter;
    block: Block;

    constructor(private _meterService: MeterService, private _blockService: BlockService) { }

    ngOnChanges(): void {
      this.loading = true;
      if (this.meterId) {
        this._meterService.getMeterById(this.meterId, this.effectiveDate).subscribe(
            result => {
                this.meter = result;
                this._blockService.getBlockById(this.meter.blockId).subscribe(
                    blockResult => {
                        this.block = blockResult;
                        this.loading = false;
                    },
                    error => {
                        this.loading = false;
                        console.log(error);
                    }
                )
                
            },
            error => {
              this.loading = false;
              console.log(error);
            }
        );
      }
      else {
        this.loading = false;
      }
    }

    navigateToMeterPage(meterId: string) {
        window.open('/inventorymanagement/meters/' + meterId, 'noopener');
    }
}
