import { Component } from '@angular/core';
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './environment';
import { TokenCache } from './security/token-cache';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor (private router: Router, private TokenCache: TokenCache) {
    this.bearerToken = 'CpmBearerToken';
  }

  activeButton: string;
  hideNavigation: boolean;
  username: string;
  bearerToken: string;
  displayTrueUpTab: boolean;
  displayAdminTab: boolean;
  displayReportsTab: boolean;
  routeSubscription: Subscription;

  ngOnInit() {

    const currentUrl = location.pathname.substr(1);

    if (currentUrl === '') {
      this.changeTab('inventorymanagement');
    }
    else {
      this.router.navigate([currentUrl]);
    }

    this.hideNavigation = (currentUrl.includes('login'));

    this.routeSubscription = this.router.events.subscribe((value) => {

      this.displayTrueUpTab = this.TokenCache.checkForCPMUser() || this.TokenCache.checkForCityUser();
      this.displayAdminTab = this.TokenCache.checkForCPMUser() || this.TokenCache.checkForLAZUser();
      this.displayReportsTab = true; //all can see this now - this.TokenCache.checkForCPMUser() || this.TokenCache.checkForLAZUser();

      if (value['url']) {
        if (value['url'].includes('inventorymanagement')) {
          this.activeButton = 'inventorymanagement';
        }
        else if (value['url'].includes('trueup') && this.displayTrueUpTab) {
          this.activeButton = 'trueup';
        }
        else if (value['url'].includes('admin') && this.displayAdminTab) {
          this.activeButton = 'admin';
        }
        else if (value['url'].includes('reports') && this.displayReportsTab) {
          this.activeButton = 'reports';
        }
        else if (value['url'].includes('workorders')) {
          this.activeButton = 'workorders';
        }
        else {
          this.activeButton = 'inventorymanagement';
        }

        this.hideNavigation = (value['url'].includes('login') || value['url'].includes('unauthorized') || this.TokenCache.getUserName() === undefined);
        this.username = this.TokenCache.getUserName();
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  changeTab(tab) {
    this.router.navigate([tab]);
    this.activeButton = tab;
  }
}
