import { ClosureFilterRepresentation } from "../closure/closure-configurator/closure-filter-representation";
import { QueryStringBuilder, FilterOperator } from "../../core/query-strings/query-string-builder";

export class ClosureQueryStringBuilder {
  static createFilterQueryString(evt: ClosureFilterRepresentation): string {
    let filterQueryString = '';

    if (evt.FilterByMeterId) {
      filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'meterId', evt.MeterIdFilterOperator, evt.MeterId);
    }

    if (evt.FilterBySpaceId) {
      filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'spaceId', evt.SpaceIdFilterOperator, evt.SpaceId);
    }

    if (evt.FilterByBlockId) {
      filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'blockId', evt.BlockIdFilterOperator, evt.BlockId);
    }

    if (evt.FilterByZoneId) {
      filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'zoneId', FilterOperator.Equal, evt.ZoneId);
    }

    if (evt.FilterByAreaId) {
      filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'areaId', FilterOperator.Equal, evt.AreaId);
    }

    if (evt.FilterByClosureId) {
      filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'closureId', evt.ClosureIdFilterOperator, evt.ClosureId);
    }

    filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'numberOfClosures', evt.NumberOfClosuresFilterOperator, evt.NumberOfClosures);

    filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'qualifiedRequiredClosures', evt.QualifiedRequiredClosuresFilterOperator, evt.QualifiedRequiredClosures);

    return QueryStringBuilder.PrependFilterQueryStringVariableName(filterQueryString);
  }
}
