import { TrueUpDetailJsonConverter } from "../true-up-detail-json-converter";
import { PaginatedGetResult } from "../../core/models/paginated-get-result";
import { MonthlyTrueUpCalculationDetailBase } from "../monthly-calculations/monthly-true-up-calculation-detail-base";
import { SpaceTrueUpDetail } from "./space-true-up-detail";

export class SpaceTrueUpDetailJsonConverter extends TrueUpDetailJsonConverter<SpaceTrueUpDetail> {

    getInstance(): SpaceTrueUpDetail {
        return new SpaceTrueUpDetail();
    }

    jsonToLevelSpecificDetail(responseJson: any) : PaginatedGetResult<SpaceTrueUpDetail> {
        
        var paginatedResult = this.jsonToPaginatedResult(responseJson);
        
        paginatedResult.items.forEach(meterTrueUpDetail => {
        
            this.jsonToBaseTrueUpDetail(meterTrueUpDetail);
        });

        return paginatedResult;
    }
}