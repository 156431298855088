exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".filterContainer {\r\n    padding: 0px 8px 0px 8px;\r\n}\r\n\r\n.idFiltersContainer {\r\n    padding: 0px 16px 0px 16px;\r\n}\r\n\r\n.allFiltersContainer {\r\n    padding: 8px 0px 0px 0px;\r\n}\r\n\r\n.mat-form-field {\r\n    width: 140px;\r\n    margin: 0px 8px 0px 8px;\r\n}\r\n\r\n.filterButton {\r\n    margin: 0px 8px 0px 8px;\r\n}\r\n.filter-button-container\r\n{\r\n    margin: 16px 0px 0px 0px\r\n}\r\n\r\n.material-icons {\r\n  font-size: 16px;\r\n}\r\n\r\n.space-id {\r\n   margin-left:36% \r\n}\r\n\r\n.meter-id {\r\n    margin-left:36%;\r\n}\r\n\r\n.month {\r\n    margin-right:38%;\r\n}\r\n\r\n.calendar-year {\r\n    margin-right:26%;\r\n}\r\n\r\n.reporting-quarter {\r\n    margin-right:18%;\r\n}\r\n\r\n.rv {\r\n    margin-right:43%;\r\n}\r\n\r\n.er {\r\n    margin-right:43%;\r\n}\r\n\r\n.fua {\r\n    margin-right:41%;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();