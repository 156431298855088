import { PagedResult } from '../../core/models/paged-result';

export class MeterSummary {
  activeMetersOnBlock: number;

  areaId: string;

  areaName: string;

  blockId: string;

  blockName: string;

  blockRange: string;

  displayId: string;

  displayAddress: string;

  foundationType: string;

  id: string;

  meterContractType: string;

  meterStatus: string;

  mostFrequentRatePackage: string;

  mostFrequentRatePackageDescription: string;

  multipleRatePackages: boolean;

  numberOfActiveSpaces: number;

  parkingType: string;

  ratePackageId: string;

  ratePackageIds: RatePackageIdSummary[];

  ratePackageDescription: string;

  sideOfStreet: string;

  wardId: string;

  wardName: string;

  zoneId: string;

  zoneName: string;
}

export class RatePackageIdSummary {
  id: string;

  name: string;

  description: string;

  numberOfSpacesWithRatePackage: number;
}

export class MeterPaginationResult extends PagedResult<MeterSummary> {}

export class Location {
  numberRangeEnd: number;

  numberRangeStart: number;

  streetDirection: string;

  streetName: string;
}
