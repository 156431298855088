// This file corresponds with src\app\trueup\monthly-calculations\monthly-true-up-calculation-service-base.ts


import { Injectable, ErrorHandler } from '@angular/core';
import { CollectionsDetailBase } from '../../collections-list-column-def';
import { ReportingHttpService } from '../../../services/reporting-http.service';
import { FileDownloadService } from '../../../../core/file-download/file-download.service';
import { CollectionsFilterRepresentation } from '../collections-filter-representation-base';
import { PaginatedGetResult } from '../../../../core/models/paginated-get-result';
import { Observable } from 'rxjs';
import { QueryStringBuilder } from '../../../../core/query-strings/query-string-builder';
import { ExportSelectRepresentation } from '../../../../inventory-management/inventory-filter/export-representations';
import { ExportCollectionsFilterRepresentation } from '../collections-export-filter-representation';
import { DateTimeUtility } from '../../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { CollectionsDetailJsonConverter } from './collections-detail-json-converter';
import { TimeZoneConverter } from '../../../../core/time-zone/time-zone-converter';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class CollectionsDetailService {

    constructor(private collectionsDataHttpService: ReportingHttpService, private errorHandler: ErrorHandler, private jsonConverter: CollectionsDetailJsonConverter,
        private fileDownloadService: FileDownloadService ) { }

    /* This is static so that it can be tested more easily */
    static createFilterQueryString(filterRepresentation: CollectionsFilterRepresentation): string {
      let filter = '';
      if (filterRepresentation.ShouldFilterByMeterId) {
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'meterid', filterRepresentation.MeterIdFilterOperator, filterRepresentation.MeterId);
      }

      if ( filterRepresentation.ShouldFilterByDateOfCollection) {
          var filterDate = DateTimeUtility.parseDateString(TimeZoneConverter.EnsureChicagoTime(filterRepresentation.DateOfCollection));
          var filterDateEnd = DateTimeUtility.parseDateString(TimeZoneConverter.EnsureChicagoTime(filterRepresentation.DateOfCollectionEnd));
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'collection_date', filterRepresentation.DateOfCollectionFilterOperator, filterDate, filterDateEnd);
      }
      if ( filterRepresentation.ShouldFilterByTimeOfCollection) {
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'collection_time',  filterRepresentation.TimeOfCollectionFilterOperator, filterRepresentation.TimeOfCollection, filterRepresentation.TimeOfCollectionEnd);
      }

      if (filterRepresentation.ShouldFilterByDateReceived) {
        var filterDate = DateTimeUtility.parseDateString(TimeZoneConverter.EnsureChicagoTime(filterRepresentation.DateReceived));
        var filterDateEnd = DateTimeUtility.parseDateString(TimeZoneConverter.EnsureChicagoTime(filterRepresentation.DateReceivedEnd));
        filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'received_date', filterRepresentation.DateReceivedFilterOperator, filterDate, filterDateEnd);
      }
      if (filterRepresentation.ShouldFilterByCollectionAmount) {
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'collection_amount', filterRepresentation.CollectionAmountFilterOperator, filterRepresentation.CollectionAmount, filterRepresentation.CollectionAmountEnd);
      }
      if (filterRepresentation.ShouldFilterByCollectionType) {
          filter = QueryStringBuilder.AddEqualsMulti(filter, 'collection_type', filterRepresentation.CollectionType);
      }
      if (filterRepresentation.ShouldFilterByDenominationName) {
          filter = QueryStringBuilder.AddEquals(filter, 'denomination_name', filterRepresentation.DenominationName);
      }
      if (filterRepresentation.ShouldFilterByZone) {
        filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'zone', filterRepresentation.ZoneFilterOperator, filterRepresentation.Zone, filterRepresentation.ZoneEnd);
      }
      if (filterRepresentation.ShouldFilterByArea) {
       // filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'area', filterRepresentation.AreaFilterOperator, 'gt', filterRepresentation.Area);
        filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'area', filterRepresentation.AreaFilterOperator, filterRepresentation.Area, filterRepresentation.AreaEnd);
      }
      if (filterRepresentation.ShouldFilterByBlockId) {
        filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'blockid', filterRepresentation.BlockIdFilterOperator, filterRepresentation.BlockId);
      }
      if (filterRepresentation.ShouldFilterByParkingType) {
          filter = QueryStringBuilder.AddEquals(filter, 'parking_type', filterRepresentation.ParkingType);
      }
      if (filterRepresentation.ShouldFilterByCollectionNumber) {
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'collection_number', filterRepresentation.CollectionNumberFilterOperator, filterRepresentation.CollectionNumber, filterRepresentation.CollectionNumberEnd);
      }
      if (filterRepresentation.ShouldFilterByNumberOfCoins) {
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'number_of_coins', filterRepresentation.NumberOfCoinsFilterOperator, filterRepresentation.NumberOfCoins, filterRepresentation.NumberOfCoinsEnd);
      }
      if (filterRepresentation.ShouldFilterByPurchaseCount) {
          filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'purchase_count', filterRepresentation.PurchaseCountFilterOperator, filterRepresentation.PurchaseCount, filterRepresentation.PurchaseCountEnd);
      }
      if (filterRepresentation.ShouldFilterByCollectionGUID) {
          filter = QueryStringBuilder.AddEquals(filter, 'collection_guid', filterRepresentation.CollectionGUID);
      }
      if (filter.length === 0) {
          return filter;
      }
      return QueryStringBuilder.PrependFilterQueryStringVariableName(filter);
  }
  /* End Static Method */

  public downloadReport(key: string) {
    this.collectionsDataHttpService.DownloadReport(this.GetReportType(), key, 0);
  }

  GetEndpoint(): string {
    return `/collections`;
  }

  GetReportType(): string {
    return 'WAREHOUSE_COLLECTIONS';
  }

  public GetExportEndpoint(): string {
    return this.GetEndpoint() + '/export';
  }


  private handleError(error: any): Promise<any> {
    if (this.errorHandler) {
      this.errorHandler.handleError(error);
    }
    return Promise.reject(error.message || error);
  }

    public getAllItems(orderByColumn: string, isAscending: boolean = true,
        skip: number = 25, top: number= 25, filter: CollectionsFilterRepresentation): Observable<PaginatedGetResult<CollectionsDetailBase>> {
            let queryString = CollectionsDetailService.createFilterQueryString(filter);
            if (orderByColumn !== undefined) {
                queryString = QueryStringBuilder.AddOrderBy(queryString, orderByColumn, isAscending);
            }
            queryString = QueryStringBuilder.AddSkip(queryString, skip);
            queryString = QueryStringBuilder.AddTop(queryString, top);

            // tslint:disable-next-line: prefer-const
            let endpoint = this.GetEndpoint();
            return this.collectionsDataHttpService.get(`${endpoint}?${queryString}`, null)
              .pipe(
                map(response => response != null ? this.jsonConverter.jsonToLevelSpecificDetail(response) : null),
                catchError((error) => this.handleError(error))
              );
    }

    public requestCollectionsReport(orderByColumn: string, isAscending: boolean = true,
        filter: ExportCollectionsFilterRepresentation): Observable<any> {
            let queryString = CollectionsDetailService.createFilterQueryString(filter.CollectionsFilterRepresentation);

            if (orderByColumn !== undefined) {
                queryString = QueryStringBuilder.AddOrderBy(queryString, orderByColumn, isAscending);
            }

            // tslint:disable-next-line: prefer-const
            let endpoint = this.GetExportEndpoint();
            // tslint:disable-next-line: prefer-const
            let encodedEndpoint = `${endpoint}?${queryString}`;
            return this.collectionsDataHttpService.post(encodedEndpoint, null)
              .pipe(catchError((error) => this.handleError(error)));
    }
}
