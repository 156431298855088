import { Router } from '@angular/router';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

import { WorkOrderUpdate } from './../models/work-order-update';
import { LetterOfDirectionService } from './../services/letter-of-direction.service';
import { LODApplyHistoricalChangesConfirmation } from './lod-apply-historical-changes-confirmation/lod-apply-historical-changes-confirmation.component';
import { LetterOfDirection } from './../models/letter-of-direction';
import { WorkOrder, LetterOfDirectionStatusType, WorkOrderStatusType } from '../models/work-order';
import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { TokenCache } from '../../security/token-cache';
import { CalculationsCompleteService } from '../../trueup/calculations-complete-service/calculations-complete-service';
import { WorkOrderRoleConstants } from '../../security/login/securityConstants';
import { ActionConstants } from '../action/action-constants';
import { RoleOptionsConstants } from '../../environment';

@Component({
  selector: 'app-letter-of-directions-list',
  templateUrl: './letter-of-directions-list.component.html',
  styleUrls: ['./letter-of-directions-list.component.css']
})
export class LetterOfDirectionsListComponent implements OnChanges {

  constructor(private letterOfDirectionService: LetterOfDirectionService, private snackBar: MatSnackBar, private dialog: MatDialog,
    private tokenCache: TokenCache, private calculationsCompleteService: CalculationsCompleteService, private router: Router) { }

  @Input() letterOfDirections: LetterOfDirection[];
  @Input() workOrder: WorkOrder;

  dataSource: MatTableDataSource<LetterOfDirection>;

  displayedColumns = ['versionNumber', 'author', 'date', 'response', 'responder', 'dateOfResponse', 'tempApprovals', 'apply-historical-change-column'];

  showApplyHistoricalChangeButton = false;
  workOrderIsComplete = false;
  maxLodVersion = 0;
  accepted = LetterOfDirectionStatusType.Accepted;
  selectedLetterOfDirection: LetterOfDirection;
  userIsCity: boolean;

  ConcessionaireName = RoleOptionsConstants.ConcessionaireName.value;

  ngOnChanges() {
    this.userIsCity = this.tokenCache.checkForCityUser();
    this.dataSource = new MatTableDataSource<LetterOfDirection>(this.letterOfDirections);
    this.workOrderIsComplete = this.workOrder.currentStatus === WorkOrderStatusType.CompletedName;
    this.maxLodVersion = 0;
    this.letterOfDirections.forEach(lod => {
      if (lod.versionNumber >= this.maxLodVersion) {
        this.maxLodVersion = lod.versionNumber;
      }
    });
    this.selectedLetterOfDirection = this.workOrder.currentLetterOfDirection;
    this.letterOfDirections.forEach(lod => {
      if (this.tokenCache.checkForLAZUser() && lod.versionNumber === this.maxLodVersion && this.workOrderIsComplete && lod.status === this.accepted) {
        const today = new Date();
        const sortedWorkOrderActions = lod.workOrderActions.sort((a, b) => DateTimeUtility.compareDate(a.effectiveDate, b.effectiveDate) ? -1 : 1);
        const effectiveDate = new Date(sortedWorkOrderActions[0].effectiveDate);
        const dateDifference = (moment(today).diff(moment(effectiveDate), 'days'));
        this.calculationsCompleteService.isCalculationApprovedForYearAndMonth(effectiveDate.getFullYear(), effectiveDate.getMonth() + 1).subscribe(approvedResult => {
          if (approvedResult.result === true) {
            this.calculationsCompleteService.getLastFinalizedQuarter(effectiveDate.getFullYear(), effectiveDate.getMonth() + 1).subscribe(lastQuarterResult => {
              this.showApplyHistoricalChangeButton = lastQuarterResult;
            });
          }
          else {
            this.showApplyHistoricalChangeButton = true;
          }
        });
      }
      else {
        this.showApplyHistoricalChangeButton = lod.versionNumber === this.maxLodVersion && this.workOrderIsComplete && lod.status === this.accepted && !this.userIsCity;
      }
    });

  }

  selectLetterOfDirection(lod: LetterOfDirection) {
    this.selectedLetterOfDirection = lod;
  }

  parseDateTime(dateTime) {
    if (dateTime !== null && dateTime !== undefined) {
      return DateTimeUtility.parseDateString(dateTime);
    }
    else {
      return null;
    }
  }

  getTempApprovals(lod: LetterOfDirection): string {
    let list = '';
    if (!lod.tempApprovalStatus) {
      return null;
    }
    lod.letterOfDirectionResponses.forEach(response => {
      list += response.responder + ' - ' + DateTimeUtility.parseDateString(response.responseDate) + ', ';
    });
    if (list.charAt(list.length - 2) === ',') {
      return list.substring(0, list.length - 2);
    }
    return list;
  }

  showHistoricalChangeButton(lod: LetterOfDirection): boolean {
    return this.showApplyHistoricalChangeButton && lod.versionNumber === this.maxLodVersion;
  }

  applyHistoricalChanges(lod: LetterOfDirection) {
    const workOrderUpdate = new WorkOrderUpdate();
    workOrderUpdate.id = this.workOrder.id;
    if (this.workOrder.currentLetterOfDirection.historicalEffectiveDate) {
      workOrderUpdate.hasHistoricalEffectiveDate = true;
    }
    workOrderUpdate.historicalEffectiveDate = this.workOrder.currentLetterOfDirection.historicalEffectiveDate;
    workOrderUpdate.workOrderActions = lod.workOrderActions;
    workOrderUpdate.workOrderStatusId = this.workOrder.currentStatusId;
    workOrderUpdate.workOrderStatus = this.workOrder.currentStatus;

    var goesToCityForApproval = this.workOrder.currentLetterOfDirection.workOrderActions.find(action=> {
      return action.actionId !== ActionConstants.ShiftSpaceId && action.actionId !== ActionConstants.UpdateMeterAttributesId;
    })

    const dialogRef = this.dialog.open(LODApplyHistoricalChangesConfirmation, {
      width: '500px',
      height: 'auto',
      data: {doesNotNeedApproval: !goesToCityForApproval}
    });

    dialogRef.afterClosed().subscribe((result) => {

      if (result) {
        this.letterOfDirectionService.applyHistoricalChanges(workOrderUpdate)
        .subscribe(response => {
          this.snackBar.open('Historical Change Letter of Direction Draft Created', '', <MatSnackBarConfig>{
            duration: 1500,
          }).afterDismissed().subscribe(result => {
            this.router.navigate(['workorders/create/' + this.workOrder.id]);
          });

        },
          error => {
          const messageBody = JSON.parse(error['_body'].message);
          this.snackBar.open(messageBody, 'X');
        });
      }
    });
  }
}
