export class UpdateMeterAttributesSpaceChange {
  spaceId: string;

  spaceDisplayId: string;

  meterId: string;

  meterDisplayId: string;

  currentFineAmountId: string;

  newFineAmountId: string;

  newFineAmount?: number;

  effectiveDate: Date;

  workOrderActionId: string;
}
