import { DateTimeUtility } from './../meter-true-up/models/month-name-dictionary';
import { Component, OnChanges, Input, OnInit, ErrorHandler } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { MeterService } from './../meters.service';
import { MeterDowntime } from '../meter';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { MeterDowntimeReportService } from '../../../reports/services/meter-downtime-report.service';

@Component({
  selector: 'app-meter-downtime-table',
  templateUrl: './meter-downtime-table.component.html',
  styleUrls: ['./meter-downtime-table.component.css']
})
export class MeterDowntimeTableComponent implements OnChanges, OnInit {

  constructor(private _meterService: MeterService, private _startTimeService: SystemStartTimeService, private reportService: MeterDowntimeReportService, private _errorHandler: ErrorHandler) { }

  @Input() meterDisplayId: string;

  displayedColumns = ['startDateTime', 'endDateTime', 'totalMinutes', 'relevantSeconds', 'utilizationAmount', 'inoperableId', 'overlap'];
  dataSource = new MatTableDataSource<MeterDowntime>();
  reportingYearFilter: number;
  reportingYears: number[] = [];

  // TODO August/Chris - Remove when service call is available
  inoperableTimelineResponse: MeterDowntime[] = [];

  ngOnInit() {
      this._startTimeService.getInitialSystemStartTime().subscribe(result => {
        for (let i = result.getFullYear(); i <= new Date().getFullYear(); i++) {
          this.reportingYears.push(i);
        }
      },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
      });
  }

  ngOnChanges() {
    if (this.meterDisplayId != null && this.meterDisplayId !== undefined) {
      this.getAllDowntimes();
    }
  }

  getAllDowntimes() {
    // TODO August/Chris - Use call

     this.reportService.getInoperableRecordsForMeter(this.meterDisplayId)
      .subscribe(result => {
        this.inoperableTimelineResponse = result;
        this.dataSource = new MatTableDataSource<MeterDowntime>(result);
      },
        error => {
          console.log(error);
        });

    // TODO August/Chris - Remove when service call is available
    // this.timelineJson.forEach(t => {
    //   var mD = new MeterDowntime();
    //   mD.meterDisplayId = t.meterDisplayId;
    //   mD.inoperableId = t.inoperableId;
    //   mD.inoperableMonth = new Date(t.inoperableMonth);
    //   mD.startDateTime = new Date(t.startDateTime);
    //   mD.endDateTime = new Date(t.endDateTime);
    //   mD.notes = t.notes;

    //   this.inoperableTimelineResponse.push(mD);
    // });

    // this.dataSource = new MatTableDataSource<MeterDowntime>(this.inoperableTimelineResponse);
  }


  clearFilter() {
    this.reportingYearFilter = null;
    this.dataSource = new MatTableDataSource<MeterDowntime>(this.inoperableTimelineResponse);
  }

  applyFilter() {
    this.dataSource.data = this.inoperableTimelineResponse
      .filter(downTime => {
        // If startDateTime or endDateTime of the downtime record happens in the reporting year,
        // we'll still show them in the table
        const startYear = DateTimeUtility.parseYear(downTime.startDateTime);
        const startMonth = DateTimeUtility.parseMonth(downTime.startDateTime);
        const startTimeInReportYear = ((startMonth >= 3 && startYear == this.reportingYearFilter) 
          || (startMonth < 3 && startYear == this.reportingYearFilter + 1));
  
       const endYear = DateTimeUtility.parseYear(downTime.endDateTime);
       const endMonth = DateTimeUtility.parseMonth(downTime.endDateTime);
       const endTimeInReportYear = ((endMonth >= 3 && endYear == this.reportingYearFilter) 
          || (endMonth < 3 && endYear == this.reportingYearFilter + 1));

       return  startTimeInReportYear || endTimeInReportYear;
      });
    this.dataSource.data = this.sortByDate();
  }

  exportTable() {
    this.reportService.RequestMeterDowntimeReportForMeter(this.meterDisplayId, this.reportingYearFilter).subscribe(
      result => {
        this.reportService.DownloadWorkOrderReport(result.result);
      },
      error => {
        this._errorHandler.handleError(error);
      }
    )
  }

  sortByDate() {
    return this.dataSource.data.sort((a, b) => {
      return DateTimeUtility.compareDate(a, b) ? -1 : 1;
    });
  }


  // TODO August/Chris - Remove when service call is available
  // timelineJson = [
  //   {
  //       "meterDisplayId": null,
  //       "inoperableMonth": "2014-01-01T06:00:00+00:00",
  //       "inoperableId": "0001",
  //       "startDateTime": "2014-01-02T06:00:00+00:00",
  //       "endDateTime": "2014-01-03T06:00:00+00:00",
  //       "notes": "IT'S BROKEN"
  //   },
  //   {
  //       "meterDisplayId": null,
  //       "inoperableMonth": "2015-01-01T06:00:00+00:00",
  //       "inoperableId": "0002",
  //       "startDateTime": "2014-01-02T06:00:00+00:00",
  //       "endDateTime": "2015-01-03T06:00:00+00:00",
  //       "notes": "IT BROKE AGAIN"
  //   },
  //   {
  //       "meterDisplayId": null,
  //       "inoperableMonth": "2016-01-01T06:00:00+00:00",
  //       "inoperableId": "0003",
  //       "startDateTime": "2016-01-02T06:00:00+00:00",
  //       "endDateTime": "2016-01-03T06:00:00+00:00",
  //       "notes": "WE SHOULD RETURN THIS METER"
  //   }
  // ]
}
