import { BlockService } from './../../../inventory-management/blocks/blocks.service';
import { MeterService } from './../../../inventory-management/meters/meters.service';
import { LoadingZoneImpactService } from './../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { StringExtensions } from './../../../string-extensions';
import { SpaceSummary } from './../../../inventory-management/spaces/space-summary';
import { SpaceService } from './../../../inventory-management/spaces/spaces.service';
import { SpaceInventoryChange } from './../models/space-inventory-change';
import { WorkOrderAction } from './../../models/work-order-action';
import { ActionConstants } from './../../action/action-constants';
import { RatePackageService } from '../../../inventory-management/rate-packages/services/rate-package.service';
import { DateTimeUtility } from '../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { WorkorderActionJsonConstants } from '../../action-field/workorder-action-json-constants';
import { LoadingZoneImpactAssessmentPostBody } from '../../models/loading-zone-impacts/loading-zone-impact-assessment-body';
import { FineAmountService } from './../../../inventory-management/fine-amounts/fine-amounts.service';
import { FineAmount } from './../../../inventory-management/fine-amounts/fine-amount';
import { SpaceStatusConstants } from '../../../inventory-management/spaces/space-constants';

@Component({
  selector: 'app-work-order-space-inventory',
  templateUrl: './work-order-space-inventory.component.html',
  styleUrls: ['./work-order-space-inventory.component.css']
})
export class WorkOrderSpaceInventoryComponent implements OnInit {

  public ActionConstants = ActionConstants;
  public spaceInventoryForm: FormGroup;
  public ratePackageName: string;
  public eur: number;
  public fineAmount: number;
  public changesSubmitted: boolean;
  public actionId: string;
  public initialized = false;
  public spaceModificationLabel: string;
  public displayMode: string;
  public multiSelectCheckboxArray = new Array<SpaceSummary>();
  public validSpaces = new Array<SpaceSummary>();

  public allSpacesReady: boolean = false;

  public actionsToHideEffectiveDate = [ActionConstants.RemoveSpaceId.toString(), ActionConstants.ChangeRatePackageId.toString()];
  public actionsToHideRatePackage = [ActionConstants.RemoveSpaceId.toString(), ActionConstants.ChangeRatePackageId.toString(), ActionConstants.ChangeContractTypeToConcessionId.toString(), ActionConstants.ReverseRatePackageChangeId.toString()];
  potentialCLZImpactActions = [ActionConstants.InstallMeterId, ActionConstants.InstallSpaceId, ActionConstants.ChangeRatePackageId, ActionConstants.UpdateMeterAttributesId, ActionConstants.RemoveSpaceId];


  inventoryChangeEffectiveDate?: Date;
  fineAmounts: FineAmount[];

  @Input() spaceInventoryChanges: SpaceInventoryChange[];
  @Input() workOrderAction: WorkOrderAction;
  @Input() meterId: string;
  @Input() contractType: string;
  @Input() historicalEffectiveDate: Date;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private _spaceService: SpaceService, private _fineAmountService: FineAmountService,
    private _ratePackageService: RatePackageService, private _loadingZoneImpactService: LoadingZoneImpactService, private _meterService: MeterService, private _blockService: BlockService) {

    this.spaceInventoryForm = this.formBuilder.group({
      spaceId: void 0,
      fineAmount: void 0
    });
  }

  ngOnInit() {
    this._fineAmountService.getAllFineAmounts()
    .subscribe(result => {
      this.fineAmounts = result.sort((a, b) => a.amount - b.amount);
      this.setFiniancialInfo();
    });

    this.actionId = this.workOrderAction.actionId.toString();

    this.displayMode = this.determineMode(this.actionId);

    if (this.displayMode === 'displaySpaceCheckboxes') {
      this.spaceModificationLabel = this.getSpacemodificationLabel(this.actionId);
    }

    if (this.potentialCLZImpactActions.includes(this.workOrderAction.actionId) && JSON.parse(this.workOrderAction.workOrderActionJson)[WorkorderActionJsonConstants.blockGuid] !== '') {
      const postBody = this.generateLoadingZoneImpactAssessmentBody(JSON.parse(this.workOrderAction.workOrderActionJson)[WorkorderActionJsonConstants.blockGuid], this.workOrderAction.actionId);
      this.workOrderAction.CLZImpactedSpaces = [];
    }

    this.setEffectiveDate();
    this.eur = this.spaceInventoryChanges[0].eur;

    this.getSpacesForMeter(this.meterId, this.historicalEffectiveDate)
        .subscribe(result => {
          this.validSpaces = this.filterInvalidSpaces(result);
        });

    if (this.meterId) {
      this._meterService.getMeterById(this.meterId, this.historicalEffectiveDate)
        .subscribe(result => {
          this.spaceInventoryChanges.forEach(spaceChange => {
            spaceChange.zoneDisplayId = result.zone;
            spaceChange.areaDisplayId = result.area;
            spaceChange.blockDisplayId = result.blockDisplayId;
            spaceChange.meterDisplayId = result.displayId;
          });
        });
    }
    else {
      const workOrderActionJsonObject = JSON.parse(this.workOrderAction.workOrderActionJson);
      this._blockService.getBlockById(workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid])
      .subscribe(result => {
        this.spaceInventoryChanges.forEach(spaceChange => {
          spaceChange.zoneDisplayId = result.zoneName;
          spaceChange.areaDisplayId = result.areaName;
          spaceChange.blockDisplayId = result.displayId;
        });
      });
    }

    this.changesSubmitted = this.spaceInventoryChanges.filter(spaceChange => {
      return spaceChange.status === 'Completed';
    }).length === this.spaceInventoryChanges.length;

    if (!this.changesSubmitted) {
      this.buildForm();
      this.initialized = true;
    }
  }

  private generateLoadingZoneImpactAssessmentBody(blockId: string, actionId: number): LoadingZoneImpactAssessmentPostBody {
    const postBody = new LoadingZoneImpactAssessmentPostBody();
    postBody.effectiveDate = this.historicalEffectiveDate ? this.historicalEffectiveDate : new Date();
    if (actionId === ActionConstants.RemoveSpaceId) {
      postBody.removedOriginBlockId = blockId;
    }
    else {
      postBody.originBlockId = blockId;
    }

    return postBody;
  }

  private getSpacesForMeter(meterId: string, historicalEffectiveDate?: Date) {
      return this._spaceService.getSpacesByMeterId(meterId, historicalEffectiveDate);
  }

  private setFiniancialInfo() {

      if (this.spaceInventoryChanges[0]
          && this.spaceInventoryChanges[0].fineAmountId) {
        this.fineAmount = this.fineAmounts.find(fineAmount => this.spaceInventoryChanges[0].fineAmountId === fineAmount.id).amount;
      }

      if (this.spaceInventoryChanges[0]
        && this.spaceInventoryChanges[0].ratePackageId
        && this.spaceInventoryChanges[0].ratePackageId !== StringExtensions.EmptyGuid) {
        this._ratePackageService
          .getRatePackageById(this.spaceInventoryChanges[0].ratePackageId)
          .subscribe(ratePackage => {
            this.ratePackageName = ratePackage.name;
          });
    }
  }

  public bubbleValidation(formIsValid: boolean) {
    this.workOrderAction.spaceInventoryChangesFormComplete = formIsValid && this.allSpacesReady;
  }

  public bubbleSpacesReady(spacesValid: boolean) {
    this.allSpacesReady = spacesValid;
    this.bubbleValidation(this.spaceInventoryForm.valid);
  }

  private determineMode(actionId: string) {
    const spaceModificationActions = [
      ActionConstants.ShiftSpaceId.toString(),
      ActionConstants.RemoveSpaceId.toString(),
      ActionConstants.ChangeRatePackageId.toString(),
      ActionConstants.ChangeContractTypeToConcessionId.toString()
    ];

    if (spaceModificationActions.includes(actionId)) {
      return 'displaySpaceCheckboxes';
    } else if (actionId === ActionConstants.ReverseRatePackageChangeId.toString()) {
      return 'reverseRatePackage';
    } else {
      return 'default';
    }
  }

  private filterInvalidSpaces(spaces: Array<SpaceSummary>) {
    const validSpacesForMeter = spaces.filter(space => {
      if (!space || !space.currentStatus) { return false; }
      if (space.currentMeterId !== this.meterId) { return false; }
      return true;
    }).sort((a, b) => Number.parseInt(a.displayId) - Number.parseInt(b.displayId));

    if (this.actionId === ActionConstants.ChangeContractTypeToConcessionId.toString()) {
      return [...validSpacesForMeter.filter(space => space.currentStatus.toLowerCase() === SpaceStatusConstants.reserved.toLowerCase())];
    }

    if (this.actionId === ActionConstants.RemoveSpaceId.toString()
        || this.actionId === ActionConstants.ShiftSpaceId.toString()) {

      return [...validSpacesForMeter.filter(space => space.currentStatus.toLowerCase() === SpaceStatusConstants.active.toLowerCase()
                                                  || space.currentStatus.toLowerCase() === SpaceStatusConstants.reserved.toLowerCase())];
    }

    if (this.actionId === ActionConstants.InstallSpaceId.toString()
        || this.actionId === ActionConstants.InstallMeterId.toString()) {
      return [...validSpacesForMeter.filter(space => space.currentStatus.toLowerCase() === SpaceStatusConstants.removed.toLowerCase())];
    }

    return validSpacesForMeter;
  }

  private getSpacemodificationLabel(actionId: string) {
    if (actionId === ActionConstants.ReverseRatePackageChangeId.toString()) { return 'Reverse Reserved Power'; }
    if (actionId === ActionConstants.RemoveSpaceId.toString()) { return 'Remove'; }
    if (actionId === ActionConstants.ChangeContractTypeToConcessionId.toString()
        || actionId === ActionConstants.ChangeRatePackageId.toString()) { return 'Change'; }
    return 'Shift';
  }

  setEffectiveDate() {
    if (this.spaceInventoryChanges[0]) {
      this.spaceInventoryChanges.forEach(space => {
        if (space.effectiveDate) {
          if (!this.inventoryChangeEffectiveDate && space.effectiveDate.toString() !== DateTimeUtility.MINDATE) {
            this.inventoryChangeEffectiveDate = space.effectiveDate;
          }
          else {
            if (this.inventoryChangeEffectiveDate < space.effectiveDate) {
              this.inventoryChangeEffectiveDate = space.effectiveDate;
            }
          }
        }
      });
    }
  }

  buildForm() {
    this.spaceInventoryForm = this.formBuilder.group({
      fineAmount: ['']
    });
    this.spaceInventoryChanges.forEach(spaceInventoryChange => {


      this.spaceInventoryForm.addControl(spaceInventoryChange.id, new FormControl);
      this.spaceInventoryForm.addControl(spaceInventoryChange.id + 'removedSpace', new FormControl);

      if (spaceInventoryChange.spaceDisplayId) {
        if ((!spaceInventoryChange.spaceId || spaceInventoryChange.spaceId === StringExtensions.EmptyGuid)
        || (spaceInventoryChange.spaceId && spaceInventoryChange.spaceId !== StringExtensions.EmptyGuid
            && this.historicalEffectiveDate)) {
          this.spaceInventoryForm.get(spaceInventoryChange.id).setValue(spaceInventoryChange.spaceDisplayId);
        } else {
          this.spaceInventoryForm.get(spaceInventoryChange.id + 'removedSpace').setValue(spaceInventoryChange.spaceDisplayId);
        }
      }

      this.spaceInventoryForm.get('fineAmount').setValue(spaceInventoryChange.fineAmountId);

      this.spaceInventoryForm.get(spaceInventoryChange.id).valueChanges.subscribe((displayId: string) => {
        spaceInventoryChange.spaceDisplayId = displayId;
      });
    });

    this.spaceInventoryForm.get('fineAmount').valueChanges.subscribe((fineAmount: string) => {
      this.spaceInventoryChanges.forEach(spaceInventoryChange => {
        spaceInventoryChange.fineAmountId = fineAmount;
      });
    });

    this.spaceInventoryForm.valueChanges.subscribe(() => {
      this.bubbleValidation(this.spaceInventoryForm.valid);
    });
  }

}
