import { InventoryQueryStringBuilder } from './../inventory-table/inventory-query-string-builder';
import { InventoryListJsonConverter } from './../inventory-table/inventory-list-json-converter';
import { InventoryFilterRepresentation } from './../inventory-filter/inventory-filter-representation';
import { Injectable } from '@angular/core';
import { Block, BlockPaginationResult } from './block';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BlockJsonConverter } from './block-json-converter';
import { InventoryHttpService } from '../../inventory-http.service';
import { AssociatedBlockRelatedBlock } from '../../work-orders/associated-block-details/associated-block-related-block';
import { LinkedBlock } from '../../admin/linked-blocks/linked-block';
import { WorkOrdersService } from '../../work-orders/services/work-orders.service';

@Injectable()
export class BlockService {
    constructor(private http: HttpClient, private inventoryHttp: InventoryHttpService, private workOrderService: WorkOrdersService) { }

    public getBlockById(id: string): Observable<Block> {
        return this.http.get('/Blocks/' + id)
          .pipe(
            map(response => {
              return response != null ? BlockJsonConverter.jsonToBlock(response) : null;
            }),
            catchError(error => this.handleError(error))
          );
    }

    public deleteLinkFromBlock(firstLinkedBlockId: string, secondLinkedBlockId: string): Observable<Block> {
      return this.inventoryHttp.nonPostDelete('/blocks/linkedBlocks/' + firstLinkedBlockId + '/' + secondLinkedBlockId, null)
        .pipe(catchError(error => this.handleError(error)));
    }

    public getBlockAuditHistory(id: string): Observable<any> {
        return this.http.get('/Blocks/audit/' + id)
          .pipe(catchError(error => this.handleError(error)));
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    public calculateSideOfStreet(streetDirection, addressNumber) {
        if (streetDirection === 'N' || streetDirection === 'S') {
            if (addressNumber % 2 === 0) {
                return 'W';
            }
            else {
                return 'E';
            }
        }
        else if (streetDirection === 'E' || streetDirection === 'W') {
            if (addressNumber % 2 === 0) {
                return 'N';
            }
            else {
                return 'S';
            }
        }
    }
    public getAllBlocks(orderByCategory: string = 'currentStatus', isAscending: boolean = true,
    skip: number = 25, take: number = 25, filter: InventoryFilterRepresentation): Observable<BlockPaginationResult> {
    const order = isAscending ? 'asc' : 'desc';

    return new Observable<BlockPaginationResult>(obs => {
        if(filter.FilterByWorkOrder){
            this.workOrderService.getWorkOrderActionIdsByWorkOrderDisplayId(filter.WorkOrder)
            .subscribe(workOrderActionIdsResponse=>{
                var workOrderActionIds = workOrderActionIdsResponse.join(',');
                if(workOrderActionIds){
                    filter.WorkOrderActionIds = workOrderActionIds;
                }
                return this.sendGetAllBlocksRequest(orderByCategory,skip,take,filter,order).subscribe(x=>obs.next(x));
            });
        }else{
            return this.sendGetAllBlocksRequest(orderByCategory,skip,take,filter,order).subscribe(x=>obs.next(x));
        }
    });
}

    private sendGetAllBlocksRequest(orderByCategory:string,
    skip: number, take: number, filter: InventoryFilterRepresentation, order:string){

        const filterQueryString = InventoryQueryStringBuilder.createFilterQueryString(filter, false);
        const endpoint = `/blocks?orderby=${orderByCategory}%20${order}&skip=${skip}&top=${take}&${filterQueryString}`;

        return this.http.get(endpoint)
          .pipe(catchError(error => this.handleError(error)));
    }

    public getAllLinkedBlocksForBlockWithFilter(orderByCategory: string = 'currentStatus', isAscending: boolean = true,
        skip: number = 25, take: number = 25, filter: InventoryFilterRepresentation): Observable<any[]> {
        const order = isAscending ? 'asc' : 'desc';

        const filterQueryString = InventoryQueryStringBuilder.createFilterQueryString(filter, false);
        const endpoint = `/blocks/linkedBlocks?orderby=${orderByCategory}%20${order}&skip=${skip}&top=${take}&${filterQueryString}`;

        return this.http.get(endpoint,)
          .pipe(catchError(error => this.handleError(error)));
    }

    public getAllLinkedBlocksForSingleBlock(blockId: string): Observable<AssociatedBlockRelatedBlock[]> {
      const endpoint = '/blocks/linkedBlocks/' + blockId;
      return this.http.get(endpoint)
        .pipe(catchError(error => this.handleError(error)));
    }

    public SaveLinkedBlocks(linkedBlocks: LinkedBlock[]): Observable<any[]> {
      return this.inventoryHttp
        .put('/blocks/linkedBlocks/' + linkedBlocks[0].FirstLinkedBlockId, linkedBlocks)
        .pipe(catchError(error => this.handleError(error)));
    }

    public updateBlockAttributes(block: Block): Observable<Block> {
        return this.http.put('/blocks/updateAttributes/', block)
          .pipe(catchError(error => this.handleError(error)));
    }
}
