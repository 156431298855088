// This file corresponds to monthly-true-up-calculation-datasource-base.ts

import { CollectionsDetailBase } from './collections-list-column-def';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';

export class CollectionsListDataSourceBase extends DataSource<CollectionsDetailBase> {

    constructor(private Observable: Observable<Array<CollectionsDetailBase>>) {
        super();
    }

    public connect(): Observable<Array<CollectionsDetailBase>> {
      return this.Observable;
    }
    public disconnect() { }
}

