import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { Injectable } from '@angular/core';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';
import { SpaceAudit } from '../../reports/audit/space-audit';
import { HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import { TokenCache } from '../../security/token-cache';
import { Observable } from 'rxjs';
import { MeterAudit } from '../../reports/audit/meter-audit';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AuditReportService extends ReportingHttpService {

  constructor(
    backend: HttpBackend, options: HttpRequestOptions, tokenCache: TokenCache, fileService: FileDownloadService)
  { super(backend, options, tokenCache, fileService); }

  public RequestMeterReport(startDate: Date, endDate: Date) {

    const start = DateTimeUtility.parseDateString(startDate, 'M/D/YYYY');
    const end = DateTimeUtility.parseDateString(endDate,'M/D/YYYY');

    const endpoint = `/audit/meter/export/${start}/to/${end}`;

    return this.post(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public RequestMeterAuditHistory(meterId: string) : Observable<MeterAudit[]>{

    const endpoint = `/audit/meter/${meterId}`;

    return this.get(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public RequestSpaceReport(startDate: Date, endDate: Date) {

    const start = DateTimeUtility.parseDateString(startDate, 'M/D/YYYY');
    const end = DateTimeUtility.parseDateString(endDate, 'M/D/YYYY');

    const endpoint = `/audit/space/export/${start}/to/${end}`;

    return this.post(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public RequestSpaceAuditHistory(spaceId: string) : Observable<SpaceAudit[]> {

    const endpoint = `/audit/space/${spaceId}`;

    return this.get(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  //Correlates to QueueItemTypeMetaData in Reporting API
  public static SPACE_AUDIT_KEY: string = 'SPACE_AUDIT';
  public static METER_AUDIT_KEY: string = 'METER_AUDIT';

  public DownloadMeterReport(key: string) {
    return super.DownloadReport(AuditReportService.METER_AUDIT_KEY, key, 0);
  }

  public DownloadSpaceReport(key: string) {
    return super.DownloadReport(AuditReportService.SPACE_AUDIT_KEY, key, 0);
  }
}
