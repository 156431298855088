import { ActionFieldOption } from './action-field-option';

export class ActionField {
    conditionalFieldId?: number;

    conditionalValue?: string;

    dateAdded: string;

    dateRemoved: string;

    displayField?: boolean;

    fieldBehavior: string;

    fieldBehaviorId: number;

    fieldId: number;

    fieldName: string;

    fieldOptions?: Array<ActionFieldOption>;

    fieldType: string;

    id: number;

    inputRow: number;

    inputWidth?: number;

    order: number;

    value: any;
}

export class ActionFieldType {
    public static CheckBox = 'checkbox';
    public static Comments = 'Comments';
    public static InternalComments = 'Internal Comments';
    public static Date = 'date';
}
