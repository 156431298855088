import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderHttpService } from './work-order-http.service';
import { LetterOfDirectionResponse, WorkOrderResponse, WorkOrderActionResponse} from '../models/approval-models';
import {map, catchError} from 'rxjs/operators';

@Injectable()
export class WorkOrderResponseService {
  constructor(private workorderHttp: WorkOrderHttpService) { }

  public submitLetterOfDirectionResponse(workOrderId:string, LetterOfDirectionResponseApiModel: LetterOfDirectionResponse):Observable<Object> {
      return this.workorderHttp.post(`/workOrders/${workOrderId}/letterOfDirections/${LetterOfDirectionResponseApiModel.letterOfDirectionId}/response`, LetterOfDirectionResponseApiModel)
        .pipe(
          map(response => response),
          catchError(error => Observable.throwError(error))
        );
  }

  public submitWorkOrderActionResponse(workOrderActionId:string, workOrderActionResponse: WorkOrderActionResponse):Observable<any> {
    return this.workorderHttp.post(`/workOrderActions/${workOrderActionId}/response`, workOrderActionResponse)
      .pipe(
        map(response => response),
        catchError(error => Observable.throwError(error))
      );
  }

  public submitWorkOrderResponse(workOrderId:string, workOrderResponse: WorkOrderResponse):Observable<any> {
    return this.workorderHttp.post(`/workOrders/${workOrderId}/response`, workOrderResponse)
      .pipe(
        map(response => response),
        catchError(error => Observable.throwError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
