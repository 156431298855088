
import {of as observableOf,  Observable } from 'rxjs';
import { ValidatorFn, AbstractControl } from '@angular/forms';

import { BlockService } from './../../../../inventory-management/blocks/blocks.service';
import { FilterOperator } from './../../../../core/query-strings/query-string-builder';
import { InventoryFilterRepresentation } from '../../../../inventory-management/inventory-filter/inventory-filter-representation';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { ActionFormWithValidation } from '../forms/action-form-with-validation';
import {map, catchError} from 'rxjs/operators';

export class InventoryIdExistsValidation {
 static doesNotExist = { MeterIdDoesNotExist : true  };

  static handleError(error: any): Promise<any> {
    return Promise.resolve(InventoryIdExistsValidation.doesNotExist);
  }

  static MeterIdDoesNotExistValidator(_meterService: MeterService, isHistoricalChange: (component: ActionFormWithValidation) => boolean, historicalEffectiveDate: (component: ActionFormWithValidation) => Date, component: ActionFormWithValidation): ValidatorFn {

    if (!isHistoricalChange) {
      isHistoricalChange = (component: ActionFormWithValidation) => false;
    }
    if (!historicalEffectiveDate) {
      historicalEffectiveDate = (component: ActionFormWithValidation) => null;
    }
    return (control: AbstractControl) => {
      if (control.value.toString().length > 5) {
          return _meterService.getMeterByDisplayId(control.value, historicalEffectiveDate(component))
            .pipe(
              map(result => {
            return { };
            },
            error => {
              return InventoryIdExistsValidation.doesNotExist;
            }),
            catchError((error) => this.handleError(error))
            );
        }
      else {
        return observableOf({ MeterIdTooShort : true  });
      }
    };
  }

  static BlockIdDoesNotExistValidator(_blockService: BlockService): ValidatorFn {
    return (control: AbstractControl) => {
        const filter = new InventoryFilterRepresentation();
        filter.BlockId = control.value;
        filter.BlockDisplayIdFilterOperator = FilterOperator.Equal;

        return _blockService.getAllBlocks('currentStatus', true, 0, 1, filter).pipe(map(result => {
          return result.items.length > 0 ? null : { MeterIdDoesNotExist : true };
        }));
      };
  }
}

export function MeterIdDoesNotExistValidator(): ValidatorFn {
  return (control: AbstractControl): {} => {
    return { MeterIdDoesNotExist : true };
  };
}

export function MeterIdIsTooShortValidator(): ValidatorFn {
  return (control: AbstractControl): {} => {
    return { MeterIdTooShort : true };
  };
}
