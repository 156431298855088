import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment, SecurityConstants } from '../environment';
import { WorkOrderRoleConstants } from './login/securityConstants';

@Injectable()
export class TokenCache {

  roleKey = 'role';
  bearerToken = 'CpmBearerToken';
  workOrderRoleLocalStorageKey = 'workOrderRole';
  userNameKey = 'userName';
  fullNameKey = 'fullName';

  private _tokenChange = new Subject();

  get onTokenChange() {
    return this._tokenChange.asObservable();
  }

  get tokenIsValid() {
    return this.getBearerToken() && parseInt(localStorage.getItem(environment.envName + 'tokenExpiration')) > Date.now();
  }

  public getBearerToken(): string {
    return localStorage.getItem(environment.envName + this.bearerToken);
  }

  public getWorkOrderUserRole(): string {
    return localStorage.getItem(environment.envName + this.workOrderRoleLocalStorageKey);
  }

  public setBearerToken(accessToken, username) {
    localStorage.removeItem(environment.envName + this.bearerToken);
    localStorage.removeItem(environment.envName + this.userNameKey);
    localStorage.removeItem(environment.envName + this.fullNameKey);
    localStorage.setItem(environment.envName + this.bearerToken, `Bearer ${accessToken}`);
    localStorage.setItem(environment.envName + this.userNameKey, username);

    const tokenDataString = accessToken.split('.')[1];
    const tokenData = window.atob(tokenDataString);
    const token = JSON.parse(tokenData);
    var roles = new Array<String>();
    if(Array.isArray(token.role))
    {
      roles = token.role;
    }
    else{
      roles = [token.role];
    }

    const workOrderUserRole = WorkOrderRoleConstants.getWorkOrderUserRole(roles);

    localStorage.removeItem(environment.envName + this.workOrderRoleLocalStorageKey);
    localStorage.setItem(environment.envName + this.workOrderRoleLocalStorageKey, workOrderUserRole);
    localStorage.setItem(environment.envName + this.roleKey, JSON.stringify(roles));
    localStorage.setItem(environment.envName + 'tokenExpiration', (token.exp * 1000).toString());
    localStorage.setItem(environment.envName + this.fullNameKey, token.FullName);
    this._tokenChange.next();
  }

  public getUserRoles():Array<string> {
    var roles = localStorage.getItem(environment.envName + this.roleKey);
    return JSON.parse(roles);
  }

  public getUserName():string {
    var name = localStorage.getItem(environment.envName + this.userNameKey);
    return name;
  }

  public getUserFullName():string {
    var name = localStorage.getItem(environment.envName + this.fullNameKey);
    return name;
  }

  public checkForCPMUser():boolean {
    var roles = this.getUserRoles();
    if(!roles || roles.length < 1)
    {
      return false;
    }
    return roles.filter(userRole => userRole === SecurityConstants.CPMAdmin 
                      || userRole === SecurityConstants.CPMElevatedApprover
                      || userRole === SecurityConstants.CPMUser).length > 0;
  }

  public checkForCPMAdmin():boolean {
    var roles = this.getUserRoles();
    if(!roles || roles.length < 1)
    {
      return false;
    }
    return roles.filter(userRole => userRole === SecurityConstants.CPMAdmin).length > 0;
  }

  public checkForLAZUser():boolean {
    var roles = this.getUserRoles();
    if(!roles || roles.length < 1)
    {
      return false;
    }
    return roles.filter(userRole => userRole === SecurityConstants.LAZAdmin
                      || userRole === SecurityConstants.LAZUser
                      || userRole === SecurityConstants.BackOfficeSupervisor).length > 0;
  }

  public checkForCityUser() :boolean{
    var roles = this.getUserRoles();
    if(!roles || roles.length < 1)
    {
      return false;
    }
    return roles.filter(userRole => userRole === SecurityConstants.CityApprover
                      || userRole === SecurityConstants.CityUser ).length > 0;
  }

  isAdminUser():boolean {
    var roles = this.getUserRoles();
    if(!roles || roles.length < 1)
    {
      return false;
    }
    return roles.filter(userRole => userRole === SecurityConstants.CPMAdmin 
                      || userRole === SecurityConstants.CPMElevatedApprover).length > 0;
  }

  isElevatedApproverUser():boolean {
    var roles = this.getUserRoles();
    if(!roles || roles.length < 1)
    {
      return false;
    }
    return roles.filter(userRole => userRole === SecurityConstants.CPMElevatedApprover).length > 0;
  }
}
