import { SpaceClosureByWarehouse } from './../spaces/space-closures/space-closure-warehouse';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { WorkOrderHistoryJsonConverter } from './../work-order-history/work-order-history-json-converter';
import { WorkOrderHistory } from './../work-order-history/work-order-history';
import { InventoryQueryStringBuilder } from './../inventory-table/inventory-query-string-builder';
import { InventoryListJsonConverter } from './../inventory-table/inventory-list-json-converter';
import { Meter } from './meter';
import { MeterPaginationResult } from './meter-summary';
import { InventoryFilterRepresentation } from '../inventory-filter/inventory-filter-representation';
import { MeterJsonConverter } from './meter-json-converter';
import { TrueUpHttpService } from './../../true-up-http.service';
import { InventoryHttpService } from '../../inventory-http.service';
import { DateStringBuilder } from '../../core/query-strings/date-string-builder';
import { ExportSelectRepresentation } from '../inventory-filter/export-representations';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { SpaceClosureByDate } from '../spaces/space-closures/space-closure-date';
import { WorkOrdersService } from '../../work-orders/services/work-orders.service';
import { TimeZoneConverter } from '../../core/time-zone/time-zone-converter';

@Injectable()
export class MeterService {

    constructor(private http: InventoryHttpService, private trueUpDataHttpService: TrueUpHttpService, private workOrderService: WorkOrdersService) { }

    public getMeterById(id: string, date: Date = null): Observable<Meter> {

        let endpoint = `/meters/${id}`;
        if (!DateStringBuilder.dateIsNullOrDefault(date)) {
            const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
            const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
            const queryString = this.builDateQueryString(dateString);
            endpoint = `${endpoint}${queryString}`;
        }
        return this.http.get(endpoint, null)
          .pipe(
            map(data => data != null ? MeterJsonConverter.jsonToMeter(data) : null),
            catchError(error => this.handleError(error))
          );
    }

    public getMeterByDisplayId(displayId: string, date: Date = null): Observable<Meter> {
        let endpoint = `/meters/displayId/${displayId}`;
        if (!DateStringBuilder.dateIsNullOrDefault(date)) {
            const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
            const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
            const queryString = this.builDateQueryString(dateString);
            endpoint = `${endpoint}${queryString}`;
        }
        return this.http.get(endpoint, null)
            .pipe(
              map(data => data != null ? MeterJsonConverter.jsonToMeter(data) : null),
              catchError(error => this.handleError(error))
            );
    }

    public exportMeters(orderByCategory: string = 'currentStatus', isAscending: boolean = true, filter: InventoryFilterRepresentation, select: ExportSelectRepresentation): Observable<any> {
        const order = isAscending ? 'asc' : 'desc';

        const filterQueryString = InventoryQueryStringBuilder.createFilterQueryString(filter, true);
        const selectQueryString = select.createEncodedSelect();
        const endpoint = `/meters/export?orderby=${orderByCategory}%20${order}&${filterQueryString}&select=${selectQueryString}`;

        return this.http.getFile(endpoint, null)
          .pipe(catchError(error => this.handleError(error)));
    }

    public getAllMeters(orderByCategory: string = 'currentStatus', isAscending: boolean = true,
        skip: number = 25, take: number = 25, filter: InventoryFilterRepresentation): Observable<MeterPaginationResult> {
        const order = isAscending ? 'asc' : 'desc';

        return new Observable<MeterPaginationResult>(obs => {
            if(filter.FilterByWorkOrder){
                this.workOrderService.getWorkOrderActionIdsByWorkOrderDisplayId(filter.WorkOrder)
                .subscribe(workOrderActionIdsResponse=>{
                    var workOrderActionIds = workOrderActionIdsResponse.join(',');
                    if(workOrderActionIds){
                        filter.WorkOrderActionIds = workOrderActionIds;
                    }
                    return this.sendGetAllMetersRequest(orderByCategory,skip,take,filter,order).subscribe(x=>obs.next(x));
                })
            }else{
                return this.sendGetAllMetersRequest(orderByCategory,skip,take,filter,order).subscribe(x=>obs.next(x));
            }
        });



    }

    private sendGetAllMetersRequest(orderByCategory:string,
        skip: number, take: number, filter: InventoryFilterRepresentation, order:string){

        const filterQueryString = InventoryQueryStringBuilder.createFilterQueryString(filter, true);
        const endpoint = `/meters?orderby=${orderByCategory}%20${order}&skip=${skip}&top=${take}&${filterQueryString}`;

        return this.http.get(endpoint, null)
          .pipe(
            map(data => data != null ? InventoryListJsonConverter.jsonToInventoryListPaginationResult(data) : null),
            catchError(error => this.handleError(error))
          );
    }

    public getAllMetersOnBlock(blockId: string, date: Date): Observable<Meter[]> {

        const convertedDate = TimeZoneConverter.EnsureChicagoTime(date);
        const dateString = DateStringBuilder.buildStringFromDate(convertedDate);
        const queryString = this.builDateQueryString(dateString);

        const endpoint = `/blocks/${blockId}/meters${queryString}`;
        return this.http.get(endpoint, null)
          .pipe(
            map(data => data != null ? InventoryListJsonConverter.jsonToInventoryListPaginationResult(data) : null),
            catchError(error => this.handleError(error))
          );
    }

    public getMeterWorkOrderHistories(meterId: string): Observable<WorkOrderHistory[]> {
        return this.http.get('/meters/' + meterId + '/workOrderHistories', null)
          .pipe(
            map(data => data != null ? WorkOrderHistoryJsonConverter.jsonToWorkOrderHistoryList(data) : null),
            catchError(error => this.handleError(error))
          );
    }

    public validateNewRatePackage(meterId: string, ratePackageId: string): Observable<any> {
        return this.http.post('/meters/' + meterId + '/validateNewRatePackage/' + ratePackageId, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    public getInoperableTimelineForMeter(meterId: string): Observable<any[]> {
        return this.trueUpDataHttpService.get('/Occupancy/InoperableTimeline/' + meterId, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    public updateMeterAttributes(meter: Meter): Observable<Meter> {
        return this.http.put('/meters/updateAttributes/', meter)
          .pipe(catchError((error) => this.handleError(error)));
    }

  public getClosuresByWarehouseId(meterId: string, pageSize: number,
    page: number, year?: number, quarter?: number): Observable<PaginatedGetResult<SpaceClosureByWarehouse>> {

    var queryString = '/Occupancy/closure/meter/'
      + meterId +
      '/byclosure/'
      + pageSize +
      '/'
      + page;

    if (year && quarter) {
      queryString = queryString + '?year=' + year + '&quarter=' + quarter;
    }

    return this.trueUpDataHttpService.get(queryString, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public getClosuresByDate(meterId: string, pageSize: number,
    page: number, year?: number, quarter?: number): Observable<PaginatedGetResult<SpaceClosureByDate>> {

    var queryString = '/Occupancy/closure/meter/'
      + meterId +
      '/bydate/'
      + pageSize +
      '/'
      + page;

    if (year && quarter) {
      queryString = queryString + '?year=' + year + '&quarter=' + quarter;
    }

    return this.trueUpDataHttpService.get(queryString, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  //Useful to make sure the lambda is up before calling a long-running service
  public primeTrueUpService(): Observable<Date> {
    return this.trueUpDataHttpService.get('/time/start/', null)
      .pipe(
        map(data => data != null ? new Date(data.toString()) : null),
        catchError(error => this.handleError(error))
      );
  }

    builDateQueryString(dateString: string): string {
        if (!dateString) {
            return '';
        }
        return `?date=${dateString}`;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }
}
