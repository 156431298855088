export class WorkOrderHistory {
    spaceId: string;

    meterId: string;

    effectiveTimeStamp: string;

    endTimeStamp: string;

    effectiveDate: Date;

    endDate: Date;

    workOrderId: string;

    workOrderActionId: string;

    workOrderName: string;

    changeType: string;

    expectedUtilizationRate: number;

    isReservedPower: boolean;

    newValueId: string;

    newValueName: string;

    meterWorkOrderHistories: WorkOrderHistory[];

    isExecuted: boolean;

    isHistoricalChange: boolean;
}
