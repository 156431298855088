import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Column } from '../inventory-list/inventory-list-column-def';
import { SelectedColumn } from './selected-column';

@Component({
    selector: 'app-column-customizer-dialog',
    templateUrl: './column-customizer-dialog.component.html',
    styleUrls: ['./column-customizer-dialog.component.css']
})
export class ColumnCustomizerDialogComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ColumnCustomizerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    allColumns: Array<Column>;
    defaultColumns: Array<Column>;
    displayedColumns: Array<string>;
    selectedColumns: Array<SelectedColumn> = new Array<SelectedColumn>();

    ngOnInit() {
        this.allColumns = this.data.allColumns;
        this.defaultColumns = this.data.defaultColumns;
        this.displayedColumns = this.data.displayedColumns;

        this.allColumns.forEach((allColumn: Column) => {
            const newSelectedColumn = new SelectedColumn();
            newSelectedColumn.column = allColumn;
            var findDisplayedColumn = this.displayedColumns.find(displayedColumn => {
                return displayedColumn == allColumn.constant;
            });
            if(findDisplayedColumn !== null && findDisplayedColumn !== undefined) {
                newSelectedColumn.selected = true;
                this.selectedColumns.push(newSelectedColumn);
            }
            else {
                newSelectedColumn.selected = false;
                this.selectedColumns.push(newSelectedColumn);
            }
            
        });
    }

    selected(column: Column): boolean {
        var findSelectedColumn = this.selectedColumns.find(x => x.column === column);
        if (findSelectedColumn !== undefined && findSelectedColumn.selected) {
            return true;
        }
        else { return false; }
    }

    selectColumn(event, selectedColumn: Column) {
        const column = this.selectedColumns.find(x => x.column === selectedColumn);
        if (event.checked) {
            column.selected = true;
        }
        else {
            column.selected = false;
        }
    }

    orderUp(column: Column) {
        const oldSelectedIndex = this.selectedColumns.findIndex(x => x.column === column);
        const newSelectedIndex = this.selectedColumns.findIndex(x => x.column === column) - 1;
        if (oldSelectedIndex !== 0 && oldSelectedIndex !== -1) {
            this.selectedColumns.splice(newSelectedIndex, 0, this.selectedColumns.splice(oldSelectedIndex, 1)[0]);
        }

        const oldAllIndex = this.allColumns.findIndex(x => x === column);
        const newAllIndex = this.allColumns.findIndex(x => x === column) - 1;
        if (oldAllIndex !== 0 && oldAllIndex !== -1) {
            this.allColumns.splice(newAllIndex, 0, this.allColumns.splice(oldAllIndex, 1)[0]);
        }
    }

    orderDown(column: Column) {
        let oldIndex = this.selectedColumns.findIndex(x => x.column === column);
        let newIndex = this.selectedColumns.findIndex(x => x.column === column) + 1;
        if (oldIndex !== this.selectedColumns.length - 1) {
            this.selectedColumns.splice(newIndex, 0, this.selectedColumns.splice(oldIndex, 1)[0]);
        }

        oldIndex = this.allColumns.findIndex(x => x === column);
        newIndex = this.allColumns.findIndex(x => x === column) + 1;
        if (oldIndex !== this.allColumns.length - 1) {
            this.allColumns.splice(newIndex, 0, this.allColumns.splice(oldIndex, 1)[0]);
        }
    }

    topArrow(column: Column): boolean {
        if (this.allColumns.findIndex(x => x === column) === 0) {
            return true;
        }
        else { return false; }
    }

    bottomArrow(column: Column): boolean {
        if (this.allColumns.findIndex(x => x === column) === this.allColumns.length - 1) {
            return true;
        }
        else { return false; }
    }

    applyChanges() {
        const columnsToReturn = new Array<Column>();
        this.selectedColumns.forEach(column => {
            if (column.selected) { columnsToReturn.push(column.column); }
        });
        this.dialogRef.close(columnsToReturn);
    }

    applyDefault() {
        this.dialogRef.close(this.defaultColumns);
    }

    cancel() {
        this.dialogRef.close([]);
    }
}
