import { Injectable, ErrorHandler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map, catchError } from "rxjs/operators";
import { Observable } from 'rxjs';

import { InventoryHttpService } from '../../inventory-http.service';
import { SystemUser } from './system-user';

@Injectable()
export class LoginService {
    public redirectUrl: string;

    constructor(private httpService: InventoryHttpService, private errorHandler: ErrorHandler) { }

    public login(username: string, password: string): Observable<string> {
        const body = `username=${username}&password=${password}&grant_type=password`;

        let options = {
          headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
        };

        return this.httpService.login('token', body, options)
          .pipe(
            map(result => result != null ? this.jsonToAccessToken(result) : null),
            catchError((error) => this.handleError(error))
          );
    }

    public getUsers(): Observable<SystemUser[]> {
        return this.httpService.getUsers('', null)
          .pipe(catchError(
            error => {
              if(error.status == 401){
                this.errorHandler.handleError('Current user is not allowed to retrieve user profiles.');
                var failedUser = new SystemUser();
                failedUser.FullName = 'Not Authorized';
                return Promise.resolve([failedUser]);
              }
              else{
                return this.handleError(error);
              }
            }
            ));
    }

    public getUsersByRole(role: string): Observable<SystemUser[]> {
        return this.httpService.getUsersByRole('', role, null)
          .pipe(map(response => response),
            catchError(
              error => {
                if (error.status === 401) {
                  this.errorHandler.handleError('Current user is not allowed to retrieve user profiles.');
                  var failedUser = new SystemUser();
                  failedUser.FullName = 'Not Authorized';
                  return Promise.resolve([failedUser]);
                }
                else {
                  return this.handleError(error);
                }
              }
              ));
    }


  jsonToAccessToken(json: any): string {
        if (json['access_token']) {
            return json['access_token'];
        }

        return '';
    }

    private handleError(error: any): Promise<any> {
        this.errorHandler.handleError(error.status + ' ' + error.statusText);
        return Promise.reject(error.message || error);
    }
}
