import { SpaceTrueUpDetailDataSource } from "./space-true-up-detail-datasource";
import { ErrorHandler, Component, OnInit } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpaceTrueUpDetailService } from "./space-true-up-detail.service";
import { MonthlyTrueUpCalculationComponentBase } from "../monthly-calculations/monthly-true-up-calculation-component-base";
import { SpaceTrueUpDetail } from "./space-true-up-detail";
import { WorkOrdersService } from "../../work-orders/services/work-orders.service";

@Component({
  selector: 'space-true-up-detail',
  templateUrl: './space-true-up-detail.component.html',
  styleUrls: ['./space-true-up-detail.component.css']
})
export class SpaceTrueUpDetailComponent extends MonthlyTrueUpCalculationComponentBase<SpaceTrueUpDetail> implements OnInit {
  dataSource: SpaceTrueUpDetailDataSource;
  hideFilter: boolean = false;
  
constructor(errorHandler: ErrorHandler, spaceTrueUpDetailService:SpaceTrueUpDetailService, workOrderService: WorkOrdersService, public snackBar: MatSnackBar) {
  
  super(errorHandler,spaceTrueUpDetailService, workOrderService, snackBar);
  
  }

  ngOnInit() {
    if(this.filterRepresentation && this.filterRepresentation.SpaceId) {
      this.hideFilter = true;
    }

    super.ngOnInit();
  }
}

