import { Injectable } from '@angular/core';
import { TrueUpHttpService } from '../../true-up-http.service';
import { ReportingQuarter } from './models/reporting-quarter';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ReportingQuarterService {

  constructor(private trueUpHttpService: TrueUpHttpService) { }

  public getReportingQuarters(): Observable<ReportingQuarter[]> {
    const endpoint = '/TrueUpCalculationRun/ReportingQuarters';

    return this.trueUpHttpService.get(endpoint, null)
      .pipe(map(response =>
        response != null ? response : ReportingQuarter[0]
      ),
      catchError((error) => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
