import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkOrder } from '../../models/work-order';

@Component({
    selector: 'app-work-order-cancel-confirmation-dialog',
    templateUrl: './work-order-cancel-confirmation.html',
    styleUrls: ['./work-order-cancel-confirmation.css']
})
export class WorkOrderCancelConfirmation {

    constructor(public dialogRef: MatDialogRef<WorkOrderCancelConfirmation>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.actionType = data.actionType;
        this.workOrder = data.workOrder;
    }

    actionType: string;
    reason: string;
    workOrder: WorkOrder;

    submit() {
      if (this.reason) {
        this.dialogRef.close(this.reason);
      }
    }
}
