import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { InventoryFilterRepresentation } from '../../../inventory-management/inventory-filter/inventory-filter-representation';

@Component({
  selector: 'app-inventory-select-dialog',
  templateUrl: './inventory-select-dialog.component.html',
  styleUrls: ['./inventory-select-dialog.component.css']
})
export class InventorySelectDialogComponent implements OnInit {

  context: string;
  meterId: string;
  blockId: string;
  filter: InventoryFilterRepresentation;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.context = this.data.context;
    this.meterId = this.data.meterId;
    this.blockId = this.data.blockId;
    this.filter = this.data.filter;
  }

}
