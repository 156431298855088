
import {of as observableOf,  Observable } from 'rxjs';


import { DataSource } from '@angular/cdk/collections';
import { SpaceCollection } from './models/space-collection';

export class MeterSpaceTrueUpDataSource extends DataSource<any> {
  public spaceDistributions: SpaceCollection[] = [];
  constructor(spaceCollections: SpaceCollection[]) {
    super();
    this.spaceDistributions = spaceCollections;
  }

  connect(): Observable<SpaceCollection[]> {
    const displayDataChanges = [
      this.spaceDistributions,
    ];
    return observableOf(this.spaceDistributions);
  }
  disconnect() { }
}
