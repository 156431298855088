
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { WorkOrder } from './../../../models/work-order';
import { ActionConstants } from './../../action-constants';
import { Validators } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { InventoryIdExistsValidation } from './../form-field-validators/inventory-id-validators';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { RemovingLastSpace } from './../../../models/warning-constants';
import { RemoveSpaceConstants } from '../../../action-field/action-field-constants';
import { RemoveSpaceTooManySpacesValidator, RemoveSpaceMoreThanZeroSpacesValidator } from './../form-field-validators/remove-space-validators';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from './../../../action-field/workorder-action-json-constants';
import { HistoricalMeterForm } from './historical-meter-form';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { LoadingZoneImpactService } from '../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { Meter } from '../../../../inventory-management/meters/meter';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export class RemoveSpaceForm extends HistoricalMeterForm implements OnDestroy {

  meterChangeSubscription: Subscription;

  ngOnDestroy(): void {
    if(this.meterChangeSubscription){
      this.meterChangeSubscription.unsubscribe();
    }
  }

  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, loadingZoneImpactService: LoadingZoneImpactService,
    historicalDate: Date, previousActivationEffectiveDate: Date, overrideAsCity: boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, loadingZoneImpactService, historicalDate, previousActivationEffectiveDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this.onChanges();
  }

  private onChanges() {
    this.initializeFormForLoadingZoneImpacts(ActionConstants.RemoveSpaceId);
    this.setInitialForm();
    this.setNumberOfSpacesWarnings();

    if (this.isCityUser()) {
      this.setCityFormValidation();
    }
    else {
      this.SetCpmLazFormValidation();
    }
  }

  private setInitialForm(): void {

    this.meterChangeSubscription = this.onSelectedMeterUpdated.subscribe(meter =>{
      if (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
        this.setHistoricalMeterConditionals(RemoveSpaceConstants.MeterSelect, WorkorderActionJsonConstants.meterGuid, meter.displayId);
      }
      else {
        this.setNumberOfSpacesValidator();
      }
    });

    if (this.workOrderActionJsonObject) {
      var meter = new Meter();

      if (this.isCityUser()) {
        this.setMeterTextField(RemoveSpaceConstants.MeterTextId, RemoveSpaceConstants.MeterStreetNumber);
        meter.displayId = this.workOrderActionJsonObject[RemoveSpaceConstants.MeterTextId];
      }
      else {
        this.setMeterSelectIdBasedOnGuid(RemoveSpaceConstants.MeterSelect);
        meter.displayId = this.workOrderActionJsonObject[RemoveSpaceConstants.MeterSelect];
      }

      //kicks off validation of number of spaces
      this.onSelectedMeterUpdated.next(meter);
    }
    else {
      this.disableControl(RemoveSpaceConstants.NumberOfSpaces);
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.blockGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.meterGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
    }

  }

  private setCityFormValidation(): void {
    if (!this.hasHistoricalEffectiveDate) {
      this.setAsyncValidator(RemoveSpaceConstants.MeterTextId, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, this.getHasHistoricalEffectiveDate, this.getHistoricalEffectiveDate, this));
    }

    //distinct keeps this from firing needlessly when we submit
    this.subscribeToControl(RemoveSpaceConstants.MeterTextId).pipe(distinctUntilChanged()).pipe(debounceTime(800)).subscribe(value => {
      this.setMeterGuidBasedOnDisplayId(value, WorkorderActionJsonConstants.meterGuid);
    });
  }

  private SetCpmLazFormValidation() {
    this.subscribeToControl(RemoveSpaceConstants.UpdateBlockRange).subscribe(value => {
      this.enableAndDisableBlockRangeControls(value);
    });

    //distinct keeps this from firing needlessly when we submit
    this.subscribeToControl(RemoveSpaceConstants.MeterSelect).pipe(distinctUntilChanged()).subscribe(value => {
      this.setMeterGuidBasedOnDisplayId(value, WorkorderActionJsonConstants.meterGuid);
    });
  }

  private setHistoricalMeterConditionals(meterControlId: number, meterGuidControlId: string, value: string): void {
    this.addOrRemoveMeterIdValidations(meterControlId, RemoveSpaceConstants.NumberOfSpaces, meterGuidControlId, value, [Validators.required, RemoveSpaceMoreThanZeroSpacesValidator(), RemoveSpaceTooManySpacesValidator(this.numberOfSpacesOnMeter)], ActionConstants.RemoveSpaceId);
    this.setNumberOfSpacesWarnings();
  }

  private setNumberOfSpacesValidator(): void {
    //start clearing the control
    //this way it won't stay in a valid state if api call fails
    //also prevents user from submitting during this call, or changing the value
    var originalSpaceCount = this.getControlValue(RemoveSpaceConstants.NumberOfSpaces);
    this.setValue(RemoveSpaceConstants.NumberOfSpaces, null);
    //this is disabled on creation until a meter is selected for the first time
    this.setValidators(RemoveSpaceConstants.NumberOfSpaces, [Validators.required, RemoveSpaceMoreThanZeroSpacesValidator()]);
    this.enableControl(RemoveSpaceConstants.NumberOfSpaces);

    if (this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid)) {

      this._meterService.getMeterById(this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid), this.historicalEffectiveDate).subscribe(meterResult => {
        this._spaceService.getTotalNumberOfSpaces(meterResult.id, this.historicalEffectiveDate).subscribe(result => {

          this.numberOfSpacesOnMeter = result.totalNumberOfSpaces;

          this.setValidators(RemoveSpaceConstants.NumberOfSpaces, [Validators.required, RemoveSpaceTooManySpacesValidator(this.numberOfSpacesOnMeter), RemoveSpaceMoreThanZeroSpacesValidator()]);
          this.enableControl(RemoveSpaceConstants.NumberOfSpaces);

          //now put the value back
          this.setValue(RemoveSpaceConstants.NumberOfSpaces, originalSpaceCount);
        });
      });
    }
  }

  private enableAndDisableBlockRangeControls(updatBlockRangeSelected: boolean): void {
    if (updatBlockRangeSelected) {
      this.enableControl(RemoveSpaceConstants.BlockRangeFrom);
      this.enableControl(RemoveSpaceConstants.BlockRangeTo);
      this.setAsRequired(RemoveSpaceConstants.BlockRangeFrom);
      this.setAsRequired(RemoveSpaceConstants.BlockRangeTo);
    }
    else {
      this.disableControl(RemoveSpaceConstants.BlockRangeFrom);
      this.disableControl(RemoveSpaceConstants.BlockRangeTo);
    }
  }

  private setNumberOfSpacesWarnings(): void {
    this.subscribeToControl(RemoveSpaceConstants.NumberOfSpaces).subscribe(value => {
      const totalSpaces = +this.numberOfSpacesOnMeter - +value;
      if (totalSpaces === 0 && !(this.warnings.includes(RemovingLastSpace))) {
        this.pushToWarnings(RemovingLastSpace);
      }
      else if (this.warnings.includes(RemovingLastSpace) && totalSpaces !== 0) {
        this.removeFromWarnings(RemovingLastSpace);
      }
      this.formGroup.updateValueAndValidity();
    });
  }
}
