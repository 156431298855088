
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import * as moment from 'moment';

import { TokenCache } from './../../security/token-cache';
import { WorkOrder } from '../models/work-order';
import { WorkOrderAction } from './../models/work-order-action';
import { WorkOrderCreateConfirmationComponent } from './work-order-create-confirmation/work-order-create-confirmation';
import { WorkOrdersService } from '../services/work-orders.service';
import { WorkOrderUpdate } from '../models/work-order-update';
import { WorkOrderWarning } from '../models/work-order-warning';
import { ActionConstants } from '../action/action-constants';
import { MeterSpaceRepresentation } from './meter-space-representation';
import { WorkorderActionJsonConstants } from '../action-field/workorder-action-json-constants';
import { InstallSpaceConstants, RemoveSpaceConstants, ShiftSpaceConstants, ChangeContractTypeToConcessionConstants, InstallMeterConstants } from '../action-field/action-field-constants';
import { MoreThanTwentySpaces, RemovingLastSpace, RemovingTooManySpaces, ShiftSpaceToDifferentBlocks, WrongNumberOfSpacesForChangingContractType, EurBlankOnConcessionMeter } from '../models/warning-constants';
import { ContractTypeConstants } from '../../inventory-management/meters/meter-constants';
import { LoadingZoneImpactService } from '../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { TimeZoneConverter } from '../../core/time-zone/time-zone-converter';

@Component({
  selector: 'app-work-order-create',
  templateUrl: './work-order-create.component.html',
  styleUrls: ['./work-order-create.css']
})
export class WorkOrderCreateComponent implements OnInit, OnDestroy {
  tokenCache = new TokenCache();
  historicalEffectiveDate: Date;
  previousActivationEffectiveDate: Date;
  hasHistoricalEffectiveDate: boolean;
  isCityUser: boolean = this.tokenCache.checkForCityUser();
  loading: boolean;
  prepopulationPersistentKey: string;
  warnings = new Array<WorkOrderWarning>();
  multipleActionWarnings = new Array<WorkOrderWarning>();
  disableOkButtonOnConfirmation = false;
  workOrder: WorkOrder;
  workOrderActions: WorkOrderAction[];
  workOrderFormGroup = new FormGroup({});
  overrideToCityUserRole: boolean;
  historicalEffectiveDateIsTooRecent: boolean;

  constructor(
    private _workOrdersService: WorkOrdersService,
    private _loadingZoneImpactService: LoadingZoneImpactService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.prepopulationPersistentKey = this.route.snapshot.queryParams['prepopulationKey'];

    this.workOrder = new WorkOrder();
    if (this.prepopulationPersistentKey) {
      this.workOrder.currentStatus = 'prepopulated';
      this.workOrder.workOrderActions = new Array<WorkOrderAction>();
    }
    const overrideUserRoleToCity = this.route.snapshot.queryParams['overrideToCityUserRole'];
    if (overrideUserRoleToCity === 'true') {
      this.overrideToCityUserRole = true;
    }
  }

  ngOnInit() {
    this.loading = true;
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.setWorkOrder(id);
    } else {
      this.createNewWorkOrder();
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem(this.prepopulationPersistentKey);
  }

  private setWorkOrder(id: string): void {
    this._workOrdersService.getWorkOrderById(id).subscribe(
      result => {
        this.workOrder = result;
        if (this.workOrder.currentLetterOfDirection.historicalEffectiveDate) {
          this.hasHistoricalEffectiveDate = true;
        }

        if (this.workOrder.currentLetterOfDirection.historicalEffectiveDate) {
          const nonUTCDate = new Date(this.workOrder.currentLetterOfDirection.historicalEffectiveDate);
          this.historicalEffectiveDate = new Date(
            nonUTCDate.getUTCFullYear(),
            nonUTCDate.getUTCMonth(),
            nonUTCDate.getUTCDate()
          );
        } else if (this.workOrder.previousActivationEffectiveDate) {
          const nonUTCDate = new Date(this.workOrder.previousActivationEffectiveDate);
          this.historicalEffectiveDate = new Date(
            nonUTCDate.getUTCFullYear(),
            nonUTCDate.getUTCMonth(),
            nonUTCDate.getUTCDate()
          );
        }

        if (this.workOrder.previousActivationEffectiveDate) {
          const nonUTCDate = new Date(this.workOrder.previousActivationEffectiveDate);
          this.previousActivationEffectiveDate = new Date(
            nonUTCDate.getFullYear(),
            nonUTCDate.getMonth(),
            nonUTCDate.getDate(),
            nonUTCDate.getHours(),
            nonUTCDate.getMinutes()
          );
        }

        this.workOrderActions = this.workOrder.currentLetterOfDirection.workOrderActions;
        let actionIndex = 0;
        this.workOrderActions.forEach(action => {
          action.actionIndex = actionIndex;
          actionIndex++;
        });
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  private isCityAndShiftSpace(action: WorkOrderAction): boolean {
    return this.tokenCache.checkForCityUser() && action.actionId === ActionConstants.ShiftSpaceId;
  }

  private createNewWorkOrder(): void {
    this.loading = false;
    this.workOrder = new WorkOrder();
    this.workOrderActions = new Array<WorkOrderAction>();

    if (this.prepopulationPersistentKey) {
      this.buildPrepopulatedWorkOrders();
    } else {
      const newWorkOrderAction = new WorkOrderAction();
      newWorkOrderAction.actionIndex = this.workOrderActions.length;
      this.workOrderActions.push(newWorkOrderAction);
    }
    this.workOrder.workOrderActions = this.workOrderActions;
  }

  buildPrepopulatedWorkOrders() {
    const json = localStorage.getItem(this.prepopulationPersistentKey);
    const items = JSON.parse(json);
    items.forEach(item => {
      const workOrderAction = new WorkOrderAction();

      if(item[WorkorderActionJsonConstants.ratePackageReversalDate]){
        item.currentLetterOfDirection.historicalEffectiveDate = item[WorkorderActionJsonConstants.ratePackageReversalDate];
        item.currentLetterOfDirection.hasHistoricalEffectiveDate = true;
      }

      workOrderAction.actionIndex = this.workOrderActions.length;
      workOrderAction.workOrderActionJson = JSON.stringify(item);
      workOrderAction.actionId = ActionConstants.ReverseRatePackageChangeId;
      workOrderAction.isSystemGenerated = true;
      this.workOrderActions.push(workOrderAction);



      if (!this.workOrder.currentLetterOfDirection) {
        this.workOrder.currentLetterOfDirection = item.currentLetterOfDirection;
      }
    });

    if(this.workOrder.currentLetterOfDirection.hasHistoricalEffectiveDate){
      this.historicalEffectiveDate = this.workOrder.currentLetterOfDirection.historicalEffectiveDate;
      this.hasHistoricalEffectiveDate = this.workOrder.currentLetterOfDirection.hasHistoricalEffectiveDate;
      this.checkIfHistoricalEffectiveDateIsTooRecent();
    }

    this.workOrder.currentStatus = 'prepopulated';
  }

  public actionFormIsValid(actionIndex: number): boolean {
    if (this.workOrderFormGroup.controls[actionIndex]) {
      return this.workOrderFormGroup.controls[actionIndex].invalid;
    } else {
      return false;
    }
  }

  addWorkOrderAction() {
    const newWorkOrderAction = new WorkOrderAction();
    newWorkOrderAction.actionIndex = this.workOrderActions.length;
    this.workOrderActions.push(newWorkOrderAction);
  }

  removeWorkOrderAction(index: number): void {
    this.workOrderActions.splice(index, 1);
    this.workOrderFormGroup.removeControl(index.toString());
  }

  disableAddButton(): boolean {
    if (this.workOrderActions !== undefined) {
      if (
        this.workOrderActions.filter(x => {
          return (
            x.actionId === ActionConstants.UpdateMeterAttributesId ||
            x.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId ||
            x.actionId === ActionConstants.RegularRateAdjustmentId
          );
        }).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }

  }

  hasRegularRateAdjustmentAction(): boolean {
    if (this.workOrderActions !== undefined) {
      if (
        this.workOrderActions.filter(x => {
          return x.actionId === ActionConstants.RegularRateAdjustmentId;
        }).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }

  }
  submitWorkOrder() {
    this.snackBar.open('Submitting Work Order...', '', <MatSnackBarConfig>{
      duration: 10000
    });

    const workOrderUpdate = this.generateWorkOrderUpdate();

    this._workOrdersService.submitWorkOrder(workOrderUpdate, this.overrideToCityUserRole).subscribe(
      response => {
        this.snackBar.open('Submitted Work Order', '', <MatSnackBarConfig>{
          duration: 3000
        });
        this.router.navigate(['workorders/' + response.result.id]);
      },
      error => {
        try{
          //may not always need to deserialize?
          if(!error['_body'].message){
            const messageBody = JSON.parse(error['_body']);
            this.snackBar.open(messageBody.message, 'X');
          }
          else{
            this.snackBar.open(error['_body'].message, 'X');
          }
        }
        catch(err){
          this.snackBar.open("Failed to submit work order.", 'X');
        }
      }
    );
  }

  generateWorkOrderUpdate(): WorkOrderUpdate {
    const workOrderUpdate = new WorkOrderUpdate();
    workOrderUpdate.id = this.workOrder.id;
    workOrderUpdate.hasHistoricalEffectiveDate = this.hasHistoricalEffectiveDate;
    workOrderUpdate.historicalEffectiveDate = this.historicalEffectiveDate;
    workOrderUpdate.workOrderStatus = this.workOrder.currentStatus;
    workOrderUpdate.workOrderStatusId = this.workOrder.currentStatusId;
    workOrderUpdate.zoneDisplayIds = this.workOrder.zoneDisplayIds;
    workOrderUpdate.areaDisplayIds = this.workOrder.areaDisplayIds;
    workOrderUpdate.blockDisplayIds = this.workOrder.blockDisplayIds;
    workOrderUpdate.meterDisplayIds = this.workOrder.meterDisplayIds;

    this.workOrderActions.forEach(action => {
      const index = this.workOrderActions.indexOf(action);
      const newObjectForActionJsonWithUpdatedKeys = {};

      if (this.workOrderFormGroup.controls[index]) {
        this.workOrderFormGroup.controls[index].enable();
      }


      if (
        this.workOrderFormGroup.controls[index] != null &&
        this.workOrderFormGroup.controls[index] !== undefined
      ) {
        Object.keys(this.workOrderFormGroup.controls[index].value).forEach(key => {
          const keyWithoutIndex = key.replace(index.toString(), '');

          var formValue = this.workOrderFormGroup.controls[index].value[key];

          if(formValue instanceof Date)
          {
            formValue = TimeZoneConverter.EnsureChicagoTime(formValue);
          }
          newObjectForActionJsonWithUpdatedKeys[keyWithoutIndex] = formValue;
        });
      }
      const workOrderActionJson = JSON.stringify(newObjectForActionJsonWithUpdatedKeys);
      action.workOrderActionJson = workOrderActionJson;
    });

    workOrderUpdate.workOrderActions = this.workOrderActions;

    return workOrderUpdate;
  }

  saveWorkOrder() {
    this.snackBar.open('Saving Work Order...', '', <MatSnackBarConfig>{
      duration: 10000
    });

    const workOrderUpdate = this.generateWorkOrderUpdate();
    this._workOrdersService.saveWorkOrder(workOrderUpdate, this.overrideToCityUserRole).subscribe(
      response => {
        this.snackBar.open('Saved Work Order Draft', '', <MatSnackBarConfig>{
          duration: 3000
        });
        this.router.navigate(['workorders/' + response.result.id]);
      },
      error => {
        const messageBody = JSON.parse(error['_body'].message);
        this.snackBar.open(messageBody, 'X');
      }
    );
  }

  confirmSubmittingWorkOrder() {
    if (!this.hasHistoricalEffectiveDate) {
      this.submitWorkOrderServiceCall();
    } else {
      const workOrderUpdate = new WorkOrderUpdate();
      workOrderUpdate.id = this.workOrder.id;
      workOrderUpdate.workOrderActions = this.workOrderActions;
      this.generateWorkOrderUpdate();
      this._workOrdersService
        .getAllRelevantWorkOrders(workOrderUpdate)
        .subscribe(allRelevantWorkOrders => {
          if (allRelevantWorkOrders.length > 0) {
            const dialogRef = this.dialog.open(WorkOrderCreateConfirmationComponent, {
              width: '340px',
              height: 'auto',
              data: {
                isHistoricalChangeConfirmation: true,
                workOrderDisplayIds: allRelevantWorkOrders.result
              }
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.submitWorkOrderServiceCall();
              }
            });
          } else {
            this.submitWorkOrderServiceCall();
          }
        });
    }
  }

  submitWorkOrderServiceCall() {
    if (this.workOrderActions.length > 1) {
      this.getMultipleActionsWarnings();
    }

    this.warnings.forEach(warning => {
      if (warning.warnings.length === 0) {
        this.warnings.splice(this.warnings.indexOf(warning));
      }
    });

    if (this.warnings.length > 0 || this.multipleActionWarnings.length > 0 || this.hasHistoricalEffectiveDate) {

      const dialogRef = this.dialog.open(WorkOrderCreateConfirmationComponent, {
        width: '340px',
        height: 'auto',
        data: {
          warnings: this.warnings,
          multipleActionWarnings: this.multipleActionWarnings,
          disableOkButton: this.disableOkButtonOnConfirmation,
          isHistoricalChangeConfirmation: this.hasHistoricalEffectiveDate
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.submitWorkOrder();
        }
      });
    } else {
      this.submitWorkOrder();
    }
  }

  getMultipleActionsWarnings() {
    this.disableOkButtonOnConfirmation = false;
    const meterSpaceRepresentations = new Array<MeterSpaceRepresentation>();
    this.workOrderActions.forEach(action => {
      const index = this.workOrderActions.indexOf(action);
      const meterSpaceCount = new MeterSpaceRepresentation();
      if (
        action.actionId === ActionConstants.InstallSpaceId ||
        action.actionId === ActionConstants.RemoveSpaceId ||
        action.actionId === ActionConstants.ChangeContractTypeToConcessionId
      ) {
        // tslint:disable-next-line:no-var-keyword
        var meter = meterSpaceRepresentations.filter(x => {
          return (
            x.meterGuid ===
            this.workOrderFormGroup.controls[index].value[
              index + WorkorderActionJsonConstants.meterGuid
            ]
          );
        })[0];
        if (action.actionId === ActionConstants.InstallSpaceId) {
          if (meter != null && meter !== undefined) {
            meter.numberOfSpaces =
              meter.numberOfSpaces +
              +this.workOrderFormGroup.controls[index].value[
                index.toString() + InstallSpaceConstants.NumberOfSpaces.toString()
              ];
            meter.actionsAffected.push(this.workOrderActions.indexOf(action));
          } else {
            meterSpaceCount.meterGuid = this.workOrderFormGroup.controls[index].value[
              index + WorkorderActionJsonConstants.meterGuid
            ];
            meterSpaceCount.numberOfSpaces =
              +this.workOrderFormGroup.controls[index].value[
                index + WorkorderActionJsonConstants.totalNumberOfSpacesOnMeter
              ] +
              +this.workOrderFormGroup.controls[index].value[
                index.toString() + InstallSpaceConstants.NumberOfSpaces.toString()
              ];
            meterSpaceCount.actionsAffected.push(this.workOrderActions.indexOf(action));
            meterSpaceRepresentations.push(meterSpaceCount);
          }
        } else if (action.actionId === ActionConstants.RemoveSpaceId) {
          if (meter != null && meter !== undefined) {
            meter.numberOfSpaces =
              meter.numberOfSpaces -
              +this.workOrderFormGroup.controls[index].value[
                index.toString() + RemoveSpaceConstants.NumberOfSpaces.toString()
              ];
            meter.actionsAffected.push(this.workOrderActions.indexOf(action));
          } else {
            meterSpaceCount.meterGuid = this.workOrderFormGroup.controls[index].value[
              index + WorkorderActionJsonConstants.meterGuid
            ];
            meterSpaceCount.numberOfSpaces =
              +this.workOrderFormGroup.controls[index].value[
                index + WorkorderActionJsonConstants.totalNumberOfSpacesOnMeter
              ] -
              +this.workOrderFormGroup.controls[index].value[
                index.toString() + RemoveSpaceConstants.NumberOfSpaces.toString()
              ];
            meterSpaceCount.actionsAffected.push(this.workOrderActions.indexOf(action));
            meterSpaceRepresentations.push(meterSpaceCount);
          }
        } else if (action.actionId === ActionConstants.ChangeContractTypeToConcessionId) {
          if (
            !this.workOrderFormGroup.controls[index].value[
              index.toString() + ChangeContractTypeToConcessionConstants.EntireMeter
            ]
          ) {
            if (meter != null && meter !== undefined) {
              meter.numberOfSpaces =
                meter.numberOfSpaces -
                +this.workOrderFormGroup.controls[index].value[
                  index.toString() +
                    ChangeContractTypeToConcessionConstants.NumberOfSpaces.toString()
                ];
            } else {
              meterSpaceCount.meterGuid = this.workOrderFormGroup.controls[index].value[
                index + WorkorderActionJsonConstants.meterGuid
              ];
              meterSpaceCount.numberOfSpaces =
                +this.workOrderFormGroup.controls[index].value[
                  index + WorkorderActionJsonConstants.totalNumberOfSpacesOnMeter
                ] -
                +this.workOrderFormGroup.controls[index].value[
                  index.toString() +
                    ChangeContractTypeToConcessionConstants.NumberOfSpaces.toString()
                ];
              meterSpaceCount.contractTypeFlag = true;
              meterSpaceRepresentations.push(meterSpaceCount);
            }
          }
        }
      } else if (action.actionId === ActionConstants.ShiftSpaceId) {
        let toMeter;
        if (this.workOrderFormGroup.controls[index]) {
          toMeter = meterSpaceRepresentations.filter(x => {
            return (
              x.meterGuid ===
              this.workOrderFormGroup.controls[index].value[
                index + WorkorderActionJsonConstants.meterToGuid
              ]
            );
          })[0];
        }
        if (toMeter != null && meter !== undefined) {
          toMeter.numberOfSpaces =
            toMeter.numberOfSpaces +
            +this.workOrderFormGroup.controls[index].value[
              index.toString() + ShiftSpaceConstants.NumberOfSpaces.toString()
            ];
          toMeter.actionsAffected.push(this.workOrderActions.indexOf(action));
        } else {
          const toMeterSpaceCount = new MeterSpaceRepresentation();
          if (this.workOrderFormGroup.controls[index]) {
            toMeterSpaceCount.meterGuid = this.workOrderFormGroup.controls[index].value[
              index + WorkorderActionJsonConstants.meterToGuid
            ];
            toMeterSpaceCount.numberOfSpaces =
            +this.workOrderFormGroup.controls[index].value[
              index + WorkorderActionJsonConstants.totalNumberOfSpacesOnToMeter
            ] +
            +this.workOrderFormGroup.controls[index].value[
              index.toString() + ShiftSpaceConstants.NumberOfSpaces.toString()
            ];
          }


          toMeterSpaceCount.actionsAffected.push(this.workOrderActions.indexOf(action));
          meterSpaceRepresentations.push(toMeterSpaceCount);
        }
        let fromMeter;
        if (this.workOrderFormGroup.controls[index]) {
          fromMeter = meterSpaceRepresentations.filter(x => {
            return (
              x.meterGuid ===
              this.workOrderFormGroup.controls[index].value[
                index + WorkorderActionJsonConstants.meterFromGuid
              ]
            );
          })[0];
        }

        if (fromMeter != null && meter !== undefined) {
          fromMeter.numberOfSpaces =
            fromMeter.numberOfSpaces -
            +this.workOrderFormGroup.controls[index].value[
              index.toString() + ShiftSpaceConstants.NumberOfSpaces.toString()
            ];
          fromMeter.actionsAffected.push(this.workOrderActions.indexOf(action));
        } else {
          const fromMeterSpaceCount = new MeterSpaceRepresentation();
          if (this.workOrderFormGroup.controls[index]) {
            fromMeterSpaceCount.meterGuid = this.workOrderFormGroup.controls[index].value[
              index + WorkorderActionJsonConstants.meterFromGuid
            ];
            fromMeterSpaceCount.numberOfSpaces =
              +this.workOrderFormGroup.controls[index].value[
                index + WorkorderActionJsonConstants.totalNumberOfSpacesOnFromMeter
              ] -
              +this.workOrderFormGroup.controls[index].value[
                index.toString() + ShiftSpaceConstants.NumberOfSpaces.toString()
              ];
          }

          fromMeterSpaceCount.actionsAffected.push(this.workOrderActions.indexOf(action));
          meterSpaceRepresentations.push(fromMeterSpaceCount);
        }
      }
    });

    this.multipleActionWarnings = [];
    meterSpaceRepresentations.forEach(count => {
      const warningArray = new Array<string>();
      if (count.contractTypeFlag) {
        if (count.numberOfSpaces !== 0) {
          warningArray.push(WrongNumberOfSpacesForChangingContractType);
          this.disableOkButtonOnConfirmation = true;
        }
      } else {
        if (count.numberOfSpaces > 20) {
          warningArray.push(MoreThanTwentySpaces);
        }
        if (count.numberOfSpaces === 0) {
          warningArray.push(RemovingLastSpace);
        }
        if (count.numberOfSpaces < 0) {
          warningArray.push(RemovingTooManySpaces);
          this.disableOkButtonOnConfirmation = true;
        }
      }

      if (warningArray.length > 0) {
        const newWarning = new WorkOrderWarning('Total Space Count on Meter', warningArray);
        this.multipleActionWarnings.push(newWarning);
      } else {
        this.multipleActionWarnings = [];
      }

      if (this.warnings.length > 0) {
        count.actionsAffected.forEach(action => {
          if (this.warnings[action]) {
            if (!this.warnings[action].warnings.includes(ShiftSpaceToDifferentBlocks)) {
              this.warnings.splice(this.warnings.indexOf(this.warnings[action]), 1);
            } else {
              this.warnings[action].warnings = [];
              this.warnings[action].warnings.push(ShiftSpaceToDifferentBlocks);
            }
          }
        });
      }
    });

    this.workOrderActions.forEach(action => {
      if (action.actionId === ActionConstants.InstallMeterId && this.isCityUser) {
        const index = this.workOrderActions.indexOf(action);
        //City must specify EUR when installing a concession meter, UNLESS there are 0 spaces
        if (
          this.workOrderFormGroup.controls[index].value[index.toString() + InstallMeterConstants.ContractType] === ContractTypeConstants.Concession.title
          &&
          (this.workOrderFormGroup.controls[index].value[index.toString() + InstallMeterConstants.EUR] == null
            || this.workOrderFormGroup.controls[index].value[index.toString() + InstallMeterConstants.EUR] === '')
          &&
          !(this.workOrderFormGroup.controls[index].value[index.toString() + InstallMeterConstants.NumberOfSpaces] == null //not empty or zero
            || this.workOrderFormGroup.controls[index].value[index.toString() + InstallMeterConstants.NumberOfSpaces] == ''
            || this.workOrderFormGroup.controls[index].value[index.toString() + InstallMeterConstants.NumberOfSpaces] == 0)
        ) {
          const warningArray = new Array<string>();
          warningArray.push(EurBlankOnConcessionMeter);
          this.disableOkButtonOnConfirmation = true;
          const newWarning = new WorkOrderWarning('Install Meter Error', warningArray);
          this.multipleActionWarnings.push(newWarning);
        }
      }
    });
  }

  templatedDescriptionEmitter(workOrderAction: WorkOrderAction) {
    this.workOrderActions[this.workOrderActions.indexOf(workOrderAction)] = workOrderAction;
  }

  hasHistoricalEffectiveDateChanged(eventObj: boolean) {
    if (eventObj && this.historicalEffectiveDate == null) {
      const today = new Date();
      const initialHistoricalDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      initialHistoricalDate.setDate(today.getDate() - 7);

      this.historicalEffectiveDate = initialHistoricalDate;
    } else if (!eventObj) {
      this.historicalEffectiveDate = null;
    }
  }

  checkIfHistoricalEffectiveDateIsTooRecent() {
    const today = new Date();
    const effectiveDate = new Date(this.historicalEffectiveDate);
    const dateDifference = (moment(today).startOf('day').diff(moment(effectiveDate).startOf('day'), 'days'));
    if ((today.getMonth() === effectiveDate.getMonth() && today.getFullYear() === effectiveDate.getFullYear()) || dateDifference <= 7) {
      this.historicalEffectiveDateIsTooRecent = true;
    }
    else {
      this.historicalEffectiveDateIsTooRecent = false;
    }
  }

  toggleHistoricalChange(event) {
    if (event === false) {
      this.historicalEffectiveDateIsTooRecent = false;
      this.historicalEffectiveDate = null;
    }
  }

  disableSubmitButton(): boolean {
    if (
      (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate === null) ||
      (this.workOrderFormGroup.invalid) || this.historicalEffectiveDateIsTooRecent) {
        return true;
    }
    return false;
  }
  cancel() {
    if (this.workOrder.id) {
        this.router.navigate(['workorders/' + this.workOrder.id]);
      }
      else {
        this.router.navigate(['workorders/']);
      }
    }
}
