import { Column, InventoryListColumnDef } from './inventory-list/inventory-list-column-def';

export class ColumnConstants {
    public static allColumns: Array<Column> = [
        InventoryListColumnDef.meterId,
        InventoryListColumnDef.meterStatus,
        InventoryListColumnDef.meterType,
        InventoryListColumnDef.numberOfActiveSpaces,
        InventoryListColumnDef.ratePackage,
        InventoryListColumnDef.contractType,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.meterAddress,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.activeMetersOnBlock,
        InventoryListColumnDef.locationType,   
        InventoryListColumnDef.lastReservedPowerActionDate,
        InventoryListColumnDef.lastUpdateDate,
        InventoryListColumnDef.lastUpdatedBy,
        InventoryListColumnDef.tempRemoved,
        InventoryListColumnDef.foundationType,
        InventoryListColumnDef.hasSignsOnly,
        InventoryListColumnDef.eLockNumber,
        InventoryListColumnDef.latitude,
        InventoryListColumnDef.longitude,
        InventoryListColumnDef.serialNumber,
        InventoryListColumnDef.sim
    ];

    public static defaultColumns: Array<Column> = [
        InventoryListColumnDef.meterId,
        InventoryListColumnDef.meterStatus,
        InventoryListColumnDef.numberOfActiveSpaces,
        InventoryListColumnDef.ratePackage,
        InventoryListColumnDef.contractType,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.meterAddress,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.activeMetersOnBlock,
        InventoryListColumnDef.locationType,    
        InventoryListColumnDef.lastReservedPowerActionDate,
        InventoryListColumnDef.lastUpdateDate,
        InventoryListColumnDef.lastUpdatedBy,
        InventoryListColumnDef.hasSignsOnly
    ];

    public static spaceAllColumns: Array<Column> = [
        InventoryListColumnDef.spaceId,
        InventoryListColumnDef.spaceStatus,
        InventoryListColumnDef.contractType,
        InventoryListColumnDef.ratePackage,
        InventoryListColumnDef.meterId,
        InventoryListColumnDef.meterStatus,
        InventoryListColumnDef.meterType,
        InventoryListColumnDef.meterAddress,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.locationType,
        InventoryListColumnDef.physicalBlockId,
        InventoryListColumnDef.lastReservedPowerActionDate,
        InventoryListColumnDef.lastExpectedUtilizationRate,
        InventoryListColumnDef.dateCreated,
        InventoryListColumnDef.dateActive,
        InventoryListColumnDef.dateRemoved,
        InventoryListColumnDef.lastUpdateDate,
        InventoryListColumnDef.lastUpdatedBy,
        InventoryListColumnDef.hasSignsOnly
    ];

    public static spaceDefaultColumns: Array<Column> = [
        InventoryListColumnDef.spaceId,
        InventoryListColumnDef.spaceStatus,
        InventoryListColumnDef.contractType,
        InventoryListColumnDef.ratePackage,
        InventoryListColumnDef.meterId,
        InventoryListColumnDef.meterAddress,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.locationType,
        InventoryListColumnDef.physicalBlockId,
        InventoryListColumnDef.lastReservedPowerActionDate,
        InventoryListColumnDef.lastExpectedUtilizationRate,
        InventoryListColumnDef.lastUpdateDate,
        InventoryListColumnDef.lastUpdatedBy,
        InventoryListColumnDef.hasSignsOnly
    ];

    public static blockAllColumns: Array<Column> = [
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.activeMetersOnBlock,
        InventoryListColumnDef.meterIds,
        InventoryListColumnDef.numberOfActiveSpaces,
        InventoryListColumnDef.activeSpacesPhysicallyOnBlock,
        InventoryListColumnDef.contractType,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.streetName,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.spaceType,
        InventoryListColumnDef.hasSignsOnly   
    ];

    public static blockDefaultColumns: Array<Column> = [
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.activeMetersOnBlock,
        InventoryListColumnDef.meterIds,
        InventoryListColumnDef.numberOfActiveSpaces,
        InventoryListColumnDef.activeSpacesPhysicallyOnBlock,
        InventoryListColumnDef.contractType,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.streetName,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.spaceType,      
        InventoryListColumnDef.hasSignsOnly
    ];

    public static linkedBlocksAllColumns: Array<Column> = [
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.activeMetersOnBlock,
        InventoryListColumnDef.meterIds,
        InventoryListColumnDef.spaceType,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.streetName,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.numberOfActiveSpaces,
        InventoryListColumnDef.activeSpacesPhysicallyOnBlock,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.deleteItem
    ];

    public static linkedBlocksDefaultColumns: Array<Column> = [
        InventoryListColumnDef.blockId,
        InventoryListColumnDef.zone,
        InventoryListColumnDef.area,
        InventoryListColumnDef.activeMetersOnBlock,
        InventoryListColumnDef.meterIds,
        InventoryListColumnDef.spaceType,
        InventoryListColumnDef.blockRange,
        InventoryListColumnDef.streetName,
        InventoryListColumnDef.streetSide,
        InventoryListColumnDef.numberOfActiveSpaces,
        InventoryListColumnDef.activeSpacesPhysicallyOnBlock,
        InventoryListColumnDef.ward,
        InventoryListColumnDef.deleteItem
    ];

    public static blockMetersColumns: Array<string> = [
        InventoryListColumnDef.meterId.constant,
        InventoryListColumnDef.meterAddress.constant,
        InventoryListColumnDef.meterStatus.constant,
        InventoryListColumnDef.numberOfActiveSpaces.constant,
        InventoryListColumnDef.ratePackage.constant,
        InventoryListColumnDef.meterType.constant,
        InventoryListColumnDef.hasSignsOnly.constant,
        InventoryListColumnDef.foundationType.constant
    ];

    public static blockSpacesColumns: Array<string> = [
        InventoryListColumnDef.spaceId.constant,
        InventoryListColumnDef.spaceStatus.constant,
        InventoryListColumnDef.meterId.constant,
        InventoryListColumnDef.meterType.constant,
        InventoryListColumnDef.meterStatus.constant,
        InventoryListColumnDef.meterAddress.constant,
        InventoryListColumnDef.ratePackage.constant,
        InventoryListColumnDef.contractType.constant,
        InventoryListColumnDef.ward.constant,
        InventoryListColumnDef.fineAmount.constant,
        InventoryListColumnDef.lastExpectedUtilizationRate.constant
    ];

    public static meterSelectColumns: Array<string> = [
        InventoryListColumnDef.meterId.constant,
        InventoryListColumnDef.meterAddress.constant,
        InventoryListColumnDef.streetSide.constant,
        InventoryListColumnDef.blockId.constant,
        InventoryListColumnDef.blockRange.constant,
        InventoryListColumnDef.activeMetersOnBlock.constant,
        InventoryListColumnDef.meterStatus.constant,
        InventoryListColumnDef.numberOfActiveSpaces.constant,
        InventoryListColumnDef.contractType.constant,
        InventoryListColumnDef.ratePackage.constant,
        InventoryListColumnDef.meterType.constant,
        InventoryListColumnDef.hasSignsOnly.constant,
        InventoryListColumnDef.foundationType.constant
    ];
}
