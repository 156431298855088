import { FilterOperator } from './../../core/query-strings/query-string-builder';
import { PreviousCollectionComponent } from './previous-collection/previous-collection.component';
import { DeleteManualCollectionsConfirmationComponent } from './delete-manual-collections-confirmation/delete-manual-collections-confirmation';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryFilterService } from './../../work-orders/inventory-information/services/inventory-filter-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownOptions } from '../../work-orders/inventory-information/services/inventory-filter-service';
import { Collection } from './models/collection';
import { ManualCollectionsService } from './services/manual-collections-service';
import { StringExtensions } from './../../string-extensions';
import { ConfirmationDialogComponent } from '../../trueup/confirmation-dialog/confirmation-dialog.component';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';

@Component({
  selector: 'app-enter-manual-collections',
  templateUrl: './enter-manual-collections.component.html',
  styleUrls: ['./enter-manual-collections.component.css']
})
export class EnterManualCollectionsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns = ['meterId', 'spaceId', 'fullyUtilizedAmount', 'amountDistributed'];

  inventoryFilterForm = new FormGroup({
    zoneControl: new FormControl(),
    areaControl: new FormControl(),
    blockControl: new FormControl(),
    meterControl: new FormControl(),
    filterDate: new FormControl(),
    payUnit: new FormControl(),
    amount: new FormControl()
  });

  zoneOptions: DropdownOptions[];
  areaOptions: DropdownOptions[];
  blockOptions: DropdownOptions[];
  meterOptions: DropdownOptions[];
  payUnitOptions = ['Coin', 'Card', 'Monthly Hang Tag Revenue', 'ParkChicago', 'External Pay Unit'];

  dateRangeStart: Date;
  dateRangeEnd: Date;
  dateRangeFilterOperator: string = FilterOperator.Between;
  dateRangeDropdown = [FilterOperator.GreaterThan, FilterOperator.LessThan, FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between];
  amountFrom: number;
  amountTo: number;
  amountFilterOperator: string = FilterOperator.Equal;
  amountFilterDropdown = [FilterOperator.Equal, FilterOperator.GreaterThan, FilterOperator.LessThan, FilterOperator.NotEqual, FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between];

  zoneId: string;
  areaId: string;
  blockId: string;

  loadingSpaces = false;
  displayAreaLoading: boolean;
  displayBlockLoading: boolean;
  displayMeterLoading: boolean;
  dataSource: MatTableDataSource<any>;

  collections: Collection[];
  initialCollections: Collection[];
  collectionsLoading: boolean;
  pendingCollectionsToSubmit: any;
  filtersAtSubmission: any;
  editFlag: boolean;
  editedCollectionId: string;
  selectedArea: string = null;
  selectedBlock: string = null;
  selectedMeter: string = null;

  confirmDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(private _manualCollectionsService: ManualCollectionsService, private _inventoryFilterService: InventoryFilterService, private snackbar: MatSnackBar, private dialog: MatDialog, private _reportService: ReportingHttpService) { }

  ngOnInit() {
    this.collectionsLoading = true;
    this.pendingCollectionsToSubmit = [];
    this.inventoryFilterForm.get('filterDate').setValue(new Date());
    this.inventoryFilterForm.disable();
    this.inventoryFilterForm.get('filterDate').enable();
    this.inventoryFilterForm.get('amount').enable();
    this.inventoryFilterForm.get('payUnit').enable();

    this._inventoryFilterService.getSpacesForDrilldownForCollections('zone', this.inventoryFilterForm.get('filterDate').value).subscribe(result => {
      this.zoneOptions = this.sortFilters(result, 'value');
      this.inventoryFilterForm.get('zoneControl').enable();
    });

    this._manualCollectionsService.getAllManualCollections()
      .subscribe(result => {
        this.collectionsLoading = false;
        this.initialCollections = result;
        this.collections = result;
      }, error => {
        this.snackbar.open(error['_body'], 'X');
        console.log(error);
      });

    this.inventoryFilterForm
      .get('zoneControl')
      .valueChanges.subscribe(value => {
        if (value === null) {
          this.inventoryFilterForm.get('areaControl').disable();
          this.inventoryFilterForm.get('areaControl').setValue(null);
          this.inventoryFilterForm.get('blockControl').disable();
          this.inventoryFilterForm.get('blockControl').setValue(null);
          this.inventoryFilterForm.get('meterControl').disable();
          this.inventoryFilterForm.get('meterControl').setValue(null);
        }
        else {
          this.displayAreaLoading = true;
          this._inventoryFilterService.getSpacesForDrilldownForCollections('area', this.inventoryFilterForm.get('filterDate').value, [this.inventoryFilterForm.get('zoneControl').value]).subscribe(result => {
            this.areaOptions = this.sortFilters(result, 'value');
            if (this.selectedArea != null && this.editFlag) {
              const area = this.areaOptions.find(areaId => {
                return areaId.value === this.selectedArea;
              });
              this.inventoryFilterForm.get('areaControl').setValue(area);
            }
            this.inventoryFilterForm.get('areaControl').enable();
            this.displayAreaLoading = false;
          },
          error => {
            this.snackbar.open(error['_body'], 'X');
            console.log(error);
            this.displayAreaLoading = false;
          });
        }
      });

    this.inventoryFilterForm
      .get('areaControl')
      .valueChanges.subscribe(value => {
        if (value === null) {
          this.inventoryFilterForm.get('blockControl').disable();
          this.inventoryFilterForm.get('blockControl').setValue(null);
          this.inventoryFilterForm.get('meterControl').disable();
          this.inventoryFilterForm.get('meterControl').setValue(null);
        }
        else {
          this.displayBlockLoading = true;
          this._inventoryFilterService.getSpacesForDrilldownForCollections('block', this.inventoryFilterForm.get('filterDate').value, [this.inventoryFilterForm.get('areaControl').value]).subscribe(result => {
            this.blockOptions = this.sortFilters(result, 'value');
            if (this.selectedBlock != null && this.editFlag) {
              const block = this.blockOptions.find(blockId => {
                return blockId.value === this.selectedBlock;
              });
              this.inventoryFilterForm.get('blockControl').setValue(block);
            }
            this.inventoryFilterForm.get('blockControl').enable();
            this.displayBlockLoading = false;
          },
          error => {
            this.snackbar.open(error['_body'], 'X');
            console.log(error);
            this.displayBlockLoading = false;
          });
        }
      });

    this.inventoryFilterForm
      .get('blockControl')
      .valueChanges.subscribe(value => {
        if (value === null) {
          this.inventoryFilterForm.get('meterControl').disable();
          this.inventoryFilterForm.get('meterControl').setValue(null);
        }
        else {
          this.displayMeterLoading = true;
          this._inventoryFilterService.getSpacesForDrilldownForCollections('meter', this.inventoryFilterForm.get('filterDate').value, [this.inventoryFilterForm.get('blockControl').value]).subscribe(result => {
            this.meterOptions = this.sortFilters(result, 'value');
            if (this.selectedMeter != null && this.editFlag) {
              const meter = this.meterOptions.find(meterId => {
                return meterId.value === this.selectedMeter;
              });
              this.inventoryFilterForm.get('meterControl').setValue(meter);
            }
            this.inventoryFilterForm.get('meterControl').enable();
            this.displayMeterLoading = false;
          },
          error => {
            this.snackbar.open(error['_body'], 'X');
            console.log(error);
            this.displayMeterLoading = false;
          });
        }
      });
  }

  filter() {
    let filteredCollections = this.initialCollections;

    filteredCollections = filteredCollections.filter(collection => {
    const collectionDate = new Date(collection.collectionDate);

      if (this.dateRangeFilterOperator === FilterOperator.Between && this.dateRangeStart && this.dateRangeEnd) {
        return this.dateRangeStart.getTime() < collectionDate.getTime() && collectionDate.getTime() < this.dateRangeEnd.getTime();
      }

      if (this.dateRangeStart) {
        if (this.dateRangeFilterOperator === FilterOperator.GreaterThan) {
          return this.dateRangeStart.getTime() < collectionDate.getTime();
        }
        else if (this.dateRangeFilterOperator === FilterOperator.GreaterThanOrEqual) {
          return this.dateRangeStart.getTime() <= collectionDate.getTime();
        }
        else if (this.dateRangeFilterOperator === FilterOperator.LessThan) {
          return this.dateRangeStart.getTime() > collectionDate.getTime();
        }
        else if (this.dateRangeFilterOperator === FilterOperator.LessThanOrEqual) {
          return this.dateRangeStart.getTime() >= collectionDate.getTime();
        }
      }

      return collection;
    });

    filteredCollections = filteredCollections.filter(collection => {
      if (this.amountFilterOperator === FilterOperator.Between && this.amountTo && this.amountFrom) {
        return this.amountFrom <= collection.collectionAmount && this.amountTo >= collection.collectionAmount;
      }

      if (this.amountFrom) {
        if (this.amountFilterOperator === FilterOperator.Equal) {
          return this.amountFrom === collection.collectionAmount;
        }
        else if (this.amountFilterOperator === FilterOperator.GreaterThan) {
          return this.amountFrom < collection.collectionAmount;
        }
        else if (this.amountFilterOperator === FilterOperator.GreaterThanOrEqual) {
          return this.amountFrom <= collection.collectionAmount;
        }
        else if (this.amountFilterOperator === FilterOperator.LessThan) {
          return this.amountFrom > collection.collectionAmount;
        }
        else if (this.amountFilterOperator === FilterOperator.LessThanOrEqual) {
          return this.amountFrom >= collection.collectionAmount;
        }
        else if (this.amountFilterOperator === FilterOperator.NotEqual) {
          return this.amountFrom !== collection.collectionAmount;
        }
      }
      return collection;
    });

    if (this.zoneId) {
      filteredCollections = filteredCollections.filter(collection => {
        return collection.zoneDisplayId.endsWith(this.zoneId);
      });
    }

    if (this.areaId) {
      filteredCollections = filteredCollections.filter(collection => {
        return this.areaId === collection.areaDisplayId;
      });
    }

    if (this.blockId) {
      filteredCollections = filteredCollections.filter(collection => {
        return this.blockId === collection.blockDisplayId;
      });
    }

    this.collections = filteredCollections;
  }

  disableFilter(): boolean {
    if (this.zoneId || this.areaId || this.blockId) {
      return false;
    }
    else if ((this.dateRangeFilterOperator !== FilterOperator.Between && this.dateRangeStart)
      || (this.dateRangeFilterOperator === FilterOperator.Between && (this.dateRangeStart && this.dateRangeEnd))) {
      return false;
    }
    else if ((this.amountFilterOperator !== FilterOperator.Between && this.amountFrom)
      || (this.amountFilterOperator === FilterOperator.Between && (this.amountFrom && this.amountTo))) {
      return false;
    }
    return true;
  }


  emitExport() {

    this.snackbar.open('Downloading Report', '', <MatSnackBarConfig>{
        duration: 2000
    });
    this._manualCollectionsService.requestReport()
    .subscribe(result => { this._reportService.DownloadReport('MANUAL_COLLECTIONS', result.result, 0);
    },
    error => {
      // this.snackbar.open(error['_body'], 'X');
      console.log(error);
    });
  }

  clearDateRangeFilter() {
    this.collections = this.initialCollections;
    this.dateRangeStart = null;
    this.dateRangeEnd = null;
    this.dateRangeFilterOperator = FilterOperator.Between;
    this.zoneId = null;
    this.areaId = null;
    this.blockId = null;
    this.amountFilterOperator = FilterOperator.Equal;
    this.amountFrom = null;
    this.amountTo = null;
  }

  clear(controlName): void {
    this.inventoryFilterForm.get(controlName).setValue(null);
  }

  clearAll(): void {
    this.inventoryFilterForm.reset();
    this.editFlag = false;
  }

  sortFilters(array: any[], sortBy?: string): any[]  {
    array.sort((a: any, b: any) => {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      } else if (a[sortBy] > b[sortBy]) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  }

  submitSelection(): void {
    this.pendingCollectionsToSubmit = [];
    this.dataSource = new MatTableDataSource([]);
    this.loadingSpaces = true;
    let idList = [];
    let nodeType = '';
    if (this.inventoryFilterForm.get('meterControl').value) {
      idList = this.inventoryFilterForm.get('meterControl').value;
      nodeType = 'meter';
    } else if (this.inventoryFilterForm.get('blockControl').value) {
      idList = this.inventoryFilterForm.get('blockControl').value;
      nodeType = 'block';
    } else if (this.inventoryFilterForm.get('areaControl').value) {
      idList = this.inventoryFilterForm.get('areaControl').value;
      nodeType = 'area';
    } else if (this.inventoryFilterForm.get('zoneControl').value) {
      idList = this.inventoryFilterForm.get('zoneControl').value;
      nodeType = 'zone';
    }

    var filterDate = new Date(this.inventoryFilterForm.get('filterDate').value);

    this._inventoryFilterService.getMeterSpacesForManualCollection(filterDate, idList, nodeType).subscribe(
      result => {
        const postBody = this.createPostBody(result, filterDate, this.inventoryFilterForm.get('amount').value);
        this._manualCollectionsService.postManualCollectionsDistributions(postBody).subscribe(distributionResult => {
          this.pendingCollectionsToSubmit = distributionResult;
          const distributionArray = [];
          this.pendingCollectionsToSubmit.forEach(meterDistribution => {
            distributionArray.push({ meterDisplayId: meterDistribution.meterDisplayId, fullyUtilizedAmount: meterDistribution.fullyUtilizedAmount, amountDistributed: meterDistribution.amountDistributed });

            meterDistribution.spaceDistributions.forEach(spaceDistribution => {
              distributionArray.push({ spaceDisplayId: spaceDistribution.spaceDisplayId, fullyUtilizedAmount: spaceDistribution.fullyUtilizedAmount, amountDistributed: spaceDistribution.amountDistributed });
            });
          });
          this.dataSource = new MatTableDataSource(distributionArray);
          this.dataSource.paginator = this.paginator;
          this.createFilterState();
          this.loadingSpaces = false;
        },
        error => {
          this.loadingSpaces = false;
          console.log(error);
          this.snackbar.open('Unable to distribute collection', 'X');
        });
      },
      error => {
        this.loadingSpaces = false;
        console.log(error);
        this.snackbar.open('Unable to distribute collection', 'X');
      }
    );
  }

  createPostBody(meterSpacesForManualCollectionResult: any, effectiveDate: Date, totalManualCollectionAmount: any): any {
    const spaceRatePackages = [];
    const meterSpaces = [];
    const meterDisplayIds = [];
    const spaceDisplayIds = [];
    for (const meterIdKey in meterSpacesForManualCollectionResult.meters) {
      const meterSpacesValue = meterSpacesForManualCollectionResult.meters[meterIdKey];
      if (meterSpacesValue[0]) {
        const spaceIds = [];
        meterSpacesValue.forEach(space => {
          spaceRatePackages.push({ Key: space.id, Value: space.currentRatePackageId });
          spaceIds.push(space.id);
          spaceDisplayIds.push({Key: space.id, Value: space.displayId });
        });
        meterSpaces.push({ Key: meterIdKey, Value: spaceIds });
        meterDisplayIds.push({ Key: meterIdKey, Value: meterSpacesValue[0].currentMeterDisplayId });
      }
    }

    effectiveDate = new Date(effectiveDate);
    const effectiveDateFirstOfMonth = new Date(effectiveDate.getFullYear(), effectiveDate.getMonth(), 1);

    const postBody = {
      EffectiveDate : effectiveDateFirstOfMonth,
      TotalManualCollectionAmount : totalManualCollectionAmount,
      SpaceRatePackages: spaceRatePackages,
      MeterSpaces : meterSpaces,
      MeterDisplayIds : meterDisplayIds,
      SpaceDisplayIds: spaceDisplayIds,
      RatePackages: meterSpacesForManualCollectionResult.ratePackages
    };
    return postBody;
  }

  createFilterState() {
    const manualCollectionMeterDistributions = [];
    this.pendingCollectionsToSubmit.forEach(collection => {
      const newMeterCollection = { MeterId: collection.meterId, MeterDisplayId: collection.meterDisplayId, AmountDistributed: collection.amountDistributed, FullyUtilizedAmount: collection.fullyUtilizedAmount, ManualSpaceCollections: [] };
      collection.spaceDistributions.forEach(spaceCollection => {
        const newSpaceCollection = { SpaceId: spaceCollection.spaceId, SpaceDisplayId: spaceCollection.spaceDisplayId, AmountDistributed: spaceCollection.amountDistributed, FullyUtilizedAmount: spaceCollection.fullyUtilizedAmount };
        newMeterCollection.ManualSpaceCollections.push(newSpaceCollection);
      });
      manualCollectionMeterDistributions.push(newMeterCollection);
    });
    this.filtersAtSubmission = {
      CollectionAmount: this.inventoryFilterForm.get('amount').value,
      CollectionDate: this.inventoryFilterForm.get('filterDate').value,
      PayUnit: this.inventoryFilterForm.get('payUnit').value,
      ManualCollectionMeterDistributions: manualCollectionMeterDistributions,
    };
    if (this.inventoryFilterForm.get('areaControl').value) {
      this.filtersAtSubmission.AreaFilter = {Key: this.inventoryFilterForm.get('areaControl').value.key, Value: this.inventoryFilterForm.get('areaControl').value.value};
    }
    if (this.inventoryFilterForm.get('zoneControl').value) {
      this.filtersAtSubmission.ZoneFilter = {Key: this.inventoryFilterForm.get('zoneControl').value.key, Value: this.inventoryFilterForm.get('zoneControl').value.value};
    }
    if (this.inventoryFilterForm.get('blockControl').value) {
      this.filtersAtSubmission.BlockFilter = {Key: this.inventoryFilterForm.get('blockControl').value.key, Value: this.inventoryFilterForm.get('blockControl').value.value};
    }
    if (this.inventoryFilterForm.get('meterControl').value) {
      this.filtersAtSubmission.MeterFilter = {Key: this.inventoryFilterForm.get('meterControl').value.key, Value: this.inventoryFilterForm.get('meterControl').value.value};
    }
    if (this.editFlag) {
      this.filtersAtSubmission.ManualNodeCollectionId = this.editedCollectionId;
    }
  }

  getTotalDistribution(): number {
    let sum = 0;
    this.pendingCollectionsToSubmit.forEach(collection => {
      sum += collection.amountDistributed;
    });
    return sum;
  }

  enterCollection() {
    this.confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '240px',
      width: '400px',
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to enter this collection information into the system?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.editFlag) {
          this._manualCollectionsService.saveManualCollectionsDistributions(this.filtersAtSubmission).subscribe(result => {
            this.snackbar.open('Manual Collection Information Saved', null, <MatSnackBarConfig>{
              duration: 1500,
            })
            .afterDismissed().subscribe(dismissed => {
              window.location.reload();
            });
          });
        }
          else {
            this._manualCollectionsService.updateManualCollection(this.filtersAtSubmission).subscribe(result => {
              this.snackbar.open('Manual Collection Information Saved', null, <MatSnackBarConfig>{
                duration: 1500,
              })
              .afterDismissed().subscribe(dismissed => {
                window.location.reload();
              });
            });
          }
      }
    });
  }

  delete(collection: Collection): void {
    const dialogRef = this.dialog.open(DeleteManualCollectionsConfirmationComponent, {
      width: 'auto',
      height: '150px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._manualCollectionsService.deleteManualCollection(collection.id).subscribe(result => {
          window.location.reload();
        });
      }
    });
  }

  edit(collection: Collection): void {
    this.editFlag = true;
    if (collection.zoneDisplayId || collection.zoneFilter !== StringExtensions.EmptyGuid) {
      const zone = this.zoneOptions.find(zoneId => {
        return zoneId.value === collection.zoneDisplayId;
      });
      this.inventoryFilterForm.get('zoneControl').setValue(zone);
    }
    if (collection.areaDisplayId || collection.areaFilter !== StringExtensions.EmptyGuid) {
      this.selectedArea = collection.areaDisplayId;
    }
    if (collection.blockDisplayId || collection.blockFilter !== StringExtensions.EmptyGuid) {
      this.selectedBlock = collection.blockDisplayId;
    }
    if (collection.meterDiplayId || collection.meterFilter !== StringExtensions.EmptyGuid) {
      this.selectedMeter = collection.meterDiplayId;
    }
    this.inventoryFilterForm.get('filterDate').setValue(collection.collectionDate);
    this.inventoryFilterForm.get('amount').setValue(collection.collectionAmount);
    this.inventoryFilterForm.get('payUnit').setValue(collection.payUnit);
    this.editedCollectionId = collection.id;
  }

  viewCollectionDetails(collection: any): void {
    const dialogRef = this.dialog.open(PreviousCollectionComponent, {
      width: 'auto',
      height: 'auto',
      maxHeight: '500px',
      data: { collectionId: collection.id }
    });
  }

}
