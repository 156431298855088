import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrueUpHttpService } from '../../../../true-up-http.service';
import { environment } from '../../../../environment';
import { InoperableMeterUploadSummaryApiModel } from '../models/inoperable-meter-upload-summary';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class DowntimeUploadService {
  constructor(
    private trueUpDataHttpService: TrueUpHttpService,
    protected errorHandler: ErrorHandler
  ) {}

  public uploadEndpoint: string = `${environment.TRUE_UP_API_BASE}/InoperableMetersImport/Import`;

  public getAllManualCollections(): Observable<InoperableMeterUploadSummaryApiModel[]> {
    return this.trueUpDataHttpService
      .get('/InoperableMetersImport', null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    this.errorHandler.handleError(error);
    return Promise.reject(error.message || error);
  }
}
