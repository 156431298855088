import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HoursOfRestriction } from '../models/hours-of-restriction';

@Injectable()
export class HoursOfRestrictionService {

    constructor(private http: HttpClient) { }

    public getHoursOfRestrictionById(id: string): Observable<HoursOfRestriction> {
        return this.http.get('/HoursOfRestriction/' + id)
          .pipe(
            map(response => response != null ? this.jsonToHoursOfRestriction(response) : null),
            catchError((error) => this.handleError(error))
          );
      }

    public getAllHoursOfRestriction(): Observable<HoursOfRestriction[]> {
        return this.http.get('/HoursOfRestriction/')
          .pipe(
            map(response => response != null ? this.jsonToHoursOfRestrictionList(response) : null),
            catchError((error) => this.handleError(error))
          );
    }

    public setHoursOfRestriction(hoursOfRestriction: HoursOfRestriction): Observable<any> {
        return this.http.post('/HoursOfRestriction', JSON.stringify(hoursOfRestriction))
        .pipe(map(response => response));
    }

    jsonToHoursOfRestriction(json: any): HoursOfRestriction {
        const toReturn = new HoursOfRestriction();
        for (const prop in json) {
            toReturn[prop] = json[prop];
        }
        return toReturn;
    }

    jsonToHoursOfRestrictionList(json: any): Array<HoursOfRestriction> {
        const toReturn = new Array<HoursOfRestriction>();

        json.forEach(hoo => {
            toReturn.push(this.jsonToHoursOfRestriction(hoo));
        });

        return toReturn;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
