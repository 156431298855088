import { ParkingTypeConstants } from './../meter-constants';
import { EditMeterDialogComponent } from './../edit-meter-dialog/edit-meter-dialog.component';
import { SpaceSummary } from './../../spaces/space-summary';
import { Component, OnInit, ErrorHandler, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';


import { Meter } from '../meter';
import { MeterService } from '../meters.service';

import { RatePackageService } from '../../rate-packages/services/rate-package.service';
import { SpaceService } from './../../spaces/spaces.service';
import { SpaceStatusConstants } from './../../spaces/space-constants';

import * as _ from 'lodash';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RatePackageDetailComponent } from '../../rate-packages/rate-package-detail/rate-package-detail.component';
import { TokenCache } from '../../../security/token-cache';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { MeterStatusConstants } from '../meter-constants';
import {MonthlyTrueUpCalculationFilterRepresentation} from '../../../trueup/monthly-calculations/monthly-true-up-calculation-filter-representation-base';

declare var google: any;

@Component({
    selector: 'app-meter',
    templateUrl: './meter-detail.component.html',
    styleUrls: ['./meter-detail.component.css']
})

export class MeterDetailComponent implements OnInit {

    meter: Meter;
    map: any;
    activeSpaces: SpaceSummary[];
    ratePackage: any;
    ratePackageTabLabel = 'Rate Package';
    readonly spacesTabHeaderText = 'Spaces';

    spaceTabHeader = this.spacesTabHeaderText;
    numberOfActiveSpaces: string;

    originalMeterId: string;

    displaySignsOnlyChip: boolean;

    @ViewChild(RatePackageDetailComponent, {static: true}) ratePackageDetailComponent: RatePackageDetailComponent;

    dialogRef: MatDialogRef<EditMeterDialogComponent>;

    showEditMeterButton: boolean;
    showHardwareInformation: boolean;
    systemStartDate: Date;
    errorMessage: string;
    showTrueUpTab: boolean;
    filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation;

    locationTypes= [ParkingTypeConstants.OnStreet, ParkingTypeConstants.ParkingLot, ParkingTypeConstants.CLZ, ParkingTypeConstants.WrigleyvilleEventParking];

    constructor(private meterService: MeterService, private route: ActivatedRoute, private router: Router,
                private ratePackageService: RatePackageService, private location: PlatformLocation,
                private spaceService: SpaceService, private _errorHandler: ErrorHandler,
                private dialog: MatDialog, private snackBar: MatSnackBar, private tokenCache: TokenCache,
                private startDateService: SystemStartTimeService) {
        location.onPopState(() => {
            const url = '/inventorymanagement/meters/' + this.originalMeterId;
            this.router.navigate([url]);
            window.location.reload();
        });
     }

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('id');
        this.originalMeterId = id;

        this.meterService.getMeterById(id).subscribe(
            result => {
                this.meter = result;
                this.filterRepresentation = new MonthlyTrueUpCalculationFilterRepresentation();
                this.filterRepresentation.MeterId = this.meter.displayId;
            },
            error => {
              this.errorMessage = error._body;
                console.log(error);
            });

          this.startDateService.getInitialSystemStartTime().subscribe(result => {
            this.systemStartDate = result;
          },
          error => {
            console.log('error');
            this._errorHandler.handleError(error);
          });

        this.spaceService.getSpacesByMeterId(id).subscribe(
            result => {
                if (result != null) {
                    this.activeSpaces = result.filter(
                        space => {
                            return space.currentStatus.toLowerCase() === SpaceStatusConstants.active.toLowerCase()
                                    || space.currentStatus.toLowerCase() === SpaceStatusConstants.reserved.toLowerCase();
                        });

                    const hasAroundTheCornerBlockId = this.activeSpaces.filter(space => {
                        return space.aroundTheCornerBlockId != null && space.currentMeterId == id;
                    });

                    this.displaySignsOnlyChip = hasAroundTheCornerBlockId.length > 0;

                    this.loadRatePackage(this.activeSpaces);
                }
            },
            error => {
                this._errorHandler.handleError(error);
            }
        );

        this.showEditMeterButton = this.tokenCache.checkForCPMUser() || this.tokenCache.checkForLAZUser();
        this.showHardwareInformation = !this.tokenCache.checkForCityUser();
        this.showTrueUpTab = this.tokenCache.checkForCPMUser() || this.tokenCache.checkForCityUser();
    }

    showTempRemovedChip(meter: Meter): boolean {
        if (meter.currentStatus === MeterStatusConstants.inactive && meter.isPhysicallyRemoved) {
            return true;
        }
        return false;
    }

    goToVirtualMeter(id) {
        const url = '/inventorymanagement/meters/' + id;
        this.router.navigate([url]);
        window.location.reload();
    }

    goToParentMeter(id) {
        const url = '/inventorymanagement/meters/' + id;
        this.router.navigate([url]);
        window.location.reload();
    }

    changeNumberSpaces(event) {
        this.numberOfActiveSpaces = event;
        this.spaceTabHeader = this.spacesTabHeaderText + ` (Active: ${event})`;
    }

    loadRatePackage(activeSpaces) {
        if (activeSpaces.length === 0) {
          return;
        }
        // TODO: handle when there is more than one
        const ratePackageId: string = _.map(activeSpaces, (s: any) => s.currentRatePackageId)[0];
        this.ratePackageService.getRatePackageById(ratePackageId)
        .subscribe(
            result => {
                this.ratePackage = result;
            },
            error => {
                console.log(error);
            });
    }

    loadMap() {
        const address = this.meter.location.number + ' ' + this.meter.location.streetDirection + ' ' + this.meter.location.streetName + ', Chicago, IL';

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'location': {
          'lat': this.meter.location.latitude,
          'lng': this.meter.location.longitude
          }
        }, function (results, status) {
            if (status === 'OK') {
                const mapProp = {
                    center: results[0].geometry.location,
                    zoom: 15
                };

                this.map = new google.maps.Map(document.getElementById('map'), mapProp);

                const marker = new google.maps.Marker({
                    map: this.map,
                    position: results[0].geometry.location
                });
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    updateMeterAttributes(updatedMeter: Meter) {
        this.meter.displayId = updatedMeter.displayId;
        this.meter.description = updatedMeter.description;
        this.meter.location.number = updatedMeter.location.number;
        this.meter.location.latitude = updatedMeter.location.latitude;
        this.meter.location.longitude = updatedMeter.location.longitude;
        this.meter.merchantAccount = updatedMeter.merchantAccount;
        this.meter.merchantId = updatedMeter.merchantId;
        this.meter.serialNumber = updatedMeter.serialNumber;
        this.meter.eLockNumber = updatedMeter.eLockNumber;
        this.meter.sim = updatedMeter.sim;
        this.meter.parkingTypeId = updatedMeter.parkingTypeId;
        this.meter.foundationType = updatedMeter.foundationType;
        this.meter.parkingType = this.locationTypes.filter(locationType => {
          return locationType.value === updatedMeter.parkingTypeId;
        })[0].title;
    }

    editMeter() {
        this.dialogRef = this.dialog.open(EditMeterDialogComponent, {
            height: 'auto',
            width: '450px',
          });
          // Make a copy of meter object and pass that to dialog, so what's modified in meter doesn't affect what's displaying on Meter detail
          const meterUpdateModel = JSON.parse(JSON.stringify(this.meter));
          this.dialogRef.componentInstance.meter = meterUpdateModel;

          this.dialogRef.afterClosed().subscribe(result => {
           if (result) {
               this.meterService.updateMeterAttributes(result).subscribe(
                   result => {
                       if (result) {
                           this.updateMeterAttributes(result);
                           this.snackBar.open('Successfully updated meter attributes', '', <MatSnackBarConfig>{
                            duration: 2000,
                          });
                       }
                   },
                   error => {
                    this.snackBar.open(error['_body'], 'X');
                   });
           }
          });
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        if (this.ratePackage && tabChangeEvent.tab.textLabel === this.ratePackageTabLabel) {
            this.ratePackageDetailComponent.ngOnChanges();
        }
    }
}
