// This file corresponds with src\app\trueup\monthly-calculations\monthly-true-up-calculation-filter-properties.ts

import { PageEvent } from '@angular/material/paginator';

import { PaginationParameters } from '../../../core/models/pagination-paramters';
import { CollectionsFilterRepresentation } from './collections-filter-representation-base';

export class CollectionsFilterProperties {
  paginationParameters: PaginationParameters;
  filterRepresentation: CollectionsFilterRepresentation;
}
