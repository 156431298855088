import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { Http, Headers, HttpBackend, HttpRequestOptions, HttpRequestOptions, Response, ResponseContentType } from '@angular/http';
import {HttpClient, HttpHeaders, HttpBackend, HttpResponse} from '@angular/common/http';
import { HttpRequestOptions } from './core/http-request-options';

import { TokenCache } from './security/token-cache';
import { environment } from './environment';

@Injectable()
export class TrueUpHttpService extends HttpClient {
    headers: HttpHeaders;
    postOptions: HttpRequestOptions;

    constructor(backend: HttpBackend, options: HttpRequestOptions, private tokenCache: TokenCache) {
        super(backend);
        this.setHeaders();
        this.tokenCache.onTokenChange.subscribe(() => this.setHeaders());
    }

    public get(url: string, options: any): Observable<any> {
        options = options || new HttpRequestOptions();

        if (!options.headers) {
          options.headers = this.headers;
        }

        url = `${environment.TRUE_UP_API_BASE}${url}`;
        return super.get(url, options);
    }


    public getFile(url: string, options: HttpRequestOptions): Observable<HttpResponse<Blob>> {
        // allow changing response type for file downloads
        if (!options) {
            var option = new HttpRequestOptions();

            option.headers = this.headers;
            option.headers = option.headers.set('Accept', 'application/*');
        }

        url = `${environment.TRUE_UP_API_BASE}${url}`;
        return super.get(url, { headers: option.headers, observe: 'response', responseType: 'blob' });
    }

    public post(url: string, body: any, options?: any): Observable<any> {
        url = `${environment.TRUE_UP_API_BASE}${url}`;
        return super.post(url, body, this.getRequestOption(this.postOptions));
    }

    public put(url: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = { headers: this.headers };
        }
        url = `${environment.TRUE_UP_API_BASE}${url}`;
        return super.put(url, body, options);
    }

    public delete(url: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = { headers: this.headers };
        }
        url = `${environment.TRUE_UP_API_BASE}${url}`;
        return super.post(url, body, options);
    }

    private setHeaders() {
        this.headers = new HttpHeaders({
            'Authorization': this.tokenCache.getBearerToken()
        });

        this.postOptions = {
            headers: new HttpHeaders({
                'Authorization': this.tokenCache.getBearerToken(),
                'Content-Type': 'application/json'
            })
        };
    }

    private getRequestOption(option: HttpRequestOptions): any {
      const options = {
        headers: option.headers,
        observe: option.observe,
        params: option.params,
        reportProgress: option.reportProgress,
        responseType: option.responseType,
        withCredentials: option.withCredentials
      };

      return options;
    }
}
