import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-comments-dialog',
    templateUrl: './comments-dialog.component.html',
    styleUrls: ['./comments-dialog.component.css']
})
export class CommentsDialogComponent implements OnInit {
    public comments: string;
    public isRejection: boolean;
    constructor(public dialogRef: MatDialogRef<CommentsDialogComponent>) {
     }

    ngOnInit() {  }

    closeDialog(submitted: boolean) {
        if(submitted) {
            this.dialogRef.close(this.comments);
        }
        else {
            this.dialogRef.close(null);
        }
    }
}
