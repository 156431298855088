exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\r\n.login-card {\r\n  width: 50%;\r\n  height: auto;\r\n  margin: auto;\r\n  top:100px;\r\n}\r\n\r\nmat-card-content {\r\n  padding: 2%;\r\n  height:150px;\r\n}\r\n\r\nmat-spinner {\r\n  height:35px;\r\n}\r\n\r\nmat-card {\r\n  padding:0px;\r\n}\r\n\r\n.login-form {\r\n  padding-top:25px;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();