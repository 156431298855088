import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import {SystemStartTimeService} from '../../system-start-time.service';
import {SystemSummaryReportService} from '../services/system-summary-report.service';

@Component({
  selector: 'app-system-summary-reports',
  templateUrl: './system-summary.component.html',
  styleUrls: ['./system-summary.component.css']
})
export class SystemSummaryComponent implements OnInit, OnDestroy {

  reportingQuarters: number[] = [1, 2, 3, 4];
  reportingYears: number[] = [];

  reportLoading: boolean;
  subscriptions: Subscription[] = [];

  reportingQuarter: number;
  reportingYear: number;

  constructor(
    private startTimeService: SystemStartTimeService,
    private snackBar: MatSnackBar,
    private reportService: SystemSummaryReportService,
    private errorHandler: ErrorHandler) { }

  ngOnInit() {

    this.startTimeService.getInitialSystemStartTime().subscribe(result => {
        for (let i = result.getFullYear(); i <= new Date().getFullYear(); i++) {
          this.reportingYears.push(i);
        }

        this.reportingYears = this.reportingYears.reverse();

      },
      error => {
        console.log('error');
        this.errorHandler.handleError(error);
      });


    this.subscriptions.push(this.reportService.isLoading
      .subscribe(response => {
        if (response.timeout) {
          this.snackBar.open('The report you are requesting is too large. Please filter your results further.', '', <MatSnackBarConfig>{
            duration: 2000,
          });
        }
        if (response.reportType == SystemSummaryReportService.SYSTEM_SUMMARY_RESERVED_POWER_KEY) {
          this.reportLoading = response.loading;
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  export() {
    if(!this.reportingYear || !this.reportingQuarter) {
      this.snackBar.open('Please enter reporting year and reporting quarter for Completion Date filter', '', <MatSnackBarConfig>{
        duration: 2000,
      });
      return;
    }

    this.reportLoading = true;

    this.reportService.RequestSystemSummaryReport(this.reportingYear, this.reportingQuarter).subscribe(
      result => {
        this.reportService.DownloadSystemSummaryReport(result.result);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }



}
