exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".label {\r\n  width: 100px;\r\n  overflow-wrap: normal;\r\n}\r\n\r\n.wide-column {\r\n  width:125px;\r\n}\r\n\r\n.detail {\r\n  width: 100px;\r\n}\r\n\r\n.material-icons, .mat-icon-buttom  {\r\n  font-size: 75%;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();