import { HttpHeaders, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';

// @Injectable()
export class HttpRequestOptions {
    headers?: HttpHeaders;
    observe?: string;
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;

    constructor(headers: HttpHeaders = null,
                observe: string = 'body',
                params: HttpParams = null,
                reportProgress: boolean = null,
                responseType: string = 'json',
                withCredentials: boolean = null) {
      this.headers = headers;
      this.observe = observe;
      this.params = params;
      this.reportProgress = reportProgress;
      this.responseType = responseType;
      this.withCredentials = withCredentials;
    }
}
