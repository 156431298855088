export class SpaceCollection {
    spaceId: string;
    spaceDisplayId: string;
    distributionAmount: number;
    distributionPercentage: number;
    fullyUtilizedAmount: number;
}

export class SpaceDisplayIdKeyValuePair 
{
    id: string;
    displayId: string;
}