import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { MeterDowntimeReportService } from '../services/meter-downtime-report.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-meter-downtime-reports',
    templateUrl: './meter-downtime.component.html',
    styleUrls: ['./meter-downtime.component.css']
})
export class MeterDowntimeReportComponent implements OnInit, OnDestroy {
    startDate: string;
    endDate: string;
    reportLoading: boolean;
    subscriptions: Subscription[] = [];

    constructor(private snackBar: MatSnackBar, private reportService: MeterDowntimeReportService, private errorHandler: ErrorHandler) { }

    ngOnInit() {
        this.subscriptions.push(this.reportService.isLoading
            .subscribe(response => {
                if(response.timeout) {
                    this.snackBar.open('The report you are requesting is too large. Please filter your result.', '', <MatSnackBarConfig>{
                        duration: 2000
                    });
                }
                if(response.reportType == MeterDowntimeReportService.METER_DOWNTIME_KEY) {
                    this.reportLoading = response.loading;
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    export() {
        if(!this.startDate || !this.endDate) {
            this.snackBar.open('Please enter Start Date and End Date for Completion Date filter', '', <MatSnackBarConfig>{
                duration: 2000,
            });
            return;
        }

        this.reportLoading = true;

        this.reportService.RequestMeterDowntimeReport(this.startDate, this.endDate).subscribe(
            result => {
                this.reportService.DownloadWorkOrderReport(result.result);
            },
            error => {
                this.errorHandler.handleError(error);
            }
        );
    }

    clearStartDate() {
        this.startDate = undefined;
    }

    clearEndDate() {
        this.endDate = undefined;
    }
}