import { RegularRateAdjustmentInventoryChanges } from './../inventory-information/regular-rate-adjustment/models/regular-rate-adjustment-inventory-changes';
import { MeterInventoryChange } from './../inventory-information/models/meter-inventory-change';
import { BlockInventoryChange } from './../inventory-information/models/block-inventory-change';
import { SpaceInventoryChange } from './../inventory-information/models/space-inventory-change';
import { Action } from './action';
import { UpdateMeterAttributesInventoryChanges } from '../inventory-information/models/update-meter-attributes';
import { WorkOrderActionResponse } from './approval-models';
import { LoadingZoneImpactedSpace } from './loading-zone-impacts/loading-zone-impacted-spaces';
import { LoadingZoneImpactChangeGroup } from './loading-zone-impacts/loading-zone-impact-change-group';

export class WorkOrderAction {
  action: Action;

  actionId: number;

  actionName: string;

  blockIDs: string[];

  blockInventoryChanges: BlockInventoryChange[];

  currentStatus: number;

  dueDate: string;

  effectiveDate: Date;

  formValid: boolean;

  historicalChange: string;

  id: string;

  letterOfDirection: string;

  meterIDs: string[];

  projectedCompletionDate: Date;

  selectedDueDateRule: DueDateRule;

  spaceIDs: string[];

  meterInventoryChanges: MeterInventoryChange[];

  spaceInventoryChanges: SpaceInventoryChange[];

  summationSentence: string;

  workOrderActionJson: string;

  workOrderActionResponses: WorkOrderActionResponse[];

  workOrderActionStatuses: WorkOrderActionStatus[];

  workOrderId: number;

  // fields used for inventory changes page TODO(Joe + Jackson) Does this make sense to do it this way?
  allFormsReadOnly = true;
  meterChangesExist = false;
  blockChangesExist = false;
  spaceChangesExist = false;
  showActivateButton = false;
  meterToId: string; // this feels really hacky Jackson
  meterId: string; // this feels really hacky Jackson
  blockToId: string;
  blockInventoryChangesFormComplete = false;
  meterInventoryChangesFormComplete = false;
  spaceInventoryChangesFormComplete = false;

  actionIndex: number;

  areaId: string;
  areaName: string;

  contractType: string;
  updateMeterAttributesInventoryChanges: UpdateMeterAttributesInventoryChanges[];

  regularRateAdjustmentInventoryChanges: RegularRateAdjustmentInventoryChanges[];
  isSystemGenerated: boolean;

  CLZImpactedSpaces: LoadingZoneImpactedSpace[];
  loadingZoneImpactChangeGroups: LoadingZoneImpactChangeGroup[];
}

export class WorkOrderActionStatus {
  dateCreated: string;

  id: string;

  workOrderActionStatusTypeId: number;
}

export class DueDateRule {
  id: number;
  name: string;
  daysToComplete: number;
  dayType: string;
}
