import { Injectable } from '@angular/core';
import { FileDownloadResult } from '../../core/models/file-download-result';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';

@Injectable()
export class TempRemovedMeterService {

  constructor(private http: ReportingHttpService,
    private _fileService: FileDownloadService) { }

  public getAllTempRemovedMeters(): Observable<any> {
    const endpoint = `/TemporarilyRemovedMeter`;

    return this.http.get(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }


  public exportMeters(): Observable<any> {
    const endpoint = `/reports/tempremovedmeters`;

    return this.http.getFile(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
}


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
