import { FormGroup } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { GenerateFormGroup} from './../generate-form-group';
import { TokenCache } from '../../../../security/token-cache';

export class BaseActionForm {
  formGroup: FormGroup;
  tokenCache: TokenCache;
  warnings = new Array<string>();
  actionIndex: string;
  hasHistoricalEffectiveDate: boolean;
  historicalEffectiveDate: Date;
  previousActivationEffectiveDate: Date;

  constructor(actionIndex: string, actionFields: ActionField[], tokenCache: TokenCache, hasHistoricalEffectiveDate: boolean, historicalEffectiveDate?: Date, previousActivationEffectiveDate?: Date) {
    this.formGroup = GenerateFormGroup.toFormGroup(actionIndex, actionFields);

    this.formGroup.valueChanges.subscribe(formValue => {
      this.displayConditionalFieldsOnFormChanges(actionFields, this.formGroup);
   });

   this.actionIndex = actionIndex;
   this.hasHistoricalEffectiveDate = hasHistoricalEffectiveDate;
   this.historicalEffectiveDate = historicalEffectiveDate;
   this.previousActivationEffectiveDate = previousActivationEffectiveDate;
   this.tokenCache = tokenCache;
  }

  private displayConditionalFieldsOnFormChanges(actionFields: ActionField[], formGroup: FormGroup) {
    actionFields.forEach(field => {
      if (field.conditionalFieldId) {
        const conditionalField = this.getConditionalField(field, actionFields);

        if (formGroup.get(this.actionIndex + conditionalField.id.toString()).value) {
          field.displayField = (field.conditionalValue === formGroup.get(this.actionIndex + conditionalField.id.toString()).value.toString());
        }
        else if (conditionalField.fieldType === 'checkbox') {
          field.displayField = (field.conditionalValue === 'false');
        }
      }
      else {
        field.displayField = true;
      }
    });
  }

  private getConditionalField(field: ActionField, actionFields: ActionField[]): ActionField {
    return actionFields.filter(fieldInFilter => {
      return fieldInFilter.fieldId === field.conditionalFieldId;
    })[0];
  }
}
