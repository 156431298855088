exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "mat-row.log-row{\r\n  max-height: none !important;\r\n}\r\n\r\n.mat-table {\r\n  margin-left: 10px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.mat-cell:last-of-type, .mat-header-cell:last-of-type{\r\n   padding-left: 10px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();