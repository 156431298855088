import { InventoryChangeBase } from './inventory-change-base';

export class MeterInventoryChange extends InventoryChangeBase {

  id: string;

  latitude: number;

  longitude: number;

  meterAddress: string;

  meterId: string;

  meterDisplayId: string;

  meterType: string;

  newAssignedBlockId: string;

  newAssignedBlockName: string;

  newAssignedAreaName: string;

  newAssignedZoneName: string;

  newClosureAllowanceId: string;

  newClosureAllowanceName: string;

  currentClosureAllowanceId: string;

  currentClosureAllowancename: string;

  newMerchantId: string;

  newFoundationType: string;

  newMeterStatusId: string;

  newMeterTypeId: string;

  newContractTypeId: string;

  newContractTypeName: string;

  currentContractTypeId: string;

  currentContractTypeName: string;

  newParkingTypeId: string;

  newParkingTypeName: string;

  currentParkingTypeId: string;

  currentParkingTypeName: string;

  sideOfStreet: string;

  status: string;

  streetNumber: number;

    changedDateTime: Date;

  workOrderActionId: string;
}
