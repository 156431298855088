export class EditedCollection {

  collectionId: string;

  comments: string;

  changedBy: string;

  editedDate: Date;

  id: string;

  meterId: string;

  newCollectionDate: Date;

  newCollectionAmount: number;

  oldCollectionDate: Date;

  oldCollectionAmount: number;
}
