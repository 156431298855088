import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { Injectable } from '@angular/core';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';
import { HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import { TokenCache } from '../../security/token-cache';
import { Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class MeterDowntimeReportService extends ReportingHttpService {
    constructor(backend: HttpBackend, options: HttpRequestOptions, tokenCache: TokenCache, fileService: FileDownloadService) {
        super(backend, options, tokenCache, fileService);
    }

    public RequestMeterDowntimeReport(startDate: any, endDate: any) {
        const start = DateTimeUtility.parseDateString(startDate, 'M/D/YYYY');
        const end = DateTimeUtility.parseDateString(endDate, 'M/D/YYYY');

        const endpoint = `/reports/meterdowntime/${start}/to/${end}`;

        return this.post(endpoint, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    public RequestMeterDowntimeReportForMeter(meterId: string, reportingYear: number) {
        const endpoint = `/reports/meterdowntime/${meterId}`;

        return this.post(endpoint, reportingYear)
          .pipe(catchError((error) => this.handleError(error)));
    }

    public getInoperableRecordsForMeter(meterId: string): Observable<any[]> {
        return this.get('/Occupancy/InoperableMeter/' + meterId, null)
          .pipe(catchError((error) => this.handleError(error)));
    }

    //Correlates to QueueItemTypeMetaData in Reporting API
    public static METER_DOWNTIME_KEY: string = 'METER_DOWNTIME';

    public DownloadWorkOrderReport(key: string) {
        return super.DownloadReport(MeterDowntimeReportService.METER_DOWNTIME_KEY, key, 0);
    }
}
