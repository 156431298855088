import { Input, ErrorHandler, Component, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { MeterDistributionLogDialog } from './meter-distribution-log-dialog/meter-distribution-log-dialog.component';
import { MeterDistribution, MeterDistributionKeyValuePair } from './models/meter-distribution';
import { MonthNameToMonth, MonthsInFiscalYear } from './models/month-name-dictionary';
import { MeterDistributionService } from './meter-distribution.service';
import { SpaceService } from './../../spaces/spaces.service';
import { MeterSpaceTrueUpDataSource } from './true-up-data-source';
import { MeterDistributionPanel } from './models/meter-distribution-panel';
import { SystemStartTimeService } from '../../../system-start-time.service';

@Component({
  selector: 'app-meter-true-up',
  templateUrl: './meter-true-up.component.html',
  styleUrls: ['./meter-true-up.component.css']
})

export class MeterTrueUpComponent implements AfterViewInit  {
  displayedColumns = ['spaceId', 'distribution', 'distributionPercentage', 'fullUtilizationAmount'];
  selectedYear: string;
  dataSource: MeterSpaceTrueUpDataSource;
  @Input() meterId: string;

  availableFiscalYear = [];

  distributionPanels: MeterDistributionPanel[] = [];
  showExpansions = false;
  dialogRef: MatDialogRef<MeterDistributionLogDialog>;

  constructor(private _errorHandler: ErrorHandler,
    public dialog: MatDialog, private meterDistributionService: MeterDistributionService,
    private spaceService: SpaceService, private systemStartTimeService: SystemStartTimeService) {

  }

  ngAfterViewInit() {
    this.initializeFiscalYearSelectValues();
  }

  initializeFiscalYearSelectValues() {
    this.systemStartTimeService.getInitialSystemStartTime().subscribe(
      result => {
        const systemStartYear = result.getFullYear();
        const currentYear = new Date().getFullYear() + 1;
        for (let year = Number(currentYear); year >= systemStartYear; year--) {
          this.availableFiscalYear.push(
            {
              value: String(year),
              viewValue: `FY${year}`
            });
        }
        this.selectedYear = this.availableFiscalYear[0].value;

        if (this.meterId) {
          this.onSelectChange(this.selectedYear);
        }

      },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
      }
    );

  }

  initializeExpansionPanel() {

    this.meterDistributionService.getMeterDistributionsForYear(Number(this.selectedYear), this.meterId)
      .subscribe(
      result => {
        const meterDistributions = this.buildMeterDistributionsFromKeyValuePairs(result);

        for (let i = 0; i < 12; i++) {
          const distributionPanel: MeterDistributionPanel = {
            dataSource: null,
            isDisabled: true,
            isExpanded: false,
            month: MonthsInFiscalYear[i],
            meterDistribution: null
          };

          const distribution = meterDistributions.find(dist => dist.forMonth === MonthsInFiscalYear[i]);
          if (distribution != null) {
            distributionPanel.isDisabled = false;
            distributionPanel.meterDistribution = distribution;
            distributionPanel.dataSource = new MeterSpaceTrueUpDataSource([]);
          }

          this.distributionPanels.push(distributionPanel);
        }
      },
      error => {
        this._errorHandler.handleError(error);
      });
  }

  buildMeterDistributionsFromKeyValuePairs(keyValuePairs: MeterDistributionKeyValuePair[]): MeterDistribution[] {
    const meterDistributions: MeterDistribution[] = [];
    for (let i = 0; i < keyValuePairs.length; i++) {
      const distribution: MeterDistribution = {
        forMonth: keyValuePairs[i].meterDistribution.forMonth,
        meterDistributionAmount: keyValuePairs[i].meterDistribution.meterDistributionAmount,
        calculationTimeStamp: keyValuePairs[i].meterDistribution.calculationTimeStamp,
        meterId: keyValuePairs[i].meterDistribution.meterId
      };
      meterDistributions.push(distribution);
    }
    return meterDistributions;
  }

  toggleMonthExpansion(monthlyDistribution: MeterDistributionPanel) {
    monthlyDistribution.isExpanded = !monthlyDistribution.isExpanded;
    if (monthlyDistribution.isExpanded && monthlyDistribution.dataSource && monthlyDistribution.dataSource.spaceDistributions.length === 0) {
      this.GetSpaceDistributions(monthlyDistribution);
    }
  }

  GetSpaceDistributions(monthlyDistribution: MeterDistributionPanel) {
    const monthExpanded = MonthNameToMonth[monthlyDistribution.month];
    const year = monthExpanded <= 2 ? Number(this.selectedYear) + 1 : Number(this.selectedYear);

    this.meterDistributionService.getSpaceDistributionForMonth(year, monthExpanded, this.meterId)
      .subscribe(
      result => {
        const spaceDistributions = result;
        const guids = spaceDistributions.map(dist => dist.spaceId);

        this.spaceService.getSpaceDisplayIds(guids).subscribe(
          result => {
            const displayIdKeyValuePair = result;
            for (let i = 0; i < displayIdKeyValuePair.length; i++) {
              const spaceDistribution = spaceDistributions.filter(dist => dist.spaceId === displayIdKeyValuePair[i].id)[0];
              spaceDistribution.spaceDisplayId = displayIdKeyValuePair[i].displayId;
            }
            monthlyDistribution.dataSource = new MeterSpaceTrueUpDataSource(spaceDistributions);
          },
          error => {
            this._errorHandler.handleError(error);
          });
      },
      error => {
        this._errorHandler.handleError(error);
        monthlyDistribution.dataSource = new MeterSpaceTrueUpDataSource([]);
      });
  }

  onSelectChange(year) {
    this.showExpansions = true;
    this.selectedYear = year;
    this.distributionPanels = [];
    this.initializeExpansionPanel();
  }

  viewLog(distribution: MeterDistributionPanel) {
    const monthExpanded = MonthNameToMonth[distribution.month];
    const year = monthExpanded <= 2 ? Number(this.selectedYear) + 1 : Number(this.selectedYear);

    this.meterDistributionService.getLogForYearAndMonth(year, monthExpanded, this.meterId)
      .subscribe(
      result => {
        this.dialogRef = this.dialog.open(MeterDistributionLogDialog, {
          height: '90%',
          width: '50%',
          data: result
        });
        this.dialogRef.afterClosed().subscribe(result => {
        });
      },
      error => {
        this.dialogRef = this.dialog.open(MeterDistributionLogDialog, {
          height: '90%',
          width: '50%',
          data: 'No logs available'
        });
        this.dialogRef.afterClosed().subscribe(result => {
        });
      });
  }
}
