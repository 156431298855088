import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

import { ZoneService } from './../../inventory-management/zones/zones.service';
import { ActionService } from './../services/action.service';
import { WorkOrderFilterRepresentation } from './work-order-filter-representation';
import {TempApprovalStatusTypes, WorkOrderStatusType} from '../models/work-order';
import { ContractTypeConstants } from '../../inventory-management/meters/meter-constants';
import { FilterOperator } from '../../core/query-strings/query-string-builder';
import { WorkOrdersService } from '../services/work-orders.service';
import { TokenCache } from '../../security/token-cache';
import { Zone } from '../../inventory-management/zones/zone';
import { RoleOptionsConstants } from '../../environment';

@Component({
  selector: 'app-work-order-filter',
  templateUrl: './work-order-filter.component.html',
  styleUrls: ['./work-order-filter.component.css']
})
export class WorkOrderFilterComponent implements OnInit {

  workOrderStatusDropdown = [WorkOrderStatusType.AcceptedName, WorkOrderStatusType.CancelledName, WorkOrderStatusType.CompletedName, WorkOrderStatusType.DraftedName, WorkOrderStatusType.PausedName,
    WorkOrderStatusType.PendingSurveyName, WorkOrderStatusType.PostSurveyApprovedName, WorkOrderStatusType.ReadyForReviewName, WorkOrderStatusType.InventoryChangesApprovedName,
    WorkOrderStatusType.ReadyForSurveyName, WorkOrderStatusType.UndoneName];

  actionTypeDropdown = [];
  tempApprovalDropdown = [TempApprovalStatusTypes.PendingSecondaryApproval, TempApprovalStatusTypes.TempApproved, TempApprovalStatusTypes.Approved, TempApprovalStatusTypes.Denied];

  userRoleDropdown = [RoleOptionsConstants.CityRoleName, RoleOptionsConstants.ConcessionaireName];
  contractTypeDropdown = [ContractTypeConstants.Concession, ContractTypeConstants.Reserved];

  workOrderGroupDropDown = [];
  dateRangeDropdown = [FilterOperator.GreaterThan, FilterOperator.LessThan,
    FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between];

  isCityUser = false;

  zoneIdDropDown = [];
  constructor(private actionService: ActionService, private workOrderService: WorkOrdersService, private tokenCache: TokenCache, private zoneService: ZoneService) { }

  @ViewChild('expansion', {static: true}) expansion: MatExpansionPanel;

  @Output()
  workOrderFilterEventEmitter: EventEmitter<WorkOrderFilterRepresentation> = new EventEmitter<WorkOrderFilterRepresentation>(true);

  @Output()
  exportWorkOrderFilterEventEmitter: EventEmitter<WorkOrderFilterRepresentation> = new EventEmitter<WorkOrderFilterRepresentation>(true);

  @Input()
  workOrderFilter: WorkOrderFilterRepresentation;


  @Input()
  filtersPrepopulated= false;

  startingFilter: WorkOrderFilterRepresentation;

  ngOnInit() {
      // this.expansion.open();
      if (!this.workOrderFilter) {
          this.workOrderFilter = new WorkOrderFilterRepresentation();
      }
      this.startingFilter = this.MapFilterProperties(this.workOrderFilter); // set one up to return to if the filter is cleared

      this.actionService.getAllActionsWithCPMLaz().subscribe(
          result => {
              result.forEach(action => {
                  this.actionTypeDropdown.push(action.name);

                  this.actionTypeDropdown = this.sortActionNames(this.actionTypeDropdown);
              });
          }
      );

      this.workOrderService.getAllWorkOrderGroups().subscribe(result => {
          if (result) {
              this.workOrderGroupDropDown = result;
          }
      });

      this.isCityUser = this.tokenCache.checkForCityUser();

      this.zoneService.getAllZones().subscribe(result => {
          if (result) {
              this.zoneIdDropDown = result.sort((a: Zone, b: Zone) => a.displayId < b.displayId ? -1 : 1);
          }
      });
  }

  sortActionNames(actions: string[]) {
    return actions.sort((a, b) => {
      if (a < b) { return -1; }
      else if (a > b) { return 1; }
      else { return 0; }
    });
  }

  clear(fieldName) {
      this.workOrderFilterEventEmitter.emit(new WorkOrderFilterRepresentation());

      this.workOrderFilter[fieldName] = undefined;
      if (fieldName === 'WorkOrderGroupId') {
        this.workOrderFilter.WorkOrderGroup = undefined;
      }
      this.workOrderFilterEventEmitter.emit(this.workOrderFilter);
  }

  onSubmit() {
      this.expansion.close();

      this.workOrderFilterEventEmitter.emit(this.workOrderFilter);
  }

  emitExportWorkOrderFilter() {
    this.exportWorkOrderFilterEventEmitter.emit(this.workOrderFilter);
  }

  workOrderGroupFilterChanged(event) {
    this.workOrderFilter.WorkOrderGroupId = event.value.id;
  }

  clearFilters() {
      // Clear all filters
      Object.keys(this.workOrderFilter).forEach(key => {
          if (!key.toLowerCase().includes('operator')) {
              this.workOrderFilter[key] = undefined;
          }
      });
      this.workOrderFilter = this.MapFilterProperties(this.startingFilter);

      this.workOrderFilterEventEmitter.emit(this.workOrderFilter);
  }

  MapFilterProperties(from: WorkOrderFilterRepresentation): WorkOrderFilterRepresentation {
        return Object.assign(new WorkOrderFilterRepresentation(), from);
  }

  effectiveDateFilterOperatorChange(event) {
      if (event.value !== 'between') {
        this.workOrderFilter.EffectiveDateRangeEnd = undefined;
        this.workOrderFilter.EffectiveDateRangeEndDisplayString = undefined;
        }
  }
}
