
import { SpaceSummary } from '../spaces/space-summary';
import { Space } from './space';

export class SpaceJsonConverter {
    static jsonToSpaceSummary(json: any): SpaceSummary {
        const toReturn = new SpaceSummary();

        for (const prop in json) {
            if (prop) {
                toReturn[prop] = json[prop];
            }
        }
        return toReturn;
    }

    static jsonToSpaceSummaries(arrayOfSpaces: any): SpaceSummary[] {
        const toReturn: SpaceSummary[] = [];
        for (let i = 0; i < arrayOfSpaces.length; i++) {
            toReturn.push(this.jsonToSpaceSummary(arrayOfSpaces[i]));
        }
        return toReturn;
    }

    static jsonToSpace(json: any): Space {
        const toReturn = new Space();

        for (const prop in json) {
            if (prop) {
                toReturn[prop] = json[prop];
            }
        }
        return toReturn;
    }
}
