import { TrueUpDetailJsonConverter } from "../true-up-detail-json-converter";
import { PaginatedGetResult } from "../../core/models/paginated-get-result";
import { MonthlyTrueUpCalculationDetailBase } from "../monthly-calculations/monthly-true-up-calculation-detail-base";
import { MeterTrueUpDetail } from "./meter-true-up-detail";

export class MeterTrueUpDetailJsonConverter extends TrueUpDetailJsonConverter<MeterTrueUpDetail> {

    getInstance(): MeterTrueUpDetail {
        return new MeterTrueUpDetail();
    }

    jsonToLevelSpecificDetail(responseJson: any) : PaginatedGetResult<MeterTrueUpDetail> {
        
        var paginatedResult = this.jsonToPaginatedResult(responseJson);
        
        paginatedResult.items.forEach(meterTrueUpDetail => {        
            this.jsonToBaseTrueUpDetail(meterTrueUpDetail);
        });

        return paginatedResult;
    }
}
