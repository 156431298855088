exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".clear {\r\n    font-size:100%;\r\n    cursor:pointer;\r\n}\r\n\r\n.mat-form-field {\r\n    padding-left:25px;\r\n}\r\n\r\nbutton {\r\n    margin-left:15px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();