import { WorkOrderAction } from './work-order-action';
import { LetterOfDirection } from './letter-of-direction';
import { WorkOrderComment } from './work-order-comment';
import {WorkOrderResponse} from './approval-models';
import { WorkOrderStatus } from './work-order-status';

export class WorkOrder {

    allInventoryChangesCompleted: boolean;

    areaDisplayIds: string[] = [];

    assignedTo: string;

    blockDisplayIds: string[] = [];

    canvasId: string;

    completionDate: Date;

    containsCompletedInventoryChanges: boolean;

    currentLetterOfDirection: LetterOfDirection;

    currentStatus: string;

    currentStatusId: number;

    displayId: number;

    dueDate: string;

    eligibleForReversal: boolean;

    historicalChange: string;

    historicalEffectiveDate: Date;

    id: string;

    isEscalated: boolean;

    letterOfDirections: Array<LetterOfDirection>;

    meterDisplayIds: string[] = [];

    mostRecentResponseGroup: WorkOrderResponse[] = new Array<WorkOrderResponse>();

    mostRecentResponse: WorkOrderResponse;

    previousActivationEffectiveDate?: Date;

    requester: string;

    requestDate: string;

    revisedRequestDate: string;

    tempApprovalStatus: string;

    workOrderActions: WorkOrderAction[] = new Array<WorkOrderAction>();

    workOrderComments: WorkOrderComment[] = new Array<WorkOrderComment>();

    workOrderResponses: WorkOrderResponse[] = new Array<WorkOrderResponse>();

    workOrderStatuses: WorkOrderStatus[] = new Array<WorkOrderStatus>();

    zoneDisplayIds: string[] = [];

    workOrderGroupId: string;

    workOrderGroupName: string;
}

export class WorkOrderStatusType {

    public static CancelledName = 'Cancelled';
    public static CancelledId = 200;

    public static DraftedName = 'Drafted';
    public static DraftedId = 0;

    public static ReadyForReviewName = 'Ready for Review';
    public static ReadyForReviewId = 10;

    public static AcceptedName = 'LOD Accepted';
    public static AcceptedId = 15;

    static PausedId = 20;
    static PausedName = 'Paused';

    public static PendingSurveyName = 'Pending Survey';
    public static PendingSurveyId = 25;

    public static ReadyForSurveyName = 'Ready for Survey';
    public static ReadyForSurveyId = 50;

    public static PostSurveyApprovedName = 'Post-Survey Aprroved';
    public static PostSurveyApprovedId = 75;

    public static AssessingLoadingZoneImpactId = 77;
    public static AssessingLoadingZoneImpactName = 'Assessing Loading Zone Impact';

    public static LoadingZoneImpactAssessmentCompleteId = 78;
    public static LoadingZoneImpactAssessmentCompleteName = 'Loading Zone Impact Assessment Complete';

    public static PendingCityApprovalId = 80;
    public static PendingCityApprovalName = 'Pending City Approval';


    public static InventoryChangesApprovedId = 90;
    public static InventoryChangesApprovedName = 'Inventory Changes Approved';

    public static BulkChangesQueuedId = 95;
    public static BulkChangesQueuedName = 'Processing Bulk Changes';

    public static CompletedName = 'Completed';
    public static CompletedId = 100;

    public static UndoneName = 'Undone';
    public static UndoneId = 150;

    public static PrepopulatedName = 'prepopulated';

}

export class LetterOfDirectionStatusType {
    public static Drafted = 'Drafted';
    public static Completed = 'Completed';
    public static Accepted = 'Accepted';
    public static ReadyForReview = 'Ready for Review';
    public static ReturnedWithComments = 'Returned with Comments';
}

export class TempApprovalStatusTypes {
    public static TempApproved = 'Temporarily Approved';
    public static PendingSecondaryApproval = 'Pending Secondary Approval';
    public static Denied = 'Denied';
    public static Approved = 'Approved';
}
