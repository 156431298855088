import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnChanges, Input } from '@angular/core';

import { SpaceService } from './../../inventory-management/spaces/spaces.service';
import { InventoryFilterRepresentation } from '../../inventory-management/inventory-filter/inventory-filter-representation';
import { Block } from '../../inventory-management/blocks/block';
import { BlockService } from '../../inventory-management/blocks/blocks.service';
import { MeterService } from '../../inventory-management/meters/meters.service';
import { Space } from '../../inventory-management/spaces/space';
import { MeterStatusConstants } from '../../inventory-management/meters/meter-constants';

@Component({
    selector: 'app-associated-space-details',
    templateUrl: './associated-space-details.component.html',
    styleUrls: ['./associated-space-details.component.css']
})

export class AssociatedSpaceDetailsComponent implements OnChanges {
    @Input() blockId: string;
    @Input() meterId: string;
    @Input() isInventoryChange: boolean;
    @Input() loading: boolean;
    @Input() effectiveDate: Date = new Date();

    block: Block;
    spaces: Space[];

    constructor(private _blockService: BlockService, private _meterService: MeterService, private _spaceService: SpaceService, private snackbar: MatSnackBar) { }

    ngOnChanges(): void {
      if (this.blockId) {
        this._blockService.getBlockById(this.blockId).subscribe(
            result => {
              this.block = result;
              this.getAllSpacesOnBlock();
            },
            error => {
                console.log(error);
                this.snackbar.open('Error finding Spaces on Block: ' + error, 'X');
            }
        );
      }
      else if (this.meterId) {
        this._meterService.getMeterById(this.meterId)
        .subscribe(result => {
          if (result.blockId != null) {
            this._blockService.getBlockById(result.blockId)
            .subscribe(result => {
              this.block = result;
              this.getAllSpacesOnBlock();
            },
            error => {
                console.log(error);
                this.snackbar.open('Error finding Spaces on Block: ' + error, 'X');
            });
          }
        });
      }
    }

    getAllSpacesOnBlock() {
      const filter = new InventoryFilterRepresentation();
      filter.BlockId = this.blockId ? this.blockId : null;
      this._spaceService.getAllSpacesOnBlock(this.block.id, this.effectiveDate).subscribe(
          result => {
              this.spaces = result.sort((a: Space, b: Space) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);
              this.loading = false;
          },
          error => {
            this.loading = false;
            this.snackbar.open('Error finding Spaces on Block: ' + error, 'X');
          });
    }

    navigateToSpacePage(spaceId: string) {
        window.open('/inventorymanagement/spaces/' + spaceId, 'noopener');
    }

    navigateToMeterPage(meterId: string) {
        window.open('/inventorymanagement/meters/' + meterId, 'noopener');
    }

    getSpaces(indexInFirstHalf: boolean): Space[] {
      if (this.spaces) {
        return this.spaces.filter(space => {
          if (indexInFirstHalf) {
            return this.spaces.indexOf(space) <= (this.spaces.length / 2);
          }
          else {
            return this.spaces.indexOf(space) > (this.spaces.length / 2);
          }
        });
      }
    }
}
