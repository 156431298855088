import { Injectable, ErrorHandler } from '@angular/core';
import { QueryStringBuilder, FilterOperator } from '../../core/query-strings/query-string-builder';
import { Observable } from 'rxjs';
import { PagedResult } from '../../core/models/paged-result';
import { AbstractJsonConverter } from '../../core/json-converters/abstract-json-convertert';
import { MonthlyTrueUpCalculationDetailBase } from './monthly-true-up-calculation-detail-base';
import { MonthlyTrueUpCalculationFilterRepresentation } from './monthly-true-up-calculation-filter-representation-base';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { TrueUpDetailJsonConverter } from '../true-up-detail-json-converter';
import { ExportMonthlyTrueUpCalculationFilterRepresentation } from './export-monthly-true-up-calculation-filter-representation';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ExportSelectRepresentation } from '../../inventory-management/inventory-filter/export-representations';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';
import { DateTimeUtility } from '../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import {catchError, map} from 'rxjs/operators';

export abstract class MonthlyTrueUpCalculationServiceBase<T extends MonthlyTrueUpCalculationDetailBase> {

  constructor(private trueUpDataHttpService: ReportingHttpService, protected ErrorHandler: ErrorHandler, private jsonConverter: TrueUpDetailJsonConverter<T>,
    private fileDownloadService: FileDownloadService) { }

  public getAll(orderByColumn: string, isAscending: boolean = true,
    skip: number = 25, top: number = 25, filter: MonthlyTrueUpCalculationFilterRepresentation): Observable<PaginatedGetResult<T>> {

    var  queryString = MonthlyTrueUpCalculationServiceBase.createFilterQueryString(filter);

    queryString = this.AddNodeSpecificQueryStringParts(queryString, filter);

    if (orderByColumn != undefined) {
      queryString = QueryStringBuilder.AddOrderBy(queryString, orderByColumn, isAscending);
    }
    queryString = QueryStringBuilder.AddSkip(queryString, skip);
    queryString = QueryStringBuilder.AddTop(queryString, top);

    var endpoint = this.GetEndpoint();
    return this.trueUpDataHttpService.get(`${endpoint}?${queryString}`, null)
      .pipe(
        map(response =>
          response != null ? this.jsonConverter.jsonToLevelSpecificDetail(response) : null),
        catchError((error) => this.handleError(error))
      );
  }

  public requestTrueUpReport(orderByColumn: string, isAscending: boolean = true,
     filter: ExportMonthlyTrueUpCalculationFilterRepresentation, select: ExportSelectRepresentation): Observable<any> {

    var  queryString = MonthlyTrueUpCalculationServiceBase.createFilterQueryString(filter.MonthlyTrueUpCalculationFilterRepresentation);

    queryString = this.AddNodeSpecificQueryStringParts(queryString, filter.MonthlyTrueUpCalculationFilterRepresentation);
    if (orderByColumn != undefined) {
      queryString = QueryStringBuilder.AddOrderBy(queryString, orderByColumn, isAscending);
    }

    const selectQueryString = select.createEncodedSelect();

    var endpoint = this.GetExportEndpoint();
    var encodedEndpoint = `${endpoint}?${queryString}&select=${selectQueryString}`;
    return this.trueUpDataHttpService.post(encodedEndpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public downloadReport(key: string) {
    this.trueUpDataHttpService.DownloadReport(this.GetReportType(), key, 0);
  }

  abstract GetEndpoint(): string;

  abstract GetReportType(): string;

  public GetExportEndpoint(): string {
    return this.GetEndpoint() + '/export';
  }

  abstract AddNodeSpecificQueryStringParts(queryString: string, filter: MonthlyTrueUpCalculationFilterRepresentation): string;
  abstract getAllItems(orderByColumn: string, isAscending: boolean,
    skip: number, top: number, filter: MonthlyTrueUpCalculationFilterRepresentation)
    : Observable<PaginatedGetResult<T>>;

  private handleError(error: any): Promise<any> {
    if(this.ErrorHandler){
      this.ErrorHandler.handleError(error);
    }
    return Promise.reject(error.message || error);
  }

  /* This is static so that it can be tested more easily */
  static createFilterQueryString(filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation): string {

    var filter = '';
    if (filterRepresentation.ShouldFilterByCalendarMonth) {
      filter = QueryStringBuilder.AddEquals(filter, 'month', filterRepresentation.CalendarMonth);
    }
    if (filterRepresentation.ShouldFilterByCalendarYear) {
      filter = QueryStringBuilder.AddEquals(filter, 'year', filterRepresentation.CalendarYear);
    }
    if (filterRepresentation.ShouldFilterByFiscalQuarter) {
      filter = QueryStringBuilder.AddEquals(filter, 'fiscalQuarter', filterRepresentation.FiscalQuarter);
    }
    if (filterRepresentation.ShouldFilterByReportingQuarter) {
      filter = QueryStringBuilder.AddEquals(filter, 'reportingQuarter', filterRepresentation.ReportingQuarter);
    }
    if (filterRepresentation.ShouldFilterByReportingYear) {
      filter = QueryStringBuilder.AddEquals(filter, 'reportingYear', filterRepresentation.ReportingYear);
    }
    if (filterRepresentation.ShouldFilterByFiscalYear) {
      filter = QueryStringBuilder.AddEquals(filter, 'fiscalYear', filterRepresentation.FiscalYear);
    }
    if (filterRepresentation.ShouldFilterByAreaId) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'area', filterRepresentation.AreaFilterOperator, filterRepresentation.Area, filterRepresentation.AreaEnd);
    }
    if (filterRepresentation.ShouldFilterByZone) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'zone', filterRepresentation.ZoneFilterOperator, filterRepresentation.Zone, filterRepresentation.ZoneEnd);
    }
    if (filterRepresentation.ShouldFilterByBlockId) {
      filter = QueryStringBuilder.AddEquals(filter, 'block', filterRepresentation.BlockId);
    }
    if (filterRepresentation.ShouldFilterBySpaceStatus) {
      filter = QueryStringBuilder.AddEquals(filter, 'spaceStatus', filterRepresentation.SpaceStatus);
    }
    if (filterRepresentation.ShouldFilterByChangeInMonth) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'changeInMonth', filterRepresentation.ChangeInMonthFilterOperator, filterRepresentation.ChangeInMonth);
    }
    if (filterRepresentation.ShouldFilterByHasReservedPowerWorkOrderActions) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'hasReservedPowerActions', filterRepresentation.HasReservedPowerWorkOrderActionsFilterOperator, filterRepresentation.HasReservedPowerWorkOrderActions);
    }
    if (filterRepresentation.ShouldFilterByNumberOfMonthlyRPAs) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'numberOfMonthlyRPAs', filterRepresentation.NumberOfMonthlyRPAsFilterOperator, filterRepresentation.NumberOfMonthlyRPAs, filterRepresentation.NumberOfMonthlyRPAsEnd);
    }
    if (filterRepresentation.ShouldFilterByNumberOfTotalRPAs) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'numberOfTotalRPAs', filterRepresentation.NumberOfTotalRPAsFilterOperator, filterRepresentation.NumberOfTotalRPAs, filterRepresentation.NumberOfTotalRPAsEnd);
    }
    if (filterRepresentation.ShouldFilterByWOID) {
      filter = QueryStringBuilder.AddEquals(filter, 'WOID', filterRepresentation.WorkOrderActionIds);
    }
    if (filterRepresentation.ShouldFilterByHasCLZChange) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'hasCLZChange', filterRepresentation.HasCLZChangeFilterOperator, filterRepresentation.HasCLZChange);
    }
    if (filterRepresentation.ShouldFilterByRatePackage) {
      filter = QueryStringBuilder.AddEquals(filter, 'ratePackage', filterRepresentation.RatePackage);
    }
    if (filterRepresentation.ShouldFilterByRatePackageName) {
      filter = QueryStringBuilder.AddEquals(filter, 'ratePackageName', filterRepresentation.RatePackageName);
    }
    if (filterRepresentation.ShouldFilterByContractType) {
      filter = QueryStringBuilder.AddEquals(filter, 'contractType', filterRepresentation.ContractType);
    }
    if (filterRepresentation.ShouldFilterByMonthlyUR) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'monthlyUR', filterRepresentation.MonthlyURFilterOperator, filterRepresentation.MonthlyUR, filterRepresentation.MonthlyUREnd);
    }
    if (filterRepresentation.ShouldFilterByMUR) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'MUR', filterRepresentation.MURFilterOperator, filterRepresentation.MUR, filterRepresentation.MUREnd);
    }
    if (filterRepresentation.ShouldFilterByUAI) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'utilizedAmountInoperablePeriods', filterRepresentation.UAIFilterOperator, filterRepresentation.UAI, filterRepresentation.UAIEnd);
    }
    if (filterRepresentation.ShouldFilterByExistingRevenue) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'existingRevenue', filterRepresentation.ExistingRevenueFilterOperator, filterRepresentation.ExistingRevenue, filterRepresentation.ExistingRevenueEnd);
    }
    if (filterRepresentation.ShouldFilterByRevenueValue) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'revenueValue', filterRepresentation.RevenueValueFilterOperator, filterRepresentation.RevenueValue, filterRepresentation.RevenueValueEnd);
    }
    if (filterRepresentation.ShouldFilterByRevenueValueAdjustment) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'revenueValueAdjustment', filterRepresentation.RevenueValueAdjustmentFilterOperator, filterRepresentation.RevenueValueAdjustment, filterRepresentation.RevenueValueAdjustmentEnd);
    }
    if (filterRepresentation.ShouldFilterByExpectedUtilizationRate) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'expectedUtilizationRate', filterRepresentation.ExpectedUtilizationRateFilterOperator, filterRepresentation.ExpectedUtilizationRate, filterRepresentation.ExpectedUtilizationRateEnd);
    }
    if (filterRepresentation.LastReservedPowerAction) {
      filterRepresentation.LastReservedPowerActionDisplayString = DateTimeUtility.parseDateString(filterRepresentation.LastReservedPowerAction);
      filterRepresentation.LastReservedPowerActionRangeEndDisplayString = DateTimeUtility.parseDateString(filterRepresentation.LastReservedPowerActionRangeEnd);

      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'lastReservedPowerActionDate', filterRepresentation.LastReservedPowerActionRangeFilterOperator, filterRepresentation.LastReservedPowerActionDisplayString, filterRepresentation.LastReservedPowerActionRangeEndDisplayString);
    }
    if (filterRepresentation.LastInventoryChangeDate) {
      filterRepresentation.LastInventoryChangeDateDisplayString = DateTimeUtility.parseDateString(filterRepresentation.LastInventoryChangeDate);
      filterRepresentation.LastInventoryChangeDateRangeEndDisplayString = DateTimeUtility.parseDateString(filterRepresentation.LastInventoryChangeDateRangeEnd);

      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'lastInventoryChangeDate', filterRepresentation.LastInventoryChangeDateRangeFilterOperator, filterRepresentation.LastInventoryChangeDateDisplayString, filterRepresentation.LastInventoryChangeDateRangeEndDisplayString);
    }
    if (filterRepresentation.ShouldFilterByActualOperatingRevenue) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'actualOperatingRevenueMonthly', filterRepresentation.ActualOperatingRevenueFilterOperator, filterRepresentation.ActualOperatingRevenueMonthly, filterRepresentation.ActualOperatingRevenueMonthlyEnd);
    }
    if (filterRepresentation.ShouldFilterByRequiredClosurePayments) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'requiredClosurePayments', filterRepresentation.RequiredClosurePaymentsFilterOperator, filterRepresentation.RequiredClosurePayments, filterRepresentation.RequiredClosurePaymentsEnd);
    }
    if (filterRepresentation.ShouldFilterByQualifiedREquiredClosures) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'qualifiedRequiredClosures', filterRepresentation.QualifiedRequiredClosuresFilterOperator, filterRepresentation.QualifiedRequiredClosures, filterRepresentation.QualifiedRequiredClosuresEnd);
    }
    if (filterRepresentation.ShouldFilterByClosureIDs) {
      filter = QueryStringBuilder.AddEquals(filter, 'closureIDs', filterRepresentation.ClosureIDs);
    }
    if (filterRepresentation.ShouldFilterByMeterParkingRevenue) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'meterParkingRevenue', filterRepresentation.MeterParkingRevenueFilterOperator, filterRepresentation.MeterParkingRevenue, filterRepresentation.MeterParkingRevenueEnd);
    }
    if (filterRepresentation.ShouldFilterByMeasuredUtilizationRate) {
      filter = QueryStringBuilder.AddBasedOnFilterOperator(filter, 'measuredUtilizationRate', filterRepresentation.MeasuredUtilizationRateFilterOperator, filterRepresentation.MeasuredUtilizationRate, filterRepresentation.MeasuredUtilizationRateEnd);
    }
    if (filterRepresentation.ShouldFilterByRowCount) {
      filter = QueryStringBuilder.AddEquals(filter, 'rowCount', filterRepresentation.RowCount);
    }
    if (filter.length == 0) {
      return filter;
    }
    return QueryStringBuilder.PrependFilterQueryStringVariableName(filter);

  }
}


