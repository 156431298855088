export class ActionFieldOption {
    id: number;

    order: number;

    title: string;

    value: string;
    
    workOrderTypeFieldId: number;
}