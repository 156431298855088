import { InventoryFilterRepresentation } from "./inventory-filter-representation";

export class ExportInventoryFilterRepresentation
{
    InventoryFilterRepresentation:InventoryFilterRepresentation = undefined;
    ForMeter:boolean = false;
    ForSpace:boolean = false;

}

export class ExportSelectRepresentation
{
    Columns:Array<string> = new Array<string>();

    createEncodedSelect(): string {
        var selectQuery = "";
        for(var i = 0; i < this.Columns.length; i++) {
            //encode this for to handle special characters
            var encoded = encodeURIComponent(this.Columns[i]);
            selectQuery += encoded;
            if(i != this.Columns.length - 1) {
                selectQuery += ',';
            }
        }

        return selectQuery;
    }
}
