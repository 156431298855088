exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".select-all-checkbox {\r\n    margin-right: 15px;\r\n    margin-left: 15px;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.rate-step-rows{\r\n    margin-left: 25px;\r\n}\r\n\r\n.float-right{\r\n    position:absolute;\r\n    right:15px;\r\n}\r\n\r\n.time-input-margin {\r\n    margin-bottom:6px;\r\n}\r\n\r\n.restriction-type-options{\r\n    margin-top:15px;\r\n}\r\n\r\n.rate-package-save {\r\n    padding-top:20px;\r\n}\r\n\r\n.mat-card-title {\r\n    font-size:150% !important;\r\n    font-weight:bold !important;\r\n    padding:0 !important;\r\n    padding-top: 5px !important;\r\n}\r\n\r\n.hours-of-radios{\r\n  font-size: 13pt;\r\n}\r\n\r\nh2 {\r\n    margin-top:0px;\r\n}\r\n\r\n.radio-class{\r\n    padding-top: -15px !important;\r\n}\r\n\r\n.rate-package-content {\r\n    margin-top: 80px;\r\n    padding: 5px;\r\n}\r\n\r\n.add-step-button {\r\n    display: -webkit-box;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: horizontal;\r\n    -webkit-box-direction: normal;\r\n        -ms-flex-direction: row;\r\n            flex-direction: row;\r\n    -webkit-box-align: end;\r\n        -ms-flex-align: end;\r\n            align-items: flex-end;\r\n}\r\n\r\n.rate-step-section {\r\n    display:-webkit-box;\r\n    display:-ms-flexbox;\r\n    display:flex;\r\n    -ms-flex-wrap:wrap;\r\n        flex-wrap:wrap;\r\n    -ms-flex-pack:distribute;\r\n        justify-content:space-around;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-box-direction: normal;\r\n        -ms-flex-direction: column;\r\n            flex-direction: column;\r\n    -webkit-box-align: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n}\r\n\r\n.title {\r\n    margin-bottom:20px;\r\n}\r\n\r\n.rate-packages-return {\r\n    position:absolute;\r\n    left:25px;\r\n}\r\n\r\n.margin-right {\r\n  margin-right:30px;\r\n}\r\n\r\n.margin-left {\r\n  margin-left:30px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();