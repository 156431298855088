export abstract class InventoryChangeBase {

    id: string;
    workOrderActionId: string;
    workOrderActionType: string;
    effectiveDate?: Date;
    projectedCompletionDate?: Date;

    zoneDisplayId: string;
    areaDisplayId: string;
    blockDisplayId: string;
}

export class InventoryChangeStatusType {
    public static Created = 'Created';
    public static Completed = 'Completed';
    public static Pending = 'Pending';
}
