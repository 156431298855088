import { WorkOrderHistory } from './work-order-history';
export class WorkOrderHistoryJsonConverter {

    static jsonToWorkOrderHistoryList(arrayOfSpaceHistories: WorkOrderHistory[]): any[] {
        var toReturn: WorkOrderHistory[] = [];
        for (var i = 0; i < arrayOfSpaceHistories.length; i++) {
            toReturn.push(this.jsonToWorkOrderHistory(arrayOfSpaceHistories[i]));
        }
        return toReturn;   
    }

    static jsonToWorkOrderHistory(json: any): WorkOrderHistory {
        let toReturn = new WorkOrderHistory();

        for (let prop in json) {
            if (prop)
                toReturn[prop] = json[prop];
        }
        return toReturn;
    }

}