
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { ActionFormWithValidation } from '../forms/action-form-with-validation';
import { ShiftSpaceForm } from '../forms/shift-space-form';

export function ShiftSpaceTooManySpacesValidator(numSpacesOnMeter: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    const numberOfRemainingSpaces = numSpacesOnMeter - control.value;

    return numberOfRemainingSpaces < 0 ? { ShiftSpaceTooManySpaces : true } : null;
  };
}

export function ShiftSpaceShiftMoreThanZeroSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    return Number.parseInt(control.value) <= 0 ? { ShiftSpaceShiftMoreThanZeroSpaces : true } : null;
  };
}

export function ShiftSpaceHistoricalValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    return Number.parseInt(control.value) !== 0 ? { ShiftSpaceShiftMoreThanZeroSpaces : true } : null;
  };
}

export function ShiftSpaceShiftMatchingMetersValidator(formGroup: FormGroup, component: ActionFormWithValidation): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    const meterFromId = formGroup.get(component.actionIndex + WorkorderActionJsonConstants.meterFromGuid) ? formGroup.get(component.actionIndex + WorkorderActionJsonConstants.meterFromGuid).value : null;
    const meterToId = formGroup.get(component.actionIndex + WorkorderActionJsonConstants.meterToGuid) ? formGroup.get(component.actionIndex + WorkorderActionJsonConstants.meterToGuid).value : null;

    return (meterFromId === meterToId) && (meterFromId) && (meterToId) ? observableOf({ ShiftSpaceMatchingMeters : true}) : observableOf(null);
  };
}
