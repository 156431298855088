import { ParkingTypeConstants } from './../meter-constants';
import { StringExtensions } from './../../../string-extensions';
import { FilterOperator } from './../../../core/query-strings/query-string-builder';
import { MeterService } from './../meters.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Meter } from '../meter';
import { MerchantConstants, FoundationTypeConstants } from '../meter-constants';
import { InventoryFilterRepresentation } from '../../inventory-filter/inventory-filter-representation';

@Component({
  selector: 'app-edit-meter-dialog',
  templateUrl: './edit-meter-dialog.component.html',
  styleUrls: ['./edit-meter-dialog.component.css']
})
export class EditMeterDialogComponent implements OnInit {

  meter: Meter;
  originalMeterId: string;
  merchantDropDown = MerchantConstants.AllMerchants;
  meterIdAlreadyExists = false;

  locationTypes= [ParkingTypeConstants.OnStreet, ParkingTypeConstants.ParkingLot, ParkingTypeConstants.CLZ, ParkingTypeConstants.WrigleyvilleEventParking];
  foundationTypes = [FoundationTypeConstants.Drill, FoundationTypeConstants.Pad];

  constructor(public dialogRef: MatDialogRef<EditMeterDialogComponent>,
  public meterService: MeterService) { }

  ngOnInit() {
    this.originalMeterId = this.meter.displayId;
    this.meter.parkingTypeId = this.locationTypes.filter(locationType => {
      return locationType.title === this.meter.parkingType;
    })[0].value;
    this.meter.foundationType = this.foundationTypes.filter(foundationType => {
      return foundationType === this.meter.foundationType;
    })[0];
  }

  checkIfMeterExists(meterId) {
    if (meterId === this.originalMeterId || StringExtensions.StringIsEmpty(meterId.toString())) {
      this.meterIdAlreadyExists = false;
      return;
    }

    const meterFilter = new InventoryFilterRepresentation();
    meterFilter.MeterIdFilterOperator = FilterOperator.Equal;
    meterFilter.MeterId = meterId;
    this.meterService.getAllMeters('currentStatus', true, 0, 25, meterFilter).subscribe(
      metersReturned => {
        this.meterIdAlreadyExists = metersReturned && metersReturned.count > 0;
      }
    );
  }
}
