import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../core/core.module';

import { SpaceTrueUpDetailComponent } from './space-true-up-detail/space-true-up-detail.component';
import { SpaceTrueUpDetailService } from './space-true-up-detail/space-true-up-detail.service';
import { SystemTrueUpDetailJsonConverter } from './system-true-up-detail/system-true-up-detail-json-converter';
import { MonthlyTrueUpCalculationComponent } from './monthly-calculations/monthly-true-up-calculation.component';
import { MeterTrueUpDetailComponent } from './meter-true-up-detail/meter-true-up-detail.component';
import { SystemTrueUpDetailComponent } from './system-true-up-detail/system-true-up-detail.component';
import { SystemTrueUpDetailService } from './system-true-up-detail/system-true-up-detail.service';
import { MeterTrueUpDetailService } from './meter-true-up-detail/meter-true-up-detail.service';
import { SpaceTrueUpDetailJsonConverter } from './space-true-up-detail/space-true-up-detail-json-converter';
import { MeterTrueUpDetailJsonConverter } from './meter-true-up-detail/meter-true-up-detail-json-converter';
import { TrueUpCalculationStatusComponent } from './true-up-calculation-status/true-up-calculation-status.component';
import { TrueUpCalculationService } from './true-up-calculation-status/true-up-calculation-service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CalculationsCompleteService } from './calculations-complete-service/calculations-complete-service';
import { RecentLogMessagesComponent } from './recent-log-messages/recent-log-messages.component';
import { LogMessagesService } from './log-messages.service';
import { ClosureConfiguratorComponent } from './closure/closure-configurator/closure-configurator.component';
import { ClosureReportingService } from './services/closure-reporting.service';
// import { RequestOptions, XHRBackend } from '@angular/http';
import { HttpXhrBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../core/http-request-options';
import { TokenCache } from '../security/token-cache';
import { FileDownloadService } from '../core/file-download/file-download.service';
import { ReportingQuarterService } from './monthly-calculations/reporting-quarter.service';
import { MeterTrueUpDetailListComponent } from './meter-true-up-detail/meter-true-up-detail-list/meter-true-up-detail-list.component';
// import {closureHttpFactory} from '../reports/reports.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    SpaceTrueUpDetailComponent,
    MonthlyTrueUpCalculationComponent,
    SystemTrueUpDetailComponent,
    MeterTrueUpDetailComponent,
    TrueUpCalculationStatusComponent,
    ConfirmationDialogComponent,
    RecentLogMessagesComponent,
    ClosureConfiguratorComponent,
    MeterTrueUpDetailListComponent
  ],
  exports: [
    SpaceTrueUpDetailComponent,
    MonthlyTrueUpCalculationComponent,
    ClosureConfiguratorComponent,
    MeterTrueUpDetailListComponent,
  ],
  providers: [
    SpaceTrueUpDetailService,
    SpaceTrueUpDetailJsonConverter,
    MeterTrueUpDetailJsonConverter,
    SystemTrueUpDetailJsonConverter,
    SystemTrueUpDetailService,
    MeterTrueUpDetailService,
    TrueUpCalculationService,
    CalculationsCompleteService,
    ReportingQuarterService,
    LogMessagesService,
    {
      provide: ClosureReportingService,
      useFactory: closureHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    }
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [ConfirmationDialogComponent]

})
export class TrueupModule { }

export function closureHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new ClosureReportingService(backend, defaultOptions, TokenCache, fileDownload);
}

