import { forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { UpdateMeterAttributesInventoryChanges } from './../inventory-information/models/update-meter-attributes';
import { LoadingZoneImpactService } from './../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { LetterOfDirectionService } from './../services/letter-of-direction.service';
import { StringExtensions } from './../../string-extensions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpRequestOptions } from '../../core/http-request-options';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { ActionFieldService } from '../services/action-field.service';
import { Block } from './../../inventory-management/blocks/block';
import { BlockService } from './../../inventory-management/blocks/blocks.service';
import { Component, OnInit } from '@angular/core';
import { environment, RoleOptionsConstants } from '../../environment';
import { MeterService } from './../../inventory-management/meters/meters.service';
import { Meter } from './../../inventory-management/meters/meter';
import { LODButtonCalculator } from '../letter-of-directions-list/lod-button-calculator';
import { LodWithActionFields, LetterOfDirection, WorkOrderActionWithActionFields } from '../models/letter-of-direction';
import { Space } from './../../inventory-management/spaces/space';
import { TokenCache } from '../../security/token-cache';
import { WorkOrderAction } from '../models/work-order-action';
import { WorkOrderStatusType } from './../models/work-order';
import { WorkOrderCancelConfirmation } from './work-order-cancel-confirmation/work-order-cancel-confirmation';
import {WorkOrder, LetterOfDirectionStatusType, TempApprovalStatusTypes} from '../models/work-order';
import { WorkOrdersService } from './../services/work-orders.service';
import { ActionConstants } from '../action/action-constants';
import { WorkOrderGroup } from '../models/work-order-group';
import { WorkOrderSingleFieldInputComponent } from './work-order-field-input-dialog/work-order-field-input-dialog.component';
import { ReversibilityDeciderService } from '../reversibility/reversibility-decider.service';
import { WorkorderActionJsonConstants } from '../action-field/workorder-action-json-constants';
import { WorkOrderChangeStatus } from '../models/work-order-change-status';
import { ConfirmationDialogComponent } from '../../trueup/confirmation-dialog/confirmation-dialog.component';
import { CommentsDialogComponent } from '../letter-of-directions-list/comments-dialog/comments-dialog.component';
import { InventoryChangesService } from '../inventory-information/services/inventory-changes-service';
import { InventoryChangeStatusType } from '../inventory-information/models/inventory-change-base';
import { WorkOrderUpdate } from '../models/work-order-update';
import { ProjectedCompletionDate } from '../projected-completion-date';
import { WorkOrderResponseService } from '../services/work-order-response.service';
import {LetterOfDirectionResponse, TypeOfActionToApproveConstants, WorkOrderResponse} from '../models/approval-models';
import {WorkOrderComment} from '../models/work-order-comment';
import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { WorkOrderStatusConstants } from '../work-order-constants';
import { LoadingZoneImpactAssessmentPostBody } from '../models/loading-zone-impacts/loading-zone-impact-assessment-body';
import { WorkOrderFilterRepresentation } from '../work-order-filter/work-order-filter-representation';
import { CanvasIdConfirmationComponent } from './canvas-id-confirmation/canvas-id-confirmation.component';
import { RegularRateAdjustmentInventoryChanges } from '../inventory-information/regular-rate-adjustment/models/regular-rate-adjustment-inventory-changes';
import { SurveyRequest } from '../models/survey-request';
import { LetterOfDirectionResponseConstants } from '../models/letter-of-direction-response-constants';
import { WorkOrderReverseConfirmationComponent } from './work-order-reverse-confirmation/work-order-reverse-confirmation.component';


@Component({
    selector: 'app-work-order-detail',
    templateUrl: './work-order-detail.component.html',
    styleUrls: ['./work-order-detail.component.css']
})

export class WorkOrderDetailComponent implements OnInit {

    constructor(private httpClient: HttpClient, private _workOrderService: WorkOrdersService, private route: ActivatedRoute, private router: Router, private _letterOfDirectionService: LetterOfDirectionService,
      private meterService: MeterService, private blockService: BlockService, private inventoryChangeService: InventoryChangesService, options: HttpRequestOptions, private TokenCache: TokenCache, public dialog: MatDialog, public snackBar: MatSnackBar,
      private _actionFieldService: ActionFieldService, private reversibilityDeciderService: ReversibilityDeciderService, private workOrderResponseService: WorkOrderResponseService, private _loadingZoneImpactService: LoadingZoneImpactService) {

            this.headers = new HttpHeaders({
                'Authorization': TokenCache.getBearerToken()
            });
        }

    workOrderActions: WorkOrderAction[];
    workOrder: WorkOrder;
    block: Block;
    meter: Meter;
    spaces: Space[];
    letterOfDirection: LetterOfDirection;
    selectedIndex: number;
    headers: HttpHeaders;
    loading: boolean;
    loadingError: boolean = false;
    cancelConstant = 'cancel';
    undoConstant = TypeOfActionToApproveConstants.undo;
    historicalChangeConstant = TypeOfActionToApproveConstants.historicalChange;

    selectedLod: LodWithActionFields;
    lodApprovable = false;
    showAcceptButton = false;
    showModifyButton = false;
    showModifyAsCityButton = false;
    showCommentsButton = false;
    showButtonRow: boolean;
    showTemporarilyApproveButton: boolean;
    showRejectButton: boolean;
    showPauseButton: boolean;
    showReinstateButton: boolean;
    canModifyAcceptedWorkOrder: boolean;

    isCityUser = true;
    showUndoButton = false;
    showReverseButton = false;
    adminOverrideReverseButton = false;

    showRelatedWorkOrder = false;

    allWorkOrderGroups: WorkOrderGroup[];
    relatedWorkOrdersInGroup = [];
    selectedWorkOrderGroup: WorkOrderGroup;

    alreadyInGroup: boolean;
    disableChangeGroupButton: boolean;
    unrelateToGroup: boolean;
    relatedWorkOrdersTabLabel = 'Related Work Orders';
    lodDictionary: LodWithActionFields[] = [];
    ActionConstants = ActionConstants;

    dialogRefConfirmation: MatDialogRef<ConfirmationDialogComponent>;
    dialogRefComments: MatDialogRef<CommentsDialogComponent>;

    draftedStatus = WorkOrderStatusType.DraftedName;
    readyForReviewStatus = WorkOrderStatusType.ReadyForReviewName;
    acceptedStatus = WorkOrderStatusType.AcceptedName;

    showEscalationButton: boolean;

    escalationButtonText: string;
    escalateText = 'Escalate Work Order';
    deescalateText = 'De-escalate Work Order';
    workOrderAttachmentsTabLabel: string;

    projectedCompletionDate = new ProjectedCompletionDate();

    numberOfComments: Number;

    hasBulkAction: boolean;
    potentialCLZImpactActions = [ActionConstants.InstallMeterId, ActionConstants.InstallSpaceId, ActionConstants.ChangeRatePackageId, ActionConstants.UpdateMeterAttributesId, ActionConstants.RemoveSpaceId];
    potentialClzImpact: boolean[] = [];

    ngOnInit(): void {
        this.loading = true;
        const id = this.route.snapshot.paramMap.get('id');
        this.isCityUser = this.TokenCache.checkForCityUser();
        this.workOrderAttachmentsTabLabel = 'Attachments';
        this.setWorkOrder(id);

    }

    setWorkOrder(id: string) {
      this.selectedIndex = 0;
        const _options = { headers: this.headers };
        this._workOrderService.getWorkOrderById(id)
        .subscribe(result => {
            this.loading = false;
            this.loadingError = false;

            this.workOrder = result;
            this.letterOfDirection = this.workOrder.currentLetterOfDirection;

            if (this.TokenCache.checkForCityUser()) {
              this.letterOfDirection.workOrderActions = this.letterOfDirection.workOrderActions.filter(action => {
                return !(action.actionId === ActionConstants.ShiftSpaceId);
              });
            }

            this.workOrderActions = this.letterOfDirection.workOrderActions;
            this.hasBulkAction = this.workOrderActions.filter(x => {
                return x.actionId === ActionConstants.UpdateMeterAttributesId || x.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId || x.actionId === ActionConstants.RegularRateAdjustmentId;
            }).length > 0;
            this.checkTempMeterAction();
            this.checkReversibility(this.workOrder.eligibleForReversal, this.workOrderActions, this.workOrder.previousActivationEffectiveDate);
            this.setUpEscalationButton(this.workOrder);

            this.letterOfDirection.workOrderActions.forEach(action => {
                const workOrderActionJsonObject = JSON.parse(action.workOrderActionJson);
                action.meterIDs = new Array<string>();
                action.blockIDs = new Array<string>();
                if (workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid] !== null && workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid] !== undefined
                    && workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid] !== '') {
                    action.meterIDs.push(workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid]);
                    this.meterService.getMeterById(workOrderActionJsonObject[WorkorderActionJsonConstants.meterGuid])
                    .subscribe(meter => {
                        if (!action.blockIDs.includes(workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid]) && workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid] !== '') {
                            action.blockIDs.push(meter.blockId);
                        }
                    });
                }
                if (workOrderActionJsonObject[WorkorderActionJsonConstants.meterToGuid] !== null && workOrderActionJsonObject[WorkorderActionJsonConstants.meterToGuid] !== undefined) {
                  action.meterToId = workOrderActionJsonObject[WorkorderActionJsonConstants.meterToGuid];
                    this.meterService.getMeterById(action.meterToId)
                    .subscribe(meter => {
                        action.blockToId = meter.blockId;
                    });
                }
                if (workOrderActionJsonObject[WorkorderActionJsonConstants.meterFromGuid] !== null && workOrderActionJsonObject[WorkorderActionJsonConstants.meterFromGuid] !== undefined) {
                    action.meterIDs.push(workOrderActionJsonObject[WorkorderActionJsonConstants.meterFromGuid]);
                    this.meterService.getMeterById(workOrderActionJsonObject[WorkorderActionJsonConstants.meterFromGuid])
                    .subscribe(meter => {
                        if (!action.blockIDs.includes(meter.blockId)) {
                            action.blockIDs.push(meter.blockId);
                        }
                    });
                }
                if (workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid] !== null && workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid] !== undefined
                    && workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid] !== '') {
                    if (!action.blockIDs.includes(workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid])) {
                        action.blockIDs.push(workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid]);
                    }
                }
                if (workOrderActionJsonObject[WorkorderActionJsonConstants.blockToGuid] !== null && workOrderActionJsonObject[WorkorderActionJsonConstants.blockToGuid] !== undefined
                    && workOrderActionJsonObject[WorkorderActionJsonConstants.blockToGuid] !== '') {
                        action.blockToId = workOrderActionJsonObject[WorkorderActionJsonConstants.blockToGuid];
                    }
                if (action.actionId === ActionConstants.InstallMeterId && action.meterInventoryChanges) {
                    if (action.meterInventoryChanges[0] && action.meterInventoryChanges[0].meterId !== null) {
                        action.meterIDs.push(action.meterInventoryChanges[0].meterId);
                    }
              }
                if (action.blockInventoryChanges.length > 0) {
                  action.blockInventoryChanges.forEach(blockChange => {
                    if (!action.blockIDs.includes(blockChange.blockId) && blockChange.blockId !== null) {
                        action.blockIDs.push(blockChange.blockId);
                    }
                  });
                }
            });

            const newLodDictEntry = new LodWithActionFields(this.workOrder.currentLetterOfDirection, []);
            this.workOrder.currentLetterOfDirection.workOrderActions.forEach(workOrderAction => {
              this._actionFieldService.getAllReadOnlyActionFields(workOrderAction.actionId, this.workOrder.currentLetterOfDirection.authorUserRole)
              .subscribe(result => {
                const workOrderActionWithFields = new WorkOrderActionWithActionFields(workOrderAction, result, JSON.parse(workOrderAction.workOrderActionJson), workOrderAction.summationSentence);
                newLodDictEntry.actionsWithFields.push(workOrderActionWithFields);
                if (this.workOrder.currentLetterOfDirection.workOrderActions.indexOf(workOrderAction) === this.workOrder.currentLetterOfDirection.workOrderActions.length - 1) {
                  this.lodDictionary.push(newLodDictEntry);
                  this.selectLod(this.lodDictionary.sort((a, b) => (a.lod.versionNumber > b.lod.versionNumber ? 0 : 1))[0].lod);
                }
              },
              error => {
                console.log(error);
              });
            });

            this.getWorkOrderGroupsAndUpdateViews();
            const shouldNavigateToAttachmentsTab = localStorage.getItem('NavigateToAttachmentsTab');
            if (shouldNavigateToAttachmentsTab) {
                this.changeToAttachmentTab();
            }
        },
        error => {
            this.loading = false;
            this.loadingError = true;
            console.log(error);
        });
    }

    hasUndo(): boolean {
        if (this.workOrder !== null && this.workOrder !== undefined) {
            const hasUndo = this.workOrder.workOrderResponses.find(x => {
                return x.typeOfActionToApprove.toLowerCase() === TypeOfActionToApproveConstants.undo.toLowerCase();
            });
            return hasUndo !== null && hasUndo !== undefined;
        }

    }

    hasHistoricalChange(): boolean {
        if (!this.workOrder) {
            return false;
        }

        const hasHistoricalChange = this.workOrder.workOrderResponses.find(x => {
            return x.typeOfActionToApprove.toLowerCase() === TypeOfActionToApproveConstants.historicalChange.toLowerCase();
        });

        return hasHistoricalChange !== null && hasHistoricalChange !== undefined;
    }

    calculateUndoer(): string {
        if (this.getUndoers().length > 0) {
            const firstUndoer = this.getUndoers()[0];
            return firstUndoer.responder + ' on ' + DateTimeUtility.parseDateString(firstUndoer.responseDate);
        }
    }

    calculateUndoApprovers(): string {
        let undoApprovers = '';
        const undoers = this.getUndoers();
        const firstUndoer = undoers[0];
        undoers.forEach(undoer => {
            if (undoer.id !== firstUndoer.id && undoer.responseType.toLowerCase() === 'approve') {
                undoApprovers += undoer.responder + ' on ' + DateTimeUtility.parseDateString(undoer.responseDate) + ', ';
            }
        });
        if (undoApprovers.charAt(undoApprovers.length - 2) === ',') {
            return undoApprovers.substring(0, undoApprovers.length - 2);
        }
        return undoApprovers;
    }

    getUndoers(): WorkOrderResponse[] {
        const undos = this.workOrder.mostRecentResponseGroup.filter(x => {
            return x.typeOfActionToApprove.toLowerCase() === TypeOfActionToApproveConstants.undo.toLowerCase();
        });
        const undoers =  undos.sort((a, b) => {
            return DateTimeUtility.compareDate(a.responseDate, b.responseDate) ? 1 : -1;
        });
        return undoers;
    }

    calculateHistoricalChanger(): string {
        if (this.getHistoricalChangers().length > 0) {
            const firstHistoricalChanger = this.getHistoricalChangers()[0];
            return firstHistoricalChanger.responder + ' on ' + DateTimeUtility.parseDateString(firstHistoricalChanger.responseDate);
        }
    }

    calculateHistoricalChangeApprovers(): string {
        let historicalChangeApprovers = '';
        const historicalChangers = this.getHistoricalChangers();
        const firstHistoricalChanger = historicalChangers[0];
        historicalChangers.forEach(historicalChanger => {
            if (historicalChanger.id !== firstHistoricalChanger.id && historicalChanger.responseType.toLowerCase() === 'approve') {
                historicalChangeApprovers += historicalChanger.responder + ' on ' + DateTimeUtility.parseDateString(historicalChanger.responseDate) + ', ';
            }
        });
        if (historicalChangeApprovers.charAt(historicalChangeApprovers.length - 2) === ',') {
            const noTrailingComma = historicalChangeApprovers.substring(0, historicalChangeApprovers.length - 2);
            return noTrailingComma;
        }
        return historicalChangeApprovers;
    }

    getHistoricalChangers(): WorkOrderResponse[] {
        const historicalChanges = this.workOrder.mostRecentResponseGroup.filter(x => {
            return x.typeOfActionToApprove.toLowerCase() === TypeOfActionToApproveConstants.historicalChange.toLowerCase();
        });
        const historicalChangers = historicalChanges.sort((a, b) => {
            return DateTimeUtility.compareDate(a.responseDate, b.responseDate) ? 1 : -1;
        });
        return historicalChangers;
    }

    getAttachmentCount(evt: number) {
        this.workOrderAttachmentsTabLabel = `Attachments (${evt})`;
    }

    selectLod(lod: LetterOfDirection) {
      this.selectedLod = this.lodDictionary.filter(lodToMatch => {
        return lodToMatch.lod.versionNumber === lod.versionNumber;
      })[0];
      this.lodApprovable = this.workOrder.currentLetterOfDirection.readyForAcceptance;

      const workOrderUserRole = this.TokenCache.getWorkOrderUserRole();
      const isAdminUser = this.TokenCache.isAdminUser();
      const userIsCity = this.TokenCache.checkForCityUser();
      const isNonCPMUser = this.TokenCache.checkForCityUser() || this.TokenCache.checkForLAZUser();

      this.showAcceptButton = LODButtonCalculator.showAcceptButton(this.workOrder, this.selectedLod, this.workOrder.currentLetterOfDirection, workOrderUserRole, userIsCity, this.workOrder.currentStatusId);

      this.workOrderActions.forEach(action => {
        if (this.potentialCLZImpactActions.includes(action.actionId)) {

          // TODO - Use this to flag the WO or actually save on the LOD?
          if (JSON.parse(action.workOrderActionJson)[WorkorderActionJsonConstants.blockGuid] !== '') {
            const postBody = this.generateLoadingZoneImpactAssessmentBody(JSON.parse(action.workOrderActionJson)[WorkorderActionJsonConstants.blockGuid], action.actionId);
            action.CLZImpactedSpaces = [];
            // this._loadingZoneImpactService.getAllImpactedSpaces(postBody)
            //   .subscribe(result => {
            //     if (result.loadingZoneImpacts.length) {
            //       result.loadingZoneImpacts.forEach(impact => {
            //         impact.impactedSpaces.forEach(spaceImpact => {
            //           action.CLZImpactedSpaces.push(spaceImpact);
            //         });
            //       });
            //       this.potentialClzImpact[this.workOrderActions.indexOf(action)] = true;
            //     }
            //   });
          }

        }
      });

      const userName = this.TokenCache.getUserName();
      this.showTemporarilyApproveButton = LODButtonCalculator.showTemporarilyApproveButton(this.workOrder, this.selectedLod, this.workOrder.currentLetterOfDirection, workOrderUserRole,
                                                                this.TokenCache.isElevatedApproverUser(), userIsCity, userName, this.TokenCache.getUserFullName());

      this.showModifyAsCityButton = LODButtonCalculator.showModifyAsCityButton(this.workOrder, this.selectedLod, this.workOrder.currentLetterOfDirection, workOrderUserRole, isAdminUser, isNonCPMUser);

      this.showModifyButton =  LODButtonCalculator.showModifyButton(this.workOrder, this.selectedLod, this.workOrder.currentLetterOfDirection, this.workOrder.currentStatusId, userIsCity);
      this.showCommentsButton = LODButtonCalculator.showCommentsButton(this.workOrder, this.selectedLod, this.workOrder.currentLetterOfDirection, workOrderUserRole, userIsCity, userName);
      this.showRejectButton = LODButtonCalculator.showRejectButton(this.workOrder, this.selectedLod, this.workOrder.currentLetterOfDirection, workOrderUserRole, userIsCity);
      this.showPauseButton = LODButtonCalculator.showPauseButton(this.workOrder, userIsCity);
      this.showReinstateButton = LODButtonCalculator.showReinstateButton(this.workOrder, userIsCity);
      this.canModifyAcceptedWorkOrder = LODButtonCalculator.showModifyAcceptedWorkOrderButton(this.workOrder);

      this.showButtonRow = this.showAcceptButton || this.showTemporarilyApproveButton || this.showModifyAsCityButton || this.showModifyButton || this.showCommentsButton || this.showRejectButton || this.canModifyAcceptedWorkOrder;
    }

    wasWorkOrderEverPaused(): boolean {
        const findPausedStatus = this.workOrder.workOrderStatuses.find(x => x.workOrderStatusTypeId === WorkOrderStatusConstants.PausedId);
        if ( findPausedStatus !== null && findPausedStatus !== undefined) {
            return true;
        }
        return false;
    }

    getPausedDates(): string {
        let pausedString = 'This Work Order was paused from ';
        const orderedStatuses = this.workOrder.workOrderStatuses.sort((a, b) => {
            return a.dateCreated > b.dateCreated ? 1 : -1;
        });
        const pausedStatuses = orderedStatuses.filter(x => x.workOrderStatusTypeId === WorkOrderStatusConstants.PausedId);
        for (let i = 0; i < pausedStatuses.length; i++) {
            const pausedDate = new Date(pausedStatuses[i].dateCreated);
            pausedString += DateTimeUtility.parseDateString(pausedDate) + ' to ';
            const pauseIndex = orderedStatuses.findIndex(x => x.id === pausedStatuses[i].id);
            const nextStatus = orderedStatuses[pauseIndex + 1];
            if (nextStatus !== null && nextStatus !== undefined) {
                const nextDate = new Date(nextStatus.dateCreated);
                pausedString += DateTimeUtility.parseDateString(nextDate);
            }
            if (pausedStatuses[i + 1] !== null && pausedStatuses[i + 1] !== undefined) {
                pausedString += ' and ';
            }
        }
        return pausedString;
    }

    generateLoadingZoneImpactAssessmentBody(blockId: string, actionId: number): LoadingZoneImpactAssessmentPostBody {
      const postBody = new LoadingZoneImpactAssessmentPostBody();
      postBody.effectiveDate = this.hasHistoricalChange() ? this.workOrder.previousActivationEffectiveDate : new Date();
      if (actionId === ActionConstants.RemoveSpaceId) {
        postBody.removedOriginBlockId = blockId;
      }
      else {
        postBody.originBlockId = blockId;
      }

      return postBody;
    }

    updateWorkOrderGroupTabView() {
        if (this.workOrder.workOrderGroupName) {
            this.selectedWorkOrderGroup = this.allWorkOrderGroups.filter(gp => gp.name === this.workOrder.workOrderGroupName)[0];
            this.workOrder.workOrderGroupId = this.selectedWorkOrderGroup.id;

            // Filter out current viewing work order from relatedWorkOrdersInGroup
            this.relatedWorkOrdersInGroup = this.selectedWorkOrderGroup.workOrderDisplayIds
                                            .filter(displayIdPair => displayIdPair.workOrderId !== this.workOrder.id);

            this.showRelatedWorkOrder = true;

            this.relatedWorkOrdersTabLabel = `Related Work Orders (${this.selectedWorkOrderGroup.workOrderDisplayIds.length - 1})`;
        }
        else {
            this.selectedWorkOrderGroup = null;
            this.relatedWorkOrdersInGroup = [];
            this.showRelatedWorkOrder = false;
            this.relatedWorkOrdersTabLabel = `Related Work Orders`;
        }

        this.alreadyInGroup = StringExtensions.StringIsNotEmpty(this.workOrder.workOrderGroupName);
        this.disableChangeGroupButton = this.alreadyInGroup && this.workOrder.workOrderGroupName === this.selectedWorkOrderGroup.name;
    }

    displayNumberOfComments(numberOfComments: Number) {
        this.numberOfComments = numberOfComments;
    }

    getWorkOrderGroupsAndUpdateViews() {
        this._workOrderService.getAllWorkOrderGroups().subscribe(
            result => {
                if (result) {
                    this.allWorkOrderGroups = result;

                    this.updateWorkOrderGroupTabView();
                }
            }
        );
    }

    clearWorkOrderGroup() {
      this.selectedWorkOrderGroup = null;
      this.unrelateToGroup = true;
      this.showRelatedWorkOrder = false;
    }

    removeFromWorkOrderGroup() {
      this.unrelateToGroup = false;
      this._workOrderService.RemoveWorkOrderFromGroup(this.workOrder.id).subscribe(
        result => {
          if (result) {
            this.snackBar.open(`Removed work order ${this.workOrder.displayId} from ${this.workOrder.workOrderGroupName}`, '', <MatSnackBarConfig>{
              duration: 2000,
            });

            this.workOrder.workOrderGroupName = undefined;
            this.updateWorkOrderGroupTabView();
          }
        },
        error => {
          this.snackBar.open(`Failed to remove work order ${this.workOrder.displayId} from ${this.workOrder.workOrderGroupName}`, 'X');
          this.unrelateToGroup = true;
        });

    }

    editCanvasId() {
        const dialogRef = this.dialog.open(WorkOrderSingleFieldInputComponent, {
            width: '340px',
            height: '200px',
        });
        dialogRef.componentInstance.fieldName = 'Canvas ID';
        dialogRef.componentInstance.originalValue = this.workOrder.canvasId;
        dialogRef.componentInstance.dialogTitle = 'Change Canvas ID';

        dialogRef.afterClosed().subscribe(canvasId => {
            if (canvasId !== this.workOrder.canvasId) {
                const filter = new WorkOrderFilterRepresentation();
                filter.WorkOrderCanvasId = canvasId;
                this._workOrderService.getAllWorkOrders('currentStatus', true, 0, 25, filter).subscribe(result => {

                    if (result.count === 0) {
                        this.updateCanvasId(canvasId);
                    }
                    else {
                        const workOrders = [];
                        result.items.forEach(workOrder => {
                            workOrders.push(workOrder.workOrder.displayId);
                        });

                        const dialogRef = this.dialog.open(CanvasIdConfirmationComponent, {
                            width: '500px',
                            height: 'auto',
                            data: {workOrders: workOrders}
                        });
                        dialogRef.afterClosed().subscribe(confirm => {
                            if (confirm) {
                                this.updateCanvasId(canvasId);
                            }
                        });
                    }
                });
            }
        });
    }

    updateCanvasId(canvasId) {
        this._workOrderService.UpdateCanvasId(this.workOrder.id, canvasId).subscribe(result => {
            if (result) {
                this.snackBar.open(`Canvas ID updated successfully`, '', <MatSnackBarConfig>{
                    duration: 2000,
                });

                this.workOrder.canvasId = canvasId;
            }
        },
        error => {
            this.snackBar.open(`Failed to update canvas ID`, 'X');
        });
    }

    populateWorkOrderDisplayIdsInGroup(event) {
      this.showRelatedWorkOrder = true;
      this.relatedWorkOrdersInGroup = event.value.workOrderDisplayIds;

      // Filter out current viewing work order from relatedWorkOrdersInGroup
      this.relatedWorkOrdersInGroup = this.selectedWorkOrderGroup.workOrderDisplayIds
        .filter(displayIdPair => displayIdPair.workOrderId !== this.workOrder.id);

      this.disableChangeGroupButton = this.alreadyInGroup && this.workOrder.workOrderGroupName === this.selectedWorkOrderGroup.name;

      this.unrelateToGroup = false;
    }

    navigateToWorkOrder(workOrderId: string) {
        window.open(`/workorders/${workOrderId}`);
    }

    createAndAddToWorkOrderGroup() {
        const dialogRef = this.dialog.open(WorkOrderSingleFieldInputComponent, {
            width: '340px',
            height: '200px',
        });
        dialogRef.componentInstance.fieldName = 'Group Name';
        dialogRef.componentInstance.dialogTitle = 'Create Work Order Group';

        dialogRef.afterClosed().subscribe(groupName => {
            if (groupName) {
                this._workOrderService.createGroupAndAddWorkOrder(this.workOrder.id, groupName).subscribe(result => {
                    this.snackBar.open(`Created group ${groupName} and added ${this.workOrder.displayId} to ${groupName}`, '', <MatSnackBarConfig>{
                        duration: 2000,
                    });

                    this.workOrder.workOrderGroupName = groupName;

                    this.getWorkOrderGroupsAndUpdateViews();
                },
                    error => {
                        this.snackBar.open(`Failed to create work order group`, 'X');
                    });
            }
        });
    }

    addToWorkOrderGroup() {
        this._workOrderService.AddWorkOrderToGroup(this.workOrder.id, this.selectedWorkOrderGroup.id).subscribe(
            result => {
                this.snackBar.open(`Added ${this.workOrder.displayId} to ${this.selectedWorkOrderGroup.name}`, '', <MatSnackBarConfig>{
                    duration: 2000,
                });

                this.workOrder.workOrderGroupName = this.selectedWorkOrderGroup.name;
                this.getWorkOrderGroupsAndUpdateViews();
            },
            error => {
                this.snackBar.open(`Failed to add work order to group`, 'X');
            }
        );
    }

    checkTempMeterAction() {
        this.workOrderActions.forEach(workOrderAction => {
            if (workOrderAction.actionId.toString() !== ActionConstants.InactivateMeterId.toString() && workOrderAction.actionId.toString() !== ActionConstants.ReactivateMeterId.toString()) {
                this.showUndoButton = true;
            }
        });
    }

    StatusIsDraftedOrReadyForReview(status: string): boolean {
        return status === LetterOfDirectionStatusType.Drafted || status === LetterOfDirectionStatusType.ReadyForReview;
    }

    jsonToItem(json: any): WorkOrder {
        const item = this.getInstance();
        for (const prop in json) {
            item[prop] = json[prop];
        }
        return item;
    }

    getInstance(): WorkOrder {
        return new WorkOrder();
    }

    changeToDescriptionTab() {
      this.setWorkOrder(this.workOrder.id);
    }

    changeToAttachmentTab() {
        this.selectedIndex = 4;
        localStorage.removeItem('NavigateToAttachmentsTab');
    }

    getBlock(meter: Meter) {
        this.blockService.getBlockById(meter.blockId)
        .subscribe(block => {
            this.block = block;
        });
    }

    getDaysToComplete(dueDate: string): number {
        if (dueDate) {
            const today = new Date();
            return -(moment(today).startOf('day').diff(moment(dueDate).startOf('day'), 'days'));
        }
    }

    getProjectedCompletionDate(): Date {
        return this.projectedCompletionDate.getProjectedCompletionDate(this.workOrderActions);
    }

    getEffectiveDate(): Date {
        const sortedActions = this.workOrderActions.sort((a, b) => {
            return DateTimeUtility.compareDate(a.effectiveDate, b.effectiveDate) ? -1 : 1;
        });
        if (sortedActions[0]) {
            return sortedActions[0].effectiveDate;
        }

    }

    displayCancelWorkOrder() {
        if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
            return false;
        }
        const isCityUser = this.TokenCache.checkForCityUser();
        return !this.workOrder.containsCompletedInventoryChanges && !isCityUser && this.workOrder.currentStatus !== 'Cancelled';
    }

    displaySendSurveyRequest(){
        if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId
            || this.workOrder.currentStatusId === WorkOrderStatusConstants.CancelledId) {
            return false;
        }

        if(!this.workOrderActions
            || this.workOrderActions.length < 1){
                return false;
            }

        const isCityUser = this.TokenCache.checkForCityUser();

        let workOrderAllowsSurvey = false;

        this.workOrderActions.forEach(action => {
            //if we find any of these types, we can send a survey
            workOrderAllowsSurvey = workOrderAllowsSurvey || this.workOrderActionIsSurveyable(action);
        })

        return !isCityUser && workOrderAllowsSurvey;

    }

    workOrderActionIsSurveyable(woa: WorkOrderAction){
        return woa.actionId === ActionConstants.InstallMeterId
        || woa.actionId === ActionConstants.InstallSpaceId
        || woa.actionId === ActionConstants.RemoveSpaceId
        || woa.actionId === ActionConstants.RelocateMeterId
        || woa.actionId === ActionConstants.ReactivateMeterId
    }

    displayUndoWorkOrder() {
        if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
            return false;
        }
        const isCityUser = this.TokenCache.checkForCityUser();
        const adminHasAlreadyRequestedUndo =
          this.workOrder.mostRecentResponse &&
          this.workOrder.mostRecentResponse.responderRole &&
          this.workOrder.mostRecentResponse.responderRole === RoleOptionsConstants.ConcessionaireName.value;

        return this.workOrder.currentStatus === 'Completed' && this.workOrder.allInventoryChangesCompleted && !isCityUser && !adminHasAlreadyRequestedUndo;
    }

    displayApproveUndoWorkOrder() {
        if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
            return false;
        }
      const isCityUser = this.TokenCache.checkForCityUser();
      const isCpmElevatedUser = this.TokenCache.isElevatedApproverUser();
      const tempApprovalStatus = this.workOrder.tempApprovalStatus;

      const showToCity = isCityUser && (tempApprovalStatus === TempApprovalStatusTypes.PendingSecondaryApproval || tempApprovalStatus === TempApprovalStatusTypes.TempApproved);
      const showToCpmElevated = isCpmElevatedUser && tempApprovalStatus === TempApprovalStatusTypes.PendingSecondaryApproval;

      return this.workOrder.allInventoryChangesCompleted && (showToCity || showToCpmElevated);
    }

    displayDenyUndoWorkOrder() {
        if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
            return false;
        }
      const isCityUser = this.TokenCache.checkForCityUser();
      const tempApprovalStatus = this.workOrder.tempApprovalStatus;
      const requiresCityAttention = tempApprovalStatus === TempApprovalStatusTypes.PendingSecondaryApproval || tempApprovalStatus === TempApprovalStatusTypes.TempApproved;

      return isCityUser && requiresCityAttention;
    }

  isBulkAction(action): boolean {
    return action.actionId === ActionConstants.RegularRateAdjustmentId || action.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId || action.actionId === ActionConstants.UpdateMeterAttributesId;
  }

  tempApproveLetterOfDirection() {
      this.snackBar.open('Adding Temporary Approval', '');
      const response = new LetterOfDirectionResponse();
      response.letterOfDirectionId = this.workOrder.currentLetterOfDirection.id;
      response.responseType = LetterOfDirectionResponseConstants.Approve;
      this.workOrderResponseService.submitLetterOfDirectionResponse(this.workOrder.id, response).subscribe(response => {
          this.snackBar.open('Approval Added.', '');
          window.location.reload();
        },
          error => {
            this.snackBar.open('Unable to accept Letter of Direction', 'X');
        });
  }

  approveLetterOfDirection() {
    this.dialogRefConfirmation = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: '360px',
    });
    this.dialogRefConfirmation.componentInstance.confirmMessage = `Are you sure you want to accept letter of direction LODv${this.workOrder.currentLetterOfDirection.versionNumber}?`;

    this.dialogRefConfirmation.afterClosed().subscribe(result => {
      if (result) {
        this.snackBar.open('Accepting Letter of Direction...', '');
        this._letterOfDirectionService.approveLetterOfDirection(this.workOrder.id, this.workOrder.currentLetterOfDirection.id)
        .subscribe(
          result => {
            this.snackBar.open('Accepted Letter of Direction', 'X');
            this.setWorkOrder(this.workOrder.id);
          },
          error =>{
            this.snackBar.open('Unable to accept Letter of Direction', 'X');
          }
          );
      }
    });
  }

  returnWithComments(isRejection: boolean) {
    this.dialogRefComments = this.dialog.open(CommentsDialogComponent, {
      height: '400px',
      width: '500px'
    });
    this.dialogRefComments.componentInstance.isRejection = isRejection;
    this.dialogRefComments.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        this.snackBar.open('Returning Letter of Direction with comments...', '');
        this._letterOfDirectionService.returnWithComments(this.workOrder.id, this.workOrder.currentLetterOfDirection.id, result, isRejection)
        .subscribe(
          result => {
            this.snackBar.open('Returned Letter of Direction with comments', 'X');
            window.location.reload();
          }
        );
      }
    });
  }

    checkReversibility(isEligibleForReversal: boolean, workOrderActions: WorkOrderAction[], effectiveDate: Date) {
        //always assume false initially
        this.showReverseButton = false;
        this.adminOverrideReverseButton = false;

        //city is never allowed to reverse
        const isCityUser = this.TokenCache.checkForCityUser();
        if(isCityUser){
            return;
        }

        if( this.workOrder.currentStatus !== 'Completed' //must be completed
            || !this.workOrder.allInventoryChangesCompleted // all inventory changes must be completed
            || this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId){ //cannot be paused
            return;
        }

        //if user is a cpm admin, and this is a reversible type, always show the button
        if(this.TokenCache.checkForCPMAdmin() && isEligibleForReversal){
            this.adminOverrideReverseButton = true;
        }

        if (!isEligibleForReversal) //must be generally eligible
        {
            return;
        }

        //if we got this far, test each action
        workOrderActions.forEach(x => {
            if (x.actionId !== ActionConstants.ChangeRatePackageId) {
                return; // if it is not a change rate package it is not an action we need to check
            }
            x.spaceInventoryChanges.forEach(change => {
                this.reversibilityDeciderService.checkSpaceActionReversibility(change.spaceId).subscribe(reversible => {
                    if (reversible) {
                        this.showReverseButton = true; // if any are reversible the work order is reversible
                        return;
                    }
                }, error => {
                  console.log(error);
                });
            });
        });

    }

    toggleEscalation() {
        this._workOrderService.toggleWorkOrderEscalation(this.workOrder.id, !this.workOrder.isEscalated)
            .subscribe(response => {
                this.workOrder.isEscalated = response.result;
                this.setUpEscalationButton(this.workOrder);
            },
            error => {
              this.snackBar.open(`Failed to change work order escalation`, 'X');
            });
    }

    setUpEscalationButton(workorder: WorkOrder): any {
        if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
            this.showEscalationButton = false;
        }
        else {
            this.showEscalationButton = workorder.currentStatusId < WorkOrderStatusType.CompletedId;
        }

        if (this.workOrder.isEscalated) {
            this.escalationButtonText = this.deescalateText;
        }
        else {
            this.escalationButtonText = this.escalateText;
        }
     }

    createNewRatePackageReversalWorkOrder(reason: any, workOrderActionIds: Array<string>, historicalDate?:Date): any {
         this.reversibilityDeciderService.getRatePackageReversalModelsForWorkOrderAction(workOrderActionIds, historicalDate)
            .subscribe(x => {
                const workOrderRatePackageReversals = this.reversibilityDeciderService.parseReversibilityModels(x);
                if (!workOrderRatePackageReversals) {
                    return;
                }

                const persistenceKey = this.reversibilityDeciderService.persistForNavigation(this.workOrder, workOrderRatePackageReversals, reason, historicalDate);
                this.router.navigate([`/workorders/create/`], {queryParams: { prepopulationKey: persistenceKey}});

            }, error => {
                this.snackBar.open('Error determining reversibility', 'X');
                console.log(error);
            });
    }

    precheckReservedPowerReversal(){

        //if this is an override reversal, show an extra warning
        if(!this.showReverseButton && this.adminOverrideReverseButton){

            const data = {
                spaceIds: []
            };

            this.workOrderActions.forEach(x => {
                if (x.actionId !== ActionConstants.ChangeRatePackageId) {
                    return; // if it is not a change rate package it is not an action we need to check
                }

                x.spaceInventoryChanges.forEach(change => {
                    data.spaceIds.push(change.spaceId)
                });
            });

            const dialogRef = this.dialog.open(WorkOrderReverseConfirmationComponent, {
                width: '500px',
                height: 'auto',
                data
              });

            dialogRef.afterClosed().subscribe(payload => {
               if(payload && payload.submit){
                    this.openWorkOrderConfirmation('historically reverse', payload.historicalDate) //only proceed if accepted
               }
            });
        }
        else{
            this.openWorkOrderConfirmation('reverse')
        }
    }

    openWorkOrderConfirmation(mode: string, historicalDate?:Date) {
      const data = {
          workOrderId: this.workOrder.id,
          actionType: mode,
          workOrder: this.workOrder
      };

      const dialogRef = this.dialog.open(WorkOrderCancelConfirmation, {
          width: '500px',
          height: 'auto',
          data: data
        });

        dialogRef.afterClosed().subscribe(reason => {
          if (mode === this.cancelConstant) {
            if (reason) {
                  const changeStatus = new WorkOrderChangeStatus();
                  changeStatus.workOrderId = this.workOrder.id;
                  changeStatus.comments = 'Work Order ' + mode + ': ' + reason;

                  this._workOrderService.cancelWorkOrder(changeStatus)
                  .subscribe(response => {
                      this.snackBar.open('Cancelled Work Order', '', <MatSnackBarConfig>{
                      duration: 2000,
                      });
                      window.location.reload();
                  },
                  error => {
                      const messageBody = JSON.parse(error['_body'].message);
                      this.snackBar.open(messageBody, 'X');
                  });
              }
          }
          else if (mode === 'reverse') {
              if (reason) {
                const changeRatePackageWorkOrderActionIds = this.letterOfDirection.workOrderActions
                .filter(x => x.actionId === ActionConstants.ChangeRatePackageId)
                .map(x => x.id);
                this.createNewRatePackageReversalWorkOrder('Work Order Rate Package Reversal: ' + reason, changeRatePackageWorkOrderActionIds);
              }
          }
          else if (mode === 'historically reverse') {
              if (reason) {
                const changeRatePackageWorkOrderActionIds = this.letterOfDirection.workOrderActions
                .filter(x => x.actionId === ActionConstants.ChangeRatePackageId)
                .map(x => x.id);
                this.createNewRatePackageReversalWorkOrder('Work Order Historical Rate Package Reversal: ' + reason, changeRatePackageWorkOrderActionIds, historicalDate);
              }
          }
          else if (mode === 'undo') {
            if (reason) {
              const response = new WorkOrderResponse();
              response.workOrderId = this.workOrder.id;
              response.responseType = 'Approve';
              response.typeOfActionToApprove = TypeOfActionToApproveConstants.undo;
              response.comment = new WorkOrderComment();
              response.comment.text = 'Work Order ' + mode + ': ' + reason; // the rest of this should be setup on the backend
              this.workOrderResponseService.submitWorkOrderResponse(this.workOrder.id, response)
                .subscribe(response => {
                    this.snackBar.open('Work Order ' + this.workOrder.displayId + ' Undo Request Submitted', '', <MatSnackBarConfig>{
                      duration: 2000,
                    });
                    window.location.reload();
                  },
                  error => {
                    const messageBody = JSON.parse(error['_body'].message);
                    this.snackBar.open(messageBody, 'X');
                  });
            }
          }
          else if (mode === 'approve') {
              if (reason) {
                const response = new WorkOrderResponse();
                response.workOrderId = this.workOrder.id;
                response.responseType = 'Approve';
                response.typeOfActionToApprove = this.workOrder.mostRecentResponse.typeOfActionToApprove;
                response.comment = new WorkOrderComment();
                response.workOrderResponseGroupId = this.workOrder.mostRecentResponse.workOrderResponseGroupId;
                response.historicalEffectiveDate = this.workOrder.mostRecentResponse.workOrderResponseGroup.historicalEffectiveDate;
                response.comment.text = 'Work Order ' + mode + ': ' + reason; // the rest of this should be setup on the backend

                if (this.workOrder.mostRecentResponse.typeOfActionToApprove === TypeOfActionToApproveConstants.undo) {
                  this._workOrderService.undoWorkOrder(this.workOrder.id, response)
                    .subscribe(response => {
                        this.snackBar.open('Work Order ' + this.workOrder.displayId + ' Undone', '', <MatSnackBarConfig>{
                          duration: 2000,
                        });
                        window.location.reload();
                      },
                      error => {
                        const messageBody = JSON.parse(error['_body'].message);
                        this.snackBar.open(messageBody, 'X');
                      });
                }
                else if (this.workOrder.mostRecentResponse.typeOfActionToApprove === TypeOfActionToApproveConstants.historicalChange) {
                  this.inventoryChangeService.approveHistoricalChange(response)
                    .subscribe(response => {
                        this.snackBar.open('Work Order ' + this.workOrder.displayId + ' Approved For Historical Change', '', <MatSnackBarConfig>{
                          duration: 2000,
                        });
                        window.location.reload();
                      },
                      error => {
                        const messageBody = JSON.parse(error['_body'].message);
                        this.snackBar.open(messageBody, 'X');
                      });
                }
              }
          }
          else if (mode === 'deny') {
              if (reason) {
                const response = new WorkOrderResponse();
                response.workOrderId = this.workOrder.id;
                response.responseType = 'Deny';
                response.typeOfActionToApprove = this.workOrder.mostRecentResponse.typeOfActionToApprove;
                response.comment = new WorkOrderComment();
                response.historicalEffectiveDate = this.workOrder.mostRecentResponse.workOrderResponseGroup.historicalEffectiveDate;
                response.workOrderResponseGroupId = this.workOrder.mostRecentResponse.workOrderResponseGroupId;
                response.comment.text = 'Work Order ' + mode + ': ' + reason; // the rest of this should be setup on the backend

                this._workOrderService.undoWorkOrder(this.workOrder.id, response)
                  .subscribe(response => {
                      this.snackBar.open('Work Order ' + this.workOrder.displayId + ' ' + this.workOrder.mostRecentResponse.typeOfActionToApprove + ' Denied', '', <MatSnackBarConfig>{
                        duration: 2000,
                      });
                      window.location.reload();
                    },
                    error => {
                      const messageBody = JSON.parse(error['_body'].message);
                      this.snackBar.open(messageBody, 'X');
                    });
              }
          }
          else if (mode === 'pause') {
              if (reason) {
                  this._workOrderService.pauseWorkOrder(this.workOrder.id, 'Work Order ' + mode + ': ' + reason)
                    .subscribe(response => {
                        this.snackBar.open('Work Order ' + this.workOrder.displayId + ' Paused', '', <MatSnackBarConfig>{
                            duration: 2000
                        });
                        window.location.reload();
                    },
                error => {
                    const messageBody = JSON.parse(error['_body'].message);
                    this.snackBar.open(messageBody, 'X');
                });
              }
          }
          else if (mode === 'reinstate') {
              if (reason) {
                  this._workOrderService.reinstateWorkOrder(this.workOrder.id, 'Work Order ' + mode + ': ' + reason)
                    .subscribe(response => {
                        this.snackBar.open('Work Order ' + this.workOrder.displayId + ' Reinstated', '', <MatSnackBarConfig>{
                            duration: 2000
                        });
                        window.location.reload();
                    },
                error => {
                    const messageBody = JSON.parse(error['_body'].message);
                    this.snackBar.open(messageBody, 'X');
                });
              }
          }
          else if (mode === 'request a survey for') {
            if (reason) {
                this.sendSurveyRequestsForAllActions(reason);
            }
          }
        });
    }

    sendSurveyRequestsForAllActions(reason: string){
        var blockIDs = new Array();

        var surveyWorkOrderActions =
        this.workOrderActions.filter(workOrderAction => {
            if(!this.workOrderActionIsSurveyable(workOrderAction)){
                return false;
            }

            blockIDs.push(workOrderAction.blockIDs);

            return true;
        });

        //must have at least one surveyable action
        if(surveyWorkOrderActions.length < 1){
            return;
        }

        var surveyBlocks = Array.from(new Set(blockIDs));

        //must have at least one surveyable block
        if(surveyBlocks.length < 1){
            this.snackBar.open(`Could not find any blocks to survey for this Work Order`, 'X');
            return;
        }

        this.sendSurveyRequest(reason, surveyBlocks);
    }

    sendSurveyRequest(reason: string, blockIDs: string[]) {
        blockIDs.forEach(blockId =>{

            this.blockService.getBlockById(blockId).subscribe(block =>{
                var request = new SurveyRequest();
                request.blockId = block.displayId;
                request.dueDate = this.workOrder.completionDate ? this.workOrder.completionDate.toUTCString() : this.workOrder.dueDate;
                request.type = this.workOrder.displayId.toString();
                request.instructions = reason;

                this._workOrderService.requestSurveyForWorkOrder(request).subscribe(response => {
                    this.snackBar.open(`Requested survey for Work Order ${request.type}, block ${request.blockId}`, '', <MatSnackBarConfig>{
                        duration: 2000,
                    });
                },
                error=>{
                    const messageBody = JSON.parse(error['_body'].message);
                    console.log(`Failed to request survey for Work Order ${request.type}, block ${request.blockId}: ${messageBody}`)
                    this.snackBar.open(`Failed to request survey for Work Order ${request.type}, block ${request.blockId}`, 'X');
                });
            },
            error => {
                this.snackBar.open(`Could not find block with id ${blockId}`, 'X');
            });

        })
    }

    modifyWorkOrder() {
        const url = this.getModifyUrl();
        this.router.navigate([url]);
    }
    modifyWorkOrderAsCity() {
        const url = `${this.getModifyUrl()}`;
        this.router.navigate([url], {queryParams: { overrideToCityUserRole: 'true'}});
    }

    getModifyUrl(): string {
        return `/workorders/create/${this.workOrder.id}`;
    }

    modifyAcceptedWorkOrder() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '600px',
            height: 'auto',
        });
        const hasCompletedInventoryChanges = this.workOrder.currentLetterOfDirection.workOrderActions.filter(
            woa => woa.spaceInventoryChanges.filter(change => change.status === InventoryChangeStatusType.Completed).length > 0
        ).length > 0;

        dialogRef.componentInstance.confirmMessage = hasCompletedInventoryChanges ?
            'Are you sure you would like to delete pending inventory changes and modify this work order?' :
            'Are you sure you would like to modify this accepted work order?';

        dialogRef.afterClosed().subscribe(
            confirmed => {
                if (confirmed) {
                    if (!hasCompletedInventoryChanges) {
                        this.createNewDraftedLetterOfDirection();
                    }
                    else {
                        const observables = this.workOrder.currentLetterOfDirection.workOrderActions.map(workOrderAction => {
                            return this.inventoryChangeService.deleteInventoryPendingFacets(workOrderAction.id);
                        });

                        observableForkJoin(observables).subscribe(
                            response => {
                                this.createNewDraftedLetterOfDirection();
                            },
                            error => {
                                const messageBody = 'Failed to delete pending inventory changes and modify work order';
                                this.snackBar.open(messageBody, 'X');
                            });
                    }
                }

            });
    }

    createNewDraftedLetterOfDirection() {
        const workOrderUpdate = this.generateWorkOrderUpdate();
        this._workOrderService.modifyAcceptedWorkOrder(workOrderUpdate).subscribe(
            result => {
                if (result) {
                    this.router.navigate([this.getModifyUrl()]);
                }
            },
            error => {
                const messageBody = 'Failed to create new letter of direction';
                this.snackBar.open(messageBody, 'X');
            });
    }

    generateWorkOrderUpdate(): WorkOrderUpdate {
        const workOrderUpdate = new WorkOrderUpdate();
        workOrderUpdate.id = this.workOrder.id;
        workOrderUpdate.workOrderStatus = this.workOrder.currentStatus;
        workOrderUpdate.workOrderStatusId = this.workOrder.currentStatusId;
        workOrderUpdate.workOrderActions = this.workOrderActions;
        return workOrderUpdate;
    }

    displayAssociatedMeterDetails(action: WorkOrderAction): boolean {
      return action.meterIDs.length > 0
      && action.actionId !== ActionConstants.InstallMeterId;
    }

    setSubmittedRateAdjustmentGroups(regularRateAdjustmentInventoryChanges: RegularRateAdjustmentInventoryChanges[]): RegularRateAdjustmentInventoryChanges[] {
      const submittedRegularRateAdjustmentInventoryChanges = regularRateAdjustmentInventoryChanges.filter(rateAdjustmentChange => rateAdjustmentChange && rateAdjustmentChange.relatedChanges && rateAdjustmentChange.relatedChanges.length > 0);
      return submittedRegularRateAdjustmentInventoryChanges;
    }

    setUpdateMeterAttributesGroups(updateMeterAttributesInventoryChanges: UpdateMeterAttributesInventoryChanges[]): UpdateMeterAttributesInventoryChanges[] {
      const submittedUpdateMeterAttributesInventoryChanges = updateMeterAttributesInventoryChanges.filter(updateGroup => updateGroup && updateGroup.relatedMeterChanges && ( updateGroup.relatedMeterChanges.length > 0 || updateGroup.relatedSpaceChanges.length > 0));
      return submittedUpdateMeterAttributesInventoryChanges;
    }
}
