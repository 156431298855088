import { ChangeDetectorRef, Component, OnInit, ViewChild, Output, EventEmitter, ErrorHandler, Input, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { Observable ,  Subject } from 'rxjs';

import {TempApprovalStatusTypes, WorkOrderStatusType} from './../models/work-order';
import { SearchableWorkOrder } from './../models/searchable-work-order';
import { WorkOrdersService } from '../services/work-orders.service';
import { WorkOrderFilterRepresentation } from '../work-order-filter/work-order-filter-representation';
import { WorkOrderRoleConstants } from '../../security/login/securityConstants';
import { DueDateConstants } from '../models/due-date-constants';
import { TokenCache } from '../../security/token-cache';
import { ProjectedCompletionDate } from '../projected-completion-date';
import { DateTimeUtility } from '../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { RoleOptionsConstants } from '../../environment';
import {catchError, map} from 'rxjs/operators';

@Component({
    selector: 'app-workorder-list',
    templateUrl: 'work-order-list.component.html',
    styleUrls: ['work-order-list.component.css']
})
export class WorkOrderListComponent implements OnInit {

    displayedColumns: Array<string>;
    dataSource: WorkOrderListDataSource;

    pageProperties: PageEvent;
    viewMyWorkOrdersOrder = 'isEscalatedReversed,dueDate,currentStatusReversed';
    viewMyWorkOrdersOrderCity = 'isEscalatedReversed,originalRequestDate,currentStatusReversed';
    orderByCategory = 'isEscalatedReversed,currentStatus,displayId';
    paginationParameters: PaginationParameters;
    exportWorkOrderFilter: WorkOrderFilterRepresentation;
    errorMessage: any;

    filtersPrepopulated = false;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    @Input()
    context: string = WorkOrderListContextConstants.All;

    filterSubject: Subject<WorkOrderFilterRepresentation> = new Subject<WorkOrderFilterRepresentation>();
    filter: WorkOrderFilterRepresentation;
    isNewFilter = false;
    anyFiltersApplied = false;

    WorkOrderStatusType = WorkOrderStatusType;

    workOrderListLoaded = false;
    isCityUser: boolean;
    isCpmElevatedUser: boolean;

    projectedCompletionDate = new ProjectedCompletionDate();

    constructor(private _workorderService: WorkOrdersService,
        private _errorHandler: ErrorHandler, private _changeDetectorRef: ChangeDetectorRef, private tokenCache: TokenCache, private fileDownloadService: FileDownloadService, private snackBar: MatSnackBar) {
            this.pageProperties = new PageEvent();
            this.pageProperties.length = 0;
            this.pageProperties.pageIndex = 0;
            this.pageProperties.pageSize = 100;

      this.isCityUser = this.tokenCache.checkForCityUser();
      this.isCpmElevatedUser = this.tokenCache.isElevatedApproverUser();
    }

    ngOnInit(): void {
        this.setColumns();
        this.paginationParameters = {
            orderBy: this.orderByCategory,
            skip: 0,
            take: this.pageProperties.pageSize,
            isAscending: true,
            totalWorkOrderCount: 0
        };
        this.setFilters();
        this.workOrderListLoaded = false;
        this.refreshDataSource(this.pageProperties);

        if (this.filterSubject !== undefined) {
            this.filterSubject.subscribe((filter) => {
                this.filter = filter;

                this.anyFiltersApplied = this.checkIfAnyFiltersApplied();
                this.isNewFilter = true;
                this.workOrderListLoaded = false;
                this.refreshDataSource(this.pageProperties);
                this.isNewFilter = false;
            });
        }

    }


    setFilters() {
        if (this.context !== WorkOrderListContextConstants.All) {
            this.filtersPrepopulated = true;
        }
        if (this.context === WorkOrderListContextConstants.City) {
            this.prePopulateCityFilters();
        }
        else if (this.context === WorkOrderListContextConstants.ViewMyWorkOrders) {
            this.prePopulateMyWorkOrders();
        }
        else if (this.context === WorkOrderListContextConstants.TempApproved) {
          this.prePopulateTempApprovedWorkOrders();
        }
    }

    prePopulateTempApprovedWorkOrders() {
      const workOrderFilterRepresentation = new WorkOrderFilterRepresentation();
      workOrderFilterRepresentation.TempApprovalStatus = TempApprovalStatusTypes.TempApproved;
      this.filter = workOrderFilterRepresentation;
      this.paginationParameters.isAscending = true;
      this.sort.direction = 'asc';
      this.paginationParameters.orderBy = this.orderByCategory;
    }

    prePopulateMyWorkOrders() {
        const role = this.isCityUser ? RoleOptionsConstants.CityRoleName.value : RoleOptionsConstants.ConcessionaireName.value;

        if (this.isCpmElevatedUser) {
          const workOrderFilterRepresentation = new WorkOrderFilterRepresentation();
          workOrderFilterRepresentation.AssignedUserRole = RoleOptionsConstants.CityRoleName.value;
          workOrderFilterRepresentation.TempApprovalStatus = TempApprovalStatusTypes.PendingSecondaryApproval;
          this.filter = workOrderFilterRepresentation;
          this.paginationParameters.isAscending = true;
          this.sort.direction = 'asc';
          this.paginationParameters.orderBy = this.viewMyWorkOrdersOrder;
        }
        else {
          this.prePopulateFilters(role, this.viewMyWorkOrdersOrder);
        }
    }
    prePopulateCityFilters() {
      this.prePopulateFilters(RoleOptionsConstants.CityRoleName.value, this.viewMyWorkOrdersOrderCity);
    }
    prePopulateFilters(assignedUserRole: string, orderBy: string) {
        const workOrderFilterRepresentation = new WorkOrderFilterRepresentation();
        workOrderFilterRepresentation.AssignedUserRole = assignedUserRole;
        workOrderFilterRepresentation.InProgress = 'true';
        this.filter = workOrderFilterRepresentation;
        this.paginationParameters.isAscending = true;
        this.sort.direction = 'asc';
        this.paginationParameters.orderBy = orderBy;
    }

    checkIfAnyFiltersApplied(): boolean {
        Object.keys(this.filter).forEach(key => {
            if (this.filter[key]) {
                return true;
            }
        });
        return false;
    }
    setColumns() {
        if (this.isCityUser) {
            this.displayedColumns = ['displayId', 'canvasId', 'requester', 'action', 'originalRequestDate', 'revisedRequestDate', 'assignedTo', 'daysToComplete', 'dueDate', 'completionDate', 'effectiveDate', 'currentStatus', 'meterDisplayId', 'blockDisplayId', 'areaDisplayId', 'zoneDisplayId', 'groupName', 'escalated', 'tempApprovalColumn', 'histChange'];
        } else {
          this.displayedColumns = ['displayId', 'canvasId', 'requester', 'action', 'originalRequestDate', 'revisedRequestDate', 'assignedTo', 'daysToComplete', 'dueDate', 'projectedCompletionDate', 'completionDate', 'effectiveDate', 'currentStatus', 'meterDisplayId', 'blockDisplayId', 'areaDisplayId', 'zoneDisplayId', 'groupName', 'escalated', 'tempApprovalColumn', 'histChange'];
        }
    }

    getWorkOrderFilter(evt) {
      this.dataSource = null;
      this.workOrderListLoaded = false;
        this.filterSubject.next(evt);
    }

    refreshDataSource(pageEvent: PageEvent) {
      this.workOrderListLoaded = false;
      this.dataSource = null;
        this.paginationParameters.skip = pageEvent.pageSize * pageEvent.pageIndex;
        this.paginationParameters.take = pageEvent.pageSize;

        this.dataSource = new WorkOrderListDataSource(this._workorderService, pageEvent.pageSize, this.sort,
            this.paginationParameters, this._errorHandler, this.errorMessage, this.filter, this.isNewFilter, this.paginator);

        this.dataSource.pagerServiceEventEmitter.subscribe(parameters => {
            this.paginationParameters = parameters;
            this.pageProperties.length = parameters;
            this.pageProperties = pageEvent;

            this.workOrderListLoaded = true;
        });
        setTimeout(function() {
            window.scrollTo(0,0); 
        }, 1500);
    }

    sortChanged() {
        this.pageProperties.pageIndex = 0;
        this.paginationParameters.skip = 0;
        this.workOrderListLoaded = false;
        this.refreshDataSource(this.pageProperties);
    }

    formatDate(dateFromApi: string): string {
        if (!dateFromApi || dateFromApi === DateTimeUtility.MINDATE) {
            return '';
        }
        const newDate = new Date(dateFromApi);
        const stringToReturn = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear();
        return stringToReturn;
    }

    getProjectedCompletionDate(searchableWorkorder: SearchableWorkOrder): Date {
        if (searchableWorkorder.workOrder.currentLetterOfDirection.workOrderActions) {
            return this.projectedCompletionDate.getProjectedCompletionDate(searchableWorkorder.workOrder.currentLetterOfDirection.workOrderActions);
        }
        else {
            return null;
        }
    }

    formatDaysToComplete(searchableWorkOrder: SearchableWorkOrder): string {

      if (!searchableWorkOrder.daysToComplete && !searchableWorkOrder.dueDate) {
        return '';
      }

      if (searchableWorkOrder.daysToComplete === null
        || !searchableWorkOrder.daysToComplete === undefined) {
        return '';
      }

      if (searchableWorkOrder.dueDateRule === DueDateConstants.businessDayKey) {
        return searchableWorkOrder.daysToComplete + ' ' + DueDateConstants.businessDayFriendly;
      }
      else if (searchableWorkOrder.dueDateRule === DueDateConstants.calendarDayKey) {
        return searchableWorkOrder.daysToComplete + ' ' + DueDateConstants.calendarDayFriendly;
      }
      else {
        return searchableWorkOrder.daysToComplete + ' Day(s)';
      }

    }

    formatDaysToCompleteNumber(searchableWorkOrder: SearchableWorkOrder): number {

        if (!searchableWorkOrder.daysToComplete && !searchableWorkOrder.dueDate) {
          return null;
        }
  
        if (searchableWorkOrder.daysToComplete === null
          || !searchableWorkOrder.daysToComplete === undefined) {
          return null;
        }

        return parseInt(searchableWorkOrder.daysToComplete);
      }

    getExportInventoryFilter(evt: WorkOrderFilterRepresentation) {
        this.exportWorkOrderFilter = evt;
        this.export(this.exportWorkOrderFilter);
    }

    export(exportWorkOrderFilter: WorkOrderFilterRepresentation) {
        if (!this.paginationParameters.orderBy) {
            this.paginationParameters.orderBy = 'displayId';
        }
        if (this.paginationParameters.isAscending === undefined) {
            this.paginationParameters.isAscending = true;
        }
        this.snackBar.open('Downloading Work Order Report', '', <MatSnackBarConfig>{
            duration: 2000
        });
        return this._workorderService.exportWorkOrders(this.paginationParameters.orderBy, this.paginationParameters.isAscending, this.exportWorkOrderFilter).subscribe(result => {
            const fileDownloadResult = this.fileDownloadService.MapResponseToFileResult(result);
            this.fileDownloadService.SaveFileToBrowser(fileDownloadResult);
        });
    }
}

export class WorkOrderListDataSource extends DataSource<any> {
    constructor(private _workorderService: WorkOrdersService,
        private _pageSize: number,
        private _sort: MatSort,
        public paginationParameters: PaginationParameters,
        private _errorHandler: ErrorHandler,
        public errorMessage: any,
        private filter: WorkOrderFilterRepresentation,
        private isNewFilter: boolean,
        private paginator: MatPaginator) {
            super();
        }
    public workorders: SearchableWorkOrder[] = new Array<SearchableWorkOrder>();

    @Output()
    pagerServiceEventEmitter: EventEmitter<PaginationParameters> = new EventEmitter<PaginationParameters>(true);

    connect(): Observable<SearchableWorkOrder[]> {
        const displayDataChanges = [
            this.workorders,
            this._sort.sortChange
        ];

         if ((this._sort.direction &&  this.paginationParameters.isAscending !== (this._sort.direction === 'asc'))) {
             this.paginationParameters.isAscending = (this._sort.direction === 'asc') || !(this._sort.direction);
         }

        if (this._sort.active && this.paginationParameters.orderBy !== this._sort.active) {
            this.paginationParameters.orderBy = this._sort.active;
        }
        this.paginationParameters.take = this._pageSize;

        if (this.isNewFilter) {
            if (this.paginator) {
            this.paginator.pageIndex = 0;
            }
        }

      this.workorders = [];

        return this._workorderService.getAllWorkOrders(this.paginationParameters.orderBy,
            this.paginationParameters.isAscending,
            this.paginationParameters.skip,
            this.paginationParameters.take,
            this.filter).pipe(
            map(
                result => {
                    this.workorders = result.items;
                    if (result == null) {
                        this.workorders = [];
                    }
                    this.paginationParameters.totalWorkOrderCount = result.totalItems;

                    this.pagerServiceEventEmitter.emit(this.paginationParameters);
                    return this.workorders;
                },
                error => {
                    this.workorders = [];
                    this._errorHandler.handleError(error);
                    this.errorMessage = error;

                    return this.workorders;
                }
            ));

    }

    disconnect() { }
}

export class PaginationParameters {
    orderBy: string;
    skip: number;
    take: number;
    isAscending: boolean;
    totalWorkOrderCount: number;
}

export class WorkOrderListContextConstants {
    public static City = 'City';
    public static ViewMyWorkOrders = 'ViewMyWorkOrders';
    public static All = 'All';
    public static TempApproved = 'TempApproved';
}