import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Area } from './area';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AreaService {
    constructor(private http: HttpClient) { }

    public getAllAreas(): Observable<Array<Area>> {
        return this.http.get('/Areas')
          .pipe(catchError(error => this.handleError(error)));
    }

    public getAreasByZoneId(id: string): Observable<Array<Area>> {
        return this.http.get('/zones/' + id + '/areas')
          .pipe(catchError(error => this.handleError(error)));
    }

    public createArea(area: Area): Observable<Area> {
        return this.http.post('/areas', area)
          .pipe(catchError(error => this.handleError(error)));
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
