import { StringExtensions } from './../../../string-extensions';
import { MeterTypeConstants, ParkingTypeConstants, MerchantConstants, ClosureAllowanceConstants, SpaceTypeConstants } from './../../../inventory-management/meters/meter-constants';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnChanges, Input } from '@angular/core';

import { InventoryChangeAudit } from './models/inventory-change-audit';
import { RatePackageService } from './../../../inventory-management/rate-packages/services/rate-package.service';
import { FineAmountService } from './../../../inventory-management/fine-amounts/fine-amounts.service';
import { FineAmount } from './../../../inventory-management/fine-amounts/fine-amount';
import { InventoryChangesAuditService } from './../../services/inventory-changes-audit.service';

@Component({
  selector: 'app-inventory-change-audit',
  templateUrl: './inventory-change-audit.component.html',
  styleUrls: ['./inventory-change-audit.component.css']
})
export class InventoryChangeAuditComponent implements OnChanges {

  constructor(private _inventoryChangesAuditService: InventoryChangesAuditService, private _fineAmountService: FineAmountService, private _ratePackageService: RatePackageService) { }

  @Input() workOrder;

  dataSource: MatTableDataSource<InventoryChangeAudit>;
  inventoryChangeHistories: InventoryChangeAudit[];
  selectedChangeHistory: InventoryChangeAudit;

  meterTypeConstants =  [MeterTypeConstants.MP104, MeterTypeConstants.CLZVirtualID, MeterTypeConstants.SSM, MeterTypeConstants.CWT];
  parkingTypeConstants = [ParkingTypeConstants.OnStreet, ParkingTypeConstants.ParkingLot, ParkingTypeConstants.CLZ, ParkingTypeConstants.WrigleyvilleEventParking];
  closureAllowanceConstants = [ClosureAllowanceConstants.CBD, ClosureAllowanceConstants.NonCBD];
  merchantConstants = MerchantConstants.AllMerchants;
  spaceTypeConstants = [SpaceTypeConstants.DiagonalStriped, SpaceTypeConstants.DiagonalUnstriped, SpaceTypeConstants.Parallel, SpaceTypeConstants.PerpendicularStriped, SpaceTypeConstants.PerpendicularUnstriped];
  fineAmounts: FineAmount[];

  displayedColumns = ['InventoryChangesVersion', 'SubmittedBy', 'SubmittedDateTime', 'ActivatedBy',  'ActivatedDateTime', 'LODVersion'];

  ngOnChanges() {
    this._fineAmountService.getAllFineAmounts()
    .subscribe(result => {
    this.fineAmounts = result;
    this._inventoryChangesAuditService.getInventoryChangesAuditByWorkOrderId(this.workOrder.id)
    .subscribe(result => {
      this.inventoryChangeHistories = result;
      this.inventoryChangeHistories.forEach(changeHistory => {
        changeHistory.spaceInventoryChangeAuditHistories.forEach(spaceAuditRecord => {
          const fineAmount = this.fineAmounts.filter(fa => fa.id === spaceAuditRecord.newFineAmountId);
          if (fineAmount.length > 0) {
            spaceAuditRecord.newFineAmount = fineAmount[0].amount;
            if (spaceAuditRecord.newRatePackageId) {
              this.getRatePackageName(spaceAuditRecord);
            }
          }
        });
      });
      this.dataSource = new MatTableDataSource(this.inventoryChangeHistories);
      this.selectedChangeHistory = this.inventoryChangeHistories[0];
    },
    error => {
      console.log(error);
    });
    });
  }

  selectAuditRecord(auditRecord: InventoryChangeAudit) {
    this.selectedChangeHistory = auditRecord;
  }

  highlightRow(auditRecord): boolean {
    if (!this.selectedChangeHistory) {
      return false;
    }
    return auditRecord.inventoryChangesVersion === this.selectedChangeHistory.inventoryChangesVersion;
  }

  getRatePackageName(spaceAuditRecord) {
    this._ratePackageService.getRatePackageById(spaceAuditRecord.newRatePackageId)
    .subscribe(result => {
      spaceAuditRecord.newRatePackageName = result.name;
    });
    }

    formatParkingType(parkingTypeId: string): string {
      if (parkingTypeId === StringExtensions.EmptyGuid) {
        return '';
      }
      return this.parkingTypeConstants.filter(ptc => ptc.value === parkingTypeId)[0].title;
    }

    formatMeterType(meterTypeId: string): string {
      if (meterTypeId === StringExtensions.EmptyGuid) {
        return '';
      }
      return this.meterTypeConstants.filter(ptc => ptc.value === meterTypeId)[0].title;
    }

    formatRequiredClosureAllowance(rcaId: string): string {
      if (rcaId === StringExtensions.EmptyGuid) {
        return '';
      }
      return this.closureAllowanceConstants.filter(ptc => ptc.value === rcaId)[0].title;
    }

    formatMerchant(merchantId: string): string {
      if (merchantId === StringExtensions.EmptyGuid) {
        return '';
      }
      return this.merchantConstants.filter(ptc => ptc.value === merchantId)[0].title;
    }

    formatSpaceTypes(spaceTypeString: string) {

      if (StringExtensions.StringIsEmpty(spaceTypeString)) {
        return spaceTypeString;
      }

      this.spaceTypeConstants.forEach(constant => {
        spaceTypeString = spaceTypeString.toLowerCase().replace(constant.id, constant.name);
      });

      return spaceTypeString;
    }

    formatFineAmounts(fineAmount: string) {
      if (fineAmount === StringExtensions.EmptyGuid) {
        return '';
      }
      return this.fineAmounts.filter(fa => fa.id === fineAmount)[0].amount;
    }
}
