import { SpaceClosureByWarehouse } from './../../spaces/space-closures/space-closure-warehouse';
import { Component, Input, OnChanges, ErrorHandler } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Space } from '../../spaces/space';
import { MeterService } from './../../meters/meters.service';
import { StringExtensions } from './../../../string-extensions';
import { WorkOrderHistory } from './../../work-order-history/work-order-history';
import { SpaceService } from './../../spaces/spaces.service';
import { MeterDowntime } from '../../meters/meter';
import { InventoryTimelineConstants } from './inventory-timeline-constants';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { SpaceStatusConstants } from '../../spaces/space-constants';
import * as moment from 'moment';

declare var google: any;

@Component({
  selector: 'app-inventory-timeline',
  templateUrl: './inventory-timeline.component.html',
  styleUrls: ['./inventory-timeline.component.css']
})
export class InventoryTimelineComponent implements OnChanges {

  @Input() space: Space;
  @Input() meterId: string;
  @Input() meterDisplayId: string;

  systemStartDate: Date;

  constructor(private _spaceService: SpaceService, private _meterService: MeterService,
    private datePipe: DatePipe, private startDateService: SystemStartTimeService,
    private errorHandler: ErrorHandler) { }

  ngOnChanges() {
      this.startDateService.getInitialSystemStartTime().subscribe(result => {
        this.systemStartDate = result;
        this.initSpace();
      },
      error => {
        console.log('error');
        this.errorHandler.handleError(error);
      });
  }

  initSpace() {
    if (this.space) {
      this._spaceService.getSpacesWorkOrderHistories(this.space.id)
        .subscribe(result => {
          this.createSpaceHistoryTimeline(result, this.datePipe, true, this.space.id, null, null, this.space);
        });
    }
    else if (StringExtensions.StringIsNotEmpty(this.meterId)) {

      this._meterService.getMeterWorkOrderHistories(this.meterId.toString())
        .subscribe(result => {

        const inoperableTimelineResponse: MeterDowntime[] = [];
        const closures: SpaceClosureByWarehouse[] = [];

        this._meterService.getClosuresByWarehouseId(this.meterId, 1000, 0)
            .subscribe(closureResult => {
              if (closureResult.items) {
                for (let i = 0; i < closureResult.items.length; i++) {
                  const t = closureResult.items[i];
                  const sc = new SpaceClosureByWarehouse();

                  sc.closureWarehouseId = t.closureWarehouseId;
                  sc.earliestClosureDate = new Date(t.earliestClosureDate);
                  sc.latestClosureDate = new Date(t.latestClosureDate);

                  closures.push(sc);
                }
              }
              this._meterService.getInoperableTimelineForMeter(this.meterDisplayId)
                .subscribe(inopResult => {
                  if (inopResult) {
                    for (let i = 0; i < inopResult.length; i++) {
                      const t = inopResult[i];
                      const mD = new MeterDowntime();

                      mD.meterDisplayId = t.meterDisplayId;
                      mD.inoperableId = t.inoperableId;
                      mD.inoperableMonth = new Date(t.inoperableMonth);
                      mD.startDateTime = new Date(t.startDateTime);
                      mD.endDateTime = new Date(t.endDateTime);
                      mD.notes = t.notes;

                      inoperableTimelineResponse.push(mD);
                    }
                    this.createSpaceHistoryTimeline(result, this.datePipe, false, this.meterId, inoperableTimelineResponse, closures);
                  }
                });
            }, error => {
              console.log(error);
            });
        });
    }
  }

  static sortHistories(a: WorkOrderHistory, b: WorkOrderHistory, systemStartDate: Date): number
  {
    //convert effective dates if we have never sorted this before
    if (!a.effectiveDate) {
      a.effectiveDate = this.localDateFromString(a.effectiveTimeStamp, systemStartDate);
      a.endDate = this.localDateFromString(a.endTimeStamp, systemStartDate);
    }

    if (!b.effectiveDate) {
      b.effectiveDate = this.localDateFromString(b.effectiveTimeStamp, systemStartDate);
      b.endDate = this.localDateFromString(b.endTimeStamp, systemStartDate);
    }

    if (a.effectiveDate.getTime() < b.effectiveDate.getTime()) {
      return -1;
    } else if (a.effectiveDate.getTime() > b.effectiveDate.getTime()) {
      return 1;
    } else {
      return 0;
    }
  }

  static localDateFromString(dateString:string, systemStartDate:Date):Date
  {
    if(!dateString || dateString == ''){
      return null;
    }

    let nonUtcDate = new Date(dateString) < systemStartDate ? systemStartDate : new Date(dateString);
    return new Date(nonUtcDate.getFullYear(), nonUtcDate.getUTCMonth(), nonUtcDate.getUTCDate());
  }

  static filterMeterHistoriesForSpace(meterChangeRows: WorkOrderHistory[], changeType: string, systemStartDate: Date, datePipe: DatePipe): WorkOrderHistory[] {
    let meterStatusChangeRows: WorkOrderHistory[] = [];
    meterChangeRows.sort((a: WorkOrderHistory, b: WorkOrderHistory) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate))
      .forEach(parentMeterChange => {

        var nextParent = meterChangeRows[meterChangeRows.indexOf(parentMeterChange) + 1];
        var previousChild: WorkOrderHistory;

        parentMeterChange.meterWorkOrderHistories.sort((a: WorkOrderHistory, b: WorkOrderHistory) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate))
          .forEach(childMeterWorkOrderHistory => {
            if (childMeterWorkOrderHistory.changeType !== changeType) {
              return;
            }

            //truncate time spans based on meter assignment; don't include things that happened
            //outside the time the space was on the meter

            //if this happens after the space is already on a different meter, ignore
            if (nextParent) {
              if (childMeterWorkOrderHistory.effectiveDate >= nextParent.effectiveDate) {
                return;
              }
            }

            //if the meter change row started before the current meter assignment,
            //aka this happened before the space was on the meter
            //chop it to the start of the meter assignment
            if (childMeterWorkOrderHistory.effectiveDate < parentMeterChange.effectiveDate) {
              childMeterWorkOrderHistory.effectiveDate = parentMeterChange.effectiveDate;
            }

            //watch out for before system start
            if (childMeterWorkOrderHistory.effectiveDate < systemStartDate) {
              childMeterWorkOrderHistory.effectiveDate = systemStartDate;
            }

            //if the last status from this meter had the same effective date, remove it
            //handles multiple changes before space was on meter
            if (previousChild) {
              if (previousChild.effectiveDate.getTime() === childMeterWorkOrderHistory.effectiveDate.getTime()) {
                meterStatusChangeRows.pop();
              }
            }

            childMeterWorkOrderHistory.newValueName = childMeterWorkOrderHistory.newValueName + ',  '
              + datePipe.transform(childMeterWorkOrderHistory.effectiveDate, 'MM/dd/yy');

            meterStatusChangeRows.push(childMeterWorkOrderHistory);
            previousChild = childMeterWorkOrderHistory;
          });
      });

    meterStatusChangeRows = meterStatusChangeRows
      .sort((a, b) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate));

    return meterStatusChangeRows;
  }

  public createSpaceHistoryTimeline(workOrderHistories: WorkOrderHistory[], datePipe: DatePipe, isSpace: boolean, id: string, meterDownTimes?: MeterDowntime[], closures?: SpaceClosureByWarehouse[], space?: Space) {
    google.charts.load('current', { 'packages': ['timeline'] });
    google.charts.setOnLoadCallback(() => {
      drawChart(this.systemStartDate);
    });

    function drawChart(systemStartDate: Date) {
      const container = document.getElementById('timeline' + id);
      if (container) {
        const chart = new google.visualization.Timeline(container);
        const dataTable = new google.visualization.DataTable();

        dataTable.addColumn({ type: 'string', id: 'Row' });
        dataTable.addColumn({ type: 'string', id: 'Label' });
        dataTable.addColumn({ type: 'string', role: 'style' });
        dataTable.addColumn({ type: 'date', id: 'Start' });
        dataTable.addColumn({ type: 'date', id: 'End' });

        const red = '#EF5350';
        const purple = '#6200EA';
        const blue = '#0091EA';
        const yellow = '#FFEE58';
        const green = '#66BB6A';
        const gray = '#78909C';
        const blueGray = '#607d8b';
        const orange = '#E65100';

        const startDates = new Array<number>();

        const today = new Date();
        let startDate = new Date();

        const sixMonthsAhead = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());

        // Populate all effective dates and value names with dates
        // Placeholder for bad data changes any date before system start date to start date
        workOrderHistories.forEach(historyRecord => {
          if (historyRecord.isExecuted) {
            
            historyRecord.effectiveDate = InventoryTimelineComponent.localDateFromString(historyRecord.effectiveTimeStamp, systemStartDate);
            historyRecord.endDate = InventoryTimelineComponent.localDateFromString(historyRecord.endTimeStamp, systemStartDate);

            if (historyRecord.changeType === 'Expected Utilization Rate' && historyRecord.newValueName === '%') {
              historyRecord.newValueName = historyRecord.expectedUtilizationRate + '%';
            }
            historyRecord.newValueName = historyRecord.newValueName + ',  ' + datePipe.transform(historyRecord.effectiveDate, 'MM/dd/yy');

            if (isSpace) {
              if (!startDates.includes(historyRecord.effectiveDate.getTime()) && historyRecord.expectedUtilizationRate != null) {
                startDates.push(historyRecord.effectiveDate.getTime());
              }
              else if (!startDates.includes(historyRecord.effectiveDate.getTime()) && historyRecord.expectedUtilizationRate != null) {
                startDates.push(historyRecord.effectiveDate.getTime());
              }
            }
          }
          else if (space && space.currentStatus === SpaceStatusConstants.activePending) {
            historyRecord.newValueName = historyRecord.newValueName + ',  ' + datePipe.transform(today, 'MM/dd/yy');
            if (isSpace) {
                startDates.push(today.getTime());
            }
          }
        });

        if ((space && space.currentStatus !== SpaceStatusConstants.activePending) || space == null) {
          workOrderHistories = workOrderHistories.filter(history => {
            return history.isExecuted;
          });

          workOrderHistories = workOrderHistories.sort((a: WorkOrderHistory, b: WorkOrderHistory) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate));
        }

        // Populate Status Row
        const statusRows = workOrderHistories.filter(history => {
          return (history.changeType === InventoryTimelineConstants.StatusChange || history.changeType === InventoryTimelineConstants.MeterStatus);
        });

        if (statusRows.length > 0) {
          if (space && space.currentStatus === SpaceStatusConstants.activePending) {
            startDate = today;
          }
          else {
            startDate = statusRows[0].effectiveDate;
          }

          statusRows.forEach(historyRecord => {

            let color = '';
            if (historyRecord.newValueName.includes('Inactive')) {
              color = blueGray;
            }
            else if (historyRecord.newValueName.includes('Active')) {
              color = green;
            }
            else if (historyRecord.newValueName.includes('Removed')) {
              color = red;
            }
            else if (historyRecord.newValueName.includes('Pending')) {
              color = yellow;
            }
            else { //unknown status
              color = gray;
            }

            if (statusRows[statusRows.indexOf(historyRecord) + 1]) {
              dataTable.addRows([['Status', historyRecord.newValueName, color, historyRecord.effectiveDate, statusRows[statusRows.indexOf(historyRecord) + 1].effectiveDate]]);
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['Status', historyRecord.newValueName, color, today, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([['Status', historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
            }
          });
        }
        else {
          dataTable.addRows([
            ['Status', '', 'none', new Date(), new Date()]
          ]);
        }

        //Populate Space Meter Change Row
        const meterChangeRows = workOrderHistories.filter(history => {
          return history.changeType === InventoryTimelineConstants.MeterAssignment;
        });

        if (meterChangeRows.length > 0) {
          meterChangeRows.forEach(historyRecord => {

            let color = '';
            if (meterChangeRows.indexOf(historyRecord) % 2 === 0) {
              color = blue;
            }
            else {
              color = yellow;
            }

            if (meterChangeRows[meterChangeRows.indexOf(historyRecord) + 1]) {
              dataTable.addRows([['Meter', historyRecord.newValueName, color, historyRecord.effectiveDate, meterChangeRows[meterChangeRows.indexOf(historyRecord) + 1].effectiveDate]]);
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['Meter', historyRecord.newValueName, color, today, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([['Meter', historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
            }
          });

          let meterStatusChangeRows: WorkOrderHistory[] =
            InventoryTimelineComponent.filterMeterHistoriesForSpace(meterChangeRows, InventoryTimelineConstants.MeterStatus, systemStartDate, datePipe);
          
          meterStatusChangeRows.forEach(meterStatusChangeRow => {
            var endDate: Date;

            if (meterStatusChangeRows[meterStatusChangeRows.indexOf(meterStatusChangeRow) + 1]) {
              endDate = new Date(meterStatusChangeRows[meterStatusChangeRows.indexOf(meterStatusChangeRow) + 1].effectiveDate);              
            }
            else {
              endDate = sixMonthsAhead;
            }

            let color = '';
            if (meterStatusChangeRow.newValueName.includes('Inactive')) {
              color = blueGray;
            }
            else if (meterStatusChangeRow.newValueName.includes('Active')) {
              color = green;
            }
            else if (meterStatusChangeRow.newValueName.includes('Removed')) {
              color = red;
            }
            else if (meterStatusChangeRow.newValueName.includes('Pending')) {
              color = yellow;
            }
            else { //unknown meter status
              color = gray;
            }

            dataTable.addRows([['Meter Status', meterStatusChangeRow.newValueName, color, meterStatusChangeRow.effectiveDate, endDate]]);

          });

        }
        else if (isSpace) {
          dataTable.addRows([
            ['Meter', '', 'none', new Date(), new Date()]
          ]);
        }

        // Populate Contract Type Change Row
        let contractTypeRows = new Array<WorkOrderHistory>();
        if (isSpace) {
          contractTypeRows = InventoryTimelineComponent.filterMeterHistoriesForSpace(meterChangeRows, InventoryTimelineConstants.ContractType, systemStartDate, datePipe);
        }
        else {
          contractTypeRows = workOrderHistories.filter(history => {
            return history.changeType === InventoryTimelineConstants.ContractType;
          })
          .sort((a, b) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate));
        }

        if (contractTypeRows.length > 0) {
          contractTypeRows.forEach(historyRecord => {
            if (isSpace) {
              if (contractTypeRows.indexOf(historyRecord) === 0) {
                historyRecord.effectiveDate = startDate;
                historyRecord.newValueName = historyRecord.newValueName.slice(0, historyRecord.newValueName.indexOf(' ') + 1) + datePipe.transform(startDate, 'MM/dd/yy');
              }
            }
            
            let color = '';
            if (contractTypeRows.indexOf(historyRecord) % 2 === 0) {
              color = green;
            }
            else {
              color = yellow;
            }

            if (contractTypeRows[contractTypeRows.indexOf(historyRecord) + 1]) {
              if (contractTypeRows[contractTypeRows.indexOf(historyRecord) + 1].effectiveDate > startDate) {
                dataTable.addRows([['Contract', historyRecord.newValueName, color, historyRecord.effectiveDate, contractTypeRows[contractTypeRows.indexOf(historyRecord) + 1].effectiveDate]]);
              }

            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['Contract', historyRecord.newValueName, color, today, sixMonthsAhead]]);
              }
              else {
                if (historyRecord.effectiveDate < startDate) {
                  historyRecord.effectiveDate = startDate;
                  historyRecord.newValueName = historyRecord.newValueName.slice(0, historyRecord.newValueName.indexOf(' ') + 1) + datePipe.transform(startDate, 'MM/dd/yy');
                  dataTable.addRows([['Contract', historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
                }
                else {
                  dataTable.addRows([['Contract', historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
                }

              }
            }
          });
        }
        else {
          dataTable.addRows([
            ['Contract', '', 'none', new Date(), new Date()]
          ]);
        }

        // Populate Rate Package Change Row
        const ratePackageRows = workOrderHistories.filter(history => {
          return history.changeType === InventoryTimelineConstants.RatePackageReservedPower || history.changeType === InventoryTimelineConstants.RegularRateAdjustment
            || history.changeType === InventoryTimelineConstants.ReverseRatePackage;
        });

        if (ratePackageRows.length > 0) {
          ratePackageRows.forEach(historyRecord => {

            let color = '';
            if (ratePackageRows.indexOf(historyRecord) % 2 === 0) {
              color = gray;
            }
            else {
              color = purple;
            }

            if (ratePackageRows[ratePackageRows.indexOf(historyRecord) + 1]) {
              dataTable.addRows([['MRP', historyRecord.newValueName, color, historyRecord.effectiveDate, ratePackageRows[ratePackageRows.indexOf(historyRecord) + 1].effectiveDate]]);
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['MRP', historyRecord.newValueName, color, today, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([['MRP', historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
            }
          });
        }
        else if (isSpace) {
          dataTable.addRows([
            ['MRP', '', 'none', new Date(), new Date()]
          ]);
        }

        // reversible
        const reversibilityRows = workOrderHistories.filter(history => {
          return history.changeType === InventoryTimelineConstants.RatePackageReversibility;
        });
        let reversibilityRowsDisplayed = false;

        if (reversibilityRows.length > 0 && reversibilityRows.every(x => x.newValueName.includes(InventoryTimelineConstants.BlankReversibility))) {
          dataTable.addRows([
            [InventoryTimelineConstants.PendingRVA, '', 'none', new Date(), new Date()]
          ]);
          reversibilityRowsDisplayed = true;
        }
        else if (reversibilityRows.length > 0) {
          reversibilityRows.forEach(historyRecord => {
            if (historyRecord.newValueName.includes(InventoryTimelineConstants.BlankReversibility)) {
              return;
            }
            reversibilityRowsDisplayed = true;
            const nextRecord = reversibilityRows[reversibilityRows.indexOf(historyRecord) + 1];

            let color = '';
            if (reversibilityRows.indexOf(historyRecord) % 2 === 0) {
              color = gray;
            }
            else {
              color = purple;
            }

            if (nextRecord) {
              let endDate = nextRecord.effectiveDate;

              if (moment(historyRecord.effectiveDate).isBefore(new Date(new Date(historyRecord.effectiveDate).getFullYear(), 2, 1), 'days')
              ){
                var natEndDate = new Date(new Date(historyRecord.effectiveDate).getFullYear() + 1, 1, 28);

                if(moment(endDate).isAfter(natEndDate)){
                  endDate = natEndDate;
                }
              }
              else 
              {
                var natEndDate = new Date(new Date(historyRecord.effectiveDate).getFullYear() + 2, 1, 28);

                if(moment(endDate).isAfter(natEndDate)){
                  endDate = natEndDate;
                }
              }

              if(endDate > sixMonthsAhead) {
                dataTable.addRows([[InventoryTimelineConstants.PendingRVA, historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([[InventoryTimelineConstants.PendingRVA, historyRecord.newValueName, color, historyRecord.effectiveDate, endDate]]);
              }
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([[InventoryTimelineConstants.PendingRVA, historyRecord.newValueName, color, today, sixMonthsAhead]]);
              }
              else {
                let endDate = sixMonthsAhead;
                if (moment(historyRecord.effectiveDate).isBefore(new Date(new Date(historyRecord.effectiveDate).getFullYear(), 2, 1), 'days')) {
                  var natEndDate = new Date(new Date(historyRecord.effectiveDate).getFullYear() + 1, 1, 28);

                  if(moment(endDate).isAfter(natEndDate)){
                    endDate = natEndDate;
                  }
                }
                else 
                {
                  var natEndDate = new Date(new Date(historyRecord.effectiveDate).getFullYear() + 2, 1, 28);

                  if(moment(endDate).isAfter(natEndDate)){
                    endDate = natEndDate;
                  }
                }
                dataTable.addRows([[InventoryTimelineConstants.PendingRVA, historyRecord.newValueName, color, historyRecord.effectiveDate, endDate]]);
              }
            }
          });
        }
        else if (isSpace && !reversibilityRowsDisplayed) {
          dataTable.addRows([
            [InventoryTimelineConstants.PendingRVA, '', 'none', new Date(), new Date()]
          ]);
        }

        const eurRows = workOrderHistories.filter(history => {
          return history.changeType === InventoryTimelineConstants.EUR;
        })
        .sort((a, b) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate));

        let eurRowsDisplayed = false;

        if (eurRows.length > 0 && eurRows.every(x => x.newValueName.includes(InventoryTimelineConstants.BlankReversibility))) {
          dataTable.addRows([
            [InventoryTimelineConstants.EUR, '', 'none', new Date(), new Date()]
          ]);
          eurRowsDisplayed = true;
        }
        else if (eurRows.length > 0) {
          eurRows.forEach(historyRecord => {
            if (historyRecord.newValueName.includes(InventoryTimelineConstants.BlankReversibility)) {
              return;
            }

            let color = '';
            if (eurRows.indexOf(historyRecord) % 2 === 0) {
              color = gray;
            }
            else {
              color = purple;
            }

            eurRowsDisplayed = true;
            
            let endDate = historyRecord.endDate;
            
            if (endDate > sixMonthsAhead) {
              dataTable.addRows([[InventoryTimelineConstants.EUR, historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
            }
            else {
              dataTable.addRows([[InventoryTimelineConstants.EUR, historyRecord.newValueName, color, historyRecord.effectiveDate, endDate]]);
            }
            
          });
        }
        else if (isSpace && !eurRowsDisplayed) {
          dataTable.addRows([
            [InventoryTimelineConstants.EUR, '', 'none', new Date(), new Date()]
          ]);
        }

        // Populate Fine Level Change Row
        const fineLevelRows = workOrderHistories.filter(history => {
          return history.changeType === InventoryTimelineConstants.FineAmount;
        });

        if (fineLevelRows.length > 0) {
          fineLevelRows.forEach(historyRecord => {

            let color = '';
            if (fineLevelRows.indexOf(historyRecord) % 2 === 0) {
              color = gray;
            }
            else {
              color = purple;
            }

            if (fineLevelRows[fineLevelRows.indexOf(historyRecord) + 1]) {
              dataTable.addRows([['Fine Level', historyRecord.newValueName, color, historyRecord.effectiveDate, fineLevelRows[fineLevelRows.indexOf(historyRecord) + 1].effectiveDate]]);
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['Fine Level', historyRecord.newValueName, color, today, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([['Fine Level', historyRecord.newValueName, color, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
            }
          });
        }
        else if (isSpace) {
          dataTable.addRows([
            ['Fine Level', '', 'none', new Date(), new Date()]
          ]);
        }

        // Populate Required Closure Allowance Change Row
        let requiredClosureRows = new Array<WorkOrderHistory>();
        if (isSpace) {
          requiredClosureRows = InventoryTimelineComponent.filterMeterHistoriesForSpace(meterChangeRows, InventoryTimelineConstants.RequiredClosureAllowance, systemStartDate, datePipe);                    
        }
        else {
          requiredClosureRows = workOrderHistories.filter(history => {
            return history.changeType === InventoryTimelineConstants.RequiredClosureAllowance;
          })
          .sort((a, b) => InventoryTimelineComponent.sortHistories(a, b, systemStartDate));
        }

        if (requiredClosureRows.length > 0) {
          requiredClosureRows.forEach(historyRecord => {
            if (requiredClosureRows[requiredClosureRows.indexOf(historyRecord) + 1]) {
              dataTable.addRows([['RCA', historyRecord.newValueName, gray, historyRecord.effectiveDate, requiredClosureRows[requiredClosureRows.indexOf(historyRecord) + 1].effectiveDate]]);
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['RCA', historyRecord.newValueName, gray, today, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([['RCA', historyRecord.newValueName, gray, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
            }
          });
        }
        else {
          dataTable.addRows([
            ['RCA', '', 'none', new Date(), new Date()]
          ]);
        }

        // Populate Location Type Change Row
        const locationTypeRows = workOrderHistories.filter(history => {
          return history.changeType === InventoryTimelineConstants.LocationType;
        });

        if (locationTypeRows.length > 0) {
          locationTypeRows.forEach(historyRecord => {
            if (locationTypeRows[locationTypeRows.indexOf(historyRecord) + 1]) {
              dataTable.addRows([['Location', historyRecord.newValueName, gray, historyRecord.effectiveDate, locationTypeRows[locationTypeRows.indexOf(historyRecord) + 1].effectiveDate]]);
            }
            else {
              if (space && space.currentStatus === SpaceStatusConstants.activePending) {
                dataTable.addRows([['Location', historyRecord.newValueName, gray, today, sixMonthsAhead]]);
              }
              else {
                dataTable.addRows([['Location', historyRecord.newValueName, gray, historyRecord.effectiveDate, sixMonthsAhead]]);
              }
            }
          });
        }
        else if (!isSpace) {
          dataTable.addRows([
            ['Location', '', 'none', new Date(), new Date()]
          ]);
        }

        if (!isSpace) {
          const meterDowntimeMonths = [];
          meterDownTimes.forEach(dT => {

            const existingMonthRecords = meterDowntimeMonths.filter(t => {
              return t['startDateTime'].getUTCMonth() === dT.startDateTime.getUTCMonth() && t['startDateTime'].getUTCFullYear() === dT.startDateTime.getUTCFullYear();
            }).length === 0;

            if (existingMonthRecords) {
              const matchingCount = meterDownTimes.filter(downTime => {
                return downTime.startDateTime.getUTCMonth() === dT.startDateTime.getUTCMonth() && downTime.startDateTime.getUTCFullYear() === dT.startDateTime.getUTCFullYear();
              });

              let commaSeparatedList = '';
              matchingCount.forEach(mC => {
                commaSeparatedList = matchingCount.indexOf(mC) === matchingCount.length - 1 ? commaSeparatedList + mC.inoperableId : commaSeparatedList + mC.inoperableId + ', ';
              });

              meterDowntimeMonths.push({ startDateTime: dT.startDateTime, listOfIds: commaSeparatedList });
            }
          });

          if (meterDowntimeMonths.length > 0) {
            meterDowntimeMonths.forEach(downtime => {
              dataTable.addRows([['Red Alarm', downtime.listOfIds, red, new Date(downtime.startDateTime.getUTCFullYear(), downtime.startDateTime.getUTCMonth(), 1), new Date(downtime.startDateTime.getUTCFullYear(), downtime.startDateTime.getUTCMonth() + 1, 1)]]);
            });
          }
          else if (!isSpace) {
            dataTable.addRows([
              ['Red Alarm', '', 'none', new Date(), new Date()]
            ]);
          }

          if (closures.length > 0) {
            console.log(closures);
            closures.forEach(closure => {

              const start = new Date(closure.earliestClosureDate.getUTCFullYear(), closure.earliestClosureDate.getUTCMonth(), 1);
              const end = moment(new Date(closure.latestClosureDate.getUTCFullYear(), closure.latestClosureDate.getUTCMonth(), closure.latestClosureDate.getUTCDate()));
              const endOfMonth = end.endOf('month').toDate();

              dataTable.addRows([['Closures', closure.closureWarehouseId, orange, start, endOfMonth]]);
            });
          }
          else if (!isSpace) {
            dataTable.addRows([
              ['Closures', '', 'none', new Date(), new Date()]
            ]);
          }
        }
        else {
          // Populate CLZ Impacts Row
          const clzImpactRows = workOrderHistories.filter(history => {
            return history.changeType === InventoryTimelineConstants.CLZ;
          });

          if (clzImpactRows.length > 0) {
            clzImpactRows.forEach(impact => {

              let color = impact.newValueName.includes('Reversal') ? yellow : orange;
              dataTable.addRows([['CLZ Change', impact.newValueName, color, new Date(impact.effectiveDate.getUTCFullYear(), impact.effectiveDate.getUTCMonth(), 1), new Date(impact.effectiveDate.getUTCFullYear(), impact.effectiveDate.getUTCMonth() + 1, 1)]]);
            });
          }
          else {
            dataTable.addRows([
              ['CLZ Change', '', 'none', new Date(), new Date()]
            ]);
          }
        }

        const options = {
          height: 400,
          tooltip: {
            isHtml: true
          },
          timeline: {
            groupByRowLabel: true,
            rowLabelStyle: { fontName: 'Trebuchet MS', fontSize: 10 },
            barLabelStyle: { fontName: 'Trebuchet MS', fontSize: 10 },
            displayLegendValues: true,
            hAxis: {
              minValue: systemStartDate,
              maxValue: sixMonthsAhead,
            },
          },
          avoidOverlappingGridLines: false
        };
        chart.draw(dataTable, options);

        // Hide empty rows
        const allRows = document.getElementsByTagName('rect');
        let width = 5;
        let rowsToRemove = [];
        for (let i = 0; i < allRows.length; i++) {
          const cwidth = parseInt(allRows[i].getAttribute('width'));
          if (cwidth < width) {
            rowsToRemove = [allRows[i]];
            width = cwidth;
          }
          else if (cwidth === width) {
            rowsToRemove.push(allRows[i]);
          }
        }
        for (let i = 0; i < rowsToRemove.length; i++) {
          rowsToRemove[i].setAttribute('fill', 'none');
        }
      }
    }
  }
}

