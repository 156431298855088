import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { transform } from 'typescript';
import { ManualCollectionsService } from '../services/manual-collections-service';
import { bool } from 'aws-sdk/clients/signer';

@Component({
  selector: 'app-previous-collection',
  templateUrl: './previous-collection.component.html',
  styleUrls: ['./previous-collection.component.css']
})
export class PreviousCollectionComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns = ['meterId', 'spaceId', 'fullyUtilizedAmount', 'amountDistributed'];
  dataSource: MatTableDataSource<any>;
  currentId: string = null;
  collectionsLoading: bool = false;
  moreCollectionsExist: bool = true;
  totalItems: number = 0;
  serverPageIndex = 0;

  constructor(private _manualCollectionsService: ManualCollectionsService, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.currentId = this.data.collectionId;
    this.loadInitialCollections();
  }

  loadInitialCollections() {
    this.collectionsLoading = true;
    this._manualCollectionsService.getManualCollection(this.currentId, this.serverPageIndex).subscribe(result => {
      this.collectionsLoading = false;
      var transformed = this.transformDistributionForDisplay(result.manualMeterCollections);
      this.dataSource = new MatTableDataSource(transformed);
      this.dataSource.paginator = this.paginator;
      this.totalItems = this.dataSource.data.length;
      this.serverPageIndex = this.serverPageIndex + 1; //only change this when we actually hit the server

      if (result.manualMeterCollections.length < 1) {
        this.moreCollectionsExist = false;
      }
    },
    error => {
      this.collectionsLoading = false;
      this.moreCollectionsExist = false;
    });
  }

  loadMoreCollections(event: PageEvent) {
    //only add more if we need more
    if (this.dataSource.data.length >= (event.pageIndex + 1) * event.pageSize) {
      return;
    }

    //have to be careful about how we load, since count is meters, but rows are spaces
    this.collectionsLoading = true;
    this._manualCollectionsService.getManualCollection(this.currentId, this.serverPageIndex).subscribe(result => {
      this.collectionsLoading = false;
      var transformed = this.transformDistributionForDisplay(result.manualMeterCollections);
      //add the new data onto the end
      this.dataSource.data = this.dataSource.data.concat(transformed);
      this.totalItems = this.dataSource.data.length;
      this.serverPageIndex = this.serverPageIndex + 1; //only change this when we actually hit the server

      if (result.manualMeterCollections.length < 1) {
        this.moreCollectionsExist = false;
      }
    },
    error => {
      this.collectionsLoading = false;
      this.moreCollectionsExist = false;
    });
  }

  transformDistributionForDisplay(meterCollections: any[]): any[] {
    const distributionArray = [];
    meterCollections.forEach(meterDistribution => {
      distributionArray.push({ meterDisplayId: meterDistribution.meterDisplayId, fullyUtilizedAmount: meterDistribution.fullyUtilizedAmount, amountDistributed: meterDistribution.amountDistributed });

      meterDistribution.manualSpaceCollections.forEach(spaceDistribution => {
        distributionArray.push({ spaceDisplayId: spaceDistribution.spaceDisplayId, fullyUtilizedAmount: spaceDistribution.fullyUtilizedAmount, amountDistributed: spaceDistribution.amountDistributed });
      });
    });
    return distributionArray;
  }

}
