exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-row {\r\n    min-height:35px !important;\r\n    height:35px !important;\r\n}\r\n\r\n.add {\r\n    position:absolute;\r\n    top:50px;\r\n    right:50px;\r\n    z-index: 2;\r\n}\r\n\r\n.pad-title {\r\n    padding: 15px;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();