import { FineAmountService } from './fine-amounts/fine-amounts.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InventoryTableModule } from './inventory-table/inventory-table.module';
import { ZoneService } from './zones/zones.service';
import { AreaService } from './areas/areas.service';
import { CoreModule } from '../core/core.module';
import { InventoryManagementComponent } from './inventory-management.component';
import { MetersModule } from './meters/meters.module';
import { RatePackagesModule } from './rate-packages/rate-packages.module';
import { SpacesModule } from './spaces/spaces.module';
import { BlocksModule } from './blocks/blocks.module';
import { LoadingZoneImpactService } from './loading-zone-impact/loading-zone-impact.service';
import { LoadingZoneImpactComponent } from './loading-zone-impact/loading-zone-impact.component';
import { SpaceClosureDetailComponent } from './spaces/space-closures/space-closure-detail.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MetersModule,
    RatePackagesModule,
    SpacesModule,
    BlocksModule,
    InventoryTableModule
  ],
  declarations: [
    InventoryManagementComponent,
    LoadingZoneImpactComponent,
    SpaceClosureDetailComponent
  ],
  entryComponents: [
    InventoryManagementComponent,
    LoadingZoneImpactComponent,
    SpaceClosureDetailComponent
  ],
  exports: [
    InventoryManagementComponent,
    LoadingZoneImpactComponent,
    SpaceClosureDetailComponent
  ],
  providers: [
    AreaService,
    FineAmountService,
    LoadingZoneImpactService,
    ZoneService
  ]
})
export class InventoryManagementModule { }
