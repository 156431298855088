import { EditedCollectionsService } from './../services/edited-collections-service';
import { EditedCollection } from './../models/edited-collection';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MeterCollection } from './../models/meter-collection';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MeterCollectionsService } from '../services/meter-collections-service';

@Component({
  selector: 'app-edit-collection-form',
  templateUrl: './edit-collection-form.component.html',
  styleUrls: ['./edit-collection-form.component.css']
})
export class EditCollectionFormComponent  implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns = ['meterId', 'spaceId', 'fullyUtilizedAmount', 'amountDistributed'];
  collectionGuid: string;
  meterCollection: MeterCollection;
  editCollectionForm: FormGroup;
  collectionNotFound: boolean;
  loading: boolean;

  constructor(public dialogRef: MatDialogRef<any>, private formBuilder: FormBuilder,
    private _meterCollectionsService: MeterCollectionsService,
    private _editedCollectionsService: EditedCollectionsService,
    private snackbar: MatSnackBar) {
    this.editCollectionForm = this.formBuilder.group({
      overrideDate: void 0,
      overrideAmount: void 0,
      comments: void 0
    });
  }

  ngOnInit() {
    this.meterCollection = new MeterCollection();
    this.editCollectionForm.disable();
  }

  search() {
    this.loading = true;
    this._meterCollectionsService.getMeterCollectionById(this.collectionGuid)
      .subscribe(result => {
        this.collectionNotFound = false;
        this.loading = false;
        this.meterCollection = new MeterCollection();
        this.meterCollection.amount = result.amount;
        this.meterCollection.meterDisplayId = result.meterDisplayId;
        this.meterCollection.collectionDate = result.collectionDate;
        this.editCollectionForm.enable();
      }, error => {
        this.collectionNotFound = true;
        this.loading = false;
        this.snackbar.open(error, 'X');
      });
  }

  submit() {
    this.snackbar.open('Submitting edited collection...', '');

    const editedCollection = new EditedCollection();
    editedCollection.newCollectionDate = this.editCollectionForm.get('overrideDate').value;
    editedCollection.newCollectionAmount = this.editCollectionForm.get('overrideAmount').value;
    editedCollection.comments = this.editCollectionForm.get('comments').value;
    editedCollection.collectionId = this.collectionGuid.toUpperCase();
    editedCollection.meterId = this.meterCollection.meterDisplayId;
    editedCollection.oldCollectionAmount = this.meterCollection.amount;
    editedCollection.oldCollectionDate = this.meterCollection.collectionDate;
    editedCollection.editedDate = new Date();

    this._editedCollectionsService.postEditedCollections(editedCollection)
      .subscribe(result => {
        this.dialogRef.close(result);
      }, error => {
        this.snackbar.open('Could not find Guid in Data Warehouse', 'X');
      });
  }

  enableSubmit(): boolean {
    return (this.editCollectionForm.get('overrideDate').value || this.editCollectionForm.get('overrideAmount').value)
      && this.editCollectionForm.get('comments').value;
  }
}
