import { StringExtensions } from './../../string-extensions';
import { Injectable, ErrorHandler } from '@angular/core';

import { QueryStringBuilder } from '../../core/query-strings/query-string-builder';
import { Observable } from 'rxjs';
import { MeterTrueUpDetail } from './meter-true-up-detail';
import { MonthlyTrueUpCalculationServiceBase } from '../monthly-calculations/monthly-true-up-calculation-service-base';
import { MonthlyTrueUpCalculationFilterRepresentation } from '../monthly-calculations/monthly-true-up-calculation-filter-representation-base';
import { MeterTrueUpDetailJsonConverter } from './meter-true-up-detail-json-converter';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { RatePackageService } from '../../inventory-management/rate-packages/services/rate-package.service';
import { CachedRatePackages } from '../cached-rate-package';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';

@Injectable()
export class MeterTrueUpDetailService extends MonthlyTrueUpCalculationServiceBase<MeterTrueUpDetail>  {

  cachedRatePackages: CachedRatePackages[] = [];


  getAllItems(orderByColumn: string, isAscending: boolean, skip: number, top: number, filter: MonthlyTrueUpCalculationFilterRepresentation): Observable<PaginatedGetResult<MeterTrueUpDetail>> {
    return super.getAll(orderByColumn, isAscending, skip, top, filter);
  }

  constructor(trueUpDataHttpService: ReportingHttpService, errorHandler: ErrorHandler,
    trueUpDetailJsonConverter: MeterTrueUpDetailJsonConverter, private ratePackageService: RatePackageService,
    meterFileDownloadService: FileDownloadService) {
      super(trueUpDataHttpService, errorHandler, trueUpDetailJsonConverter, meterFileDownloadService);
  }

  GetEndpoint(): string {
    return '/MeterMonthlyTrueUpCalculations';
  }

  GetReportType(): string {
    return 'METER_TRUEUP';
  }

  AddNodeSpecificQueryStringParts(queryString: string, filter: MonthlyTrueUpCalculationFilterRepresentation): string {
    const meterFilter = filter as MonthlyTrueUpCalculationFilterRepresentation;
    if (meterFilter.ShouldFilterByMeterId) {
      queryString = QueryStringBuilder.AddBasedOnFilterOperator(queryString, 'meterId', meterFilter.MeterIdFilterOperator, meterFilter.MeterId, null);
    }
    return QueryStringBuilder.PrependFilterQueryStringVariableName(queryString);
  }
}
