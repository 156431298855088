import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuditReportService } from '../services/audit-report.service';

@Component({
  selector: 'app-audit-reports',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit, OnDestroy {

  spaceStartDate: Date;
  spaceEndDate: Date;
  spaceReportLoading: boolean;

  meterStartDate: Date;
  meterEndDate: Date;
  meterReportLoading: boolean;

  subscriptions: Subscription[] = [];

  constructor(private snackBar: MatSnackBar,
    private reportService: AuditReportService,
    private errorHandler: ErrorHandler) { }

  ngOnInit() {
    this.subscriptions.push(this.reportService.isLoading
      .subscribe(response => {
        if (response.timeout) {
          this.snackBar.open('The report you are requesting is too large. Please filter your results further.', '', <MatSnackBarConfig>{
            duration: 2000,
          });
        }
        if (response.reportType == AuditReportService.METER_AUDIT_KEY) {
          this.meterReportLoading = response.loading;
        }
        if (response.reportType == AuditReportService.SPACE_AUDIT_KEY) {
          this.spaceReportLoading = response.loading;
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  exportSpaces() {
    if (!this.spaceStartDate || !this.spaceEndDate) {
      this.snackBar.open('Please enter Start Date and End Date for Entry Date filter', '', <MatSnackBarConfig>{
        duration: 2000,
      });
      return;
    }

    this.spaceReportLoading = true;

    this.reportService.RequestSpaceReport(this.spaceStartDate, this.spaceEndDate).subscribe(
      result => {
        this.reportService.DownloadSpaceReport(result.result);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  exportMeters() {
    if (!this.meterStartDate || !this.meterEndDate) {
      this.snackBar.open('Please enter Start Date and End Date for Entry Date filter', '', <MatSnackBarConfig>{
        duration: 2000,
      });
      return;
    }

    this.meterReportLoading = true;

    this.reportService.RequestMeterReport(this.meterStartDate, this.meterEndDate).subscribe(
      result => {
        this.reportService.DownloadMeterReport(result.result);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  clearSpaceStartDate() {
    this.spaceStartDate = undefined;
  }

  clearSpaceEndDate() {
    this.spaceEndDate = undefined;
  }

  clearMeterStartDate() {
    this.meterStartDate = undefined;
  }

  clearMeterEndDate() {
    this.meterEndDate = undefined;
  }

}
