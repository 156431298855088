export class RatePackageReversibilityModel{
    public spaceId :string;
    public spaceDisplayId :string;
    public meterId :string;
    public meterDisplayId :string;
    public previousRatePackageId :string;
    public previousRatePackageDisplayId :string;
    public newRatePackageId :string;
    public newRatePackageDisplayId :string;
    public expectedUtilizationRateBeforeReversal :number;
    public measuredUtilizationRateBeforeRatePackageChange :number;
    public effectiveDate :string;
    public workOrderActionId :string;
    public reversedWorkOrderActionId :string;
    public isReversible:boolean;
}
