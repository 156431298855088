import { DailyKeyValuePair } from "./daily-key-value-for-schedule-blocks";

export class HoursOfBase {

  description: string;

  id: string;

  isActive: boolean;

  name: string;

  weeklyDetail: DailyKeyValuePair[];

}
