import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter, ErrorHandler } from '@angular/core';

import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaginationParameters } from '../../core/models/pagination-paramters';
import { MonthlyTrueUpCalculationDetailBase } from './monthly-true-up-calculation-detail-base';

export class MonthlyTrueUpCalculationDataSourceBase<T extends MonthlyTrueUpCalculationDetailBase> extends DataSource<T> {

    constructor(private calculationObservable:Observable<Array<T>>) {
        super();
    }

    public connect(): Observable<Array<T>> {
      return this.calculationObservable;
    }
    public disconnect() { }
}