import { MeterCollection } from './../models/meter-collection';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrueUpHttpService } from './../../../true-up-http.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class MeterCollectionsService {
  constructor(
    private trueUpDataHttpService: TrueUpHttpService,
    protected errorHandler: ErrorHandler
  ) {}

  public getMeterCollectionById(id: string): Observable<MeterCollection> {
    return this.trueUpDataHttpService
      .get('/meterCollections/' + id, null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    this.errorHandler.handleError(error);
    return Promise.reject(error.message || error);
  }
}
