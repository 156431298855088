import { StringExtensions } from '../../string-extensions';

export class FilterOperator {
    static Equal = '=';
    static GreaterThan = '>';
    static LessThan = '<';
    static NotEqual = '<>';
    static GreaterThanOrEqual = '>=';
    static LessThanOrEqual = '<=';
    static Between = 'Between';
    static Contains = 'Contains';
    static StartsWith = 'Starts with';
    static EndsWith = 'Ends with';
}

 export class QueryStringBuilder {
  private static EqualsOperator = 'eq';
  private static NotEqualsOperator = 'ne';
  private static GreaterThanOrEqualToOperator = 'ge';
  private static GreaterThanOperator = 'gt';
  private static LessThanOrEqualToOperator = 'le';
  private static LessThanOperator = 'lt';


  public static AddGuidEquals(filterQueryString: string, propertyName: string, value: string|number): string {
    return this.AddGuidOperation(filterQueryString, propertyName, value, this.EqualsOperator);
  }

  public static AddEquals(filterQueryString: string, propertyName: string, value: string|number): string {
    return this.AddOperation(filterQueryString, propertyName, value, this.EqualsOperator);
  }

  public static AddEqualsMulti(filterQueryString: string, propertyName: string, value: Array<string>): string {
    return this.AddOperationMulti(filterQueryString, propertyName, value, this.EqualsOperator);
  }

  public static AddGreaterThan(filterQueryString: string, propertyName: string, value: string|number): string {
    return this.AddOperation(filterQueryString, propertyName, value, this.GreaterThanOperator);
  }

  public static AddGreaterThanOrEqual(filterQueryString: string, propertyName: string, value: string|number): string {
    return this.AddOperation(filterQueryString, propertyName, value, this.GreaterThanOrEqualToOperator);
  }

  public static AddLessThan(filterQueryString: string, propertyName: string, value: string|number): string {
    return this.AddOperation(filterQueryString, propertyName, value, this.LessThanOperator);
  }

  public static AddLessThanOrEqual(filterQueryString: string, propertyName: string, value: string|number): string {
    return this.AddOperation(filterQueryString, propertyName, value, this.LessThanOrEqualToOperator);
  }

  public static AddNotEqual(filterQueryString: string, propertyName: string, value: string|number) {
    return this.AddOperation(filterQueryString, propertyName, value, this.NotEqualsOperator);
  }

  private static AddOperation(filterQueryString: string, propertyName: string, value: string|number, operator: string): string {
    filterQueryString = this.AddAnd(filterQueryString);
    filterQueryString += `${encodeURIComponent(propertyName)} ${operator} '${encodeURIComponent(value.toString())}'`;
    return filterQueryString;
  }

  private static AddOperationMulti(filterQueryString: string, propertyName: string, value: Array<string>, operator: string): string {
    filterQueryString = this.AddAnd(filterQueryString);

    if(value.length === 0){
      return filterQueryString;
    }

    let concatValue = value[0];

    //no comma if there is only one value
    for(let i = 1; i < value.length; i++){
      concatValue = `${concatValue},${value[i]}`
    }

    filterQueryString += `${encodeURIComponent(propertyName)} ${operator} '${encodeURIComponent(value.toString())}'`;
    return filterQueryString;
  }

  private static AddGuidOperation(filterQueryString: string, propertyName: string, value: string|number, operator: string): string {
    filterQueryString = this.AddAnd(filterQueryString);
    filterQueryString += `${encodeURIComponent(propertyName)} ${operator} (guid'${value}')`;
    return filterQueryString;
  }

  public static AddSubstringOf(filterQueryString: string, propertyName: string, value: string|number): string {
    filterQueryString = this.AddAnd(filterQueryString);
    filterQueryString += `substringOf(${encodeURIComponent(propertyName)}, '${value}')`;
    return filterQueryString;
  }

  public static AddStartsWith(filterQueryString: string, propertyName: string, value: string|number): string {
    filterQueryString = this.AddAnd(filterQueryString);
    filterQueryString += `startsWith(${encodeURIComponent(propertyName)}, '${value}')`;
    return filterQueryString;
  }

  public static AddEndsWith(filterQueryString: string, propertyName: string, value: string|number): string {
    filterQueryString = this.AddAnd(filterQueryString);
    filterQueryString += `endsWith(${encodeURIComponent(propertyName)}, '${value}')`;
    return filterQueryString;
  }

  private static AddAnd(target: string): string {
      if (StringExtensions.StringIsNotEmpty(target)) {
          target += ' and ';
      }
      return target;
  }

  public static AddOrderBy(filterQueryString: string, columnName: string, isAscending: boolean): string {
      if (StringExtensions.StringIsNotEmpty(filterQueryString)) {
        filterQueryString += '&';
      }

     const order = isAscending ? 'asc' : 'desc';
     filterQueryString += `orderby=${encodeURIComponent(columnName)} ${order}`;

     return filterQueryString;
   }
   public static AddSkip(filterQueryString: string, skip: number): string {
     if (StringExtensions.StringIsNotEmpty(filterQueryString)) {
       filterQueryString += '&';
     }
     return filterQueryString += `skip=${skip}`;
   }

   public static AddTop(filterQueryString: string, top: number): string {
     if (StringExtensions.StringIsNotEmpty(filterQueryString)) {
       filterQueryString += '&';
     }
     return filterQueryString += `top=${top}`;
   }

  public static AddBasedOnFilterOperator(filterQueryString: string, filterName: string,  filterOperator: string, filter: string | number, endFilter?: string | number): string {
    if (filterOperator === FilterOperator.Equal) {
      return this.AddEquals(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.GreaterThan) {
      return this.AddGreaterThan(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.LessThan) {
      return this.AddLessThan(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.NotEqual) {
      return this.AddNotEqual(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.GreaterThanOrEqual) {
      return this.AddGreaterThanOrEqual(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.LessThanOrEqual) {
      return this.AddLessThanOrEqual(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.Between) {
      filterQueryString =  this.AddGreaterThanOrEqual(filterQueryString, filterName , `${filter}`);
      return this.AddLessThanOrEqual(filterQueryString, filterName , `${endFilter}`);
    } else if (filterOperator === FilterOperator.Contains) {
      return this.AddSubstringOf(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.StartsWith) {
      return this.AddStartsWith(filterQueryString, filterName , `${filter}`);
    } else if (filterOperator === FilterOperator.EndsWith) {
      return this.AddEndsWith(filterQueryString, filterName , `${filter}`);
    }
  }

  public static PrependFilterQueryStringVariableName(filterQueryString: string): string {
    if (filterQueryString.startsWith('filter=')) {
      return filterQueryString;
    }

    return `filter=${encodeURIComponent(filterQueryString)}`;
  }
 }
