import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { WorkOrderReportService } from '../services/work-order-report.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-work-orders-reports',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.css']
})
export class WorkOrdersReportComponent implements OnInit, OnDestroy {

  startDate: string;
  endDate: string;
  reportLoading: boolean;
  subscriptions: Subscription[] = [];

  constructor(private snackBar: MatSnackBar,
    private reportService: WorkOrderReportService,
  private errorHandler: ErrorHandler) { }

  ngOnInit() {
    this.subscriptions.push(this.reportService.isLoading
      .subscribe(response => {
        if (response.timeout) {
          this.snackBar.open('The report you are requesting is too large. Please filter your results further.', '', <MatSnackBarConfig>{
            duration: 2000,
          });
        }
        if (response.reportType == WorkOrderReportService.WORK_ORDER_KEY) {
          this.reportLoading = response.loading;
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  export() {
    if(!this.startDate || !this.endDate) {
      this.snackBar.open('Please enter Start Date and End Date for Completion Date filter', '', <MatSnackBarConfig>{
        duration: 2000,
      });
      return;
    }

    this.reportLoading = true;

    this.reportService.RequestWorkOrderReport(this.startDate, this.endDate).subscribe(
      result => {
        this.reportService.DownloadWorkOrderReport(result.result);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  clearStartDate() {
    this.startDate = undefined;
  }

  clearEndDate() {
    this.endDate = undefined;
  }
}
