import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { InventoryChangesService } from '../../services/inventory-changes-service';
import { HistoricalChangeToInventoryConfirm } from '../../models/historical-change-to-inventory-confirm';
import {TypeOfActionToApproveConstants, WorkOrderResponse} from '../../../models/approval-models';
import {WorkOrderComment} from '../../../models/work-order-comment';
import {WorkOrderResponseService} from '../../../services/work-order-response.service';
import { CalculationsCompleteService } from '../../../../trueup/calculations-complete-service/calculations-complete-service';
import { TokenCache } from '../../../../security/token-cache';

@Component({
  selector: 'app-inventory-changes-apply-historical-changes-confirmation-dialog',
  templateUrl: './inventory-changes-apply-historical-changes-confirmation.component.html',
  styleUrls: ['./inventory-changes-apply-historical-changes-confirmation.component.css']
})
export class InventoryChangesApplyHistoricalChangesConfirmation {
  constructor(
    public dialogRef: MatDialogRef<InventoryChangesApplyHistoricalChangesConfirmation>,
    public snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private workOrderResponseService: WorkOrderResponseService,
    private calculationsCompleteService: CalculationsCompleteService,
    private inventoryChangesService: InventoryChangesService,
    private tokenCache: TokenCache
  ) {}

  effectiveDate: Date;
  calculationIsComplete = false;
  reason: string;

  effectiveDateChanged() {
    if (this.tokenCache.checkForLAZUser()) {
      this.calculationsCompleteService
        .isCalculationApprovedForYearAndMonth(
          this.effectiveDate.getFullYear(),
          this.effectiveDate.getMonth() + 1
        )
        .subscribe(approvedResult => {
          if (approvedResult.result === true) {
            this.calculationsCompleteService
              .getLastFinalizedQuarter(
                this.effectiveDate.getFullYear(),
                this.effectiveDate.getMonth() + 1
              )
              .subscribe(lastQuarterResult => {
                this.calculationIsComplete = !lastQuarterResult.result;
              });
          } else {
            this.calculationIsComplete = false;
          }
        });
    } else {
      this.calculationIsComplete = false;
    }
  }

  submit() {
    this.snackbar.open('Setting new effective date...', '');
    const historicalChangeToInventoryConfirmModel = new HistoricalChangeToInventoryConfirm();
    historicalChangeToInventoryConfirmModel.WorkOrderId = this.data.workOrderId;
    historicalChangeToInventoryConfirmModel.HistoricalEffectiveDate = this.effectiveDate;

    const response = new WorkOrderResponse();
    response.workOrderId = this.data.workOrderId;
    response.responseType = 'Approve';
    response.typeOfActionToApprove = TypeOfActionToApproveConstants.historicalChange;
    response.comment = new WorkOrderComment();
    response.comment.text =
      'Requesting historical change with effective date of: ' +
      this.effectiveDate +
      ' because of the following reason: ' +
      this.reason;
    response.historicalEffectiveDate = this.effectiveDate;
    this.workOrderResponseService.submitWorkOrderResponse(response.workOrderId, response).subscribe(
      workOrderResponse => {
        if(this.data.approveHistoricalChange) {
          this.inventoryChangesService.approveHistoricalChange(response).subscribe(approveHistoricalChangeResponse => {
            this.snackbar.dismiss();
            this.dialogRef.close(this.effectiveDate);
          },
        error => {
          console.log(error);
        });
        }
        else {
          this.snackbar.dismiss();
            this.dialogRef.close(this.effectiveDate);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  disableConfirmButton(): boolean {
    return this.calculationIsComplete || !this.effectiveDate || !this.reason;
  }
}
