exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".full-width {\r\n  width:100%\r\n}\r\n\r\n.flex-container {\r\n  font-size: 8pt!important;\r\n}\r\n\r\n.mat-dialog-container {\r\n  width:auto;\r\n  max-width:1350px !important;\r\n}\r\n\r\n.cdk-overlay-pane {\r\n  width:auto;\r\n  max-width:1350px !important;\r\n}\r\n\r\n.mat-row:hover, .selected-row {\r\n  background-color: #CFD8DC;\r\n  cursor: pointer;\r\n}\r\n\r\n.mat-paginator {\r\n  min-height:30px !important;\r\n  height:30px !important;\r\n}\r\n\r\n.warning {\r\n  font-style: italic;\r\n  color: red;\r\n}\r\n\r\n.meter-error {\r\n  padding-top:5px;\r\n  padding-bottom:10px;\r\n}\r\n\r\n.rate-package-options {\r\n  font-style: italic;\r\n  font-size: 75%;\r\n}\r\n\r\n.mat-row {\r\n  height:25px !important;\r\n  min-height:25px !important;\r\n  max-height:25px !important;\r\n}\r\n\r\n.mat-card-title {\r\n  margin-bottom: 0px;\r\n}\r\n.readonly-field{\r\n  color: rgba(0,0,0,.38)\r\n}\r\n\r\n.select-rp-button {\r\n  min-width: auto;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();