import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {Component, Input, Output, EventEmitter, OnChanges, OnInit} from '@angular/core';

import { ActionConstants } from './../../action/action-constants';
import { BlockInventoryChange } from './../models/block-inventory-change';
import { SpaceTypeConstants } from './../../../inventory-management/meters/meter-constants';
import { BlockService } from './../../../inventory-management/blocks/blocks.service';
import { MeterService } from './../../../inventory-management/meters/meters.service';
import { ZoneService } from './../../../inventory-management/zones/zones.service';
import { AreaService } from './../../../inventory-management/areas/areas.service';
import { Zone } from './../../../inventory-management/zones/zone';
import { Area } from './../../../inventory-management/areas/area';
import { Block } from './../../../inventory-management/blocks/block';
import { InventoryFilterRepresentation } from '../../../inventory-management/inventory-filter/inventory-filter-representation';
import { DateTimeUtility } from '../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { SpaceType } from '../../../inventory-management/meters/meter';
import { WorkOrderAction } from '../../models/work-order-action';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-work-order-block-inventory',
  templateUrl: './work-order-block-inventory.component.html',
  styleUrls: ['./work-order-block-inventory.component.css']
})
export class WorkOrderBlockInventoryComponent implements OnChanges, OnInit {

  @Input() meterId: string;
  @Input() blockId: string;
  @Input() block: Block;
  @Input() blockInventoryChange: BlockInventoryChange;
  @Input() actionId: number;
  @Input() workOrderAction: WorkOrderAction;
  showUpdateBlockRange: boolean;

  @Output()
  errorsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output()
  blockInventoryChangeEmitter: EventEmitter<BlockInventoryChange> = new EventEmitter<BlockInventoryChange>(true);

  areaOptions: Array < Area > ;
  zoneOptions: Array < Zone > ;
  zoneOption: any;
  areaOption: any;
  streetDirections = [{ title: 'North', value: 'N' }, { title: 'South', value: 'S' }, { title: 'East', value: 'E' }, { title: 'West', value: 'W' }];
  sideOfStreet: string;
  spaceTypes = [SpaceTypeConstants.Parallel, SpaceTypeConstants.DiagonalStriped, SpaceTypeConstants.DiagonalUnstriped, SpaceTypeConstants.PerpendicularStriped, SpaceTypeConstants.PerpendicularUnstriped];
  multiSelectCheckboxArray: SpaceType[] = [];
  isNewBlock: boolean;
  isHistoricalChange: boolean;
  displayBlockIdError = false;
  inventoryChangeEffectiveDate?: Date;
  displayBlockRangeOnly: boolean;
  selectedZone: Zone;
  selectedArea: Area;
  blockInventoryChangeFormGroup = new FormGroup({});

  blockInventoryChangeFields = ['zoneDisplayId', 'zoneId', 'blockDisplayId', 'areaId',  'areaDisplayId', 'ward', 'blockStart', 'blockEnd', 'streetDirection', 'sideOfStreet', 'streetName', 'streetSuffix', 'spaceTypes', 'description'];

  constructor(private areaService: AreaService, private zoneService: ZoneService, public meterService: MeterService, public blockService: BlockService, public dialog: MatDialog) {}

  ngOnInit() {
    if (!this.blockInventoryChange) {
      this.blockInventoryChange = new BlockInventoryChange();
      this.isNewBlock = true;
      this.getAllZones();
    }
    else {
    this.multiSelectCheckboxArray = this.blockInventoryChange.spaceTypes;
    }

    this.createBlockInventoryForm();
  }

  ngOnChanges() {
    this.displayBlockRangeOnly = this.blockInventoryChange.workOrderActionType === 'UpdateBlockRange';
    this.isNewBlock = !this.displayBlockRangeOnly;
    if (!this.displayBlockRangeOnly) {
      this.getAllZones();
    }
    if (this.blockInventoryChange.effectiveDate && this.blockInventoryChange.effectiveDate.toString() !== DateTimeUtility.MINDATE) {
       this.inventoryChangeEffectiveDate = this.blockInventoryChange.effectiveDate;
    }
    this.multiSelectCheckboxArray = this.blockInventoryChange.spaceTypes;
  }

  createBlockInventoryForm() {

    var configurBlockField = (field: string) => {
      this.blockInventoryChangeFormGroup.addControl(field, new FormControl);

      this.blockInventoryChangeFormGroup.get(field).valueChanges.subscribe(value => {
        this.blockInventoryChange[field] = value;
        this.validateBlockForm();
      });

      if (this.blockInventoryChange[field]) {
        this.blockInventoryChangeFormGroup.get(field).setValue(this.blockInventoryChange[field]);
      }
    }

    this.blockInventoryChangeFields.forEach(configurBlockField);

    this.blockInventoryChangeFormGroup.get('spaceTypes').setValue(this.blockInventoryChange.spaceTypes);
    this.blockInventoryChangeFormGroup.get('areaDisplayId').disable();

    this.blockInventoryChangeFormGroup.get('blockDisplayId').valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      if (this.isNewBlock) {
        const blockFilter = new InventoryFilterRepresentation();
        blockFilter.BlockDisplayId = value;
        this.blockService.getAllBlocks('currentStatus', true, 0, 25, blockFilter)
        .subscribe(result => {
          if (result.items && (result.items.length === 1) && (result.items[0].displayId === value)) {
            this.displayBlockIdError = true;
          }
          else {
            this.displayBlockIdError = false;
          }
          this.blockInventoryChangeFormGroup.get('blockDisplayId').setErrors(this.displayBlockIdError ? {'incorrect': true} : null);
        });
      }
    });

  }

  private validateBlockForm() {
    this.blockInventoryChangeFormGroup.updateValueAndValidity();
    this.blockInventoryChangeEmitter.emit(this.blockInventoryChange);
    this.errorsEmitter.emit(this.blockInventoryChangeFormGroup.invalid);
    if (this.workOrderAction) {
      this.workOrderAction.blockInventoryChangesFormComplete = this.blockInventoryChangeFormGroup.valid;
    }
  }

  private getAllZones() {
    this.zoneService.getAllZones()
      .subscribe(zoneResult => {
        this.zoneOptions = zoneResult.sort((a: Zone, b: Zone) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);
        this.areaService.getAllAreas().subscribe(areas => {
          const selectedArea = areas.filter(area => {
            return area.id === this.blockInventoryChange.areaId;
          });
          if (selectedArea.length === 1) {
            this.areaOption = selectedArea[0];
            this.blockInventoryChange.areaDisplayId = selectedArea[0].displayId;
            const selectedZone = this.zoneOptions.filter(zone => {
              return zone.id === selectedArea[0].zoneId;
            });
            if (selectedZone.length === 1) {
              this.zoneOption = selectedZone[0];
              this.blockInventoryChange.zoneDisplayId = selectedZone[0].displayId;
              this.filterAreasByZone(selectedZone[0].id, this.areaOption);
            }
          }
        });
      });
  }

  zoneSelected(selection: any) {
    this.blockInventoryChangeFormGroup.get('zoneId').setValue(selection.value.id);
    this.blockInventoryChangeFormGroup.get('zoneDisplayId').setValue(selection.value.displayId);
    this.filterAreasByZone(this.blockInventoryChange.zoneId);
  }

  areaSelected(selection: any) {
    this.blockInventoryChangeFormGroup.get('areaId').setValue(selection.value.id);
    this.blockInventoryChangeFormGroup.get('areaDisplayId').setValue(selection.value.displayId);
    this.blockInventoryChange.areaDisplayId = selection.value.displayId;
  }

  calculateSideOfStreet(): string {
    if (this.blockInventoryChange.streetDirection && this.blockInventoryChange.blockStart) {
      var sideOfStreet = this.blockService.calculateSideOfStreet(this.blockInventoryChange.streetDirection, this.blockInventoryChange.blockStart);
      this.blockInventoryChangeFormGroup.get('sideOfStreet').setValue(sideOfStreet);
      return sideOfStreet;
    }
  }

  createCheckboxMultiselectArray(checkboxValue) {
    if (this.multiSelectCheckboxArray) {
      const index = this.multiSelectCheckboxArray.findIndex(spaceType => {
        return spaceType.id === checkboxValue.id;
      });
      if (index === -1) {
        this.multiSelectCheckboxArray.push(checkboxValue);
      } else {
        this.multiSelectCheckboxArray.splice(index, 1);
        if (this.multiSelectCheckboxArray.length === 0) {
          this.multiSelectCheckboxArray = null;
        }
      }
    } else {
      this.multiSelectCheckboxArray = new Array <SpaceType> ();
      this.multiSelectCheckboxArray.push(checkboxValue);
    }
    this.blockInventoryChangeFormGroup.get('spaceTypes').setValue(this.multiSelectCheckboxArray);
    this.blockInventoryChangeFormGroup.get('spaceTypes').updateValueAndValidity();
  }

  selectedSpaceType(spaceTypeId: string): boolean {
    if (this.blockInventoryChange.spaceTypes) {
      const hasSpaceType = this.blockInventoryChange.spaceTypes.filter(spaceType => {
        return spaceType.id === spaceTypeId;
      });
      if (hasSpaceType.length === 1) {
        return true;
      }
      else { return false; }
    }
  }

  filterAreasByZone(zoneId: string, areaOption?: any) {
    this.areaService.getAreasByZoneId(zoneId)
    .subscribe(result => {
      this.areaOptions = result.sort((a: Area, b: Area) => a.displayId < b.displayId ? -1 : 1);
      const areaFromAreaOptions = this.areaOptions.filter(area => {
        return area.id === this.blockInventoryChange.areaId;
      });
      if (areaFromAreaOptions.length === 1) {
        this.selectedArea = areaFromAreaOptions[0];
      }

      if (this.blockInventoryChangeFormGroup.get('areaDisplayId')) {
        this.blockInventoryChangeFormGroup.get('areaDisplayId').enable();
      }
      if (areaOption) {
        this.areaOption = areaFromAreaOptions.filter(option => option.id === areaOption.id)[0];
        this.blockInventoryChange.areaDisplayId = this.areaOption.displayId;
      }
    });
  }

}
