exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "mat-card {\r\n    padding:0px;\r\n}\r\n\r\nmat-tab-group{\r\n    padding:5px;\r\n}\r\n\r\ndiv.mat-tab-body-content {\r\n    padding-top:0px !important;\r\n}\r\n\r\nbutton.create {\r\n    margin-top:5px;\r\n    margin-right:5px;\r\n    position:absolute;\r\n    right:0px;\r\n    z-index:2;\r\n}\r\n\r\n.chart, div {\r\n    padding-right:10px;\r\n    padding-left:10px;\r\n}\r\n\r\nspan {\r\n    font-size: 90%;\r\n}\r\n\r\n.inactive-chip {\r\n  background-color: #607d8b;\r\n  color: white;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();