import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Input } from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { TokenCache } from '../../../security/token-cache';
import { environment } from '../../../environment';
import { InoperableMeterUploadSummaryApiModel } from './models/inoperable-meter-upload-summary';
import { DowntimeUploadService } from './services/downtime-upload-service';


@Component({
  selector: 'app-import-downtime',
  templateUrl: './import-downtime.component.html',
  styleUrls: ['./import-downtime.component.css']
})
export class ImportDowntimeComponent implements OnInit {

  @Input()
  public uploader: FileUploader;
  public fileUploading: Boolean = false;
  public summariesLoading: Boolean = false;

  uploadColumns: Array<string> = ['rowNumber', 'message'];
  uploadMessageDataSource: MatTableDataSource<any>;

  uploadSummaryColumns: Array<string> = ['totalRecords', 'source', 'start', 'end', 'upload']
  uploadSummaryDataSource: MatTableDataSource<InoperableMeterUploadSummaryApiModel>;

  constructor(private tokenCache: TokenCache, private snackBar: MatSnackBar, private uploadService: DowntimeUploadService) {
   }

  ngOnInit() {
    this.uploader = new FileUploader({ url: this.uploadService.uploadEndpoint, authToken: this.tokenCache.getBearerToken(), authTokenHeader: 'Authorization' });
    this.uploader.onAfterAddingFile = (file) => {
      //otherwise the uploader queues up multiple files!
      //so remove all but the last one
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }

      file.withCredentials = false;
    };
    
    const snackBar = this.snackBar;

    this.uploader.onBeforeUploadItem = (item: any) => {
      this.fileUploading = true;
    }

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      if (status == 200) {
        this.uploadMessageDataSource = new MatTableDataSource<any>(JSON.parse(response));
        snackBar.open('File Imported', 'X');
      }
      else {

        this.uploadMessageDataSource = new MatTableDataSource<any>([]);

        let parsed = false;

        try {
          var parsedMessage = JSON.parse(response);
          parsed = true;
        }
        catch(e){
          parsed = false;
        }

        let message = '';

        if (status == 504) {
          //gateway timeout
          message = 'Upload timeout. Try submitting a smaller file.';
        }
        else {
          message = parsed ? parsedMessage.message : response;
        }

        snackBar.open('Error importing file: ' + message, 'X');
      }

      this.fileUploading = false;

      this.reloadUploadSummaries();

      this.uploader.clearQueue();
    }

    this.reloadUploadSummaries();
  }

  reloadUploadSummaries() {
    this.summariesLoading = true;
    this.uploadService.getAllManualCollections()
      .subscribe(result => {
        this.uploadSummaryDataSource = new MatTableDataSource<InoperableMeterUploadSummaryApiModel>(result);
        this.summariesLoading = false;
      },
      error => {
        this.summariesLoading = false;
      });
  }

  upload() {
    this.uploader.uploadAll();
  }
  
}
