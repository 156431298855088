import { Observable } from 'rxjs';
import { WorkOrderHttpService } from './work-order-http.service';
import { Injectable } from '@angular/core';
import {catchError} from 'rxjs/operators';

@Injectable()
export class InventoryChangesAuditService {
  constructor(private workorderHttp: WorkOrderHttpService) { }

  public getInventoryChangesAuditByWorkOrderId(id: string): Observable<any> {
    return this.workorderHttp.get('/inventorychangesaudit/' + id, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
