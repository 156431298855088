import { WorkOrder } from './../../../models/work-order';
import { Validators } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { ActionFormWithValidation } from './action-form-with-validation';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { ContractTypeConstants } from './../../../../inventory-management/meters/meter-constants';
import { HistoricalMeterForm } from './historical-meter-form';
import { InventoryIdExistsValidation } from '../form-field-validators/inventory-id-validators';
import { InstallSpaceMoreThanZeroSpacesValidator } from '../form-field-validators/install-space-validators';
import { InstallSpaceConstants } from './../../../action-field/action-field-constants';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { MoreThanTwentySpaces } from './../../../models/warning-constants';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { ActionConstants } from '../../action-constants';
import { LoadingZoneImpactService } from '../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import {debounceTime} from 'rxjs/operators';

export class InstallSpaceForm extends HistoricalMeterForm {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, loadingZoneImpactService: LoadingZoneImpactService, historicalDate: Date, overrideAsCity: boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, loadingZoneImpactService, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this.onChanges();
  }

  blockRangeControls = [InstallSpaceConstants.BlockRangeFrom, InstallSpaceConstants.BlockRangeTo];
  numberOfSpacesOnMeter: number;

  private onChanges() {
    this.initializeFormForLoadingZoneImpacts(ActionConstants.InstallSpaceId);
    this.setInitialForm();
    this.setUpdateBlockRangeConditionals();
    this.setSignsOnlyConditionals();
    this.setNumberOfSpacesValidatorsAndWarnings();
    this.setNumberOfSpacesByMeterId();
  }

  private setInitialForm(): void {
    if (this.workOrderActionJsonObject) {
      if (this.isCityUser()) {
        this.setMeterTextField(InstallSpaceConstants.MeterIDText, InstallSpaceConstants.MeterStreetNumber, InstallSpaceConstants.ContractType);
        this.setAsyncValidator(InstallSpaceConstants.MeterIDText, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, this.getHasHistoricalEffectiveDate, this.getHistoricalEffectiveDate, this));
      }
      else {
        this.setMeterSelectIdBasedOnGuid(InstallSpaceConstants.MeterSelect);
      }

      if (this.guidControlExists(WorkorderActionJsonConstants.blockGuid)) {

      }
    }
    else {
      this.disableControl(InstallSpaceConstants.NumberOfSpaces);
      this.disableBlockControls([InstallSpaceConstants.PhysicalBlockId]);
      this.disableBlockControls(this.blockRangeControls);

      if (this.isCityUser()) {
        this.setAsyncValidator(InstallSpaceConstants.MeterIDText, InventoryIdExistsValidation.MeterIdDoesNotExistValidator(this._meterService, this.getHasHistoricalEffectiveDate, this.getHistoricalEffectiveDate, this));
        this.subscribeToControl(InstallSpaceConstants.MeterIDText).pipe(debounceTime(1500)).subscribe(result => {
          this.setMeterGuidBasedOnDisplayId(result, WorkorderActionJsonConstants.meterGuid);
        });
      }
      else {
        let currentValue;
        this.subscribeToControl(InstallSpaceConstants.MeterSelect).pipe(debounceTime(1500)).subscribe(value => {
          if (currentValue !== value) {
            currentValue = value;
            this.setMeterGuidBasedOnDisplayId(value, WorkorderActionJsonConstants.meterGuid);
            if (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
              this.addOrRemoveMeterIdValidations(InstallSpaceConstants.MeterSelect, InstallSpaceConstants.NumberOfSpaces, WorkorderActionJsonConstants.meterGuid, value, null, ActionConstants.InstallSpaceId);
            }
          }
        });
      }
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.blockGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
    }

    if (!this.guidControlExists(WorkorderActionJsonConstants.meterGuid)) {
      this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
    }

    if(this.isCityUser()){      
      this.subscribeToControl(InstallSpaceConstants.ContractType).subscribe(result => {
        this.toggleEUR(result);
      });
    }
  }

  private setUpdateBlockRangeConditionals(): void {
    if (this.controlExists(InstallSpaceConstants.UpdateBlockRange)) {
      this.subscribeToControl(InstallSpaceConstants.UpdateBlockRange).subscribe(value => {
        if (value) {
          this.enableAndRequireBlockControls(this.blockRangeControls);
        }
        else {
          this.disableBlockControls(this.blockRangeControls);
        }
      });
    }
  }

  private setSignsOnlyConditionals(): void {
    if (this.controlExists(InstallSpaceConstants.SignsOnly)) {
      this.subscribeToControl(InstallSpaceConstants.SignsOnly).subscribe(value => {
        if (value) {
          this.enableAndRequireBlockControls([InstallSpaceConstants.PhysicalBlockId]);
        }
        else {
          this.disableBlockControls([InstallSpaceConstants.PhysicalBlockId]);
        }
      });
    }
  }

  private setNumberOfSpacesValidatorsAndWarnings(): void {
    if (!this.controlExists(InstallSpaceConstants.NumberOfSpaces)) {
      this.addControl(InstallSpaceConstants.NumberOfSpaces, [Validators.required, InstallSpaceMoreThanZeroSpacesValidator()]);
    }
    else {
      this.setValidators(InstallSpaceConstants.NumberOfSpaces, [Validators.required, InstallSpaceMoreThanZeroSpacesValidator()]);
    }
    this.subscribeToControl(InstallSpaceConstants.NumberOfSpaces).pipe(debounceTime(2000)).subscribe(result => {
      this.addOrRemoveDifferentNumberOfSpacesWarning(result);
    });
  }

  private setNumberOfSpacesByMeterId(): void {
    this.subscribeToGuidControl(WorkorderActionJsonConstants.meterGuid).subscribe(result => {
        const meterToId = this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid);
        this._meterService.getMeterById(meterToId, this.historicalEffectiveDate).subscribe(result => {
          this.numberOfSpacesOnMeter = result.activeSpaces;
          this.enableControl(InstallSpaceConstants.NumberOfSpaces);

          this.toggleEUR(result.currentContractType);
        });
    });
  }

  private toggleEUR(contractType: string) {
    if (contractType === ContractTypeConstants.Reserved.title) {
      if (this.controlExists(InstallSpaceConstants.EUR)) {
        this.disableControl(InstallSpaceConstants.EUR);
        this.setValue(InstallSpaceConstants.EUR, null);
      }
    }
    else if (contractType === ContractTypeConstants.Concession.title) {
      this.enableControl(InstallSpaceConstants.EUR);
      if (this.isCityUser()) {
        this.setAsRequired(InstallSpaceConstants.EUR);
        this.updateValueAndValidity(InstallSpaceConstants.EUR);
      }
    }
  }

  private disableBlockControls(controlIds: number[]): void {
    controlIds.forEach(controlId => {
      this.disableControl(controlId);
      this.setValue(controlId, null);
    });
  }

  private enableAndRequireBlockControls(controlIds: number[]): void {
    controlIds.forEach(controlId => {
      this.enableControl(controlId);
      this.setAsRequired(controlId);
    });
  }

  private addOrRemoveDifferentNumberOfSpacesWarning(value: number): void {
    const totalSpaces = +this.numberOfSpacesOnMeter + +value;
    if (totalSpaces > 20 && !(this.warnings.includes(MoreThanTwentySpaces))) {
        this.pushToWarnings(MoreThanTwentySpaces);
    }
    else if (this.warnings.includes(MoreThanTwentySpaces) && totalSpaces < 20) {
      this.removeFromWarnings(MoreThanTwentySpaces);
    }
    this.formGroup.updateValueAndValidity();
  }
}
