import { UtcDateTimePipe } from './utc-date-pipe/utc-date-time-pipe';
import { HttpClientModule, HttpClient, HttpXhrBackend, HttpRequest } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpRequestOptions } from './http-request-options';
import { RouterModule } from '@angular/router';

import { InventoryHttpService } from '../inventory-http.service';
import { LoadingComponent } from './loading/loading.component';
import { MaterialModule } from './material-module';
import { TokenCache } from '../security/token-cache';
import { TrueUpHttpService } from '../true-up-http.service';
import { WorkOrderHttpService } from '../work-orders/services/work-order-http.service';
import { ReportingHttpService } from '../reports/services/reporting-http.service';
import { FileDownloadService } from './file-download/file-download.service';
import { UtcDatePipe } from './utc-date-pipe/utc-date-pipe';
import { HolidayService } from './services/holiday.service';
import { ClosureLinkService } from './services/closure-link.service';

@NgModule({
  imports: [
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule
  ],
  declarations: [
    LoadingComponent,
    UtcDatePipe,
    UtcDateTimePipe
  ],
  exports: [
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    LoadingComponent,
    UtcDatePipe,
    UtcDateTimePipe
  ],
  providers: [
    TokenCache,
    DatePipe,
    UtcDatePipe,
    UtcDateTimePipe,
    HttpRequestOptions,
    {
      provide: HttpClient,
      useFactory: httpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache]
    },
    {
      provide: InventoryHttpService,
      useFactory: httpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache]
    },
    {
      provide: WorkOrderHttpService,
      useFactory: workorderHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache]
    },
    {
      provide: TrueUpHttpService,
      useFactory: trueUpHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache]
    },
    {
      provide: ReportingHttpService,
      useFactory: reportingHttpFactory,
      deps: [HttpXhrBackend, HttpRequestOptions, TokenCache, FileDownloadService]
    },
    FileDownloadService,
    HolidayService,
    ClosureLinkService
  ],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule { }

export function workorderHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache) {
  return new WorkOrderHttpService(backend, defaultOptions, TokenCache);
}

export function httpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache) {
  return new InventoryHttpService(backend, defaultOptions, TokenCache);
}

export function trueUpHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache) {
  return new TrueUpHttpService(backend, defaultOptions, TokenCache);
}

export function reportingHttpFactory(backend: HttpXhrBackend, defaultOptions: HttpRequestOptions, TokenCache: TokenCache, fileDownload: FileDownloadService) {
  return new ReportingHttpService(backend, defaultOptions, TokenCache, fileDownload);
}
