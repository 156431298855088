import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderHttpService } from './work-order-http.service';
import { WorkOrderRoleConstants } from './../../security/login/securityConstants';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ActionFieldService {

  constructor(private workorderhttp: WorkOrderHttpService) { }

  public getAllActionFields(id: number, overrideToCityUserRole:boolean): Observable<any> {
    var url = `/actionFields/${id}`;
    if(overrideToCityUserRole){
      url += '/role/city'
    }
    return this.workorderhttp.get(url, null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public getAllReadOnlyActionFields(id: number, authorRole: string): Observable<any> {

    return this.workorderhttp.get('/actionFields/' + id + '/' + authorRole + '/readonly', null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
