import { Component, OnInit } from '@angular/core';
import { TokenCache } from '../security/token-cache';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  isCPMUser: boolean = false;
  constructor(private TokenCache: TokenCache) { }

  ngOnInit() {
    this.isCPMUser = this.TokenCache.checkForCPMUser();
  }
}
