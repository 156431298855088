import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderHttpService } from '../../services/work-order-http.service';
import { HistoricalChangeToInventoryConfirm } from '../models/historical-change-to-inventory-confirm';
import { InventoryHttpService } from '../../../inventory-http.service';
import {WorkOrderResponse} from '../../models/approval-models';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class InventoryChangesService {

  constructor(private inventoryHttp: InventoryHttpService, private workorderhttp: WorkOrderHttpService) { }

  variables: any;
  error: any;

  public approveHistoricalChange(response: WorkOrderResponse): Observable<any> {
    return this.workorderhttp.put('/workOrders/approveHistoricalChange', response)
        .pipe(
          map(response => response),
          catchError(error => this.handleError(error))
        );
  }

  public deleteInventoryPendingFacets(workOrderActionId: string): Observable<any> {
    return this.inventoryHttp.put(`/inventoryChanges/${workOrderActionId}/delete`, null)
        .pipe(
          map(response => response != null ? response : null),
          catchError(error => this.handleError(error))
        );
  }

  public updateInventoryChanges(workOrderId: string, workOrderActionId: string) {
    return this.workorderhttp.put(`/workOrders/${workOrderId}/workOrderActions/${workOrderActionId}/edit`, null)
    .pipe(
      map((response) => response),
      catchError(error => Observable.throwError(error))
    );
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
