exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "div {\r\n    padding-bottom: 2%;\r\n}\r\n\r\n.toolbar {\r\n    position:fixed;\r\n    z-index: 2;\r\n}\r\n\r\nmat-card.mat-card {\r\n    padding:0px;\r\n    margin: 0px;\r\n}\r\n\r\n.nav-active {\r\n    cursor: pointer !important;\r\n    background-color: #607d8b !important;\r\n    color:white !important;\r\n}\r\n\r\n.meter-field {\r\n    word-wrap: break-word !important;\r\n    word-break: break-all !important;\r\n    padding-right:5px !important;\r\n    overflow: hidden !important;\r\n    text-overflow: ellipsis !important;\r\n    white-space: nowrap !important;\r\n}\r\n\r\n.menu {\r\n    background-color: #607d8b;\r\n    color:white;\r\n    font-size:150%;\r\n    font-weight: bolder;\r\n    padding:10px;\r\n}\r\n.material-icons {\r\n    font-size: 16px;\r\n  }\r\n\r\n.active-button {\r\n    color:#607d8b;\r\n    background-color:white;\r\n}\r\n\r\n.toolbar {\r\n    padding:0px;\r\n}\r\n\r\n.im-button {\r\n    width:200px !important;\r\n    min-width:200px !important;\r\n}\r\n\r\n.tu-button {\r\n    width:125px !important;\r\n    min-width:125px !important;\r\n}\r\n\r\n.wo-button {\r\n    width:150px !important;\r\n    min-width:150px !important;\r\n}\r\n\r\n.rpt-button {\r\n  width: 105px !important;\r\n  min-width: 105px !important;\r\n}\r\n\r\nbutton {\r\n    font-size: auto;\r\n}\r\n\r\n.username {\r\n    font-size:75%;\r\n    padding-left:10px;\r\n    padding-right:10px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();