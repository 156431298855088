import { Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-meter-distribution-log-dialog',
    templateUrl: 'meter-distribution-log-dialog.component.html',
    styleUrls: ['./meter-distribution-log-dialog.component.css']
  })
  export class MeterDistributionLogDialog {

    constructor(
      public dialogRef: MatDialogRef<MeterDistributionLogDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
      this.dialogRef.close();
    }
  }
