import { Component, OnInit, ErrorHandler } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuditReportService } from '../../../reports/services/audit-report.service';
import { SpaceAudit } from '../../../reports/audit/space-audit';

@Component({
    selector: 'app-space-audit',
    templateUrl: './space-audit.component.html',
    styleUrls: ['./space-audit.component.css']
})

export class SpaceAuditComponent implements OnInit {
    spaceId: string;

    spaceAudit: SpaceAudit[];
    dataSource = new MatTableDataSource<SpaceAudit>();
    displayedColumns: Array<string>;

    constructor(private spaceService: AuditReportService, private _errorHandler: ErrorHandler, private route: ActivatedRoute) {
        this.spaceAudit = new Array<SpaceAudit>();
    }

    ngOnInit(): void {
        this.spaceId = this.route.snapshot.paramMap.get('id');
        this.displayedColumns = [
          'WorkOrder',
          'WorkOrderActionType',
          'HistoricalChange',
          'OldValues',
          'NewValues',
          'EffectiveDate',
          'EntryDate',
          'EUR',
          'RequestedBy',
          'ApprovedBy',
          'TempApproved',
          'ActivatedBy'
        ];
        this.getSpaceAudit();
    }

    getSpaceAudit() {
      this.spaceService.RequestSpaceAuditHistory(this.spaceId).subscribe(
            result => {
                this.spaceAudit = result;
                this.dataSource.data = this.spaceAudit;
                this.dataSource = new MatTableDataSource<SpaceAudit>(this.spaceAudit);
            },
            error => {
                this._errorHandler.handleError(error);
            }
        );
    }
}
