exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".confirm{\r\n    margin: auto;\r\n    width: 50%;\r\n}\r\n\r\n.button {\r\n    height:35px;\r\n    max-height:35px;\r\n    min-width:300px;\r\n}\r\n\r\n.date-picker {\r\n    min-width:200px;\r\n    margin-right:25px;\r\n}\r\n\r\n.mat-expansion-panel-header {\r\n    height:50px;\r\n}\r\n\r\n.button-row {\r\n    min-height:50px !important;\r\n    max-height:50px !important;\r\n    height:50px !important;\r\n    padding-top:50px;\r\n    padding-bottom:50px\r\n}\r\n\r\n.meter-inventory {\r\n    min-height:250px !important;\r\n    height:250px !important;\r\n    padding-top:50px;\r\n    padding-bottom:50px;\r\n}\r\n\r\n.block-inventory {\r\n    min-height:250px !important;\r\n    height:250px !important;\r\n    padding-top:50px;\r\n}\r\n\r\n.status-annotation{\r\n    font-style: italic;\r\n}\r\n\r\n.id-error {\r\n    font-style: italic;\r\n    color: red;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();