import { ChangeDetectorRef, Component, ErrorHandler, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OnChanges, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { InventoryFilterRepresentation } from '../../inventory-filter/inventory-filter-representation';
import { MeterService } from '../../meters/meters.service';
import { InventoryListSummary } from '../inventory-list-summary';
import { PaginationParameters } from './../../../core/models/pagination-paramters';
import { BlockService } from './../../blocks/blocks.service';
import { SpaceStatusConstants } from './../../spaces/space-constants';
import { MeterStatusConstants, ContractTypeConstants } from './../../meters/meter-constants';
import { SpaceService } from './../../spaces/spaces.service';
import { InventoryListDataSource } from './inventory-list-data-source';
import { FilterOperator } from '../../../core/query-strings/query-string-builder';
import { ColumnCustomizerDialogComponent } from './../column-customizer-dialog/column-customizer-dialog.component';
import { InventoryListColumnDef, Column } from './inventory-list-column-def';
import { ExportSelectRepresentation } from '../../inventory-filter/export-representations';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../../trueup/confirmation-dialog/confirmation-dialog.component';
import { ColumnConstants } from '../column-constants';
import { Subscription } from 'rxjs/subscription';

class RefreshEvent {
  pageEvent: PageEvent;
  filter: InventoryFilterRepresentation;
}

@Component({
    selector: 'app-inventory-list',
    templateUrl: './inventory-list.component.html',
    styleUrls: ['./inventory-list.component.css']
})
export class InventoryListComponent implements OnChanges, OnInit, OnDestroy {

    @Input() pageContext: string;
    @Input() filterSubject: Subject<InventoryFilterRepresentation>;
    @Input() filter: InventoryFilterRepresentation;
    @Input() blockId: string;
    @Input() meterId: string;
    @Input() displayedColumns: Array<string>;
    @Input() columnsForExport: Array<string>;

    newFilter = false;
    objectName: string;

    @Output()
    inventoryCount: EventEmitter<number> = new EventEmitter<number>(true);

    allColumns: Array<Column> = new Array<Column>();
    dataSource: InventoryListDataSource;

    filterSubscription: Subscription;

    pageProperties: PageEvent = new PageEvent();

    orderByCategory = 'displayId';
    isAscending = true;
    paginationParameters: PaginationParameters;
    inventoryItemsLoaded: boolean;

    confirmDialogRef: MatDialogRef<ConfirmationDialogComponent>;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    @Output()
    selectedInventory: EventEmitter<InventoryListSummary> = new EventEmitter<InventoryListSummary>(true);
    selectedInventoryIndex: InventoryListSummary;

    @Output()
    paginationParameterEmitter: EventEmitter<PaginationParameters> = new EventEmitter<PaginationParameters>(true);

    @Output()
    exportSelectEventEmitter: EventEmitter<ExportSelectRepresentation> = new EventEmitter<ExportSelectRepresentation>(true);

    refreshSubject: Subject<RefreshEvent> = new Subject<RefreshEvent>();
    refreshSubscription: Subscription;

    constructor(private meterService: MeterService, private spaceService: SpaceService, private blockService: BlockService,
        private _errorHandler: ErrorHandler, private _changeDetectorRef: ChangeDetectorRef, private dialog: MatDialog,  private router: Router) {
    }

    ngOnInit(): void {
      this.pageProperties = new PageEvent();
      this.pageProperties.length = 0;
      this.pageProperties.pageIndex = 0;
      let pageSize = 15;
      if (this.pageContext) {
        if (this.pageContext.includes('list')) {
          pageSize = 100;
        } else if (this.pageContext.includes('select')) {
          pageSize = 10;
        }
      }
      this.pageProperties.pageSize = pageSize;

      this.refreshSubject.pipe(
        debounceTime(1000))
        .subscribe(event => this.refreshDataSource(event.pageEvent, event.filter));

      //check if we already have subscription, don't need more than one
      if (this.filterSubject !== undefined && !this.filterSubscription) {
        this.filterSubscription = this.filterSubject.pipe(
          debounceTime(1000))
          .subscribe((filter) => {
            this.filter = filter;
            this.newFilter = true;
            this.pushRefresh(this.pageProperties, this.filter);
          });
      }
    }

    ngOnDestroy(): void {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }

      if (this.refreshSubscription) {
        this.refreshSubscription.unsubscribe();
      }
    }

    ngAfterViewInit() {
        this._changeDetectorRef.detectChanges();
    }

    ngOnChanges() {
      switch (this.pageContext.toLowerCase()) {
        case 'meter-list':
        case 'block-meters':
        case 'meter-select':
        case 'workordermeterinventory':
        case 'meter-select-not-removed':
        case 'meter-select-inactive':
        case 'meter-select-active-reserved':
        case 'meter-from-select':
        case 'meter-to-select':
          this.objectName = 'Meter';
          break;
        case 'space-list':
        case 'block-spaces':
        case 'space-select':
          this.objectName = 'Space';
          break;
        case 'block-list':
        case 'linked-blocks':
        case 'block-select':
          this.objectName = 'Block';
          break;
        default:
            this.objectName = 'Node';
          break;
      }

        this.paginationParameters = {
            orderByColumn: this.orderByCategory,
            pageSize: this.pageProperties.pageSize,
            isAscending: true,
            totalItemCount: 0,
            pageIndex: 0
        };
        // emit pagination to table
        this.emitPaginationParameters(this.paginationParameters);
        this.emitSelectFilter(this.columnsForExport);


        if (this.filter === undefined) {
            this.filter = new InventoryFilterRepresentation();
        }

        if (this.pageContext === 'space-select') {
            this.filter.SpaceStatus = SpaceStatusConstants.removed;
            if (this.meterId !== null && this.meterId !== undefined) {
                this.filter.MeterId = this.meterId;
            }
        }
        else if (this.pageContext === 'space-select') {
            this.filter.SpaceStatus = SpaceStatusConstants.removed;
            this.filter.BlockId = this.blockId;
        }
        else if (this.blockId && (this.pageContext === 'block-meters' || this.pageContext === 'block-spaces' || this.pageContext === 'linked-blocks')) {
            this.filter.BlockId = this.blockId;
        }
        else if (this.pageContext === 'meter-select' || this.pageContext === 'meter-from-select' || this.pageContext === 'meter-to-select') {
            this.filter.MeterStatus = MeterStatusConstants.active;
        }
        else if (this.pageContext === 'workOrderMeterInventory') {
            this.filter.MeterStatus = MeterStatusConstants.removed;
        }
        else if (this.pageContext === 'meter-select-inactive') {
            this.filter.MeterStatus = MeterStatusConstants.inactive;
        }
        else if (this.pageContext === 'meter-select-active-reserved') {
            this.filter.MeterStatus = MeterStatusConstants.active;
            this.filter.ContractType = ContractTypeConstants.Reserved.title;
        }
        else if (this.pageContext === 'meter-select-not-removed') {
            this.filter.MeterStatus = MeterStatusConstants.removed;
            this.filter.MeterStatusFilterOperator = FilterOperator.NotEqual;
        }

        this.pushRefresh(this.pageProperties, this.filter);
    }

    deleteLinkedBlock(linkedBlockId: string, linkedBlockDisplayId: string) {
      this.confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '240px',
        width: '400px',
      });
      this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you remove the link to Block ` + linkedBlockDisplayId + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.blockService.deleteLinkFromBlock(this.blockId, linkedBlockId).subscribe(
            result => {
              window.location.reload();
            },
            error => {
              this._errorHandler.handleError(error);
            });
        }
      });
    }

    goToRelatedBlock(id: string) {
      const url = '/inventorymanagement/blocks/' + id;
      this.router.navigate([url]);
      window.location.reload();
    }

    sortChanged() {
        if (!this.pageProperties) {
            this.pageProperties = new PageEvent();
        }
        this.pageProperties.pageIndex = 0;
        this.pushRefresh(this.pageProperties, this.filter);
    }

    refreshDataSourceForPageEvent(event: PageEvent) {
        this.inventoryItemsLoaded = false;
        this.pageProperties = event;
        this.pushRefresh(event, this.filter);
        setTimeout(function() {
          window.scrollTo(0,0); 
      }, 1500);
        }

    refreshDataSource(pageEvent: PageEvent, filter: InventoryFilterRepresentation) {
        const skip = pageEvent.pageSize * pageEvent.pageIndex;
        const take = pageEvent.pageSize;

        this.dataSource = new InventoryListDataSource(pageEvent.pageSize, skip, this.sort,
            this.paginationParameters, this._errorHandler, filter, this.newFilter, this.paginator, this.meterService, this.spaceService, this.blockService, this.pageContext);

        this.dataSource.pagerServiceEventEmitter.subscribe(parameters => {
            this.paginationParameters = parameters;
            this.pageProperties.length = parameters;
            this.pageProperties = pageEvent;
        });

        this.dataSource.inventoryListLoaded.subscribe(itemsLoaded => {
          this.inventoryItemsLoaded = itemsLoaded;
          this.newFilter = false;
        });

        this.dataSource.inventoryCount.subscribe(count => {
            this.inventoryCount.emit(count);
        });


    }

    pushRefresh(pageEvent: PageEvent, filter: InventoryFilterRepresentation) {
      var event = new RefreshEvent();
      event.pageEvent = pageEvent;
      event.filter = filter;
      this.inventoryItemsLoaded = false;

      //this is done as a subject so that we can "debounce" it
      //filter otherwise fires refresh way too many times when it starts up
      this.refreshSubject.next(event);
    }

    emitSelectFilter(columns: Array<string>) {
        const selectRepresentation = new ExportSelectRepresentation();
        selectRepresentation.Columns = columns;
        this.exportSelectEventEmitter.emit(selectRepresentation);
    }

    selectRow(inventory) {
        this.selectedInventoryIndex = inventory;
        this.selectedInventory.emit(this.selectedInventoryIndex);
    }

    emitPaginationParameters(paginationParams: PaginationParameters) {
        this.paginationParameterEmitter.emit(paginationParams);
    }

  navigateToRatePackagePage(ratePackageName: string) {
    window.open('/inventorymanagement/ratepackages/' + ratePackageName, 'noopener');
  }
}
