exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".buttons {\r\n    padding: 15px;\r\n}\r\n\r\n.dialog-width{\r\n    width:400px;\r\n}\r\n\r\n.dialog-title {\r\n    padding:10px;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();