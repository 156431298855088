import { Component, OnInit, ErrorHandler } from '@angular/core';
import { WorkOrdersService } from './../../work-orders/services/work-orders.service';
import { WorkOrderGroup } from '../../work-orders/models/work-order-group';

@Component({
    selector: 'work-order-groups',
    templateUrl: './work-order-groups.component.html',
    styleUrls: ['./work-order-groups.component.css']
})
export class WorkOrderGroupsComponent implements OnInit {
    constructor(private _workOrdersService: WorkOrdersService, private _errorHandler: ErrorHandler) { }

    workOrderGroups: Array<WorkOrderGroup> = [];

    ngOnInit() {
        this.getAllWorkOrderGroups();
    }

    deleteWorkOrderGroup(id: string) {
        this._workOrdersService.DeleteWorkOrderGroup(id).subscribe(result => {
            this.getAllWorkOrderGroups();
        },
        error => {
            this._errorHandler.handleError(error);
        })
    }

    getAllWorkOrderGroups() {
        this._workOrdersService.getAllWorkOrderGroups().subscribe(result => {
            this.workOrderGroups = result;
        },
        error => {
            this._errorHandler.handleError(error);
        });
    }
}