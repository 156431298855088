import { FilterOperator } from './../../../core/query-strings/query-string-builder';
import { StringExtensions } from './../../../string-extensions';

export class RatePackageFilterRepresentation {
    ratePackageName: string = undefined;
    ratePackageNameFilterOperator: string = FilterOperator.Equal;
    get FilterByRatePackageName(): boolean {
        return StringExtensions.StringIsNotEmpty(this.ratePackageName);
    }

    hoursOfOperationName: string = undefined;
    get FilterByHoursOfOperationName(): boolean {
        return StringExtensions.StringIsNotEmpty(this.hoursOfOperationName);
    }

    isInactivated = false;
    get FilterByIsInactivated(): boolean {
      return true;
    }

    hoursOfRestrictionName: string = undefined;
    get FilterByHoursOfRestrictionName(): boolean {
        return StringExtensions.StringIsNotEmpty(this.hoursOfRestrictionName);
    }

    ratePerHour: number = undefined;
    ratePerHourFilterOperator: string = FilterOperator.Equal;
    ratePerHourEnd: number = undefined;
    get FilterByRatePerHour(): boolean {
        return this.ratePerHour !== undefined && this.ratePerHour !== null;
    }

    periodOfStay: number = undefined;
    periodOfStayFilterOperator: string = FilterOperator.Equal;
    periodOfStayInMinutes: number = undefined;
    get FilterByperiodOfStay(): boolean {
        return this.periodOfStay !== undefined && this.periodOfStay !== null;
    }

    date: Date;
}
