export class SpaceClosureByWarehouse {
    closureWarehouseRecordId: string;

    closureWarehouseId: string;

    earliestClosureDate: Date;

    latestClosureDate: Date;

    affectedAmount: number;

    affectedHours: number;

    affectedSpaceIds: string[];
}
