import { Injectable } from '@angular/core';

import { WorkOrderHttpService } from './work-order-http.service';
import { ExecuteWorkOrderAction } from '../models/execute-work-order-action';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class WorkOrderActionService {

  constructor(private workorderhttp: WorkOrderHttpService) { }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public executeInventoryChangesForWorkOrderAction(workOrderActionId: string, effectiveDate: Date) {
    const request = new ExecuteWorkOrderAction();
    request.workOrderActionId = workOrderActionId;
    request.effectiveDate = effectiveDate;

    return this.workorderhttp.put('/inventoryChanges/workOrderActions/' + workOrderActionId + '/executeChanges/', request)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public executeHistoricalChangesForEntireWorkOrder(workOrderId: string, effectiveDate?: Date) {
    return this.workorderhttp.put('/inventoryChanges/workOrders/' + workOrderId + '/executeHistoricalChanges', effectiveDate ? effectiveDate : {})
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

}
