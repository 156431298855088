import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { DaysOfWeek } from '../rate-package-constants';
import { Holiday } from '../../../../core/models/holiday';
import { HolidayService } from './../../../../core/services/holiday.service';
import { RateStep } from '../../models/rate-step';
import { RestrictionTypes } from './../rate-package-constants';

@Component({
  selector: 'app-rate-step',
  templateUrl: './rate-step.component.html',
  styleUrls: ['./rate-step.component.css']
})
export class RateStepComponent implements OnInit {


  @Input() rateStep: RateStep;
  @Input() isOperation: boolean;
  @Input() rateSteps: RateStep[];
  @Input() containsInvalidRateStep: boolean;
  @Input() hoursOfScheduleName: string;
  @Input() hoursOfScheduleDescription: string;
  @Input() isRegularRateAdjustment: boolean;

  @Output() rateStepsEmitter: EventEmitter<any> = new EventEmitter<any>();

  holidays: Holiday[];
  RestrictionTypes = RestrictionTypes;
  startDate: Date;
  endDate: Date;

  containsOverlappingRows: boolean;

  constructor(private _holidayService: HolidayService) { }

  ngOnInit() {
    this.confirmValidRateStep();
    this._holidayService.getAllHolidays().subscribe(result => {
      this.holidays = result;
    });
  }

  confirmValidRateStep() {
    if (this.rateStep.periodOfStay) {
      this.rateStep.periodOfStayInMinutes = this.rateStep.periodOfStay * 60;
    }

    this.rateStep.containsInvalidTimeline = (this.rateStep.stopTime <= this.rateStep.startTime && this.rateStep.stopTime !== '00:00:00' && this.rateStep.stopTime !== '00:00');

    if (this.isOperation) {
      this.rateStep.containsNullValues = (this.rateStep.rate === null || this.rateStep.periodOfStay === null || !this.rateStep.startTime || !this.rateStep.stopTime);
    }
    else {
      this.rateStep.containsNullValues = (!this.rateStep.startTime || !this.rateStep.stopTime);
    }

    if (this.rateStep.isSeasonal) {
      if (this.rateStep.isSeasonalHoliday) {
        this.rateStep.seasonalDayStart = null;
        this.rateStep.seasonalDayEnd = null;
        this.rateStep.seasonalMonthStart = null;
        this.rateStep.seasonalMonthEnd = null;
        this.startDate = null;
        this.endDate = null;
      }
      else {
        this.rateStep.seasonalHolidayStart = null;
        this.rateStep.seasonalHolidayEnd = null;
        if (this.startDate) {
          this.rateStep.seasonalDayStart = this.startDate.getUTCDate();
          this.rateStep.seasonalMonthStart = this.startDate.getUTCMonth() + 1;
        }
        else  if (this.rateStep.seasonalMonthStart) {
          this.startDate = new Date(new Date().getFullYear(), this.rateStep.seasonalMonthStart, this.rateStep.seasonalDayStart);
        }
        if (this.endDate) {
          this.rateStep.seasonalDayEnd = this.endDate.getUTCDate();
          this.rateStep.seasonalMonthEnd = this.endDate.getUTCMonth() + 1;
        }
        else if (this.rateStep.seasonalMonthEnd) {
          this.endDate = new Date(new Date().getFullYear(), this.rateStep.seasonalMonthEnd, this.rateStep.seasonalDayEnd);
        }
      }
    }
    else {
      this.rateStep.seasonalDayStart = null;
      this.rateStep.seasonalDayEnd = null;
      this.rateStep.seasonalMonthStart = null;
      this.rateStep.seasonalMonthEnd = null;
      this.rateStep.seasonalHolidayStart = null;
      this.rateStep.seasonalHolidayEnd = null;
    }

    this.rateStep.zeroDaysSelected = true;
    DaysOfWeek.forEach(day => {
      if (this.rateStep[day.toLowerCase()]) {
        this.rateStep.zeroDaysSelected = false;
      }
      else {
        this.rateStep.selectAll = false;
      }
    });

    this.containsInvalidRateStep = false;
    this.rateSteps.forEach(step => {
      this.containsInvalidRateStep = step.containsInvalidTimeline
                                  || step.containsNullValues
                                  || step.zeroDaysSelected
                                  || !this.hoursOfScheduleName
                                  || !this.hoursOfScheduleDescription
                                  || this.containsOverlappingRows
                                  || step.periodOfStay === 0;
    });

    this.rateStepsEmitter.emit(true);
  }

  selectAll(rateStep) {
    DaysOfWeek.forEach(day => {
      this.rateStep[day.toLowerCase()] = this.rateStep.selectAll;
    });

    this.confirmValidRateStep();
  }

  removeRateStep(rateStep) {
    this.rateSteps.splice(this.rateSteps.indexOf(rateStep), 1);

    this.rateStepsEmitter.emit(true);
  }
}
