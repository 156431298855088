import { InventoryChangesAuditService } from './services/inventory-changes-audit.service';
import { InventoryChangeAuditComponent } from './work-order-detail/inventory-change-audit/inventory-change-audit.component';
import { CompletedInventoryChangesComponent } from './work-order-detail/completed-inventory-changes/completed-inventory-changes.component';
import { UpdateMeterAttributesService } from './inventory-information/services/update-meter-attributes-service';
import { InventoryFilterService } from './inventory-information/services/inventory-filter-service';
import { RegularRateAdjustmentService } from './inventory-information/services/regular-rate-adjustment.service';
import { RegularRateAdjustmentComponent } from './inventory-information/regular-rate-adjustment/regular-rate-adjustment.component';
import { WorkOrderCancelConfirmation } from './work-order-detail/work-order-cancel-confirmation/work-order-cancel-confirmation';
import { LetterOfDirectionComponent } from './letter-of-directions-list/letter-of-direction/letter-of-direction.component';
import { LetterOfDirectionsListComponent } from './letter-of-directions-list/letter-of-directions-list.component';
import { InventorySelectDialogComponent } from './action-field/inventory-select-dialog/inventory-select-dialog.component';
import { InventoryManagementModule } from './../inventory-management/inventory-management.module';
import { WorkOrderActionService } from './services/work-order-action.service';
import { WorkOrderBlockInventoryComponent } from './inventory-information/work-order-block-inventory/work-order-block-inventory.component';
import { WorkOrderSpaceInventoryComponent } from './inventory-information/work-order-space-inventory/work-order-space-inventory.component';
import { WorkOrderMeterInventoryComponent } from './inventory-information/work-order-meter-inventory/work-order-meter-inventory.component';
import { RelocateMeterComponent } from './inventory-information/relocate-meter/relocate-meter.component';
import { MetersModule } from './../inventory-management/meters/meters.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { WorkOrderActionComponent } from './action/action.component';
import { WorkOrderCreateComponent } from './work-order-create/work-order-create.component';
import { WorkOrderListComponent } from './work-order-list/work-order-list.component';
import { ActionFieldComponent} from './action-field/action-field.component';
import { WorkOrdersService } from './services/work-orders.service';
import { WorkOrderDetailComponent } from './work-order-detail/work-order-detail.component';
import { ActionService } from './services/action.service';
import { ActionFieldService } from './services/action-field.service';
import { WorkOrderJsonConverter } from './work-order-json-converter';
import { AssociatedMeterDetailsComponent } from './associated-meter-details/associated-meter-details.component';
import { AssociatedBlockDetailsComponent } from './associated-block-details/associated-block-details.component';
import { InventoryChangesComponent } from './inventory-information/inventory-changes/inventory-changes.component';
import { WorkOrderCreateConfirmationComponent } from './work-order-create/work-order-create-confirmation/work-order-create-confirmation';
import { SpaceInventoryChangeService } from './inventory-information/services/space-inventory-change-service';
import { MeterInventoryChangeService } from './inventory-information/services/meter-inventory-change-service';
import { BlockInventoryChangeService } from './inventory-information/services/block-inventory-change-service';
import { InventoryTableModule } from '../inventory-management/inventory-table/inventory-table.module';
import { RatePackageSelectDialogComponent } from './action-field/rate-package-select-dialog/rate-package-select-dialog.component';
import { RatePackagesModule } from '../inventory-management/rate-packages/rate-packages.module';
import { WorkOrderRatePackageInformationComponent } from './inventory-information/work-order-rate-package-information/work-order-rate-package-information.component';
import { LetterOfDirectionService } from './services/letter-of-direction.service';
import { CommentsDialogComponent } from './letter-of-directions-list/comments-dialog/comments-dialog.component';
import { WorkOrderFilterComponent } from './work-order-filter/work-order-filter.component';
import { WorkOrderSingleFieldInputComponent } from './work-order-detail/work-order-field-input-dialog/work-order-field-input-dialog.component';
import { UpdateMeterAttributesComponent } from './inventory-information/update-meter-attributes/update-meter-attributes.component';
import { LODApplyHistoricalChangesConfirmation } from './letter-of-directions-list/lod-apply-historical-changes-confirmation/lod-apply-historical-changes-confirmation.component';
import { InventoryChangesApplyHistoricalChangesConfirmation } from './inventory-information/inventory-changes/inventory-changes-apply-historical-changes-confirmation/inventory-changes-apply-historical-changes-confirmation.component';
import { ReversibilityDeciderService } from './reversibility/reversibility-decider.service';
import { DueDateService } from './services/due-date.service';
import { InventoryChangesService } from './inventory-information/services/inventory-changes-service';
import { WorkOrdersListsComponent } from './work-orders-lists/work-orders-lists.component';
import { AssociatedSpaceDetailsComponent } from './associated-space-details/associated-space-details.component';
import { WorkOrderInstallComponent } from './inventory-information/work-order-space-inventory/work-order-install-space/work-order-install-space.component';
import { WorkOrderSpaceModificationComponent } from './inventory-information/work-order-space-inventory/work-order-space-modification/work-order-space-modification.component';
import { WorkOrderAttachmentsComponent } from './work-order-detail/work-order-attachments/work-order-attachments.component';
import { AdFileUploadModule } from 'ad-file-upload';
import { DeleteAttachmentConfirmationComponent } from './work-order-detail/work-order-attachments/delete-attachment-confirmation/delete-attachment-confirmation.component';
import { CanvasIdConfirmationComponent } from './work-order-detail/canvas-id-confirmation/canvas-id-confirmation.component';
import { WorkOrderResponseService } from './services/work-order-response.service';
import { WorkOrderCommentsComponent } from './work-order-detail/work-order-comments/work-order-comments.component';
import { WorkOrderReverseConfirmationComponent } from './work-order-detail/work-order-reverse-confirmation/work-order-reverse-confirmation.component';


@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    InventoryManagementModule,
    MetersModule,
    InventoryTableModule,
    RatePackagesModule,
    AdFileUploadModule,
  ],
  declarations: [
    ActionFieldComponent,
    AssociatedBlockDetailsComponent,
    AssociatedMeterDetailsComponent,
    AssociatedSpaceDetailsComponent,
    CommentsDialogComponent,
    InventorySelectDialogComponent,
    InventoryChangesComponent,
    InventoryChangeAuditComponent,
    LetterOfDirectionComponent,
    LetterOfDirectionsListComponent,
    RatePackageSelectDialogComponent,
    RegularRateAdjustmentComponent,
    RelocateMeterComponent,
    UpdateMeterAttributesComponent,
    WorkOrderActionComponent,
    WorkOrderBlockInventoryComponent,
    WorkOrderCreateComponent,
    WorkOrderDetailComponent,
    WorkOrderListComponent,
    WorkOrderMeterInventoryComponent,
    WorkOrderSpaceInventoryComponent,
    WorkOrderCreateConfirmationComponent,
    WorkOrderCancelConfirmation,
    WorkOrderRatePackageInformationComponent,
    WorkOrderFilterComponent,
    WorkOrderSingleFieldInputComponent,
    LODApplyHistoricalChangesConfirmation,
    InventoryChangesApplyHistoricalChangesConfirmation,
    WorkOrdersListsComponent,
    WorkOrderInstallComponent,
    WorkOrderCommentsComponent,
    WorkOrderSpaceModificationComponent,
    WorkOrderAttachmentsComponent,
    DeleteAttachmentConfirmationComponent,
    CanvasIdConfirmationComponent,
    CompletedInventoryChangesComponent,
    WorkOrderReverseConfirmationComponent
  ],
  exports: [
    ActionFieldComponent,
    AssociatedBlockDetailsComponent,
    AssociatedMeterDetailsComponent,
    AssociatedSpaceDetailsComponent,
    CommentsDialogComponent,
    InventorySelectDialogComponent,
    InventoryChangesComponent,
    LetterOfDirectionComponent,
    LetterOfDirectionsListComponent,
    RelocateMeterComponent,
    WorkOrderActionComponent,
    WorkOrderBlockInventoryComponent,
    WorkOrderCreateComponent,
    WorkOrderDetailComponent,
    WorkOrderListComponent,
    WorkOrderMeterInventoryComponent,
    WorkOrderSpaceInventoryComponent,
    WorkOrderCreateConfirmationComponent,
    WorkOrderCancelConfirmation,
    RatePackageSelectDialogComponent,
    WorkOrderRatePackageInformationComponent,
    LODApplyHistoricalChangesConfirmation,
    InventoryChangesApplyHistoricalChangesConfirmation,
    DeleteAttachmentConfirmationComponent,
    CanvasIdConfirmationComponent,
    WorkOrderReverseConfirmationComponent
  ],
  providers: [
    ActionService,
    ActionFieldService,
    SpaceInventoryChangeService,
    BlockInventoryChangeService,
    LetterOfDirectionService,
    InventoryChangesService,
    InventoryChangesAuditService,
    MeterInventoryChangeService,
    RegularRateAdjustmentService,
    WorkOrderActionService,
    WorkOrderJsonConverter,
    WorkOrdersService,
    WorkOrderResponseService,
    LetterOfDirectionService,
    InventoryFilterService,
    UpdateMeterAttributesService,
    ReversibilityDeciderService,
    DueDateService
  ],
  entryComponents: [
    CommentsDialogComponent,
    InventorySelectDialogComponent,
    RatePackageSelectDialogComponent,
    WorkOrderCreateConfirmationComponent,
    WorkOrderCancelConfirmation,
    WorkOrderSingleFieldInputComponent,
    LODApplyHistoricalChangesConfirmation,
    InventoryChangesApplyHistoricalChangesConfirmation,
    DeleteAttachmentConfirmationComponent,
    CanvasIdConfirmationComponent,
    WorkOrderReverseConfirmationComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WorkOrdersModule { }
