import { MatSnackBar } from '@angular/material/snack-bar';
import { MeterInventoryChange } from './../models/meter-inventory-change';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {WorkOrderHttpService} from '../../services/work-order-http.service';
import { StringExtensions } from '../../../string-extensions';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class MeterInventoryChangeService {

  constructor(private workorderhttp: WorkOrderHttpService, private snackBar: MatSnackBar) { }

  public saveMeterInventoryChange(workOrderId: string, meterInventoryChange: MeterInventoryChange): Observable<any> {
    return this.workorderhttp.put('/WorkOrder/' + workOrderId + '/meterChanges/' + meterInventoryChange.id + '/save', meterInventoryChange)
      .pipe(
        map(response => response),
        catchError(error=>this.handleError(error))
      );
  }

  public submitMeterChange(meterChange: MeterInventoryChange, workOrderId: string, blockId?: string) {

      if (StringExtensions.StringIsNotEmpty(blockId)) {
        meterChange.newAssignedBlockId = blockId;
      }

      return this.workorderhttp
        .put( '/WorkOrder/' + workOrderId + '/meterChanges/' + meterChange.id + '/submit', meterChange)
        .pipe(
          map(response => response),
          catchError(error=>this.handleError(error))
        );

  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}
