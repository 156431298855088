import { Block } from './block';

export class BlockJsonConverter {
    static jsonToBlock(json: any): Block {
        const toReturn = new Block();
        for (const prop in json) {
            toReturn[prop] = json[prop];
        }

        if (toReturn ['location'] && toReturn['location'].streetDirection != null &&
            toReturn['location'].streetDirection.length > 0) {
            toReturn['location'].streetDirection = toReturn['location'].streetDirection.charAt(0);
        }
        return toReturn;
    }
}
