import { WorkOrderComment } from "./work-order-comment";

export class AbstractResponse {
    id: string;
    responder: string;
    responderRole: string;
    responseDate: Date;
    responseType: string;
    comment: WorkOrderComment;
}
export class WorkOrderResponse extends AbstractResponse {
    workOrderId: string;
    typeOfActionToApprove: string;
    workOrderResponseGroupId: string;
    workOrderResponseGroup: WorkOrderResponseGroup;
    historicalEffectiveDate : Date;
}
export class LetterOfDirectionResponse extends AbstractResponse {
    letterOfDirectionId: number;
}
export class WorkOrderActionResponse extends AbstractResponse {
    workOrderActionId: string;
    workOrderId: string;
}

export class WorkOrderResponseGroup {
  id: string;
  historicalEffectiveDate : Date;
}
export class TypeOfActionToApproveConstants {
  static undo = "Undo";
  static historicalChange = "Historical Change";
}
