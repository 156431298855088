import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrueUpHttpService } from './../../../true-up-http.service';
import { CreateManualCollectionPostBody } from '../models/create-manual-collection-post-body';
import { ReportingHttpService } from '../../../reports/services/reporting-http.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ManualCollectionsService {
  constructor(
    private trueUpDataHttpService: TrueUpHttpService,
    protected errorHandler: ErrorHandler,
    private _reportService: ReportingHttpService,
  ) {}

  public getAllManualCollections(): Observable<any[]> {
    return this.trueUpDataHttpService
      .get('/manualCollections', null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public getManualCollection(collectionId: any, page: number): Observable<any> {
    return this.trueUpDataHttpService
      .get('/manualCollections/' + collectionId + '/page/' + page, null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public postManualCollectionsDistributions(postBody: any): Observable<any> {
    return this.trueUpDataHttpService
      .post('/manualCollections/distributions', postBody)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public saveManualCollectionsDistributions(collection: CreateManualCollectionPostBody): Observable<any> {
    return this.trueUpDataHttpService
      .post('/manualCollections', collection)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public deleteManualCollection(collectionId: string): Observable<any> {
    return this.trueUpDataHttpService
      .put('/manualCollections/delete/' + collectionId, null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public updateManualCollection(collection: any): Observable<any> {
    return this.trueUpDataHttpService
      .put('/manualCollections/update', collection)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public requestReport() {
   return this._reportService
   .post('/collections/manual/export', null)
     .pipe(
       map(response => response),
       catchError(error => this.handleError(error))
     );
  }

  public downloadManualCollectionsReport(key: string) {
    this._reportService.DownloadReport(key, key, 0);
  }

  private handleError(error: any): Promise<any> {
    this.errorHandler.handleError(error);
    return Promise.reject(error.message || error);
  }
}
