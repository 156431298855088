export class PagedResult<T> {
    
      columnSortedBy: string;
    
      count: number;
    
      pageSize: number;
    
      numberOfPages: number;
    
      totalItems: number;
      items :T[]
    }
    