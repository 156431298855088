import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { Injectable } from '@angular/core';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';
import { HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import { TokenCache } from '../../security/token-cache';
import {catchError} from 'rxjs/operators';

@Injectable()
export class WorkOrderReportService extends ReportingHttpService {

  constructor(
    backend: HttpBackend, options: HttpRequestOptions, tokenCache: TokenCache, fileService: FileDownloadService)
  { super(backend, options, tokenCache, fileService); }

  public RequestWorkOrderReport(startDate: any, endDate: any) {

    const start = DateTimeUtility.parseDateString(startDate, 'M/D/YYYY');
    const end = DateTimeUtility.parseDateString(endDate,'M/D/YYYY');

    const endpoint = `/reports/workOrders/${start}/to/${end}`;

    return this.post(endpoint, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  //Correlates to QueueItemTypeMetaData in Reporting API
  public static WORK_ORDER_KEY: string = 'WORK_ORDER';

  public DownloadWorkOrderReport(key: string) {
    return super.DownloadReport(WorkOrderReportService.WORK_ORDER_KEY, key, 0);
  }
}
