import { SecurityConstants, RoleOptionsConstants } from "../../environment";

export class WorkOrderRoleConstants {
  
    static getWorkOrderUserRole(rolesFromToken: Array<String>): string {

        let role = '';
        rolesFromToken.forEach(roleFromToken => {
            if (role === '') {
                if (roleFromToken === SecurityConstants.BackOfficeSupervisor
                  || roleFromToken === SecurityConstants.LAZAdmin
                  || roleFromToken === SecurityConstants.LAZUser
                  || roleFromToken === SecurityConstants.CPMAdmin
                  || roleFromToken === SecurityConstants.CPMUser
                  || roleFromToken === SecurityConstants.CPMElevatedApprover) {
                    role = RoleOptionsConstants.ConcessionaireName.value;
                    return;
                }
                else if (roleFromToken === SecurityConstants.CityApprover
                        || roleFromToken === SecurityConstants.CityUser) {
                    role = RoleOptionsConstants.CityRoleName.value;
                    return;
                }
            }
        });
        return role;
    }
}
