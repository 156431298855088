import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenCache } from './token-cache';
import { LoginService } from './login/login.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private tokenCache: TokenCache, private loginService: LoginService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  private checkLogin(url: string): boolean {
    if (this.tokenCache.tokenIsValid) { return true; }

    // Store the attempted URL for redirecting
    this.loginService.redirectUrl = url;

    // Navigate to the login
    this.router.navigate(['/login']);
    return false;
  }
}
