export class LoadingZoneImpactAssessmentPostBody {
  effectiveDate: Date;

  isPreliminaryAssessment: boolean;

  newRatePackageId: string;

  newFineAmountId: string;

  newParkingTypeId: string;

  newMeterContractType: string;

  originBlockId: string;

  originMeterId: string;

  originSpaceIds: string[];

  removedOriginBlockId: string;

  removedOriginMeterId: string;

  removedOriginSpaceIds: string[];
}


