import { WorkOrder } from './../../../models/work-order';
import { ActionConstants } from './../../action-constants';
import { Validators } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { ActionFormWithValidation } from './action-form-with-validation';
import { InstallMeterConstants } from './../../../action-field/action-field-constants';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { TokenCache } from './../../../../security/token-cache';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { HistoricalMeterForm } from './historical-meter-form';
import { LoadingZoneImpactService } from '../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { ContractTypeConstants, MeterTypeConstants } from '../../../../inventory-management/meters/meter-constants';
import {debounceTime} from 'rxjs/operators';

export class InstallMeterForm extends HistoricalMeterForm {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, loadingZoneImpactService: LoadingZoneImpactService, historicalDate: Date, overrideAsCity: boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, loadingZoneImpactService, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this._blockService = _blockService;
    this.onChanges();
  }
  _blockService: BlockService;

  blockLocationControls = [InstallMeterConstants.BlockAddressBlockRangeStart, InstallMeterConstants.BlockAddressBlockRangeEnd, InstallMeterConstants.StreetDirection, InstallMeterConstants.StreetName, InstallMeterConstants.Suffix];
  blockRangeControls = [InstallMeterConstants.BlockRangeFrom, InstallMeterConstants.BlockRangeTo];
  blockSelectControl = [InstallMeterConstants.BlockIDSelect];
  installSpacesControls = [InstallMeterConstants.RatePackageSelect];

  private onChanges() {
    this.initializeFormForLoadingZoneImpacts(ActionConstants.InstallMeterId);
    if (this.controlExists(InstallMeterConstants.UseExistingBlock)) {
      this.subscribeToControl(InstallMeterConstants.UseExistingBlock).subscribe(value => {

        //initial fire, when value is not set at all
        if(value == null
          || value == undefined){
            this.setValue(InstallMeterConstants.UseExistingBlock, true);
            return;
        }

        //parse the value this way, because sometimes it is a string, and if(value) evaluates true if value = "false" (string)
        var boolValue = Boolean(JSON.parse(value));

        if (boolValue) {
          this.removeBlockControls(this.blockLocationControls);
          this.addAndRequireBlockControls(this.blockSelectControl);
        }
        else {
          this.addAndRequireBlockControls(this.blockLocationControls);
          this.removeBlockControls(this.blockSelectControl);
        }
      });

      if (!this.guidControlExists(WorkorderActionJsonConstants.blockGuid)) {
        this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
      }

      if (!this.guidControlExists(WorkorderActionJsonConstants.meterGuid)) {
        this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
      }

    }

    if (this.controlExists(InstallMeterConstants.MeterType)) {
      this.subscribeToControl(InstallMeterConstants.MeterType).subscribe(value => {
        if (value === MeterTypeConstants.CLZVirtualID.title) {
          this.formGroup.get(this.actionIndex + InstallMeterConstants.PhysicalMeterID.toString()).enable();
          this.formGroup.get(this.actionIndex + InstallMeterConstants.PhysicalMeterID.toString()).setValidators([Validators.required]);
        }
        else {
          this.formGroup.get(this.actionIndex + InstallMeterConstants.PhysicalMeterID).disable();
          this.formGroup.get(this.actionIndex + InstallMeterConstants.PhysicalMeterID.toString()).clearValidators();
        }
      });
    }

    if(!this.isCityUser()) {
      this.subscribeToControl(InstallMeterConstants.PhysicalMeterID).pipe(debounceTime(1500)).subscribe(value => {
        if(this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
          this._meterService.getMeterByDisplayId(value).subscribe(result => {
            this.addGuidControl(WorkorderActionJsonConstants.meterGuid);
            this.setGuidValue(WorkorderActionJsonConstants.meterGuid, result.id);
          });
        }
      });
    }

    if (this.controlExists(InstallMeterConstants.UpdateBlockRange)) {
      this.subscribeToControl(InstallMeterConstants.UpdateBlockRange).subscribe(value => {

        //initial fire, when value is not set at all
        if(value == null
          || value == undefined){
            this.removeBlockControls(this.blockRangeControls);
            return;
        }

        //parse the value this way, because sometimes it is a string, and if(value) evaluates true if value = "false" (string)
        var boolValue = Boolean(JSON.parse(value));

        if (boolValue) {
          this.addAndRequireBlockControls(this.blockRangeControls);
        }
        else {
          this.removeBlockControls(this.blockRangeControls);
        }
      });
    }

    if (this.controlExists(InstallMeterConstants.NumberOfSpaces)) {
      this.subscribeToControl(InstallMeterConstants.NumberOfSpaces).subscribe(value => {
        if (value !== null && value !== undefined && value > 0 && !this.isCityUser()) {
          this.requireInstallSpaceControls(this.installSpacesControls);
        }
        else if (value == null || value === undefined) {
          this.unrequireInstallSpaceControls(this.installSpacesControls);
        }

        if(value == 0) {
          if(this.controlExists(InstallMeterConstants.EUR)) {
            this.disableControl(InstallMeterConstants.EUR);
            this.setAsNotRequired(InstallMeterConstants.EUR);
            this.setValue(InstallMeterConstants.EUR, null);
          }
        }
        else if(this.getControlValue(InstallMeterConstants.ContractType) !== ContractTypeConstants.Reserved.title) {
          this.enableControl(InstallMeterConstants.EUR);
          if(this.isCityUser()) {
            this.setAsRequired(InstallMeterConstants.EUR);
            this.updateValueAndValidity(InstallMeterConstants.EUR);
          }
        }
      });
    }

    if(this.controlExists(InstallMeterConstants.ContractType)) {
      this.subscribeToControl(InstallMeterConstants.ContractType).subscribe(value => {

        if(value == ContractTypeConstants.Reserved.title) {
          if(this.controlExists(InstallMeterConstants.EUR)) {
            this.disableControl(InstallMeterConstants.EUR);
            this.setAsNotRequired(InstallMeterConstants.EUR);
            this.setValue(InstallMeterConstants.EUR, null);
          }
        }
        else if(this.getControlValue(InstallMeterConstants.NumberOfSpaces) != 0) {
          this.enableControl(InstallMeterConstants.EUR);
          if(this.isCityUser()) {
            this.setAsRequired(InstallMeterConstants.EUR);
            this.updateValueAndValidity(InstallMeterConstants.EUR);
          }
        }
      });
    }

    if (this.workOrderActionJsonObject && this.isCityUser() && this.workOrderActionJsonObject[InstallMeterConstants.UseExistingBlock] === 'true') {
      this._blockService.getBlockById(this.workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid]).subscribe(result => {
        let address = '';
        const streetNumber = this.workOrderActionJsonObject[InstallMeterConstants.MeterAddress];
        address = streetNumber + ' ' + result.location.streetDirection + ' ' + result.location.streetName;
        if (result.location.streetSuffix != null) {
          address + ' ' + result.location.streetSuffix;
        }
        this.setValue(InstallMeterConstants.CrossStreetsOrAddressDescription, address);
      });
    }
    else if(this.workOrderActionJsonObject && this.isCityUser() && this.workOrderActionJsonObject[InstallMeterConstants.UseExistingBlock] === 'false') {
      let address = this.workOrderActionJsonObject[InstallMeterConstants.MeterAddress] + ' ' +
      this.workOrderActionJsonObject[InstallMeterConstants.StreetDirection] + ' ' +
      this.workOrderActionJsonObject[InstallMeterConstants.StreetName] + ' ' +
      this.workOrderActionJsonObject[InstallMeterConstants.Suffix];
      this.setValue(InstallMeterConstants.CrossStreetsOrAddressDescription, address);
    }


  }

  private removeBlockControls(controlIds: number[]): void {
    controlIds.forEach(controlId => {
      this.removeControl(controlId);
    });
  }

  private addAndRequireBlockControls(controlIds: number[]): void {
    controlIds.forEach(controlId => {
      this.addControl(controlId, [Validators.required]);
    });
  }

  private unrequireInstallSpaceControls(controlIds: number[]): void {
    controlIds.forEach(controlId => {
      this.formGroup.get(this.actionIndex + controlId.toString()).clearValidators();
    });
  }

  private requireInstallSpaceControls(controlIds: number[]): void {
    controlIds.forEach(controlId => {
      this.formGroup.get(this.actionIndex + controlId.toString()).setValidators([Validators.required]);
    });
  }
}
