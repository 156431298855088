export class MeterCollection {
  dateCreatedUTC: Date;
  
  collectionDate: Date;

  amount: number;

  collectionGuid: string;

  meterGuid: string;

  meterDisplayId: string;

}
