import { Component, OnInit, ErrorHandler } from '@angular/core';
import { MonthlyTrueUpCalculationComponentBase } from '../../monthly-calculations/monthly-true-up-calculation-component-base';
import { MeterTrueUpDetail } from '../meter-true-up-detail';
import { MeterTrueUpDetailService } from '../meter-true-up-detail.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkOrdersService } from '../../../work-orders/services/work-orders.service';

@Component({
  selector: 'meter-true-up-detail-list',
  templateUrl: './meter-true-up-detail-list.component.html',
  styleUrls: ['./meter-true-up-detail-list.component.css']
})
export class MeterTrueUpDetailListComponent extends MonthlyTrueUpCalculationComponentBase<MeterTrueUpDetail> implements OnInit {
  hideFilter: boolean = false;

  constructor(errorHandler: ErrorHandler, service: MeterTrueUpDetailService, workOrderService: WorkOrdersService, public snackBar: MatSnackBar) {
    super(errorHandler, service, workOrderService, snackBar);
  }

  ngOnInit() {
    if (this.filterRepresentation && this.filterRepresentation.MeterId) {
      this.hideFilter = true;
    }

    super.ngOnInit();
  }
}
