import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(value: string): any {

    if (!value) {
      return '';
    }

    const dateValue = DateTimeUtility.parseDateString(value);

    return dateValue;
  }
}