import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkOrderHttpService } from './work-order-http.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class DueDateService {

  constructor(private workorderhttp: WorkOrderHttpService) { }

  public getDueDate(actionTypeId: number): Observable<any> {
    return this.workorderhttp.get('/holiday/actions/' + actionTypeId + '/dueDate/', null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
