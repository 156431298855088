export class Column {
    readable: string;
    constant: string;
}

export class InventoryListColumnDef {
    static meterId: Column = { readable: 'Meter ID', constant: 'meterId' };
    static meterStatus: Column = { readable: 'Meter Status', constant: 'meterStatus' };
    static contractType: Column = { readable: 'Contract Type', constant: 'contractType' };
    static numberOfActiveSpaces: Column = { readable: 'Number of Active Spaces', constant: 'numberOfActiveSpaces' };
    static ratePackage: Column = { readable: 'Rate Package', constant: 'ratePackage' };
    static blockRange: Column = { readable: 'Block Range', constant: 'blockRange' };
    static meterAddress: Column = { readable: 'Meter Address', constant: 'meterAddress' };
    static blockId: Column = { readable: 'Block ID', constant: 'blockId' };
    static streetSide: Column = { readable: 'Side of Street', constant: 'sideOfStreet' };
    static zone: Column = { readable: 'Zone', constant: 'zone' };
    static area: Column = { readable: 'Area', constant: 'area' };
    static activeMetersOnBlock: Column = { readable: 'Active Meters On Block', constant: 'activeMetersOnBlock' };
    static spaceId: Column = { readable: 'Space ID', constant: 'spaceId' };
    static meterIds: Column = { readable: 'Meter IDs', constant: 'meterIds' };
    static spaceStatus: Column = { readable: 'Space Status', constant: 'spaceStatus' };
    static streetName: Column = { readable: 'Street Name', constant: 'streetName' };
    static activeSpacesPhysicallyOnBlock: Column = { readable: 'Active Spaces Physically On Block', constant: 'activeSpacesPhysicallyOnBlock' };
    static ward: Column = { readable: 'Ward', constant: 'ward' };
    static spaceType: Column = { readable: 'Space Type', constant: 'spaceType' };
    static physicalBlockId: Column = { readable: 'Physical Block ID', constant: 'physicalBlockId' };
    static locationType: Column = { readable: 'Location Type', constant: 'locationType' };
    static meterType: Column = { readable: 'Meter Type', constant: 'meterType' };
    static lastExpectedUtilizationRate: Column = { readable: 'Last EUR', constant: 'lastExpectedUtilizationRate'};
    static deleteItem: Column = { readable: 'Delete Item', constant: 'deleteItem' };
    static fineAmount: Column = { readable: 'Fine Amount', constant: 'fineAmount'};
    static hasSignsOnly: Column = { readable: 'Has Signs Only', constant: 'hasSignsOnly'};
    static lastReservedPowerActionDate: Column = { readable: 'Last Reserved Power Action Date', constant: 'lastReservedPowerActionDate'};
    static foundationType: Column = { readable: 'Foundation Type', constant: 'foundationType'};
    static lastUpdateDate: Column = { readable: 'Date Changed', constant: 'lastUpdateDate'};
    static lastUpdatedBy: Column = { readable: 'Last Updated By', constant: 'lastUpdatedBy'};
    static tempRemoved: Column = { readable: 'Temp Removed', constant: 'tempRemoved'};
    static eLockNumber: Column = { readable: 'E-Lock Number', constant: 'eLockNumber'};
    static latitude: Column = { readable: 'Latitude', constant: 'latitude'};
    static longitude: Column = { readable: 'Longitude', constant: 'longitude'};
    static serialNumber: Column = { readable: 'Serial Number', constant: 'serialNumber'};
    static sim: Column = { readable: 'SIM', constant: 'sim'};
    static dateCreated: Column = { readable: 'Date Created', constant: 'dateCreated'};
    static dateActive: Column = { readable: 'Date Active', constant: 'dateActive'};
    static dateRemoved: Column = { readable: 'Date Removed', constant: 'dateRemoved'};
}
