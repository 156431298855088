import { Injectable } from '@angular/core';
import { CanActivate } from "@angular/router";

import { TokenCache } from '../token-cache';

@Injectable()
export class CpmCityOnlyGuard implements CanActivate {
  
  constructor(private tokenCache: TokenCache){}

  canActivate() {
    return this.tokenCache.checkForCPMUser() || this.tokenCache.checkForCityUser();
  }
}
