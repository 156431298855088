import { Meter } from './meter';
import { MeterSummary, MeterPaginationResult } from './meter-summary';

export class MeterJsonConverter {
    static jsonToMeter(json: any): Meter {
        const toReturn = new Meter();
        for (const prop in json) {
            toReturn[prop] = json[prop];
        }
        toReturn['wardName'] = json['ward'];
        if (toReturn['location'] && toReturn['location'].streetDirection != null &&
            toReturn['location'].streetDirection.length > 0) {
            toReturn['location'].streetDirection = toReturn['location'].streetDirection.charAt(0);

            toReturn.meterAddress = `${this.nullCoallesce(toReturn.location.number)} ${this.nullCoallesce(toReturn.location.streetDirection)} ${this.nullCoallesce(toReturn.location.streetName)} ${this.nullCoallesce(toReturn.location.streetSuffix)}`;
        }
        return toReturn;
    }

    static nullCoallesce(input:any):any{
        if(input == null
            || input == undefined){
                return ''
            }
        
        return input;
    }

    static jsonToMeterSummary(json: any): MeterSummary {
        const toReturn = new MeterSummary();
        for (const prop in json) {
            toReturn[prop] = json[prop];
        }

        return toReturn;
    }

    static jsonToMeterSummaries(arrayOfMeters: any): MeterSummary[] {
        const toReturn: MeterSummary[] = [];
        for (let i = 0; i < arrayOfMeters.length; i++) {
            toReturn.push(this.jsonToMeterSummary(arrayOfMeters[i]));
        }
        return toReturn;
    }

    static jsonToMeterPaginationResult(responseJson: any): MeterPaginationResult {

        const meterPaginationResult = new MeterPaginationResult();
        meterPaginationResult.items = this.jsonToMeterList(responseJson['items']);

        meterPaginationResult.columnSortedBy = responseJson['columnSortedBy'];
        meterPaginationResult.count = responseJson['count'];
        meterPaginationResult.pageSize = responseJson['pageSize'];
        meterPaginationResult.numberOfPages = responseJson['numberOfPages'];
        meterPaginationResult.totalItems = responseJson['totalItems'];

        return meterPaginationResult;
    }

    static jsonToMeterList(arrayOfMeters: any): MeterSummary[] {
        const toReturn: MeterSummary[] = [];

        if (arrayOfMeters == null) {
            return toReturn;
        }

        for (let i = 0; i < arrayOfMeters.length; i++) {
            toReturn.push(this.jsonToMeterSummary(arrayOfMeters[i]));
        }
        return toReturn;
    }
}
