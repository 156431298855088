export class WorkOrderGroup {
    public id: string;

    public name: string;

    public dateCreated: string;

    public workOrderDisplayIds: WorkOrderDisplayIdKeyValuePair[] = [];
}

export class WorkOrderDisplayIdKeyValuePair {
    workOrderId: string;
    workOrderDisplayId: string;
}