import { UpdateMeterAttributesSpaceChange } from './update-meter-attributes-space-change';
import { UpdateMeterAttributesMeterChange } from './update-meter-attributes-meter-change';

export class SubmitUpdateMeterAttributesChangesGroup {

  areaDisplayIds: string[];

  blockDisplayIds: string[];

  calculationDateTime: Date;

  changedDateTime: Date;

  effectiveDate: Date;

  meterChanges: UpdateMeterAttributesMeterChange[];

  meterDisplayIds: string[];

  newFineAmountId: string;

  nodeSelection: string;

  changeContractTypeToReserved: boolean;

  newLocationTypeName: string;

  newRequiredClosureAllowanceName: string;

  spaceChanges: UpdateMeterAttributesSpaceChange[];

  workOrderActionId: string;

  workOrderId: string;

  zoneDisplayIds: string[];
}
