import {WorkOrder} from "./models/work-order";
import { AbstractJsonConverter } from "../core/json-converters/abstract-json-convertert";
import { Injectable } from "@angular/core";
import { WorkOrderGroup, WorkOrderDisplayIdKeyValuePair } from "./models/work-order-group";

@Injectable()
export class WorkOrderJsonConverter extends AbstractJsonConverter<WorkOrder>
{
    getInstance():WorkOrder{
        return new WorkOrder();
    }

    jsonToListOfWorkOrderGroups(json: any): WorkOrderGroup[] {
        var toReturn: WorkOrderGroup[] = [];

        if (json == null) {
            return toReturn;
        }

        for (var i = 0; i < json.length; i++){
            var itemJson = json[i];
            var item = new WorkOrderGroup();
            for (let prop in itemJson) {
                if(prop == "workOrderDisplayIds") {
                    itemJson[prop].forEach(keyValuePair => {
                        var displayIdKeyValuePair: WorkOrderDisplayIdKeyValuePair = {
                            workOrderId: keyValuePair.key,
                            workOrderDisplayId: keyValuePair.value
                        };
                        item.workOrderDisplayIds.push(displayIdKeyValuePair);
                    });
                }
                else {
                    item[prop] = itemJson[prop];
                }
            }
            toReturn.push(item);
        }

        return toReturn;
    }
}

