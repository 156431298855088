import { StringExtensions } from './../../../string-extensions';
import { BlockService } from './../../blocks/blocks.service';
import { SpaceService } from './../../spaces/spaces.service';
import { MeterService } from './../../meters/meters.service';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaginationParameters } from '../../../core/models/pagination-paramters';
import { ErrorHandler, Output, EventEmitter } from '@angular/core';
import { InventoryFilterRepresentation } from '../../inventory-filter/inventory-filter-representation';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';


export class InventoryListDataSource extends DataSource<any> {

        constructor(private take: number, private skip: number,
            private _sort: MatSort,
            public paginationParameters: PaginationParameters, private _errorHandler: ErrorHandler,
            private filter: InventoryFilterRepresentation, private newFilter: boolean, private paginator: MatPaginator,
            private _meterService: MeterService, private _spaceService: SpaceService, private _blockService: BlockService, private pageContext: string) {
            super();
        }
        public inventoryItems: any[] = new Array<any>();

        @Output()
        pagerServiceEventEmitter: EventEmitter<PaginationParameters> = new EventEmitter<PaginationParameters>(true);
        @Output()
        inventoryCount: EventEmitter<number> = new EventEmitter<number>(true);
        @Output()
        inventoryListLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(true);

        connect(): Observable<any[]> {
            const displayDataChanges = [
                this.inventoryItems,
                this._sort.sortChange
            ];

            if ((this._sort.direction !== '' &&
                this.paginationParameters.isAscending !== (this._sort.direction === 'asc')) ||
                (this._sort.active !== '' &&
                    this.paginationParameters.orderByColumn !== this._sort.active)) {

                this.paginationParameters.isAscending = (this._sort.direction === 'asc') || (this._sort.direction === '');
                this.paginationParameters.orderByColumn = this._sort.active;
            }

            if (this.newFilter) {
                this.skip = 0;
                if (this.paginator) {
                this.paginator.pageIndex = 0;
                }
            }

            if (this.pageContext.toLowerCase().includes('meter') && !this.pageContext.toLowerCase().includes('space')) {
                return this._meterService.getAllMeters(this.paginationParameters.orderByColumn,
                    this.paginationParameters.isAscending,
                    this.skip,
                    this.take, this.filter)
                    .pipe(
                      map(
                        result => {
                          this.inventoryItems = result.items;
                          if (result == null) {
                            this.inventoryItems = [];
                          }
                          const activeMeters = result.items.filter(meter => StringExtensions.StringIsNotEmpty(meter.meterStatus)
                            && meter.meterStatus.toLowerCase() === 'active');
                          this.inventoryCount.emit(activeMeters.length);
                          this.paginationParameters.totalItemCount = result.totalItems;

                          this.pagerServiceEventEmitter.emit(this.paginationParameters);
                          this.inventoryListLoaded.emit(true);
                          return this.inventoryItems;
                          }),
                      catchError(error => {
                        this.inventoryItems = [];
                        this._errorHandler.handleError(error);
                        this.inventoryListLoaded.emit(true);
                        return this.inventoryItems;
                      })
                    );
            }
            else if (this.pageContext.toLowerCase().includes('space')) {
                return this._spaceService.getAllSpaces(this.paginationParameters.orderByColumn,
                    this.paginationParameters.isAscending,
                    this.skip,
                    this.take, this.filter)
                    .pipe(
                      map(result => {
                        this.inventoryItems = result.items;
                        if (result == null) {
                          this.inventoryItems = [];
                        }

                        this.inventoryCount.emit(result.items.length);
                        this.paginationParameters.totalItemCount = result.totalItems;

                        this.pagerServiceEventEmitter.emit(this.paginationParameters);
                        this.inventoryListLoaded.emit(true);
                        return this.inventoryItems;
                        }),
                      catchError(error => {
                        this.inventoryItems = [];
                        this._errorHandler.handleError(error);
                        this.inventoryListLoaded.emit(true);
                        return this.inventoryItems;
                      })
                    );
            }
            else if (this.pageContext.toLowerCase().includes('block')) {
              if (this.pageContext === 'linked-blocks') {
                return this._blockService.getAllLinkedBlocksForBlockWithFilter(this.paginationParameters.orderByColumn,
                  this.paginationParameters.isAscending,
                  this.skip,
                  this.take, this.filter)
                  .pipe(
                    map(result => {
                      this.inventoryItems = result.filter(block => {
                        return block.id !== this.filter.BlockId;
                      });

                      if (result == null) {
                          this.inventoryItems = [];
                      }

                      this.inventoryCount.emit(this.inventoryItems.length);
                      this.inventoryListLoaded.emit(true);
                      return this.inventoryItems;
                  }),
                  catchError(error => {
                    this.inventoryItems = [];
                    this._errorHandler.handleError(error);
                    this.inventoryListLoaded.emit(true);

                    return this.inventoryItems;
                  })
                  );
              }
              else {
                return this._blockService.getAllBlocks(this.paginationParameters.orderByColumn,
                  this.paginationParameters.isAscending,
                  this.skip,
                  this.take, this.filter)
                  .pipe(
                    map(result => {
                      this.inventoryItems = result.items;
                      if (result == null) {
                          this.inventoryItems = [];
                      }
                      this.inventoryCount.emit(result.items.length);
                      this.paginationParameters.totalItemCount = result.totalItems;

                      this.pagerServiceEventEmitter.emit(this.paginationParameters);
                      this.inventoryListLoaded.emit(true);
                      return this.inventoryItems;
                      }),
                  catchError(error => {
                    this.inventoryItems = [];
                    this._errorHandler.handleError(error);
                    this.inventoryListLoaded.emit(true);

                    return this.inventoryItems;
                  })
                  );
              }
            }
        }
        disconnect() { }
    }
