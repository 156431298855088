exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pad-title {\r\n    padding: 15px;\r\n}\r\n\r\n.table-style {\r\n    overflow-x:auto\r\n}\r\n\r\n.filterButton {\r\n    margin:10px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();