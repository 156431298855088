import {of as observableOf,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {mergeMap, map, debounceTime} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend, HttpResponse } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import { TokenCache } from '../../security/token-cache';
import { environment } from '../../environment';
import { FileDownloadResult } from '../../core/models/file-download-result';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ServiceLoadingResponse } from './service-loading-response';

@Injectable()
export class ReportingHttpService extends HttpClient {
  headers: HttpHeaders;
  postOptions: HttpRequestOptions;

  protected _isLoading = new Subject<ServiceLoadingResponse>();
  public get isLoading() {
    return this._isLoading.asObservable();
  }

  constructor(backend: HttpBackend, options: HttpRequestOptions, private tokenCache: TokenCache, private fileService: FileDownloadService) {
    super(backend);

    this.setHeaders();
    this.tokenCache.onTokenChange.subscribe(() => this.setHeaders());

  }

  public get(url: string, options: any): Observable<any> {
    options = options || new HttpRequestOptions();

    if (!options.headers) {
      options.headers = this.headers;
    }

    options = this.getRequestOption(options);
    url = `${environment.REPORTING_API_BASE}${url}`;
    return super.get(url, options);
  }

  public getFile(url: string, options: any): Observable<any> {
    if (!options) {
      var newHeaders = this.headers;
      newHeaders = newHeaders.append('Accept', 'application/*');

      options = { headers: newHeaders, responseType: 'blob', observe: 'response' };
    }

    url = `${environment.REPORTING_API_BASE}${url}`;

    return super.get(url, options);
  }

  public getS3File(url: string, options: any): Observable<any> {
    if (!options) {
      var newHeaders = this.headers;
      newHeaders = newHeaders.append('Accept', 'application/*');
      newHeaders = newHeaders.delete('Authorization'); //remove this header because our app's auth will confuse AWS
      options = { headers: newHeaders, responseType: 'blob', observe: 'response' };
    }

    return super.get(url, options);
  }

  public getFileWithParams(url: string, body: any, options: any): Observable<Object> {
    if (!options) {
      var newHeaders = this.headers;
      newHeaders = newHeaders.append('Accept', 'application/*');
      options = { headers: newHeaders, responseType: 'blob', observe: 'response'};
    }
    url = `${environment.REPORTING_API_BASE}${url}`;
    return super.put(url, body, options);
  }


  public post(url: string, body: any, options?: any): Observable<any> {
    url = `${environment.REPORTING_API_BASE}${url}`;
    return super.post(url, body, this.getRequestOption(this.postOptions));
  }

  private setHeaders() {
    this.headers = new HttpHeaders({
        'Authorization': this.tokenCache.getBearerToken()
    });

    var option = new HttpRequestOptions();
    option.headers = new HttpHeaders({
      'Authorization': this.tokenCache.getBearerToken(),
      'Content-Type': 'application/json'
    });

    this.postOptions = option;
  }

  public DownloadReport(reportType: string, key: string, timeSinceRetry: number) {
    this._isLoading.next({ loading: true, reportType: reportType});

    var observable = this.AttemptGetFile(reportType, key);

    observable.subscribe(response => {
      if (response instanceof FileDownloadResult) {
        this.fileService.SaveFileToBrowser(response);
        this._isLoading.next({ loading: false, reportType: reportType });
      }
      else if (response.retry && timeSinceRetry < 600000) { // don't try for more than 10 minutes
        timeSinceRetry += 10000;

        // success but no file means not done yet
        setTimeout(() => {
          this.DownloadReport(reportType, key, timeSinceRetry);
        }, 10000);
      }
      else {
        this.handleError('File was not found after 10 minutes, giving up.');
        this._isLoading.next({ loading: false, reportType: reportType, timeout: true});
      }
    },
      error => {
        this.handleError(error);
        this._isLoading.next({ loading: false, reportType: reportType });
      });
      return observable;
  }

  protected GetReportData(reportType: string, key: string, timeSinceRetry: number) {
    this._isLoading.next({ loading: true, reportType: reportType});

    return this.AttemptGetReportData(reportType, key).subscribe(response => {
      if (!response.retry) {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
          const fileData = e.srcElement['result'];
          this._isLoading.next({ loading: false, reportType: reportType, fileData: JSON.parse(fileData) });
        });

        reader.readAsText(response.body);
      }
      else if (response.retry && timeSinceRetry < 600000) { // don't try for more than 10 minutes

        timeSinceRetry += 10000;

        setTimeout(() => {
          this.GetReportData(reportType, key, timeSinceRetry);
        }, 10000);
      }
      else {
        this.handleError('Data was not found after 10 minutes, giving up.');
        this._isLoading.next({ loading: false, reportType: reportType });
      }
    },
      error => {
        this.handleError(error);
        this._isLoading.next({ loading: false, reportType: reportType });
      });
  }

  private AttemptGetFile(reportType: string, key: string): Observable<any> {
    const options = new HttpRequestOptions();
    options.observe = 'response';
    options.responseType = 'json';

    return this.get(`/reports/file/${reportType}/${key}`, options).pipe(
      map<HttpResponse<any>, any>(result => {
        if (result.status === 200) {
          if (result.body.downloadLink) {
            return { retry: false, downloadLink: result.body.downloadLink, fileName: result.body.fileName };
          }
          else {
            this.handleError('File not found.');
            return { retry: false };
          }
        }
        else {
          return { retry: true };
        }
      }),
      mergeMap(response => {
        //just pass through if there is no link
        if (!response.downloadLink) {
          return observableOf(response);
        }

        //if there is a link, download the file at that link
        return this.getS3File(response.downloadLink, null)
          .pipe(
            map((file) => {
              var fileDownload = this.fileService.MapResponseToFileResult(file, response.fileName);
              return fileDownload;
            }));
      })
    );
  }

  private AttemptGetReportData(reportType: string, key: string): Observable<any> {
    return this.getFile(`/reports/report/${reportType}/${key}`, null)
      .pipe(
        map((result) => {
          if (result.status === 200) {
            return result;
          }
          else {
            return { retry: true };
          }
        }));
  };

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  private getRequestOption(option: HttpRequestOptions): any {
    const options = {
      headers: option.headers,
      observe: option.observe,
      params: option.params,
      reportProgress: option.reportProgress,
      responseType: option.responseType,
      withCredentials: option.withCredentials
    };

    return options;
  }
}
