import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'delete-attachment-confirmation-dialog',
    templateUrl: './delete-attachment-confirmation.component.html',
    styleUrls: ['./delete-attachment-confirmation.component.css']
})
export class DeleteAttachmentConfirmationComponent {

    constructor(public dialogRef: MatDialogRef<DeleteAttachmentConfirmationComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

    submit() {
        this.dialogRef.close(true);
    }
}
