import { StringExtensions } from './../../../string-extensions';
import { TokenCache } from './../../../security/token-cache';
import { RatePackageChartUtility } from './rate-package-chart-utility';
import {  DaysOfWeekAbbreviated, DayAbbreviatedDictionary } from './../rate-package-create/rate-package-constants';
import { MonthService } from './../../../core/services/month.service';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


import * as _ from 'lodash';
import * as moment from 'moment';

import { HoursOfRestriction } from './../models/hours-of-restriction';
import { HoursOfOperation } from './../models/hours-of-operation';
import { DateStartEndPair, RatePackage, RatePackageStatistics } from '../models/rate-package';
import { RatePackageDailyDialog } from './../rate-package-daily-dialog/rate-package-daily-dialog';
import { RatePackageService } from '../services/rate-package.service';
import { ScheduleBlocks } from '../models/schedule-blocks';

declare var google: any;

@Component({
  selector: 'app-rate-package',
  templateUrl: './rate-package-detail.component.html',
  styleUrls: ['./rate-package-detail.css']
})
export class RatePackageDetailComponent implements OnChanges {

  constructor(public dialog: MatDialog, private _ratePackageService: RatePackageService, private snackbar: MatSnackBar) {
    this.fuaStats = {weekly: 0, monthly: 0, yearly: 0};
    this.operationStats = {weekly: 0, monthly: 0, yearly: 0};
  }

  @Input() showButtons: boolean = false;
  @Input() context: string; //TODO: is this used for anything?
  @Input() ratePackage: RatePackage;
  ratePackageForDisplay: RatePackage;
  hoursOfOperationForSeasonal: HoursOfOperation;
  hoursOfRestrictionForSeasonal: HoursOfRestriction;

  currentDate = moment();
  currentYear = this.currentDate.year();
  currentMonth = this.currentDate.month() + 1;
  currentMonthAbv = MonthService.getMonthName(this.currentMonth);
  currentDateRange: DateStartEndPair;
  currentDateRangeIndex: number;

  fuaStats: RatePackageStatistics;
  operationStats: RatePackageStatistics;
  yearForDisplayingFua = `${String(this.currentYear).substr(2, 2)}/${String(this.currentYear + 1).substr(2, 2)}`;

  tokenCache = new TokenCache();
  emptyGuid = StringExtensions.EmptyGuid;


  ngOnChanges() {
    if (!this.ratePackage) {
      return;
    }

    this.ratePackageForDisplay = this.deepCopy(this.ratePackage);

    if (this.ratePackageForDisplay.seasonalDateRanges) {
      const seasonalDateRanges = this.ratePackageForDisplay.seasonalDateRanges.filter(dateRange => {
        return dateRange.isSeasonal;
      });

      if (seasonalDateRanges.length > 0) {
        this.createSeasonalLogic();
      }
      else {
        this.setDefaultDateRange();
      }
    }
    else {
      this.setDefaultDateRange();
    }

    this.setHeadersForCurrentRange();

    this.createChartSetup();
  }

  private deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  openDailyDetail(mode) {
    //throws an error if rp not loaded yet
    if (!this.ratePackage || this.ratePackage.fiscalYear === 0) {
      return;
    }

    this.dialog.open(RatePackageDailyDialog, {
      width: '280px',
      height: 'auto',
      data: { ratePackage: this.ratePackage, mode: mode }
    });
  }

  isCityUser(): boolean {
    return this.tokenCache.checkForCityUser();
  }

  setDefaultDateRange() {
    this.currentDateRange = new DateStartEndPair(new Date(this.currentDate.year(), 1, 1), new Date(this.currentDate.year(), 12, 31), false);
    this.resetCurrentMonthHeaders();
  }

  createChartSetup() {
    const rp = this.deepCopy(this.ratePackageForDisplay);
    this.resetStatistics();
    if (this.ratePackageForDisplay.hoursOfOperation) {
      RatePackageChartUtility.generateChart(rp, this.currentDateRange.isSeasonal, this.hoursOfOperationForSeasonal);      
      this.loadStatistics();      
    }
    else {
      RatePackageChartUtility.generateBlankChart();
      this.resetStatistics();
    }
  }

  createSeasonalLogic(): void {
    this.setCurrentSeason();
    this.hoursOfOperationForSeasonal = this.deepCopy(this.ratePackage.hoursOfOperation);
    if (this.hoursOfOperationForSeasonal) {
      this.filterHoursOfOperation();
    }

    if (this.ratePackage.hoursOfRestriction) {
      this.hoursOfRestrictionForSeasonal = this.deepCopy(this.ratePackage.hoursOfRestriction);
      if (this.hoursOfRestrictionForSeasonal) {
        this.filterHoursOfRestriction();
      }
    }
  }

  checkIfSeasonal(): boolean {
    if (this.ratePackageForDisplay && this.ratePackageForDisplay.seasonalDateRanges) {
      const seasonalRangesCount = this.ratePackageForDisplay.seasonalDateRanges.filter(dateRange => {
        return dateRange.isSeasonal;
      }).length;
      return seasonalRangesCount > 0;
    }
    else {
      return false;
    }
  }

  checkIsTodayInCurrentRange(): boolean {
    const today = new Date();
    return ((this.currentDateRange.startDate.getTime() <= today.getTime()) && (today.getTime() <= this.currentDateRange.endDate.getTime()));
  }

  setCurrentSeason() {
    if (this.ratePackageForDisplay.seasonalDateRanges) {
      if (this.ratePackageForDisplay.seasonalDateRanges.length === 1) {
        this.currentDateRange = this.ratePackageForDisplay.seasonalDateRanges[0];
        this.currentDateRange.startDate = new Date(this.currentDateRange.startDate);
        this.currentDateRange.endDate = new Date(this.currentDateRange.endDate);
      }
      else {
        const dateRangeWithOpenDates = new Array<DateStartEndPair>();
      if (this.ratePackageForDisplay.seasonalDateRanges[0].startDate !== new Date(this.currentDate.year(), 1, 1)) {
          dateRangeWithOpenDates.push(new DateStartEndPair(new Date(this.currentDate.year(), 1, 1), this.ratePackageForDisplay.seasonalDateRanges[0].startDate, this.ratePackageForDisplay.seasonalDateRanges[0].isSeasonal));
        }
        else if (this.ratePackageForDisplay.seasonalDateRanges[0].endDate.getTime() === this.ratePackageForDisplay.seasonalDateRanges[1].startDate.getTime() && this.ratePackageForDisplay.seasonalDateRanges[1].startDate !== new Date(this.currentDate.year(), 12, 31)) {
          dateRangeWithOpenDates.push(new DateStartEndPair(this.ratePackageForDisplay.seasonalDateRanges[1].endDate, this.ratePackageForDisplay.seasonalDateRanges[0].startDate, this.ratePackageForDisplay.seasonalDateRanges[0].isSeasonal));
        }
        else if (this.ratePackageForDisplay.seasonalDateRanges[1].startDate !== new Date(this.currentDate.year(), 31)) {
          dateRangeWithOpenDates.push(new DateStartEndPair(this.ratePackageForDisplay.seasonalDateRanges[1].endDate, new Date(this.currentDate.year(), 12, 31), this.ratePackageForDisplay.seasonalDateRanges[1].isSeasonal));
        }

        const today = new Date();
        this.ratePackageForDisplay.seasonalDateRanges.forEach(dateRange => {
          dateRange.startDate = new Date(dateRange.startDate);
          dateRange.endDate = new Date(dateRange.endDate);
          if ((dateRange.startDate.getTime() <= today.getTime()) && (today.getTime() <= dateRange.endDate.getTime())) {
            this.currentDateRange = dateRange;
          }
        });
      }
    }
  }

  updateSeason() {
    this.filterHoursOfOperation();
    if (this.ratePackageForDisplay.hoursOfRestriction) {
      this.filterHoursOfRestriction();
    }
    this.ratePackage = this.ratePackage; // TODO: what is this for???

    this.setHeadersForCurrentRange();

    this.createChartSetup();
  }

  setHeadersForCurrentRange() {
    if (this.checkIsTodayInCurrentRange()) {
      this.resetCurrentMonthHeaders();
    }
    else {
      this.ratePackageForDisplay.dateRequested = this.currentDateRange.startDate;
      this.currentMonthAbv = MonthService.getMonthName(this.currentDateRange.startDate.getMonth() + 1);
      this.yearForDisplayingFua = `${String(this.currentDateRange.startDate.getFullYear()).substr(2, 2)}/${String(this.currentDateRange.startDate.getFullYear() + 1).substr(2, 2)}`;
    }
  }

  resetCurrentMonthHeaders() {
    this.ratePackageForDisplay.dateRequested = null;
    this.currentMonthAbv = MonthService.getMonthName(this.currentMonth);
    this.yearForDisplayingFua = `${String(this.currentYear).substr(2, 2)}/${String(this.currentYear + 1).substr(2, 2)}`;
  }

  filterHoursOfOperation() {
    this.hoursOfOperationForSeasonal = this.deepCopy(this.ratePackage.hoursOfOperation);
    let containsSeasonalHoO = false;
    this.hoursOfOperationForSeasonal.weeklyDetail.forEach(dailyDetail => {
      const index = this.hoursOfOperationForSeasonal.weeklyDetail.indexOf(dailyDetail);
      this.ratePackageForDisplay.hoursOfOperation.weeklyDetail[index].value.scheduleDetails = dailyDetail.value.scheduleDetails.filter(scheduleBlock => {
        if (scheduleBlock.seasonalDayStart || scheduleBlock.seasonalHolidayStart) {
          containsSeasonalHoO = true;
        }
        if (this.currentDateRange.isSeasonal) {
          if (scheduleBlock.seasonalDayStart || scheduleBlock.seasonalHolidayStart) {
            const startDateConverted = new Date(scheduleBlock.seasonalRateStart);
            const endDateConverted = new Date(scheduleBlock.seasonalRateEnd);

            const startDate = new Date(this.currentDate.year(), startDateConverted.getMonth(), startDateConverted.getDate());
            const endDate = new Date(this.currentDate.year(), endDateConverted.getMonth(), endDateConverted.getDate());
            return startDate.getTime() <= this.currentDateRange.startDate.getTime() && this.currentDateRange.startDate.getTime() <= endDate.getTime();
          }
        }
        return !scheduleBlock.seasonalDayStart && !scheduleBlock.seasonalHolidayStart;
      }, this);
    }, this);

    if (!containsSeasonalHoO) {
      this.ratePackageForDisplay.hoursOfOperation.weeklyDetail = this.deepCopy(this.ratePackage.hoursOfOperation.weeklyDetail);
    }
  }

  filterHoursOfRestriction() {
    this.hoursOfRestrictionForSeasonal = this.deepCopy(this.ratePackage.hoursOfRestriction);
    this.hoursOfRestrictionForSeasonal.weeklyDetail.forEach(dailyDetail => {
      const index = this.hoursOfRestrictionForSeasonal.weeklyDetail.indexOf(dailyDetail);
      this.ratePackageForDisplay.hoursOfRestriction.weeklyDetail[index].value.scheduleDetails = dailyDetail.value.scheduleDetails.filter(scheduleBlock => {
        if (this.currentDateRange.isSeasonal) {
          if (scheduleBlock.seasonalDayStart || scheduleBlock.seasonalHolidayStart) {
            const startDate = new Date(this.currentDate.year(), new Date (scheduleBlock.seasonalRateStart).getMonth(), new Date(scheduleBlock.seasonalRateStart).getDay());
            const endDate = new Date(this.currentDate.year(), new Date (scheduleBlock.seasonalRateEnd).getMonth(), new Date(scheduleBlock.seasonalRateEnd).getDay());
            return startDate.getTime() <= this.currentDateRange.startDate.getTime() && this.currentDateRange.startDate.getTime() <= endDate.getTime();
          }
        }
        else {
          return !scheduleBlock.seasonalDayStart && !scheduleBlock.seasonalHolidayStart;
        }
      }, this);
    }, this);
  }

  resetStatistics() {
    this.fuaStats.weekly = 0;
    this.fuaStats.monthly = 0;
    this.fuaStats.yearly = 0;
    this.operationStats.weekly = 0;
    this.operationStats.monthly = 0;
    this.operationStats.yearly = 0;
  }

  loadStatistics() {
    
    let queryMonth = this.currentMonth;
    let queryYear = this.currentYear;

    if (!this.checkIsTodayInCurrentRange()) {
      queryMonth = this.currentDateRange.startDate.getMonth() + 1;
      queryYear = this.currentDateRange.startDate.getFullYear();
    }

    this._ratePackageService.getWeeklyUtilization(this.ratePackageForDisplay).subscribe(
        result => {
          if (!result || result === 0 || !result.entries) {
            this.fuaStats.weekly = 0;
            return;
          }

          const sum = _.sumBy(Array.from(result.entries()), function(o: any) {
            return o[1];
          });
          this.fuaStats.weekly = sum;
        },
        error => {
          console.log(error);
          this.fuaStats.weekly = 0;
        });

    this._ratePackageService.getMonthlyUtilization(this.ratePackageForDisplay, queryYear, queryMonth).subscribe(
        result => {
          this.fuaStats.monthly = result;
        },
        error => {
          console.log(error);
          this.fuaStats.monthly = 0;
        });

    this._ratePackageService.getYearlyFUA(this.ratePackageForDisplay, this.ratePackageForDisplay.fiscalYear).subscribe(
        result => {
          this.fuaStats.yearly = result.utilizationAmount;
        },
        error => {
          console.log(error);
          this.fuaStats.yearly = 0;
        }
      );

    this._ratePackageService.getWeeklyPeriodOfOperation(this.ratePackageForDisplay).subscribe(
        result => {
          this.operationStats.weekly = result / 60;
        },
        error => {
          console.log(error);
          this.operationStats.weekly = 0;
        });

    this._ratePackageService.getMonthlyPeriodOfOperation(this.ratePackageForDisplay, queryYear, queryMonth).subscribe(
        result => {
          this.operationStats.monthly = result / 60;
        },
        error => {
          console.log(error);
          this.operationStats.monthly = 0;
        });

    this._ratePackageService.getYearlyPeriodOfOperation(this.ratePackageForDisplay, this.ratePackageForDisplay.fiscalYear).subscribe(
        result => {
          this.operationStats.yearly = result / 60;
        },
        error => {
          console.log(error);
          this.operationStats.yearly = 0;
        });
  }

  updateIsInactive() {
    this.snackbar.open((!this.ratePackageForDisplay.isInactivated ? 'Inactivating' : 'Activating') + ' Rate Package...', '');
    this._ratePackageService.updateRatePackageIsInactive(this.ratePackageForDisplay.id, !this.ratePackageForDisplay.isInactivated)
    .subscribe(result => {
      this.ratePackageForDisplay.isInactivated = !this.ratePackageForDisplay.isInactivated;
      this.snackbar.open((this.ratePackageForDisplay.isInactivated ? 'Inactivated' : 'Activated') + ' Rate Package ' + this.ratePackageForDisplay.name, 'X');
    },
    error => {
      this.snackbar.open('Could not ' + (!this.ratePackageForDisplay.isInactivated ? 'Inactivate' : 'Activate') + ' Rate Package: ' + error, 'X');
    });
  }
}
