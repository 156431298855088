
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { ChangeRatePackageConstants } from './../../../action-field/action-field-constants';
import { ActionFormWithValidation } from '../forms/action-form-with-validation';
import { MeterService } from '../../../../inventory-management/meters/meters.service';

export function ChangeRatePackageTooManySpacesValidator(numSpacesOnMeter: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    const numberOfRemainingSpaces = numSpacesOnMeter - Number.parseInt(control.value);
    return numberOfRemainingSpaces < 0 ? { ChangeRatePackageTooManySpaces : true} : null;
  };
}

export function ChangeRatePackageMoreThanZeroSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    return Number.parseInt(control.value) <= 0 ? { ChangeRatePackageMoreThanZeroSpaces : true} : null;
  };
}

export function ChangeRatePackageIsValidForMeterValidator(formGroup: FormGroup, component: ActionFormWithValidation, _meterService: MeterService): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any } => {
    if(formGroup.get(component.actionIndex + ChangeRatePackageConstants.EntireMeter).value === false && formGroup.get(component.actionIndex + WorkorderActionJsonConstants.meterGuid).value && formGroup.get(component.actionIndex + WorkorderActionJsonConstants.ratePackageGuid).value) {
      return _meterService.validateNewRatePackage(formGroup.get(component.actionIndex + WorkorderActionJsonConstants.meterGuid).value, formGroup.get(component.actionIndex + WorkorderActionJsonConstants.ratePackageGuid).value).pipe(map(result => {
        return (result.isValidForMeter === false) ? { ChangeRatePackageInvalidForMeter : true } : null;
      }))
    }
    else {
      return observableOf(null);
    }
  }

}

