import { distinctUntilChanged,  debounceTime } from 'rxjs/operators';
import { WorkorderActionJsonConstants } from './../../action-field/workorder-action-json-constants';
import { BlockService } from './../../../inventory-management/blocks/blocks.service';
import { MeterInventoryChange } from './../models/meter-inventory-change';
import { InventoryFilterRepresentation } from './../../../inventory-management/inventory-filter/inventory-filter-representation';
import { InventorySelectDialogComponent } from './../../action-field/inventory-select-dialog/inventory-select-dialog.component';
import { MeterService } from './../../../inventory-management/meters/meters.service';
import { MatDialog } from '@angular/material/dialog';
import { ParkingTypeConstants, MeterTypeConstants, ContractTypeConstants, ClosureAllowanceConstants, MerchantConstants,
  FoundationTypeConstants } from './../../../inventory-management/meters/meter-constants';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Meter } from '../../../inventory-management/meters/meter';
import { StringExtensions } from '../../../string-extensions';
import { WorkOrderAction } from '../../models/work-order-action';
import { ActionConstants } from '../../action/action-constants';
import { Subject } from 'rxjs';
import { NumberAttributeConstraintsType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

@Component({
  selector: 'app-work-order-meter-inventory',
  templateUrl: './work-order-meter-inventory.component.html',
  styleUrls: ['./work-order-meter-inventory.component.css']
})
export class WorkOrderMeterInventoryComponent implements OnInit {

  meterInventoryForm: FormGroup;
  selectedMeterInput: number;
  sideOfStreet: string;
  selectedMeter: Meter;
  displayMeterExistsError: boolean;
  meterIdValidated: boolean;
  meterTypeConstants = MeterTypeConstants;
  previousMeterSelected = false;
  inventoryChangeEffectiveDate?: Date;
  static clzConstant = MeterTypeConstants.CLZVirtualID;

  @Input() meterChanges: any[];
  @Input() meterId: string;
  @Input() meterInventoryChange: MeterInventoryChange;
  @Input() workOrderAction: WorkOrderAction;
  @Input() historicalEffectiveDate: Date;

  locationTypeDropdown = [ParkingTypeConstants.OnStreet, ParkingTypeConstants.ParkingLot, ParkingTypeConstants.CLZ, ParkingTypeConstants.WrigleyvilleEventParking];
  meterTypeDropdown = [MeterTypeConstants.MP104, MeterTypeConstants.CLZVirtualID, MeterTypeConstants.SSM, MeterTypeConstants.CWT];
  contractTypeDropdown = [ContractTypeConstants.Concession, ContractTypeConstants.Reserved];
  requiredClosureAllowanceDropDown = [ClosureAllowanceConstants.CBD, ClosureAllowanceConstants.NonCBD];
  merchantDropDown = MerchantConstants.AllMerchants;
  foundationTypeDropDown = [FoundationTypeConstants.Drill, FoundationTypeConstants.Pad];

  newMeterIdToConfirm = new Subject<string>();
  previousMeterIdToConfirm = new Subject<string>();

  constructor(
    private formBuilder: FormBuilder, public dialog: MatDialog, public meterService: MeterService, public _blockService: BlockService) {
      this.meterInventoryForm = this.formBuilder.group({
        enteredMeterId: void 0,
        previouslyRemovedMeterId: void 0
      });
     }

  ngOnInit() {

    //debounce these so we don't hit api like crazy when people type
    this.previousMeterIdToConfirm
      .pipe(debounceTime(1000)).pipe(
    distinctUntilChanged())
    .subscribe(id => {
      if(this.selectedMeterInput !== 2){
        return;
      }
      this.previouslyRemovedMeterIdChanged(id);
    });

    this.newMeterIdToConfirm
      .pipe(debounceTime(1000)).pipe(
      distinctUntilChanged())
      .subscribe(id =>{
        if(this.selectedMeterInput !== 1){
          return;
      }
      this.confirmMeterIdDoesNotExist();
    });

var constant = MeterTypeConstants.CLZVirtualID;

    this.buildForm();
    if (this.meterInventoryChange === null || this.meterInventoryChange === undefined || (this.meterInventoryChange && this.meterInventoryChange.status === 'Completed') || this.workOrderAction.actionId.toString() === ActionConstants.InactivateMeterId.toString()
        || this.workOrderAction.actionId.toString() === ActionConstants.ReactivateMeterId.toString() || this.workOrderAction.actionId.toString() === ActionConstants.ChangeContractTypeToConcessionId.toString()) {
      this.meterInventoryChange = new MeterInventoryChange();
      this.inventoryChangeEffectiveDate = this.meterInventoryChange.effectiveDate;
      if (this.meterId) {
        this.meterService.getMeterById(this.meterId, this.historicalEffectiveDate)
        .subscribe(result => {
          this.selectedMeter = result;
          this.meterInventoryChange.zoneDisplayId = result.zone;
          this.meterInventoryChange.areaDisplayId = result.area;
          this.meterInventoryChange.blockDisplayId = result.blockDisplayId;
          this.meterInventoryChange.meterDisplayId = result.displayId;
          this.updateFormCompletion(true);
          },
          error => {
            this.updateFormCompletion(false);
          });
      }
    }

    if (this.meterInventoryChange !== null && this.meterInventoryChange !== undefined && this.workOrderAction.actionId.toString() === ActionConstants.InstallMeterId.toString()) {
      if (this.meterInventoryChange.meterId === StringExtensions.EmptyGuid) {
        this.meterInventoryForm.get('enteredMeterId').setValue(this.meterInventoryChange.meterDisplayId);
        this.meterInventoryForm.get('previouslyRemovedMeterId').disable();
        this.previousMeterSelected = false;
        this.selectedMeterInput = 1;
        this.updateFormCompletion(false);
      }
      else if (this.meterInventoryChange.meterDisplayId) {
        this.meterService.getMeterByDisplayId(this.meterInventoryChange.meterDisplayId, this.historicalEffectiveDate).subscribe(result => {
          this.meterInventoryForm.get('previouslyRemovedMeterId').setValue(this.meterInventoryChange.meterDisplayId);
          this.meterInventoryForm.get('enteredMeterId').disable;
          this.previousMeterSelected = true;
          this.selectedMeterInput = 2;
          this.meterInventoryChange.zoneDisplayId = result.zone;
          this.meterInventoryChange.areaDisplayId = result.area;
          this.meterInventoryChange.blockDisplayId = result.blockDisplayId;
          this.meterInventoryChange.meterDisplayId = result.displayId;
          this.updateFormCompletion(true);
        },
        error => {
          this.meterInventoryForm.get('enteredMeterId').setValue(this.meterInventoryChange.meterDisplayId);
          this.meterInventoryForm.get('previouslyRemovedMeterId').disable();
          this.previousMeterSelected = false;
          this.selectedMeterInput = 1;
          this.updateFormCompletion(false);
        });
      }
      else {
        //default case, we have nothing
        this.meterInventoryForm.get('enteredMeterId').setValue(this.meterInventoryChange.meterDisplayId);
        this.meterInventoryForm.get('previouslyRemovedMeterId').disable();
        this.previousMeterSelected = false;
        this.selectedMeterInput = 1;
        this.updateFormCompletion(false);

        //unclear why this is here
        if (this.workOrderAction.workOrderActionJson) {
          const workOrderActionJsonObject = JSON.parse(this.workOrderAction.workOrderActionJson);

          if(workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid]){
            this._blockService.getBlockById(workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid])
            .subscribe(result => {
              this.meterInventoryChange.zoneDisplayId = result.zoneName;
              this.meterInventoryChange.areaDisplayId = result.areaName;
              this.meterInventoryChange.blockDisplayId = result.displayId;
            });
          }
        }
      }
    }

    this.mapSideOfStreet();
  }

  mapSideOfStreet(){
    if(!this.meterInventoryChange.newAssignedBlockId) {
      if(this.workOrderAction.blockInventoryChanges.length > 0) {
        this.sideOfStreet = this._blockService.calculateSideOfStreet(this.workOrderAction.blockInventoryChanges[0].streetDirection, this.meterInventoryChange.streetNumber);
      }
    }
    else {
      this._blockService.getBlockById(this.meterInventoryChange.newAssignedBlockId).subscribe(result => {
        this.sideOfStreet = result.location.sideOfStreet;
      });
    }
  }

  openMeterSelectDialog(): void {
    const dialogRef = this.dialog.open(InventorySelectDialogComponent, {
      width: '1300px',
      maxWidth: '1300px',
      height: '90%',
      data: {context: 'workOrderMeterInventory'}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.meterService.getMeterById(result.id)
        .subscribe((meter: Meter) => {
          this.previousMeterSelected = true;

          this.selectedMeter = meter;
          this.meterInventoryChange.meterId = meter.id;
          this.meterInventoryChange.meterDisplayId = meter.displayId;
          this.meterInventoryChange.zoneDisplayId = result.zoneName;
          this.meterInventoryChange.areaDisplayId = result.areaName;
          this.meterInventoryChange.blockDisplayId = result.blockName;
          this.meterInventoryChange.streetNumber = result.meterStreetNumber;
          this.meterInventoryChange.latitude = result.latitude;
          this.meterInventoryChange.longitude = result.longitude;
          this.updateFormCompletion(true);

          const locationType = this.locationTypeDropdown.filter(locationType => {
            return locationType.title === meter.parkingType;
          });
          this.meterInventoryChange.newParkingTypeId = locationType[0].value;
          const meterType = this.meterTypeDropdown.filter(meterType => {
            return meterType.title === meter.meterType;
          });
          this.meterInventoryChange.newMeterTypeId = meterType[0].value;
          const contractType = this.contractTypeDropdown.filter(contractType => {
            return contractType.title === meter.currentContractType;
          });
          this.meterInventoryChange.newContractTypeId = contractType[0].value;

          const requiredClosureAllowance = this.requiredClosureAllowanceDropDown.filter(rca => {
            return rca.title === meter.requiredClosureAllowanceName;
          });

          if (requiredClosureAllowance.length > 0) {
            this.meterInventoryChange.newClosureAllowanceId = requiredClosureAllowance[0].value;
          }

          const merchantId = this.merchantDropDown.filter(merchant => {
            return merchant.title === meter.merchantAccount;
          });
          if (merchantId.length > 0) {
            this.meterInventoryChange.newMerchantId = merchantId[0].value;
          }

          const foundationType = this.foundationTypeDropDown.filter(type => {
            return type === meter.foundationType;
          });
          if (foundationType.length > 0) {
            this.meterInventoryChange.newFoundationType = foundationType[0];
          }

          this.meterInventoryForm.get('previouslyRemovedMeterId').setValue(result.displayId);
        });
      }
      else {
        this.meterInventoryChange.meterId = StringExtensions.EmptyGuid;
        this.meterInventoryChange.meterDisplayId = null;
        this.updateFormCompletion(false);
      }
    });
  }


  queueMeterIdForConfirmation(){
    this.newMeterIdToConfirm.next(this.meterInventoryChange.meterDisplayId);
  }


  confirmMeterIdDoesNotExist() {
    this.meterService.getMeterByDisplayId(this.meterInventoryChange.meterDisplayId, this.historicalEffectiveDate)
      .pipe(debounceTime(1000))
      .subscribe(result => {
      if (result) {
        this.displayMeterExistsError = true;
        this.updateFormCompletion(false);
      }
      else {
        this.displayMeterExistsError = false;
        this.updateFormCompletion(true);
      }
    },
    error => {
      if(error.status && error.status === 404){
        //404 is ok, means no meter exists with that id
        this.displayMeterExistsError = false;
        this.updateFormCompletion(true);
      }
      else{
        this.displayMeterExistsError = true;
        this.updateFormCompletion(false); //this should not be complete if there was an error
      }
    });
  }

  queuePreviousMeterIdForConfirmation(displayId){
    this.previousMeterIdToConfirm.next(displayId);
  }

  previouslyRemovedMeterIdChanged(displayId) {
    this.meterService.getMeterByDisplayId(displayId)
    .subscribe(result => {
      if (result) {
        this.meterInventoryChange.meterId = result.id;
        this.meterInventoryChange.meterDisplayId = result.displayId;
        const locationType = this.locationTypeDropdown.filter(locationType => {
          return locationType.title === result.parkingType;
        });
        this.meterInventoryChange.newParkingTypeId = locationType[0].value;
        const meterType = this.meterTypeDropdown.filter(meterType => {
          return meterType.title === result.meterType;
        });
        this.meterInventoryChange.newMeterTypeId = meterType[0].value;

        const requiredClosureAllowance = this.requiredClosureAllowanceDropDown.filter(rca => {
          return rca.title === result.requiredClosureAllowanceName;
        });

        if (requiredClosureAllowance.length > 0) {
          this.meterInventoryChange.newClosureAllowanceId = requiredClosureAllowance[0].value;
        }

        const merchantId = this.merchantDropDown.filter(merchant => {
          return merchant.title === result.merchantAccount;
        });
        if (merchantId.length > 0) {
          this.meterInventoryChange.newMerchantId = merchantId[0].value;
        }
        const foundationType = this.foundationTypeDropDown.filter(type => {
          return type === result.foundationType;
        });
        if (foundationType.length > 0) {
          this.meterInventoryChange.newFoundationType = foundationType[0];
        }

        this.updateFormCompletion(true);
      }
      else {
        this.meterInventoryChange.meterId = null;
        this.meterInventoryChange.meterDisplayId = null;

        this.updateFormCompletion(false);
      }
    }, error => {

        //any error means there is something wrong with the meter we are trying to use
        this.meterInventoryChange.meterId = null;
        this.meterInventoryChange.meterDisplayId = null;

        this.updateFormCompletion(false);
    });
  }

  buildForm() {
    this.meterInventoryForm = this.formBuilder.group({
      enteredMeterId: void 0,
      previouslyRemovedMeterId: void 0
    });

    this.meterInventoryForm.addControl('enteredMeterId', new FormControl);
    this.meterInventoryForm.addControl('previouslyRemovedMeterId', new FormControl);
    this.meterInventoryForm.addControl('meterStreetNumber', new FormControl);
    this.meterInventoryForm.addControl('meterType', new FormControl);
    this.meterInventoryForm.addControl('locationType', new FormControl);
    this.meterInventoryForm.addControl('contractType', new FormControl);
    this.meterInventoryForm.addControl('closureAllowance', new FormControl);
    this.meterInventoryForm.addControl('latitude', new FormControl);
    this.meterInventoryForm.addControl('longitude', new FormControl);
    this.meterInventoryForm.addControl('merchant', new FormControl);
    this.meterInventoryForm.addControl('foundationType', new FormControl);

    this.meterInventoryForm.get('enteredMeterId').valueChanges.subscribe((displayId: string) => {
      this.meterInventoryChange.meterDisplayId = displayId;
    });

    this.meterInventoryForm.valueChanges.subscribe(() => {
      //form must be looking good, and also all meter id checks are good
      this.updateFormCompletion();
    });
  }

  updateFormCompletion(setMeterValid:boolean = null){
    if(setMeterValid != null && setMeterValid != undefined){
      this.meterIdValidated = setMeterValid;
    }
    this.workOrderAction.meterInventoryChangesFormComplete = this.meterInventoryForm.valid && this.meterIdValidated;
  }

  changeMeterIdSelection() {
    this.meterInventoryChange.meterId = StringExtensions.EmptyGuid;
    this.previousMeterSelected = false;

    if(this.selectedMeterInput === 1){
      this.meterInventoryForm.get('previouslyRemovedMeterId').setValue(null);
      this.meterInventoryForm.get('enteredMeterId').enable();
      this.meterInventoryForm.get('previouslyRemovedMeterId').disable();
      this.previousMeterSelected = false;
      this.updateFormCompletion(false);
    }
    else{
      this.meterInventoryForm.get('enteredMeterId').setValue(null);
      this.meterInventoryForm.get('enteredMeterId').disable();
      this.meterInventoryForm.get('previouslyRemovedMeterId').enable();
      this.previousMeterSelected = true;
      this.updateFormCompletion(false);
    }
  }

}
