import { Component, ErrorHandler, OnInit, OnDestroy } from '@angular/core';
import { ReservedPowerReportingService } from '../../services/reserved-power-reporting.service';
import { FileDownloadService } from '../../../core/file-download/file-download.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ReservedPowerColumns } from '../reserved-power-columns';

@Component({
  selector: 'app-space-reserved-power',
  templateUrl: './space-reserved-power.component.html',
  styleUrls: ['./space-reserved-power.component.css']
})
export class SpaceReservedPowerComponent implements OnInit, OnDestroy {

  reportLoading: boolean;
  subscriptions: Subscription[] = [];
  tableLoading: boolean;
  dataSource: MatTableDataSource<any>;

  displayedColumns = ReservedPowerColumns.SpaceReservedPowerColumns;

  constructor(private _reportingService: ReservedPowerReportingService,
    private _fileDownloader: FileDownloadService,
    private _errorHandler: ErrorHandler) { }


  ngOnInit() {
    this.subscriptions.push(this._reportingService.isLoading
      .subscribe(response => {
        if (response.reportType === ReservedPowerReportingService.SPACE_KEY) {
          this.reportLoading = response.loading;
        }
        else if (response.reportType === ReservedPowerReportingService.SPACE_DATA_KEY) {
          this.tableLoading = response.loading;
          if (!this.tableLoading) {
            this.dataSource = new MatTableDataSource<any>(response.fileData);
            this.tableLoading = false;
          }
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  spaceWorkOrderRequested(event: Date) {
    this._reportingService.RequestSpaceMonthlyReservedPowerReport(event)
      .subscribe(result => {
        this._reportingService.DownloadSpaceReport(result.result);
      },
      error => {
        this._errorHandler.handleError(error);
      });
  }

  spaceTableDataRequested(event: Date) {
    this.tableLoading = true;
    this._reportingService.RequestSpaceMonthlyReservedPowerData(event)
      .subscribe(result => {
        this._reportingService.DownloadSpaceReportData(result.result);
      },
      error => {
        this._errorHandler.handleError(error);
      });
  }  

}
