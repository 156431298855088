import { Injectable, ErrorHandler } from '@angular/core';

import { QueryStringBuilder } from '../../core/query-strings/query-string-builder';
import { Observable } from 'rxjs';
import { PagedResult } from '../../core/models/paged-result';
import { SpaceTrueUpDetail } from './space-true-up-detail';
import { SpaceTrueUpDetailDataSource } from './space-true-up-detail-datasource';
// import { errorHandler } from '@angular/platform-browser/src/browser';
import { AbstractJsonConverter } from '../../core/json-converters/abstract-json-convertert';
import { TrueUpDetailJsonConverter } from './../true-up-detail-json-converter';
import { MonthlyTrueUpCalculationServiceBase } from '../monthly-calculations/monthly-true-up-calculation-service-base';
import { MonthlyTrueUpCalculationFilterRepresentation } from '../monthly-calculations/monthly-true-up-calculation-filter-representation-base';
import { SpaceTrueUpDetailJsonConverter } from './space-true-up-detail-json-converter';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { RatePackageService } from '../../inventory-management/rate-packages/services/rate-package.service';
import { StringExtensions } from '../../string-extensions';
import { CachedRatePackages } from '../cached-rate-package';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ReportingHttpService } from '../../reports/services/reporting-http.service';

@Injectable()
export class SpaceTrueUpDetailService extends MonthlyTrueUpCalculationServiceBase<SpaceTrueUpDetail>  {

  cachedRatePackages: CachedRatePackages[] = [];

  getAllItems(orderByColumn: string, isAscending: boolean, skip: number, top: number, filter: MonthlyTrueUpCalculationFilterRepresentation): Observable<PaginatedGetResult<SpaceTrueUpDetail>> {
    return super.getAll(orderByColumn, isAscending, skip, top, filter);
  }

  constructor(trueUpDataHttpService: ReportingHttpService, errorHandler: ErrorHandler, trueUpDetailJsonConverter: SpaceTrueUpDetailJsonConverter,
     private ratePackageService: RatePackageService, private spaceFileDownloadService: FileDownloadService) {
    super(trueUpDataHttpService,errorHandler,trueUpDetailJsonConverter, spaceFileDownloadService);
  }

  GetEndpoint(): string {
    return '/SpaceMonthlyTrueUpCalculations';
  }

  GetReportType(): string {
    return 'SPACE_TRUEUP';
  }

  AddNodeSpecificQueryStringParts(queryString: string, filter: MonthlyTrueUpCalculationFilterRepresentation): string {

    var spaceFilter = filter as MonthlyTrueUpCalculationFilterRepresentation;
    if (spaceFilter.ShouldFilterByMeterId) {
      queryString = QueryStringBuilder.AddBasedOnFilterOperator(queryString, 'meterId', spaceFilter.MeterIdFilterOperator, spaceFilter.MeterId, null);
    }
    if (spaceFilter.ShouldFilterBySpaceId) {
      queryString = QueryStringBuilder.AddBasedOnFilterOperator(queryString, 'spaceId', spaceFilter.SpaceIdFilterOperator, spaceFilter.SpaceId, null);
    }
    return QueryStringBuilder.PrependFilterQueryStringVariableName(queryString);
  }
}
