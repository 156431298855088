exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".unauthorized {\r\n    padding-top: 75px;\r\n}\r\n\r\n.material-icons {\r\n    color: #607d8b;\r\n    font-size:350%;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();