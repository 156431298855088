exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "a {\r\n  cursor: pointer;\r\n}\r\n\r\n.material-icons {\r\n  font-size: 16px;\r\n}\r\n\r\n.pagination a {\r\n  color: black;\r\n  float: left;\r\n  margin: 16px 16px 16px 16px;\r\n  padding: 8px 8px 8px 8px;\r\n  text-decoration: none;\r\n  -webkit-transition: background-color .3s;\r\n  transition: background-color .3s;\r\n}\r\n\r\n.pagination a.active {\r\n  background-color: #4CAF50;\r\n  color: white;\r\n}\r\n\r\n.pagination a:hover:not(.active) {\r\n  background-color: #ddd;\r\n}\r\n\r\n.mat-header-row {\r\n  border-top: .5px solid lightgray;\r\n}\r\n\r\n.lightgray {\r\n  color:lightgray\r\n}\r\n\r\n.selected-row {\r\n  background-color: #CFD8DC;\r\n  cursor: pointer;\r\n}\r\n\r\n.mat-expansion-panel-header {\r\n  max-height:48px;\r\n}\r\n\r\n.list-container {\r\n  height: 800px;\r\n  overflow: auto;\r\n}\r\n\r\n.select-container {\r\n  height: 360px;\r\n  overflow: auto;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n}\r\n\r\n.list-table {\r\n  max-height: none !important;\r\n}\r\n\r\n.app-loading {\r\n  position: absolute;\r\n  background-color: white;\r\n  left: 46%;\r\n  padding: 20px;\r\n  z-index: 1;\r\n}\r\n\r\nmat-header-cell.small-cell, mat-header-cell.large-cell {\r\n  -webkit-box-align: center !important;\r\n      -ms-flex-align: center !important;\r\n          align-items: center !important;\r\n}\r\n\r\n.mat-table {\r\n  padding: 0px 20px;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();