export class WorkorderActionJsonConstants {
    static meterGuid = 'MeterGUID';
    static meterDisplayId = 'meterDisplayId';
    static ratePackageGuid = 'ratePackageGUID';
    static ratePackageDisplayId = 'ratePackageDisplayId';
    static blockGuid = 'blockGuid';
    static blockDisplayId = 'blockDisplayId';
    static spaceGuid = 'SpaceGUID';
    static meterFromDisplayId = '510';
    static meterFromGuid = 'meterFromGuid';
    static meterToDisplayId = '520';
    static meterToGuid = 'meterToGuid';
    static blockFromGuid = 'blockFromGuid';
    static blockToGuid = 'blockToGuid';
    static physicalBlockGuid = 'physicalBlockGuid';
    static totalNumberOfSpacesOnMeter = 'totalNumberOfSpacesOnMeter';
    static numberOfActiveSpacesOnMeter = 'numberOfActiveSpacesOnMeter';

    static totalNumberOfSpacesOnToMeter = 'totalNumberOfSpacesOnToMeter';
    static totalNumberOfSpacesOnFromMeter= 'totalNumberOfSpacesOnFromMeter';

    static ratePackageReversalModels = 'ratePackageReversalModels';
    static ratePackageReversalDate = 'ratePackageReversalDate';
}
