export class InventoryTimelineConstants {
    public static BlankReversibility = 'Blank';
    public static PendingRVA = 'Pending RVA';
    public static RatePackageReversibility = 'Rate Package Reversibility';
    public static EUR = 'Expected Utilization Rate';
    public static CLZ = 'CLZ Impact';
    public static LocationType = 'Location Type';
    public static RequiredClosureAllowance = 'Required Closure Allowance';
    public static FineAmount = 'Fine Amount';
    public static StatusChange = 'Status Change';
    public static MeterStatus = 'Meter Status';
    public static MeterAssignment = 'Meter Assignment';
    public static ContractType = 'Contract Type';
    public static RatePackageReservedPower = 'Rate Package Reserved Power';
    public static RegularRateAdjustment = 'Regular Rate Adjustment';
    public static ReverseRatePackage = 'Reverse Rate Package';
}
