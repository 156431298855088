// import { Request } from '@angular/http';
import { WorkOrderFilterRepresentation } from '../work-order-filter/work-order-filter-representation';
import { QueryStringBuilder, FilterOperator } from '../../core/query-strings/query-string-builder';
import { DateTimeUtility } from '../../inventory-management/meters/meter-true-up/models/month-name-dictionary';

export class WorkOrderQueryStringBuilder {
    static createFilterQueryString(evt: WorkOrderFilterRepresentation): string {

        let filterQueryString = '';

        if (evt.FilterByActionType) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'actionType', FilterOperator.Equal, evt.ActionType);
        }
        if (evt.FilterByAssignedUserRole) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'assignedTo', FilterOperator.Equal, evt.AssignedUserRole);
        }
        if (evt.FilterByAreaId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'areaDisplayId', FilterOperator.Contains, `${evt.AreaId}`);
        }
        if (evt.FilterByZoneId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'zoneDisplayId', FilterOperator.Contains, `${evt.ZoneId}`);
        }
        if (evt.FilterByBlockId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'blockDisplayId', FilterOperator.Contains, `${evt.BlockId}`);
        }
        if (evt.FilterByContractType) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'contractType', FilterOperator.Equal, `${evt.ContractType}`);
        }
        if (evt.FilterByCurrentStatus) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'currentStatus', FilterOperator.Equal, `${evt.CurrentStatus}`);
        }
        else if (evt.FilterByInProgress) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'inProgress', FilterOperator.Equal, `${evt.InProgress}`);
        }
        if (evt.FilterByMeterId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'meterDisplayId', FilterOperator.Contains, `${evt.MeterId}`);
        }
        if (evt.FilterByRequesterUserRole) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'requester', FilterOperator.Equal, `${evt.RequesterUserRole}`);
        }
        if (evt.FilterByWorkOrderId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'displayId', FilterOperator.Equal, `${evt.WorkOrderId}`);
        }
        if (evt.FilterByWorkOrderCanvasId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'workOrderCanvasId', FilterOperator.Contains, evt.WorkOrderCanvasId);
        }
        if (evt.FilterByWorkOrderGroupId) {
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'workOrderGroupId', FilterOperator.Equal, evt.WorkOrderGroupId);
        }
        if (evt.FilterByTempApprovalStatus){
          filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'tempApprovalStatus', FilterOperator.Equal, evt.TempApprovalStatus);
        }

        if (evt.OriginalRequestDate) {
            evt.OriginalRequestDateDisplayString = DateTimeUtility.parseDateString(evt.OriginalRequestDate);
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'originalRequestDate', FilterOperator.Equal, evt.OriginalRequestDateDisplayString);
        }
        if (evt.ProjectedCompletionDate) {
            evt.ProjectedCompletionDateDisplayString = DateTimeUtility.parseDateString(evt.ProjectedCompletionDate);
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'projectedCompletionDate', FilterOperator.Equal, evt.ProjectedCompletionDateDisplayString);
        }
        if (evt.RevisedRequestDate) {
            evt.RevisedRequestDateDisplayString = DateTimeUtility.parseDateString(evt.RevisedRequestDate);
            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'revisedRequestDate', FilterOperator.Equal, evt.RevisedRequestDateDisplayString);
        }

        if (evt.DueDate) {
            evt.DueDateDisplayString = DateTimeUtility.parseDateString(evt.DueDate);
            evt.DueDateRangeEndDisplayString = DateTimeUtility.parseDateString(evt.DueDateRangeEnd);

            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'dueDate', evt.DueDateRangeFilterOperator, evt.DueDateDisplayString, evt.DueDateRangeEndDisplayString);
        }
        if (evt.CompletionDate) {
            evt.CompletionDateDisplayString = DateTimeUtility.parseDateString(evt.CompletionDate);
            evt.CompletionDateRangeEndDisplayString = DateTimeUtility.parseDateString(evt.CompletionDateRangeEnd);

            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'completionDate', evt.CompletionDateRangeFilterOperator, evt.CompletionDateDisplayString, evt.CompletionDateRangeEndDisplayString);
        }
        if (evt.EffectiveDate) {
            evt.EffectiveDateDisplayString = DateTimeUtility.parseDateString(evt.EffectiveDate);
            evt.EffectiveDateRangeEndDisplayString = DateTimeUtility.parseDateString(evt.EffectiveDateRangeEnd);

            filterQueryString = QueryStringBuilder.AddBasedOnFilterOperator(filterQueryString, 'effectiveDate', evt.EffectiveDateRangeFilterOperator, evt.EffectiveDateDisplayString, evt.EffectiveDateRangeEndDisplayString);
        }

        return QueryStringBuilder.PrependFilterQueryStringVariableName(filterQueryString);
    }
}
