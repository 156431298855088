exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".buttons {\r\n    padding: 15px;\r\n}\r\n\r\n.cancel-dialog {\r\n    padding: 15px;\r\n    font-size:150%;\r\n    padding-top:25px;\r\n}\r\n\r\n.pad {\r\n  padding-left: 15px;\r\n  padding-right: 15px;\r\n}\r\n\r\n.inventory-list{\r\n    overflow-y: scroll;\r\n    max-height: 240px;\r\n}\r\n\r\n.mat-form-field {\r\n    width: 400px;\r\n}\r\n\r\n.material-icons{\r\n    padding: 15px;\r\n    font-size: 300%;\r\n    color: red;\r\n}\r\n\r\n.error{\r\n    font-style: italic;\r\n    color: red;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();