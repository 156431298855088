import { RatePackage } from '../models/rate-package';
import { MatSort } from '@angular/material/sort';
import { Injectable } from '@angular/core';
import { ColumnSorter } from '../../../column-sorter';

@Injectable() 
export class RatePackageSorter extends ColumnSorter {  
    getSortedData(ratePackages: RatePackage[], sort: MatSort): RatePackage[] {

            const data = ratePackages;

            if (!sort.active || sort.direction === '') {
                return data.sort((left, right) => {
                    return this.CompareStrings(left.name,right.name,sort);               
                })
            }

            return data.sort((left, right) => {

                let leftSortValue: number|string = '';
                let rightSortValue: number|string = '';

                switch (sort.active) {
                    case 'Name':  
                        if(left.name != null && right.name != null)
                        {
                            [leftSortValue, rightSortValue] = [parseInt(left.name), parseInt(right.name)]; 
                            return this.CompareNumbers(+leftSortValue,+rightSortValue, sort);
                        }
                        else
                        {
                            [leftSortValue, rightSortValue] = [left.name, right.name];   
                            return this.CompareStrings(String(leftSortValue), String(rightSortValue), sort);
                        }
                    case 'Description': 
                        [leftSortValue, rightSortValue] = [left.description, right.description]; 
                        return this.CompareStrings(String(leftSortValue), String(rightSortValue), sort);
                }
            });
    }
}
