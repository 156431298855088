import { AbstractJsonConverter } from "../core/json-converters/abstract-json-convertert";
import { SpaceTrueUpDetail } from "./space-true-up-detail/space-true-up-detail";
import { Injectable } from "@angular/core";
import { PaginatedGetResult } from "../core/models/paginated-get-result";
import { MonthlyTrueUpCalculationDetailBase } from './monthly-calculations/monthly-true-up-calculation-detail-base';
import { DateTimeUtility } from "../inventory-management/meters/meter-true-up/models/month-name-dictionary";

@Injectable()
export abstract class TrueUpDetailJsonConverter<T> extends AbstractJsonConverter<T> {

  jsonToTrueUpDetail(paginatedResult: PaginatedGetResult<MonthlyTrueUpCalculationDetailBase>, responseJson: any) 
  {    
    paginatedResult.items.forEach(trueUpDetail =>
    {
      trueUpDetail.calendarMonth = DateTimeUtility.parseMonth(trueUpDetail.forMonth);
      trueUpDetail.calendarYear = DateTimeUtility.parseYear(trueUpDetail.forMonth);
      trueUpDetail.displayReportingQuarter = 
                `Q${trueUpDetail.reportingQuarter} ${trueUpDetail.reportingYear}`;
    });
  }

  jsonToBaseTrueUpDetail(trueUpDetail: MonthlyTrueUpCalculationDetailBase) 
  {    
      trueUpDetail.calendarMonth = DateTimeUtility.parseMonth(trueUpDetail.forMonth);
      trueUpDetail.calendarYear = DateTimeUtility.parseYear(trueUpDetail.forMonth);
      trueUpDetail.displayReportingQuarter = 
                `Q${trueUpDetail.reportingQuarter} ${trueUpDetail.reportingYear}`;
  }

  abstract jsonToLevelSpecificDetail(responseJson: any): PaginatedGetResult<T>; 

}
