import { DateTimeUtility } from './../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDateTime'
})
export class UtcDateTimePipe implements PipeTransform {

  transform(value: string): any {

    if (!value) {
      return '';
    }

    const dateValue = DateTimeUtility.parseDateTimeString(value);

    return dateValue;
  }
}
