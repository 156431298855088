import { AnnualPercentageAdjustmentValueService } from './../services/annual-percentage-adjustment-value-service';
import { AnnualPercentageAdjustmentValue, AnnualPercentageAdjustmentValueToSave, AnnualPercentageAdjustmentValueToUpdate } from './../annual-percentage-adjustment-value';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ErrorHandler } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemStartTimeService } from '../../../system-start-time.service';

@Component({
  selector: 'edit-annual-percentage-adjustment-value-dialog',
  templateUrl: './edit-annual-percentage-adjustment-value.html',
  styleUrls: ['./edit-annual-percentage-adjustment-value.css']
})
export class EditAnnualPercentageAdjustmentValue implements OnInit {
  constructor(public dialogRef: MatDialogRef<EditAnnualPercentageAdjustmentValue>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private formBuilder: FormBuilder, private _annualPercentageAdjustmentValueService: AnnualPercentageAdjustmentValueService,
    private _startTimeService: SystemStartTimeService, private _errorHandler: ErrorHandler) {
    this.annualPercentageAdjustmentValueForm = this.formBuilder.group({
      fiscalStartYear: void 0,
      percentage: void 0
    });
  }

  annualPercentageAdjustmentValue: AnnualPercentageAdjustmentValue;
  annualPercentageAdjustmentValueForm: FormGroup;
  yearOptions: number[] = [];

  ngOnInit() {
    this.annualPercentageAdjustmentValue = this.data.param;

    this._startTimeService.getInitialSystemStartTime().subscribe(result => {
      for (let i = result.getFullYear(); i <= new Date().getFullYear() + 1; i++) {
        const yearUsed = this.data.allAnnualPercentageAdjustmentValues.filter(param => {
          return (new Date(param.fiscalStartYear).getFullYear().toString() === i.toString());
        }).length > 0;

        if (!yearUsed || (this.annualPercentageAdjustmentValue && new Date(this.annualPercentageAdjustmentValue.fiscalStartYear).getFullYear() === i)) {
          this.yearOptions.push(i);
        }
      }
    },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
      });

    this.buildForm();
  }

  buildForm() {
    this.annualPercentageAdjustmentValueForm = this.formBuilder.group({
      fiscalStartYear: void 0,
      percentage: void 0
    });

    // Prepopulate if applicable
    if (this.annualPercentageAdjustmentValue) {
      const fiscalStartYear = new Date(this.annualPercentageAdjustmentValue.fiscalStartYear).getUTCFullYear();
      this.annualPercentageAdjustmentValueForm.get('fiscalStartYear').setValue(fiscalStartYear);
      this.annualPercentageAdjustmentValueForm.get('percentage').setValue(this.annualPercentageAdjustmentValue.percentage);
    }
  }

  save() {
    let apaToSave;
    if (!this.annualPercentageAdjustmentValue) {
      apaToSave = new AnnualPercentageAdjustmentValueToSave(Number.parseFloat(this.annualPercentageAdjustmentValueForm.get('percentage').value), this.annualPercentageAdjustmentValueForm.get('fiscalStartYear').value);
    }
    else {
      apaToSave = new AnnualPercentageAdjustmentValueToUpdate(this.annualPercentageAdjustmentValue.id, Number.parseFloat(this.annualPercentageAdjustmentValueForm.get('percentage').value), this.annualPercentageAdjustmentValueForm.get('fiscalStartYear').value);
    }

    this._annualPercentageAdjustmentValueService.saveAnnualPercentageAdjustmentValue(apaToSave)
      .subscribe(result => {
        this.snackBar.open('Saved System Level Parameter', '', <MatSnackBarConfig>{
          duration: 2000,
        });

        this.dialogRef.close(true);
      },
        error => {
          console.log(error); this.snackBar.open(error['_body'], 'X');
        });
  }
}
