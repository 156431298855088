import { FormGroup } from '@angular/forms';
import { LoadingZoneImpactComponent } from './../../../inventory-management/loading-zone-impact/loading-zone-impact.component';
import { WorkorderActionJsonConstants } from './../../action-field/workorder-action-json-constants';
import { LoadingZoneImpactService } from './../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Component, OnChanges, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable ,  forkJoin ,  zip ,  of } from 'rxjs';

import { BlockService } from './../../../inventory-management/blocks/blocks.service';
import { MeterService } from './../../../inventory-management/meters/meters.service';
import { StringExtensions } from './../../../string-extensions';
import { MeterInventoryChange } from './../models/meter-inventory-change';
import { BlockInventoryChange } from './../models/block-inventory-change';
import { SpaceInventoryChangeService } from './../services/space-inventory-change-service';
import { BlockInventoryChangeService } from './../services/block-inventory-change-service';
import { MeterInventoryChangeService } from './../services/meter-inventory-change-service';
import { InventoryChangesApplyHistoricalChangesConfirmation } from './inventory-changes-apply-historical-changes-confirmation/inventory-changes-apply-historical-changes-confirmation.component';
import { WorkOrderAction } from './../../models/work-order-action';
import { WorkOrder, WorkOrderStatusType } from './../../models/work-order';
import { SpaceInventoryChange } from '../models/space-inventory-change';
import { ActionConstants } from './../../action/action-constants';
import { InstallMeterConstants } from './../../action-field/action-field-constants';
import { InventoryChangeStatusType } from '../models/inventory-change-base';
import { LetterOfDirection } from '../../models/letter-of-direction';
import { WorkOrderActionService } from '../../services/work-order-action.service';

import { CalculationsCompleteService } from '../../../trueup/calculations-complete-service/calculations-complete-service';
import { InventoryChangesService } from '../services/inventory-changes-service';
import { WorkOrderActionStatusConstants, WorkOrderStatusConstants } from '../../work-order-constants';
import {TempApprovalStatusTypes} from '../../models/work-order';
import { TokenCache } from '../../../security/token-cache';
import { DateTimeUtility } from '../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import * as moment from 'moment';
import { UUID } from 'angular2-uuid';
import { WorkOrdersService } from '../../services/work-orders.service';
import { LoadingZoneImpactAssessmentPostBody } from '../../models/loading-zone-impacts/loading-zone-impact-assessment-body';
import { InventoryFilterRepresentation } from '../../../inventory-management/inventory-filter/inventory-filter-representation';
import { Meter } from '../../../inventory-management/meters/meter';
import { LoadingZoneImpactChangeGroup, LoadingZoneImpactChange } from '../../models/loading-zone-impacts/loading-zone-impact-change-group';
import { ContractTypeConstants } from '../../../inventory-management/meters/meter-constants';
import { String } from 'aws-sdk/clients/acm';
import {catchError, map} from 'rxjs/operators';

@Component({
  selector: 'app-inventory-changes',
  templateUrl: './inventory-changes.component.html',
  styleUrls: ['./inventory-changes.component.css']
})
export class InventoryChangesComponent implements OnChanges, OnDestroy {
  @Input() workOrderActions: WorkOrderAction[];
  @Input() workOrder: WorkOrder;

  @Output() changeToDescriptionTab: EventEmitter<boolean> = new EventEmitter<boolean>();

  public changedDateTime: Date;

  public spaceChangesToSubmit: SpaceInventoryChange[];
  public showInventoryChangesTab: boolean;

  public blockReadyForSubmission = true;
  public meterReadyForSubmission = true;
  public spacesReadyForSubmission = true;
  public lodHasHistoricalChange = false;
  public currentLetterOfDirection: LetterOfDirection;
  public ActionConstants = ActionConstants;
  public workOrderIsNotTrueUpFinalized: boolean;
  public effectiveDate: Date;
  public isUserCity: boolean;
  public loading;
  public meter: Meter;
  totalNumberOfChanges: number;
  numberOfChangesComplete: number;
  numberOfFailedChanges: number;
  numberOfImpactsAssessed: number;
  potentialLoadingZoneImpactChanges: LoadingZoneImpactChangeGroup[];
  submissionLoadingZoneImpactChanges: LoadingZoneImpactChangeGroup[];

  potentialCLZImpactActionTypes = [ActionConstants.InstallMeterId, ActionConstants.InstallSpaceId, ActionConstants.ChangeRatePackageId, ActionConstants.UpdateMeterAttributesId, ActionConstants.RemoveSpaceId];
  statusTimer: any;
  isDestroyed: boolean = false;

  constructor(
    public _spaceInventoryChangeService: SpaceInventoryChangeService,
    public _blockInventoryChangeService: BlockInventoryChangeService,
    public _meterInventoryChangeService: MeterInventoryChangeService,
    public _loadingZoneImpactService: LoadingZoneImpactService,
    public _meterService: MeterService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private calculationsCompleteService: CalculationsCompleteService,
    private workOrderActionService: WorkOrderActionService,
    private _blockService: BlockService,
    private inventoryChangesService: InventoryChangesService,
    private tokenCache: TokenCache,
    private workOrderService: WorkOrdersService
  ) {}

  ngOnDestroy(){
    if(this.statusTimer){
      clearTimeout(this.statusTimer);
      this.isDestroyed = true;
    }
  }

  ngOnChanges() {
    this.isUserCity = this.tokenCache.checkForCityUser();
    if (this.workOrder !== undefined && this.workOrder !== null) {
      this.showInventoryChangesTab =
        this.workOrder.currentStatusId !== WorkOrderStatusType.ReadyForReviewId
        && this.workOrder.currentStatusId !== WorkOrderStatusType.DraftedId;

      this.currentLetterOfDirection = this.workOrder.currentLetterOfDirection;

      this.workOrderActions.forEach(workOrderAction => {
        this.intializeWorkOrderAction(workOrderAction);
      });
    }

    this.checkIfWorkOrderIsNotTrueUpFinalized();

    if (this.workOrder.currentStatusId === WorkOrderStatusType.BulkChangesQueuedId) {
      this.loading = true;
      this.endLoadingWhenWorkOrderStatusChanges(this, 2000, WorkOrderStatusType.BulkChangesQueuedId);
    }
    if (this.workOrder.currentStatusId ===  WorkOrderStatusType.AssessingLoadingZoneImpactId) {
      this.loading = true;
      this.endLoadingWhenWorkOrderStatusChanges(this, 2000, WorkOrderStatusType.AssessingLoadingZoneImpactId);
    }

  }

  private intializeWorkOrderAction(workOrderAction: WorkOrderAction) {

    if (this.currentLetterOfDirection !== null && this.currentLetterOfDirection.historicalEffectiveDate) {
      this.lodHasHistoricalChange = true;
      workOrderAction.effectiveDate = this.currentLetterOfDirection.historicalEffectiveDate;
      this.effectiveDate = workOrderAction.effectiveDate;
    }

    workOrderAction.blockChangesExist = !!(workOrderAction.blockInventoryChanges && workOrderAction.blockInventoryChanges.length);
    workOrderAction.meterChangesExist = !!(workOrderAction.meterInventoryChanges && workOrderAction.meterInventoryChanges.length);
    workOrderAction.spaceChangesExist = !!(workOrderAction.spaceInventoryChanges && workOrderAction.spaceInventoryChanges.length);
    workOrderAction.showActivateButton = !!(this.showInventoryChangesTab
                                            && this.workOrder.currentStatus !== WorkOrderStatusType.CancelledName
                                            && this.workOrder.currentStatus !== WorkOrderStatusType.CompletedName
                                            && this.workOrder.currentStatus !== WorkOrderStatusType.UndoneName);

    const { blockInventoryChanges, meterInventoryChanges, spaceInventoryChanges } = workOrderAction;
    workOrderAction.allFormsReadOnly = this.areAllInventoryChangesCompleted(blockInventoryChanges, meterInventoryChanges, spaceInventoryChanges);

    if (workOrderAction.meterChangesExist && !workOrderAction.blockChangesExist) {
      workOrderAction.meterInventoryChanges.forEach(meterInventoryChange => {
        this.updateBlockInfoForMeterChange(meterInventoryChange);
      });
    }

      if (workOrderAction.meterId || workOrderAction.actionId === ActionConstants.InactivateMeterId) {
        if (workOrderAction.actionId === ActionConstants.InactivateMeterId && workOrderAction.meterInventoryChanges[0]) {
          this._meterService.getMeterById(workOrderAction.meterInventoryChanges[0].meterId)
          .subscribe(meter => {
            this.meter = meter;
            workOrderAction.meterId = meter.id;
          });
        }
        else {
          this._meterService.getMeterById(workOrderAction.meterId)
          .subscribe(meter => {
            this.meter = meter;
            workOrderAction.meterId = meter.id;
          });
        }
      }

    if (workOrderAction.actionId.toString() === ActionConstants.ShiftSpaceId.toString()) {
      const { meterFromGuid, meterToGuid } = JSON.parse(workOrderAction.workOrderActionJson);
      workOrderAction.meterId = meterFromGuid;
      workOrderAction.meterToId = meterToGuid;

      this._meterService.getMeterById(workOrderAction.meterToId)
        .subscribe(meter => {
          this.meter = meter;
        });
    } else {
      if (workOrderAction.spaceInventoryChanges[0]) {
          workOrderAction.meterId = workOrderAction.spaceInventoryChanges[0].meterId;
      }
    }

    if (workOrderAction.actionId.toString() === ActionConstants.RelocateMeterId.toString()) {
      workOrderAction.blockToId = JSON.parse(workOrderAction.workOrderActionJson)[WorkorderActionJsonConstants.blockToGuid];
    }

    if (workOrderAction.actionId.toString() === ActionConstants.InstallMeterId.toString()) {
      workOrderAction.contractType = JSON.parse(workOrderAction.workOrderActionJson)[InstallMeterConstants.ContractType];
    }

  }

  private areAllInventoryChangesCompleted(...changeTypes: Array<Array<BlockInventoryChange|MeterInventoryChange|SpaceInventoryChange>>) {
    if (!changeTypes) { return true; }

    const changes = [].concat(...changeTypes);
    for (let i = 0, change; change = changes[i]; ++i) {
      if (change.status !== InventoryChangeStatusType.Completed) { return false; }
    }

    return true;
  }

  displayWorkOrderMeterInventoryForm(workOrderAction: WorkOrderAction): boolean {
    if (workOrderAction.actionId !== ActionConstants.InstallMeterId) {
      return false;
    }
    const incompleteMeterInventoryChanges = workOrderAction.meterInventoryChanges.filter(meterChange => {
      return meterChange.status !== 'Completed';
    });

    return incompleteMeterInventoryChanges.length > 0;
  }

  displayBlockReadOnlyInformation(workOrderAction: WorkOrderAction): boolean {
    return (this.getIncompleteBlockChanges(workOrderAction).length === 0 || workOrderAction.meterInventoryChanges.length === 0)
      && !this.actionUsesInventoryDrilldown(workOrderAction);
  }

  displayBlockInventoryChanges(workOrderAction: WorkOrderAction): boolean {
    return !this.actionUsesInventoryDrilldown(workOrderAction)
        && this.getIncompleteBlockChanges(workOrderAction).length > 0;
  }

  displaySaveAndProjectedCompletionDate(workOrderAction: WorkOrderAction): boolean {
    return !workOrderAction.allFormsReadOnly
         && !this.actionUsesInventoryDrilldown(workOrderAction);
  }

  displaySpaceChanges(workOrderAction: WorkOrderAction): boolean {
    return !this.actionUsesInventoryDrilldown(workOrderAction)
        && workOrderAction.spaceInventoryChanges.length > 0;
  }

  actionUsesInventoryDrilldown(workOrderAction): boolean {
    return workOrderAction.actionId === ActionConstants.RegularRateAdjustmentId
    || workOrderAction.actionId === ActionConstants.UpdateMeterAttributesId
    || workOrderAction.actionId === ActionConstants.UpdateMeterAttributesChangeContractTypeId;
  }

  private updateBlockInfoForMeterChange(meterInventoryChange: MeterInventoryChange) {
    if (meterInventoryChange.newAssignedBlockId) {
      this._blockService
        .getBlockById(meterInventoryChange.newAssignedBlockId)
        .subscribe(result => {
          meterInventoryChange.newAssignedAreaName = result.areaName;
          meterInventoryChange.newAssignedBlockName = result.displayId;
          meterInventoryChange.newAssignedZoneName = result.zoneName;
        });
    }
  }

  public confirm() {
    const goesToCityForApproval = this.workOrder.currentLetterOfDirection.workOrderActions.find(action => {
      return action.actionId !== ActionConstants.UpdateMeterAttributesId;
    });
    const dialogRef = this.dialog.open(InventoryChangesApplyHistoricalChangesConfirmation, {
      width: '500px',
      height: 'auto',
      data: {
        workOrderId: this.workOrder.id,
        approveHistoricalChange: !goesToCityForApproval
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeToDescriptionTab.emit(true);
      }
    });
}

  public save(action: WorkOrderAction) {
    this.changedDateTime = moment.utc(new Date()).toDate();

    var obz: Observable<any>[] = [];

    if (action.blockChangesExist) {
      obz = obz.concat(this.saveBlockInventoryChanges(action.blockInventoryChanges));
    }

    if (action.meterChangesExist) {
      obz = obz.concat(this.saveMeterInventoryChanges(action.meterInventoryChanges));
    }

    if (action.spaceChangesExist) {
      obz = obz.concat(this.saveSpaceInventoryChanges(action.spaceInventoryChanges));
    }

    zip(...obz)
    .subscribe(
      results => {

        let foundError = false;

        results.forEach(result =>{
          if(!result.errorBody){
            this.openSnackBarOnSuccess('Saved ' + result.inventoryChangeClass + ' Change for Work Order ' + this.workOrder.displayId, false);
          }
          else{
            const error = JSON.parse(result.errorBody);
            this.handleErrorForSubmission(error, result.inventoryChangeClass, 'saving');
          }
        });

        if(!foundError){
          this.openSnackBarOnSuccess('Saved All Changes for Work Order ' + this.workOrder.displayId, true);
        }
        else{
          this.openSnackBarOnSuccess('Encountered error while saving all changes for Work Order ' + this.workOrder.displayId, true);
        }
     });


  }

  private saveBlockInventoryChanges(blockInventoryChanges: Array<BlockInventoryChange>):Observable<any>[] {

    var obz: Observable<any>[] = [];

    blockInventoryChanges
      .filter(change => change.status !== InventoryChangeStatusType.Completed)
      .forEach(blockInventoryChange => {
        blockInventoryChange.changedDateTime = this.changedDateTime;
        let o = this._blockInventoryChangeService
          .saveBlockInventoryChange(this.workOrder.id, blockInventoryChange)
          .pipe(
            map(r => {
              r['inventoryChangeClass'] = 'Block';
              return r;
            }),
            catchError( error => {
              return of({
                errorBody: error['_body'],
                inventoryChangeClass: 'Block'
              });
            })
          );

          obz.push(o);
      });

      return obz;
  }

  private saveMeterInventoryChanges(meterInventoryChanges: Array<MeterInventoryChange>):Observable<any>[] {

    var obz: Observable<any>[] = [];

    meterInventoryChanges
      .filter(change => change.status !== InventoryChangeStatusType.Completed)
      .forEach(meterInventoryChange => {
        meterInventoryChange.changedDateTime = this.changedDateTime;
        let o = this._meterInventoryChangeService
          .saveMeterInventoryChange(this.workOrder.id, meterInventoryChange)
          .pipe(
            map(r => {
              r['inventoryChangeClass'] = 'Meter';
              return r;
          }),
          catchError( error => {
            return of({
              errorBody: error['_body'],
              inventoryChangeClass: 'Meter'
            });
          })
          );

          obz.push(o);
      });

      return obz;
  }

  private saveSpaceInventoryChanges(spaceInventoryChanges: Array<SpaceInventoryChange>):Observable<any>[] {

    var obz: Observable<any>[] = [];

    spaceInventoryChanges.filter(change => change.status !== InventoryChangeStatusType.Completed)
      .forEach(spaceInventoryChange => {
        spaceInventoryChange.changedDateTime = this.changedDateTime;
        let o = this._spaceInventoryChangeService
          .saveSpaceInventoryChange(this.workOrder.id, spaceInventoryChange)
          .pipe(
            map(r => {
              r['inventoryChangeClass'] = 'Space';
              return r;
            }),
          catchError( error => {
            return of({
              errorBody: error['_body'],
              inventoryChangeClass: 'Space'
            });
          })
          );

          obz.push(o);
      });

      return obz;
  }

  updateInventoryChange(workOrderAction: WorkOrderAction) {
    this.inventoryChangesService.updateInventoryChanges(this.workOrder.id, workOrderAction.id).subscribe(
      result => {
        if (result) {
          this.changeToDescriptionTab.emit(true);
        }
      });
  }

  workOrderActionIsReadyForActivation(action: WorkOrderAction) {
    return (
      action.actionId !== ActionConstants.RegularRateAdjustmentId &&
      action.actionId !== ActionConstants.UpdateMeterAttributesId &&
      action.actionId !== ActionConstants.UpdateMeterAttributesChangeContractTypeId &&
      action.allFormsReadOnly &&
      action.showActivateButton &&
      action.currentStatus < WorkOrderActionStatusConstants.Activated
    );
  }

  allWorkOrderActionsAreReadyForActivation() {
    return this.workOrderActions.every(
      this.workOrderActionIsReadyForActivation
    );
  }

  private checkIfWorkOrderIsNotTrueUpFinalized() {
    if (this.tokenCache.checkForLAZUser()) {
      const today = new Date();
      const sortedWorkOrderActions = this.workOrder.currentLetterOfDirection.workOrderActions.sort((a, b) => DateTimeUtility.compareDate(a.effectiveDate, b.effectiveDate) ? -1 : 1);
      const effectiveDate = new Date(sortedWorkOrderActions[0].effectiveDate);
      const dateDifference = (moment(today).startOf('day').diff(moment(effectiveDate).startOf('day'), 'days'));
      this.calculationsCompleteService.isCalculationApprovedForYearAndMonth(effectiveDate.getFullYear(), effectiveDate.getMonth() + 1).subscribe(approvedResult => {
        if (approvedResult.result === true) {
          this.calculationsCompleteService.getLastFinalizedQuarter(effectiveDate.getFullYear(), effectiveDate.getMonth() + 1).subscribe(lastQuarterResult => {
            this.workOrderIsNotTrueUpFinalized = lastQuarterResult;
          });
        }
        else {
          this.workOrderIsNotTrueUpFinalized = true;
        }
      });
    }
    else {
      this.workOrderIsNotTrueUpFinalized = true;
    }
  }

  handleErrorForSubmission(error: any, nodeName: string, action: String) {
    let response: any = {};

    try {
      //might actually be the response object
      if(error.message || error.validationRepresentations){
        response = error;
      }
      else if(error._body){ //might be raw response
        //error body might be an object already
        if(error._body.message || error._body.validationRepresentations){
          response = error._body;
        }
        else{
          //try to get json from string
          try {
            response = JSON.parse(error['_body']);
          } catch (ex2) {
            //give up, just show what we have
            this.openSnackBarForErrors(`Error ${action} ${nodeName} Inventory Change: ${error._body}`);
            return;
          }
        }
      }
      else{
        this.openSnackBarForErrors(`Error ${action} ${nodeName} Inventory Change: ${error}`);
        return;
      }

      if (response.validationRepresentations && response.validationRepresentations.length > 0) {
        let errorMessage = '';
        for (let i = 0; i < response.validationRepresentations.length; i++) {
          errorMessage += response.validationRepresentations[i].message;
          if (i !== response.validationRepresentations.length - 1) {
            errorMessage += ', ';
          }
        }
        this.openSnackBarForErrors(`Error ${action} ${nodeName} Inventory Change:  ${errorMessage}`);
      }
      else if (response.message) {
        this.openSnackBarForErrors(`Error ${action} ${nodeName} Inventory Change:  ${response.message}`);
      }
      else{
        this.openSnackBarForErrors(`Error ${action} ${nodeName} Inventory Change:  ${response}`);
      }

    } catch (ex1) {
      //never fail to display the error, no matter what happens
      this.openSnackBarForErrors(`Error ${action} ${nodeName} Inventory Change: Unknown Error`);
      return;
    }

  }

  openSnackBarOnSuccess(message: string, reloadWorkOrder: boolean) {
    this.snackBar.open(message, 'X');
    if (reloadWorkOrder) {
      this.changeToDescriptionTab.emit(true);
    }
  }

  openSnackBarForErrors(message: string) {
    console.log(message);
    this.snackBar.open(message, 'X');
  }

  checkIfFormIsReadyForSubmission(workOrderAction: WorkOrderAction) {
    if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
      return false;
    }

    if (workOrderAction.actionId.toString() === ActionConstants.InactivateMeterId.toString()
      || workOrderAction.actionId.toString() === ActionConstants.ReactivateMeterId.toString()
      || workOrderAction.actionId.toString() === ActionConstants.ReverseRatePackageChangeId.toString()) {
      return true;
    }

    return this.checkAllFormsForCompletion(workOrderAction);
  }

  private checkAllFormsForCompletion(workOrderAction: WorkOrderAction){
    var spaceFormComplete = (!workOrderAction.spaceInventoryChanges || workOrderAction.spaceInventoryChanges.length == 0) //either have no space changes
                            || (workOrderAction.spaceInventoryChanges.length > 0 && !this.displaySpaceChanges(workOrderAction)) //we have changes, but we are hiding them
                            || workOrderAction.spaceInventoryChangesFormComplete; //or space form is good

    var meterFormComplete = (!workOrderAction.meterInventoryChanges || workOrderAction.meterInventoryChanges.length == 0) //either have no meter changes
                            || (workOrderAction.meterInventoryChanges.length > 0 && !this.displayWorkOrderMeterInventoryForm(workOrderAction) && workOrderAction.actionId != ActionConstants.RelocateMeterId) //we have changes, but we are hiding them, so no need to check
                            || workOrderAction.meterInventoryChangesFormComplete; //or meter is good

    var blockFormComplete = (!workOrderAction.blockInventoryChanges || workOrderAction.blockInventoryChanges.length == 0) //either have no block changes
                            || (workOrderAction.blockInventoryChanges.length > 0 && !this.displayBlockInventoryChanges(workOrderAction)) //we have changes, but we are hiding them
                            || workOrderAction.blockInventoryChangesFormComplete; //or block form is good

    return spaceFormComplete && meterFormComplete && blockFormComplete;
  }

  checkIfFormIsReadyForUpdate() {
    if (this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId) {
      return false;
    }
    else {
      return true;
    }
  }

  workOrderIsPaused() {
    return this.workOrder.currentStatusId === WorkOrderStatusConstants.PausedId;
  }

  disableHistoricalEffectiveDate(): boolean {
    return this.workOrder.currentLetterOfDirection.historicalEffectiveDate !== null;
  }

  projectedCompletionDateChanged(type: string, event: MatDatepickerInputEvent<Date>, workOrderAction: WorkOrderAction) {
    const projectedDate = new Date(event.value);

    workOrderAction.blockInventoryChanges.forEach(blockInventoryChange => {
      blockInventoryChange.projectedCompletionDate = projectedDate;
    });

    workOrderAction.meterInventoryChanges.forEach(meterInventoryChange => {
      meterInventoryChange.projectedCompletionDate = projectedDate;
    });

    workOrderAction.spaceInventoryChanges.forEach(spaceInventoryChange => {
      spaceInventoryChange.projectedCompletionDate = projectedDate;
    });
  }

  executeInventoryChanges(workOrderActionId: string, effectiveDate?: Date) {
    this.snackBar.open('Activating Inventory Changes...', '');
    const workOrderActionMatches = this.workOrderActions.filter(x => x.id === workOrderActionId);

    if (workOrderActionMatches && workOrderActionMatches.length > 0 && !effectiveDate) {
      effectiveDate = workOrderActionMatches[0].effectiveDate;
    }
    if (effectiveDate) {
      this.workOrderActionService.executeInventoryChangesForWorkOrderAction(workOrderActionId, effectiveDate)
        .subscribe(() => {
          this.snackBar.open(`Inventory changes activated`, '', <MatSnackBarConfig>{
            duration: 1500,
          }).afterDismissed().subscribe(result => {
            this.changeToDescriptionTab.emit(true);
          });
        }, error => {
          this.openSnackBarForErrors(`Error activating inventory changes: ` + error['_body']);
          return error;
        }
        );
    } else {
      this.openSnackBarForErrors(`You must select an effective date.`);
    }
  }

  onDateChange(event) {
    this.effectiveDate = event;
  }

  executeWorkOrderActionInventoryChanges(workOrderAction:WorkOrderAction){
    if(!this.workOrderActionIsReadyForActivation(workOrderAction)){
      this.snackBar.open('Work Order Action is not ready for activation!', '', <MatSnackBarConfig>{
        duration: 5000,
      });
      return;
    }

    this.executeInventoryChanges(workOrderAction.id, workOrderAction.effectiveDate);
  }

  executeAllInventoryChanges() {
    const completedStatuses = this.workOrder.workOrderStatuses.filter(status => {
      return status.workOrderStatusTypeId === WorkOrderStatusConstants.CompletedId;
    });

    if (completedStatuses.length > 0 || this.currentLetterOfDirection.historicalEffectiveDate) {
      this.snackBar.open(`Activating Inventory Changes...`, '', <MatSnackBarConfig>{
        duration: 5000,
      });
      this.workOrderActionService
        .executeHistoricalChangesForEntireWorkOrder(this.workOrder.id, this.currentLetterOfDirection.historicalEffectiveDate ? null : this.effectiveDate)
        .subscribe(() => {
          this.snackBar.open(`Inventory changes activated`, '', <MatSnackBarConfig>{
            duration: 1500,
          }).afterDismissed().subscribe(result => {
            this.changeToDescriptionTab.emit(true);
          });
        }, error => {
          this.openSnackBarForErrors(`Error activating inventory changes`);
          return error;
        });
    }
    else {
      this.currentLetterOfDirection.workOrderActions.forEach(workOrderAction => {
        this.executeInventoryChanges(workOrderAction.id, this.effectiveDate);
      }, this);
    }
  }

  getIncompleteBlockChanges(workOrderAction: WorkOrderAction): BlockInventoryChange[] {

    return workOrderAction.blockInventoryChanges.filter(blockChange => {
      return blockChange.status !== 'Completed';
    });
  }

  getIncompleteMeterChanges(workOrderAction: WorkOrderAction): MeterInventoryChange[] {
    return workOrderAction.meterInventoryChanges.filter(meterChange => {
      return meterChange.status !== 'Completed';
    });
  }

  displayAssociatedToBlockDetails(workOrderAction: WorkOrderAction): boolean {
    return (
      workOrderAction.actionId === ActionConstants.ShiftSpaceId &&
      (StringExtensions.StringIsNotEmpty(workOrderAction.meterToId) ||
        StringExtensions.StringIsNotEmpty(workOrderAction.blockToId))
    );
  }

  displayAssociatedMeterDetails(workOrderAction: WorkOrderAction): boolean {
    return (workOrderAction.meterInventoryChanges.length === 0
        && workOrderAction.actionId !== ActionConstants.UpdateMeterAttributesChangeContractTypeId
        && workOrderAction.actionId !== ActionConstants.UpdateMeterAttributesId)
        ||
        workOrderAction.actionId === ActionConstants.InactivateMeterId;
  }

  displayRequestHistoricalChange(): boolean {
    return this.workOrder.currentStatus === 'Completed'
      && this.workOrder.tempApprovalStatus !== TempApprovalStatusTypes.PendingSecondaryApproval
      && this.workOrder.tempApprovalStatus !== TempApprovalStatusTypes.TempApproved;
  }

  endLoadingWhenWorkOrderStatusChanges(that: InventoryChangesComponent, delay, status) {

    const reRun = function (millisecondsToDelay) {
      //so it stops running after we go out of scope
      if(that.isDestroyed){
        return;
      }
      that.endLoadingWhenWorkOrderStatusChanges(that, millisecondsToDelay, status);
    };

    //can use the first one, because this is only for bulk, and bulk never has more than one
    let workOrderAction = that.workOrderActions[0];
    that.workOrderService.getWorkOrderStatusByWorkOrderId(that.workOrder.id, workOrderAction.id).subscribe(response => {
      if (response.workOrderStatus !== status) {
        this.loading = false;
        window.location.reload();
      }
      else {

        let hasChanges = false;

        hasChanges = that.numberOfChangesComplete != response.numberOfChangesComplete
                    || that.numberOfFailedChanges != response.numberOfFailedChanges
                    || that.numberOfImpactsAssessed != response.numberOfImpactsAssessed;

        that.totalNumberOfChanges = response.totalNumberOfChanges;
        that.numberOfChangesComplete = response.numberOfChangesComplete;
        that.numberOfFailedChanges = response.numberOfFailedChanges;
        that.numberOfImpactsAssessed = response.numberOfImpactsAssessed;

        let currentDelay = 2000;

        //back off when there are no changes
        if(!hasChanges){
          currentDelay = delay * 2;
        }

        that.statusTimer = setTimeout(function() {reRun(currentDelay); }, currentDelay);
      }
    }, error => {
      this.openSnackBarForErrors(`Error loading work order current status please refresh the page: `);
    });
  }

  public submitAsPendingButton(action: WorkOrderAction) {
    this.snackBar.open('Submitting Inventory Changes, do not close this window...', '');

    const impactBody = this.generateLoadingZoneImpactAssessmentBody(action);
    if (this.potentialCLZImpactActionTypes.includes(action.actionId) && action.blockInventoryChanges.length === 0 && impactBody) {
      this.checkForLoadingZoneImpactsAndSubmitInventoryChanges(impactBody, action);
    }
    else {
      this.submitInventoryChanges(action);
    }
  }

  private generateLoadingZoneImpactAssessmentBody(workOrderAction: WorkOrderAction): LoadingZoneImpactAssessmentPostBody {
    const blockId = workOrderAction.blockIDs[0];
    const meterId = workOrderAction.meterId;

    if (!(blockId || meterId) || workOrderAction.spaceInventoryChanges.length === 0) {
      return null;
    }

    const loadingZoneImpactPostBody = new LoadingZoneImpactAssessmentPostBody();
    loadingZoneImpactPostBody.effectiveDate = this.workOrder.previousActivationEffectiveDate ? this.workOrder.previousActivationEffectiveDate : new Date();

    loadingZoneImpactPostBody.isPreliminaryAssessment = false;

    const spaceIds = [];
    const inventoryFilter = new InventoryFilterRepresentation();

    if (this.meter && workOrderAction.actionId !== ActionConstants.RemoveSpaceId) {
      loadingZoneImpactPostBody.newMeterContractType = ContractTypeConstants[this.meter.currentContractType].value;
    }

    // if we are removing spaces, everything is a removal and that is all we need
    if (workOrderAction.actionId === ActionConstants.RemoveSpaceId) {
      loadingZoneImpactPostBody.removedOriginBlockId = blockId;
      loadingZoneImpactPostBody.removedOriginMeterId = meterId ? meterId : StringExtensions.EmptyGuid;
      loadingZoneImpactPostBody.removedOriginSpaceIds = [];
      workOrderAction.spaceInventoryChanges.forEach(spaceChange => {
        loadingZoneImpactPostBody.removedOriginSpaceIds.push(spaceChange.spaceId);
      });
      return loadingZoneImpactPostBody;
    }

    // if this is rate package change, we already know which spaces we are changing
    if (workOrderAction.actionId === ActionConstants.ChangeRatePackageId) {
      loadingZoneImpactPostBody.originSpaceIds = [];
      workOrderAction.spaceInventoryChanges.forEach(spaceChange => {
        loadingZoneImpactPostBody.originSpaceIds.push(spaceChange.spaceId);
      });
    }
    // otherwise, send an empty guid because we don't know yet
    else {
      loadingZoneImpactPostBody.originSpaceIds = [StringExtensions.EmptyGuid];
    }

    loadingZoneImpactPostBody.originBlockId = blockId;
    loadingZoneImpactPostBody.originMeterId = meterId;

    const workOrderActionJsonObject = JSON.parse(workOrderAction.workOrderActionJson);

    if (workOrderAction.actionId === ActionConstants.InstallMeterId) {
      // this can be empty if we are creating a new meter
      loadingZoneImpactPostBody.originMeterId = StringExtensions.EmptyGuid;
      loadingZoneImpactPostBody.newParkingTypeId = workOrderAction.meterInventoryChanges[0].newParkingTypeId;
      loadingZoneImpactPostBody.newMeterContractType = workOrderAction.meterInventoryChanges[0].newContractTypeId;
    }

    // need to include new rate package if creating spaces or meters, or changing rate package
    if (workOrderAction.actionId === ActionConstants.InstallMeterId
        || workOrderAction.actionId === ActionConstants.ChangeRatePackageId
        || workOrderAction.actionId === ActionConstants.InstallSpaceId) {
      loadingZoneImpactPostBody.newRatePackageId = workOrderActionJsonObject[WorkorderActionJsonConstants.ratePackageGuid];
    }

    // this applies install space, meter, meter attributes
    if (workOrderAction.spaceInventoryChanges
        && workOrderAction.actionId !== ActionConstants.ChangeRatePackageId // but not changing rate packages
        && workOrderAction.spaceInventoryChanges.length > 0) {
      loadingZoneImpactPostBody.newFineAmountId = workOrderAction.spaceInventoryChanges[0].fineAmountId;
    }

    return loadingZoneImpactPostBody;
  }

  private checkForLoadingZoneImpactsAndSubmitInventoryChanges(loadingZoneImpactPostBody: LoadingZoneImpactAssessmentPostBody, workOrderAction: WorkOrderAction) {
    // Get all impacts/reversals
    this._loadingZoneImpactService.getAllImpactedSpaces(loadingZoneImpactPostBody)
    .subscribe(impactsResult => {

      if (impactsResult.failed) {
        this.openSnackBarForErrors('Loading Zone Impact Assessment Error: ' + impactsResult.message);
        return;
      }

      // No impacts/reversals found
      if (impactsResult.okWithItem && impactsResult.result.loadingZoneImpacts.length === 0) {
        this.submitInventoryChanges(workOrderAction);
        return;
      }

     // Impacts/reversals found
      if (impactsResult.okWithItem && impactsResult.result.loadingZoneImpacts.length > 0) {
        this.snackBar.dismiss();
        const loadingZoneImpacts = impactsResult.result.loadingZoneImpacts;

        // Confirm impacts/reversals
        const dialogRef = this.dialog.open(LoadingZoneImpactComponent, {
          width: 'auto',
          height: 'auto',
          data: {
            impactReasons: impactsResult.result.impactReasons
          }
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          // Cancel out of impact/reversal modal
          if (!dialogResult) {
            this.openSnackBarForErrors('Submit as Pending Cancelled');
            return;
          }

          // Confirmed impact/reversal modal
          this.generateLoadingZoneImpactChangeGroups(loadingZoneImpacts);
          this.submitInventoryChanges(workOrderAction);
        });
      }
    },
    error => {
        this.openSnackBarForErrors('Error checking for Loading Zone Impacts: ' + error);
    });
  }

  private generateLoadingZoneImpactChangeGroups(loadingZoneImpacts): void {
    this.potentialLoadingZoneImpactChanges = [];
    const batchRequestId = UUID.UUID();

    loadingZoneImpacts.forEach(impact => {
      const loadingZoneImpactChangeGroup = new LoadingZoneImpactChangeGroup();
      loadingZoneImpactChangeGroup.batchRequestId = batchRequestId;
      loadingZoneImpactChangeGroup.loadingZoneSpaceId = impact.loadingZoneSpaceId;
      loadingZoneImpactChangeGroup.loadingZoneSpaceDisplayId = impact.loadingZoneSpaceDisplayId;
      loadingZoneImpactChangeGroup.relatedChanges = this.generateLoadingZoneImpactChanges(impact);

      this.potentialLoadingZoneImpactChanges.push(loadingZoneImpactChangeGroup);
    });
  }

  private generateLoadingZoneImpactChanges(impact): LoadingZoneImpactChange[] {
    const relatedChanges = new Array<LoadingZoneImpactChange>();
      impact.impactedSpaces.forEach(space => {
        const loadingZoneImpactChange = new LoadingZoneImpactChange();
        loadingZoneImpactChange.spaceId = space.spaceId;
        loadingZoneImpactChange.spaceDisplayId = space.spaceDisplayId;
        loadingZoneImpactChange.impactType = space.impactType;
        loadingZoneImpactChange.impactReason = space.impactReason;

        relatedChanges.push(loadingZoneImpactChange);
      });

      return relatedChanges;
  }

  private submitInventoryChanges(action: WorkOrderAction): void {
    this.changedDateTime = moment.utc(new Date()).toDate();
    if (action.blockChangesExist && action.blockInventoryChanges[0].status !== InventoryChangeStatusType.Completed) {
      this.submitBlockChanges(action, action.blockInventoryChanges[0]);
    } else if (action.meterChangesExist && action.meterInventoryChanges[0].status !== InventoryChangeStatusType.Completed) {
      this.submitMeterChange(action, action.meterInventoryChanges[0], null);
    } else if (action.spaceChangesExist) {
      this.createSpaceChangeObservables(action, action.spaceInventoryChanges, null, null);
    }
  }

  private submitBlockChanges(action: WorkOrderAction, blockChange: BlockInventoryChange) {
    if (blockChange) {
        blockChange.changedDateTime = this.changedDateTime;
      this._blockInventoryChangeService
          .submitBlockChange(blockChange, this.workOrder.id)
          .subscribe(body => {
            if (!body) { return; }
            this.submitMeterChange(action, action.meterChangesExist ? action.meterInventoryChanges[0] : null, body.result.blockId);
            this.snackBar.open('Submitted Block Inventory Change', 'X' , <MatSnackBarConfig>{
              duration: 2000,
            });
          }, error => {
            this.handleErrorForSubmission(error, 'Block', 'submitting');
            return error;
          });
    }
    else {
      this.openSnackBarForErrors('Attempted to submit meter change but no meter change was provided');
    }
  }

  private submitMeterChange(action: WorkOrderAction, meterChange: MeterInventoryChange, blockId ?: string) {
      if (meterChange) {
        meterChange.changedDateTime = this.changedDateTime;
        this._meterInventoryChangeService.submitMeterChange(meterChange, this.workOrder.id, blockId)
        .subscribe(body => {
          if (action.spaceChangesExist) {
            this.createSpaceChangeObservables(action,  action.spaceChangesExist ? action.spaceInventoryChanges : null, body.result.meterId, body.result.meterDisplayId);
          }
          this.openSnackBarOnSuccess('Submitted Meter Inventory Change', !action.spaceChangesExist);
        },
        error => {
          this.handleErrorForSubmission(error, 'Meter', 'submitting');
          return error;
        });
      }
      else if(action.spaceChangesExist) {
        this.createSpaceChangeObservables(action, action.spaceChangesExist ? action.spaceInventoryChanges : null, action.spaceInventoryChanges[0].meterId, action.spaceInventoryChanges[0].meterDisplayId);
      }
      else {
        this.openSnackBarForErrors('Attempted to submit meter change but no meter change was provided');
      }
  }

  private async createSpaceChangeObservables(action: WorkOrderAction, spaceChanges: SpaceInventoryChange[], meterId: string, meterDisplayId: string) {
      action.spaceInventoryChanges.filter(x => x.status !== InventoryChangeStatusType.Completed).forEach(spaceInventoryChange => {
        spaceInventoryChange.changedDateTime = this.changedDateTime;
        // make sure the spaces have meter id
        if (action.actionId.toString() === ActionConstants.ShiftSpaceId.toString()) {
          if (StringExtensions.StringIsNotEmpty(action.meterToId)) {
            spaceInventoryChange.meterId = action.meterToId;
            spaceInventoryChange.meterDisplayId = meterDisplayId;
          }
        } else {
          if (StringExtensions.StringIsNotEmpty(meterId)) {
            spaceInventoryChange.meterId = meterId;
            spaceInventoryChange.meterDisplayId = meterDisplayId;
          }
        }
      });

      this.spaceChangesToSubmit = action.spaceInventoryChanges.filter(
        x => x.status !== InventoryChangeStatusType.Completed
      );

      const coldSpaceChangeObservables = this.spaceChangesToSubmit.map(spaceChange => {
        return this._spaceInventoryChangeService.submitSpaceInventoryChange(this.workOrder.id, spaceChange);
      });

      this.submitSpaceChanges(coldSpaceChangeObservables, action);

  }

  private submitSpaceChanges(spaceInventoryChanges: Observable <any> [], action: WorkOrderAction) {
    const changeNumber = this.spaceChangesToSubmit.length - spaceInventoryChanges.length;

    // Space inventory changes need to be submitted one by one because the backend needs to check if the space change submitted
    // is the last space in the meter for RemoveSpace and ShiftSpace actions, in order to remove the meter along with it.
    const change = spaceInventoryChanges.pop();

    change.subscribe(spaceInventoryChangeResponse => {
      // If there are no more space inventory changes, submit loading zone impacts or end submit call
        if (spaceInventoryChangeResponse.ok && spaceInventoryChanges.length === 0) {
          // Submit loading zone impacts if there are any
            if (this.potentialLoadingZoneImpactChanges && this.potentialLoadingZoneImpactChanges.length > 0) {
                this.copyImpactToSubmissionImpacts(spaceInventoryChangeResponse.result, action.id);
                this.createImpactedSpaces(action);
            }
            // End submit call
            else if (spaceInventoryChanges.length === 0) {
                this.openSnackBarOnSuccess('Submitted Space Inventory Changes ', true);
            }
        }
        // Submit next space change if there are any
        // And copy new space change to new impact
        else if (spaceInventoryChangeResponse.ok) {
          this.copyImpactToSubmissionImpacts(spaceInventoryChangeResponse.result, action.id);
          this.submitSpaceChanges(spaceInventoryChanges, action);
        }
        // Handle error from response
        else {
          this.handleErrorForSubmission(spaceInventoryChangeResponse, 'Space', 'submitting');
        }
      },
      error => {
        this.handleErrorForSubmission(error, 'Space', 'submitting');
      });
  }

  private createImpactedSpaces(action: WorkOrderAction): void {
    // Create new empty collection if needed to clear previous impacts/reversals
    if (!this.submissionLoadingZoneImpactChanges) {
      this.submissionLoadingZoneImpactChanges = [];
      const batchRequestId = UUID.UUID();
      const changeToSubmit = new LoadingZoneImpactChangeGroup();
      changeToSubmit.batchRequestId = batchRequestId;
      changeToSubmit.workOrderActionId = action.id;
      changeToSubmit.effectiveDate = new Date();

      if (!this.submissionLoadingZoneImpactChanges) {
        this.submissionLoadingZoneImpactChanges = [];
      }
      this.submissionLoadingZoneImpactChanges.push(changeToSubmit);
    }
    // Submit impacts/reversals
    this.submitImpactedSpaces(this.submissionLoadingZoneImpactChanges);
  }

  private submitImpactedSpaces(spaceImpactChanges: LoadingZoneImpactChangeGroup[]): void {
    // Reload the work order if impact changes are done
    if (spaceImpactChanges.length === 0) {
      this.changeToDescriptionTab.emit(true);
    }

    const currentChange = spaceImpactChanges.pop();
    if (currentChange) {
      this._spaceInventoryChangeService.submitSpaceImpactChange(currentChange, this.workOrder.id, currentChange.workOrderActionId).subscribe(result => {
        if (result.ok && spaceImpactChanges.length > 0) {
          this.submitImpactedSpaces(spaceImpactChanges);
        }
        else if (!result.ok) {
          this.openSnackBarForErrors(result.message);
        }
        else {
          this.openSnackBarOnSuccess('Submitted Space Inventory Changes', true);
        }
      },
      error => {
        this.openSnackBarForErrors(error);
      });
    }
  }

  private copyImpactToSubmissionImpacts(response: any, actionId: string) {
    if (this.potentialLoadingZoneImpactChanges) {
      this.potentialLoadingZoneImpactChanges.forEach(change => {
        // if this id is empty, we just submitted a new clz space
        // so we need to create a new grouping
        if (change.loadingZoneSpaceId === StringExtensions.EmptyGuid) {
          // add a new clz space to the submission set
          this.createNewCLZImpact(change, response);
        }
        // otherwise, we have submitted a change to an existing clz, or created new concession spaces
        else {
          // if this is the first one we have seen, need to populate the list of submissions
          let existingSubmission;

           if (this.submissionLoadingZoneImpactChanges) {
            existingSubmission = this.submissionLoadingZoneImpactChanges
            .find(submission => submission.loadingZoneSpaceId === change.loadingZoneSpaceId);
           }

          if (existingSubmission === undefined) {
              existingSubmission = this.copyNewClzImpact(change, response, existingSubmission, actionId);
          }

          // copy in a new spaces, if we had any empty ids
          change.relatedChanges.forEach(impactedSpace => {
            // empty means new space
            if (impactedSpace.spaceId !== StringExtensions.EmptyGuid) {
                return;
            }

            const newImpact = new LoadingZoneImpactChange();
            newImpact.impactReason = impactedSpace.impactReason;
            newImpact.impactType = impactedSpace.impactType;
            newImpact.workOrderActionId = actionId;

            // ID comes from the space change
            newImpact.spaceDisplayId = response.result.spaceDisplayId;
            newImpact.spaceId = response.result.spaceId;

            existingSubmission.relatedChanges.push(newImpact);
          });
        }
      });
    }
  }

  private copyNewClzImpact(change: LoadingZoneImpactChangeGroup, response: any, existingSubmission: LoadingZoneImpactChangeGroup, actionId: string) {
      const changeToSubmit = new LoadingZoneImpactChangeGroup();
      changeToSubmit.batchRequestId = change.batchRequestId;
      changeToSubmit.loadingZoneSpaceId = change.loadingZoneSpaceId;
      changeToSubmit.loadingZoneSpaceDisplayId = change.loadingZoneSpaceDisplayId;

      changeToSubmit.effectiveDate = response.effectiveDate;
      changeToSubmit.status = response.status;
      changeToSubmit.statusId = response.statusId;
      changeToSubmit.workOrderActionId = response.workOrderActionId;
      changeToSubmit.projectedCompletionDate = response.projectedCompletionDate;
      changeToSubmit.previousActivationEffectiveDate = response.previousActivationEffectiveDate;
      changeToSubmit.workOrderActionType = response.workOrderActionType;
      changeToSubmit.relatedChanges = [];

      // copy over existing concession spaces
      change.relatedChanges.forEach(impactedSpace => {
        // don't copy any new space
        if (impactedSpace.spaceId === StringExtensions.EmptyGuid) {
            return;
        }

        const newImpact = new LoadingZoneImpactChange();
        newImpact.impactReason = impactedSpace.impactReason;
        newImpact.impactType = impactedSpace.impactType;
        newImpact.spaceDisplayId = impactedSpace.spaceDisplayId;
        newImpact.spaceId = impactedSpace.spaceId;
        newImpact.workOrderActionId = actionId;
        changeToSubmit.relatedChanges.push(newImpact);
      });

      existingSubmission = changeToSubmit;

      if (!this.submissionLoadingZoneImpactChanges) {
        this.submissionLoadingZoneImpactChanges = [];
      }
      this.submissionLoadingZoneImpactChanges.push(changeToSubmit);
      return existingSubmission;
    }

  private createNewCLZImpact(change: LoadingZoneImpactChangeGroup, response: any) {
    const changeToSubmit = new LoadingZoneImpactChangeGroup();
    changeToSubmit.batchRequestId = change.batchRequestId;
    changeToSubmit.effectiveDate = response.effectiveDate;
    changeToSubmit.status = response.status;
    changeToSubmit.statusId = response.statusId;
    changeToSubmit.workOrderActionId = response.workOrderActionId;
    changeToSubmit.projectedCompletionDate = new Date();
    changeToSubmit.previousActivationEffectiveDate = new Date();

    // ID is new space id
    changeToSubmit.loadingZoneSpaceId = response.spaceId;
    changeToSubmit.loadingZoneSpaceDisplayId = response.spaceDisplayId;

    // copy over the impacted concession spaces to our new grouping
    changeToSubmit.relatedChanges = [];
    change.relatedChanges.forEach(impactedSpace => {
        const newImpact = new LoadingZoneImpactChange();
        newImpact.impactReason = impactedSpace.impactReason;
        newImpact.impactType = impactedSpace.impactType;
        newImpact.spaceDisplayId = impactedSpace.spaceDisplayId;
        newImpact.spaceId = impactedSpace.spaceId;
        newImpact.workOrderActionId = response.workOrderActionId;
        newImpact.effectiveDate = response.effectiveDate;

        changeToSubmit.relatedChanges.push(newImpact);
    });

    if (!this.submissionLoadingZoneImpactChanges) {
      this.submissionLoadingZoneImpactChanges = [];
    }
    this.submissionLoadingZoneImpactChanges.push(changeToSubmit);
  }
}
