import { WorkOrder } from './../../../models/work-order';
import { StringExtensions } from './../../../../string-extensions';
import { MeterTypeConstants, ContractTypeConstants, ParkingTypeConstants } from './../../../../inventory-management/meters/meter-constants';
import { InstallMeterConstants } from './../../../action-field/action-field-constants';
import { InventoryFilterRepresentation } from './../../../../inventory-management/inventory-filter/inventory-filter-representation';
import { ValidatorFn, Validators } from '@angular/forms';

import { LoadingZoneImpactService } from './../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import { ActionField } from '../../../models/action-field';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { Meter } from '../../../../inventory-management/meters/meter';
import { ActionFormWithValidation } from './action-form-with-validation';
import { TokenCache } from '../../../../security/token-cache';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { MeterIdDoesNotExistValidator, MeterIdIsTooShortValidator } from '../form-field-validators/inventory-id-validators';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { ActionConstants } from '../../action-constants';
import { LoadingZoneImpactAssessmentPostBody } from '../../../models/loading-zone-impacts/loading-zone-impact-assessment-body';

export class HistoricalMeterForm extends ActionFormWithValidation {

  constructor(actionIndex: string, actionFields: ActionField[], tokenCache: TokenCache, _meterService: MeterService,
    _blockService: BlockService, _spaceService: SpaceService, hasHistoricalDate: boolean, overrideAsCity: boolean, workOrder: WorkOrder, _loadingZoneImpactService: LoadingZoneImpactService,
    historicalDate?: Date, previousActivationEffectiveDate?: Date) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, historicalDate, previousActivationEffectiveDate);
    this._meterService = _meterService;
    this._blockService = _blockService;
    this._loadingZoneImpactService = _loadingZoneImpactService;
  }

  numberOfSpacesOnMeter: number;
  numberOfSpacesOnFromMeter: number;
  numberOfSpacesOnToMeter: number;
  _loadingZoneImpactService: LoadingZoneImpactService;
  loadingZoneImpactPostBody = new LoadingZoneImpactAssessmentPostBody();

  clzImpactTypes = ['Rate/Hour Difference', 'Period of Operations Difference', 'Fine Amount Difference'];
  clzImpactPrefix = 'This action will trigger a CLZ change due to: ';
  contractType: string;
  meter: Meter;

  initializeFormForLoadingZoneImpacts(actionId: number) {
    this.formGroup.valueChanges.subscribe(() => {
      this.generateLoadingZoneImpactAssessmentBody(actionId);
    });
  }

  public validMeterLength(value): boolean {
    return (value !== undefined && value.toString().length > 5);
  }

  public addOrRemoveMeterIdValidations(meterControlId: number, numberOfSpacesControlId: number, meterGuidConstant: string, value: string, validators: ValidatorFn[], actionId: number): void {
    if (this.validMeterLength(this.getControlValue(meterControlId))) {
      this._meterService.getMeterByDisplayId(value, this.previousActivationEffectiveDate ? this.previousActivationEffectiveDate : this.historicalEffectiveDate ).subscribe(result => {
        this.formGroup.get(this.actionIndex + meterControlId.toString()).clearValidators();
        if (meterGuidConstant === WorkorderActionJsonConstants.meterGuid) {
          this.numberOfSpacesOnMeter = result.activeSpaces;
        }
        else if (meterGuidConstant === WorkorderActionJsonConstants.meterFromGuid) {
          this.numberOfSpacesOnFromMeter = result.activeSpaces;
        }
        else if (meterGuidConstant === WorkorderActionJsonConstants.meterToGuid) {
          this.numberOfSpacesOnToMeter = result.activeSpaces;
        }

        this.setValidators(numberOfSpacesControlId, validators);
        this.enableControl(numberOfSpacesControlId);
        this.addGuidControl(meterGuidConstant);
        this.setGuidValue(meterGuidConstant, result.id);
        this.addGuidControl(WorkorderActionJsonConstants.blockGuid);
        this.setGuidValue(WorkorderActionJsonConstants.blockGuid, result.blockId);
        this.updateValueAndValidity(meterControlId);
        this.contractType = ContractTypeConstants[result.currentContractType].value;
      },
      error => {
        this.removeGuidControl(meterGuidConstant);
        this.setValidators(meterControlId, [Validators.required, MeterIdDoesNotExistValidator()]);
        this.updateValueAndValidity(meterControlId);
      });
    }
    else {
      this.setValidators(meterControlId, [Validators.required, MeterIdIsTooShortValidator()]);
    }
  }
  getHistoricalEffectiveDate(component: ActionFormWithValidation): Date {
    return component.historicalEffectiveDate;
  }

  getHasHistoricalEffectiveDate(component: ActionFormWithValidation): boolean {
    return component.hasHistoricalEffectiveDate;
  }

  checkForCLZImpact(): void {
    this._loadingZoneImpactService.getAllImpactedSpaces(this.loadingZoneImpactPostBody)
    .subscribe(result => {
      if (result.result && result.result.impactReasons.length > 0) {
        let impactTypes = '';
        result.result.impactReasons.forEach(reason => {
            if (impactTypes === '') {
              impactTypes = reason;
            }
            else {
              impactTypes = impactTypes + ', ' + reason;
            }
        });

        if (!this.warnings.includes(this.clzImpactPrefix + ' ' + impactTypes) && impactTypes) {
          this.warnings.push(this.clzImpactPrefix + ' ' + impactTypes);
          this.formGroup.updateValueAndValidity();
        }
      }
      else {
        this.clearLoadingZoneWarning();
      }
    },
    error => {
      console.log(error);
      this.clearLoadingZoneWarning();
    });
  }

  clearLoadingZoneWarning(): void {
    this.warnings.forEach(warning => {
      if (warning.includes(this.clzImpactPrefix)) {
        this.warnings.splice(this.warnings.indexOf(warning), 1);
      }
    });
  }

  private guidNotEmpty(id: string): boolean {
    return id !== undefined
      && id != null
      && id !== ''
      && id !== StringExtensions.EmptyGuid;
  }

  generateLoadingZoneImpactAssessmentBody(actionId: number): void {
    const blockId = this.getGuidControlValue(WorkorderActionJsonConstants.blockGuid) ? this.getGuidControlValue(WorkorderActionJsonConstants.blockGuid)
                         : this.workOrderActionJsonObject && this.workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid] ? this.workOrderActionJsonObject[WorkorderActionJsonConstants.blockGuid] : null;
    let readyForSubmission = false;

    this.loadingZoneImpactPostBody.effectiveDate = this.historicalEffectiveDate ? this.historicalEffectiveDate : new Date();
    this.loadingZoneImpactPostBody.isPreliminaryAssessment = true;

    // to install a meter, we need
    // 1) the block
    // 2) proposed meter type
    // 3) one dummy space
    // 4) proposed rate package
    // 5) proposed fine amount? probably never have this
    // 6) meter contract type
    if (actionId === ActionConstants.InstallMeterId) {
      const contractType = this.getControlValue(InstallMeterConstants.ContractType) ? this.getControlValue(InstallMeterConstants.ContractType) : this.workOrderActionJsonObject && this.workOrderActionJsonObject[InstallMeterConstants.ContractType] ? this.workOrderActionJsonObject[InstallMeterConstants.ContractType] : null;
      const locationType = this.getControlValue(InstallMeterConstants.LocationType) ? this.getControlValue(InstallMeterConstants.LocationType) : this.workOrderActionJsonObject && this.workOrderActionJsonObject[InstallMeterConstants.LocationType] ? this.workOrderActionJsonObject[InstallMeterConstants.LocationType] : null;
      const ratePackageId = this.getGuidControlValue(WorkorderActionJsonConstants.ratePackageGuid) ? this.getGuidControlValue(WorkorderActionJsonConstants.ratePackageGuid) : this.workOrderActionJsonObject && this.workOrderActionJsonObject[WorkorderActionJsonConstants.ratePackageGuid] ? this.workOrderActionJsonObject[WorkorderActionJsonConstants.ratePackageGuid] : null;

      if (contractType && locationType && ratePackageId) {
        this.loadingZoneImpactPostBody.originBlockId = blockId;
        this.loadingZoneImpactPostBody.originMeterId = StringExtensions.EmptyGuid;
        this.loadingZoneImpactPostBody.newMeterContractType = ContractTypeConstants[contractType].value;
        this.loadingZoneImpactPostBody.originSpaceIds = [StringExtensions.EmptyGuid];
        this.loadingZoneImpactPostBody.newRatePackageId = ratePackageId;
        this.loadingZoneImpactPostBody.newParkingTypeId = ParkingTypeConstants[locationType.replace(/[\s]/g, '')].value;

        readyForSubmission =
          this.guidNotEmpty(this.loadingZoneImpactPostBody.originBlockId)
          && this.guidNotEmpty(this.loadingZoneImpactPostBody.newMeterContractType)
          && this.guidNotEmpty(this.loadingZoneImpactPostBody.newRatePackageId);
      }
    }

    // to install a space, we need
    // 1) the block
    // 2) the meter
    // 3) one dummy space
    // 4) proposed rate package
    // 5) proposed fine amount? probably never have this
    if (actionId === ActionConstants.InstallSpaceId) {
      this.loadingZoneImpactPostBody.originBlockId = blockId;
      this.loadingZoneImpactPostBody.originMeterId = this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid);
      this.loadingZoneImpactPostBody.originSpaceIds = [StringExtensions.EmptyGuid];
      this.loadingZoneImpactPostBody.newRatePackageId = this.getGuidControlValue(WorkorderActionJsonConstants.ratePackageGuid);

      readyForSubmission =
        this.guidNotEmpty(this.loadingZoneImpactPostBody.originBlockId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.originMeterId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.newRatePackageId);
    }

    // to remove a space, we need
    // 1) the block
    // 2) the meter
    // 3) one dummy space? do we not know which space?
    if (actionId === ActionConstants.RemoveSpaceId) {
      this.loadingZoneImpactPostBody.removedOriginBlockId = blockId;
      this.loadingZoneImpactPostBody.removedOriginMeterId = this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid);
      this.loadingZoneImpactPostBody.removedOriginSpaceIds = [StringExtensions.EmptyGuid];

      readyForSubmission =
        this.guidNotEmpty(this.loadingZoneImpactPostBody.removedOriginBlockId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.removedOriginMeterId);
    }

    // to change rate package, we need
    // 1) the block
    // 2) the meter
    // 3) one dummy space / or all spaces on the meter
    // 4) proposed rate package
    if (actionId === ActionConstants.ChangeRatePackageId && this.meter) {
        this.loadingZoneImpactPostBody.originBlockId = blockId;
        this.loadingZoneImpactPostBody.originMeterId = this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid);
        this.loadingZoneImpactPostBody.originSpaceIds = [StringExtensions.EmptyGuid];
        this.loadingZoneImpactPostBody.newRatePackageId = this.getGuidControlValue(WorkorderActionJsonConstants.ratePackageGuid);
        this.loadingZoneImpactPostBody.newMeterContractType = ContractTypeConstants[this.meter.currentContractType].value;

      readyForSubmission =
        this.guidNotEmpty(this.loadingZoneImpactPostBody.originBlockId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.originMeterId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.newRatePackageId);
    }

    // to update meter attributes, we need
    // 1) the block
    // 2) the meter
    // 3) the fine amount? probably never have it
    if (actionId === ActionConstants.UpdateMeterAttributesId) {
      this.loadingZoneImpactPostBody.originBlockId = blockId;
      this.loadingZoneImpactPostBody.originMeterId = this.getGuidControlValue(WorkorderActionJsonConstants.meterGuid);


      readyForSubmission =
        this.guidNotEmpty(this.loadingZoneImpactPostBody.originBlockId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.originMeterId)
        && this.guidNotEmpty(this.loadingZoneImpactPostBody.newParkingTypeId);
    }

    // only check this if we have enough things to avoid an error
    if (readyForSubmission) {
      this.checkForCLZImpact(); // TODO: somehow block if this gets an error?
    }

  }
}
