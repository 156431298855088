export class LoadingZoneImpactChangeGroup {
    batchRequestId: string;

    id: string;

    loadingZoneSpaceId: string;

    loadingZoneSpaceDisplayId: string;

    relatedChanges: LoadingZoneImpactChange[];

    status: string;

    statusId: number;

    workOrderActionId: string;

    effectiveDate: Date;

    workOrderActionType: string;

    projectedCompletionDate: Date;

    previousActivationEffectiveDate: Date;
}

export class LoadingZoneImpactChange {
    spaceId: string;

    spaceDisplayId: string;

    impactType: string;

    impactReason: string;

    workOrderActionId: string;

    effectiveDate: Date;

    workOrderActionType: string;

    projectedCompletionDate: Date;

    previousActivationEffectiveDate: Date;
}
