export class ReservedPowerColumns {
  public static MeterReservedPowerColumns = [
    'meter',
    'workOrderId',
    'workOrderType',
    'effectiveDate',
    'tempApproved',
    'historicalChange',
    'affectedSpaces',
    'eur',
    'eRofAffectedSpaces',
    'rVofAffectedSpaces',
    'ward',
    'area',
    'block',
    'blockAddress',
    'numberOfSpaces',
    'ratePackage',
    'rate',
    'pos',
    'fua',
    'hoo',
    'hor',
    'notes'
  ];

  public static SpaceReservedPowerColumns = [
    'fiscalYear',
    'calendarYear',
    'calendarMonth',
    'block',
    'blockDesc',
    'area',
    'zone',
    'rca',
    'spaceId',
    'meterId',
    'workOrderAction',
    'workOrderId',
    'er',
    'rv',
    'urrv',
    'eur',
    'ratePackage',
    'ratePackageDesc',
    'hoo',
    'hor',
    'pos',
    'rate',
    'fua',
    'pmeterId',
    'pratePackage',
    'pratePackageDesc',
    'phoo',
    'phor',
    'ppos',
    'prate',
    'pfua',
    'status',
    'effectiveDate',
    'tempApproved',
    'historicalChange'
  ];
}
