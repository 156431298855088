import { Component, Input, OnInit, ViewChild, EventEmitter, Output, ErrorHandler } from "@angular/core";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MonthlyTrueUpCalculationFilterRepresentation } from "./monthly-true-up-calculation-filter-representation-base";
import { Subject } from "rxjs";
import { MonthlyTrueUpCalculationDetailBase } from "./monthly-true-up-calculation-detail-base";
import { PaginationParameters } from "../../core/models/pagination-paramters";
import { FiscalUnitConverter, MonthNameToMonth, MonthToMonthName, MonthsInFiscalYear } from '../../inventory-management/meters/meter-true-up/models/month-name-dictionary';
import { MonthlyTrueUpCalculationDataSourceBase } from "./monthly-true-up-calculation-datasource-base";
import { MonthlyTrueUpCalculationFilterProperties } from "./monthly-true-up-calculation-filter-properties";
import { PaginatedGetResult } from "../../core/models/paginated-get-result";
import { SystemStartTimeService } from "../../system-start-time.service";
import { FilterOperator } from "../../core/query-strings/query-string-builder";
import { ExportMonthlyTrueUpCalculationFilterRepresentation } from "./export-monthly-true-up-calculation-filter-representation";
import { ColumnCustomizerDialogComponent } from './../../inventory-management/inventory-table/column-customizer-dialog/column-customizer-dialog.component';
import { Column } from "../../inventory-management/inventory-table/inventory-list/inventory-list-column-def";
import { ExportSelectRepresentation } from "../../inventory-management/inventory-filter/export-representations";
import { TrueUpColumnConstants } from "./true-up-column-constants";
import { ReportingQuarter } from "./models/reporting-quarter";
import { ReportingQuarterService } from "./reporting-quarter.service";
import { SpaceStatusConstants } from "../../inventory-management/spaces/space-constants";
import { ContractTypeConstants } from "../../inventory-management/meters/meter-constants";



@Component({
  selector: 'monthly-true-up-calculation',
  templateUrl: './monthly-true-up-calculation.component.html',
  styleUrls: ['./monthly-true-up-calculation.component.css']
})
export class MonthlyTrueUpCalculationComponent implements OnInit {


  constructor(private systemStartTimeService: SystemStartTimeService, private errorHandler: ErrorHandler,
    private dialog: MatDialog, private reportingQuarterService: ReportingQuarterService) {
    this.initializePaginationProperties();
  }

  allColumns:Array<Column> = new Array<Column>();
  defaultColumns:Array<Column> = new Array<Column>();
  displayedColumns:Array<string> = new Array<string>();
  columnsForExport:Array<string> = new Array<string>();
  @Input() filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation;

  exportFilterRepresentation: ExportMonthlyTrueUpCalculationFilterRepresentation;

  @Output()
  exportFilterRepresentationEmitter: EventEmitter<ExportMonthlyTrueUpCalculationFilterRepresentation>;


  @Output()
  filterUpdated: EventEmitter<MonthlyTrueUpCalculationFilterProperties>;

  @Output()
  rowCountEmitter: EventEmitter<MonthlyTrueUpCalculationFilterProperties>;

  @Output()
  exportSelectEventEmitter: EventEmitter<ExportSelectRepresentation> = new EventEmitter<ExportSelectRepresentation>(true);

  @Input() dataSubject: Subject<PaginatedGetResult<MonthlyTrueUpCalculationDetailBase>>;
  @Input() nodeLevel: string;

  @Input() showSpaceLevelColumns: boolean = false;
  @Input() showMeterLevelColumns: boolean = false;
  @Input() showSystemLevelColumns: boolean = false;
  @Input() hideFilter: boolean = false;
  errorMessage: any;

  dataSource: MonthlyTrueUpCalculationDataSourceBase<MonthlyTrueUpCalculationDetailBase>;

  pageEvent: PageEvent;
  pageProperties: MonthlyTrueUpCalculationFilterProperties;
  orderByColumn: string;
  years = [];
  quarters = [];
  reportingQuarters: ReportingQuarter[] = [];
  months = [];
  systemStartDate: Date;
  filterTypes= {
    calendarMonth: 'calendarMonth',
    calendarYear: 'calendarYear',
    fiscalQuarter: 'fiscalQuarter',
    fiscalYear: 'fiscalYear',
    reportingQuarter: 'reportingQuarter',
    spaceId: 'spaceId',
    meterId: 'meterId',
  };
  selectedReportingQuarter: any;

  MeterIdFilterOperatorDropDown = [FilterOperator.Equal, FilterOperator.Contains];
  SpaceIdFilterOperatorDropDown = [FilterOperator.Equal, FilterOperator.Contains];
  operatorDropdown = [FilterOperator.Equal, FilterOperator.GreaterThan, FilterOperator.LessThan, FilterOperator.NotEqual,
    FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between ];
  spaceStatusDropdown = [SpaceStatusConstants.active, SpaceStatusConstants.activePending, SpaceStatusConstants.removed];
  changeInMonthDropdown = ["Change In Month", "No Change In Month"];
  hasReservedPowerActionsDropdown = ["Has Reserved Power Actions", "Does Not Have Reserved Power Actions"];
  hasCLZChangeDropdown = ["Has CLZ Change", "Does Not Have CLZ Change"];
  contractTypeDropdown = [ContractTypeConstants.Concession, ContractTypeConstants.Reserved];
  dateRangeDropdown = [FilterOperator.GreaterThan, FilterOperator.LessThan,
    FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between];

  totalItems: number;
  itemsSubject: Subject<Array<MonthlyTrueUpCalculationDetailBase>>;

  dialogRef: MatDialogRef<ColumnCustomizerDialogComponent>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  private initializePaginationProperties() {
    this.pageProperties = new MonthlyTrueUpCalculationFilterProperties();
    this.pageProperties.paginationParameters = new PaginationParameters();
    this.filterRepresentation = new MonthlyTrueUpCalculationFilterRepresentation();

    this.pageProperties.paginationParameters.orderByColumn = this.orderByColumn;
    this.pageProperties.paginationParameters.pageSize = 25;
    this.pageProperties.paginationParameters.pageIndex = 0;
    this.pageProperties.paginationParameters.isAscending = true;
    this.pageProperties.paginationParameters.totalItemCount = 0;

    this.pageEvent = new PageEvent();
    this.pageEvent.length = this.pageProperties.paginationParameters.totalItemCount;
    this.pageEvent.pageIndex = this.pageProperties.paginationParameters.pageIndex;
    this.pageEvent.pageSize = this.pageProperties.paginationParameters.pageSize;

    this.itemsSubject = new Subject<Array<MonthlyTrueUpCalculationDetailBase>>();

    this.filterUpdated = new EventEmitter<MonthlyTrueUpCalculationFilterProperties>();
    this.rowCountEmitter = new EventEmitter<MonthlyTrueUpCalculationFilterProperties>();
    this.exportFilterRepresentationEmitter = new EventEmitter<ExportMonthlyTrueUpCalculationFilterRepresentation>();
  }

  ngOnInit(): void {
    this.exportFilterRepresentation = new ExportMonthlyTrueUpCalculationFilterRepresentation();

    this.initializeSystemStartYear();
    this.initializeQuarterSelectorValues();
    this.initializeMonthSelectorValues();
    if(!this.hideFilter) {
      this.initializeFilters();
    }

    var dataSourceObservable = this.dataSubject.asObservable();

    dataSourceObservable.subscribe(result => {
      this.totalItems = result.totalItems;
      this.pageProperties.paginationParameters.pageIndex = 0;
      this.itemsSubject.next(result.items);
    });

    this.displayedColumns = new Array<string>();

    if (this.showMeterLevelColumns) {
      this.allColumns = TrueUpColumnConstants.AllMeterColumns;
      this.defaultColumns = TrueUpColumnConstants.DefaultMeterColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });

      this.columnsForExport = TrueUpColumnConstants.ExportMeterColumns
      this.emitSelectFilter(this.columnsForExport);
    }

    if (this.showSpaceLevelColumns) {
      this.allColumns = TrueUpColumnConstants.AllSpaceColumns;
      this.defaultColumns = TrueUpColumnConstants.DefaultSpaceColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });

      this.columnsForExport = TrueUpColumnConstants.ExportSpaceColumns;
      this.emitSelectFilter(this.columnsForExport);
    }
    if (this.showSystemLevelColumns) {
      this.allColumns = TrueUpColumnConstants.AllSystemColumns;
      this.defaultColumns = TrueUpColumnConstants.DefaultSystemColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });

      this.columnsForExport = TrueUpColumnConstants.ExportSystemColumns;
      this.emitSelectFilter(this.columnsForExport);
    }
    this.dataSource = new MonthlyTrueUpCalculationDataSourceBase(this.itemsSubject.asObservable());
  }

  getMonth(forMonth) {
    var month = new Date(forMonth);

    return (month.getMonth() + 1);
  }

  clear(filterType): void {
    switch (filterType) {
      case this.filterTypes.calendarMonth:
        this.filterRepresentation.CalendarMonth = null;
        break;
      case this.filterTypes.calendarYear:
        this.filterRepresentation.CalendarYear = null;
        break;
      case this.filterTypes.fiscalYear:
        this.filterRepresentation.FiscalYear = null;
        break;
      case this.filterTypes.fiscalQuarter:
        this.filterRepresentation.FiscalQuarter = null;
        break;
      case this.filterTypes.reportingQuarter:
        this.selectedReportingQuarter = null;
        this.filterRepresentation.ReportingQuarter = null;
        this.filterRepresentation.ReportingYear = null;
        break;
      case this.filterTypes.spaceId:
        this.filterRepresentation.SpaceId = null;
        break;
      case this.filterTypes.meterId:
        this.filterRepresentation.MeterId = null;
        break;
    }

    this.filter(true);
  }

  customizeColumns() {
    this.dialogRef = this.dialog.open(ColumnCustomizerDialogComponent, {
        height: '600px',
        width: '400px',
        data: {
            allColumns: this.allColumns,
            defaultColumns: this.defaultColumns,
            displayedColumns: this.displayedColumns
        }
    });

    this.dialogRef.afterClosed().subscribe(result => {
        if(result && result.length > 0) {
            this.displayedColumns = [];
            this.columnsForExport = [];
            result.forEach(column => {
              this.displayedColumns.push(column.constant);
            });


            this.columnsForExport = [];
            result.forEach(column => {
              this.columnsForExport.push(column.readable);
            });
            this.emitSelectFilter(this.columnsForExport);
        }
    });
  }

  removeColumns(columnsToRemove, displayedColumns) {
    columnsToRemove.forEach(column => {
      displayedColumns.splice(this.displayedColumns.findIndex(x => { return x == column}), 1);
    })
  }



  emitSelectFilter(columns: Array<string>) {
    var selectRepresentation = new ExportSelectRepresentation();
    selectRepresentation.Columns = columns;
    this.exportSelectEventEmitter.emit(selectRepresentation);
  }

  sortChanged(event) {
    if ((this.sort.direction != ''
      && this.pageProperties.paginationParameters.isAscending != (this.sort.direction == "asc"))
      || (this.sort.active != '' && this.pageProperties.paginationParameters.orderByColumn != this.sort.active)) {
      this.pageProperties.paginationParameters.isAscending = (this.sort.direction == "asc") || (this.sort.direction == '');
      this.pageProperties.paginationParameters.orderByColumn = this.sort.active;
    }
    this.filter(true);
  }

  initializeFilters() {
    this.clearFilters();
  }

  clearFilters() {
    this.selectedReportingQuarter = null;

    this.filterRepresentation = new MonthlyTrueUpCalculationFilterRepresentation();

    //reset sorting too
    this.pageProperties.paginationParameters.orderByColumn = null;
    this.pageProperties.paginationParameters.isAscending = true;

    this.filter(true);
  }

  filterButtonClicked() {
    this.filter(true);
  }

  countButtonClicked() {
    this.count();
  }

  emitExportFilterRepresentation(){
    this.exportFilterRepresentation.MonthlyTrueUpCalculationFilterRepresentation = this.filterRepresentation;
    this.exportFilterRepresentation.MonthlyTrueUpCalculationFilterProperties = this.pageProperties;
    this.exportFilterRepresentationEmitter.emit(this.exportFilterRepresentation);
  }

  filter(newFilterApplied: boolean = false) {
    this.pageProperties.filterRepresentation = this.filterRepresentation;
    if(newFilterApplied) {
      this.pageEvent.pageIndex = 0;
    }
    this.filterUpdated.next(this.pageProperties);
  }

  count(newFilterApplied: boolean = false) {
    this.pageProperties.filterRepresentation = this.filterRepresentation;
    if(newFilterApplied) {
      this.pageEvent.pageIndex = 0;
    }
    this.rowCountEmitter.next(this.pageProperties);
  }

  refreshDataSourceForPageEvent(event: PageEvent) {
    this.pageProperties.paginationParameters.pageIndex = event.pageIndex;
    this.pageProperties.paginationParameters.pageSize = event.pageSize;
    this.pageEvent = event;

    this.filter();
  }


  onSelectCalendarMonthChange(month) {
    this.filterRepresentation.CalendarMonth = month;
  }

  onSelectCalendarYearChange(year) {
    this.filterRepresentation.CalendarYear = year;
  }

  onSelectFiscalYearChange(year) {
    this.filterRepresentation.FiscalYear = year;
  }

  onSelectFiscalQuarterChange(quarter) {
    this.filterRepresentation.FiscalQuarter = quarter;
  }

  onSelectReportingQuarterChange(reportingQuarter:ReportingQuarter) {

    this.filterRepresentation.ReportingQuarter = reportingQuarter.reportingQuarter.toString();
    this.filterRepresentation.ReportingYear = reportingQuarter.reportingYear.toString();
  }

  onSpaceIdChange(spaceId) {
    this.filterRepresentation.SpaceId = spaceId;
  }

  onMeterIdChange(meterId) {
    this.filterRepresentation.MeterId = meterId;
  }

  changeInMonthSelected(status) {
    this.filterRepresentation.ChangeInMonth = status;
    if (status === "Change In Month") {
      this.filterRepresentation.ChangeInMonthFilterOperator =
        FilterOperator.Equal;
    } else if (status === "No Change In Month") {
      this.filterRepresentation.ChangeInMonthFilterOperator =
        FilterOperator.NotEqual;
    }
  }

  hasReservedPowerActionsSelected(status) {
    this.filterRepresentation.HasReservedPowerWorkOrderActions = status;
    if (status === "Has Reserved Power Actions") {
      this.filterRepresentation.HasReservedPowerWorkOrderActionsFilterOperator =
        FilterOperator.Equal;
    } else if (status === "Does Not Have Reserved Power Actions") {
      this.filterRepresentation.HasReservedPowerWorkOrderActionsFilterOperator =
        FilterOperator.NotEqual;
    }
  }

  hasCLZChangeSelected(status) {
    this.filterRepresentation.HasCLZChange = status;
    if (status === "Has CLZ Change") {
      this.filterRepresentation.HasCLZChangeFilterOperator =
        FilterOperator.Equal;
    } else if (status === "Does Not Have CLZ Change") {
      this.filterRepresentation.HasCLZChangeFilterOperator =
        FilterOperator.NotEqual;
    }
  }

  initializeSystemStartYear() {
    this.systemStartTimeService.getInitialSystemStartTime().subscribe(
      result => {
        this.systemStartDate = result;
        this.initializeYearSelectorValues();
        this.initializeReportingQuarterSelectorValues();
      },
      error => {
        console.log('error');
        this.errorHandler.handleError(error)
      }
    );
  }


  initializeYearSelectorValues() {
    var systemStartYear = this.systemStartDate.getFullYear();

    var currentYear = (new Date().getFullYear()) + 1; //go out one extra year

    for (var year = Number(currentYear); year >= systemStartYear; year--) {
      this.years.push(
        {
          value: year,
          viewValue: `${year}`
        });
    }
  }

  initializeQuarterSelectorValues() {
    for (var quarter = 1; quarter <= 4; quarter++) {
      this.quarters.push(
        {
          value: quarter,
          viewValue: `Q${quarter}`
        });
    }
  }

  initializeReportingQuarterSelectorValues() {
    this.reportingQuarterService.getReportingQuarters()
      .subscribe(quarters => {
        this.reportingQuarters = quarters.sort(ReportingQuarter.reportingQuarterSorter);
      },
      error => {
        this.errorHandler.handleError(error);
      });
  }

  initializeMonthSelectorValues() {
    for (var month = 1; month <= 12; month++) {
      this.months.push(
        {
          value: month,
          viewValue: `${MonthToMonthName[month]}`
        });
    }
  }

  navigateToRatePackagePage(ratePackageName: string) {
    window.open('/inventorymanagement/ratepackages/' + ratePackageName, 'noopener');
  }
}
