import { FilterOperator } from './../../../core/query-strings/query-string-builder';
import { BlockService } from './../blocks.service';
import { ZoneService } from './../../zones/zones.service';
import { Block, spaceType } from './../block';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AreaService } from '../../areas/areas.service';
import { Zone } from '../../zones/zone';
import { Area } from '../../areas/area';
import { AddressValidationService } from '../address-validation.service';
import { InventoryFilterRepresentation } from '../../inventory-filter/inventory-filter-representation';
import { StringExtensions } from '../../../string-extensions';
import { SpaceTypeConstants } from '../../meters/meter-constants';

@Component({
  selector: 'app-edit-block-dialog',
  templateUrl: './edit-block-dialog.component.html',
  styleUrls: ['./edit-block-dialog.component.css']
})
export class EditBlockDialogComponent implements OnInit {

  block: Block;
  areaDropDown = [];
  zoneDropDown = [];
  streetDirectionDropDown = ['E', 'S', 'W', 'N'];
  errorMessage:string; 

  originalBlockId: string;
  blockIdAlreadyExists: boolean = false;

  spaceTypeDropdown = [SpaceTypeConstants.DiagonalStriped, SpaceTypeConstants.DiagonalUnstriped, SpaceTypeConstants.Parallel, SpaceTypeConstants.PerpendicularStriped, SpaceTypeConstants.PerpendicularUnstriped];
  selectedSpaceTypes = [];

  constructor(private areaService: AreaService, private zoneService: ZoneService, private addressValidationService:AddressValidationService,
    public dialogRef: MatDialogRef<EditBlockDialogComponent>, private blockService: BlockService) { }

  ngOnInit() {
    this.selectedSpaceTypes = this.block.spaceTypes.map(s => s.id);

    this.zoneService.getAllZones()
        .subscribe(zoneResult => {
          this.zoneDropDown = zoneResult.sort((a: Zone, b: Zone) => Number(a.displayId) < Number(b.displayId) ? -1 : 1);          
          this.block.zone = this.zoneDropDown.filter(z => z.displayId == this.block.zoneName)[0];
          this.block.zoneId = this.block.zone.id;
          
          this.updateAreaOptions();
        });

    this.originalBlockId = this.block.displayId;
  }

  updateAreaOptions() {
    this.block.zoneId = this.block.zone.id;
    this.block.zoneName = this.block.zone.displayId;

    this.areaService.getAreasByZoneId(this.block.zoneId)
    .subscribe(result => {
      this.areaDropDown = result.sort((a: Area, b: Area) => a.displayId < b.displayId ? -1 : 1);
      this.block.area = this.areaDropDown.filter(a => a.id == this.block.areaId)[0];
    });
  }

  updateSelectedArea(event) {
    this.block.areaId = event.value.id;
    this.block.areaName = event.value.displayId;
  }

  checkIfBlockExists(blockId) {
    if(blockId == this.originalBlockId || StringExtensions.StringIsEmpty(blockId)) {
      this.blockIdAlreadyExists = false;
      return;
    }

    var blockFilter = new InventoryFilterRepresentation();
    blockFilter.BlockDisplayId = blockId;
    blockFilter.BlockDisplayIdFilterOperator = FilterOperator.Equal;
    this.blockService.getAllBlocks("currentStatus", true, 0, 25, blockFilter).subscribe(
      blocksReturned => {
        this.blockIdAlreadyExists = blocksReturned && blocksReturned.count > 0;
      }
    );
  }

  submit(){
    this.block.spaceTypes = this.spaceTypeDropdown.filter(s => this.selectedSpaceTypes.includes(s.id));

    var validationResult = this.addressValidationService.validateAddressIsValid(this.block.location.numberRangeStart, this.block.location.numberRangeEnd);
    if(validationResult.failed){
      this.errorMessage = validationResult.message;
    }
    else{
      this.dialogRef.close(this.block);
    }
  }
}
