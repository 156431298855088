exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".expansion-panel-table .mat-expansion-panel-header-title,\r\n.expansion-panel-table .mat-expansion-panel-header-description {\r\n  -ms-flex-preferred-size: 0;\r\n      flex-basis: 0;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n}\r\n\r\n.description .expansion-panel-table .mat-expansion-panel-header-description {\r\n  -ms-flex-pack: distribute;\r\n      justify-content: space-around;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n}\r\n\r\ndiv.mat-expansion-panel-content:not(.mat-expanded) {\r\n  height: 0px !important;\r\n  visibility: hidden !important;\r\n}\r\n\r\n.mat-expansion-panel-header {\r\n  height: 48px;\r\n}\r\n\r\n:host /deep/ .mat-select-trigger {\r\n    font-size: 18px !important;\r\n    height: inherit;\r\n}\r\n\r\n:host /deep/ .mat-input-placeholder {\r\n  font-size: 14px;\r\n}\r\n\r\n.mat-option {\r\n    font-size: 14px !important;\r\n}\r\n\r\n.mat-select {\r\n  margin-top: 4px;\r\n}\r\n\r\n.tab-view {\r\n  margin-top: -80px;\r\n}\r\n\r\n.expansion-panel-table {\r\n    margin-top: 36px;\r\n}\r\n\r\n.space-column-width {\r\n    -webkit-box-flex: 0;\r\n        -ms-flex: 0 0 60px;\r\n            flex: 0 0 60px;\r\n    -webkit-box-pack:center;\r\n        -ms-flex-pack:center;\r\n            justify-content:center;\r\n    padding: 8px 8px 8px 8px;\r\n  }\r\n\r\n.view-logs-button {\r\n  color: #607d8b;\r\n  background-color: white;\r\n}\r\n\r\n.pad-top {\r\n  margin-top:0px;\r\n}\r\n\r\n.card-margin {\r\n  margin-bottom:10px;\r\n}\r\n\r\n.distribution-amount-panel {\r\n  margin-right:61%;\r\n}\r\n\r\n.calculation-time-stamp {\r\n  margin-right:66%;\r\n}\r\n\r\n.space-id {\r\n  margin-left:28%;\r\n}\r\n\r\n.distribution-percentage {\r\n  margin-right:24%;\r\n}\r\n\r\n.distribution-amount {\r\n  margin-right:27%;\r\n}\r\n\r\n.full-utilization-amount {\r\n  margin-right:24%;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();