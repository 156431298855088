import { Component, OnInit, ErrorHandler, Output, EventEmitter } from '@angular/core';
import { SystemStartTimeService } from '../../../system-start-time.service';
import { MonthToMonthName } from '../../../inventory-management/meters/meter-true-up/models/month-name-dictionary';

@Component({
  selector: 'app-time-period-picker',
  templateUrl: './time-period-picker.component.html',
  styleUrls: ['./time-period-picker.component.css']
})
export class TimePeriodPickerComponent implements OnInit {

  constructor(private startTimeService: SystemStartTimeService, private errorHandler: ErrorHandler) { }

  years: number[] = [];
  selectedYear: number;

  months = [];
  selectedMonth: any;

  @Output()
  reportRequested: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  tableDataRequested: EventEmitter<Date> = new EventEmitter<Date>();

  ngOnInit() {
    this.startTimeService.getInitialSystemStartTime().subscribe(result => {
      for (let i = result.getFullYear(); i <= new Date().getFullYear(); i++) {
        this.years.push(i);
      }

      this.years = this.years.reverse();
    },
    error => {
      console.log('error');
      this.errorHandler.handleError(error);
    });

    for (let month = 1; month <= 12; month++) {
      this.months.push(
        {
          value: month,
          viewValue: `${MonthToMonthName[month]}`
        });
    }
  }

  requestReport() {
    if (this.selectedMonth && this.selectedYear) {
      const requestDate = new Date(this.selectedYear, this.selectedMonth - 1, 1);
      this.reportRequested.emit(requestDate);
    }
  }

  requestTableData() {
    if (this.selectedMonth && this.selectedYear) {
      const requestDate = new Date(this.selectedYear, this.selectedMonth - 1, 1);
      this.tableDataRequested.emit(requestDate);
    }
  }

}
