import { Injectable } from '@angular/core';
// import { Response } from '@angular/http';
import { HttpResponse } from '@angular/common/http';
import { HttpRequestOptions } from '../http-request-options';
import { FileDownloadResult } from '../models/file-download-result';

@Injectable()
export class FileDownloadService {

  constructor() { }

  public SaveFileToBrowser(file: FileDownloadResult) {
    var url = window.URL.createObjectURL(file.fileContent);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('href', url);
    link.setAttribute('download', file.fileName);
    link.setAttribute('target', 'noopener');
    link.click();
    link.remove();
  }

  public MapResponseToFileResult(response: HttpResponse<any>, fileName:string = null): FileDownloadResult {
    var result = new FileDownloadResult();

    var contentDispositionHeader = response.headers.get("content-disposition");

    //explicit file name first
    if (fileName) {
      result.fileName = fileName;
    }
    //try to get name from header
    else if (contentDispositionHeader) {

      var beginning = contentDispositionHeader.indexOf("filename=");

      var fileNameFragment = contentDispositionHeader.substr(beginning + 9);

      var end = fileNameFragment.indexOf(";");

      fileNameFragment = fileNameFragment.substr(0, end);

      //if there is whitespace, there will be quotes around the name
      if (fileNameFragment.startsWith("\"")) {
        fileNameFragment = fileNameFragment.substr(1);
      }

      if (fileNameFragment.endsWith("\"")) {
        fileNameFragment = fileNameFragment.substr(0, fileNameFragment.length - 1);
      }

      result.fileName = fileNameFragment;
    }
    //default unique name
    else {
      var now = new Date();
      result.fileName = `download_${now.toUTCString()}`;
    }

    result.fileContent = response.body;

    return result;
  }

}
