import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-work-order-field-input-dialog',
  templateUrl: './work-order-field-input-dialog.component.html',
  styleUrls: ['./work-order-field-input-dialog.component.css']
})
export class WorkOrderSingleFieldInputComponent implements OnInit {

  originalValue: string; 

  fieldName: string;
  fieldValue: string;
  dialogTitle: string;
  
  constructor(public dialogRef: MatDialogRef<WorkOrderSingleFieldInputComponent>) { }

  ngOnInit() {
    this.fieldValue = this.originalValue;
  }

}
