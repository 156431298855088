export class TimeZoneConverter{
    /**
     * EnsureChicagoTime
     * Used to ensure that dates submitted from the UI are Central Time
     * This can be an issue if the user's machine is on a different time zone
     * Matters for things like historical changes
     */
    public static EnsureChicagoTime(submittedDate:Date) :Date {

        //null check
        if(!submittedDate){
            return submittedDate;
        }

        //check the dates, make sure correct time zone
        var currentDate = new Date(submittedDate.toLocaleString("en-US"));        
        var currentDateChicago = new Date(submittedDate.toLocaleString("en-US", {timeZone: "America/Chicago"}));

        // 4/1/2017 12:00 AM ET - 3/31/2017 23:00 CT = 1 hour
        var differenceHours = currentDate.getTime() - currentDateChicago.getTime();

        if(differenceHours === 0){
            return submittedDate;
        }

        //add the difference back on to correct the date
        currentDate = (new Date((currentDate.getTime() + differenceHours)));        
        return currentDate;
    }

    /**
     * EnsureLocalTime
     * Used to ensure that dates shown the UI are displayed as user's local time
     * This can be an issue if the user's machine is on a different time zone
     * Matters for things like field discovery date
     */
    public static EnsureLocalTime(submittedDate:Date) :Date {

        //null check
        if(!submittedDate){
            return submittedDate;
        }

        //check the dates, make sure correct time zone
        var currentDate = new Date(submittedDate.toLocaleString("en-US"));        
        var currentDateChicago = new Date(submittedDate.toLocaleString("en-US", {timeZone: "America/Chicago"}));

        // 4/1/2017 12:00 AM ET - 3/31/2017 23:00 CT = 1 hour
        var differenceHours = currentDate.getTime() - currentDateChicago.getTime();

        if(differenceHours === 0){
            return submittedDate;
        }

        //subtract the difference back on to correct the date
        currentDate = (new Date((currentDate.getTime() - differenceHours)));        
        return currentDate;
    }
}