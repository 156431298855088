export const AlreadyTempRemoved = 'This meter is already temporarily removed.';

export const BackdatingRemovalDate = 'Please set the Removal Date to a date in the future.';

export const ChangeRatePackageTooManySpaces = 'You are changing the rate package of more spaces than exist on this meter. Please correct the number of spaces to change.';

export const ChangeRatePackageZeroSpaces = 'Must change rate package for at least 1 space';

export const EurZeroOnConcessionSpace = 'You are entering EUR as 0%. Please confirm that this Meter is Reserved, or enter a different EUR.';

export const EurBlankOnConcessionMeter = 'You are creating a meter with a concession contract type. Please enter an EUR.';

export const InstallingZeroSpaces = 'Must install at least 1 space';

export const MeterIdDoesNotExist = 'Meter Id does not exist.';

export const MeterIdTooShort = 'Invalid Meter Length.';

export const MeterIsNotActive = 'Meter is not active.';

export const MeterIsNotTempRemoved = 'Meter is not temporarily removed, please select the correct meter.';

export const MeterIsRemovedOrHasAConcessionContractType = 'Meter is removed or has a contract type of concession.';

export const MoreThanTwentySpaces = 'If you continue, there will be more than 20 spaces assigned to this meter.';

export const MoreThanTwentySpacesInstallMeter = 'If you continue, there will be more than 20 spaces assigned to this new meter.';

export const NotPhysicallyRemovingMeter = 'This meter will not be physically removed.';

export const NotPhysicallyReinstallingMeter = 'This meter will not be physically reinstalled';

export const RatePackageError = 'Please select a rate package that has the same rate/hour as the other rate packages on this meter.';

export const ReinstallingActiveMeter = 'This meter is not temporarily removed.';

export const RemovingLastSpace = 'You are removing all spaces. If you continue, this meter will also be permanently removed.';

export const RemovingTooManySpaces = 'You are removing more spaces than exist on this meter. Please correct the number of spaces to be removed.';

export const RemovingZeroSpaces = 'Must remove at least 1 space';

export const SetDueDate = 'You are changing the set due date. Please leave internal comments with reason for changing due date.';

export const ShiftingLastSpace = 'You are shifting all spaces. If you continue, this meter will also be permanently removed.';

export const ShiftingSpaceTooManySpaces = 'Number of spaces may not exceed number of active spaces on meter';

export const ShiftSpaceToDifferentBlocks = 'You are about to shift spaces to a meter that is on a different block. If you continue, these spaces will be around the corner from their new associated meter.';

export const ShiftSpaceSameMeter = 'Same meter is selected for To and From Meter fields.';

export const ShiftingTooManySpaces = 'You are shifting more spaces than exist on this meter. Please correct the number of spaces to be removed.';

export const ShiftingZeroSpaces = 'Must shift at least 1 space';

export const ShiftingToDifferentContractType = 'The contract type of the spaces and the meter you are shifting the spaces to does not match. Please make sure the contract type matches in order to shift these spaces to this meter.';

export const WrongNumberOfSpacesForChangingContractType = 'You have not changed the contract type for all active spaces on this meter. Please change the contract type and set the EUR for all active spaces';

export const InvalidRatePackageForMeter = "Please select a rate package that has the same rate/hour as the rate package on this meter's other spaces.";
