import { MatSort } from '@angular/material/sort';
import { Injectable } from '@angular/core';

@Injectable()
export class ColumnSorter {
    protected GetDefaultSortOrder(leftSortValue: number, rightSortValue: number,
        leftDisplayId: string, rightDisplayId: string,
        sort: MatSort): number {

        if (rightSortValue != leftSortValue) {
            if (sort.direction === '') {
                return (leftSortValue < rightSortValue ? -1 : 1);
            }
            return (leftSortValue < rightSortValue ? -1 : 1) * this.SortDirectionIsAscending(sort);
        }
        else {
            return this.CompareStrings(leftDisplayId, rightDisplayId, sort);
        }
    }

    protected CompareStrings(left: string, right: string, sort: MatSort): number {

        if (left === right) {
            return 0;
        }
        else if (left == null) {
            return this.SortDirectionIsAscending(sort) * 1;
        }
        else if (right == null) {
            return this.SortDirectionIsAscending(sort) * -1;
        }
        
        var sortNumber = (left < right ? -1 : 1) * this.SortDirectionIsAscending(sort);
        return sortNumber;
    }

    protected CompareNumbers(left: number, right: number, sort: MatSort): number {
        
                if (left === right) {
                    return 0;
                }
                else if (left == null) {
                    return this.SortDirectionIsAscending(sort) * 1;
                }
                else if (right == null) {
                    return this.SortDirectionIsAscending(sort) * -1;
                }
                
                var sortNumber = (left < right ? -1 : 1) * this.SortDirectionIsAscending(sort);
                return sortNumber;
            }

    protected SortDirectionIsAscending(sort: MatSort): number {
        var sortNumber = (sort.direction === 'asc' || sort.direction === '' ? 1 : -1);
        return sortNumber;
    }

}