export class Space {
    blockId: string;
    
    blockDisplayId: string;
    
    changeDate: string;
    
    currentMeterId: string;
    
    currentRevenueValue: string;

    currentStatus: string;
    
    currentUtilizationRate: string;
    
    dateActive: Date;
    
    dateChanged: Date;
    
    dateCreated: Date;
    
    dateRemoved: Date;

    displayId: string;
    
    expectedUtilizationRate: string;
    
    fineLevel: string;
    
    id: string;

    initialValueRevenueValue: string;
    
    initialYearUtilizationRate: string;

    isAroundCorner: boolean;
    
    location: Location;

    physicalBlockId: string;
    
    currentRatePackageId: string;
    
    reservePowerAction: string;
    
    trueUpContractType: string;
    
    workOrder: string;
    currentRatePackageName:string;

}
