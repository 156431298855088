import { Component, OnInit, ErrorHandler, OnDestroy } from '@angular/core';
import { LogMessage } from './log-message';
import { MatTableDataSource } from '@angular/material/table';
import { LogMessagesService } from '../log-messages.service';

@Component({
  selector: 'app-recent-log-messages',
  templateUrl: './recent-log-messages.component.html',
  styleUrls: ['./recent-log-messages.component.css']
})
export class RecentLogMessagesComponent implements OnInit, OnDestroy {

  dataSource: MatTableDataSource<LogMessage>;
  logMessages: LogMessage[];
  displayedColumns: Array<string>;
  loading: Boolean;
  lastStream: string;
  lastToken: string;
  lastChangeCount: number = 0;
  loadingSeconds: number = 0;

  refreshInterval: NodeJS.Timer;

  
  constructor(private logService: LogMessagesService, private _errorHandler: ErrorHandler) {
    this.logMessages = new Array<LogMessage>();
    this.loading = false;
  }

  ngOnInit() {
    this.displayedColumns = [
      'timeStamp',
      'message'
    ];
    this.getRecentMessages();
  }

  ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  forceRefreshRecentMessages() {
    //reset wait
    this.loading = true;
    this.lastChangeCount = 0;

    this.getRecentMessages();
  }

  getRecentMessages() {
    const messageCount: number = 100;
    const defaultLoadingSeconds: number = 10;

    this.loading = true;
    this.logService.getRecent(messageCount, this.lastStream, this.lastToken).subscribe(
      result => {

        if (this.lastToken != result.nextToken) {
          this.loadingSeconds = defaultLoadingSeconds;
          this.lastChangeCount = 0;
        }
        else {
          this.lastChangeCount++;
          this.loadingSeconds = Math.pow(2, this.lastChangeCount) * defaultLoadingSeconds;
        }

        //new stream, clean log
        if (this.lastStream != result.streamName) {
          this.logMessages = new Array<LogMessage>();
        }

        this.lastToken = result.nextToken;
        this.lastStream = result.streamName;
        this.logMessages = result.logMessages.concat(this.logMessages);

        if (this.logMessages.length > messageCount) {
          this.logMessages = this.logMessages.slice(0, (messageCount - 1));
        }

        this.dataSource = new MatTableDataSource<LogMessage>(this.logMessages);

        this.configureRefreshInterval();

        this.loading = false;
      },
      error => {
        this._errorHandler.handleError(error);

        this.lastToken = null;
        this.lastStream = null;
        this.logMessages = new Array<LogMessage>();

        this.dataSource = new MatTableDataSource<LogMessage>(this.logMessages);

        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }

        this.loading = false;
      });
  }

  private configureRefreshInterval() {
      if (this.refreshInterval == undefined) {
          this.refreshInterval = setInterval(x => {
              if (this.loading) {
                  return;
              }
              this.loadingSeconds--;
              if (this.loadingSeconds <= 0) {
                  this.getRecentMessages();
              }
          }, 1000);
      }
  }
}
