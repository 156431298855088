export class MeterStatusConstants {
    static active = 'Active';
    static activePending = 'Active - Pending';
    static inactive = 'Inactive';
    static removed = 'Removed';
}

export class MeterTypeConstants {
    static MP104 = { title: 'MP104', value: 'BA17A27B-0F84-49B7-AFED-C1BFE5AAE0A2'.toLocaleLowerCase()};
    static CLZVirtualID = { title: 'Virtual CLZ', value: '9E182A07-568D-4717-9FFB-C34DEBA5EA20'.toLocaleLowerCase()};
    static SSM = { title: 'SSM', value: '836EF62A-102B-4DE4-8493-4AFC6306F334'.toLocaleLowerCase()};
    static CWT = { title: 'CWT', value: 'FBD59648-82CB-4D97-85AD-4DAAF335DEE8'.toLocaleLowerCase()};
}

export class ClosureAllowanceConstants {
    static CBD = { title: 'CBD', value: '89899A48-98C0-4DEC-8AB1-2CC2A77F6A2A'.toLocaleLowerCase()};
    static NonCBD = { title: 'Non-CBD', value: '40A21AA7-14E1-42E7-BAC7-D77E4BC3A37B'.toLocaleLowerCase()};
}


export class ContractTypeConstants {
    static Concession = { title: 'Concession', value: 'E2AE7442-8A60-4E85-9FB6-109AD6424C67'.toLocaleLowerCase()};
    static Reserved = { title: 'Reserved', value: '8671995A-B5DE-4326-B1CA-FC4C024BC090'.toLocaleLowerCase()};
}

export class MerchantConstants {
    static Unknown = { title: 'UnknownId', value: '0F406615-05B8-419B-BDC0-5F550D18EE5B'.toLocaleLowerCase()};
    static Zone1 = { title: 'Zone 1', value: '4DB69348-9F77-4758-8FDB-3D066E5E1EB9'.toLocaleLowerCase()};
    static Zone2 = { title: 'Zone 2', value: '8B4370A7-4C59-4A11-AB7C-39E2E17DAB42'.toLocaleLowerCase()};
    static Zone3 = { title: 'Zone 3', value: '5FE72CCF-F53D-4035-AF07-4BE6962D7810'.toLocaleLowerCase()};
    static Zone4 = { title: 'Zone 4', value: '27D3478C-2AF6-47AE-AAC8-B4FAAEC3C086'.toLocaleLowerCase()};
    static Zone5 = { title: 'Zone 5', value: 'F0BBFBBC-C655-4B75-9545-56F9E5B9CB16'.toLocaleLowerCase()};
    static Zone6 = { title: 'Zone 6', value: '020F2981-9656-4987-8531-851BF95DCA9C'.toLocaleLowerCase()};
    static Zone7 = { title: 'Zone 7', value: '4C5D2CED-8DC1-4AA5-8ED2-E4B0B010FC99'.toLocaleLowerCase()};
    static Zone8 = { title: 'Zone 8', value: '902209E9-373B-4C60-A457-820ED306424C'.toLocaleLowerCase()};
    static Zone9 = { title: 'Zone 9', value: '0C7E76BB-4493-49C7-B71A-16DDC89D985C'.toLocaleLowerCase()};

    static AllMerchants = [MerchantConstants.Unknown, MerchantConstants.Zone1, MerchantConstants.Zone2,
                            MerchantConstants.Zone3, MerchantConstants.Zone4, MerchantConstants.Zone5,
                            MerchantConstants.Zone6, MerchantConstants.Zone7, MerchantConstants.Zone8,
                            MerchantConstants.Zone9];
}


export class ParkingTypeConstants {
    static OnStreet = { title: 'On Street', value: '76D2FC5D-1D45-4490-8F85-245513B4D7AC'.toLocaleLowerCase()};
    static ParkingLot = { title: 'Parking Lot', value: '26BBB322-7301-4607-BB70-E56352C82EA2'.toLocaleLowerCase()};
    static CLZ = { title: 'CLZ', value: 'D1AD376A-0F21-4B40-97A7-DE1F97B58B08'.toLocaleLowerCase()};
    static WrigleyvilleEventParking = { title: 'Wrigleyville Event Parking', value: '17828976-721B-49B4-838B-C9537378F0EE'.toLocaleLowerCase()};
}

export class SpaceTypeConstants {
    static Parallel = { name: 'Parallel', description: 'Parallel', id: 'de8237d8-7215-4a84-9f06-37d04f7d86d5'.toLocaleLowerCase()};
    static DiagonalStriped = { name: 'Diagonal-Striped', description: 'Diagonal-Striped', id: 'ae6d942c-b5d8-496c-9c42-e796972d060f'.toLocaleLowerCase()};
    static DiagonalUnstriped = { name: 'Diagonal-Unstriped', description: 'Diagonal-Unstriped', id: '3d96d677-29b8-4d92-aa3a-3d24ba537d81'.toLocaleLowerCase()};
    static PerpendicularStriped = { name: 'Perpendicular-Striped', description: 'Perpendicular-Striped', id: '46202add-0362-4ba4-b593-f7d6313fa1fb'.toLocaleLowerCase()};
    static PerpendicularUnstriped = { name: 'Perpendicular-Unstriped', description: 'Perpendicular-Unstriped', id: '71418013-7d33-4cd6-9c02-170f3a7bb68a'.toLocaleLowerCase()};
}

export class TempRemovalStatusConstants {
    static TempRemoved = 'Temp Removed';
    static NotTempRemoved = 'Not Temp Removed';
}

export class HasSignsOnlyConstants {
    static HasSignsOnly = 'Has Signs Only';
    static DoesNotHaveSignsOnly = 'Does Not Have Signs Only';
}

export class FoundationTypeConstants {
    static Drill = 'Drill';
    static Pad = 'Pad';
}



