exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "hr {\r\n  display: block;\r\n  margin: 10px 0 10px 0;\r\n  border-top: 1px solid rgba(0, 0, 0, .12);\r\n  width: 100%\r\n}\r\n\r\ndiv {\r\n  padding-bottom: 2%;\r\n}\r\n\r\nspan.title {\r\n  padding-bottom: 25px;\r\n}\r\n\r\n.active-chip {\r\n  background-color: #00c853;\r\n  color: white;\r\n}\r\n\r\n.inactive-chip {\r\n  background-color: #607d8b;\r\n  color: white;\r\n}\r\n\r\n.temp-removed-chip {\r\n  background-color: orange;\r\n  color: white;\r\n}\r\n\r\n.mat-tab-body {\r\n  overflow-y: hidden;\r\n}\r\n\r\na {\r\n  cursor: pointer;\r\n}\r\n\r\nmat-chip {\r\n  height: 12.5px;\r\n}\r\n\r\n.mat-tab-body-content {\r\n  padding:0 !important;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();