import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {


  constructor() { }

  @Input() loadingObject: string;
  @Input() loading: boolean;
  @Input() errorMessage: any;
  @Input() prefixWord = 'Loading';

}
