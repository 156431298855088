import { UtcDatePipe } from './../../core/utc-date-pipe/utc-date-pipe';
import { EditedCollection } from './models/edited-collection';
import { EditCollectionFormComponent } from './edit-collection-form/edit-collection-form.component';
import { OnInit, ViewChild, Component } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditedCollectionsService } from './services/edited-collections-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edited-collections',
  templateUrl: './edited-collections.component.html',
  styleUrls: ['./edited-collections.component.css']
})
export class EditedCollectionsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;

  displayedColumns = ['meterId', 'newCollectionDate', 'oldCollectionDate', 'newCollectionAmount', 'oldCollectionAmount', 'editedByUsername', 'editedDate', 'comments', 'collectionId'];
  collectionsLoading = true;

  constructor(private _editedCollectionsService: EditedCollectionsService, private snackbar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit() {
    this.getCollections();
  }

  getCollections() {
    this._editedCollectionsService.getAllEditedCollections()
      .subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
        this.collectionsLoading = false;
      });
  }

  viewCollectionDetails(): void {
      const dialogRef = this.dialog.open(EditCollectionFormComponent, {
        width: 'auto',
        height: 'auto'
      });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackbar.open('Edited collection saved', '', <MatSnackBarConfig> {duration: 2000});
        this.getCollections();
      }
    });
  }

}
