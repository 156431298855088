exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".create-buttons {\r\n    margin-top: 2%;\r\n}\r\n\r\n.create-node {\r\n    margin-bottom: 2%;\r\n    margin-top: 2%;\r\n}\r\n\r\n.button-group {\r\n    margin-top: 3%;\r\n}\r\n\r\n.id-error {\r\n    font-style: italic;\r\n    color: red;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();