import { WorkOrderStatusType, WorkOrder } from '../../models/work-order';
import { TypeOfActionToApproveConstants } from '../../models/approval-models';

export class BulkChangesComponentBase {
    effectiveDate: Date;
    workOrderStatusId: number;
    workOrder: WorkOrder;

    activateButtonEnabled() {
        return (this.effectiveDate && this.workOrderStatusId === WorkOrderStatusType.InventoryChangesApprovedId) 
        || (this.effectiveDate && this.hasHistoricalChange() && this.workOrderStatusId === WorkOrderStatusType.AcceptedId);
    }

    hasHistoricalChange(): boolean {
        if (!this.workOrder) {
            return false;
        }

        const hasHistoricalChange = this.workOrder.workOrderResponses.find(x => {
            return x.typeOfActionToApprove.toLowerCase() === TypeOfActionToApproveConstants.historicalChange.toLowerCase();
        });

        return hasHistoricalChange !== null && hasHistoricalChange !== undefined;
    }
    
}
