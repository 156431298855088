import { ErrorHandler, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as moment from 'moment';

import { AnnualPercentageAdjustmentValue } from './../annual-percentage-adjustment-value';
import { TrueUpHttpService } from './../../../true-up-http.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class AnnualPercentageAdjustmentValueService {

    constructor(private trueUpDataHttpService: TrueUpHttpService, protected errorHandler: ErrorHandler) { }

    public getAllAnnualPercentageAdjustmentValues(): Observable<AnnualPercentageAdjustmentValue[]> {

        return this.trueUpDataHttpService.get('/AnnualPercentageAdjustment', null)
          .pipe(
            map(response => response),
            catchError(error => this.handleError(error))
          );
    }

  public saveAnnualPercentageAdjustmentValue(param: AnnualPercentageAdjustmentValue){

    if(param.id){
      return this.trueUpDataHttpService.put('/AnnualPercentageAdjustment/' + param.id + '/Update', param)
        .pipe(
          map((response) => response),
          catchError(error => Observable.throwError(error))
        );
    }
    else{
      return this.trueUpDataHttpService.post('/AnnualPercentageAdjustment/Create', param)
        .pipe(
          map((response) => response),
          catchError(error => Observable.throwError(error))
        );
    }
  }

    private handleError(error: any): Promise<any> {
      this.errorHandler.handleError(error);
      return Promise.reject(error.message || error);
    }
  }

