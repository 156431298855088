import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-can-deactivate-dialog',
  templateUrl: './can-deactivate-dialog.component.html',
  styleUrls: ['./can-deactivate-dialog.component.css']
})
export class RatePackageConfirmationDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<RatePackageConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  mode: string;
  name: string;
  description: string;

  ngOnInit() {
    this.mode = this.data.mode;
    this.name = this.data.name;
    this.description = this.data.description;
  }
}
