import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenCache } from '../../security/token-cache';

@Component({
  selector: 'app-meter-true-up-detail',
  templateUrl: './meter-true-up-detail.component.html',
  styleUrls: ['./meter-true-up-detail.component.css']
})
export class MeterTrueUpDetailComponent implements OnInit  {

  showRunTab: boolean = false;

  constructor(public snackBar: MatSnackBar, private tokenCache: TokenCache) {
  }

  ngOnInit(): void {
    this.showRunTab = this.tokenCache.checkForCPMAdmin();
  }

}

