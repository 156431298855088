import { Component, OnInit, Input, OnChanges, ErrorHandler } from '@angular/core';
import { WorkOrderHistoryComponent, ActionHistoryDictionary } from '../../work-order-history/work-order-history.component';
import { MatTableDataSource } from '@angular/material/table';
import { Space } from '../space';
import { SpaceService } from '../spaces.service';
import { WorkOrdersService } from '../../../work-orders/services/work-orders.service';
import { Router } from '@angular/router';
import { SpaceWorkOrderHistory } from './space-work-order-history';
import { DateTimeUtility } from '../../meters/meter-true-up/models/month-name-dictionary';
import { SystemStartTimeService } from '../../../system-start-time.service';

@Component({
  selector: 'app-space-work-order-history',
  templateUrl: './space-work-order-history.component.html',
  styleUrls: ['./space-work-order-history.component.css']
})
export class SpaceWorkOrderHistoryComponent extends WorkOrderHistoryComponent<SpaceWorkOrderHistory> implements OnInit, OnChanges {

  @Input() space: Space;
  workOrderHistories: SpaceWorkOrderHistory[] = [];
  dataSource = new MatTableDataSource<ActionHistoryDictionary>();
  systemStartDate: Date;

  constructor(private _spaceService: SpaceService, _workOrderService: WorkOrdersService, router: Router,
    private systemStartTimeService: SystemStartTimeService,
    private _errorHandler: ErrorHandler) {
    super(_workOrderService, router);
   }

  ngOnInit(): void {
    this.systemStartTimeService.getInitialSystemStartTime().subscribe(
      result => {
        this.systemStartDate = result;
        this.refreshDataSource();
      },
      error => {
        console.log('error');
        this._errorHandler.handleError(error);
      }
    );

  }

  ngOnChanges() {
    if (this.space) {
      const workOrderActionIds = new Array<string>();
      this._spaceService.getSpacesWorkOrderHistories(this.space.id)
      .subscribe(result => {
        result.filter(x => x.changeType !== 'Expected Utilization Rate').forEach(history => {
            if (history.effectiveTimeStamp) {
            if (DateTimeUtility.compareDate(this.systemStartDate, history.effectiveTimeStamp)) {
              if (history.isExecuted) {
                history.workOrderName = 'Intial Value';
              }
            }
          }
          if (history.workOrderActionId) {
            workOrderActionIds.push(history.workOrderActionId);
          }

          const nonUtcDate = new Date(history.effectiveTimeStamp);
          history.effectiveDate = new Date(nonUtcDate.getFullYear(), nonUtcDate.getUTCMonth(), nonUtcDate.getUTCDate());

          this.workOrderHistories.push(history);
        });
        if (workOrderActionIds.length > 0) {
          this.getWorkOrderActionIds(workOrderActionIds);
        }

        this.workOrderHistories.forEach(workOrderHistory => {
          const actionHistory = this.actionHistoryDictionaries.filter(actionHistory => actionHistory.actionId === workOrderHistory.workOrderActionId);
          if (workOrderHistory.changeType.toLowerCase() !== 'location type') {
            if (actionHistory.length > 0) {
              actionHistory[0].histories.push(workOrderHistory);
            }
            else {
              this.actionHistoryDictionaries.push({ actionId: workOrderHistory.workOrderActionId, histories: [workOrderHistory] });
            }
          }
      });
        this.UpdateDataSource();
      });
    }
  }

  UpdateDataSource() {
    this.dataSource = new MatTableDataSource<ActionHistoryDictionary>(this.actionHistoryDictionaries);
    this.refreshDataSource();
  }
}
