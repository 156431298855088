import { InventoryListSummary } from './inventory-list-summary';
import { Component, OnInit, Inject, Optional, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { InventoryFilterRepresentation } from '../inventory-filter/inventory-filter-representation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { InventoryListJsonConverter } from './inventory-list-json-converter';
import { ExportInventoryFilterRepresentation, ExportSelectRepresentation } from '../inventory-filter/export-representations';
import { PaginationParameters } from '../../core/models/pagination-paramters';
import { MeterService } from '../meters/meters.service';
import { SpaceService } from '../spaces/spaces.service';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { Column } from './inventory-list/inventory-list-column-def';

@Component({
  selector: 'app-inventory-table',
  templateUrl: './inventory-table.component.html',
  styleUrls: ['./inventory-table.component.css']
})
export class InventoryTableComponent implements OnInit {

  @Input() mode: string;
  @Input() context: string;
  @Input() blockId: string;
  @Input() meterId: string;
  @Input() filter: InventoryFilterRepresentation;
  @Input() hideExportButton: boolean;
  
  @Output()
  inventoryCount: EventEmitter<number> = new EventEmitter<number>();

  filterRepresentationSubject = new Subject<InventoryFilterRepresentation>();
  zeroInventorys = false;

  selectedInventoryItem: InventoryListSummary;
  object: string;

  paginationParams: PaginationParameters;
  exportInventoryFilter: ExportInventoryFilterRepresentation;
  exportSelectRep: ExportSelectRepresentation;
  displayedColumns: Array<string>;
  columnsToExport: Array<string>;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<InventoryTableComponent>, private meterService: MeterService, private spaceService: SpaceService,
    private fileDownloadService: FileDownloadService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.paginationParams = new PaginationParameters();
    this.exportInventoryFilter = new ExportInventoryFilterRepresentation();

    if (this.data) {
      this.context = this.data.context;
      this.meterId = this.data.meterId;
      if (this.context.toLowerCase().includes('meter')) {
        this.object = 'Meter';
      }
      else if (this.context.toLowerCase().includes('space')) {
        this.object = 'Space';
      }
      else if (this.context.toLowerCase().includes('block')) {
        this.object = 'Block';
      }
    }
  }

  inventoryCountChanged(event: number) {
    this.zeroInventorys = event < 1;
    this.inventoryCount.emit(event);
  }

  inventorySelected(event: InventoryListSummary) {
    this.selectedInventoryItem = event;
  }

  getInventoryFilter(evt: InventoryFilterRepresentation) {
    this.filterRepresentationSubject.next(evt);
  }

  getPaginationParameters(evt: PaginationParameters) {
    this.paginationParams = evt;
  }

  getExportInventoryFilter(evt: ExportInventoryFilterRepresentation) {
    this.exportInventoryFilter = evt;
    this.export(this.exportInventoryFilter);
  }

  getExportSelect(evt: ExportSelectRepresentation) {
    this.exportSelectRep = evt;
  }
  
  export(exportInventoryFilter: ExportInventoryFilterRepresentation) {
      let reportType;
      if (!this.paginationParams.orderByColumn) {
        this.paginationParams.orderByColumn = 'displayId';
      }
      if (this.paginationParams.isAscending === undefined) {
        this.paginationParams.isAscending = true;
      }
      if (exportInventoryFilter.ForMeter) {
          // export for meter
        reportType = 'meter';

        this.snackBar.open('Downloading Meter Report', '', <MatSnackBarConfig>{
          duration: 2000
        });
        return this.meterService.exportMeters(this.paginationParams.orderByColumn, this.paginationParams.isAscending, this.exportInventoryFilter.InventoryFilterRepresentation, this.exportSelectRep).subscribe(result => {
          const fileDownloadResult = this.fileDownloadService.MapResponseToFileResult(result);
          this.fileDownloadService.SaveFileToBrowser(fileDownloadResult);
        });
      }
      else if (exportInventoryFilter.ForSpace) {
        // export for space
        reportType = 'space';

        this.snackBar.open('Downloading Space Report', '', <MatSnackBarConfig>{
          duration: 2000
        });
        return this.spaceService.exportSpaces(this.paginationParams.orderByColumn,
          this.paginationParams.isAscending, this.exportInventoryFilter.InventoryFilterRepresentation, this.exportSelectRep).subscribe(result => {
            const fileDownloadResult = this.fileDownloadService.MapResponseToFileResult(result);
            this.fileDownloadService.SaveFileToBrowser(fileDownloadResult);
        });
      }
  }

  selectInventory() {
    let inventoryItemToReturn;
    if (this.object === 'Block') {
      inventoryItemToReturn = InventoryListJsonConverter.inventoryItemToMeter(this.selectedInventoryItem);
    }
    else if (this.object === 'Meter') {
      inventoryItemToReturn = InventoryListJsonConverter.inventoryItemToSpace(this.selectedInventoryItem);
    }
    else if (this.object === 'Space') {
      inventoryItemToReturn = InventoryListJsonConverter.inventoryItemToBlock(this.selectedInventoryItem);
    }
    this.dialogRef.close(inventoryItemToReturn);
  }

  close() {
    this.dialogRef.close();
  }

  customizeDisplayedColumns(displayedColumns: Array<string>) {
    this.displayedColumns = displayedColumns;
  }

  customizeColumnsForExport(columnsToExport: Array<string>) {
    this.columnsToExport = columnsToExport;
  }
}
