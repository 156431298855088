import { TrueUpQueueApiResult } from './true-up-queue-api-result';
import { TrueUpHttpService } from '../../true-up-http.service';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryStringBuilder } from '../../core/query-strings/query-string-builder';
import { EngineRunRequest } from './engine-run-request';
import { TrueUpQueueMessage } from './true-up-queue-message';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TrueUpCalculationService {

    constructor(private trueUpDataHttpService: TrueUpHttpService, protected errorHandler: ErrorHandler) { }

  getAll(): Observable<EngineRunRequest[]> {
      var queryString = '';

      var endpoint = '/TrueUpCalculationRun';
      return this.trueUpDataHttpService.get(`${endpoint}?${queryString}`, null)
        .pipe(catchError((error) => this.handleError(error)));
    }

    finalizeTrueUpCalculationRun(id: string) : Observable<string> {
      var endpoint = '/TrueUpCalculationRun';
      var endpoint = '/TrueUpCalculationRun';
      return this.trueUpDataHttpService.put(`${endpoint}/${id}/finalize`, null)
        .pipe(
          map(response => response),
          catchError((error) => this.handleError(error)));
    }

    runTrueUpEngine(trueUpQueueMessage: TrueUpQueueMessage): Observable<any> {
      return this.trueUpDataHttpService.post(`/TrueUpQueue/${trueUpQueueMessage.reportingYear}/${trueUpQueueMessage.reportingQuarter}`, {})
        .pipe(catchError((error) => this.handleError(error)));
    }

    testTrueUpEngine(trueUpQueueMessage: TrueUpQueueMessage): Observable<any> {
      return this.trueUpDataHttpService.post(`/TrueUpQueue/${trueUpQueueMessage.reportingYear}/${trueUpQueueMessage.reportingQuarter}/runperiod`, {})
        .pipe(catchError((error) => this.handleError(error)));
    }

    checkIfEngineIsInProcess() : Observable<any> {
      return this.trueUpDataHttpService.get(`/TrueUpQueue/inprocess`, null);
    }

    private handleError(error: any): Promise<any> {
      this.errorHandler.handleError(error);
      return Promise.reject(error.message || error);
    }
  }

