import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpaceClosuresComponent } from './space-closures/space-closures.component';
import { InventoryTimelineModule } from './../inventory-components/inventory-timeline/inventory-timeline.module';
import { WorkOrderHistoryModule } from './../work-order-history/work-order-history.module';
import { RatePackagesModule } from './../rate-packages/rate-packages.module';
import { CoreModule } from '../../core/core.module';
import { SpaceDetailComponent } from './space-detail/space-detail.component';
import { SpaceService } from './spaces.service';
import { MeterService } from './../meters/meters.service';
import { RatePackageService } from './../rate-packages/services/rate-package.service';
import { SpaceSummarySorter } from './space-summary-sorter';
import { SpaceWorkOrderHistoryComponent } from './space-work-order-history/space-work-order-history.component';
import { EditSpaceDialogComponent } from './edit-space-dialog/edit-space-dialog.component';
import { SpaceAuditComponent } from './space-audit/space-audit.component';
import { TrueupModule } from './../../trueup/trueup.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RatePackagesModule,
    WorkOrderHistoryModule,
    InventoryTimelineModule,
    TrueupModule
  ],
  exports: [
    SpaceAuditComponent,
    SpaceClosuresComponent,
    SpaceDetailComponent,
    SpaceWorkOrderHistoryComponent
  ],
  declarations: [
    SpaceAuditComponent,
    SpaceClosuresComponent,
    SpaceDetailComponent,
    SpaceWorkOrderHistoryComponent,
    EditSpaceDialogComponent,
  ],
  entryComponents: [],
  providers: [
    MeterService,
    SpaceService,
    SpaceSummarySorter,
    RatePackageService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [EditSpaceDialogComponent],
})
export class SpacesModule { }
