import { DaysOfWeekAbbreviated, DayAbbreviatedDictionary } from '../rate-package-create/rate-package-constants';
import { HoursOfRestriction } from './../models/hours-of-restriction';
import { HoursOfOperation } from './../models/hours-of-operation';
import { RatePackage } from './../models/rate-package';
import { ScheduleBlocks } from '../models/schedule-blocks';

declare var google: any;
let rp: any;

export class RatePackageChartUtility {

  static generateSeasonalSuffix(block: any): string {
    let seasonalStringSuffix;
    if (block.seasonalDayStart && block.seasonalDayEnd) {
      seasonalStringSuffix = ' - SEASONAL - ' + block.seasonalMonthStart + '/' + block.seasonalDayStart + ' - ' + block.seasonalMonthEnd + '/' + block.seasonalDayEnd;
    }
    else if (block.seasonalHolidayStart && block.seasonalHolidayEnd) {
      seasonalStringSuffix = ' - SEASONAL - ' + block.seasonalHolidayStart + ' - ' + block.seasonalHolidayEnd;
    }
    else {
      seasonalStringSuffix = ' - SEASONAL';
    }

    if (!block.restrictionType) {
      seasonalStringSuffix = ' hr) ' + seasonalStringSuffix;
    }

    return seasonalStringSuffix;
  }

  static generateChartColorList(startTimeArray: any[]): string[] {
    const red = '#B71C1C';
    const orange = '#E64A19';
    const blue = '#1E88E5';
    const purple = '#7E57C2';
    const parkingGreen = '#388E3C';
    const graphColorList = [];


    const sortedStartTimesForColorList = startTimeArray.sort((a, b) => {
      if (a.startTime < b.startTime) { return -1; }
      else if (a.startTime > b.startTime) { return 1; }
      else { return 0; }
    });

    if (sortedStartTimesForColorList[1] && sortedStartTimesForColorList[1].type !== 'Operation' && sortedStartTimesForColorList[0].type === 'Operation'
        && sortedStartTimesForColorList[0].startTime.getTime() === sortedStartTimesForColorList[1].startTime.getTime()) {
      const operationPlaceholder = sortedStartTimesForColorList[0];
      const restrictionPlaceholder = sortedStartTimesForColorList[1];
      sortedStartTimesForColorList[0] = restrictionPlaceholder;
      sortedStartTimesForColorList[1] = operationPlaceholder;
    }

    sortedStartTimesForColorList.forEach(startTime => {
      if (startTime.isSeasonal) {
        graphColorList.push(startTime.type.includes('Restriction') ? purple : blue);
      }
      else if (startTime.type === 'RestrictionRushHour' || startTime.type === 'RestrictionRush Hour') {
        graphColorList.push(red);
      }
      else if (startTime.type && startTime.type.includes('Restriction')) {
        graphColorList.push(orange);
      }
      else {
        if (startTime.rate < 2) {
          graphColorList.push('#00e600');
        }
        else if (startTime.rate < 4) {
          graphColorList.push('#00b300');
        }
        else {
          graphColorList.push('#008000');
        }
      }
    });

    if (graphColorList.length === 0) {
      graphColorList.push(parkingGreen);
    }

    return graphColorList;
  }

  static generateBlankChart(): void {
    google.charts.load('visualization', '1', {'packages': ['timeline']});
    google.charts.setOnLoadCallback(this.createBlankChart);
  }

  static createBlankChart(): void {

    const today = new Date();

    const data = new google.visualization.DataTable();

    data.addColumn('string', 'Day');
    data.addColumn('string', 'Rate');
    data.addColumn('date', 'Rate Start Time');
    data.addColumn('date', 'Rate End Time');

    DaysOfWeekAbbreviated.forEach(dayOfWeek => {
      data.addRow(
        [dayOfWeek, '',
          new Date(today.getFullYear(), today.getMonth(), dayOfWeek === 'Mon' ? today.getDate() + 1 : today.getDate()),
          new Date(today.getFullYear(), today.getMonth(), dayOfWeek === 'Mon' ? today.getDate() + 1 : today.getDate())
        ]);
    });

    const options = {
      height: 300,
      tooltip: {
        isHtml: true
      },
      timeline: {
        groupByRowLabel: true,
        rowLabelStyle: {fontName: 'Trebuchet MS', fontSize: 10},
        barLabelStyle: {fontName: 'Trebuchet MS', fontSize: 10},
        displayLegendValues: true
      },
      hAxis: {
        minValue: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        maxValue: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
      }
    };

    const chart = new google.visualization.Timeline(document.getElementById('chart_div'));
    chart.draw(data, options);

    // Hide empty rows
    const allRows = document.getElementsByTagName('rect');
    let width = 5;
    let rowsToRemove = [];
    for (let i = 0; i < allRows.length; i++) {
        const cwidth = parseInt(allRows[i].getAttribute('width'));
        if (cwidth < width) {
          rowsToRemove = [allRows[i]];
            width = cwidth;
        }
        else if (cwidth === width) {
          rowsToRemove.push(allRows[i]);
        }
    }
    for (let i = 0; i < rowsToRemove.length; i++) {
      rowsToRemove[i].setAttribute('fill', 'none');
    }
  }

  static generateChart(ratePackage: any, isSeasonal: boolean, hoursOfOperation: HoursOfOperation) {
    rp = ratePackage;
    rp.isSeasonal = isSeasonal;
    rp.seasonalHoursOfOperation = hoursOfOperation;
    google.charts.load('visualization', '1', {'packages': ['timeline']});
    google.charts.setOnLoadCallback(this.drawChart);
  }

  static drawChart() {
    const today = new Date();
    const data = new google.visualization.DataTable();
    // TODO
    data.addColumn('string', 'Day');
    data.addColumn('string', 'Rate');
    data.addColumn('date', 'Rate Start Time');
    data.addColumn('date', 'Rate End Time');

    const scheduleBlocksByDay = {
      Mon: [],
      Tue: [],
      Wed: [],
      Thurs: [],
      Fri: [],
      Sat: [],
      Sun: [],
    };

    rp.hoursOfOperation.weeklyDetail.forEach(hoo => {
      if (hoo.value.scheduleDetails) {
          hoo.value.scheduleDetails.forEach(scheduleBlock => {
            scheduleBlock.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlock.startTimeHours, scheduleBlock.startTimeMinutes);
            scheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlock.endTimeHours, scheduleBlock.endTimeMinutes);
            if (scheduleBlock.periodOfStay < 24) {
              scheduleBlock.periodOfStay = scheduleBlock.periodOfStay * 60;
            }
            const abbreviatedDate = DayAbbreviatedDictionary[hoo.key];
            scheduleBlocksByDay[abbreviatedDate].push(scheduleBlock);
          });
        }
      else {
        const abbreviatedDate = DayAbbreviatedDictionary[hoo.key];
        data.addRows([[abbreviatedDate, '', new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1), new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)]]);
      }

    });

    if (rp.hoursOfRestriction) {
        rp.hoursOfRestriction.weeklyDetail.forEach(hor => {
          if (hor.value.scheduleDetails) {
            hor.value.scheduleDetails.forEach(scheduleBlock => {
              scheduleBlock.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlock.startTimeHours, scheduleBlock.startTimeMinutes);
              if (scheduleBlock.endTimeHours === 0 && scheduleBlock.endTimeMinutes === 0) {
                scheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, scheduleBlock.endTimeHours, scheduleBlock.endTimeMinutes);
              }
              else {
                scheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlock.endTimeHours, scheduleBlock.endTimeMinutes);
              }

              if (!scheduleBlock.restrictionType) {
                scheduleBlock.restrictionType = 'Rush Hour';
              }
              const abbreviatedDate = DayAbbreviatedDictionary[hor.key];
              scheduleBlocksByDay[abbreviatedDate].push(scheduleBlock);
            });
            }
        });
    }

    if (rp.isSeasonal) {
      rp.seasonalHoursOfOperation.weeklyDetail.forEach(hoo => {
        if (hoo.value.scheduleDetails) {
          hoo.value.scheduleDetails.forEach(scheduleBlock => {
            if (scheduleBlock.seasonalDayStart || scheduleBlock.seasonalHolidayStart) {

            const startDateConverted = new Date(scheduleBlock.seasonalRateStart);
            const endDateConverted = new Date(scheduleBlock.seasonalRateEnd);

              scheduleBlock.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlock.startTimeHours, scheduleBlock.startTimeMinutes);
              if (scheduleBlock.endTimeHours === 0 && scheduleBlock.endTimeMinutes === 0) {
                scheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, scheduleBlock.endTimeHours, scheduleBlock.endTimeMinutes);
              }
              else {
                scheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlock.endTimeHours, scheduleBlock.endTimeMinutes);
              }

              const abbreviatedDate = DayAbbreviatedDictionary[hoo.key];
              // scheduleBlocksByDay[abbreviatedDate].push(scheduleBlock);
            }
          });
          }
      });
    }

    const startTimeArray = new Array<any>();

    DaysOfWeekAbbreviated.forEach(day => {
      scheduleBlocksByDay[day] = scheduleBlocksByDay[day].sort((a, b) => {
        if (a.startTime < b.startTime) { return -1; }
        else if (a.startTime > b.startTime) { return 1; }
        else { return 0; }
      });

      if (scheduleBlocksByDay[day].length === 0) {
        data.addRows([[day, '', new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1), new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)]]);
      }

      scheduleBlocksByDay[day].forEach(block => {
        let initialStartTime;
        if (block.endTime.getHours() === 0) {
          block.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, block.endTime.getHours(), block.endTime.getMinutes());
        }
        const futureRestrictions = scheduleBlocksByDay[day].filter(day => {
          if (!day.restrictionType) {
            return false;
          }
          if (day.startTime.getTime() > block.startTime.getTime()) {
            return true;
          }
          else if (day.startTime.getTime() === block.startTime.getTime() && day.endTime.getTime() < block.endTime.getTime()) {
            initialStartTime = block.startTime;
            block.startTime = day.endTime;
            return true;
          }
        });

        let nextRestriction;
        let twoRestrictionsAhead;
        if (futureRestrictions.length > 0) {
          nextRestriction = futureRestrictions[0];
          if (futureRestrictions.length > 1 && !(futureRestrictions[0].startTime.getTime() === futureRestrictions[1].startTime.getTime() &&
            futureRestrictions[0].endTime.getTime() === futureRestrictions[1].endTime.getTime()
            && futureRestrictions[0].seasonalDayStart === futureRestrictions[1].seasonalDayStart && futureRestrictions[0].seasonalDayEnd === futureRestrictions[1].seasonalDayEnd &&
            futureRestrictions[0].seasonalMonthStart === futureRestrictions[1].seasonalMonthStart && futureRestrictions[0].seasonalMonthEnd === futureRestrictions[1].seasonalMonthEnd
            && futureRestrictions[0].seasonalHolidayStart === futureRestrictions[1].seasonalHolidayStart && futureRestrictions[0].seasonalHolidayEnd === futureRestrictions[1].seasonalHolidayEnd)
            && futureRestrictions[0].restrictionType === futureRestrictions[1].restrictionType) {
              twoRestrictionsAhead = futureRestrictions[1];
          }
        }
        else {
          nextRestriction = null;
          twoRestrictionsAhead = null;
        }
        // If the next block is a restriction and the current block ends sometime before or after the start of the restriction
        // you need to either end it at the start of the restriction or create a new schedule block after the restriction for the chart
        if (nextRestriction && (block.endTime.getTime() > nextRestriction.startTime.getTime())) {

            // If next restriction starts at the same time as schedule block continue and do not adjust end-time
            if (initialStartTime && initialStartTime.getTime() === nextRestriction.startTime.getTime()) {
              return;
            }
            // If schedule block ends during the next block (restriction)
            else if (block.endTime.getTime() <= nextRestriction.endTime.getTime()) {
              block.endTime = nextRestriction.startTime;
            }
            // If schedule block ends after the end of the next block (restriction)
            else if (block.endTime.getTime() > nextRestriction.endTime.getTime()) {
              block.endTime = nextRestriction.startTime;

              const newScheduleBlock = new ScheduleBlocks();
              newScheduleBlock.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), nextRestriction.endTime.getHours(), nextRestriction.endTime.getMinutes());
              newScheduleBlock.endTime = block.endTime;
              if (twoRestrictionsAhead) {
                if (newScheduleBlock.endTime.getTime() > nextRestriction.endTime.getTime()) {
                  const newScheduleBlockAfterRestriction = this.createScheduleBlockAfterRestriction(block, nextRestriction);

                  scheduleBlocksByDay[day].push(newScheduleBlockAfterRestriction);
                }
                else {
                  newScheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), twoRestrictionsAhead.endTimeHours, twoRestrictionsAhead.endTimeMinutes);
                }
              }
              else {
                newScheduleBlock.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.endTimeHours, block.endTimeMinutes);
              }

              // TODO
              newScheduleBlock.rate = block.rate;
              newScheduleBlock.periodOfStay = block.periodOfStay;
              newScheduleBlock.isSeasonal = block.isSeasonal;
              newScheduleBlock.seasonalDayStart = block.seasonalDayStart;
              newScheduleBlock.seasonalDayEnd = block.seasonalDayEnd;
              newScheduleBlock.seasonalMonthStart = block.seasonalMonthStart;
              newScheduleBlock.seasonalMonthEnd = block.seasonalMonthEnd;
              newScheduleBlock.seasonalHolidayStart = block.seasonalHolidayStart;
              newScheduleBlock.seasonalHolidayEnd = block.seasonalHolidayEnd;

              scheduleBlocksByDay[day].push(newScheduleBlock);
            }
          }
      });

      scheduleBlocksByDay[day] = scheduleBlocksByDay[day].sort((a, b) => {
        if (a.startTime < b.startTime) { return -1; }
        else if (a.startTime > b.startTime) { return 1; }
        else { return 0; }
      });

      let mostRecentRestriction = new ScheduleBlocks();
      scheduleBlocksByDay[day].forEach(block => {
        block.isSeasonal = block.isSeasonal ? block.isSeasonal : block.seasonalDayStart || block.seasonalHolidayStart;
        if (block.rate) {
          let nextRestriction = new ScheduleBlocks();
          if (scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) + 1] && scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) + 1]) {
            nextRestriction = scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) + 1];
          }

          let startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.startTimeHours, block.startTimeMinutes);

          const seasonalStringSuffix = RatePackageChartUtility.generateSeasonalSuffix(block);

          if (block.endTime.getHours() === 0) {
            if (scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1] && scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1] && scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1].endTime.getTime() > block.startTime.getTime()) {
              if (block.endTime.getTime() > scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1].endTime.getTime()) {
                data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1].endTime.getHours(), scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1].endTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1,  block.endTime.getHours(), block.endTime.getMinutes())]]);
                startTime = scheduleBlocksByDay[day][scheduleBlocksByDay[day].indexOf(block) - 1].endTime;
              }
            }
            else {
              if (nextRestriction.startTime && block.startTime.getTime() === nextRestriction.startTime.getTime() && block.endTime.getTime() > nextRestriction.endTime.getTime()) {
                block.startTime = nextRestriction.endTime;
                data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.startTime.getHours(), block.startTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1,  block.endTime.getHours(), block.endTime.getMinutes())]]);
              }
              else {
                data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.startTime.getHours(), block.startTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1,  block.endTime.getHours(), block.endTime.getMinutes())]]);
              }
            }
          }
          else {
            if (mostRecentRestriction.endTime && mostRecentRestriction.endTime.getTime() > block.startTime.getTime()) {
              if (block.endTime.getTime() > mostRecentRestriction.endTime.getTime()) {
                data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), mostRecentRestriction.endTime.getHours(), mostRecentRestriction.endTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate(),  block.endTime.getHours(), block.endTime.getMinutes())]]);
                startTime = mostRecentRestriction.endTime;
              }
            }
            else {
              if (block.startTime.getTime() !== block.endTime.getTime()) {
                if (mostRecentRestriction.endTime && block.endTime.getTime() < mostRecentRestriction.startTime.getTime()) {
                  data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), mostRecentRestriction.endTime.getHours(), mostRecentRestriction.endTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate(),  block.endTime.getHours(), block.endTime.getMinutes())]]);
                }
                else {
                  if (nextRestriction.startTime && nextRestriction.startTime.getTime() < block.endTime.getTime() && block.restrictionType) {
                    if (nextRestriction.startTime.getTime() === block.startTime.getTime()) {
                        data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), nextRestriction.endTime.getHours(), nextRestriction.endTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate(),  block.endTime.getHours(), block.endTime.getMinutes())]]);
                    }
                    else {
                      data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.startTime.getHours(), block.startTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate(),  nextRestriction.startTime.getHours(), nextRestriction.startTime.getMinutes())]]);
                    }
                  }
                  else {
                    data.addRows([[day, '$' + block.rate + ' (' + block.periodOfStay / 60 + (block.isSeasonal ? seasonalStringSuffix : ' hr)'), new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.startTime.getHours(), block.startTime.getMinutes()), new Date(today.getFullYear(), today.getMonth(), today.getDate(),  block.endTime.getHours(), block.endTime.getMinutes())]]);
                  }
                }
              }
            }
          }

          const operationStartTime = new Object({
            type: 'Operation',
            startTime: startTime,
            rate: block.rate,
            isSeasonal: block.isSeasonal
          });

          const matches = startTimeArray.filter(startTimeValue => {
            if (startTimeValue) {
              return startTimeValue.rate === block.rate && startTimeValue.type === 'Operation' && startTimeValue.isSeasonal === block.isSeasonal;
            }
          });

          if ((startTimeArray.length === 0 || ( matches.length === 0 )) && operationStartTime) {
            startTimeArray.push(operationStartTime);
          }

        }
        else {
          mostRecentRestriction = block;
          const restrictionStartTime = new Object({
            type: 'Restriction' + block.restrictionType,
            startTime: new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.startTimeHours, block.startTimeMinutes),
            rate: null,
            isSeasonal: block.isSeasonal
          });

          const matches = startTimeArray.filter(startTimeValue => {
            if (startTimeValue) {
              if (startTimeValue.type === ('Restriction' + block.restrictionType) && startTimeValue.startTime.getTime() === block.startTime.getTime() && startTimeValue.isSeasonal === block.isSeasonal) {
                return true;
              }
            }
          });

          if ((startTimeArray.length === 0 || ( matches.length === 0 )) && restrictionStartTime) {
            startTimeArray.push(restrictionStartTime);
          }

          const seasonalStringSuffix = RatePackageChartUtility.generateSeasonalSuffix(block);

          if (block.startTime.getTime() === block.endTime.getTime()) {
            data.addRows([[day, (block.isSeasonal ? block.restrictionType + seasonalStringSuffix : block.restrictionType), new Date(block.startTime.getFullYear(), block.startTime.getMonth(), block.startTime.getDate(), block.startTimeHours, block.startTimeMinutes), new Date(block.endTime.getFullYear(), block.endTime.getMonth(), block.endTime.getDate(),  block.endTimeHours, block.endTimeMinutes)]]);
          }
          else {
            data.addRows([[day, (block.isSeasonal ? block.restrictionType + seasonalStringSuffix : block.restrictionType), new Date(block.startTime.getFullYear(), block.startTime.getMonth(), block.startTime.getDate(), block.startTime.getHours(), block.startTime.getMinutes()), new Date(block.endTime.getFullYear(), block.endTime.getMonth(), block.endTime.getDate(),  block.endTime.getHours(), block.endTime.getMinutes())]]);
          }
        }
      });
    });

    const graphColorList = RatePackageChartUtility.generateChartColorList(startTimeArray);

    const options = {
        height: 300,
        tooltip: {
          isHtml: true
        },
        timeline: {
        groupByRowLabel: true,
        rowLabelStyle: { fontName: 'Trebuchet MS', fontSize: 10 },
        barLabelStyle: { fontName: 'Trebuchet MS', fontSize: 10 },
        displayLegendValues: true
        },
        hAxis: {
          minValue: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
          maxValue: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
        },
        colors: graphColorList
    };

    if (document.getElementById('chart_div')) {
      const chart = new google.visualization.Timeline(document.getElementById('chart_div'));

      chart.draw(data, options);

      // Hide empty rows
      const allRows = document.getElementsByTagName('rect');
      let width = 5;
      let rowsToRemove = [];
      for (let i = 0; i < allRows.length; i++) {
          const cwidth = parseInt(allRows[i].getAttribute('width'));
          if (cwidth < width) {
            rowsToRemove = [allRows[i]];
              width = cwidth;
          }
          else if (cwidth === width) {
            rowsToRemove.push(allRows[i]);
          }
      }
      for (let i = 0; i < rowsToRemove.length; i++) {
        rowsToRemove[i].setAttribute('fill', 'none');
      }
    }
  }

  static createScheduleBlockAfterRestriction(block: ScheduleBlocks, nextRestriction: ScheduleBlocks): ScheduleBlocks {
    const today = new Date();
    const newScheduleBlockAfterRestriction = new ScheduleBlocks();
    newScheduleBlockAfterRestriction.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), nextRestriction.endTime.getHours(), nextRestriction.endTime.getMinutes());
    newScheduleBlockAfterRestriction.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), block.endTime.getHours(), block.endTime.getMinutes());
    newScheduleBlockAfterRestriction.rate = block.rate;
    newScheduleBlockAfterRestriction.periodOfStay = block.periodOfStay;
    newScheduleBlockAfterRestriction.isSeasonal = block.isSeasonal;
    newScheduleBlockAfterRestriction.seasonalDayStart = block.seasonalDayStart;
    newScheduleBlockAfterRestriction.seasonalDayEnd = block.seasonalDayEnd;
    newScheduleBlockAfterRestriction.seasonalMonthStart = block.seasonalMonthStart;
    newScheduleBlockAfterRestriction.seasonalMonthEnd = block.seasonalMonthEnd;
    newScheduleBlockAfterRestriction.seasonalHolidayStart = block.seasonalHolidayStart;
    newScheduleBlockAfterRestriction.seasonalHolidayEnd = block.seasonalHolidayEnd;

    return newScheduleBlockAfterRestriction;
  }

}
