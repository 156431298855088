import {Injectable} from '@angular/core';
import {TrueUpHttpService} from '../../true-up-http.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class CalculationsCompleteService{

  constructor(private trueUpDataHttpService: TrueUpHttpService) { }

  public isCalculationApprovedForYearAndMonth(year: number, month: number): Observable<any> {
    return this.trueUpDataHttpService.get(`/CalculationsComplete/${year}/${month}`, null)
      .pipe(
        map(response => response),
        catchError((error) => this.handleError(error))
      );
  }

  public getLastFinalizedQuarter(year: number, month: number): Observable<any> {
    return this.trueUpDataHttpService.get(`/CalculationsComplete/lastFinalizedQuarter/${year}/${month}`, null)
      .pipe(
        map(response => response),
        catchError((error) => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }


}
