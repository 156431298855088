export class InstallSpaceConstants {
  static BlockRangeFrom = 126;
  static BlockRangeTo = 127;
  static MeterIDText = 100;
  static MeterSelect = 101;
  static MeterStreetNumber = 102;
  static NumberOfSpaces = 103;
  static ContractType = 104;
  static EUR = 105;
  static DueDate = 124;
  static PhysicalBlockId = 119;
  static SignsOnly = 118;
  static UpdateBlockRange = 112;
}

export class RemoveSpaceConstants {
  static BlockRangeFrom = 330;
  static BlockRangeTo = 331;
  static NumberOfSpaces = 327;
  static MeterSelect = 326;
  static MeterStreetNumber = 336;
  static MeterTextId = 325;
  static UpdateBlockRange = 329;
}

export class ChangeRatePackageConstants {
  static EntireMeter = 404;
  static MeterIDText = 400;
  static MeterSelect = 401;
  static MeterStreetNumber = 402;
  static NumberOfSpaces = 405;
  static ChangeRatePerHour = 406;
  static RatePerHour = 407;
  static ChangePeriodOfStay = 408;
  static PeriodOfStay = 409;
  static ChangeHoO = 410;
  static HoursOfOperation = 411;
  static ChangeHoR = 412;
  static HoursOfRestriction = 413;
  static RatePackageId = 403;
  static EUR = 416;
}

export class InstallMeterConstants {
  static BlockIDSelect = 201;
  static BlockAddressBlockRangeStart = 202;
  static BlockAddressBlockRangeEnd = 203;
  static EUR = 270;
  static ContractType = 230;
  static DaysToComplete = 252;
  static DueDate = 251;
  static NumberOfSpaces = 232;
  static StreetDirection = 204;
  static StreetName = 205;
  static Suffix = 206;
  static UseExistingBlock = 200;
  static UpdateBlockRange = 240;
  static BlockRangeFrom = 241;
  static BlockRangeTo = 242;
  static RatePackageSelect = 220;
  static MeterAddress = 211;
  static CrossStreetsOrAddressDescription = 212;
  static MeterType = 213;
  static PhysicalMeterID = 214;
  static LocationType = 231;
  static Reason = 233;
}

export class ShiftSpaceConstants {
  static MeterFromID = 510;
  static MeterToID = 520;
  static NumberOfSpaces = 530;
  static DueDate = 334;
}

export class InactivateMeterConstants {
  static MeterAddress = 602;
  static DueDate = 607;
  static MeterSelect = 601;
  static MeterIdText = 600;
  static RemoveEffectiveImmediately = 604;
  static SelectRemovalDate = 605;
  static PhysicallyRemoveMeter = 610;
}

export class ReactivateMeterConstants {
  static MeterIdText = 700;
  static MeterSelect = 701;
  static MeterAddress = 702;
  static ReinstallEffectiveImmediately = 703;
  static ReinstallDate = 705;
  static PhysicallyReinstallMeter = 711;
}

export class ChangeContractTypeToConcessionConstants {
  static EntireMeter = 804;
  static MeterSelect = 801;
  static NumberOfSpaces = 805;
  static MeterIDText = 800;
  static MeterStreetNumber = 802;
}

export class ReverseRatePackageConstants {
  static MeterDisplayId = 1110;
  static RatePackageDisplayId = 1120;
  static Reason = 1130;
}

export class RelocateMeterConstants {
  static SelectRelocationDate = 1207;
  static RelocateEffectiveImmediately = 1206;
  static MeterIdText = 1200;
  static OldMeterStreetNumber = 1202;
  static MeterSelect = 1201;
  static RelocateToDifferentBlock = 1211;
  static SelectNewBlock = 1212;
}

export class UpdateMeterAttributesConstants {
  static FineAmount = 1005;
  static LocationType = 1004;
  static RequiredClosureAllowance = 1003;
}
