import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderHttpService } from '../../work-orders/services/work-order-http.service';
import { Holiday } from '../models/holiday';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HolidayService {

  constructor(private workorderhttp: WorkOrderHttpService) { }

  public getAllHolidays(): Observable<Holiday[]> {
    const currentYear = new Date().getUTCFullYear();
    return this.workorderhttp.get('/holiday?startDate=1/1/' + currentYear + '&endDate=12/31/' + currentYear, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
