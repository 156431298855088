import { MonthlyTrueUpCalculationDetailBase } from "../monthly-calculations/monthly-true-up-calculation-detail-base";

export class MeterTrueUpDetail extends MonthlyTrueUpCalculationDetailBase {
    isActive: boolean;

    meterDisplayId: string;    

    meterId: string;

    areaId: string;
    
    areaDisplayId: string;

    blockId: string;

    blockDisplayId: string;

    blockAddress: string;

    mostFrequentRatePackageName: string;

    mostFrequentRatePackageId: string;

    mostFrequentRatePackageDescription: string;

    ward: number;

    zoneId: string;

    zoneDisplayId: string;

    periodOfOperation: number;

    currentStatus: string;

    lastReservedPowerWorkOrderActionId: string;

    lastReservedPowerWorkOrderActionEffectiveDate: string;

    expectedUtilizationRate: number;

    expectedUtilizationAdjustment: number;
}
