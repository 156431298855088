import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DistributionJsonConverter } from './distribution-json-converter';
import { MeterDistributionKeyValuePair } from './models/meter-distribution';
import { SpaceCollection } from './models/space-collection';
import { TrueUpHttpService } from './../../../true-up-http.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MeterDistributionService {

    constructor(private trueUpHttpService: TrueUpHttpService) { }

    public getMeterDistributionsForYear(year: number, id: string): Observable<MeterDistributionKeyValuePair[]> {
        const endpoint = `/Distributions/MeterDistributions/${year}/${id}`;

        return this.trueUpHttpService.get(endpoint, null)
            .pipe(
              map((response) => response != null ? DistributionJsonConverter.jsonToMeterDistributions(response) : null),
              catchError(this.handleError)
            );
    }

    public getSpaceDistributionForMonth(year: number, month: number, id: string): Observable<SpaceCollection[]> {
        const endpoint = `/Distributions/SpaceDistributions/${year}/${month}/${id}`;

        return this.trueUpHttpService.get(endpoint, null)
            .pipe(
              map(response => response != null ? DistributionJsonConverter.jsonToSpaceCollections(response) : null),
              catchError(error => this.handleError(error))
            );
    }

    public getLogForYearAndMonth(year: number, month: number, id: string): Observable<string> {
        const endpoint = `/Distributions/Logs/${year}/${month}/${id}`;

        return this.trueUpHttpService.get(endpoint, null)
            .pipe(
              map(response => response != null ? response['result'] : ''),
              catchError(error => this.handleError(error))
            );
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
