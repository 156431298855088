import { Component, OnInit, ErrorHandler } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { MeterService } from '../meters.service';
import { TokenCache } from '../../../security/token-cache';
import { AuditReportService } from '../../../reports/services/audit-report.service';
import { MeterAudit } from '../../../reports/audit/meter-audit';

@Component({
    selector: 'app-meter-audit',
    templateUrl: './meter-audit.component.html',
    styleUrls: ['./meter-audit.component.css']
})

export class MeterAuditComponent implements OnInit {
    meterId: string;

    meterAudit: MeterAudit[];
    dataSource = new MatTableDataSource<MeterAudit>();
    displayedColumns: Array<string>;

    constructor(private meterService: AuditReportService, private _errorHandler: ErrorHandler, private route: ActivatedRoute, private tokenCache: TokenCache) {
        this.meterAudit = new Array<MeterAudit>();
    }

    ngOnInit(): void {
        this.meterId = this.route.snapshot.paramMap.get('id');
        this.displayedColumns = [
            'WorkOrder',
            'WorkOrderActionType',
            'HistoricalChange',
            'OldValues',
            'NewValues',
            'EffectiveDate',
            'EntryDate',
            'RequestedBy',
            'ApprovedBy',
            'TempApproved',
            'ActivatedBy'
          ];        
        this.getMeterAudit();
    }

    getMeterAudit() {
        this.meterService.RequestMeterAuditHistory(this.meterId).subscribe(
            result => {
                this.meterAudit = result;
                this.dataSource.data = this.meterAudit;
                this.dataSource = new MatTableDataSource<MeterAudit>(this.meterAudit);
            },
            error => {
                this._errorHandler.handleError(error);
            }
        );
    }
}
