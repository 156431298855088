import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderHttpService } from './../../services/work-order-http.service';
import { SubmitUpdateMeterAttributesChangesGroup } from './../update-meter-attributes/models/submit-update-meter-attributes-changes-group';
import { WorkOrderActionResponse } from '../../models/approval-models';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class UpdateMeterAttributesService {

  constructor(private workOrderHttp: WorkOrderHttpService, private snackBar: MatSnackBar) { }

  public submitUpdateMeterAttributeChanges(submitUpdateMeterAttributes: SubmitUpdateMeterAttributesChangesGroup, isChangecontractType: boolean): Observable<any> {
    const url = isChangecontractType ? '/UpdateMeterAttributesChangeContractType/submit' : '/UpdateMeterAttributes/submit';
    return this.workOrderHttp.post(url, submitUpdateMeterAttributes)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public activateUpdateMeterAttributeChanges(submitUpdateMeterAttributes: SubmitUpdateMeterAttributesChangesGroup, isChangecontractType: boolean): Observable<any> {
    const url = isChangecontractType ? '/UpdateMeterAttributesChangeContractType/activate' : '/UpdateMeterAttributes/activate';
    return this.workOrderHttp.put(url, submitUpdateMeterAttributes)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public returnWithComments(responseModel: WorkOrderActionResponse, isChangeContractType: boolean): Observable<any> {
    const url = isChangeContractType ? '/UpdateMeterAttributesChangeContractType/comments' : '/UpdateMeterAttributes/comments';
    return this.workOrderHttp.put(url, responseModel)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public acceptChanges(responseModel: WorkOrderActionResponse, isChangeContractType: boolean): Observable<any> {
    const url = isChangeContractType ? '/UpdateMeterAttributesChangeContractType/accept' : '/UpdateMeterAttributes/accept';
    return this.workOrderHttp.put(url, responseModel)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public deleteSelection(workOrderActionId: string, groupId: string, isChangeContractType: boolean, workOrderId: string): Observable<any> {
    const url = isChangeContractType ? '/UpdateMeterAttributesChangeContractType/' + workOrderActionId + '/deletegroup/' + groupId : '/UpdateMeterAttributes/' + workOrderActionId + '/deletegroup/' + groupId + '/' + workOrderId;
    return this.workOrderHttp.put(url, null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}
