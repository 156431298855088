import { InventoryFilterComponent } from './../inventory-filter/inventory-filter.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { InventoryTableComponent } from './inventory-table.component';
import { ColumnCustomizerDialogComponent } from './column-customizer-dialog/column-customizer-dialog.component';
import { CoreModule } from './../../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
  ],
  declarations: [
    InventoryTableComponent,
    InventoryListComponent,
    InventoryFilterComponent,
    ColumnCustomizerDialogComponent
  ],
  entryComponents: [
    InventoryTableComponent,
    InventoryListComponent,
    InventoryFilterComponent,
    ColumnCustomizerDialogComponent
  ],
  exports: [
    InventoryTableComponent,
    InventoryListComponent,
    InventoryFilterComponent,
    ColumnCustomizerDialogComponent
  ],
  providers: []
})
export class InventoryTableModule { }
