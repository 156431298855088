import { AbstractJsonConverter } from "../../core/json-converters/abstract-json-convertert";
import { SystemTrueUpDetail } from "./system-true-up-detail";
import { Injectable } from "@angular/core";
import { PaginatedGetResult } from "../../core/models/paginated-get-result";
import { TrueUpDetailJsonConverter } from "../true-up-detail-json-converter";


@Injectable()
export class SystemTrueUpDetailJsonConverter extends TrueUpDetailJsonConverter<SystemTrueUpDetail> {
  
  jsonToLevelSpecificDetail(responseJson: any): PaginatedGetResult<SystemTrueUpDetail> {
    var paginatedResult = this.jsonToPaginatedResult(responseJson);
    this.jsonToTrueUpDetail(paginatedResult, responseJson);

    return paginatedResult;
  }
    
  getInstance():SystemTrueUpDetail
  {
    return new SystemTrueUpDetail();
  }
}
