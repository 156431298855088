import { Component, OnInit, ErrorHandler, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { PaginationParameters } from '../../core/models/pagination-paramters';
import { TempRemovedMeter } from './temp-removed-meter';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { TempRemovedMetersDataSource } from './temp-removed-meters-data-source';
import { TempRemovedMeterService } from '../services/temp-removed-meters.service';

@Component({
  selector: 'app-temp-removed-meters',
  templateUrl: './temp-removed-meters.component.html',
  styleUrls: ['./temp-removed-meters.component.css']
})
export class TempRemovedMetersComponent implements OnInit {

  constructor(
    private tempRemovedMeterService: TempRemovedMeterService, private errorHandler: ErrorHandler,
    private fileDownloadService: FileDownloadService, private snackBar: MatSnackBar) { }

  displayedColumns = ['zone', 'area', 'blockRange', 'meterStreetNumber', 'direction', 'streetName', 'sideOfStreet', 'meterId','workOrderId' ,'removalReason',
    'dateOfRemoval', 'sixMonthsAfterRemoval', 'estimatedDateOfReinstallation', 'internalComments'];

  dataSource: TempRemovedMetersDataSource;
  paginationParameters: PaginationParameters;
  defaultOrder = 'meterId';
  meterListLoaded: boolean;
  reportLoading: boolean;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  tempRemovalMeterList: TempRemovedMeter[] = [];

  ngOnInit() {

    this.paginationParameters = {
      orderByColumn: this.defaultOrder,
      pageSize: 0,
      isAscending: true,
      totalItemCount: 0,
      pageIndex: 0
    };

    this.dataSource = new TempRemovedMetersDataSource(this.tempRemovalMeterList);
    this.refreshDataSource();
  }

  refreshDataSource() {
    this.meterListLoaded = false;

    if ((this.sort.direction !== '' &&
      this.paginationParameters.isAscending !== (this.sort.direction === 'asc')) ||
      (this.sort.active !== '' &&
        this.paginationParameters.orderByColumn !== this.sort.active)) {

      this.paginationParameters.isAscending = (this.sort.direction === 'asc') || (this.sort.direction === '');
      this.paginationParameters.orderByColumn = this.sort.active;
    }

    this.tempRemovedMeterService.getAllTempRemovedMeters()
      .subscribe(
        result => {
          if (result == null) {
            this.tempRemovalMeterList = [];
          } else {
            this.tempRemovalMeterList = result;
            this.dataSource.setData(result);
          }
          this.meterListLoaded = true;
        },
        error => {
          this.tempRemovalMeterList = [];
          this.errorHandler.handleError(error);
          this.meterListLoaded = true;
        });
  }

  export() {
    this.reportLoading = true;
    this.snackBar.open('Downloading Temp Removed Meter Report', '', <MatSnackBarConfig>{
      duration: 2000
    });
    this.tempRemovedMeterService.exportMeters().subscribe(
      result => {
        this.reportLoading = false;
          const fileDownloadResult = this.fileDownloadService.MapResponseToFileResult(result);
          this.fileDownloadService.SaveFileToBrowser(fileDownloadResult);
      },
      error => {
        this.reportLoading = false;
        this.errorHandler.handleError(error);
      });
  }

  sortChanged() {
    const sortingColumn = this.sort.active;
    const isAscending = (this.sort.direction === 'asc') || (this.sort.direction === '');
    const order = isAscending ? 1 : -1;
    let sortingFunc;

    if (sortingColumn === 'zone') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
                    Number(a.zoneName) >= Number(b.zoneName) ? order : order * -1;

    }  else if (sortingColumn === 'area') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
                    Number(a.areaName) >= Number(b.areaName) ? order : order * -1;

    } else if (sortingColumn === 'blockRange') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
      (Number(a.blockRange.split(' - ')[0]) > Number(b.blockRange.split(' - ')[0]))  ? order : order * -1;

    } else if (sortingColumn === 'streetName') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
                    (a.streetName) >= (b.streetName) ? order : order * -1;

    } else if (sortingColumn === 'meterId') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
      Number(a.displayId) >= Number(b.displayId) ? order : order * -1;

    } else if (sortingColumn === 'dateOfRemoval') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
                    (new Date(a.dateOfRemoval)) >= (new Date(b.dateOfRemoval)) ? order : order * -1;

    } else if (sortingColumn === 'sixMonthsAfterRemoval') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
                    (new Date(a.dateofRemovalAfterThreeMonths)) >= (new Date(b.dateofRemovalAfterThreeMonths)) ? order : order * -1;

    } else if (sortingColumn === 'estimatedDateOfReinstallation') {
      sortingFunc = (a: TempRemovedMeter, b: TempRemovedMeter) =>
                    (new Date(a.estimatedDateOfReinstallation)) >= (new Date(b.estimatedDateOfReinstallation)) ? order : order * -1;

    }

    this.tempRemovalMeterList.sort(sortingFunc);

    this.dataSource.setData(this.tempRemovalMeterList);
  }
}
