import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '../../core/core.module';

import { EditMeterDialogComponent } from './edit-meter-dialog/edit-meter-dialog.component';
import { InventoryTimelineModule } from './../inventory-components/inventory-timeline/inventory-timeline.module';
import { MeterAuditComponent } from './meter-audit/meter-audit.component';
import { MeterDetailComponent } from './meter-detail/meter-detail.component';
import { MeterDistributionLogDialog } from './meter-true-up/meter-distribution-log-dialog/meter-distribution-log-dialog.component';
import { MeterDistributionService } from './meter-true-up/meter-distribution.service';
import { MeterDowntimeTableComponent } from './meter-downtime-table/meter-downtime-table.component';
import { MeterService } from './meters.service';
import { MeterSpacesComponent } from './meter-spaces/meter-spaces.component';
import { MeterTrueUpComponent } from './meter-true-up/meter-true-up.component';
import { MeterWorkOrderHistoryComponent } from './meter-work-order-history/meter-work-order-history.component';
import { RatePackageService } from './../rate-packages/services/rate-package.service';
import { RatePackagesModule } from './../rate-packages/rate-packages.module';
import { SpacesModule } from './../spaces/spaces.module';
import { SpaceService } from './../spaces/spaces.service';
import { SpaceSummarySorter } from './../spaces/space-summary-sorter';
import { SystemStartTimeService } from '../../system-start-time.service';
import { WorkOrderHistoryModule } from '../work-order-history/work-order-history.module';
import { TrueupModule } from './../../trueup/trueup.module';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    TrueupModule,
    InventoryTimelineModule,
    RatePackagesModule,
    SpacesModule,
    WorkOrderHistoryModule
  ],
  declarations: [
    MeterAuditComponent,
    MeterDetailComponent,
    MeterDistributionLogDialog,
    MeterDowntimeTableComponent,
    MeterSpacesComponent,
    MeterTrueUpComponent,
    MeterWorkOrderHistoryComponent,
    EditMeterDialogComponent,
  ],
  exports: [
    MeterDetailComponent,
    MeterSpacesComponent
  ],
  providers: [
    MeterService,
    SpaceService,
    SpaceSummarySorter,
    RatePackageService,
    MeterDistributionService,
    SystemStartTimeService
  ],
  bootstrap: [MeterDistributionLogDialog, EditMeterDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MetersModule { }

