import { FilterOperator } from './../../core/query-strings/query-string-builder';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

import { ContractTypeConstants, MeterStatusConstants, MeterTypeConstants, ParkingTypeConstants, SpaceTypeConstants, TempRemovalStatusConstants, HasSignsOnlyConstants } from '../meters/meter-constants';
import { SpaceStatusConstants } from './../spaces/space-constants';
import { InventoryFilterRepresentation } from './inventory-filter-representation';
import { ExportInventoryFilterRepresentation } from './export-representations';
import { ColumnCustomizerDialogComponent } from '../inventory-table/column-customizer-dialog/column-customizer-dialog.component';
import { Column } from '../inventory-table/inventory-list/inventory-list-column-def';
import { ColumnConstants } from '../inventory-table/column-constants';

@Component({
  selector: 'app-inventory-filter',
  templateUrl: './inventory-filter.component.html',
  styleUrls: ['./inventory-filter.component.css']
})
export class InventoryFilterComponent implements OnInit {
    inventoryFilter: InventoryFilterRepresentation;
    historicallyAssociatedRatePackageName: string;
    exportInventoryFilter: ExportInventoryFilterRepresentation;
    operatorDropdown = [FilterOperator.Equal, FilterOperator.GreaterThan, FilterOperator.LessThan, FilterOperator.NotEqual,
            FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between ];
    onlyEqualsDropdown = [FilterOperator.Equal, FilterOperator.NotEqual];
    blockRangeDropdown = [FilterOperator.GreaterThan, FilterOperator.LessThan,
        FilterOperator.GreaterThanOrEqual, FilterOperator.LessThanOrEqual, FilterOperator.Between];
    meterStatusDropdown = [MeterStatusConstants.active, MeterStatusConstants.activePending, MeterStatusConstants.inactive, MeterStatusConstants.removed];
    spaceStatusDropdown = [SpaceStatusConstants.active, SpaceStatusConstants.activePending, SpaceStatusConstants.removed, SpaceStatusConstants.reserved];
    meterTypeDropdown = [MeterTypeConstants.MP104, MeterTypeConstants.CLZVirtualID, MeterTypeConstants.SSM, MeterTypeConstants.CWT];
    spaceTypeDropdown = [SpaceTypeConstants.DiagonalStriped, SpaceTypeConstants.DiagonalUnstriped, SpaceTypeConstants.Parallel, SpaceTypeConstants.PerpendicularStriped, SpaceTypeConstants.PerpendicularUnstriped];
    contractTypeDropdown = [ContractTypeConstants.Concession, ContractTypeConstants.Reserved];
    parkingTypeDropdown = [ParkingTypeConstants.OnStreet, ParkingTypeConstants.ParkingLot, ParkingTypeConstants.CLZ, ParkingTypeConstants.WrigleyvilleEventParking];
    tempRemovalStatusDropdown = [TempRemovalStatusConstants.TempRemoved, TempRemovalStatusConstants.NotTempRemoved];
    streetNameDropdown = [FilterOperator.Equal, FilterOperator.Contains];
    ratePackageNameFilterOperatorDropDown = [FilterOperator.Equal, FilterOperator.StartsWith, FilterOperator.EndsWith, FilterOperator.Contains ];
    hasSignsOnlyDropdown = [HasSignsOnlyConstants.HasSignsOnly, HasSignsOnlyConstants.DoesNotHaveSignsOnly];

    removedStatus = MeterStatusConstants.removed;
    tempRemoved: boolean;
    dialogRef: MatDialogRef<ColumnCustomizerDialogComponent>;
    allColumns: Array<Column> = new Array<Column>();
    defaultColumns: Array<Column> = new Array<Column>();
    displayedColumns: Array<string> = new Array<string>();
    columnsForExport: Array<string> = new Array<string>();

    constructor(private route: ActivatedRoute, private dialog: MatDialog) { }

    @ViewChild('expansion', {static: true}) expansion: MatExpansionPanel;

    @Input()
    hideExportButton: boolean;

    @Output()
    inventoryFilterEventEmitter: EventEmitter<InventoryFilterRepresentation> = new EventEmitter<InventoryFilterRepresentation>(true);

    @Output()
    exportInventoryFilterEventEmitter: EventEmitter<ExportInventoryFilterRepresentation> = new EventEmitter<ExportInventoryFilterRepresentation>(true);

    @Output()
    displayedColumnsEventEmitter: EventEmitter<Array<string>> = new EventEmitter<Array<string>>(true);
    @Output()
    columnsForExportEventEmitter: EventEmitter<Array<string>> = new EventEmitter<Array<string>>(true);

    @Input()
    context: string;


  ngOnInit() {
        if (this.expansion) {
          this.expansion.open();
        }

        this.inventoryFilter = new InventoryFilterRepresentation();
        this.exportInventoryFilter = new ExportInventoryFilterRepresentation();

        if (this.context === 'workOrderMeterInventory') {
            this.inventoryFilter.MeterStatus = this.removedStatus;
        }

        if (localStorage.getItem('RatePackageName') != null) {
            this.inventoryFilter.RatePackageId = localStorage.getItem('RatePackageName');
            localStorage.removeItem('RatePackageName');
            if (this.context.includes('meter')) {
                this.inventoryFilter.MeterStatus = MeterStatusConstants.active;
            }
            else if (this.context.includes('space')) {
                this.inventoryFilter.SpaceStatus = SpaceStatusConstants.active;
            }
            this.onSubmit();
        }
        if (localStorage.getItem('MetersHistoricallyAssociatedWithRatePackage') != null) {
          this.inventoryFilter.HistoricallyAssociatedRatePackageId = localStorage.getItem('MetersHistoricallyAssociatedWithRatePackage');
          this.historicallyAssociatedRatePackageName = localStorage.getItem('MetersHistoricallyAssociatedWithRatePackageName');
          localStorage.removeItem('MetersHistoricallyAssociatedWithRatePackage');
          localStorage.removeItem('MetersHistoricallyAssociatedWithRatePackageName');
          this.onSubmit();
        }
        if (this.context === 'linked-blocks') {
          this.inventoryFilter.LinkedBlockParentId = this.route.snapshot.paramMap.get('id');
          this.onSubmit();
        }

        this.setColumns();
    }

  clear(check) {
    this.inventoryFilterEventEmitter.emit(new InventoryFilterRepresentation());
    this.inventoryFilter[check] = undefined;

    if (this.inventoryFilter[check + 'End']) {
      this.inventoryFilter[check + 'End'] = undefined;
    }

    if (check === 'BlockRange') {
      this.inventoryFilter.BlockRangeFilterOperator = FilterOperator.Between;
    }
    else if (check === 'BlockDisplayId' || check === 'MeterId' || check === 'StreetName' || check === 'SpaceId') {
      this.inventoryFilter[check + 'FilterOperator'] = FilterOperator.Contains;
    }
    else if (this.inventoryFilter[check + 'FilterOperator']) {
      this.inventoryFilter[check + 'FilterOperator'] = FilterOperator.Equal;
    }

    if (this.context === 'block-meters' || this.context === 'block-spaces' || this.context === 'linked-blocks') {
      this.inventoryFilter.BlockId = this.route.snapshot.paramMap.get('id');
    }

    this.inventoryFilterEventEmitter.emit(this.inventoryFilter);
  }

  setColumns() {
    this.displayedColumns = new Array<string>();
    this.allColumns = new Array<Column>();
    this.columnsForExport = new Array<string>();
    if(this.context === 'block-meters') {
      this.displayedColumns = ColumnConstants.blockMetersColumns;
    }
    else if(this.context === 'block-spaces') {
      this.displayedColumns = ColumnConstants.blockSpacesColumns;
    }
    else if(this.context.includes('meter-select') || this.context.includes('meter-from-select') || this.context.includes('meter-to-select')) {
      this.displayedColumns = ColumnConstants.meterSelectColumns;
    }
    else if(this.context.includes('space')) {
      this.allColumns = ColumnConstants.spaceAllColumns;
      this.defaultColumns = ColumnConstants.spaceDefaultColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });
      this.defaultColumns.forEach(column => {
        this.columnsForExport.push(column.readable);
      });
    }
    else if(this.context.includes('linked-blocks')) {
      this.allColumns = ColumnConstants.linkedBlocksAllColumns;
      this.defaultColumns = ColumnConstants.linkedBlocksDefaultColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });
      this.defaultColumns.forEach(column => {
        this.columnsForExport.push(column.readable);
      });
    }
    else if(this.context.includes('block')) {
      this.allColumns = ColumnConstants.blockAllColumns;
      this.defaultColumns = ColumnConstants.blockDefaultColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });
      this.defaultColumns.forEach(column => {
        this.columnsForExport.push(column.readable);
      });
    }
    else {
      this.allColumns = ColumnConstants.allColumns;
      this.defaultColumns = ColumnConstants.defaultColumns;
      this.defaultColumns.forEach(column => {
        this.displayedColumns.push(column.constant);
      });
      this.defaultColumns.forEach(column => {
        this.columnsForExport.push(column.readable);
      });
    }

    this.displayedColumnsEventEmitter.emit(this.displayedColumns);
    this.columnsForExportEventEmitter.emit(this.columnsForExport);
  }

  customizeColumns() {
    this.dialogRef = this.dialog.open(ColumnCustomizerDialogComponent, {
      height: '600px',
      width: '400px',
      data: {
          allColumns: this.allColumns,
          defaultColumns: this.defaultColumns,
          displayedColumns: this.displayedColumns
      }
    });

    this.dialogRef.afterClosed().subscribe((result: Array<Column>) => {
      if (result !== undefined && result.length > 0) {
        this.displayedColumns = [];
        result.forEach(column => {
            this.displayedColumns.push(column.constant);
        });

        this.displayedColumnsEventEmitter.emit(this.displayedColumns);

        this.columnsForExport = [];
        result.forEach(column => {
            this.columnsForExport.push(column.readable);
        });

        this.columnsForExportEventEmitter.emit(this.columnsForExport);
      }
    });
  }

  onSubmit() {
    if (this.expansion) {
      this.expansion.close();
    }

    if (this.context === 'block-meters' || this.context === 'block-spaces' || this.context === 'linked-blocks') {
      this.inventoryFilter.BlockId = this.route.snapshot.paramMap.get('id');
    }
    this.inventoryFilterEventEmitter.emit(this.inventoryFilter);
  }

  emitExportInventoryFilter(reportType: String) {
    this.exportInventoryFilter.InventoryFilterRepresentation = this.inventoryFilter;

    if (reportType === 'meter') {
      // export for meter
      this.exportInventoryFilter.ForMeter = true;
      this.exportInventoryFilterEventEmitter.emit(this.exportInventoryFilter);
    } else if (reportType === 'space') {
      // export for space
      this.exportInventoryFilter.ForSpace = true;
      this.exportInventoryFilterEventEmitter.emit(this.exportInventoryFilter);
    }
  }

  clearFilters() {
    // Clear all filters
    Object.keys(this.inventoryFilter).forEach(key => {
      if (!key.toLowerCase().includes('operator')) {
        this.inventoryFilter[key] = undefined;
      }
    });

    // Set all filter operators to default
    this.inventoryFilter.BlockRangeFilterOperator =
      FilterOperator.GreaterThanOrEqual;
    this.inventoryFilter.AreaFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.ZoneFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.WardFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.MeterTypeFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.MeterStatusFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.ParkingTypeFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.VirtualMeterIdFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.SignOnlyMeterIdFilterOperator = FilterOperator.Equal;
    this.inventoryFilter.RatePackageNameFilterOperator = FilterOperator.Equal;
    this.inventoryFilterEventEmitter.emit(this.inventoryFilter);

    if (this.context === 'block-meters' || this.context === 'block-spaces' || this.context === 'linked-blocks') {
      this.inventoryFilter.BlockId = this.route.snapshot.paramMap.get('id');
    }
  }

  tempRemovalStatusSelected(status) {
    this.inventoryFilter.TempRemovalStatus = status;
    if (status === TempRemovalStatusConstants.TempRemoved) {
      this.tempRemoved = true;
      this.inventoryFilter.TempRemovalStatusFilterOperator =
        FilterOperator.Equal;
    } else if (status === TempRemovalStatusConstants.NotTempRemoved) {
      this.tempRemoved = false;
      this.inventoryFilter.TempRemovalStatusFilterOperator =
        FilterOperator.NotEqual;
    }
  }

  hasSignsOnlySelected(hasSignsOnly) {
    this.inventoryFilter.HasSignsOnly = hasSignsOnly;
    if(hasSignsOnly === HasSignsOnlyConstants.HasSignsOnly) {
      this.inventoryFilter.HasSignsOnlyFilterOperator = FilterOperator.Equal;
    }
    else if (hasSignsOnly === HasSignsOnlyConstants.DoesNotHaveSignsOnly) {
      this.inventoryFilter.HasSignsOnlyFilterOperator = FilterOperator.NotEqual;
    }
  }

  displayBlockFilters(): boolean {
    return this.context === 'block-list' || this.context === 'block-select' || this.context === 'physical-block-select' || this.context === 'block-to-select' || this.context === 'select-blocks';
  }
}
