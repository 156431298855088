import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { HoursOfOperation } from '../models/hours-of-operation';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class HoursOfOperationService {

    constructor(private http: HttpClient) { }

    public getHoursOfOperationById(id: string): Observable<HoursOfOperation> {
        return this.http.get('/HoursOfOperation/' + id)
          .pipe(
            map(response => response != null ? this.jsonToHoursOfOperation(response) : null),
            catchError((error) => this.handleError(error))
          );
      }

    public getAllHoursOfOperation(): Observable<HoursOfOperation[]> {
        return this.http.get('/HoursOfOperation/')
         .pipe(
           map(response => response != null ? this.jsonToHoursOfOperationList(response) : null),
           catchError((error) => this.handleError(error))
         );
    }

    public setHoursOfOperation(hoursOfOperation: HoursOfOperation): Observable<any> {
        return this.http.post('/HoursOfOperation/',  JSON.stringify(hoursOfOperation))
          .pipe(catchError((error) => this.handleError(error)));
    }

    jsonToHoursOfOperation(json: any): HoursOfOperation {
        const toReturn = new HoursOfOperation();
        for (const prop in json) {
            toReturn[prop] = json[prop];
        }

        return toReturn;
    }

    jsonToHoursOfOperationList(json: any): Array<HoursOfOperation> {

        const toReturn = new Array<HoursOfOperation>();

        json.forEach(hoo => {
            toReturn.push(this.jsonToHoursOfOperation(hoo));
        });

        return toReturn;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
