import { TokenCache } from './../../../security/token-cache';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { HoursOfRestriction } from './../models/hours-of-restriction';
import { HoursOfOperation } from './../models/hours-of-operation';
import { Component, OnInit, Optional, ViewChild, Output, EventEmitter, ErrorHandler, Inject, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable ,  of } from 'rxjs';


import { FileDownloadService } from '../../../core/file-download/file-download.service';
import { FilterOperator } from './../../../core/query-strings/query-string-builder';
import { RatePackage } from '../models/rate-package';
import { RatePackageDetailComponent } from './../rate-package-detail/rate-package-detail.component';
import { RatePackageFilterRepresentation } from './rate-package-filter-representation';
import { RatePackageService } from '../services/rate-package.service';
import { SpaceSummary } from './../../spaces/space-summary';
import { StringExtensions } from '../../../string-extensions';
import { setInterval } from 'timers';

@Component({
  selector: 'app-rate-package-list',
  templateUrl: './rate-package-list.component.html',
  styleUrls: ['./rate-package-list.component.css']
})
export class RatePackageListComponent implements OnInit {

    spaces: SpaceSummary[];
    dataSource: RatePackageDataSource;
    originalSpaceCount: number;
    thisYear: number;
    pastYear: number;
    nextYear: number;
    yearAfterNext: number;
    displayedColumns: Array<string>;
    ratePackagesLoaded: boolean;
    ratePackages: RatePackage[];
    originalRatePacakgeCount: number;
    selectedRatePackage: RatePackage;
    mode: string;
    displayLoadingError: boolean;
    displayNoResultMessage: string;
    noItemsDisplay: boolean;
    errorMessage: string;
    ratePackageNameFilterOperatorDropDown = [FilterOperator.Equal, FilterOperator.StartsWith, FilterOperator.EndsWith, FilterOperator.Contains ];
    ratePerHourFilterOperatorDropDown = [FilterOperator.Equal, FilterOperator.LessThanOrEqual, FilterOperator.GreaterThanOrEqual ];
    periodOfStayFilterOperatorDropDown = [FilterOperator.Equal, FilterOperator.LessThanOrEqual, FilterOperator.GreaterThanOrEqual];
    ratePackageFilter: RatePackageFilterRepresentation;
    activeOnlyFilter = true;
    isCityUser = this.TokenCache.checkForCityUser();

    @Input()
    hideExportButton: boolean;

    @Output()
    selectedTabEmitter: EventEmitter<number> = new EventEmitter<number>(true);

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<RatePackageListComponent>, private ratePackageService: RatePackageService, private _errorHandler: ErrorHandler,
        private fileDownloadService: FileDownloadService, private snackBar: MatSnackBar, private route: ActivatedRoute, private TokenCache: TokenCache) {
        this.spaces = new Array<SpaceSummary>();
        this.ratePackages = new Array<RatePackage>();
    }

    @ViewChild(RatePackageDetailComponent, {static: true}) ratePackageDetail: RatePackageDetailComponent;

    ngOnInit(): void {

      const ratePackageName = this.route.snapshot.paramMap.get('id');
      this.mode = 'Rate Package List';
      this.calculateReportingYear();
      this.displayedColumns = ['Name', 'Description', 'IsSeasonal', 'HoO ID', 'HoR ID', 'Past Year FUA', 'Current Year FUA', 'Next Year FUA', 'Associated HoO', 'Padding Column', 'Number of Active Meters', 'Number of Active Spaces', 'Number of Historically Associated Meters' ];

      if (ratePackageName) {
        this.ratePackageFilter = new RatePackageFilterRepresentation();
        this.ratePackageFilter.ratePackageName = ratePackageName;
          this.ratePackageFilter.date = new Date();
          this.ratePackageFilter.isInactivated = false;
      }

      if (this.ratePackageFilter === undefined) {
          this.ratePackageFilter = new RatePackageFilterRepresentation();
          this.ratePackageFilter.date = new Date();
          this.ratePackageFilter.isInactivated = false;
      }

      this.getRatePackages();
    }

    calculateReportingYear() {
      const today = new Date();
      const thisMonth = today.getMonth();
      if (thisMonth < 2) {
        this.thisYear = today.getFullYear() - 1;
        this.pastYear = today.getFullYear() - 2;
        this.nextYear = today.getFullYear();
        this.yearAfterNext = today.getFullYear() + 1;
      }
      else {
        this.thisYear = today.getFullYear();
        this.pastYear = today.getFullYear() - 1;
        this.nextYear = today.getFullYear() + 1;
        this.yearAfterNext = today.getFullYear() + 2;
      }
    }

    getRatePackages() {
      this.ratePackageFilter.isInactivated = !this.activeOnlyFilter;
      this.ratePackageService.getAllRatePackages(this.ratePackageFilter).subscribe(
        result => {
            this.ratePackages = result;
            this.ratePackagesLoaded = true;
            this.selectedRatePackage = this.ratePackages[0];

            if (this.ratePackages[0]) {
              this.getRatePackageById(this.ratePackages[0].id);
            }

            if (!this.data && this.ratePackages.length !== 0) {
                this.selectedRatePackage = this.ratePackages[0];
            }

            if (this.ratePackages.length === 0) {
                this.selectedRatePackage = null;
                this.displayNoResultMessage = 'Your search did not match any rate package.';
            } else {
                this.selectedRatePackage = this.ratePackages[0];
                this.displayNoResultMessage = '';
            }
            this.displayLoadingError = false;
            this.noItemsDisplay = StringExtensions.StringIsNotEmpty(this.displayNoResultMessage);

            this.dataSource = new RatePackageDataSource(this.ratePackages);
        },
        error => {
          this.ratePackagesLoaded = true;
          this.displayLoadingError = true;
          this.displayNoResultMessage = '';

          this.errorMessage = error;
          this._errorHandler.handleError(error);
        });
    }

    getRatePackageById(id: string) {
      this.ratePackageService.getRatePackageById(id)
        .subscribe(result => {
          this.selectedRatePackage = result;
      });
    }

    selectRatePackage() {
        this.dialogRef.close(this.selectedRatePackage);
    }

    goToMeters(ratePackageName: string) {
        localStorage.setItem('RatePackageName', ratePackageName);
        this.selectedTabEmitter.emit(1);
    }

    goToHistoricallyAssociatedMeters(ratePackageId: string, ratePackageName: string) {
        localStorage.setItem('MetersHistoricallyAssociatedWithRatePackage', ratePackageId);
        localStorage.setItem('MetersHistoricallyAssociatedWithRatePackageName', ratePackageName);
        this.selectedTabEmitter.emit(1);
    }

    goToSpaces(ratePackageName: string) {
        localStorage.setItem('RatePackageName', ratePackageName);
        this.selectedTabEmitter.emit(0);
    }

    close() {
        this.dialogRef.close();
    }

    applyFilters() {
      this.ratePackagesLoaded = false;
      this.dataSource = new RatePackageDataSource(null);

        this.ratePackageFilter.periodOfStayInMinutes = this.ratePackageFilter.periodOfStay * 60;

        this.getRatePackages();
    }

    clearFilters() {
        this.ratePackagesLoaded = false;
        this.ratePackageFilter.ratePackageName = undefined;
        this.ratePackageFilter.ratePackageNameFilterOperator = FilterOperator.Equal;

        this.ratePackageFilter.periodOfStay = undefined;
        this.ratePackageFilter.periodOfStayInMinutes = undefined;
        this.ratePackageFilter.periodOfStayFilterOperator = FilterOperator.Equal;

        this.ratePackageFilter.ratePerHour = undefined;
        this.ratePackageFilter.ratePerHourEnd = undefined;
        this.ratePackageFilter.ratePerHourFilterOperator = FilterOperator.Equal;


        this.ratePackageFilter.hoursOfOperationName = undefined;
        this.ratePackageFilter.hoursOfRestrictionName = undefined;

        this.ratePackageFilter.date = new Date();

        this.getRatePackages();
    }

    exportRatePackages() {
        this.snackBar.open('Downloading Rate Package Report', '', <MatSnackBarConfig>{
            duration: 2000
        });
        this.ratePackageService.exportRatePackages(this.ratePackageFilter).subscribe(result => {
            const fileDownloadResult = this.fileDownloadService.MapResponseToFileResult(result);
            this.fileDownloadService.SaveFileToBrowser(fileDownloadResult);
        });
    }

    reloadRatePackageDetail() {
        this.ratePackageDetail.ngOnChanges();
    }
}

class RatePackageDataSource extends DataSource<any> {

    constructor(private _ratePackages: RatePackage[]) {
        super();
    }

    connect(): Observable<RatePackage[]> {
        return of(this._ratePackages);
    }

    disconnect() {}
}
