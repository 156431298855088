import { SpaceSummary } from './../spaces/space-summary';
import { MatSort } from '@angular/material/sort';
import { SpaceStatusConstants } from './../spaces/space-constants';
import { Injectable } from '@angular/core';
import { ColumnSorter } from '../../column-sorter';

@Injectable()
export class SpaceSummarySorter extends ColumnSorter {
    getSortedData(spaces: SpaceSummary[], sort: MatSort): SpaceSummary[] {
            const data = spaces;

            if (!sort.active || sort.direction === '') {
                return data.sort((left, right) => {
                    return this.CompareStrings(left.displayId, right.displayId, sort);
                });
            }

            return data.sort((left, right) => {

                let leftSortValue: number|string = '';
                let rightSortValue: number|string = '';

                switch (sort.active) {
                    case 'Status':  [leftSortValue, rightSortValue] = [this.statusToNumber(left.currentStatus), this.statusToNumber(right.currentStatus)]; break;
                    case 'EffectiveDate': [leftSortValue, rightSortValue] = [left.effectiveDate, right.effectiveDate]; break;
                    case 'ExpirationDate': [leftSortValue, rightSortValue] = [left.expirationDate, right.expirationDate]; break;
                    case 'AroundCorner': [leftSortValue, rightSortValue] = [this.aroundCornerToNumber(left.isAroundCorner), this.aroundCornerToNumber(right.isAroundCorner)]; break;
                    case 'FineLevel': [leftSortValue, rightSortValue] = [0, 1];
                }

                if (leftSortValue === rightSortValue) {
                    return this.CompareStrings(left.displayId, right.displayId, sort);
                }

                return (leftSortValue < rightSortValue ? -1 : 1) * this.SortDirectionIsAscending(sort);
            });
    }

    statusToNumber(status: string): number {
        switch ((status == null) ? null : status.toLowerCase()) {
            case SpaceStatusConstants.active:
                return 0;
            case SpaceStatusConstants.created:
                return 1;
            case SpaceStatusConstants.inactive:
                return 2;
            case SpaceStatusConstants.moved:
                return 3;
            case SpaceStatusConstants.pending:
                return 4;
            case SpaceStatusConstants.removed:
                return 5;
            case null:
                return null;
            default:
                return 6;
        }
    }

    aroundCornerToNumber(aroundCorner: boolean): number {
        if ( aroundCorner) {
           return 0;
        }
        else {
            return 1;
        }

    }


    CompareStrings(leftDisplayId: string, rightDisplayId: string, sort: MatSort): number {

        if (leftDisplayId === rightDisplayId) {
            return 0;
        }
        else if (leftDisplayId == null) {
            return 1;
        }
        else if (rightDisplayId == null) {
            return -1;
        }

        if (sort.direction !== '') {
            const sortNumber = (leftDisplayId < rightDisplayId ? -1 : 1) * this.SortDirectionIsAscending(sort);
            return sortNumber;
        }

        const sortNumber =  (leftDisplayId < rightDisplayId ? -1 : 1) * this.SortDirectionIsAscending(sort);
        return sortNumber;
    }

    SortDirectionIsAscending(sort: MatSort): number {
        const sortNumber = (sort.direction === 'asc' || sort.direction === '' ? 1 : -1);
        return sortNumber;
    }

}
