import { Column } from './../../inventory-management/inventory-table/inventory-list/inventory-list-column-def';
import { TrueUpDetailColumnDef } from "./../true-up-detail-column-def";

export class TrueUpColumnConstants {
    public static AllMeterColumns: Array<Column> = [
        TrueUpDetailColumnDef.month,
        TrueUpDetailColumnDef.quarter,
        TrueUpDetailColumnDef.year,
        TrueUpDetailColumnDef.meterDisplayId,
        TrueUpDetailColumnDef.meterId,
        TrueUpDetailColumnDef.meterStatus,
        TrueUpDetailColumnDef.contractType,
        TrueUpDetailColumnDef.numberOfMonthlyRPAs,
        TrueUpDetailColumnDef.numberOfTotalRPAs,
        TrueUpDetailColumnDef.hasRPA,
        TrueUpDetailColumnDef.dateCreated,
        TrueUpDetailColumnDef.dateChanged,
        TrueUpDetailColumnDef.changeInMonth,
        TrueUpDetailColumnDef.fineAmount,
        TrueUpDetailColumnDef.closureAllowancePercentage,
        TrueUpDetailColumnDef.zoneDisplayId,
        TrueUpDetailColumnDef.areaDisplayId,
        TrueUpDetailColumnDef.ward,
        TrueUpDetailColumnDef.blockDisplayId,
        TrueUpDetailColumnDef.blockId,
        TrueUpDetailColumnDef.blockAddress,
        TrueUpDetailColumnDef.pac,
        TrueUpDetailColumnDef.reportingYear,
        TrueUpDetailColumnDef.reportingQuarter,
        TrueUpDetailColumnDef.revenueValueAdjustment,
        TrueUpDetailColumnDef.monthlyUR,
        TrueUpDetailColumnDef.existingRevenue,
        TrueUpDetailColumnDef.revenueValue,
        TrueUpDetailColumnDef.ratePackageName,
        TrueUpDetailColumnDef.ratePackageGuid,
        TrueUpDetailColumnDef.periodOfOperation,
        TrueUpDetailColumnDef.fullyUtilizedAmount,
        TrueUpDetailColumnDef.fuaYtd,
        TrueUpDetailColumnDef.fuaYearly,
        TrueUpDetailColumnDef.measuredFua,
        TrueUpDetailColumnDef.measuredFuaQtd,
        TrueUpDetailColumnDef.measuredFuaYtd,
        TrueUpDetailColumnDef.accurateFua,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods,
        TrueUpDetailColumnDef.uaiQtd,
        TrueUpDetailColumnDef.uaiYtd,
        TrueUpDetailColumnDef.mur,
        TrueUpDetailColumnDef.averageMonthlyUrYtd,
        TrueUpDetailColumnDef.activeSpacesCount,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly,
        TrueUpDetailColumnDef.aorQtd,
        TrueUpDetailColumnDef.aorYtd,
        TrueUpDetailColumnDef.meteredParkingRevenue,
        TrueUpDetailColumnDef.mprQtd,
        TrueUpDetailColumnDef.mprYtd,
        TrueUpDetailColumnDef.requiredClosurePayments,
        TrueUpDetailColumnDef.requiredClosurePaymentsQuarterToDate,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate,
        TrueUpDetailColumnDef.distributedRequiredClosurePayments,
        TrueUpDetailColumnDef.rdcpQtd,
        TrueUpDetailColumnDef.rdcpYtd,
        TrueUpDetailColumnDef.rpaReversalPayment,
        TrueUpDetailColumnDef.rpaReversalPaymentQtd,
        TrueUpDetailColumnDef.rpaReversalPaymentYtd,
        TrueUpDetailColumnDef.calculationTimeStamp,
        TrueUpDetailColumnDef.isValid,
        TrueUpDetailColumnDef.invalidTimeStamp,
        TrueUpDetailColumnDef.isDeleted,
        TrueUpDetailColumnDef.systemCalculationId,
        TrueUpDetailColumnDef.isActive,
        TrueUpDetailColumnDef.changedBy,
        TrueUpDetailColumnDef.isApproved,
        TrueUpDetailColumnDef.approvedBy
    ];

    public static DefaultMeterColumns: Array<Column> = [
        TrueUpDetailColumnDef.month,
        TrueUpDetailColumnDef.year,
        TrueUpDetailColumnDef.meterDisplayId,
        TrueUpDetailColumnDef.meterStatus,
        TrueUpDetailColumnDef.numberOfMonthlyRPAs,
        TrueUpDetailColumnDef.hasRPA,
        TrueUpDetailColumnDef.changeInMonth,
        TrueUpDetailColumnDef.closureAllowancePercentage,
        TrueUpDetailColumnDef.zoneDisplayId,
        TrueUpDetailColumnDef.areaDisplayId,
        TrueUpDetailColumnDef.blockDisplayId,
        TrueUpDetailColumnDef.pac,
        TrueUpDetailColumnDef.reportingYear,
        TrueUpDetailColumnDef.reportingQuarter,
        TrueUpDetailColumnDef.revenueValueAdjustment,
        TrueUpDetailColumnDef.existingRevenue,
        TrueUpDetailColumnDef.revenueValue,
        TrueUpDetailColumnDef.ratePackageName,
        TrueUpDetailColumnDef.fullyUtilizedAmount,
        TrueUpDetailColumnDef.fuaYearly,
        TrueUpDetailColumnDef.measuredFua,
        TrueUpDetailColumnDef.measuredFuaYtd,
        TrueUpDetailColumnDef.accurateFua,
        TrueUpDetailColumnDef.mur,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly,
        TrueUpDetailColumnDef.aorYtd,
        TrueUpDetailColumnDef.meteredParkingRevenue,
        TrueUpDetailColumnDef.mprYtd,
        TrueUpDetailColumnDef.requiredClosurePayments,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate,
        TrueUpDetailColumnDef.distributedRequiredClosurePayments,
        TrueUpDetailColumnDef.rdcpYtd,
    ];

    public static ExportMeterColumns: Array<string> = [
        TrueUpDetailColumnDef.month.readable,
        TrueUpDetailColumnDef.quarter.readable,
        TrueUpDetailColumnDef.year.readable,
        TrueUpDetailColumnDef.meterDisplayId.readable,
        TrueUpDetailColumnDef.meterStatus.readable,
        TrueUpDetailColumnDef.contractType.readable,
        TrueUpDetailColumnDef.numberOfMonthlyRPAs.readable,
        TrueUpDetailColumnDef.numberOfTotalRPAs.readable,
        TrueUpDetailColumnDef.hasRPA.readable,
        TrueUpDetailColumnDef.dateCreated.readable,
        TrueUpDetailColumnDef.dateChanged.readable,
        TrueUpDetailColumnDef.changeInMonth.readable,
        TrueUpDetailColumnDef.fineAmount.readable,
        TrueUpDetailColumnDef.closureAllowancePercentage.readable,
        TrueUpDetailColumnDef.zoneDisplayId.readable,
        TrueUpDetailColumnDef.areaDisplayId.readable,
        TrueUpDetailColumnDef.blockDisplayId.readable,
        TrueUpDetailColumnDef.blockAddress.readable,
        TrueUpDetailColumnDef.pac.readable,
        TrueUpDetailColumnDef.reportingYear.readable,
        TrueUpDetailColumnDef.reportingQuarter.readable,
        TrueUpDetailColumnDef.revenueValueAdjustment.readable,
        TrueUpDetailColumnDef.monthlyUR.readable,
        TrueUpDetailColumnDef.existingRevenue.readable,
        TrueUpDetailColumnDef.revenueValue.readable,
        TrueUpDetailColumnDef.ratePackageName.readable,
        TrueUpDetailColumnDef.periodOfOperation.readable,
        TrueUpDetailColumnDef.fullyUtilizedAmount.readable,
        TrueUpDetailColumnDef.fuaYtd.readable,
        TrueUpDetailColumnDef.fuaYearly.readable,
        TrueUpDetailColumnDef.measuredFua.readable,
        TrueUpDetailColumnDef.measuredFuaYtd.readable,
        TrueUpDetailColumnDef.accurateFua.readable,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods.readable,
        TrueUpDetailColumnDef.uaiYtd.readable,
        TrueUpDetailColumnDef.mur.readable,
        TrueUpDetailColumnDef.averageMonthlyUrYtd.readable,
        TrueUpDetailColumnDef.activeSpacesCount.readable,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly.readable,
        TrueUpDetailColumnDef.aorYtd.readable,
        TrueUpDetailColumnDef.meteredParkingRevenue.readable,
        TrueUpDetailColumnDef.mprYtd.readable,
        TrueUpDetailColumnDef.requiredClosurePayments.readable,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate.readable,
        TrueUpDetailColumnDef.distributedRequiredClosurePayments.readable,
        TrueUpDetailColumnDef.rdcpYtd.readable,
        TrueUpDetailColumnDef.rpaReversalPayment.readable,
        TrueUpDetailColumnDef.rpaReversalPaymentYtd.readable,
        TrueUpDetailColumnDef.calculationTimeStamp.readable
    ];

    public static AllSpaceColumns: Array<Column> = [
        TrueUpDetailColumnDef.month,
        TrueUpDetailColumnDef.quarter,
        TrueUpDetailColumnDef.year,
        TrueUpDetailColumnDef.meterDisplayId,
        TrueUpDetailColumnDef.meterId,
        TrueUpDetailColumnDef.spaceDisplayId,
        TrueUpDetailColumnDef.spaceId,
        TrueUpDetailColumnDef.spaceStatus,
        TrueUpDetailColumnDef.contractType,
        TrueUpDetailColumnDef.numberOfMonthlyRPAs,
        TrueUpDetailColumnDef.numberOfTotalRPAs,
        TrueUpDetailColumnDef.hasRPA,
        TrueUpDetailColumnDef.changeInMonth,
        TrueUpDetailColumnDef.dateCreated,
        TrueUpDetailColumnDef.dateChanged,
        TrueUpDetailColumnDef.spaceFineAmount,
        TrueUpDetailColumnDef.zoneDisplayId,
        TrueUpDetailColumnDef.areaDisplayId,
        TrueUpDetailColumnDef.ward,
        TrueUpDetailColumnDef.blockDisplayId,
        TrueUpDetailColumnDef.blockId,
        TrueUpDetailColumnDef.blockAddress,
        TrueUpDetailColumnDef.pac,
        TrueUpDetailColumnDef.monthlyUR,
        TrueUpDetailColumnDef.existingRevenue,
        TrueUpDetailColumnDef.revenueValue,
        TrueUpDetailColumnDef.urOfRv,
        TrueUpDetailColumnDef.revenueValueAdjustment,
        TrueUpDetailColumnDef.rvRecalculationDate,
        TrueUpDetailColumnDef.reportingYear,
        TrueUpDetailColumnDef.reportingQuarter,
        TrueUpDetailColumnDef.ratePackageName,
        TrueUpDetailColumnDef.ratePackageGuid,
        TrueUpDetailColumnDef.periodOfOperation,
        TrueUpDetailColumnDef.rateToFineMultiple,
        TrueUpDetailColumnDef.rateToFineMultipleFactor,
        TrueUpDetailColumnDef.expectedUtilizationRate,
        TrueUpDetailColumnDef.expectedUtilizationAdjustment,
        TrueUpDetailColumnDef.fullyUtilizedAmount,
        TrueUpDetailColumnDef.fuaYtd,
        TrueUpDetailColumnDef.fuaYearly,
        TrueUpDetailColumnDef.accurateFua,
        TrueUpDetailColumnDef.afuaYtd,
        TrueUpDetailColumnDef.measuredFua,
        TrueUpDetailColumnDef.measuredFuaQtd,
        TrueUpDetailColumnDef.measuredFuaYtd,
        TrueUpDetailColumnDef.averageMonthlyUrYtd,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly,
        TrueUpDetailColumnDef.aorQtd,
        TrueUpDetailColumnDef.aorYtd,
        TrueUpDetailColumnDef.meteredParkingRevenue,
        TrueUpDetailColumnDef.mprQtd,
        TrueUpDetailColumnDef.mprYtd,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods,
        TrueUpDetailColumnDef.uaiQtd,
        TrueUpDetailColumnDef.uaiYtd,
        TrueUpDetailColumnDef.inoperableWarehouseRecordIds,
        TrueUpDetailColumnDef.closurePaymentDailyAmount,
        TrueUpDetailColumnDef.closureAllowancePercentage,
        TrueUpDetailColumnDef.requiredClosureAllowance,
        TrueUpDetailColumnDef.qualifiedRequiredClosureDays,
        TrueUpDetailColumnDef.monthStartClosureBalance,
        TrueUpDetailColumnDef.monthEndClosureBalance,
        TrueUpDetailColumnDef.requiredClosurePayments,
        TrueUpDetailColumnDef.requiredClosurePaymentsQuarterToDate,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate,
        TrueUpDetailColumnDef.distributedRequiredClosurePayments,
        TrueUpDetailColumnDef.rdcpQtd,
        TrueUpDetailColumnDef.rdcpYtd,
        TrueUpDetailColumnDef.closures,
        TrueUpDetailColumnDef.lastRpaEffectiveDate,
        TrueUpDetailColumnDef.lastRpaWoId,
        TrueUpDetailColumnDef.rpaReversalEffectiveDate,
        TrueUpDetailColumnDef.rpaReversalWoId,
        TrueUpDetailColumnDef.rpaReversalPayment,
        TrueUpDetailColumnDef.rpaReversalPaymentQtd,
        TrueUpDetailColumnDef.rpaReversalPaymentYtd,
        TrueUpDetailColumnDef.lastClzWoEffectiveDate,
        TrueUpDetailColumnDef.lastClzWoId,
        TrueUpDetailColumnDef.lastRraEffectiveDate,
        TrueUpDetailColumnDef.lastRraWoId,
        TrueUpDetailColumnDef.ratePackageChangePriorToReversalEffectiveDate,
        TrueUpDetailColumnDef.ratePackageChangePriorToReversalWoId,
        TrueUpDetailColumnDef.lastStatusChangeDate,
        TrueUpDetailColumnDef.lastStatusChangeWoId,
        TrueUpDetailColumnDef.calculationTimeStamp,
        TrueUpDetailColumnDef.isDeleted,
        TrueUpDetailColumnDef.isValid,
        TrueUpDetailColumnDef.invalidTimeStamp,
        TrueUpDetailColumnDef.changedBy,
        TrueUpDetailColumnDef.isApproved,
        TrueUpDetailColumnDef.approvedBy,
        TrueUpDetailColumnDef.pendingRvaStatus,
        TrueUpDetailColumnDef.isActive,
        TrueUpDetailColumnDef.meterCalculationId
    ];

    public static DefaultSpaceColumns: Array<Column> = [
        TrueUpDetailColumnDef.month,
        TrueUpDetailColumnDef.year,
        TrueUpDetailColumnDef.meterDisplayId,
        TrueUpDetailColumnDef.spaceDisplayId,
        TrueUpDetailColumnDef.spaceStatus,
        TrueUpDetailColumnDef.contractType,
        TrueUpDetailColumnDef.numberOfMonthlyRPAs,
        TrueUpDetailColumnDef.numberOfTotalRPAs,
        TrueUpDetailColumnDef.hasRPA,
        TrueUpDetailColumnDef.changeInMonth,
        TrueUpDetailColumnDef.zoneDisplayId,
        TrueUpDetailColumnDef.areaDisplayId,
        TrueUpDetailColumnDef.blockDisplayId,
        TrueUpDetailColumnDef.pac,
        TrueUpDetailColumnDef.monthlyUR,
        TrueUpDetailColumnDef.existingRevenue,
        TrueUpDetailColumnDef.revenueValue,
        TrueUpDetailColumnDef.urOfRv,
        TrueUpDetailColumnDef.revenueValueAdjustment,
        TrueUpDetailColumnDef.reportingYear,
        TrueUpDetailColumnDef.reportingQuarter,
        TrueUpDetailColumnDef.ratePackageName,
        TrueUpDetailColumnDef.periodOfOperation,
        TrueUpDetailColumnDef.expectedUtilizationRate,
        TrueUpDetailColumnDef.expectedUtilizationAdjustment,
        TrueUpDetailColumnDef.fullyUtilizedAmount,
        TrueUpDetailColumnDef.fuaYtd,
        TrueUpDetailColumnDef.fuaYearly,
        TrueUpDetailColumnDef.mur,
        TrueUpDetailColumnDef.averageMonthlyUrYtd,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly,
        TrueUpDetailColumnDef.aorYtd,
        TrueUpDetailColumnDef.meteredParkingRevenue,
        TrueUpDetailColumnDef.mprYtd,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods,
        TrueUpDetailColumnDef.uaiYtd,
        TrueUpDetailColumnDef.closurePaymentDailyAmount,
        TrueUpDetailColumnDef.closureAllowancePercentage,
        TrueUpDetailColumnDef.qualifiedRequiredClosureDays,
        TrueUpDetailColumnDef.monthStartClosureBalance,
        TrueUpDetailColumnDef.monthEndClosureBalance,
        TrueUpDetailColumnDef.requiredClosurePayments,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate,
        TrueUpDetailColumnDef.distributedRequiredClosurePayments,
        TrueUpDetailColumnDef.rdcpYtd,
        TrueUpDetailColumnDef.closures,
        TrueUpDetailColumnDef.lastRpaEffectiveDate,
        TrueUpDetailColumnDef.lastRpaWoId
    ];

    public static ExportSpaceColumns: Array<string> = [
        TrueUpDetailColumnDef.month.readable,
        TrueUpDetailColumnDef.year.readable,
        TrueUpDetailColumnDef.meterDisplayId.readable,
        TrueUpDetailColumnDef.spaceDisplayId.readable,
        TrueUpDetailColumnDef.spaceStatus.readable,
        TrueUpDetailColumnDef.contractType.readable,
        TrueUpDetailColumnDef.numberOfMonthlyRPAs.readable,
        TrueUpDetailColumnDef.hasRPA.readable,
        TrueUpDetailColumnDef.changeInMonth.readable,
        TrueUpDetailColumnDef.zoneDisplayId.readable,
        TrueUpDetailColumnDef.areaDisplayId.readable,
        TrueUpDetailColumnDef.blockDisplayId.readable,
        TrueUpDetailColumnDef.pac.readable,
        TrueUpDetailColumnDef.monthlyUR.readable,
        TrueUpDetailColumnDef.existingRevenue.readable,
        TrueUpDetailColumnDef.revenueValue.readable,
        TrueUpDetailColumnDef.urOfRv.readable,
        TrueUpDetailColumnDef.revenueValueAdjustment.readable,
        TrueUpDetailColumnDef.rvRecalculationDate.readable,
        TrueUpDetailColumnDef.reportingYear.readable,
        TrueUpDetailColumnDef.reportingQuarter.readable,
        TrueUpDetailColumnDef.ratePackageName.readable,
        TrueUpDetailColumnDef.periodOfOperation.readable,
        TrueUpDetailColumnDef.rateToFineMultipleFactor.readable,
        TrueUpDetailColumnDef.expectedUtilizationRate.readable,
        TrueUpDetailColumnDef.expectedUtilizationAdjustment.readable,
        TrueUpDetailColumnDef.fullyUtilizedAmount.readable,
        TrueUpDetailColumnDef.fuaYtd.readable,
        TrueUpDetailColumnDef.fuaYearly.readable,
        TrueUpDetailColumnDef.accurateFua.readable,
        TrueUpDetailColumnDef.afuaYtd.readable,
        TrueUpDetailColumnDef.measuredFua.readable,
        TrueUpDetailColumnDef.measuredFuaYtd.readable,
        TrueUpDetailColumnDef.mur.readable,
        TrueUpDetailColumnDef.averageMonthlyUrYtd.readable,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly.readable,
        TrueUpDetailColumnDef.aorYtd.readable,
        TrueUpDetailColumnDef.meteredParkingRevenue.readable,
        TrueUpDetailColumnDef.mprYtd.readable,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods.readable,
        TrueUpDetailColumnDef.uaiYtd.readable,
        TrueUpDetailColumnDef.inoperableWarehouseRecordIds.readable,
        TrueUpDetailColumnDef.closurePaymentDailyAmount.readable,
        TrueUpDetailColumnDef.closureAllowancePercentage.readable,
        TrueUpDetailColumnDef.requiredClosureAllowance.readable,
        TrueUpDetailColumnDef.qualifiedRequiredClosureDays.readable,
        TrueUpDetailColumnDef.monthStartClosureBalance.readable,
        TrueUpDetailColumnDef.monthEndClosureBalance.readable,
        TrueUpDetailColumnDef.requiredClosurePayments.readable,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate.readable,
        TrueUpDetailColumnDef.distributedRequiredClosurePayments.readable,
        TrueUpDetailColumnDef.rdcpYtd.readable,
        TrueUpDetailColumnDef.closures.readable,
        TrueUpDetailColumnDef.lastRpaEffectiveDate.readable,
        TrueUpDetailColumnDef.lastRpaWoId.readable,
        TrueUpDetailColumnDef.lastStatusChangeDate.readable,
        TrueUpDetailColumnDef.lastStatusChangeWoId.readable
    ];

    public static AllSystemColumns: Array<Column> = [
        TrueUpDetailColumnDef.year,
        TrueUpDetailColumnDef.month,
        TrueUpDetailColumnDef.reportingYear,
        TrueUpDetailColumnDef.reportingQuarter,
        TrueUpDetailColumnDef.annualPercentageAdjustment,
        TrueUpDetailColumnDef.systemInServicePercentageMonthly,
        TrueUpDetailColumnDef.systemInServicePercentageQuarterly,
        TrueUpDetailColumnDef.systemInServicePaymentQuarterly,
        TrueUpDetailColumnDef.requiredClosurePayments,
        TrueUpDetailColumnDef.rdcpQtd,
        TrueUpDetailColumnDef.quarterlySettlementAmount,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate,
        TrueUpDetailColumnDef.settlementSystemRevenueValue,
        TrueUpDetailColumnDef.existingRevenue,
        TrueUpDetailColumnDef.revenueValue,
        TrueUpDetailColumnDef.revenueValueAdjustment,
        TrueUpDetailColumnDef.aggregateRevenueValue,
        TrueUpDetailColumnDef.reservedPowersSystemImpact,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly,
        TrueUpDetailColumnDef.aorYtd,
        TrueUpDetailColumnDef.fullyUtilizedAmount,
        TrueUpDetailColumnDef.fuaYtd,
        TrueUpDetailColumnDef.accurateFua,
        TrueUpDetailColumnDef.afuaYtd,
        TrueUpDetailColumnDef.measuredFua,
        TrueUpDetailColumnDef.measuredFuaYtd,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods,
        TrueUpDetailColumnDef.uaiYtd,
        TrueUpDetailColumnDef.meteredParkingRevenue,
        TrueUpDetailColumnDef.mprYtd,
        TrueUpDetailColumnDef.monthlyUR,
];

    public static DefaultSystemColumns: Array<Column> = [
        TrueUpDetailColumnDef.year,
        TrueUpDetailColumnDef.month,
        TrueUpDetailColumnDef.reportingYear,
        TrueUpDetailColumnDef.reportingQuarter,
        TrueUpDetailColumnDef.annualPercentageAdjustment,
        TrueUpDetailColumnDef.systemInServicePercentageMonthly,
        TrueUpDetailColumnDef.systemInServicePercentageQuarterly,
        TrueUpDetailColumnDef.systemInServicePaymentQuarterly,
        TrueUpDetailColumnDef.requiredClosurePayments,
        TrueUpDetailColumnDef.rdcpQtd,
        TrueUpDetailColumnDef.quarterlySettlementAmount,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate,
        TrueUpDetailColumnDef.settlementSystemRevenueValue,
        TrueUpDetailColumnDef.existingRevenue,
        TrueUpDetailColumnDef.revenueValue,
        TrueUpDetailColumnDef.revenueValueAdjustment,
        TrueUpDetailColumnDef.aggregateRevenueValue,
        TrueUpDetailColumnDef.reservedPowersSystemImpact,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly,
        TrueUpDetailColumnDef.aorYtd,
        TrueUpDetailColumnDef.fullyUtilizedAmount,
        TrueUpDetailColumnDef.fuaYtd,
        TrueUpDetailColumnDef.accurateFua,
        TrueUpDetailColumnDef.afuaYtd,
        TrueUpDetailColumnDef.measuredFua,
        TrueUpDetailColumnDef.measuredFuaYtd,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods,
        TrueUpDetailColumnDef.uaiYtd,
        TrueUpDetailColumnDef.meteredParkingRevenue,
        TrueUpDetailColumnDef.mprYtd,
        TrueUpDetailColumnDef.monthlyUR



    ];

    public static ExportSystemColumns: Array<string> = [
        TrueUpDetailColumnDef.year.readable,
        TrueUpDetailColumnDef.month.readable,
        TrueUpDetailColumnDef.reportingYear.readable,
        TrueUpDetailColumnDef.reportingQuarter.readable,
        TrueUpDetailColumnDef.annualPercentageAdjustment.readable,
        TrueUpDetailColumnDef.systemInServicePercentageMonthly.readable,
        TrueUpDetailColumnDef.systemInServicePercentageQuarterly.readable,
        TrueUpDetailColumnDef.systemInServicePaymentQuarterly.readable,
        TrueUpDetailColumnDef.requiredClosurePayments.readable,
        TrueUpDetailColumnDef.rdcpQtd.readable,
        TrueUpDetailColumnDef.quarterlySettlementAmount.readable,
        TrueUpDetailColumnDef.requiredClosurePaymentsYearToDate.readable,
        TrueUpDetailColumnDef.settlementSystemRevenueValue.readable,
        TrueUpDetailColumnDef.existingRevenue.readable,
        TrueUpDetailColumnDef.revenueValue.readable,
        TrueUpDetailColumnDef.revenueValueAdjustment.readable,
        TrueUpDetailColumnDef.aggregateRevenueValue.readable,
        TrueUpDetailColumnDef.reservedPowersSystemImpact.readable,
        TrueUpDetailColumnDef.actualOperatingRevenueMonthly.readable,
        TrueUpDetailColumnDef.aorYtd.readable,
        TrueUpDetailColumnDef.fullyUtilizedAmount.readable,
        TrueUpDetailColumnDef.fuaYtd.readable,
        TrueUpDetailColumnDef.accurateFua.readable,
        TrueUpDetailColumnDef.afuaYtd.readable,
        TrueUpDetailColumnDef.measuredFua.readable,
        TrueUpDetailColumnDef.measuredFuaYtd.readable,
        TrueUpDetailColumnDef.utilizedAmountInoperablePeriods.readable,
        TrueUpDetailColumnDef.uaiYtd.readable,
        TrueUpDetailColumnDef.meteredParkingRevenue.readable,
        TrueUpDetailColumnDef.mprYtd.readable,
        TrueUpDetailColumnDef.monthlyUR.readable



    ];
}
