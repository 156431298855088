import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { StringExtensions } from '../../../../string-extensions';
import { SpaceSummary } from '../../../../inventory-management/spaces/space-summary';
import { SpaceInventoryChange } from '../../models/space-inventory-change';

@Component({
    selector: 'app-work-order-space-modification',
    templateUrl: 'work-order-space-modification.component.html'
})
export class WorkOrderSpaceModificationComponent implements OnChanges {
    @Input() changeLabel: string;
    @Input() spaces: SpaceSummary[];
    @Input() spaceInventoryChanges: SpaceInventoryChange[];
    @Input() meterId: string;

    @Output() errorsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    public totalModifications: number;
    public remainingSpaceModifications: number;
    public selectedSpaceIds = new Array<string>();
    public selectedSpaces = new Map<string, { spaceId: string, displayId: string }>();
    public remainingSpaces: number;
    public completedInventoryChanges = new Array<string>();

    constructor() { }

    ngOnChanges() {
        if (this.spaces && (this.spaces.length === this.spaceInventoryChanges.length)) {
            this.setAll();
            return;
        }

        this.totalModifications = this.spaceInventoryChanges.length;
        this.spaceInventoryChanges
            .filter(spaceInventoryChange => spaceInventoryChange.spaceId && spaceInventoryChange.spaceId !== StringExtensions.EmptyGuid)
            .forEach(spaceInventoryChange => {
                this.selectedSpaces.set(spaceInventoryChange.spaceId,
                    {
                        spaceId: spaceInventoryChange.spaceId, displayId: spaceInventoryChange.spaceDisplayId
                    }
                );
            });

        this.remainingSpaces = this.calculateRemainingSpaceModifications();
    }

    public onSpaceSelectionChange(space: SpaceSummary) {
        if (this.selectedSpaces.has(space.id)) {
            this.selectedSpaces.delete(space.id);
        } else {
            this.selectedSpaces.set(space.id, { spaceId: space.id, displayId: space.displayId });
        }

        this.remainingSpaces = this.calculateRemainingSpaceModifications();

        const spacesArray = Array.from(this.selectedSpaces.values());

        this.spaceInventoryChanges.forEach((spaceChange, index) => {
            const space = spacesArray[index];
            if (space) {
                spaceChange.spaceId = space.spaceId;
                spaceChange.spaceDisplayId = space.displayId;
            } else {
                spaceChange.spaceId = StringExtensions.EmptyGuid;
                spaceChange.spaceDisplayId = null;
            }
        });
        this.emitSpaceInventoryChangeStatus();
    }

    public spaceCheckboxShouldBeDisabled(space: SpaceSummary) {
        if (this.spaces.length === this.spaceInventoryChanges.length) { return true; }
        if (this.remainingSpaces === 0 && this.selectedSpaces.has(space.id)) { return false; }
        if (this.remainingSpaces === 0) { return true; }
        return false;
    }

    private setAll() {
        this.spaceInventoryChanges.forEach((spaceChange, index) => {
            const space = this.spaces[index];
            if (space) {
                spaceChange.spaceId = space.id;
                spaceChange.spaceDisplayId = space.displayId;
            }
            this.selectedSpaces.set(space.id, { spaceId: space.id, displayId: space.displayId });
        });
        this.remainingSpaces = 0;
        this.emitSpaceInventoryChangeStatus();
    }

    private calculateRemainingSpaceModifications(): number {
        return (this.totalModifications - this.selectedSpaces.size) - this.completedInventoryChanges.length;
    }

    emitSpaceInventoryChangeStatus() {
        const allSpaceChangesSelected = this.spaceInventoryChanges.filter(x => !x.spaceDisplayId).length === 0;
        this.errorsEmitter.emit(allSpaceChangesSelected);
    }

}
