import { WorkOrderUpdate } from './../models/work-order-update';
import { Injectable } from '@angular/core';
import { WorkOrderHttpService } from './work-order-http.service';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class LetterOfDirectionService {

  constructor(private workorderhttp: WorkOrderHttpService) { }

  public approveLetterOfDirection(workOrderId: string, letterOfDirectionId: number): Observable<any> {

    return this.workorderhttp.put(`/workorders/${workOrderId}/letterOfDirections/${letterOfDirectionId}/approval/`, {})
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public returnWithComments(workOrderId: string, letterOfDirectionId: number, comments: string, isRejection:boolean): Observable<any> {
    var url = '';
    if(isRejection){
      url = `/workorders/${workOrderId}/letterOfDirections/${letterOfDirectionId}/rejection`;
    }
    else{
      url = `/workorders/${workOrderId}/letterOfDirections/${letterOfDirectionId}/comments`;
    }

    return this.workorderhttp.put(url, JSON.stringify(comments))
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public applyHistoricalChanges(workOrderUpdateApiModel: WorkOrderUpdate): Observable<any> {
    return this.workorderhttp.put(`/workOrders/applyLetterOfDirectionHistoricalChange`, workOrderUpdateApiModel)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
