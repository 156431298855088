import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderHttpService } from './../../services/work-order-http.service';
import { SubmitRegularRateAdjustmentChangeGroup } from './../regular-rate-adjustment/models/submit-regular-rate-adjustment-change-group';
import { WorkOrderActionResponse } from '../../models/approval-models';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class RegularRateAdjustmentService {

  constructor(private workOrderHttp: WorkOrderHttpService, private snackBar: MatSnackBar) { }

  public submitRegularRateChanges(submitRegularRateAdjustmentChangeGroup: SubmitRegularRateAdjustmentChangeGroup): Observable<any> {
    return this.workOrderHttp.put('/RegularRateAdjustment/submit', submitRegularRateAdjustmentChangeGroup)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public activateRegularRateChanges(submitRegularRateAdjustmentChangeGroup: SubmitRegularRateAdjustmentChangeGroup): Observable<any> {
    return this.workOrderHttp.put('/RegularRateAdjustment/activate', submitRegularRateAdjustmentChangeGroup)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public returnWithComments(responseModel: WorkOrderActionResponse): Observable<any> {
    return this.workOrderHttp.put('/RegularRateAdjustment/comments', responseModel)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public acceptChanges(responseModel: WorkOrderActionResponse): Observable<any> {
    return this.workOrderHttp.put('/RegularRateAdjustment/accept', responseModel)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public deleteSelection(workOrderActionId: string, groupId: string, workOrderId: string): Observable<any> {
    return this.workOrderHttp.put('/RegularRateAdjustment/' + workOrderActionId + '/deletegroup/' + groupId + '/' + workOrderId, null)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}
