import { ErrorHandler, Component } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MonthlyTrueUpCalculationComponentBase } from "../monthly-calculations/monthly-true-up-calculation-component-base";
import { SystemTrueUpDetail } from "./system-true-up-detail";
import { SystemTrueUpDetailDataSource } from "./system-true-up-detail-datasource";
import { SystemTrueUpDetailService } from "./system-true-up-detail.service";
import { WorkOrdersService } from "../../work-orders/services/work-orders.service";

@Component({
  selector: 'system-true-up-detail',
  templateUrl: './system-true-up-detail.component.html',
  styleUrls: ['./system-true-up-detail.component.css']
})
export class SystemTrueUpDetailComponent extends MonthlyTrueUpCalculationComponentBase<SystemTrueUpDetail>  {
  dataSource: SystemTrueUpDetailDataSource;
  
  constructor(errorHandler: ErrorHandler, service: SystemTrueUpDetailService, workOrderService: WorkOrdersService, public snackBar:MatSnackBar) {
  super(errorHandler,service, workOrderService, snackBar);
  }

}

