
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpaceInventoryChange } from './../models/space-inventory-change';
import { Injectable } from '@angular/core';

import {WorkOrderHttpService} from '../../services/work-order-http.service';
import { LoadingZoneImpactChangeGroup } from '../../models/loading-zone-impacts/loading-zone-impact-change-group';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class SpaceInventoryChangeService {

  constructor(private workorderhttp: WorkOrderHttpService, private snackBar: MatSnackBar) { }

  public saveSpaceInventoryChange(workOrderId: string, spaceInventoryChange: SpaceInventoryChange): Observable<any> {
    return this.workorderhttp.put('/WorkOrder/' + workOrderId + '/spaceChanges/' + spaceInventoryChange.id + '/save', spaceInventoryChange)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public submitSpaceInventoryChange(workOrderId: string, spaceInventoryChange: SpaceInventoryChange): Observable<any> {
    return this.workorderhttp.put('/WorkOrder/' + workOrderId + '/spaceChanges/' + spaceInventoryChange.id + '/submit', spaceInventoryChange)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  public submitSpaceInventoryChanges(workOrderId: string, spaceInventoryChanges: SpaceInventoryChange[]): Observable<any> {
    const variables = {};
    const variableArray = [];
    spaceInventoryChanges.forEach(spaceInventoryChange => {
      if (spaceInventoryChange.status !== 'Completed') {
        variables['spaceChange' + (spaceInventoryChanges.indexOf(spaceInventoryChange))] = this.workorderhttp.put('/WorkOrder/' + workOrderId + '/spaceChanges/' + spaceInventoryChange.id + '/submit', spaceInventoryChange);

        variableArray.push(variables['spaceChange' + spaceInventoryChanges.indexOf(spaceInventoryChange)]);
      }
    });

    observableForkJoin(variableArray).subscribe(results => {
      return results;
    });

    return null;
  }

  public submitSpaceImpactChange(model: LoadingZoneImpactChangeGroup, workOrderId: string, workOrderActionId: string): Observable<any> {
    return this.workorderhttp.post(`/WorkOrder/${workOrderId}/action/${workOrderActionId}/impactChanges/submit`, model)
      .pipe(
        map(response => response != null ? response : null),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}
