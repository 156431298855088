import { Component, Optional, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-lod-apply-historical-changes-confirmation-dialog',
    templateUrl: './lod-apply-historical-changes-confirmation.component.html',
    styleUrls: ['./lod-apply-historical-changes-confirmation.component.css']
})
export class LODApplyHistoricalChangesConfirmation implements OnInit {

    constructor(public dialogRef: MatDialogRef<LODApplyHistoricalChangesConfirmation>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

    doesNotNeedApproval: boolean;

    ngOnInit() {
        this.doesNotNeedApproval = this.data.doesNotNeedApproval;
    }

    submit() {
        this.dialogRef.close(true);
    }
}
