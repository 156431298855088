import { Injectable } from '@angular/core';
import { ReportingHttpService } from './reporting-http.service';
import { FileDownloadResult } from '../../core/models/file-download-result';
import { Observable } from 'rxjs';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { TokenCache } from '../../security/token-cache';
import { HttpBackend } from '@angular/common/http';
import { HttpRequestOptions } from '../../core/http-request-options';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ReservedPowerReportingService extends ReportingHttpService {

  // Correlates to QueueItemTypeMetaData in Reporting API
  public static METER_KEY = 'METER_RESERVED_POWER';
  public static METER_DATA_KEY = 'METER_RESERVED_POWER_DATA';
  public static SPACE_KEY = 'SPACE_RESERVED_POWER';
  public static SPACE_DATA_KEY = 'SPACE_RESERVED_POWER_DATA';

  constructor(
    backend: HttpBackend, options: HttpRequestOptions, tokenCache: TokenCache, fileService: FileDownloadService) { super(backend, options, tokenCache, fileService); }

  public RequestSpaceMonthlyReservedPowerReport(forMonth: Date) {
    return this.post(`/reports/reservedpowers/spaces/${forMonth.getFullYear()}/${forMonth.getMonth() + 1}/`, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public RequestMeterMonthlyReservedPowerReport(forMonth: Date) {
    return this.post(`/reports/reservedpowers/meters/${forMonth.getFullYear()}/${forMonth.getMonth() + 1}/`, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public RequestSpaceMonthlyReservedPowerData(forMonth: Date) {
    return this.post(`/reports/reservedpowers/data/spaces/${forMonth.getFullYear()}/${forMonth.getMonth() + 1}/`, null)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public RequestMeterMonthlyReservedPowerData(forMonth: Date) {
    return this.post(`/reports/reservedpowers/data/meters/${forMonth.getFullYear()}/${forMonth.getMonth() + 1}/`, null)
      .pipe(catchError((error) => this.handleError(error)));
  }


  public DownloadMeterReport(key: string) {
    return super.DownloadReport(ReservedPowerReportingService.METER_KEY, key, 0);
  }

  public DownloadMeterReportData(key: string) {
    return super.GetReportData(ReservedPowerReportingService.METER_DATA_KEY, key, 0);
  }

  public DownloadSpaceReport(key: string) {
    return super.DownloadReport(ReservedPowerReportingService.SPACE_KEY, key, 0);
  }

  public DownloadSpaceReportData(key: string) {
    return super.GetReportData(ReservedPowerReportingService.SPACE_DATA_KEY, key, 0);
  }

}
