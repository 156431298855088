import { ActionConstants } from './../action/action-constants';
import { StringExtensions } from './../../string-extensions';
import { LetterOfDirectionStatusType, TempApprovalStatusTypes, WorkOrder} from '../models/work-order';
import { LodWithActionFields, LetterOfDirection } from '../models/letter-of-direction';
import { WorkOrderStatusConstants } from '../work-order-constants';
import { LetterOfDirectionResponseConstants } from '../models/letter-of-direction-response-constants';

export class LODButtonCalculator {

    static showAcceptButton(workOrder: WorkOrder, selectedLod: LodWithActionFields, latestLod: LetterOfDirection, userRole: string, userIsCity: boolean, workOrderStatus: number): boolean {
        if(this.workOrderIsPaused(workOrder) || this.workOrderIsCancelled(workOrder)) {
            return false;
        }
        const notAlreadyAccepted =  this.StatusIsDraftedOrReadyForReview(latestLod.status);

        const showForCity = this.LodIsTempApprovedAndUserIsCity(latestLod, userIsCity);

        return showForCity || (selectedLod.lod.authorUserRole !== userRole
            && LODButtonCalculator.selectedLodVersionIsLatest(selectedLod, latestLod) && notAlreadyAccepted);
    }

    private static selectedLodVersionIsLatest(selectedLod: LodWithActionFields, latestLod: LetterOfDirection) {
        return selectedLod.lod.versionNumber === latestLod.versionNumber;
    }

    static showModifyButton(workOrder: WorkOrder, selectedLod: LodWithActionFields, latestLod: LetterOfDirection, workOrderStatus: number, userIsCity: boolean): boolean {
        if(this.workOrderIsPaused(workOrder)) {
            return false;
        }
        const notAlreadyAccepted =  this.StatusIsDraftedOrReadyForReview(latestLod.status);
        const workOrderNotAccepted = workOrderStatus < WorkOrderStatusConstants.AcceptedId;

        if (userIsCity && selectedLod.lod.workOrderActions.length === 1 && selectedLod.lod.workOrderActions[0].actionId === ActionConstants.RegularRateAdjustmentId) {
          return false;
        }
        return workOrderNotAccepted && notAlreadyAccepted && LODButtonCalculator.selectedLodVersionIsLatest(selectedLod, latestLod);
    }
    static showRejectButton(workOrder: WorkOrder, selectedLod: LodWithActionFields, latestLod: LetterOfDirection, userRole: string, userIsCity: boolean) {
        if(this.workOrderIsPaused(workOrder)) {
            return false;
        }
        return this.LodIsTempApprovedAndUserIsCity(latestLod, userIsCity);
    }
    static showPauseButton(workOrder: WorkOrder, userIsCity: boolean) {
        return workOrder.currentStatusId !== WorkOrderStatusConstants.PausedId && workOrder.currentStatusId !== WorkOrderStatusConstants.DraftedId && workOrder.currentStatusId !== WorkOrderStatusConstants.CompletedId 
            && workOrder.currentStatusId !== WorkOrderStatusConstants.CancelledId && workOrder.currentStatusId !== WorkOrderStatusConstants.UndoneId
            && !userIsCity;
    }
    static showReinstateButton(workOrder: WorkOrder, userIsCity: boolean) {
        return workOrder.currentStatusId === WorkOrderStatusConstants.PausedId && !userIsCity;
    }

    static showCommentsButton(workOrder: WorkOrder, selectedLod: LodWithActionFields, latestLod: LetterOfDirection, workOrderUserRole: string, userIsCity: boolean, currentUser: string): boolean {
        if(this.workOrderIsPaused(workOrder) || this.workOrderIsCancelled(workOrder)) {
            return false;
        }
        const notAlreadyAccepted =  this.StatusIsDraftedOrReadyForReview(latestLod.status);
        const alreadyResponded = latestLod.letterOfDirectionResponses && latestLod.letterOfDirectionResponses.length >= 1;

        const showForCity = this.LodIsTempApprovedAndUserIsCity(latestLod, userIsCity);

        if(latestLod.mostRecentResponse) {
            const otherUserResponded = latestLod.mostRecentResponse.responder !== currentUser;
            
            return showForCity || ((!alreadyResponded || otherUserResponded) && notAlreadyAccepted && LODButtonCalculator.selectedLodVersionIsLatest(selectedLod, latestLod)
                && (selectedLod.lod.authorUserRole !== workOrderUserRole || otherUserResponded)); 
        } 
        return showForCity || (!alreadyResponded && notAlreadyAccepted && LODButtonCalculator.selectedLodVersionIsLatest(selectedLod, latestLod)
            && selectedLod.lod.authorUserRole !== workOrderUserRole);

    }

    static StatusIsDraftedOrReadyForReview(status: string): boolean {
        return status === LetterOfDirectionStatusType.Drafted || status === LetterOfDirectionStatusType.ReadyForReview || status === LetterOfDirectionStatusType.ReturnedWithComments;
    }

    static showTemporarilyApproveButton(workOrder: WorkOrder, selectedLod: LodWithActionFields, latestLod: LetterOfDirection, userRole: string, isElevatedApproverUser: boolean, userIsCity: boolean, userLogin: string, userName: string): boolean {
        // non elevated admins never have the ability to temp approve 
        // as their temp approval always comes from initiating the action
        if(!isElevatedApproverUser){
            return false;
        }

        if(userIsCity){
            return false; //obviously
        }

        if(this.workOrderIsPaused(workOrder)) {
            return false;
        }
        
        //cannot temp approve that which is already accepted
        const notAlreadyAccepted =  this.StatusIsDraftedOrReadyForReview(latestLod.status);
        
        if(!notAlreadyAccepted){
            return false;
        }

        //cannot be same person twice, so check if this user already approved the LOD
        var approvedByUser = selectedLod.lod.letterOfDirectionResponses.filter(lod => {
            return (lod.responder === userName || lod.responder === userLogin) //it is ambiguous which of these should be saved, so check both for now
                    && lod.responseType === LetterOfDirectionResponseConstants.Approve
        });

        //reject if this user already approved it, must have two different approvals to temp approve
        if(approvedByUser.length !== 0){
            return false;
        }
        
        return  LODButtonCalculator.selectedLodVersionIsLatest(selectedLod, latestLod)
                && !this.showAcceptButton(workOrder, selectedLod, latestLod, userRole, false, null) 
                && selectedLod.lod.readyForAcceptance;
    }

    static showModifyAsCityButton(workOrder: WorkOrder, selectedLod: LodWithActionFields, latestLod: LetterOfDirection, userRole: string, isAdminUser: boolean, isNonCPMUser: boolean): boolean {
        //only CPM is allowed to modify as city
        if (isNonCPMUser) {
          return false;
        }

        if (this.workOrderIsPaused(workOrder) || this.workOrderIsCancelled(workOrder)) {
            return false;
        }

        const notAlreadyAccepted = this.StatusIsDraftedOrReadyForReview(latestLod.status);

        //only for LODs that are not accepted already
        if(!notAlreadyAccepted){
            return false;
        }

        if (selectedLod.lod.workOrderActions.length === 1 && selectedLod.lod.workOrderActions[0].actionId === ActionConstants.RegularRateAdjustmentId) {
            return false;
        }

        //only admins can modify as city
        if(!isAdminUser){
            return false;
        }

        //rule: ready for review && assigned to city 
        return workOrder.assignedTo === 'City'
            && LODButtonCalculator.selectedLodVersionIsLatest(selectedLod, latestLod);
    }

    static LodIsTempApprovedAndUserIsCity(latestLod: LetterOfDirection, userIsCity: boolean): boolean {
        return latestLod.tempApprovalStatus === TempApprovalStatusTypes.TempApproved  && userIsCity;
    }

    static showModifyAcceptedWorkOrderButton(workOrder: WorkOrder): any {
        if(this.workOrderIsPaused(workOrder)) {
            return false;
        }
        return workOrder.currentStatusId === WorkOrderStatusConstants.AcceptedId;
    }

    static workOrderIsPaused(workOrder: WorkOrder): any {
        return workOrder.currentStatusId === WorkOrderStatusConstants.PausedId;
    }

    static workOrderIsCancelled(workOrder: WorkOrder): any {
        return workOrder.currentStatusId === WorkOrderStatusConstants.CancelledId;
    }
}
