import { FineAmountService } from './fine-amounts/fine-amounts.service';
import { RatePackageListComponent } from './rate-packages/rate-package-list/rate-package-list.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-inventory-management',
  templateUrl: './inventory-management.component.html',
  styleUrls: ['./inventory-management.component.css']
})
export class InventoryManagementComponent implements OnInit {
  searchText: string;
  selectedIndex: number;
  mode: string;

  @ViewChild(RatePackageListComponent, {static: true}) ratePackageListComponent: RatePackageListComponent;


  constructor() { }

  ngOnInit() {
    this.mode = 'list';

    if (location.pathname.includes('spaces')) {
      this.selectedIndex = 0;
    }
    else if (location.pathname.includes('meters')) {
      this.selectedIndex = 1;
    }
    else if (location.pathname.includes('blocks')) {
      this.selectedIndex = 2;
    }
    else if (location.pathname.includes('ratepackages')) {
      this.selectedIndex = 3;

      if (location.pathname.includes('create')) {
        this.mode = 'create';
      }
      else {
        this.mode = 'list';
      }
    }
  }

  switchTab(evt: number) {
    this.selectedIndex = evt;
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (this.ratePackageListComponent && tabChangeEvent.tab.textLabel === 'Rate Packages') {
      this.ratePackageListComponent.reloadRatePackageDetail();
    }
  }
}
