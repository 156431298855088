
import {of as observableOf,  Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockInventoryChange } from './../models/block-inventory-change';
import { Injectable } from '@angular/core';

import {WorkOrderHttpService} from '../../services/work-order-http.service';
import { AddressValidationService } from '../../../inventory-management/blocks/address-validation.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class BlockInventoryChangeService {

  constructor(private workorderhttp: WorkOrderHttpService,
              private addressValidationService: AddressValidationService, public snackBar: MatSnackBar) { }

  variables: any;
  error: any;

  public saveBlockInventoryChange(workOrderId: string, blockInventoryChange: BlockInventoryChange): Observable<any> {

    return this.workorderhttp
      .put('/WorkOrder/' + workOrderId + '/blockChanges/' + blockInventoryChange.id + '/save', blockInventoryChange)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public submitBlockChange(blockChange: BlockInventoryChange, workOrderId: string) {

      const validationResult = this.addressValidationService.validateAddressIsValid(blockChange.blockStart, blockChange.blockEnd);
      if (validationResult.failed) {
        this.snackBar.open('Block Change Not Submitted', validationResult.message, { duration: 2000 });
        return observableOf(null); // don't submit the changes
      }

      return this.workorderhttp
          .put('/WorkOrder/' + workOrderId + '/blockChanges/' +  blockChange.id + '/submit', blockChange)
          .pipe(map(response => response));
  }

  public submitBlockChangeWithoutWorkOrder(blockChange: BlockInventoryChange) {
    const validationResult = this.addressValidationService.validateAddressIsValid(blockChange.blockStart, blockChange.blockEnd);
    if(validationResult.failed) {
      this.snackBar.open('Block Change Not Submitted: ', validationResult.message, { duration: 2000 });
      return observableOf(null); //don't submit the changes
    }

    return this.workorderhttp
      .put('/blockChange/submit', blockChange)
      .pipe(map(response => response));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action);
  }
}
