exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".new-comment {\r\n    margin-left: 5%;\r\n}\r\n\r\n.submit-button {\r\n    margin-top: 5%;\r\n    margin-bottom: 10%\r\n}\r\n\r\n.italicized {\r\n    font-style: italic;\r\n    margin-bottom: 1%;\r\n}\r\n\r\n.comment {\r\n    margin-left: 5%;\r\n    margin-bottom: 5%;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();