exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".select-button {\r\n    height:65%;\r\n    width:65%;\r\n    color: white;\r\n    background-color: #607d8b;\r\n    font-size:115%;\r\n}\r\n\r\n.mat-radio-label {\r\n    max-width:100% !important;\r\n    width:100% !important;\r\n}\r\n\r\n.select-meter {\r\n    padding-bottom:20px;\r\n}\r\n\r\n.mat-dialog-container {\r\n    max-width:1300px !important;\r\n    width:1300px !important;\r\n}\r\n\r\n.mat-row:hover, .selected-row {\r\n  background-color: #CFD8DC;\r\n  cursor: pointer;\r\n}\r\n\r\n.side-of-street {\r\n    padding-top:25px;\r\n}\r\n\r\n.meter-details {\r\n    min-height: 25px !important;\r\n    max-height: 25px !important;\r\n    height: 25px !important;\r\n}\r\n\r\n.id-error {\r\n    font-style: italic;\r\n    color: red;\r\n    padding-left:5%;\r\n}\r\n\r\n[hidden] {\r\n    display: none !important;\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();