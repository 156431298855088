export class AnnualPercentageAdjustmentValue {
    constructor(public id: string, public percentage: number, public fiscalStartYear: string){}
  }

export class AnnualPercentageAdjustmentValueToUpdate {
  constructor(public id: string, public percentage: number, public fiscalStartYearInt: number,){}
}

export class AnnualPercentageAdjustmentValueToSave {
  constructor(public percentage: number, public fiscalStartYearInt: number,){}
}