export class FieldConstants {

    static RatePackageDropdown = 106;
    static UniqueRestrictionsId = 60;

    static RelocateMeterId = 95;
    static ShiftSpacesId = 111;
    static RemoveSpaceRelocateMeterId = 165;
    static RemoveSpaceShiftSpacesId = 185;
    static RemoveSpaceUpdateBlockRange = 205;

    static InstallSpaceReasonDropdownId = 5;
    static RemoveSpaceReasonDropdownId = 10;
    static InstallSpaceReasonDropdownName = 'Reason Dropdown';
    static RemoveSpaceReasonDropdownName = 'Reason Dropdown';

    static InstallSpaceMeterTypeDropdownId = 15;
    static InstallSpaceMeterTypeDropdownName = 'Meter Type Dropdown';

    static InstallSpaceContractTypeDropdownId = 20;
    static InstallSpaceContractTypeDropdownName = 'Contract Type Dropdown';

    static InstallSpaceLocationTypeDropdownId = 30;
    static InstallSpaceLocationTypeDropdownName = 'Location Type Dropdown';

    // Install Meter
    static InstallMeterReasonDropdownId = 200;
    static InstallMeterReasonDropdownName = 'Reason Dropdown';
    static InstallMeterAddressLocationAndDescriptionId = 205;
    static InstallMeterAddressLocationAndDescriptionName = 'Address/Location Description';
    static InstallMeterLocationTypeDropdownId = 210;
    static InstallMeterLocationTypeDropdownName = 'Location Type';
    static InstallMeterCrossStreetsId = 215;
    static InstallMeterCrossStreetsName = 'Cross Streets';
    static InstallMeterMeterTypeDropdownId = 220;
    static InstallMeterMeterTypeDropdownName = 'Meter Type';

    // shared fields ids starting at 10000
    static MeterIdTextId = 10000;

    static MeterIdTextName = 'Meter ID';
    static MeterIDId = 100001;
    static MeterIDName = 'Meter ID';
    static NumberOfSpacesId = 100002;
    static NumberOfSpacesName = 'Number of Spaces to Remove';
    static EURId = 100003;
    static EURName = 'EUR (%)';
    static MeterAddressId = 100004;
    static MeterAddressName = 'Meter Address';
    static MeterSelectId = 100005;
    static MeterSelectName = 'Meter ID';
    static ContractTypeId = 100006;
    static ContractTypeName = 'Contract Type';
    static MeterRatePackageId = 106;
    static MeterRatePackageName = 'Rate Package ID';
    static BlockSelectName = 'Block ID';
    static BlockSelectId = 100008;
    static RatePackageSelectName = 'Rate Package ID';
    static RatePackageSelectId = 100009;


    static SignsOnlyFieldId = 100011;


    static SignsOnlyFieldName = 'Signs Only';

    static PhysicalBlockIdId = 100012;
    static PhysicalBlockIdName = 'Physical Block ID';

    static CommentsId = 100013;
    static CommentsName = 'Comments';
    static InternalCommentsId = 100014;
    static InternalCommentsName = 'Internal Comments';
    static DaysToCompleteId = 100015;
    static DaysToCompleteName = 'Days To Complete';
    static DueDateId = 100016;
    static DueDateName = 'Due Date';
    static UpdateBlockRangeId = 100017;
    static UpdateBlockRangeName = 'Update Block Range';
    static BlockRangeFromId = 100018;

    static UseExistingBlockCheckBoxId = 100023;
    static UseExistingBlockCheckBoxName = 'Use Existing Block';

    static BlockRangeFromName = 'Block Range From';
    static BlockRangeToId = 100024;
    static BlockRangeToName = 'Block Range To';

    static RatePerHourId = 100019;
    static RatePerHourName = 'Rate per Hour ($)';
    static PeriodOfStayId = 100020;
    static PeriodOfStayName = 'Period of Stay (in hours)';
    static HoursOfOperationId = 100021;
    static HoursOfOperationName = 'Hours of Operation';
    static HoursOfRestrictionId = 100022;
    static HoursOfRestrictionName = 'Hours of Restriction';
    static BlockAddressId = 100023;
    static BlockAddressName = 'Block Address';
    static EntireMeterId = 100034;
    static EntireMeterName = 'Entire Meter';
    static ChangeRatePackageNumberOfSpacesId = 100025;
    static ChangeRatePackageNumberOfSpacesName = 'Number of Spaces';
    static ChangeRatePerHourId = 100026;
    static ChangeRatePerHourName = 'Change Rate per Hour';
    static ChangeRatePackageRatePerHourId = 100027;
    static ChangeRatePackageRatePerHourName = 'Rate per Hour ($)';
    static ChangePeriodOfStayId = 100028;
    static ChangePeriodOfStayName = 'Change Period of Stay';
    static ChangeRatePackagePeriodOfStayId = 100029;
    static ChangeRatePackagePeriodOfStayName = 'Period of Stay (in hours)';
    static ChangeHoursOfOperationId = 100030;
    static ChangeHoursOfOperationName = 'Change Hours of Operation';
    static ChangeRatePackageHoursOfOperationId = 100031;
    static ChangeRatePackageHoursOfOperationName = 'Hours of Operation';
    static ChangeHoursOfRestrictionId = 100032;
    static ChangeHoursOfRestrictionName = 'Change Hours of Restriction';
    static ChangeRatePackageHoursOfRestrictionId = 100033;
    static ChangeRatePackagehoursOfRestrictionName = 'Hours of Restriction';

    static MeterFromId = 100035;
    static MeterFromName = 'From Meter';
    static MeterToId = 100036;
    static MeterToName = 'From Meter';

    static RemoveEffectiveImmediatelyId = 100037;
    static RemoveEffectiveImmediatelyName = 'Remove Effective Immediately';
    static RemovalDateId = 100038;
    static RemovalDateName = 'Select Removal Date';

    static ReinstallEffectiveImmediatelyId = 100040;
    static ReinstallEffectiveImmediatelyName = 'Reinstall Effective Immediately';
    static ReinstallDateLAZId = 100041;
    static ReinstallDateLAZName = 'Reinstall Date';

    static RegularRateAdjustmentIncreaseOrDecreaseId = '910';
    static RegularRateAdjustmentRate= '920';

    static UpdateMeterAttributesNewFineAmountId = '1005';
    static UpdateMeterAttributesNewLocationType = '1004';
    static UpdateMeterAttributesChangeContractTypeToReserve = '1002';
    static UpdateMeterAttributesNewRequiredClosureAllowance = '1003';

    static ChangeContractTypeToReservedId = 100061;

    static RelocateEffectiveImmediatelyId = 100980;
    static RelocateEffectiveImmediatelyName = 'Relocate Effective Immediately';

    static RelocateMeterNewMeterAddressId = 100940;
    static RelocateMeterNewMeterAddressName = 'New Meter Street Number';

    static RelocateMeterOldMeterAddressId = 100950;
    static RelocateMeterOldMeterAddressName = 'Old Meter Street Number';

    static MeterAddressTempMeterId = 100043;
    static MeterAddressTempMeterName = 'Meter Address';

}
