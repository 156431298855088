import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter, ErrorHandler } from '@angular/core';

import { MonthNameToMonth, MonthToMonthName, MonthsInFiscalYear } from '../../inventory-management/meters/meter-true-up/models/month-name-dictionary';


import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { PaginationParameters } from '../../core/models/pagination-paramters';

import { Subject ,  Observable } from 'rxjs';
import { MonthlyTrueUpCalculationDetailBase } from './monthly-true-up-calculation-detail-base';
import { MonthlyTrueUpCalculationDataSourceBase } from './monthly-true-up-calculation-datasource-base';
import { MonthlyTrueUpCalculationFilterRepresentation } from './monthly-true-up-calculation-filter-representation-base';
import { MonthlyTrueUpCalculationServiceBase } from './monthly-true-up-calculation-service-base';
import { MonthlyTrueUpCalculationFilterProperties } from './monthly-true-up-calculation-filter-properties';
import { PaginatedGetResult } from '../../core/models/paginated-get-result';
import { ExportMonthlyTrueUpCalculationFilterRepresentation } from './export-monthly-true-up-calculation-filter-representation';
import { FileDownloadService } from '../../core/file-download/file-download.service';
import { ExportSelectRepresentation } from '../../inventory-management/inventory-filter/export-representations';
import { TrueUpDetailColumnDef } from '../true-up-detail-column-def';
import { WorkOrdersService } from '../../work-orders/services/work-orders.service';


export abstract class MonthlyTrueUpCalculationComponentBase<TModel extends MonthlyTrueUpCalculationDetailBase> implements OnInit {

  @Input() filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation;
  @Input() filterSubject: Subject<MonthlyTrueUpCalculationFilterRepresentation>;

  newFilter = false;
  exportSelectRep: ExportSelectRepresentation;

  @Output()
  dataSubject: Subject<PaginatedGetResult<TModel>>;

  @Output()
  itemCount: EventEmitter<number> = new EventEmitter<number>(true);
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  loading: boolean;

  constructor(protected errorHandler: ErrorHandler, private service: MonthlyTrueUpCalculationServiceBase<TModel>,
    private workOrderService: WorkOrdersService, public snackBar: MatSnackBar) {
    this.dataSubject = new Subject<PaginatedGetResult<TModel>>();
  }

  ngOnInit() {

    this.loading = true;

    if (this.filterRepresentation === undefined) {
      this.filterRepresentation = new MonthlyTrueUpCalculationFilterRepresentation();
    }


    const pageEvent = new PageEvent();
    pageEvent.length = 0;
    pageEvent.pageIndex = 0;
    pageEvent.pageSize = 25;

    const paginationParameters = new PaginationParameters();
    paginationParameters.orderByColumn =  TrueUpDetailColumnDef.year.constant;
    paginationParameters.pageSize = pageEvent.pageSize;
    paginationParameters.isAscending = true;
    paginationParameters.totalItemCount = 0;

    this.refreshDataSource(paginationParameters, this.filterRepresentation);
  }

  handleFilterUpdated(filterProperties: MonthlyTrueUpCalculationFilterProperties) {
    this.loading = true;
      this.refreshDataSource(filterProperties.paginationParameters, filterProperties.filterRepresentation);
  }

  handleRowCount(filterProperties: MonthlyTrueUpCalculationFilterProperties) {
    this.loading = true
    this.getDataRowCount(filterProperties.paginationParameters, filterProperties.filterRepresentation);
  }

  exportTrueUpReport(evt: ExportMonthlyTrueUpCalculationFilterRepresentation) {
    const orderByColumn = evt.MonthlyTrueUpCalculationFilterProperties.paginationParameters.orderByColumn;
    const isAscending = evt.MonthlyTrueUpCalculationFilterProperties.paginationParameters.isAscending;
    this.snackBar.open('Downloading Report', '', <MatSnackBarConfig>{
      duration: 2000
    });

    evt.MonthlyTrueUpCalculationFilterRepresentation.RowCount = null;


    //otherwise be sure it is empty
    evt.MonthlyTrueUpCalculationFilterRepresentation.WorkOrderActionIds = undefined;

    if (evt.MonthlyTrueUpCalculationFilterRepresentation.ShouldFilterByWOID) {
      //get the work order action ids
      this.workOrderService.getWorkOrderActionIdsByWorkOrderDisplayId(evt.MonthlyTrueUpCalculationFilterRepresentation.WOID)
        .subscribe(workOrderActionIdsResponse => {
          var workOrderActionIds = workOrderActionIdsResponse.join(',');
          if (workOrderActionIds || workOrderActionIds === '') {
            evt.MonthlyTrueUpCalculationFilterRepresentation.WorkOrderActionIds = workOrderActionIds;
          }
          else {
            evt.MonthlyTrueUpCalculationFilterRepresentation.WorkOrderActionIds = '';
          }

          this.service.requestTrueUpReport(orderByColumn, isAscending, evt, this.exportSelectRep).subscribe(result => {
            this.service.downloadReport(result.result);
          },
            error => {
              this.errorHandler.handleError(error);
            });
        })
    }
    else {

      this.service.requestTrueUpReport(orderByColumn, isAscending, evt, this.exportSelectRep).subscribe(result => {
        this.service.downloadReport(result.result);
      },
        error => {
          this.errorHandler.handleError(error);
        });
    }
  }

  refreshDataSource(paginationParameters: PaginationParameters, filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation) {
    const skip = paginationParameters.pageIndex * paginationParameters.pageSize;
    const take = paginationParameters.pageSize;
    const emptyResult = new PaginatedGetResult<TModel>();
    this.dataSubject.next(emptyResult);

    filterRepresentation.RowCount = null;

    //defaults when there is nothing set, prevents failure on large data sets
    if (!paginationParameters.orderByColumn
      || paginationParameters.orderByColumn == '') {
      paginationParameters.orderByColumn = TrueUpDetailColumnDef.year.constant;
      paginationParameters.isAscending = true;
    }

    //otherwise be sure it is empty
    filterRepresentation.WorkOrderActionIds = undefined;

    if (filterRepresentation.ShouldFilterByWOID) {
      //get the work order action ids
      this.workOrderService.getWorkOrderActionIdsByWorkOrderDisplayId(filterRepresentation.WOID)
        .subscribe(workOrderActionIdsResponse => {
          var workOrderActionIds = workOrderActionIdsResponse.join(',');
          if (workOrderActionIds || workOrderActionIds === '') {
            filterRepresentation.WorkOrderActionIds = workOrderActionIds;
          }
          else {
            filterRepresentation.WorkOrderActionIds = '';
          }

          const result = this.service.getAllItems(
            paginationParameters.orderByColumn, paginationParameters.isAscending,
            skip, take, filterRepresentation)
            .subscribe(paginationResult => {
              this.dataSubject.next(paginationResult);
              this.loading = false;
            },
              error => {
                this.loading = false;
                console.log(error);
              });
        })
    }
    else
    {

      const result = this.service.getAllItems(
        paginationParameters.orderByColumn, paginationParameters.isAscending,
        skip, take, filterRepresentation)
        .subscribe(paginationResult => {
          this.dataSubject.next(paginationResult);
          this.loading = false;
        },
          error => {
            this.loading = false;
            console.log(error);
          });
    }
  }

  getDataRowCount(paginationParameters: PaginationParameters, filterRepresentation: MonthlyTrueUpCalculationFilterRepresentation) {
    const skip = paginationParameters.pageIndex * paginationParameters.pageSize;
    const take = paginationParameters.pageSize;

    filterRepresentation.RowCount = 'true'

    const result = this.service.getAllItems(
      paginationParameters.orderByColumn, paginationParameters.isAscending,
      skip, take, filterRepresentation)
      .subscribe(paginationResult => {
          this.snackBar.open(`Total Row Count: ${paginationResult.totalItems}`, 'X');
          // this.dataSubject.next(paginationResult);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  getExportSelect(evt: ExportSelectRepresentation) {
    this.exportSelectRep = evt;
  }
}

