import { PagedResult } from '../../core/models/paged-result';

export class InventoryListSummary {
    activeMetersOnBlock: number;
    areaId: string;
    areaName: string;
    blockId: string;
    blockName: string;
    blockRange: string;
    contractType: string;
    currentStatus: string;
    displayAddress: string;
    displayId: string;
    id: string;
    mostFrequentRatePackage: string;
    mostFrequentRatePackageDescription: string;
    multipleRatePackages: boolean;
    numberOfActiveSpaces: number;
    parkingType: string;
    ratePackageIds: RatePackageIdSummary[];
    ratePackageId: string;
    ratePackageDescription: string;
    sideOfStreet: string;
    spaceId: string;
    spaceStatus: string;
    spaceType: string;
    wardName: string;
    wardId: string;
    zoneId: string;
    zoneName: string;
    lastUpdateDate: string;
    lastUpdatedBy: string;
}

export class RatePackageIdSummary {
    id: string;
    name: string;
    numberOfSpacesWithRatePackage: number;
}

export class InventoryListPaginationResult extends PagedResult<InventoryListSummary> { }

export class Location {
    numberRangeEnd: number;
    numberRangeStart: number;
    streetDirection: string;
    streetName: string;
}
