import { ScheduleBlocks } from './schedule-blocks';

export class DailyKeyValuePair {
  key: string;

  value: SheduleBlockDayAndValue;
}

export class SheduleBlockDayAndValue {
  day: string;

  scheduleDetails: ScheduleBlocks[];
}
