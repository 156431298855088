import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Block } from '../../inventory-management/blocks/block';
import { MatDialog } from '@angular/material/dialog';
import { BlockService } from '../../inventory-management/blocks/blocks.service';
import { InventorySelectDialogComponent } from '../../work-orders/action-field/inventory-select-dialog/inventory-select-dialog.component';
import { LinkedBlock } from './linked-block';

@Component({
  selector: 'app-linked-blocks',
  templateUrl: './linked-blocks.component.html',
  styleUrls: ['./linked-blocks.component.css']
})
export class LinkedBlocksComponent implements OnChanges, OnInit {
  selectedBlock: Block;

  @Input() linkedBlocks: Block[];

  linkedAnotherBlockEnabled: boolean;

  @Output()
  submitDisabledEmitter: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  @Output()
  submitLinkedBlocksEmitter: EventEmitter<LinkedBlock[]> = new EventEmitter<LinkedBlock[]>();

  @Output() selectedBlockEmitter: EventEmitter<string> = new EventEmitter<string>(true);

  @Input() shouldSubmitLinkedBlocks: boolean;

  @Input() clearSubmittedLinkedBlocks: boolean;

  displayBlockIdError: boolean;


  constructor(private dialog: MatDialog, private _blockService: BlockService) { }

  ngOnInit() {
    this.selectedBlock = new Block();
    this.linkedBlocks = [];
    this.linkedBlocks.push(new Block());
    this.submitDisabledEmitter.emit(true);
  }

  ngOnChanges() {
    if (this.linkedBlocks && this.linkedBlocks.length !== 0) {
      var linkedBlocksToSubmit = new Array<LinkedBlock>();

      this.linkedBlocks.forEach(linkedBlock => {
        var linkedBlockToSubmit = new LinkedBlock();
        linkedBlockToSubmit.FirstLinkedBlockId = this.selectedBlock.id;
        linkedBlockToSubmit.FirstLinkedBlockName = this.selectedBlock.displayId;

        linkedBlockToSubmit.SecondLinkedBlockId = linkedBlock.id;
        linkedBlockToSubmit.SecondLinkedBlockName = linkedBlock.displayId;

        linkedBlocksToSubmit.push(linkedBlockToSubmit);
      });

      this.submitLinkedBlocksEmitter.emit(linkedBlocksToSubmit);

      this.selectedBlock = new Block();
      this.linkedBlocks = new Array<Block>();
      this.linkAnotherBlock();
    }
  }

  linkAnotherBlock() {
    this.linkedBlocks.push(new Block());
    this.linkedAnotherBlockEnabled = false;
    this.submitDisabledEmitter.emit(true);
  }

  openInventorySelectDialog(fieldType): void {
    const dialogRef = this.dialog.open(InventorySelectDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {context: 'select-blocks'},
    });

    if (fieldType.includes('block-select')) {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._blockService.getBlockById(result.id)
          .subscribe(resultBlock => {
            if (fieldType.includes('linked-block')) {
              this.linkedBlocks[this.linkedBlocks.length - 1] = resultBlock;
              this.linkedAnotherBlockEnabled = true;
              this.submitDisabledEmitter.emit(false);
            }
            else {
              this.selectedBlock = resultBlock;
            }
            this.displayBlockIdError = false;
          });
        }
      });
    }
  }

}
