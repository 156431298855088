import { WorkOrderCommentTag } from "./work-order-comment-tag";

export class WorkOrderComment {
    author: string;

    commentTags: WorkOrderCommentTag[] = new Array<WorkOrderCommentTag>();

    entryTimeStamp: Date;

    isInternal: boolean;

    text: string;

    workOrderId: string;
}