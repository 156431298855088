import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InventoryHttpService } from './inventory-http.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SystemStartTimeService {

  constructor(private inventoryHttpService: InventoryHttpService) { }

    public getInitialSystemStartTime(): Observable<Date> {
        return this.inventoryHttpService.get('/time/start/', { observe: 'response' })
          .pipe(
            map((data) => {
              return new Date(data.body);
            }),
            catchError(error => this.handleError(error))
          );
    }

    private handleError(error: any): Promise<any> {
      console.error('An error occurred', error);
      return Promise.reject(error.message || error);
    }
}
