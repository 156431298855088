import { MeterSummary, RatePackageIdSummary } from './meter-summary';
export class Meter {

  activeMetersOnBlock: number;

  activeSpaces: number;

  activeSpacesAtSystemStart: number;

  adjustmentForSeasonality: string;

  alarm: string;

  alternateMeter: string;

  area: string;

  associatedSpaceCount: number;

  blockDisplayId: string;

  blockId: string;

  currentStatus: string;

  currentContractType: string;

  dayRateHour: string;

  dayTimeLimit: string;

  description: string;

  displayId: string;

  eLockNumber: number;

  foundationType: string;

  id: string;

  initialImpactedMeter: boolean;

  isPhysicallyRemoved: boolean;

  labelCode: string;

  location: Location;

  locationType: string;

  managementFeePercentageIndex: number;

  merchantAccount: string;

  merchantId: string;

  meter: string;

  multipleRatePackageIds: boolean;

  ratePackage: string;

  meterInstallationStatus: string;

  meterType: string;

  mostFrequentRatePackage: string;

  nightRateHour: number;

  mrp: string;

  physicalMeter: MeterSummary;

  parentMeterId: number;

  parkingTypeId: string;

  parkingType: string;

  programNumber: number;

  ratePackageSummaries: RatePackageIdSummary[];

  rentalFeePercentageIndex: number;

  requiredClosureAllowance: 0;

  requiredClosureAllowanceName: string;

  serialNumber: number;

  sideOfStreet: string;

  sim: number;

  snapshot: string;

  spaceTypes: SpaceType[];

  taxIndex: number;

  tempRemovalStatus: string;

  virtualMeters: VirtualMeter[];

  wardName: string;

  workOrderNotes: string;

  zone: string;

  meterAddress: string;
}

export class VirtualMeter {
  id: string;
  displayId: string;
}

export class Location {

  latitude: number;

  longitude: number;

  number: number;

  numberRangeEnd: number;

  numberRangeStart: number;

  sideOfStreet: string;

  streetDirection: string;

  streetName: string;

  streetSuffix: string;
}

export class SpaceType {
    id: string;
    name: string;
    description: string;
}

export class MeterDowntime {
  endDateTime: Date;

  relevantSeconds: number;

  inoperableId: string;

  inoperableMonth: Date;

  meterDisplayId: string;

  notes: string;

  overlap: boolean;

  totalMinutes: number;

  startDateTime: Date;

  utilizationAmount: number;
}
