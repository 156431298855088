exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-dialog-container {\r\n    max-width:1300px;\r\n    width:1300px;\r\n}\r\n\r\n.mat-card-title {\r\n    margin-bottom:15px;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();