import { InventoryChangeBase } from './inventory-change-base';
import { SpaceType } from '../../../inventory-management/meters/meter';

export class BlockInventoryChange extends InventoryChangeBase {

    activeMetersOnBlock: number;

    areaId: string;

    areaDisplayId: string;

    blockStart: number;

    blockEnd: number;

    blockId: string;

    blockDisplayId: string;

    description: string;

    id: string;

    spaceTypes: SpaceType[];

    sideOfStreet: string;

    status: string;

    streetDirection: string;

    streetName: string;

    streetSuffix: string;

    changedDateTime: Date;

    ward: number;

    workOrderActionId: string;

    zoneId: string;

    zoneDisplayId: string;
}
