exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-card-title {\r\n    margin-bottom:15px;\r\n}\r\n\r\n.dialog-style {\r\n    max-width:1300px;\r\n    width:1300;\r\n    padding-top:0px;\r\n}\r\n\r\n.dialog-content {\r\n    overflow-y:auto;\r\n}\r\n\r\n.mat-dialog-container {\r\n    max-height:500px !important;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();