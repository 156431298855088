import { StringExtensions } from '../../../string-extensions';
import { FilterOperator } from '../../../core/query-strings/query-string-builder';

export class ClosureFilterRepresentation {
  FiscalQuarter = 1; // called reporting quarter in UI

  FiscalYear = 2013; // called reporting year in UI

  MeterId: string = undefined;
  MeterIdFilterOperator: string = FilterOperator.Equal;
  get FilterByMeterId(): boolean {
    return StringExtensions.StringIsNotEmpty(this.MeterId);
  }

  SpaceId: string = undefined;
  SpaceIdFilterOperator: string = FilterOperator.Equal;
  get FilterBySpaceId(): boolean {
    return StringExtensions.StringIsNotEmpty(this.SpaceId);
  }

  BlockId: string = undefined;
  BlockIdFilterOperator: string = FilterOperator.Equal;
  get FilterByBlockId(): boolean {
    return StringExtensions.StringIsNotEmpty(this.BlockId);
  }

  ZoneId: string = undefined;
  get FilterByZoneId(): boolean {
    return StringExtensions.StringIsNotEmpty(this.ZoneId);
  }

  AreaId: string = undefined;
  get FilterByAreaId(): boolean {
    return StringExtensions.StringIsNotEmpty(this.AreaId);
  }
  NumberOfClosures = 0;
  NumberOfClosuresFilterOperator: string = FilterOperator.GreaterThanOrEqual;

  QualifiedRequiredClosures = 0;
  QualifiedRequiredClosuresFilterOperator: string = FilterOperator.GreaterThanOrEqual;

  ClosureId: string = undefined;
  ClosureIdFilterOperator: string = FilterOperator.Equal;
  get FilterByClosureId(): boolean {
    return StringExtensions.StringIsNotEmpty(this.ClosureId);
  }
}
