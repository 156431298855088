import { WorkOrder } from './../../../models/work-order';
import { ActionConstants } from './../../action-constants';
import { HistoricalMeterForm } from './historical-meter-form';
import { Validators } from '@angular/forms';

import { ActionField } from '../../../models/action-field';
import { MeterService } from '../../../../inventory-management/meters/meters.service';
import { MoreThanTwentySpaces, ShiftingLastSpace, ShiftSpaceToDifferentBlocks, ShiftingToDifferentContractType } from '../../../models/warning-constants';
import { ShiftSpaceConstants } from '../../../action-field/action-field-constants';
import { ShiftSpaceTooManySpacesValidator, ShiftSpaceShiftMoreThanZeroSpacesValidator, ShiftSpaceShiftMatchingMetersValidator } from '../../../action/action-forms/form-field-validators/shift-space-validators';
import { TokenCache } from './../../../../security/token-cache';
import { WorkorderActionJsonConstants } from '../../../action-field/workorder-action-json-constants';
import { BlockService } from '../../../../inventory-management/blocks/blocks.service';
import { SpaceService } from '../../../../inventory-management/spaces/spaces.service';
import { LoadingZoneImpactService } from '../../../../inventory-management/loading-zone-impact/loading-zone-impact.service';
import {debounceTime} from 'rxjs/operators';

export class ShiftSpaceForm extends HistoricalMeterForm {
  constructor(actionIndex: string, private actionFields: ActionField[], _meterService: MeterService, _blockService: BlockService, _spaceService: SpaceService, tokenCache: TokenCache, workOrderActionJsonObject: any, hasHistoricalDate: boolean, loadingZoneImpactService: LoadingZoneImpactService, historicalDate: Date, overrideAsCity: boolean, workOrder: WorkOrder) {
    super(actionIndex, actionFields, tokenCache, _meterService, _blockService, _spaceService, hasHistoricalDate, overrideAsCity, workOrder, loadingZoneImpactService, historicalDate);

    this.workOrderActionJsonObject = workOrderActionJsonObject;
    this.onChanges();
    this._meterService = _meterService;
    this._blockService = _blockService;
  }

  blockFromId: string;
  blockToId: string;
  _meterService: MeterService;
  numberOfSpacesOnFromMeter: number;
  numberOfSpacesOnToMeter: number;
  toMeterContractType: string;
  fromMeterContractType: string;

  private onChanges() {
    this.setMeterFromValidators();
    this.setMeterToValidators();

    if (!this.workOrderActionJsonObject) {
      this.disableControl(ShiftSpaceConstants.NumberOfSpaces);
    }

    if (this.controlExists(ShiftSpaceConstants.NumberOfSpaces)) {
      this.subscribeToControl(ShiftSpaceConstants.NumberOfSpaces).subscribe(value => {
        this.setNumberOfSpacesWarnings(value);
      });
    }
  }

  private setMeterFromValidators() {
    this.addGuidControl(WorkorderActionJsonConstants.meterFromGuid);
    this.setGuidValidator(WorkorderActionJsonConstants.meterFromGuid, ShiftSpaceShiftMatchingMetersValidator(this.formGroup, this));

    let currentValue;
    this.subscribeToControl(ShiftSpaceConstants.MeterFromID).pipe(debounceTime(1500)).subscribe(value => {
      if (currentValue !== value) {
        currentValue = value;
        if (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
          this.setHistoricalMeterConditionals(ShiftSpaceConstants.MeterFromID, WorkorderActionJsonConstants.meterFromGuid, value);
        }
        else if (!this.hasHistoricalEffectiveDate && this.getGuidControlValue(WorkorderActionJsonConstants.meterFromGuid)) {
          const meterId = this.getGuidControlValue(WorkorderActionJsonConstants.meterFromGuid);

          this._meterService.getMeterById(meterId).subscribe(result => {
            this.numberOfSpacesOnFromMeter = result.activeSpaces;
            this.addTotalNumberOfSpacesOnFromMeterControl();
            this.setTotalNumberOfSpacesOnFromMeter(result.activeSpaces);
            this.blockFromId = result.blockId;
            this.fromMeterContractType = result.currentContractType;

            if(this.toMeterContractType && this.fromMeterContractType && this.toMeterContractType !== this.fromMeterContractType) {
              this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid).setErrors({'ShiftSpaceDifferentContractTypes': true});
            }
            else {
              this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterToGuid).setErrors(null);
              this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid).setErrors(null);

              this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid).updateValueAndValidity();
              this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterToGuid).updateValueAndValidity();
            }

            this.setValidators(ShiftSpaceConstants.NumberOfSpaces, [Validators.required, ShiftSpaceShiftMoreThanZeroSpacesValidator(),
              ShiftSpaceTooManySpacesValidator(this.numberOfSpacesOnFromMeter)]);
            this.enableControl(ShiftSpaceConstants.NumberOfSpaces);

            if (this.blockToId) {
              this.addOrRemoveDifferentBlockWarning();
            }

          });
        }
      }

      this.updateValueAndValidity(ShiftSpaceConstants.MeterFromID);
    });
  }

  private setMeterToValidators(): void {
    if (this.controlExists(ShiftSpaceConstants.MeterToID)) {
      this.addGuidControl(WorkorderActionJsonConstants.meterToGuid);
      this.setGuidValidator(WorkorderActionJsonConstants.meterToGuid, ShiftSpaceShiftMatchingMetersValidator(this.formGroup, this));

      let currentValue;
      this.subscribeToControl(ShiftSpaceConstants.MeterToID).pipe(debounceTime(1500)).subscribe(value => {
        if (currentValue !== value) {

          currentValue = value;
          if (this.hasHistoricalEffectiveDate && this.historicalEffectiveDate) {
            this.setHistoricalMeterConditionals(ShiftSpaceConstants.MeterToID, WorkorderActionJsonConstants.meterToGuid, value);
            this.addOrRemoveDifferentBlockWarning();
          }
          else {
            const meterToId = this.getGuidControlValue(WorkorderActionJsonConstants.meterToGuid);
            if (meterToId) {
              this._meterService.getMeterById(meterToId).subscribe(result => {
                this.numberOfSpacesOnToMeter = result.activeSpaces;
                this.addTotalNumberOfSpacesOnToMeterControl();
                this.setTotalNumberOfSpacesOnToMeter(result.activeSpaces);
                this.toMeterContractType = result.currentContractType;
                if(this.toMeterContractType && this.fromMeterContractType && this.toMeterContractType !== this.fromMeterContractType) {
                  this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterToGuid).setErrors({'ShiftSpaceDifferentContractTypes': true});
                }
                else {
                  this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterToGuid).setErrors(null);
                  this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid).setErrors(null);

                  this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterFromGuid).updateValueAndValidity();
                  this.formGroup.get(this.actionIndex + WorkorderActionJsonConstants.meterToGuid).updateValueAndValidity();
                }

                this.blockToId = result.blockId;
                this.addOrRemoveDifferentBlockWarning();
              });
            }
          }

        }
      });
    }
  }

  private setHistoricalMeterConditionals(meterControlId: number, meterGuidControlId: string, value: string): void {
    this.addOrRemoveMeterIdValidations(meterControlId, ShiftSpaceConstants.NumberOfSpaces, meterGuidControlId, value, [Validators.required, ShiftSpaceShiftMoreThanZeroSpacesValidator(), ShiftSpaceTooManySpacesValidator(this.numberOfSpacesOnFromMeter)], ActionConstants.ShiftSpaceId);
    this.setNumberOfSpacesWarnings(this.numberOfSpacesOnFromMeter);
  }

  private enableNumberOfSpacesIfBothMetersSelected(): void {
    if (this.getGuidControlValue(WorkorderActionJsonConstants.meterFromGuid) && this.getGuidControlValue(WorkorderActionJsonConstants.meterToGuid)) {
      this.enableControl(ShiftSpaceConstants.NumberOfSpaces);
    }
  }

  private addOrRemoveDifferentBlockWarning(): void {
    if (this.blockFromId !== this.blockToId && this.blockFromId
      && this.blockToId && !this.warnings.includes(ShiftSpaceToDifferentBlocks)) {
        this.pushToWarnings(ShiftSpaceToDifferentBlocks);
    }
    else if (this.warnings.includes(ShiftSpaceToDifferentBlocks)) {
      this.removeFromWarnings(ShiftSpaceToDifferentBlocks);
    }
  }

  private setNumberOfSpacesWarnings(numberOfSpaces: number) {
    if (+this.numberOfSpacesOnFromMeter - +numberOfSpaces === 0) {
      if (!this.warnings.includes(ShiftingLastSpace)) {
        this.pushToWarnings(ShiftingLastSpace);
      }
    }
    else if (this.warnings.includes(ShiftingLastSpace)) {
      this.removeFromWarnings(ShiftingLastSpace);
    }

    if (+this.numberOfSpacesOnToMeter + +numberOfSpaces > 15) {
      if (!this.warnings.includes(MoreThanTwentySpaces)) {
        this.pushToWarnings(MoreThanTwentySpaces);
      }
    }
    else if (this.warnings.includes(MoreThanTwentySpaces)) {
      this.removeFromWarnings(MoreThanTwentySpaces);
    }
  }
}
