import { ActionField } from './action-field';
import { WorkOrderAction } from './work-order-action';

export class LetterOfDirection {

    id: number;
    author: string;
    authorUserRole: string;
    authorLastModifiedDate: Date;
    hasHistoricalEffectiveDate: boolean;
    historicalEffectiveDate?: Date;
    versionNumber: number;
    workOrderActions: WorkOrderAction[];
    notes: string;
    status: string;
    readyForAcceptance: boolean;
    letterOfDirectionResponses: LetterOfDirectionResponseDetail[] = new Array<LetterOfDirectionResponseDetail>();
    mostRecentResponse: LetterOfDirectionResponseDetail;
    tempApprovalStatus: string;


}

export class AbstractResponseBaseDetail {
    id: string;
    responder: string;
    responderRole: string;
    responseDate: Date;
    responseType: string;
    comments: string;
}

export class LetterOfDirectionResponseDetail extends AbstractResponseBaseDetail {
    letterOfDirectionId: number;
}

export class LodWithActionFields {
    constructor(public lod: LetterOfDirection, public actionsWithFields: WorkOrderActionWithActionFields[]) { }
}

export class WorkOrderActionWithActionFields {
    constructor(public workOrderAction: WorkOrderAction, public actionFields: ActionField[], public workOrderActionJson: any, public summationSentence: string) { }
}
