exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-raised-button {\r\n    margin: 2%\r\n}\r\n\r\n.mat-form-field {\r\n    width: 80%\r\n}\r\n\r\n.button-group {\r\n    margin-top: 10%\r\n}\r\n\r\n.id-error {    \r\n    width: 80%;\r\n    font-style: italic;\r\n    color: red;\r\n}", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();