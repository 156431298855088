import { PagedResult } from './../../core/models/paged-result';
import { Zone } from '../zones/zone';
import { Area } from '../areas/area';
export class Block {
    activeMetersOnBlock: number;

    areaName: string;

    areaId: string;

    description: string;

    displayId: string;

    id: string;

    location: Location;

    spaceTypes: spaceType[];

    locationType: string;

    physicalBlockId: string;

    primaryMeter: string;

    ward: number;

    zoneId: string;
    
    zoneName: string;

    spaceTypeIds: string;

    zone: Zone;

    area: Area;
}

export class Location {
    numberRangeEnd: string;

    numberRangeStart: string;

    streetDirection: string;

    streetName: string;

    sideOfStreet: string;

    streetSuffix: string;
}

export class spaceType {
    id: string;
    name: string;
    description: string;
}

export class BlockPaginationResult extends PagedResult<Block> {
}